import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from "@angular/core";
import { Base64ObjectUtils, ROComponent, ROPageComponent } from "../ROComponent";
import { RecorderAndPlayer } from "../SoundRecorder";
import { DataService } from "src/app/service/data.service";
import { UploadService } from "../../uploadModule/upload.service";
import { AlertService } from "src/app/service/alert.service";
import { WhitePopupService } from "../../whitePopupModule/whitePopup.service";
import { ROBookConfig } from "../ROBookConfig";
import { CommonService } from "src/app/service/common.service";
import { speechPracticeInstruction } from "./instruction";
import { speechPracticeHeader } from "./header";
import { speechPracticeFinalReport } from "./finalReport";
import { conversationPractice } from "./conversationPractice";
import { exercisePractice } from "./exercisePractice";
import { ChatService } from "src/app/service/chatGPT.service";

/*
@Component({
	selector: 'html-container2',
	template: `
		<div #content class="html-container"></div>
	`
})

export class HTMLContainer2 implements OnInit, AfterViewInit, OnChanges {
	@ViewChild('content', { static: false }) public content: any;
	@Input() public html: any;

	constructor() { }

	ngOnChanges(changes: SimpleChanges): void { this.updateHTML() }

	ngOnInit(): void { }

	ngAfterViewInit(): void { this.updateHTML() }

	private updateHTML(): void {
		if (this.content) this.content.nativeElement.innerHTML = this.html;
	}
}
*/
/////

@Component({
	selector: 'speechPracticeROSTT',
	templateUrl: './speechPracticeROSTT.html',
	styleUrls: ['./speechPracticeROSTT.scss'],
})

export class speechPracticeROSTT extends ROComponent {
	@ViewChild('instruction', { static: false }) instructionContainer: speechPracticeInstruction;
	@ViewChild('header', { static: false }) headerContainer: speechPracticeHeader;
	@ViewChild('exercisePractice', { static: false }) exercisePracticeContainer: exercisePractice;
	@ViewChild('conversationPractice', { static: false }) conversationPracticeContainer: conversationPractice;
	@ViewChild('finalResult', { static: false }) finalResultContainer: speechPracticeFinalReport;
	/////////////////////////////////////////
	//       Original config variable      //
	/////////////////////////////////////////
	public myRecorder: RecorderAndPlayer;
	public processing: boolean;
	private fileReference: any;
	public ctx: any;

	/////////////////////////////////////////
	//       Initialize data variable      //
	/////////////////////////////////////////
	public compIdArray = [];
	public bookId;
	public bookIdForDemo = [
		'2868218', '2868222', '2868224', '2868226', '2868228', '2868230', '2868247', '2868251', '2868261', '2868265', '2868281',
		'2868283', '2868285', '2868288', '2868303', '2868305', '2868307', '2868309', '2868311', '2868313', '2868315', '2878880',
		'2902759', '2902762', '2902769', '2902772', '2973103', '2973105', '2973111', '2973113', '2973117', '2973306', '2984105',
		'2984109', '2984113', '2984119', '2984132', '3055517'
	]
	public dataForAIOral: any = { bsid: "", book_id: "" };
	public progressPercent = "0%";
	public questionNumber = 1;
	public bookConfig;
	public viewMode;
	public backgroundUrl;
	public fontSize = 'N';
	public lang = this.dataService.lang;					// lang for translateText()

	// Data from book structure
	public bookStructure;
	public totalStarNumber;
	public mainType;										// 練習形式 / 對話形式
	public subType;											// 題型
	public exerciseType = ["word", "sentence", "fillIn", "mc", "paragraph", "aiOral"];
	public conversationType = ["oralVocab", "oralSentence", "oralFillIn", "oralMc", "oralParagraph", "oralAI"];
	public difficulty;
	public bookTitle;
	public pageId;
	public totalPageNumber = 0;
	public pageIndex = 0;
	public introduction = "";
	public displayTopic;									// 顯示題目
	public fillInTypeTopic = [];							// 填充題題目				
	public answerChoice = [];								// 可選取答案	(唯一 / 多項)
	public correctAnswer;									// 正確答案
	public answerWithoutContractions;
	public userTotalScore;

	// Data after recording
	public recordTimer;
	public myData;
	public accuracyScore;									// 準確分數
	public completenessScore;								// 完整分數
	public fluencyScore;									// 流暢分數
	public confidence;										// 測試準確度
	public pronScore;										// 發音總分
	public userRecordAnswer;								// 作答後user答案
	public recognitionAnswer = [];							// 作答後辨識結果
	public recognitionWordArray = [];
	public foulLanguage = [];

	// Controlling state
	public isInstruction = true;							// true -> show Instruction Page
	public isInput = false;
	public isRecorded = false;								// true -> show user answer (compare to correct answer)
	public isFinalReportOpen = false;
	public isConversationReportOpen = false;
	public isLoadAudio = false;

	public aiOralfinalScore = {
		content: 10,
		grammar: 10,
		creativity: 10,
	};

	public contractions = [
		{ label: "'m", value: " am" },
		{ label: "'s", value: " is" },
		{ label: "'re", value: " are" },
		{ label: "'ll", value: " will" },
		{ label: "'ve", value: " have" },
		{ label: "'d", value: " would" },
		{ label: "n't", value: " not" },
	]

	// 成績表Result
	public totalScoreInEachQuestion = 10;

	// Reading config
	public audio: HTMLAudioElement = new Audio();
	public ttsSetting: any = {								// 朗讀題目config
		langType: "en-US",
		preprocess: "0",
		pitch: 1,
		speed: 1,
		voice: "en-US-CoraNeural",
		speakingStyle: "general",
		gender: "F"
	};
	public downloadUrl;

	// State for conversationType
	public conversationData;
	public studentLevel = "p4";
	public objective = [];
	public defaultQuestion = [];
	public EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
	public redoConversation = false;
	// public conversationFinish;

	constructor(
		cdr: ChangeDetectorRef,
		elementRef: ElementRef,
		public dataService: DataService,
		public upload: UploadService,
		public alert: AlertService,
		public coms: CommonService,
		public chat: ChatService
	) { super(cdr, elementRef) }

	ngOnInit() {
		this.elementRef.nativeElement.parentElement.classList.forEach((value) => {
			if (value.includes('p4')) this.studentLevel = "p4";
			else if (value.includes('p5')) this.studentLevel = "p5";
			else if (value.includes('s1')) this.studentLevel = "s1";
		});

		let config: ROBookConfig = this.context.config;
		this.bookConfig = this.context.config;
		this.viewMode = this.context.config.viewMode;
		this.bookId = config.book.id;
		this.dataForAIOral = { bsid: config.share.id, book_id: this.bookId };
		if (config.share.created_by.uid === this.dataService.userInfo.uid || this.dataService.userInfo.user_role == 3) {
			this.isInstruction = false;
		}
		if (location.href.indexOf('/review') > -1) {
			this.dataForAIOral.readonly = true;
			this.dataForAIOral.status = 'read-only';
		}
		this.bookTitle = config.book.title;

		let loadBookStructure = this.loadBookStructure();
		let findEnrollmentId = this.findEnrollmentId(config.share.id);
		Promise.all([loadBookStructure, findEnrollmentId]).then(() => {
			if (this.viewMode !== "review") {
				this.setEDBObjective();
				this.setDefaultQuestion();
			}
		})
	}

	public getSubComponentDataArray(isData: boolean, component: any):any [] {
		if (isData) {
			const totalPronScore = this.myData.conversationData.reduce((acc, score) => acc + score.PronScore, 0);
			const averagePronScore = totalPronScore / this.myData.conversationData.length;
			let correctPronunciation = -1;
			if (Math.floor(averagePronScore) == 100) {
				correctPronunciation = 2;
			} else if (Math.floor(averagePronScore) == 0) {
				correctPronunciation = 0;
			} else {
				correctPronunciation = 1;
			}

			const filteredEDBScore = this.myData.EDBScore.filter(value => value > 0);
			console.log(filteredEDBScore)
			const totalEDBScore = filteredEDBScore.reduce((acc, value) => acc + value, 0);
			console.log(totalEDBScore)
			const averageEDBScore = totalEDBScore / filteredEDBScore.length;
			let correctEDB = -1;
			if (Math.floor(averageEDBScore) == 100) {
				correctEDB = 2;
			} else if (Math.floor(averageEDBScore) == 0) {
				correctEDB = 0;
			} else {
				correctEDB = 1;
			}

			let stringifyEDBScore = JSON.stringify(this.myData.EDBScore);
			let uriEncodeEDBScore = encodeURIComponent(stringifyEDBScore);
			let encodeStatus = btoa(uriEncodeEDBScore);

			let items:any [] = [
				{
					type: "add",
					tag: "Pronunciation",
					com: component.compIdArray[1],
					answer: this.objectToString(this.myData.conversationData),
					result: {
						score: (Math.floor(averagePronScore / 10 * 10) / 10).toFixed(1),
						maxScore: this.totalScoreInEachQuestion,
						scorePer: Math.floor(averagePronScore),
						correct: correctPronunciation,
						correction: false,
					}
				},
				{
					type: "add",
					tag: "EDB",
					com: component.compIdArray[2],
					answer: encodeStatus,
					result: {
						score: (Math.floor(averageEDBScore / 10 * 10) / 10).toFixed(1),
						maxScore: this.totalScoreInEachQuestion,
						scorePer: Math.floor(averageEDBScore),
						correct: correctEDB,
						correction: false,
					}
				}
			]
			return items;
		} else {
			console.log("no data")
			let items:any [] = [
				{
					type: "remove",
					com: component.compIdArray[0],
				}
			]
			return items;
		}
	}

	public hasSubComponent():boolean
	{
		return true;
	}

	/////////////////////////////////////////
	//       Load BookViewer Content       //
	/////////////////////////////////////////
	protected buildContent(): void {
		this.myRecorder = new RecorderAndPlayer(this.context.service.fileIO);
		let options: any = this.node.attributes;
		this.ctx = Base64ObjectUtils.base64Decode(options.context);
		if (this.ctx.backgroundImg) this.backgroundUrl = `url('${this.ctx.backgroundImg}')`;
		if (this.ctx.fontSize) this.fontSize = this.ctx.fontSize;
		if (['N', 'S', 'L'].indexOf(this.fontSize) == -1) {
			this.fontSize = 'N';
		}
	}

	// Submitted answer data
	public set data(input: string) {
		console.log(input ? "Has input" : "No input")
		this.myRecorder.reset();
		this.defaultAnswer = null;
		this.answerChanged = false;
		if (input == null) {
			// this.conversationFinish = false;
			this.myData = { input: null };
			return;
		} else {
			this.isInput = true;
		}

		try {
			this.myData = this.stringToObject(input);
			// console.log(this.myData)

			if (this.exerciseType.includes(this.subType)) {
				let decodeResult = atob(this.myData.record);
				let uriDecodeResult = decodeURIComponent(decodeResult);
				let jsonResult = JSON.parse(uriDecodeResult);

				if (this.myData && jsonResult) {
					console.log(this.myData)
					this.isRecorded = this.myData.isRecorded;
					this.isInstruction = this.myData.isInstruction;

					this.accuracyScore = jsonResult.AccuracyScore;
					this.completenessScore = jsonResult.CompletenessScore;
					this.fluencyScore = jsonResult.FluencyScore;
					this.confidence = Math.round(jsonResult.Confidence * 100);
					this.pronScore = Math.round(jsonResult.PronScore);
					this.userRecordAnswer = this.myData.userRecordAnswer;
				}

				if (this.myData.mainType) this.mainType = this.myData.mainType;
				if (this.myData.subType) this.subType = this.myData.subType;

				if (jsonResult.Words.length > 1) {
					this.recognitionAnswer = jsonResult.Words;
					this.recognitionWordArray = [];
					for (let word of this.recognitionAnswer) {
						this.recognitionWordArray.push(word)
					}
				} else {
					this.recognitionWordArray = [];
					this.recognitionWordArray.push({ Word: this.myData.text, AccuracyScore: jsonResult.AccuracyScore })
				}
				let config: ROBookConfig = this.context.config;
				let pageWithScore = Object.values(config.dataSource.answerMap)
					.filter((item: any) => item.tag === "STT");
				if (pageWithScore) this.isInstruction = false;
			} else {
				let decodeResult = atob(this.myData.conversationRecord);
				let uriDecodeResult = decodeURIComponent(decodeResult);
				let jsonResult = JSON.parse(uriDecodeResult);
				this.myData.conversationRecord = jsonResult;

				let decodeStatus = atob(this.myData.questionPassStatus);
				let uriDecodeStatus = decodeURIComponent(decodeStatus);
				let jsonStatus = JSON.parse(uriDecodeStatus);
				this.myData.questionPassStatus = jsonStatus;

				let decodeFoul = atob(this.myData.foulLanguage);
				let uriDecodeFoul = decodeURIComponent(decodeFoul);
				let jsonFoul = JSON.parse(uriDecodeFoul);
				this.myData.foulLanguage = jsonFoul;
				
				this.defaultQuestion = this.myData.questionPassStatus.map(status => ({ pass_status: status }));
				this.pageIndex = this.myData.conversationData.length;
				this.totalPageNumber = this.myData.conversationData.length;
			}
		} catch (err) {
			console.log(err);
		}
	}

	// Book Structure config
	async loadBookStructure() {
		let data: FormData = new FormData();
		data.append("api", "SpeechPractice.loadBookStructure");
		data.append("json", JSON.stringify([this.bookId]));

		try {
			const res = await this.dataService.post2({ data: data, loading: true });
			let result = res.result.structure.replace(/\\/g, "");
			let object = JSON.parse(result);
			console.log(object)
			this.bookStructure = object;

			/////////////////////////////////////////
			//           Required config           //
			/////////////////////////////////////////
			// type
			this.subType = res.subType;
			if (this.exerciseType.includes(this.subType)) {
				this.totalPageNumber = this.context.config.pages.length;
				this.pageIndex = this.context.config.pageIndex;
				this.progressPercent = `${(this.pageIndex + 1) / this.totalPageNumber * 100}%`;
			}

			// difficulty
			this.difficulty = object.book.chapters[0].difficulty
			// page id
			let config: ROBookConfig = this.context.config;
			let pageIdArray = object.book.chapters[0].pages.map((page: any) => page.id)
			this.pageId = pageIdArray[config.pageIndex]

			object.book.chapters[0].pages.map((page: any) => {
				if (page.id === this.pageId) {
					// displayTopic / fillInTypeTopic
					if (this.subType === "fillIn") {
						const split = page.displayText.split("(");
						let firstPartReadingText = split[0].trim();
						let secondPartReadingText = split[1].split(")")[1].trim();
						this.fillInTypeTopic = [firstPartReadingText, "fill", secondPartReadingText];
						this.displayTopic = "";
						console.log(this.fillInTypeTopic)
					} else {
						this.displayTopic = page.displayText
					}
					// ttsSetting.text
					if (this.difficulty === "easy") {
						let readingTextWithAnswer = page.displayText.replace(/\(\s*\)/, `${page.correctAnswer}`);
						this.ttsSetting["text"] = [readingTextWithAnswer];
					} else {
						if (this.subType === "fillIn") {
							const split = page.displayText.split("(");
							let firstPartReadingText = split[0].trim();
							let secondPartReadingText = split[1].split(")")[1].trim();
							this.ttsSetting["text"] = [firstPartReadingText, secondPartReadingText];
						}
					}
					// answerChoice
					page.answerChoice.map((item) => {
						this.answerChoice.push({ label: item, value: item.slice(0, -1) })
					})
					// correctAnswer
					if (this.subType === "word" || this.subType === "fillIn" || this.subType === "oralVocab") {
						this.correctAnswer = page.correctAnswer;
					} else {
						this.correctAnswer = page.correctAnswer.slice(0, -1);
					}
					console.log("correctAnswer: ", this.correctAnswer);
				}
			}
			)

			// component id
			this.compIdArray = object.book.chapters[0].pages[0].components.map((item: any) => item.cid);
		} catch (err) {
			console.log(err)
		}
	}

	async findEnrollmentId(bsid) {
		let data: FormData = new FormData();
		data.append("api", "SpeechPractice.findEnrollmentId");
		data.append("json", JSON.stringify([bsid]));

		try {
			const res = await this.dataService.post2({ data: data, loading: true });
			let enrollmentId = res.result.enrollment_id
			this.dataService.post('ROEnroll.get_enrollment_details_for_map', [enrollmentId]).subscribe((res: any) => {
				res.course_summary ? res.course_summary.star ? this.totalStarNumber = res.course_summary.star : this.totalStarNumber = 0 : this.totalStarNumber = 0;
			})
		} catch (error) {
			console.log(error)
		}
	}

	setEDBObjective() {
		if (this.studentLevel === "p4" || this.studentLevel === "p5" || this.studentLevel === "p6") {
			this.objective = [
				{number: 1, content: "Use appropriate register when speaking to familiar interlocutors such as teachers and peers"},
				{number: 2, content: "Apply grammar rules such as subject-verb agreement correctly"},
				{number: 3, content: "Connect ideas by using cohesive devices"},
				{number: 4, content: "Use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings"},
				{number: 5, content: "Open an interaction by greeting someone in an appropriate manner"},
				{number: 6, content: "Open an interaction by introducing oneself giving some details"},
				{number: 7, content: "Open an interaction by eliciting a response by asking questions or providing information on a topic"},
				{number: 8, content: "Maintain an interaction by controlling participation in an interaction or group activities"},
				{number: 9, content: "Maintain an interaction by recognizing others' desire to speak"},
				{number: 10, content: "Maintain an interaction by asking and responding to others' opinions"},
				{number: 11, content: "Maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate"},
				{number: 12, content: "Maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood"},
				{number: 13, content: "Maintain an interaction by predicting the likely development of a conversation and responding accordingly"},
				{number: 14, content: "Close an interaction by using appropriate formulaic expressions"},
				{number: 15, content: "Close an interaction by giving reasons"},
			];
		} else if (this.studentLevel === 's1' || this.studentLevel === 's2' || this.studentLevel === 's3') {
			this.objective = [
				{number: 1, content: "convey ideas and information in conversations or discussions"},
				{number: 2, content: "use words and expressions appropriate to the context"},
				{number: 3, content: "use appropriate discourse markers"},
				{number: 4, content: "use correct pronunciation, intonation and register for different purposes"},
				{number: 5, content: "seek and give clarification, explain what information one requires and why, rephrase one's questions when necessary, sum up points made and redirect the discussion when the need arises"},
				{number: 6, content: "make a balanced contribution without either dominating the discussion or being too reticent"},
				{number: 7, content: "express, elicit and respond to ideas, opinions and feelings in a group discussion"},
			];
		}
	}

	setDefaultQuestion() {
		if (this.studentLevel === 'p4') {
			if (this.subType === "oralVocab") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Restaurant",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/restaurant.png"
						},
						{
							name: "Waiter",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/waiter.png"
						},
						{
							name: "Breakfast",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/breakfast.png"
						},
						{
							name: "Lunch",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/lunch.png"
						},
						{
							name: "Dinner",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/dinner.png"
						},
						// {
						// 	name: "Cup",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/cup.png"
						// },
						// {
						// 	name: "Bottle",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/bottle.png"
						// },
						// {
						// 	name: "Glass",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/glass.png"
						// },
						// {
						// 	name: "Plate",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/plate.png"
						// },
						// {
						// 	name: "Taste",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/taste.png"
						// },
						// {
						// 	name: "Sweet",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/sweet.png"
						// },
						// {
						// 	name: "Spicy",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/spicy.png"
						// },
						// {
						// 	name: "Juice",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/juice.png"
						// },
						// {
						// 	name: "Coffee",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/coffee.png"
						// },
						// {
						// 	name: "Egg",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/egg.png"
						// },
					]
				} else {
					this.defaultQuestion = [
						{
							name: "Chef",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
						},
						{
							name: "Organic",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
						},
						{
							name: "Menu",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
						},
						{
							name: "Condiment",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
						},
						{
							name: "Brunch",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
						},
						// {
						// 	name: "Omelette",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/omelette.png"
						// },
						// {
						// 	name: "Stir-fry",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/stir-fry.png"
						// },
						// {
						// 	name: "Order",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/order.png"
						// },
						// {
						// 	name: "Savour",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/savour.png"
						// },
						// {
						// 	name: "Feast",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/feast.jpg"
						// },
						// {
						// 	name: "Serve",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/serve.png"
						// },
						// {
						// 	name: "Etiquette",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/etiquette.png"
						// },
						// {
						// 	name: "Tender",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/tender.png"
						// },
						// {
						// 	name: "Crispy",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/crispy.png"
						// },
						// {
						// 	name: "Buffet",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/buffet.png"
						// },
					]
				}
			} else if (this.subType === "oralSentence") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "This restaurant is always open and serves breakfast, lunch and dinner."
						},
						{
							name: "Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?"
						},
						{
							name: "This special curry dish tastes both sweet and spicy."
						},
						{
							name: "Careful, sir. The plate is hot."
						},
						{
							name: "There is an extra charge if you wish to bring your own bottle of wine."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "I would like to order some crispy chicken to go along with my stir-fried."
						},
						{
							name: "The brunch menu says the omelette is their specialty."
						},
						{
							name: "This buffet is a feast, and I intend to savour every bite."
						},
						{
							name: "My compliments to the chef, for preparing such a tender steak."
						},
						{
							name: "It is typical restaurant etiquette to be seated before ordering."
						}
					]
				}
			} else if (this.subType === "oralFillIn") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "My wife and I would like a table for (____) please.",
							reading: "My wife and I would like a table for two please.",
							answer: "Two"
						},
						{
							name: "Careful, sir. The (____) is hot.",
							reading: "Careful, sir. The plate is hot.",
							answer: "Plate"
						},
						{
							name: "Enjoy your (____), sir.",
							reading: "Enjoy your meal, sir.",
							answer: "Meal"
						},
						{
							name: "May I have a (____) of water, please?",
							reading: "May I have a glass of water, please?",
							answer: "Glass"
						},
						{
							name: "We just stopped (____) breakfast, sir.",
							reading: "We just stopped serving breakfast, sir.",
							answer: "Serving"
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "I would like some deep-fried (____) chicken.",
							reading: "I would like some deep-fried crispy chicken.",
							answer: "Crispy"
						},
						{
							name: "All our foods are sourced locally, completely (____) without chemicals.",
							reading: "All our foods are sourced locally, completely organic without chemicals.",
							answer: "Organic"
						},
						{
							name: "We do not serve (____) to minors.",
							reading: "We do not serve alcohol to minors.",
							answer: "Alcohol"
						},
						{
							name: "Ketchup, mustard, and mayonnaise are different kinds of (____).",
							reading: "Ketchup, mustard, and mayonnaise are different kinds of condiments.",
							answer: "Condiments"
						},
						{
							name: "It's bad (____) to chew with your mouth open.",
							reading: "It's bad etiquette to chew with your mouth open.",
							answer: "Etiquette"
						},
					]
				}
			} else if (this.subType === "oralMc") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Good morning. Would you like some water?",
							choice: ["I don't drink water.", "Yes, I would like some water."],
							answer: "Yes, I would like some water."
						},
						{
							name: "Are you ready to order?",
							choice: ["Not yet. Could you give me a few more minutes?", "I don't want to order."],
							answer: "Not yet. Could you give me a few more minutes?"
						},
						{
							name: "How is your meal?",
							choice: ["It is yum yum, so no thank you.", "It's delicious, thank you for asking."],
							answer: "It's delicious, thank you for asking."
						},
						{
							name: "Is there anything else I can get you?",
							choice: ["No, thank you.", "No, I want more to drink."],
							answer: "No, thank you."
						},
						{
							name: "Would you like the bill?",
							choice: ["Yes, please. I would like to pay by credit card.", "Yes, but I don't want to pay."],
							answer: "Yes, please. I would like to pay by credit card."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "I'm sorry sir, but we are fully booked.",
							choice: ["I don't care. I demand to be seated!", "That's alright. How long do I need to wait, then?"],
							answer: "That's alright. How long do I need to wait, then?"
						},
						{
							name: "We don't start serving lunch until noon.",
							choice: ["I see. I'll have some breakfast instead, then.", "Can't you make an exception for me?"],
							answer: "I see. I'll have some breakfast instead, then."
						},
						{
							name: "Excuse me, sir, but your credit card has been declined.",
							choice: ["That's your problem, not mine.", "I will pay by cash, then."],
							answer: "I will pay by cash, then."
						}
					]
				}
			} else if (this.subType === "oralParagraph") {
				this.defaultQuestion = [
					{
						name: `Tonight Mum taught me how to make pumpkin soup.`,
					},
					{
						name: `First, I heated the butter on a saucepan over medium heat.`,
					},
					{
						name: `When it melted, Mum added some chopped onion and cooked them for two minutes.`,
					},
					{
						name: `Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat.`,
					},
				]
			}
		} else if (this.studentLevel === 'p5') {
			if (this.subType === "oralVocab") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Market",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/restaurant.png"
						},
						{
							name: "Street",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/waiter.png"
						},
						{
							name: "Island",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/breakfast.png"
						},
						{
							name: "Tour",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/lunch.png"
						},
						{
							name: "Mountain",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/dinner.png"
						},
						// {
						// 	name: "Hill",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/cup.png"
						// },
						// {
						// 	name: "Walk",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/bottle.png"
						// },
						// {
						// 	name: "Map",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/glass.png"
						// },
						// {
						// 	name: "Journey",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/plate.png"
						// },
						// {
						// 	name: "Trip",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/taste.png"
						// },
						// {
						// 	name: "Ferry",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/sweet.png"
						// },
						// {
						// 	name: "Attractions",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/spicy.png"
						// },
						// {
						// 	name: "Tourist",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/juice.png"
						// },
						// {
						// 	name: "Crowded",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/coffee.png"
						// },
						// {
						// 	name: "Countryside",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/egg.png"
						// },
					]
				} else {
					this.defaultQuestion = [
						{
							name: "Reservoir",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
						},
						{
							name: "Shed",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
						},
						{
							name: "Promenade",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
						},
						{
							name: "Avenue",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
						},
						{
							name: "Peninsula",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
						},
						// {
						// 	name: "Amble",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/omelette.png"
						// },
						// {
						// 	name: "Stroll",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/stir-fry.png"
						// },
						// {
						// 	name: "Itinerary",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/order.png"
						// },
						// {
						// 	name: "Destination",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/savour.png"
						// },
						// {
						// 	name: "Budget",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/feast.jpg"
						// },
						// {
						// 	name: "Landmark",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/serve.png"
						// },
						// {
						// 	name: "Barren",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/etiquette.png"
						// },
						// {
						// 	name: "Urban",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/tender.png"
						// },
						// {
						// 	name: "Remote",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/crispy.png"
						// },
						// {
						// 	name: "Vacation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/buffet.png"
						// },
					]
				}
			} else if (this.subType === "oralSentence") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "The market is at the end of the street."
						},
						{
							name: "Let's take a walk around the countryside."
						},
						{
							name: "I hope the attractions aren't too crowded."
						},
						{
							name: "The island can only be reached by ferry."
						},
						{
							name: "I can't find the main street on the map."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "The peninsula is very remote and difficult to reach."
						},
						{
							name: "I managed to plan an itinerary for our vacation according to our budget."
						},
						{
							name: "Taking a stroll on the promenade clears my head."
						},
						{
							name: "The shed over there is an urban landmark."
						},
						{
							name: "We have finally arrived at our destination."
						}
					]
				}
			} else if (this.subType === "oralFillIn") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "The only way to get to Cheung Chau is by (____).",
							reading: "The only way to get to Cheung Chau is by ferry.",
							answer: "Ferry"
						},
						{
							name: "It's not the destination so much as the (____).",
							reading: "It's not the destination so much as the journey.",
							answer: "Journey"
						},
						{
							name: "Causeway Bay is in Hong Kong (____).",
							reading: "Causeway Bay is in Hong Kong Island.",
							answer: "Island"
						},
						{
							name: "We can buy traditional clothes in Stanley (____).",
							reading: "We can buy traditional clothes in Stanley Market",
							answer: "Market"
						},
						{
							name: "The International Commerce (____) is the tallest building in Hong Kong.",
							reading: "The International Commerce Centre is the tallest building in Hong Kong.",
							answer: ""
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "The area over there is a (____) wasteland.",
							reading: "The area over there is a barren wasteland.",
							answer: "Barren "
						},
						{
							name: "People get their arms stuck in Cheung Po Tsai (____) Cave.",
							reading: "People get their arms stuck in Cheung Po Tsai Pirate Cave.",
							answer: "Pirate"
						},
						{
							name: "You can find delicious cuisine in Chungking (____).",
							reading: "You can find delicious cuisine in Chungking Mansion.",
							answer: "Mansion"
						},
						{
							name: "The Victoria (____) is a unique scenery of Hong Kong.",
							reading: "The Victoria Harbour is a unique scenery of Hong Kong.",
							answer: "Harbour"
						},
						{
							name: "I have never actually visited the Plover Cove (____).",
							reading: "I have never actually visited the Plover Cove Reservoir.",
							answer: "Reservoir"
						},
					]
				}
			} else if (this.subType === "oralMc") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Should we take the bus or the MTR?",
							choice: ["It doesn't matter.", "Let's take the bus."],
							answer: "Let's take the bus."
						},
						{
							name: "The Tai Tong EcoPark is beautiful.",
							choice: ["Yes, the trees are terrible.", "I agree. The view is stunning."],
							answer: "I agree. The view is stunning."
						},
						{
							name: "Are you tired yet?",
							choice: ["Yes, this trail is too steep.", "Yes, let's walk faster."],
							answer: "Yes, this trail is too steep."
						},
						{
							name: "Should we take some photos?",
							choice: ["Yes, please. Did you bring your camera?", "No! It's dumb to take photos."],
							answer: "Yes, please. Did you bring your camera?"
						},
						{
							name: "Can you see the sunset?",
							choice: ["Yes, it's breathtaking.", "No, I don't really care about it."],
							answer: "Yes, it's breathtaking."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "Excuse me, can you show me the way to the promenade?",
							choice: ["Ask another person.", "Yes, sure. Just turn left and walk across the park, and you will see it.", "I'm busy, just go away.", "The Big Buddha is very near."],
							answer: "Yes, sure. Just turn left and walk across the park, and you will see it."
						},
						{
							name: "What can we buy at the Jade Market?",
							choice: ["We can buy accessories there.", "I don't know.", "Do your own research, I'm not going to answer your question.", "We can buy some Marvel movie tapes."],
							answer: "We can buy accessories there."
						},
						{
							name: "Sorry, you are not allowed to enter this building unless you have a permit.",
							choice: ["No way! I've planned this trip for so long.", "Okay, bye-bye.", "I forgot about this. Can you teach me how?", "Are you kidding me?"],
							answer: "I forgot about this. Can you teach me how?"
						},
						{
							name: "Please do not touch any exhibits with your hand.",
							choice: ["I paid for the ticket! I have the right!", "Those exhibits are disgusting. Ew!", "Ok, Thank you for the reminder.", "You are welcome."],
							answer: "Ok, Thank you for the reminder."
						},
						{
							name: "There are a lot of interactive devices here in Tai Kwun.",
							choice: ["Ok. The devices are shut down.", "Wow! That is impressive. Should we give them a try?", "Tai Kwun is too far. I'm lazy.", "Yes. Tai Kwun used to be a police station."],
							answer: "Wow! That is impressive. Should we give them a try?"
						}
					]
				}
			} else if (this.subType === "oralParagraph") {
				this.defaultQuestion = [
					{
						name: `Whenever I'm on holiday, I want to see the sights.`,
					},
					{
						name: `So I find the mode of transport to take me where I like.`,
					},
					{
						name: `Sometimes it's an aeroplane, if I'm going far.`,
					},
					{
						name: `But if it's a short distance, I may just take a car.`,
					},
					{
						name: `For the times I'm in New York, I'll take a yellow cab.`,
					},
					{
						name: `Or if I go to Hong Kong, it's the MTR I'll grab.`,
					},
					// {
					// 	name: `In the Alps in Switzerland, it snows, so I can ski.`,
					// },
					// {
					// 	name: `But if I'm going hiking, my feet are good enough for me!`,
					// }
				]
			}
		} else if (this.studentLevel === 's1') {
			if (this.subType === "oralVocab") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Examinations",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/examination.png"
						},
						{
							name: "Homework",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/homework.png"
						},
						{
							name: "Assignment",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/assignment.png"
						},
						{
							name: "Sponsorship",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/sponsorship.png"
						},
						{
							name: "Stressful",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/stressful.png"
						},
						// {
						// 	name: "Anxiety",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/anxiety.png"
						// },
						// {
						// 	name: "Peer",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/peer.png"
						// },
						// {
						// 	name: "Grades",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/grades.png"
						// },
						// {
						// 	name: "Tuition",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/tuition.png"
						// },
						// {
						// 	name: "Failure",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/failure.png"
						// },
						// {
						// 	name: "Expectation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/expectation.png"
						// },
						// {
						// 	name: "Competition",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/competition.png"
						// },
						// {
						// 	name: "Support",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/support.png"
						// },
						// {
						// 	name: "Exercises",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/exercises.png"
						// },
						// {
						// 	name: "Break",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/easy/break.png"
						// },
					]
				} else {
					this.defaultQuestion = [
						{
							name: "Productivity",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/productivity.png"
						},
						{
							name: "Motivation",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/motivation.png"
						},
						{
							name: "Distraction",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/distraction.png"
						},
						{
							name: "Procrastination",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/procrastination.png"
						},
						{
							name: "Time-management",
							url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/time-management.png"
						},
						// {
						// 	name: "Schedule",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/schedule.png"
						// },
						// {
						// 	name: "Counselor",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/counselor.png"
						// },
						// {
						// 	name: "Communication",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/communication.png"
						// },
						// {
						// 	name: "Meditation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/meditation.png"
						// },
						// {
						// 	name: "Guidance",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/guidance.png"
						// },
						// {
						// 	name: "Concentration",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/concentration.png"
						// },
						// {
						// 	name: "Relaxation",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/relaxation.png"
						// },
						// {
						// 	name: "Burnout",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/burnout.png"
						// },
						// {
						// 	name: "Reward",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/reward.png"
						// },
						// {
						// 	name: "Visualization",
						// 	url: "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/visualization.png"
						// },
					]
				}
			} else if (this.subType === "oralSentence") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "Pay attention in class."
						},
						{
							name: "I enjoy English lessons."
						},
						{
							name: "I am nervous about the exams."
						},
						{
							name: "We couldn't agree on a topic for our group project."
						},
						{
							name: "My dog ate my homework."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "My teachers will regard me as a failure if I don't do well on the test."
						},
						{
							name: "I have scheduled an appointment for you with the school counselor."
						},
						{
							name: "Stop procrastinating and get on with your work!"
						},
						{
							name: "You're distracting your sister from her studies."
						},
						{
							name: "Sometimes, I relax by visualizing what I'd like to be in the future."
						}
					]
				}
			} else if (this.subType === "oralFillIn") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "We should not give in to (_____) pressure.",
							reading: "We should not give in to peer pressure.",
							answer: "peer"
						},
						{
							name: "The teacher gave us too much (_________).",
							reading: "The teacher gave us too much homework.",
							answer: "homework"
						},
						{
							name: "You should (______) your friends when they are down.",
							reading: "You should support your friends when they are down.",
							answer: "support"
						},
						{
							name: "I need to get more (_______) so I can get stronger.",
							reading: "I need to get more exercises so I can get stronger.",
							answer: "exercises"
						},
						{
							name: "Having good (______) isn't everything.",
							reading: "Having good grades isn't everything.",
							answer: "grades"
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "Some students need the proper (________) to succeed.",
							reading: "Some students need the proper motivation to succeed.",
							answer: "motivation"
						},
						{
							name: "A (_______) calms your mind and focuses your senses.",
							reading: "A meditation calms your mind and focuses your senses.",
							answer: "meditation"
						},
						{
							name: "Don't work too hard, or you'll suffer from (______).",
							reading: "Don't work too hard, or you'll suffer from burnout.",
							answer: "burnout"
						},
						{
							name: "The problem we have is a lack of proper (_________).",
							reading: "The problem we have is a lack of proper communication.",
							answer: "communication"
						},
						{
							name: "I would like to claim the (_______) for finding your lost wallet.",
							reading: "I would like to claim the reward for finding your lost wallet.",
							answer: "reward"
						},
					]
				}
			} else if (this.subType === "oralMc") {
				if (this.difficulty !== "hard") {
					this.defaultQuestion = [
						{
							name: "We've been studying for an hour. Would you like a rest?",
							choice: ["Yes, a short break will do me wonders.", "No, I can keep going until my eyes bleed!"],
							answer: "Yes, a short break will do me wonders."
						},
						{
							name: "Can you afford this university? It has a high tuition fee.",
							choice: ["I think so after I apply for sponsorship.", "Don't worry, we're gonna be rich after I hit the casino."],
							answer: "I think so after I apply for sponsorship."
						},
						{
							name: "How should I deal with my anxiety levels?",
							choice: ["Just ignore it, it will go away.", "Try taking deep breaths and talking with someone."],
							answer: "Try taking deep breaths and talking with someone."
						},
						{
							name: "It's Friday. Should I do my homework now or later?",
							choice: ["Do it now, so you can enjoy your weekend.", "It's Friday, enjoy yourself! Your homework will still be there tomorrow."],
							answer: "Do it now, so you can enjoy your weekend."
						},
						{
							name: "I had good grades last time. Does that mean I don't need to study?",
							choice: ["Why study when you can play video games instead?", "You need to study in order to keep up your grades."],
							answer: "You need to study in order to keep up your grades."
						}
					]
				} else {
					this.defaultQuestion = [
						{
							name: "How could I communicate better as a group leader?",
							choice: [
								"Just keep quiet and leave everyone else alone.",
								"Be as loud as possible and shout if you want.",
								"Make sure everyone is heard and let them feel important.",
								"Who wants to be a leader? Step down!",
							],
							answer: "Make sure everyone is heard and let them feel important."
						},
						{
							name: "I'm looking for a guidance counselor. Do you know one?",
							choice: [
								"Her office is back there at the end of the hallway.",
								"I can be your guidance counselor if you want.",
								"You don't need one. Just keep being you!",
								"Do you need guidance? What a lost little lamb.",
							],
							answer: "Her office is back there at the end of the hallway."
						},
						{
							name: "I think I'm suffering from burnout. I feel mentally exhausted.",
							choice: [
								"Be quiet or I will literally burn you.",
								"You need a vacation to clear your mind and relax.",
								"I don't care. Do whatever you want.",
								"Push through it, and you won't feel exhausted anymore.",
							],
							answer: "You need a vacation to clear your mind and relax."
						},
						{
							name: "We need to communicate better. I don't understand what you mean half the time!",
							choice: [
								"I don't understand what you mean either!",
								"That just means we understand each other fine the other half.",
								"You're right. We need to be clear and ask questions if we're not sure.",
								"We're better off not communicating at all.",
							],
							answer: "You're right. We need to be clear and ask questions if we're not sure.\""
						},
						{
							name: "How do I find my motivation in life?",
							choice: [
								"You don't. Just give up.",
								"Just wander through life aimlessly. No one cares.",
								"Copy what other people do, and you'll be fine.",
								"Take your time, explore your interests, and don't give up.",
							],
							answer: "Take your time, explore your interests, and don't give up."
						},
					]
				}
			} else if (this.subType === "oralParagraph") {
				this.defaultQuestion = [
					{
						name: `Classroom Rules for 1A`,
					},
					{
						name: `1. Be polite, courteous and respectful at all times. Treat others the way you want to be treated.`,
					},
					{
						name: `2. If you have something to share with the class or a question to ask during the lesson, raise your hand. Wait until the teacher calls on you.`,
					},
					{
						name: `3. Respect other students' opinions. Listen quietly when others are speaking. You will have your turn to speak, too.`,
					},
					{
						name: `4. Come to class on time and be prepared for each lesson. Have what you need for each lesson on your desk before the teacher comes in.`,
					},
					{
						name: `5. Complete all homework assignments neatly and on time.`,
					},
					{
						name: `6. Respect each other's property. Do not touch what is not yours.`,
					},
					{
						name: `7. Help keep our classroom clean and tidy. Put away things after you use them and throw rubbish into the bin.`,
					},
					{
						name: `8. Do your best and have fun!`,
					},
				]
			}
		}

		this.totalPageNumber = this.defaultQuestion.length;
	}

	nextQuestion() {
		this.progressPercent = `${(this.pageIndex + 1) / this.totalPageNumber}%`;
		this.questionNumber += 1;
		this.context.subject.next({
			type: "action",
			action: "nextQuestion",
		});
	}

	speak() {
		if (this.ttsSetting.text) {
			let data: any = {
				gender: this.ttsSetting.gender,
				speed: this.ttsSetting.speed,
				lang: this.ttsSetting.langType,
				pitch: this.ttsSetting.pitch,
				name: this.ttsSetting.voice,
				style: this.ttsSetting.speakingStyle,
			};
			let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";

			this.ttsSetting.text.forEach((item, index, array) => {
				setTimeout(() => {
					data["txt"] = item;
					this.dataService.get2({ url: url, data: data, jwt: false }).then((res: any) => {
						if (res.token && res.token.url) {
							let statusUrl: string = res.token.url;
							if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
							this.dataService.get2({ url: statusUrl, jwt: false }).then((res: any) => {
								if (res.url) {
									this.audio.setAttribute("src", res.url);
									this.audio.play();
								}
							});
						}
					});
				}, index * 3500);
			})
		} else {
			this.alert.toastError(this.translateText("此難度不會提供朗讀示範", "此难度不会提供朗读示范", "No reading example will be provided in Hard level"));
		}
	}

	record(): Promise<any> {
		if (!this.context.isAnswerEnabled()) {
			return Promise.reject("not allow to answer");
		}

		this.userRecordAnswer = "";

		return (
			this.myRecorder.hasRecording ?
				this.context.service.alertService.confirm("ro.component.recorder.overwrite-recording", true) :
				Promise.resolve(1)
		).then((o: any) => {
			return this.recordNow();
		}).then((data: any) => {
			this.processing = true;
			return this.processRecording(data);
		}).then(
			() => {
				this.processing = false;
			}
		).catch(
			(reason: any) => {
				this.processing = false;
				console.log("record failed", reason);
			}
		)
	}

	finishRecording() {
		this.myRecorder.finishRecording();
	}

	play() {
		if (this.myRecorder && this.myRecorder.hasRecording) {
			this.myRecorder.play();
		}
	}

	private processRecording(data: any): Promise<any> {
		let reference: string = this.context.createReference();
		let uid = this.context.service.dataService.userInfo.uid;
		console.log(data)
		if (this.myData == null) {
			this.fileReference = {
				key: this.douid,
				reference: reference,
				file: data.sound.file,
				index: 0
			};
			this.myData = {
				user: uid,
				key: this.fileReference.key,
				reference: this.fileReference.reference
			}
		} else {
			this.myData.user = uid;
			this.myData.reference = reference;
			this.fileReference = {
				key: this.douid,
				reference: reference,
				file: data.sound.file,
				index: 0
			};
		}

		this.answerChanged = true;

		return this.uploadAsset().then((res) => {
			console.log(data.sound)
			this.upload.uploadFileObject(data.sound.file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;

				// if(environment.production){
				// 	filePath = '//api.openknowledge.hk/RainbowOne/' + res.path;
				// }

				this.assessment(filePath);
			});
		});
	}

	async assessment(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithAnswer.append("json", JSON.stringify([filePath, this.correctAnswer, lang]));
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && this.isRecorded) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);


			const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: true });
			const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: true });
			Promise.all([firstRequest, secondRequest]).then(([firstRequest, secondRequest]) => {
				if (!firstRequest.result || !secondRequest.result ||
					firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
					secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
					clearInterval(recordTimeCount);
				} else {
					clearInterval(recordTimeCount);
					this.foulLanguageCheck(secondRequest.result.NBest[0]);
					this.userRecordAnswer = this.checking(secondRequest.result);

					console.log(this.userRecordAnswer);
					console.log(this.answerWithoutContractions);
					console.log(this.recognitionWordArray);
					console.log(this.foulLanguage);

					// 5 Scores
					this.accuracyScore = firstRequest.result.NBest[0].AccuracyScore;
					this.completenessScore = firstRequest.result.NBest[0].CompletenessScore;
					this.fluencyScore = firstRequest.result.NBest[0].FluencyScore;
					this.confidence = Math.round(firstRequest.result.NBest[0].Confidence * 100);
					this.pronScore = Math.round(firstRequest.result.NBest[0].PronScore);
					this.isRecorded = true;

					// save result into myData
					let stringifyResult = JSON.stringify(firstRequest.result.NBest[0]);
					let uriEncodeResult = encodeURIComponent(stringifyResult);
					let encodeResult = btoa(uriEncodeResult);
					this.myData.record = encodeResult;
					this.myData.userRecordAnswer = this.userRecordAnswer;
					this.myData.answerWithoutContractions = this.answerWithoutContractions;
					this.myData.isRecorded = this.isRecorded;
					if (this.foulLanguage) this.myData.foulLanguage = this.foulLanguage;

					// user & recognition answer
					// this.isInstruction = false;

					this.autoSubmit();

					if (this.subType === "sentence" || this.subType === "paragraph") {
						this.recognitionAnswer = firstRequest.result.NBest[0].Words;
						this.handleRecognitionWord();
					}
				}
			})
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	autoSubmit() {
		console.log(this.myData)
		this.context.subject.next({
			type: "action",
			action: "submitAfterRecord",
		});
	}

	handleRecognitionWord() {
		this.recognitionWordArray = [];

		for (let word of this.recognitionAnswer) {
			this.recognitionWordArray.push(word)
		}
	}

	foulLanguageCheck(record) {
		let isFoulLanguage;
		let foulList = ["*", "damn"];
		
		try {
			if (record.MaskedITN) isFoulLanguage = foulList.some(item => record.MaskedITN.includes(item));
			if (isFoulLanguage) this.foulLanguage.push({ content: record.ITN, name: this.dataService.userInfo.nickname });
		} catch (error) {
			console.error("Invalid record format", error);	
		}
	}

	checking(record) {
		console.log(record)
		let isContractions = this.contractions.some(item => this.correctAnswer.includes(item.label));
		this.answerWithoutContractions = this.correctAnswer;
		if (isContractions) {
			this.contractions.forEach(item => {
				this.answerWithoutContractions = this.answerWithoutContractions.replace(new RegExp(item.label, 'g'), item.value);
			});
		}

		let checkingNumber = parseInt(record.DisplayText.slice(0, -1))
		if (checkingNumber && record.NBest[0].AccuracyScore > this.ctx.passLevel || this.subType === "mc") {
			let capitalize = record.NBest[0].Lexical.charAt(0).toUpperCase() + record.NBest[0].Lexical.substring(1);
			return capitalize;
		} else {
			return record.DisplayText.slice(0, -1);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	async buttonAction(data: any) {
		console.log(data)
		if (data.action === "close") {
			if (this.subType === "oralAI") {
				try {
					const res = await this.scoreCoversation(this.conversationPracticeContainer.conversation);
					let content = JSON.parse(res).choices[0].message.content;
					if (typeof (content) === "string") {
						try {
							content = JSON.parse(content);
						} catch (error) {
							// var json: string = content.replace(/[\r\n]/g, " ").replace(/```.*?({.*})```/)
							var parts: string[] = content.split("```", 3);
							if (parts.length == 3) {
								var found: any = parts[1].match(/{.*}/)
								content = JSON.parse(found[0]);
								console.log(content);
							}
						}
					}
					this.aiOralfinalScore = content;
					console.log("score obj", typeof (content), content);
				} catch (error) {
					console.log(error);
				}
			}
			this.close(data.isFinalReportOpen, data.userTotalScore)
		} else if (data.action === 'reTry' && (this.subType === "paragraph" || this.conversationType.includes(this.subType))) {
			this.reTry()
		} else if (data.action === 'exercisePracticeInstructionClose') {
			this.isInstruction = false;
		} else if (data.action === "loadAudio") {
			this.isLoadAudio = true;
		} else {
			this.context.subject.next({
				type: "action",
				action: "reTry",
			});
		}
	}

	close(isFinalReportOpen: boolean, userTotalScore: number) {
		console.log(isFinalReportOpen, userTotalScore)
		if (isFinalReportOpen) {
			this.isFinalReportOpen = isFinalReportOpen;
			this.userTotalScore = userTotalScore;
		} else {
			this.context.subject.next({
				type: "action",
				action: "close",
			});
		}
	}

	reTry() {
		if (this.conversationType.includes(this.subType)) {
			this.redoConversation = true;
			this.isFinalReportOpen = false;
			this.isConversationReportOpen = false;
			this.pageIndex = 0;
		} else {
			this.isRecorded = false;
			this.isFinalReportOpen = false;
			this.questionNumber = 1;
		}
	}

	private recordNow(): Promise<any> {
		this.myData = null;
		// this.showPercent = false;
		return this.myRecorder.record();
	}

	// Add tag name of the component into object for comparison
	public getTagNames(): string[] {
		return ["AITerrence", "SpeechPractice"];
	}

	public get data(): string {
		if (this.myData) {
			return this.objectToString2(this.myData);
		}
		return null;
	}

	// after assign answer
	protected onAnswerSet(): void {
		if (this.myData) {
			this.loadFile(this.myData);
		}
	}

	loadFile(obj: any): void {
		if (obj && obj.hasOwnProperty("key") && obj.hasOwnProperty("reference")) {
			if (this.context.config.viewMode == "scoring") {
				this.context.loadStudentFile(
					<ROPageComponent>this.page,
					this,
					this.context.config.student.uid,
					obj, 0
				).then((url: string) => {
					this.myRecorder.setupAudioFromURL(url);
				});
			} else if (this.context.config.viewMode == "view" || this.context.config.viewMode == "review") {
				this.context.loadFile(<ROPageComponent>this.page, this, obj).then((url: string) => {
					this.myRecorder.setupAudioFromURL(url);
				});
			}
		} else
			this.myRecorder.setupAudioFromURL(null);
	}

	private uploadAsset(): Promise<any> {
		return this.context.uploadComponentAsset(this);
	}

	public getAnswerAssets(): any[] {
		if (this.answerChanged) return [this.fileReference];
		return null;
	}

	// ======================================================
	// data function
	// ======================================================

	// 判斷是否需要記錄答案
	public isQuestionComponent(): boolean {
		return true;
	}
	
	// 判斷是否需要核對(總結result object)答案
	public canVerify(): boolean {
		return true;
	}

	// 總結result object
	public verify(_showScoring: boolean): any {
		let score: Number;
		let correct: number = -1;
		if (this.pronScore) {
			if (this.pronScore == 100) {
				correct = 2;
			} else if (this.pronScore == 0) {
				correct = 0;
			} else {
				correct = 1;
			}

			if (this.exerciseType.includes(this.subType)) {
				this.resultObject = {
					score: this.totalScoreInEachQuestion * this.pronScore / 100,
					maxScore: this.totalScoreInEachQuestion,
					scorePer: this.pronScore,
					correct: correct,
					correction: false,
					"2280-1": this.pronScore,
					"2280-2": this.accuracyScore,
					"2280-3": this.completenessScore,
					"2280-4": this.fluencyScore,
				};
			} else {
				this.resultObject = {
					score: this.totalScoreInEachQuestion * this.pronScore / 100,
					maxScore: this.totalScoreInEachQuestion,
					scorePer: this.pronScore,
					correct: correct,
					correction: false,
					"2280-1": this.pronScore,
					"2280-2": this.accuracyScore,
					"2280-3": this.completenessScore,
					"2280-4": this.fluencyScore,
					"2822-1": this.myData.EDBScore[0],
					"2822-2": this.myData.EDBScore[1],
					"2822-3": this.myData.EDBScore[2],
					"2822-4": this.myData.EDBScore[3],
					"2822-5": this.myData.EDBScore[4],
					"2822-6": this.myData.EDBScore[5],
					"2822-7": this.myData.EDBScore[6],
					"2822-8": this.myData.EDBScore[7],
					"2822-9": this.myData.EDBScore[8],
					"2822-10": this.myData.EDBScore[9],
					"2822-11": this.myData.EDBScore[10],
					"2822-12": this.myData.EDBScore[11],
					"2822-13": this.myData.EDBScore[12],
					"2822-14": this.myData.EDBScore[13],
					"2822-15": this.myData.EDBScore[14],
				};
			}


			this.correctState = correct;
		} else {
			this.resultObject = null;
			score = 0;
			correct = 0;
		}
		this.correctState = _showScoring ? correct : -1;
		return this.resultObject;
	}

	private mathRound(n: number, ratio: number): number {
		return Math.round(n / ratio) * ratio;
	}

	public correctState: number;

	public defaultAnswer: any = null;

	public hideDefaultAnswer(): void {
		this.defaultAnswer = null;
		if (this.sObj)
			this.sObj.elementRef.nativeElement.style.display = null;
	}

	public showDefaultAnswer(): void {
		this.defaultAnswer = true;
		if (this.sObj)
			this.sObj.elementRef.nativeElement.style.display = 'none';
	}



	closeFromAIOral($event) {
		if (this.coms.sectionComponent && location.href.indexOf('/bookViewer') > -1) {
			this.coms.sectionComponent.roBookViewer.close();
		}
		// this.context.subject.next({
		// 	type:"action", 
		// 	action:"answerChanged",
		// 	data: this
		// });
	}

	scoreCoversation(conversation, criteria = ["content", "grammer", "creativity"]) {
		let criteriaString = criteria.join(",");
		const scoreContent = [...conversation.filter(message => !message.isHint)];
		scoreContent.push(
			{
				role: 'user',
				content: `score the conversation based on " + ${criteriaString} + " in x out of 10. return with the json format: {
					content: x,
					grammer: x,
					creativity: x
					}`,
			}
		);
		return this.chat.oralChat(scoreContent);
	}
}
