import {Component, Input, OnInit, ViewChild, Output, EventEmitter} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../service/data.service";
import {Modal2Component} from "../modal2Module/modal2.component";
import {WebLessonService} from "../../coreModule/lessonModule/WebLessonModule/services/WebLesson.service";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: 'toolbar-setting-modal',
	template: `
		<modal2
			#modal [title]="trans.instant('bookviewer.toolbar_setting.title')"
			(cancelClick)="confirm()"
			(confirmClick)="confirm()"
			[width]="'calc(100% - 40px)'"
			[ngClass]="current_tab_key"
		>
			<div class="tab-container">
				<tab-view
					[tabs]="tabs"
					[current_key]="current_tab_key"
					(tab_changed)="current_tab_key = $event.key"
					[ngClass]="current_tab_key"
				></tab-view>
			</div>
			<div class="tab-content">
				<ng-container *ngIf="current_tab_key === 'teacher'">
					<div class="tools">
						<ng-container *ngFor="let tool of all_teacher_toolbar_tools">
							<ng-container *ngTemplateOutlet="tool_item; context: {tool: tool, tools: teacher_toolbar_tools}"></ng-container>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="current_tab_key === 'student'">
					<div class="tools">
						<ng-container *ngFor="let tool of all_student_toolbar_tools">
							<ng-container *ngTemplateOutlet="tool_item; context: {tool: tool, tools: student_toolbar_tools}"></ng-container>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="current_tab_key === 'settings'">
					<div class="settings">
						<div class="setting" *ngIf="web_lesson.lesson_id && web_lesson.is_teacher">
							<div class="setting-label">
								{{ 'bookviewer.toolbar_setting.page_sync_method' | translate }}
							</div>
							<div class="setting-options">
								<radioBox
									[(value)]="web_lesson.lesson_state.page_sync_method"
									[options]="page_sync_method_options"
									(valueChange)="web_lesson.update_lesson_state();"
								></radioBox>
							</div>
						</div>
						<div class="setting">
							<div class="setting-label">
								{{ 'bookviewer.toolbar_setting.ui_size' | translate }}
							</div>
							<div class="setting-options">
								<radioBox
									[value]="ui_size"
									(valueChange)="ui_sizeChange.emit($event)"
									[options]="ui_size_options"
								></radioBox>
							</div>
						</div>
					</div>
				</ng-container>
			</div>
		</modal2>
		
		<ng-template #tool_item let-tool="tool" let-tools="tools">
			<div class="tool"
				 [class.active]="tools.includes(tool)"
				 [ngClass]="['app-' + tool]"
				 (click)="toggle_tool(tools, tool)"
				 [ngStyle]="get_tool_style(tool)"
			>
				<div class="selected-check">
					<fa-icon [icon]="faCheck" *ngIf="tools.includes(tool)"></fa-icon>
				</div>
				<div class="tool-name">{{ ('bookviewer.tools.' + tool) | translate }}</div>
			</div>
		</ng-template>
	`,
	styles: [`
        :host ::ng-deep {
            modal2 {
                --popupform-allmargin: 0 !important;
                z-index: 100000 !important;

                .modal {
                    overflow: hidden;
                    max-height: calc(100vh - 40px);
                    display: flex;
                    flex-direction: column;

                    .content {
                        flex: 1;
                        overflow-y: auto;
                    }
                }

                &.teacher {
                    --section-maincolor: #985e28;
                    --section-popupbgcolor: #f0e6d7;
                    --tab-content-color: #ffffff;
                    --tab-content-bg-color: #796252;
                }

                &.student {
                    --section-maincolor: #3a7691;
                    --section-popupbgcolor: #d7ebf0;
                    --tab-content-color: #ffffff;
                    --tab-content-bg-color: #3a7691;
                }

                &.settings {
                    --section-maincolor: #666666;
                    --section-popupbgcolor: #e9e9e9;
                    --tab-content-color: #888888;
                    --tab-content-bg-color: #c4c2c2;
                }
            }

            tab-view {
                &.teacher {
                    --active-bg-color: #985e28;
                }

                &.student {
                    --active-bg-color: #3a7691;
                }

                &.settings {
                    --active-bg-color: #666666;
                }
            }
        }

        .tab-container {
            display: flex;
            justify-content: center;
            padding: 8px;
        }

        .tab-content {
            background: var(--tab-content-bg-color);
            padding: 20px;
            box-shadow: inset 0px 13px 12px -13px rgba(0, 0, 0, 0.3);
            min-height: 140px;
        }

        .tools {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        .tool {
            width: 100px;
            height: 100px;
            cursor: pointer;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            overflow: hidden;
            padding: 5px 5px 9px 5px;
            background-size: 60px 60px;
            background-position: center 16px;
            background-repeat: no-repeat;

            &.app-open-book {
                background-size: 60px auto;
                background-position-y: 26px;
            }
        }

        .selected-check {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .tool-name {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
        }

        .tool.active {
            background-color: rgba(0, 0, 0, 0.2);
        }

        .settings {
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            gap: 1px;

            .setting {
                background: #ffffff;
                display: flex;
                align-items: center;
                padding: 8px 13px;

                &:first-child {
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }

                &:last-child {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }

            .setting-label {
                min-width: 120px;
            }

            .setting-options {
                flex: 1;
            }
        }
	`]
})

export class ToolbarSettingModalComponent implements OnInit {
	public tabs = [];
	public current_tab_key = 'teacher';
	public page_sync_method_options = [];
	public ui_size_options = [];

	@Input() all_teacher_toolbar_tools = [];
	@Input() all_student_toolbar_tools = [];
	@Input() teacher_toolbar_tools = [];
	@Input() student_toolbar_tools = [];
	@Input() ui_size = 1;
	@Output() ui_sizeChange = new EventEmitter();

	@ViewChild('modal', {static: false}) modal: Modal2Component;

	constructor(
		public trans: TranslateService,
		public datas: DataService,
		public web_lesson: WebLessonService,
	) {
		this.trans.use(this.datas.lang).subscribe(() => {
			this.init_tabs();
			this.init_options();
		});
	}

	init_tabs() {
		this.tabs = [];
		let old_tab_key = this.current_tab_key;

		if (this.all_teacher_toolbar_tools.length) {
			this.tabs = [
				...this.tabs,
				{
					key: 'teacher',
					title: this.trans.instant('bookviewer.toolbar_setting.teacher'),
				},
			]
		}
		if (this.all_student_toolbar_tools.length) {
			this.tabs = [
				...this.tabs,
				{
					key: 'student',
					title: this.trans.instant('bookviewer.toolbar_setting.student'),
				},
			]
		}
		this.tabs = [
			...this.tabs,
			{
				key: 'settings',
				title: this.trans.instant('bookviewer.toolbar_setting.settings'),
			}
		];

		let current_tab = this.tabs.find(tab => old_tab_key && tab.key === old_tab_key);
		if (!current_tab) {
			this.current_tab_key = this.tabs[0].key;
		}
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.init_tabs();
	}

	init_options() {
		this.page_sync_method_options = [
			{value: 'none', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_none')},
			{value: 'semi', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_semi')},
			{value: 'full', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_full')},
		];

		let ui_size_options = [];
		ui_size_options.length = 10;
		this.ui_size_options = ui_size_options.fill(null).map((_, i) => {
			return {
				value: i + 1,
				label: `${i + 1}`,
			}
		});
	}

	open() {
		this.current_tab_key = 'teacher';
		this.init_tabs();
		return this.modal.open();
	}

	toggle_tool(tools, tool) {
		if (tools.includes(tool)) {
			tools.splice(tools.indexOf(tool), 1);
		} else {
			tools.push(tool);
		}
		if (this.web_lesson.is_teacher) {
			if (tools === this.student_toolbar_tools) {
				this.web_lesson.lesson_state.current_student_tools = this.web_lesson.lesson_state.current_student_tools.filter(t => {
					return this.student_toolbar_tools.includes(t);
				});
				this.web_lesson.update_lesson_state();
			}
		}
	}

	get_tool_style(tool) {
		let image_path = `./assets/ro/app_icons/app_${tool.split('-').join('_')}.svg`;

		return {
			'background-image': `url(${image_path})`,
		}
	}

	protected readonly faCheck = faCheck;

	confirm() {
		this.modal.close();
		localStorage.setItem("bookviewer_toolbar_settings", JSON.stringify({
			uid: this.datas.userInfo.uid,
			teacher: this.teacher_toolbar_tools,
			student: this.student_toolbar_tools,
			ui_size: this.ui_size,
		}));
	}
}