import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { fromEvent, Observable, Subject, Subscription } from "rxjs";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import { AlertService } from "src/app/service/alert.service";
import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { ThemeService } from "src/app/service/theme.service";
import { BubbleBox2Component } from "../bubbleBox2Module/bubbleBox2.component";
import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { ROContext } from "./ROContext";
import { XMLNode } from "./xml/XMLNode";
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { ROScoreCom } from "./ROScoring";
import { ArrayUtils } from "src/app/common/ArrayUtils";
import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { DragManager } from "./DragManager";
import { DOMHelper } from "src/app/common/DOMHelper";
import { SmoothDrawing } from "./epen/SmoothDrawing";
import { ByteArray, Endian } from "openfl";
import { ByteArrayUtils } from "src/app/ro/hk/openknowledge/utils/ByteArrayUtils";
import { SafeStyle } from "@angular/platform-browser";
import { debounceTime } from "rxjs/operators";


class KeyboardEventTool{
	static match(regExpArray:RegExp[], text:string):any{
		var len:number = regExpArray.length;
		for(var i = 0;i < len;i++)
		{
			var reg:RegExp = regExpArray[i];
			var m = reg.exec(text);
			if(m) return m;
		}
		return null;
	}
	static toString(event:KeyboardEvent):string
	{
		var pattern = [
			( event.metaKey ? 1 : 0 ) , 
			( event.ctrlKey ? 1 : 0 ) , 
			( event.shiftKey ? 1 : 0 ) , 
			( event.altKey ? 1 : 0 ), 
			event.key
		].join("");
		pattern = pattern.toUpperCase();
		return pattern;
	}
}
interface IROScoreEditPopup
{
	component:ROComponent,
	// node:XMLNode;
	emitter:EventEmitter<any>
	close():void;
}
@Component({
	template:`

	<bubbleBox2 
		#popup
		[padding]="0"
		[showArrow]="true"
		[backgroundColor]="'#2E2E40'"
		
		[clickOutsideHandler]="getClickOutsideHandler()"
		[cancelEvents]="['pointerdown', 'tap', 'mousedown']"
		>
		<div class="tf container">
			<div class="line first-line">
				<div class="first button keyboard-button single-button btn-q" (click)="pressClear()"></div>
				<div class="button keyboard-button single-button mc-button unqualify"  
					[class.selected]="myAnswer == 'U'"
					(click)="pressU()">U</div>
				<div class="answer-button button keyboard-button single-button mc-button" 
					[class.correct]="correctAnswer == 'T'" 
					[class.selected]="myAnswer == 'T'"
					(click)="pressT()">T</div>
				<div class="answer-button button keyboard-button single-button mc-button" 
					[class.correct]="correctAnswer == 'F'" 
					[class.selected]="myAnswer == 'F'"
					(click)="pressF()">F</div>
			</div>

			<div class="line second-line correction-line" >
				<div class="button checkbox" (click)="toggleCorrection()">
					<fa-icon *ngIf="result.correction" [icon]="icon1" [class.checked]="true"></fa-icon>
					<fa-icon *ngIf="!result.correction" [icon]="icon2"></fa-icon>
				</div>
				<div class="button correction-label">{{"ro.page.correction"|translate}}</div>
				<div class="margin-right-auto"></div>
				<div 
					*ngIf="!autoConfirm"
					class="confirm-button" (click)="onConfirm()">{{"commonService.confirm"|translate}}</div>
			</div>
		</div>
	</bubbleBox2>

	`,
	
	styleUrls:["./ROQuestionScore.scss"]
	
})

export class ROScoreTFEditPopup implements OnInit, OnDestroy, AfterViewInit, IROScoreEditPopup
{
	public icon1:any = faCheckCircle;
	public icon2:any = faCheckCircleLight;
	
	@Input() public target:HTMLElement;
	@Input() context:ROContext;
	@ViewChild("popup", {static: false}) public popup: BubbleBox2Component;
	// @ViewChild("input", {static: false}) public input: any;
	@Output() emitter:EventEmitter<any> = new EventEmitter();
	// private sub:Subscription;
	public reference:any;
	@Input() public max:any;
	@Input() public result:any;
	public component:ROComponent;
	public scoreCom:ROScoreCom;
	public score:string;

	public autoConfirm:boolean = true;
	public changed:boolean;

	public correctAnswer:string = null;
	public incorrectAnswer:string = null;
	public myAnswer:string = null;

	onTap(event:any)
	{
		event.preventDefault();
		event.pointers.forEach((pointerEvent:any)=>{
			pointerEvent.stopImmediatePropagation()
		});
	}
	constructor(public alertService:AlertService, public themeService:ThemeService, public dcs:DynamicComponentService)
	{
		// this.sub = new Subscription();
	}
	
	@HostListener('window:keydown', ['$event'])
  	handleKeyboardEvent(event: KeyboardEvent) {
		
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();

		var pattern:string = KeyboardEventTool.toString(event);
		var match:any = KeyboardEventTool.match([/^[0]{4}(U|T|F|ESCAPE|ENTER|BACKSPACE|DELETE)$/], pattern)
		if(match)
		{
			var m = match[1];
			if(m)
			{
				if(m == "ENTER")
				{
					this.onConfirm();
				} else if(m == "ESCAPE")
				{
					this.close();
				} else if(m == "T")
				{
					this.pressT();
				} else if(m == "F")
				{
					this.pressF();
				} else if(m == "U")
				{
					this.pressU();
				} else if( m == "BACKSPACE" || m == "DELETE")
				{
					this.pressClear();
				}
			}
			// console.log("keyboard event", event.key, event.altKey, event.ctrlKey, event.shiftKey);
			// console.log("clear", match);
		}
  	}

	
	ngAfterViewInit(): void {
		var that = this;
		this.scoreCom = <ROScoreCom> this.component;
		setTimeout(()=>{
			var result:any = that.result;
			if(this.scoreCom.ctx.hasOwnProperty("answer"))
			{
				that.correctAnswer = this.scoreCom.ctx.hasOwnProperty("answer") ? this.scoreCom.ctx.answer : "T";
				that.incorrectAnswer = that.correctAnswer == "T" ? "F" : "T";
			} else {
				that.correctAnswer = "T";
				that.incorrectAnswer = "F";
			}
			
			if(result.hasOwnProperty("answer"))
			{
				this.myAnswer = result.answer;
			} else {
				if(result.hasOwnProperty("score"))
				{
					if(result.score > 0)
					{
						this.myAnswer = that.correctAnswer;
					} else {
						this.myAnswer = that.incorrectAnswer;
					}
				} else if(result.unclassified)
				{
					this.myAnswer = "U";
				}
			}
			// this.result.unclassified = 1;
			
			this.popup.open(this.target);
		}, 0);
	}
	
	ngOnInit(): void {
		
		
	}
	getClickOutsideHandler():any{
		return this.clickOutsideHandler.bind(this);
	}
	
	clickOutsideHandler():Promise<any>
	{
		if(this.changed)
		{
			this.onConfirm();
			return Promise.resolve("confirm");
		} else {
			this.onCancel();
			return Promise.reject("cancel");
		}
		
	}
	private onCancel():void
	{
		this.emitter.next({
			type:"cancel"
		});
	}
	
	pressClear():void
	{
		delete this.result.unclassified;
		delete this.result.answer;
		delete this.result.score;
		// this.result.score = null;
		this.result.correct = -1;
		this.result.correction = false;
		if(this.autoConfirm){
			this.onConfirm();
		}
	}

	pressU():void
	{
		this.result.answer = "U";
		this.updateScore();
		if(this.autoConfirm){
			this.onConfirm();
		}
	}

	pressT():void
	{
		delete this.result.unclassified;
		this.result.answer = "T";
		this.updateScore();
		if(this.autoConfirm){
			this.onConfirm();
		}
	}
	
	pressF():void
	{
		delete this.result.unclassified;
		this.result.answer = "F";
		this.updateScore();
		if(this.autoConfirm){
			this.onConfirm();
		}
	}

	private updateScore():void
	{
		if(this.result.answer == "U")
		{
			this.result.unclassified = 1;
			this.result.score = 0;
			this.result.correct = 0;
			this.result.correction = true;
		} else if(this.correctAnswer == this.result.answer)
		{
			delete this.result.unclassified;
			this.result.score = this.max;
			this.result.correct = 2;
			this.result.correction = false;
		} else if(this.incorrectAnswer == this.result.answer)
		{
			delete this.result.unclassified;
			this.result.score = 0;
			this.result.correct = 0;
			this.result.correction = true;
		}
	}
	/*
	pressCorrect():void
	{
		delete this.result.unclassified;
		this.result.score = this.max;
		this.result.correct = 2;
		this.result.correction = false;
		if(this.autoConfirm){
			this.onConfirm();
		}
	}

	pressIncorrect():void
	{
		delete this.result.unclassified;
		this.result.score = 0;
		this.result.correct = 0;
		this.result.correction = true;
		if(this.autoConfirm){
			this.onConfirm();
		}
	}	
	*/
	
	toggleCorrection():void
	{
		this.changed = true;
		if(this.result) this.result.correction = !this.result.correction;
	}

	onConfirm():void
	{
		this.emitter.next({
			type:"confirm", 
			result:this.result
		});
	}
	
///////////
	ngOnDestroy(): void {
		
	}

	close():void
	{
		this.popup.close().then(()=>{
			this.emitter.complete();
		})
	}
}



@Component({
	template:`

	<bubbleBox2 
		#popup
		[padding]="0"
		[showArrow]="true"
		[backgroundColor]="'#2E2E40'"
		
		[clickOutsideHandler]="getClickOutsideHandler()"
		[cancelEvents]="['pointerdown', 'tap', 'mousedown']"
		class="bubble"
		>
		<div 
			[class.all-correct]="allCorrect" 
			[class.partial-correct]="partialCorrect" 
			[class.incorrect]="incorrect" 
			[class.no-evaluation]="noEvaluation" 
			
			class="mc container" [class.multi-select]="multiSelect">
			<div class="line">
				<div class="first button keyboard-button single-button btn-q mc-button" (click)="pressClear()"></div>
				<div class="button keyboard-button single-button mc-button unqualify" (click)="press('U')" [class.selected]="studentHasAnswer('U')">U</div>
				<div class="answer-button button keyboard-button single-button mc-button" (click)="press('A')" [class.correct]="hasAnswer('A')" [class.selected]="studentHasAnswer('A')">A</div>
				<div class="answer-button button keyboard-button single-button mc-button" (click)="press('B')" [class.last]="mcCount == 2" [class.correct]="hasAnswer('B')" [class.selected]="studentHasAnswer('B')">B</div>
				<div class="answer-button button keyboard-button single-button mc-button" (click)="press('C')" [class.last]="mcCount == 3" [class.correct]="hasAnswer('C')" [class.selected]="studentHasAnswer('C')" *ngIf="mcCount >= 3">C</div>
				<div class="answer-button button keyboard-button single-button mc-button" (click)="press('D')" [class.last]="mcCount == 4" [class.correct]="hasAnswer('D')" [class.selected]="studentHasAnswer('D')" *ngIf="mcCount >= 4">D</div>
				<div class="answer-button button keyboard-button single-button mc-button" (click)="press('E')" [class.last]="mcCount == 5" [class.correct]="hasAnswer('E')" [class.selected]="studentHasAnswer('E')" *ngIf="mcCount >= 5">E</div>
				<div class="answer-button button keyboard-button single-button mc-button last" (click)="press('F')" [class.correct]="hasAnswer('F')" [class.selected]="studentHasAnswer('F')" *ngIf="mcCount >= 6">F</div>
				<div 
					*ngIf="multiSelect"
					#customScoreButton 
					class="custom-scrore button keyboard-button single-button mc-button last" 
					(click)="showCustomScore()" 
					
					>{{this.result.score}}</div>
				<div class="margin-right-auto"></div>
			</div>
			<div class="line second-line correction-line" >
				<div class="button checkbox" (click)="toggleCorrection()">
					<fa-icon *ngIf="result.correction" [icon]="icon1" [class.checked]="true"></fa-icon>
					<fa-icon *ngIf="!result.correction" [icon]="icon2"></fa-icon>
				</div>
				<div class="button correction-label">{{"ro.page.correction"|translate}}</div>
				<div class="margin-right-auto"></div>
				<ng-container *ngIf="!autoConfirm">
					<div class="all-correct-button" (click)="pressCorrect()">{{"ro.components.score-com.all-correct"|translate}}</div>
					<div class="confirm-button" (click)="onConfirm()">{{"commonService.confirm"|translate}}</div>
				</ng-container>
			</div>
		</div>
	</bubbleBox2>

	`,
	
	styleUrls:["./ROQuestionScore.scss"]
})

export class ROScoreMCEditPopup implements OnInit, OnDestroy, AfterViewInit, IROScoreEditPopup
{
	
	@ViewChild("customScoreButton", {static: false}) public customScoreButton: ElementRef;
	@Input() public target:HTMLElement;
	@ViewChild("popup", {static: false}) public popup: BubbleBox2Component;
	@Output() emitter:EventEmitter<any> = new EventEmitter();
	@Input() context:ROContext;
	public reference:any;
	@Input() public max:any;
	@Input() public result:any;
	public score:string;
	public component:ROComponent;
	public scoreCom:ROScoreCom;
	public mcCount:number = 5;
	public correctAnswer:string []= [];
	public multiSelect:boolean = false;
	public studentAnswer:string[] = [];
	public icon1:any = faCheckCircle;
	public icon2:any = faCheckCircleLight;
	
	public autoConfirm:boolean;
	public correction:boolean;

	public noEvaluation:boolean = false;
	public allCorrect:boolean = false;
	public partialCorrect:boolean = false;
	public incorrect:boolean = false;
	
	onTap(event:any)
	{
		event.preventDefault();
		event.pointers.forEach((pointerEvent:any)=>{
			pointerEvent.stopImmediatePropagation()
		});
	}
	constructor(public alertService:AlertService, public themeService:ThemeService, public dcs:DynamicComponentService)
	{

	}
	
	@HostListener('window:keydown', ['$event'])
  	handleKeyboardEvent(event: KeyboardEvent) {
		
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();

		var pattern:string = KeyboardEventTool.toString(event);
		var regExp:RegExp = /^[0]{4}([A-F]|U|ESCAPE|ENTER|BACKSPACE|DELETE|ARROWUP)$/;
		var match:any = regExp.exec(pattern);
		if(match)
		{
			var m:string = match[1];
			if(m)
			{
				if(m == "ARROWUP")
				{
					this.pressCorrect();
				} else if(m == "ENTER")
				{
					this.onConfirm();
				} else if(m == "ESCAPE")
				{
					this.close();
				} else if(/^([A-F|U])$/.test(m))
				{
					if(m == "U")
					{
						this.press(m);
					} else {
						var n = this.mcCount - (m.charCodeAt(0) - 65 + 1);
						if(n >= 0) this.press(m);
					}
				} else if( m == "BACKSPACE" || m == "DELETE")
				{
					this.pressClear();
				} else if(/ARROW/.test(m))
				{
					if(m == "ARROWUP") { this.pressCorrect(); } // 
					else if(m == "ARROWDOWN") {} // this.pressClear();
					else if(m == "ARROWLEFT") {} // this.switchTab("fast");
					else if(m == "ARROWRIGHT") {} // this.switchTab("keyboard");
					return ;
				}	
			}
		}
  	}

	ngAfterViewInit(): void {
		this.scoreCom = <ROScoreCom> this.component;
		var that = this;
		setTimeout(()=>{
			// console.log(this.result);
			that.mcCount = that.scoreCom.ctx.mcCount;
			that.correctAnswer = that.scoreCom.ctx.mcAnswer ? that.scoreCom.ctx.mcAnswer.split(",") : [];
			that.multiSelect = that.scoreCom.ctx.multiSelect ? true : false;
			that.popup.open(that.target);
			that.studentAnswer = that.result && that.result.answer ? that.result.answer.split(",") : [];
			that.autoConfirm = ! that.multiSelect;
			that.correction = that.result && that.result.correction ? true : false;
			that.updateColor();
		}, 0);
	}
	
	ngOnInit(): void {
		
		
	}

	studentHasAnswer(ans:string):boolean
	{
		return this.studentAnswer ? this.studentAnswer.indexOf(ans) != -1 : false;
	}
	hasAnswer(ans:string):boolean
	{
		return this.correctAnswer ? this.correctAnswer.indexOf(ans) != -1 : false;
	}
	
	getClickOutsideHandler():any{
		return this.clickOutsideHandler.bind(this);
	}
	
	clickOutsideHandler():Promise<any>
	{
		this.emitter.next({
			type:"cancel"
		});
		return Promise.reject("cancel");
	}
	///////////
	toggleCorrection():void
	{
		if(this.result)
		{
			this.result.correction = !this.result.correction;
		}
		if(this.autoConfirm){
			this.onConfirm();
		}
	}

	
	onConfirm():void
	{
		this.emitter.next({
			type:"confirm", 
			result:this.result
		});
	}
	private toggleElement(array:any[], element):void
	{
		var index = array.indexOf(element)
		if(index  == -1)
		{
			array.push(element);
		} else {
			array.splice(index, 1);
		}
		array.sort();
	}
	
	showCustomScore():void
	{
		this.popup.autoClose = false;
		var sub:Subscription = this.promptCustomScore(this.customScoreButton.nativeElement).subscribe(
		{	
			next:(data)=>{
				if(data.type == "confirm")
				{
					if(data.result.hasOwnProperty("score"))
					{ 
						this.result.score = data.result.score;
						this.result.correct = data.result.correct;
						if(data.result.correction)
							this.result.correction = true;
						else
							delete this.result.correction;
						this.updateColor();
					}
					sub.unsubscribe();
					this.popup.autoClose = true;
				} else if(data.type == "cancel")
				{
					sub.unsubscribe();
					this.popup.autoClose = true;
				}
			}
		});
	}
	private updateColor():void
	{
		this.noEvaluation = false;
		this.allCorrect = false;
		this.partialCorrect = false;
		this.incorrect = false;
		if(this.result.hasOwnProperty("score"))
		{
			if(this.result.score <= 0)
			{
				this.result.correct = 0;
				this.incorrect = true;
			} else if(this.result.score >= this.max)
			{
				this.result.correct = 2;
				this.allCorrect = true;
			} else {
				this.result.correct = 1;
				this.partialCorrect = true;
			}
		} else {
			this.noEvaluation = true;
		}
	}
	private promptCustomScore(target:HTMLElement):Observable<any>
	{
		return new Observable((subscriber) => {
			var dcs:DynamicComponentService = this.context.service.dcs;
			var comp:any = dcs.create(ROScoreFreeEditPopup, {
				context:this.context,
				target:target,
				result:{
					score:this.result.score
				},
				// position:"right",
				max:this.max,
				hideTab:true,
				activeTab:"keyboard",
				showCorrectionButton:false
			});
			dcs.open(comp);
			var instance:IROScoreEditPopup = comp.compRef.instance;
			instance.component = this.component;
			var subscription:Subscription = new Subscription(()=>{
				dcs.destroy(comp);
			});
			subscription.add(
				instance.emitter.subscribe({
					next:(param)=>{
						subscriber.next(param);
					},
					error:(reason)=>{
						subscriber.next(reason);
					},
					complete:()=>{
						subscription.unsubscribe();
					}
				})
			);
			return ()=>{
				instance.close();
			}
		});
	}
	pressCorrect():void
	{
		this.studentAnswer = this.correctAnswer.concat();
		this.result.answer = this.studentAnswer.join(","); 
		this.result.score = this.max;
		this.result.correct = 2;
		delete this.result.correction;
		this.updateColor();
		this.onConfirm();
	}

	press(key:string):void
	{
		if(key == "U")
		{
			this.studentAnswer = ["U"];
			this.result.answer = "U";
			this.result.correct = 0;
			this.result.score = 0;
			this.result.correction = true;
		} else {
			if(this.multiSelect)
			{
				ArrayUtils.removeElement(this.studentAnswer, "U");
				this.toggleElement(this.studentAnswer, key);
			} else {
				this.studentAnswer = [key];
			}
			var answer:string = this.studentAnswer.join(","); 
			var union:any[] = ArrayUtils.union(this.studentAnswer, this.correctAnswer);
			var intersect:any[] = ArrayUtils.intersects(this.studentAnswer, this.correctAnswer);
			if(union.length == intersect.length)
			{
				this.result.score = this.max;
				this.result.correct = 2;
			} else if(intersect.length == 0)
			{
				this.result.score = 0;
				this.result.correct = 0;
			} else if(intersect.length < this.correctAnswer.length)
			{
				// 未滿 用加分制 // 加分制 - 只計對的答案
				var unitScore:number = this.max /  this.correctAnswer.length;
				var extraAnswer:number = union.length - this.correctAnswer.length;
				this.result.score = unitScore * intersect.length;
				this.result.correct = 1;
			} else 
			{
				// 扣分制
				var unitScore:number = this.max /  this.correctAnswer.length;
				var extraAnswer:number = union.length - this.correctAnswer.length;
				this.result.score = Math.max(
					0, 
					unitScore * (intersect.length -  0.5 * extraAnswer)
				);
				this.result.correct = 1;
			}
			this.result.answer = answer;
			this.result.correction = this.result.correct == 2 ? false : true;
		}
		this.updateColor();
		var shouldConfirm:boolean = this.autoConfirm || key == "U";
		if(shouldConfirm){
			this.onConfirm();
		}
	}

	pressClear():void
	{
		this.studentAnswer = [];
		// this.result.score = null;
		delete this.result.score;
		delete this.result.answer;
		delete this.result.correction;
		this.result.correct = -1;
		this.updateColor();
		if(this.autoConfirm)
		{
			this.emitter.next({
				type:"confirm", 
				result:this.result
			});
		}
		
	}
	
	ngOnDestroy(): void {
		
	}
	close():void
	{
		this.popup.close().then(()=>{
			this.emitter.complete();
		})
	}
}


@Component({
	template:`

	<bubbleBox2 
		#popup
		[position]="position"
		[padding]="5"
		[autoTailColor]="false"
		[backgroundColor]="themeColor"
		[tailBgColor]="themeColor"
		[clickOutsideHandler]="getClickOutsideHandler()"
		[cancelEvents]="['pointerdown', 'tap', 'mousedown']"
		>
		<div class="free">
			<div class="tab-container" [class.hidden]="hideTab">
				<div class="button tab active" [class.active]="activeTab == 'fast'"  (click)="switchTab('fast')">{{'ro.page.quick-eval'|translate}}</div>
				<div class="button tab" [class.active]="activeTab == 'keyboard'" (click)="switchTab('keyboard')">{{'ro.page.complex-eval'|translate}}</div>
			</div>
			<div class="fast-keyboard-container"  *ngIf="activeTab == 'fast'">
				<div class="line">
					<div class="first button keyboard-button full-score single-button" (click)="pressFullScore()">
						<div class="name">{{"ro.page.full-score"|translate}}</div>
						<div class="score">{{max}}</div>
					</div>
					<div class="button keyboard-button single-button" (click)="fastPress('0')">0</div>
					<div class="button keyboard-button red single-button"  (click)="pressClear(true)">C</div>
				</div>
				<div class="line" *ngFor="let items of lines">
					<div [class.first]="item && item.first" *ngFor="let item of items" class="button keyboard-button single-button" (click)="item ? fastPress(item.num): null">{{item ? item.num : ''}}</div>
				</div>
				<div class="line correction-line" (click)="toggleCorrection(true)" *ngIf="isCorrectionShown">
					<div class="button checkbox">
						<fa-icon *ngIf="result.correction" [icon]="icon1" [class.checked]="true"></fa-icon>
						<fa-icon *ngIf="!result.correction" [icon]="icon2"></fa-icon>
					</div>
					<div class="button label">{{"ro.page.correction"|translate}}</div>
					<div class="margin-left-auto"></div>
				</div>
			</div>
			<div class="free-keyboard-container" *ngIf="activeTab == 'keyboard'">
				<div class="line">
					<div class="first button keyboard-button full-score single-button" (click)="pressFullScore()">
						<div class="name">{{"ro.page.full-score"|translate}}</div>
						<div class="score">{{max}}</div>
					</div>
					<div class="score-label double-button">{{score}}</div>
				</div>
				<div class="line">
					<div class="first button keyboard-button small-font single-button grey" (click)="adjustScore(0.5)">+0.5</div>
					<div class="button keyboard-button small-font single-button" (click)="adjustScore(-0.5)">-0.5</div>
					<div class="button keyboard-button red single-button"  (click)="pressClear()">C</div>
				</div>
				<div class="line">
					<div class="first button keyboard-button single-button" (click)="press('7')">7</div>
					<div class="button keyboard-button single-button" (click)="press('8')">8</div>
					<div class="button keyboard-button single-button" (click)="press('9')">9</div>
				</div>
				<div class="line">
					<div class="first button keyboard-button single-button"  (click)="press('4')">4</div>
					<div class="button keyboard-button single-button" (click)="press('5')">5</div>
					<div class="button keyboard-button single-button" (click)="press('6')">6</div>
				</div>
				<div class="line">
					<div class="first button keyboard-button single-button" (click)="press('1')">1</div>
					<div class="button keyboard-button single-button" (click)="press('2')">2</div>
					<div class="button keyboard-button single-button" (click)="press('3')">3</div>
				</div>
				<div class="line">
					<div class="first button keyboard-button single-button" (click)="press('0')">0</div>
					<div class="button keyboard-button single-button" (click)="press('.')">.</div>
					<div class="button keyboard-button red single-button" (click)="pressConfirm()">OK</div>
				</div>
				<div class="line correction-line" (click)="toggleCorrection()" *ngIf="showCorrectionButton">
					<div class="button checkbox">
						<fa-icon *ngIf="result.correction" [icon]="icon1" [class.checked]="true"></fa-icon>
						<fa-icon *ngIf="!result.correction" [icon]="icon2"></fa-icon>
					</div>
					<div class="button label">{{"ro.page.correction"|translate}}</div>
					<div class="margin-left-auto"></div>
				</div>
			</div>
		</div>
	</bubbleBox2>

	`,
	styleUrls:["./ROQuestionScore.scss"]
})

export class ROScoreFreeEditPopup implements OnInit, OnDestroy, AfterViewInit, IROScoreEditPopup
{
	@Input() public position:string = "auto";
	@Input() public showCorrectionButton:boolean = true;
	public themeColor:string;
	public icon1:any = faCheckCircle;
	public icon2:any = faCheckCircleLight;
	@Input() public hideTab:boolean = false;
	@Input() public activeTab:string = "fast";
	@Input() context:ROContext;
	@Input() public target:HTMLElement;
	@ViewChild("popup", {static: false}) public popup: BubbleBox2Component;
	// @ViewChild("input", {static: false}) public input: any;
	@Output() emitter:EventEmitter<any> = new EventEmitter();
	private sub:Subscription;
	public reference:any;
	public component:ROComponent;
	

	@Input() public max:any;
	@Input() public result:any;
	public score:string;
	// public scoreCount:number;
	private _decimalNumber:DecimalNumber = new DecimalNumber();
	@Input() lines:any [];
	@Input() isCorrectionShown: boolean = true
	// this._decimalNumber = new DecimalNumber();
	// this._decimalNumber.parse(this._model.text);
	updateTheme():void
	{
		this.themeColor = this.activeTab == 'fast' ? '#2E2E40' : '#792A3B';
	}
	switchTab(tab:string)
	{
		if(this.activeTab == tab) return;
		this.activeTab = tab;
		this.updateTheme();
		if(this.popup) this.popup.resize(true);
	}
	onTap(event:any)
	{
		event.preventDefault();
		event.pointers.forEach((pointerEvent:any)=>{
			pointerEvent.stopImmediatePropagation()
		});
	}
	constructor(public alertService:AlertService, public themeService:ThemeService, public dcs:DynamicComponentService)
	{
		this.sub = new Subscription();
		this.switchTab("fast");
	}
	@HostListener('window:keydown', ['$event'])
  	handleKeyboardEvent(event: KeyboardEvent) {
		
		event.preventDefault();
		event.stopImmediatePropagation();
		event.stopPropagation();

		var pattern:string = KeyboardEventTool.toString(event);
		var match:any = KeyboardEventTool.match([
			/^[0-1]{4}([0-9]|\.|ESCAPE|\+|\=|\-|[A-F]|ENTER|ARROWRIGHT|ARROWLEFT|ARROWUP|ARROWDOWN|BACKSPACE|DELETE)$/
		], pattern)

		if(match)
		{
			var m = match[1];
			if(m)
			{
				if(m == "ENTER")
				{
					this.pressConfirm();
					return ;
				} else if(m == "ESCAPE")
				{
					this.close();
					return ;
				} else if(/ARROW/.test(m))
				{
					if(m == "ARROWUP") this.pressFullScore(); // this.adjustScore(0.5);
					else if(m == "ARROWDOWN") {
						this.fastPress('0');
						// this.pressClear(); // this.adjustScore(-0.5);
					}
					else if(m == "ARROWLEFT") this.switchTab("fast");
					else if(m == "ARROWRIGHT") this.switchTab("keyboard");
					return ;
				}
				
				if(this.activeTab == "keyboard")
				{
					if(/^([0-9]|\.)$/.test(m))
					{
						// number;
						var numText = m;
						this.press(numText);
					} else if(/[\+|\=|\-]/.test(m))
					{
						if(m == "+" || m == "=" ) this.adjustScore(0.5);
						else if(m == "-") this.adjustScore(-0.5);
						return ;
					} else if(m == "BACKSPACE" )
					{
						this.press("BACKSPACE");
					} else if(m == "DELETE")
					{
						this.pressClear();
					} else if(/ARROW/.test(m))
					{
						if(m == "ARROWUP") this.pressFullScore();
						else if(m == "ARROWDOWN") this.pressClear();
					}
				}
			}
			// console.log("keyboard event", event.key, event.altKey, event.ctrlKey, event.shiftKey);
			// console.log("clear", match);
		}
  	}
	ngAfterViewInit(): void {
		
		setTimeout(()=>{
			this.popup.open(this.target);
			this.updateScore();
			this.updateTheme();
		}, 0);
		// this.input.nativeElement.focus();
		// PromiseUtils.delay(100, null).then(()=>{
			// this.input.nativeElement.focus();
		// });
	}
	
	ngOnInit(): void {
		if(this.result.hasOwnProperty("score") && this.result.score!=null)
		{
			this._decimalNumber.parse(this.result.score.toString());
		} else {
			this._decimalNumber.clear();
		}
		if(this.max > 1)
		{
			this.lines = [];
			// public scoreCount:number;
			var count = Math.ceil(this.max / 3);
			
			for(var i = 0;i < count;i++)
			{
				var line:any [] = [];
				this.lines.push(line);
				for(var colIndex = 1 ;colIndex <= 3 ;colIndex++)
				{
					var num = colIndex + i * 3 ;
					
					if(num <= this.max) {
						var isFirst:boolean = colIndex == 1;
						line.push({
							first:isFirst,
							num:num.toString()
						});
					} else {
						line.push(null);
					}
				}
			}
		} else {
			this.lines = [];

		}
	}
	getClickOutsideHandler():any{
		return this.clickOutsideHandler.bind(this);
	}
	
	clickOutsideHandler():Promise<any>
	{
		if(this.busy)
		{
			return Promise.reject("busy");
		} else {
			this.emitter.next({
				type:"cancel"
			});
			return Promise.reject("cancel");
		}
	}
	///////////
	pressFullScore():void
	{
		this._decimalNumber.setValue(this.max);
		if(this.result) this.result.correction = false;
		this.updateScore();
		this.onConfirm();
	}

	fastPress(key:string):void
	{
		if(key == "") return;
		this._decimalNumber.clear();
		this._decimalNumber.addNumber(key);
		this.updateScore();
		this.calculateCorrection();
		this.onConfirm();
	}
	press(key:string):void
	{
		if(key == "") return;
		var currentText:string = this._decimalNumber.toString();
		if(key == "BACKSPACE")
		{
			this._decimalNumber.backspace();
			this.updateScore();
			this.calculateCorrection();
		} else if(key == "0" && (currentText == "" ||  currentText == "0"))
		{
			this._decimalNumber.setValue(0);
			this.updateScore();
			this.calculateCorrection();
		} else {
			if(key == ".")
			{
				this._decimalNumber.addDecimal();
			} else {
				this._decimalNumber.addNumber(key);
			}
			this.updateScore();
			this.calculateCorrection();
		}
	}
	private calculateCorrection():void
	{
		if(this.result) {
			this.result.correction = this.score < this.max;
		}
	}
	pressClear(_confirm:boolean = false):void
	{
		this._decimalNumber.clear();
		if(this.result) this.result.correction = false;
		this.updateScore();
		if(_confirm)
		{
			this.onConfirm();
		}
	}

	private onConfirm():void
	{
		var score:any = this._decimalNumber.getValue();
		if(score === null){
			this.result.correct = -1;
			delete this.result.score
		} else {
			if(score <= 0)
			{
				this.result.correct = 0;
			} else if(score >= this.max)
			{
				this.result.correct = 2;
			} else {
				this.result.correct = 1;
			}
			this.result.score = score;
		}
		this.emitter.next({
			type:"confirm", 
			result:this.result
		});
	}

	public busy:boolean;
	pressConfirm():void
	{
		// this._skin.inProgress = true;
		var value:any = this._decimalNumber.getValue()
		var p:Promise<any>;
		if(value &&value > this.max)
		{
			this.busy = true;
			// p = this.alertService.confirm("ro.components.common.score-over-max", true);
			p = this.alertService.alert("ro.components.common.score-over-max-alert").then(()=>{
				return Promise.reject(null);
			});
		} else {
			p = Promise.resolve(1);
		}

		p.then((o:any)=>{
			
			this.onConfirm();
		}).finally(()=>{
			this.busy = false;
		})
	}

	toggleCorrection(_confirm:boolean = false):void
	{
		if(this.result)
		{
			this.result.correction = !this.result.correction;
		}
		if(_confirm) this.onConfirm();
	}
///////////
	ngOnDestroy(): void {
		this.sub.unsubscribe();
	}

	adjustScore(score:number):void
	{
		this._decimalNumber.boundedAdjustScore(score, this.max);
		this.updateScore();
		this.calculateCorrection();
	}

	updateScore():void
	{
		this.score = this._decimalNumber.toString();
	}

	
	
	
	close():void
	{
		this.popup.close().then(()=>{
			this.emitter.complete();
		})
	}
}

@Component({
	template:`
	<div class="offset">
		<div  (tap)="onTap($event)" (pointerdown)="cancelEpen($event)" (touchstart)="cancelEpen($event)" class="content" [ngClass]="showState">

			<svg 
				xmlns="http://www.w3.org/2000/svg" 
				version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" 
				preserveAspectRatio="none" x="0px" y="0px" width="40px" height="40px" viewBox="0 0 40 40"
				overflow="visible"
				>
				<!-- 
				<g transform="matrix( 1, 0, 0, 1, -44.4,-3) ">
					<path fill="#FFFFFF" stroke="none" d="
					M 75.4 12.25 Q 72.5 9.35 68.35 9.35 L 60.35 9.35 Q 56.2 9.35 53.25 12.25 50.35 15.2 50.35 19.35 L 50.35 27.35 Q 50.35 31.5 53.25 34.4 56.2 37.35 60.35 37.35
					L 68.35 37.35 Q 72.5 37.35 75.4 34.4 78.35 31.5 78.35 27.35 L 78.35 19.35 Q 78.35 15.2 75.4 12.25 Z"/>
					<path  
					stroke-width="1" stroke-linejoin="round" stroke-linecap="round" fill="none" d="
					M 60.35 9.35 L 68.35 9.35 Q 72.5 9.35 75.4 12.25 78.35 15.2 78.35 19.35 L 78.35 27.35 Q 78.35 31.5 75.4 34.4 72.5 37.35 68.35 37.35
					L 60.35 37.35 Q 56.2 37.35 53.25 34.4 50.35 31.5 50.35 27.35 L 50.35 19.35 Q 50.35 15.2 53.25 12.25 56.2 9.35 60.35 9.35 Z"/>
				</g>
				-->
				<rect 
						x="6" y="6" width="28" height="28" rx="10" 
						fill="#FFFFFF" 
						[attr.stroke]="color" stroke-width="1"
					/>
				<g *ngIf="type == 'mc' || type == 'full'">
					<g transform="matrix( 1, 0, 0, 1, 4.65,5.15) ">
						<path [attr.fill]="color" stroke="none" d="M 29.8 8.8 Q 29.35 5.85 27.1 3.6 25 1.5 21.9 0.95 15.4 0.1 8.85 0.95 5.8 1.5 3.65 3.6 1.4 5.85 0.95 8.8 L 0.75 10.7 30 10.7 29.8 8.8 Z"/>
					</g>

					<text
						fill="#FFFFFF" 
						x="50%" y="12" 
						dominant-baseline="middle" font-size="6.5" text-anchor="middle">{{mcAnswer}}</text>    
					
					<text
						[attr.fill]="color"
						x="50%" y="25" 
						dominant-baseline="middle" 
						font-size="12" 
						font-weight="bold"
						text-anchor="middle">{{label}}</text>    
					
					<g transform="matrix( 1, 0, 0, 1, 17.7,17.65) ">

						<!-- 
						<text 
						writing-mode="lr"><tspan x="-4.6" y="10.75" baseline-shift="0%" kerning="auto" font-size="12" 
						[attr.fill]="color" xml:space="preserve">{{label}}</tspan></text>
						-->
					</g>
				</g>

				<g *ngIf="type != 'mc' && type != 'full'">
					<text
						[attr.fill]="color"
						x="50%" y="22" 
						dominant-baseline="middle"
						font-size="15" 
						font-weight="bold"
						text-anchor="middle">{{label}}</text>    
					
				</g>
			</svg>
		<!--
			<svg viewBox="0 0 38 38" class="icon" overflow="visible">
				<g  transform="scale(0.55 0.55) translate(9 9)">
					<rect width="56" height="56" fill="#00000000" stroke="none" paint-order="stroke fill markers" />
					
					<ng-container *ngIf="type == 'mc'" >
						<path 
							[attr.fill]="color" stroke="none" paint-order="stroke fill markers" 
							d="M49.35 6.6 Q53.4 10.65 54.2 16.05 L54.6 19.5 L1.45 19.5 L1.8 16.05 Q2.6 10.65 6.65 6.6 Q10.6 2.65 16.1 1.8 Q28.05 0.2 39.9 1.8 Q45.35 2.65 49.35 6.6"
							></path>
						<text 
							x="50%" y="12" font-size="10"
							dominant-baseline="middle" text-anchor="middle" fill="white"
							
							
							>{{mcAnswer}}</text> 
					</ng-container>	
					
				</g>
			</svg>
			-->
			<!-- <div class="score-container"  *ngIf="type == 'mc' || type == 'free' || type == 'full' || type == 'tf'" [style.color]="color">{{label}}</div>
			-->
		</div>
	</div>
	<div *ngIf="canMoveScore" class="dragHandle" (pointerdown)="moveCorrection($event)"></div>
	
	<!--
	<fa-icon class="upload" [icon]="faUp"></fa-icon>
		<div class="noScore" *ngIf="getState()=='noScore'">S</div>
		<div [ngClass]="[getScoreColor()]" *ngIf="getState()=='score'">{{scoreWithPlus()}}</div>
		<div class="upload" *ngIf="getState()=='upload'"></div>
		<div class="manual" *ngIf="getState()=='manual'" (click)="open()" #menu>
			<span>{{scoreWithPlus()}}</span>
			<div class="manualScoringBox" *ngIf="openScoreEdit">
				<div>{{componentName}}</div>
				<div class="editScoreRow">
					<div (click)="decScore()"><fa-icon [icon]="faCircleMinus"></fa-icon></div>
					<div class="editScore">{{score()}}/{{totalScore()}}</div>
					<div (click)="incScore()"><fa-icon [icon]="faCirclePlus"></fa-icon></div>
				</div>
			</div>
		</div>
		-->
	`,
	styles:[
		`
		.score-container{
			position: absolute;
			top: 0px;
			left: 0px;
			line-height: 37px;
			text-align: center;
			width: 37px;
			overflow: hidden;
		}
		
		:host{
			position:absolute;
			width: 40px;
			height: 40px;
		}
		/*
		.offset{
			position:absolute;
			width: 35px;
			height: 35px;

			top: 50%;
			left: 50%;
			transform:translate(-50%, -50%);

		}
		
		
		.content{
			// position: absolute;
			
			font-size:16px;
			font-weight: bold;
			text-align: center;
			align-items: center;
			border-radius: 14px;
			border-color: var(--icon-color);
			color:var(--icon-color);
			background-color:#fff;
			// outline: solid 1px var(--icon-color) !important;
			// transform: translate(-1px,-1px);
		}
		*/
		/*
		.content:after{
			position: absolute;
			top:5px;
			left:5px;
			content: '';
			width:26px;
			height:26px;
			border-radius: 10px;
			border: solid 1px var(--icon-color);
		}
		*/
		.upload:before{
			position: absolute;
			top:9px;
			left:9px;
			content: '';
			width:20px;
			height:20px;
			background-color:var(--icon-color);
			clip-path: polygon(0% 50%, 50% 0%, 100% 50%, 75% 50%, 75% 100%, 25% 100%, 25% 50%);
			border-radius: unset;
			border:none;
		}

		.score, .noscore{
			display:grid;
		}
		.noscore:before{
			content: '';
			position: absolute;
			left: 8px;
			top: 29px;
			width: 42px;
			height: 0px;
			transform: rotate(45deg);
			border: solid 0.5px var(--icon-color);
		}
		:host.hide, .hide{
			visibility:hidden;
			display:none;
		}

		.dragHandle{
			display:block;
			width:27px;
			height:20px;
			position: absolute;
			left: 5px;
			top: -15px;
		}
		`
	]
})
export class ROQuestionScore //extends ROComponent
{
	@ViewChild('correctionAnsBox', {static:false}) correctionAnsBox!:ElementRef;

	public mcAnswer:string = "";
	public label:string = '+1';
	public color:any;
	@Input() public parent:ROComponent;
	@Input() public page:ROComponent;
	@Input() public node:XMLNode;
	@Input() public context:ROContext;
	public type:string = "free"; //
	public x:number;
	public y:number;
	public componentRef:ComponentRef<any>;

	protected dd:DragManager;
	public scoreCom:ROScoreCom;
	public canMoveScore:boolean = false;
	private tapDelay:Subject<any>;

	constructor(protected cdr:ChangeDetectorRef, public elementRef:ElementRef)
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.add("question-score");
		this.tapDelay = new Subject();
		this.tapDelay.pipe(debounceTime(300)).subscribe(this.onTapDelay.bind(this));
	}

	reattach():void
	{
		this.cdr.reattach();
		
	}
	detach():void
	{
		this.cdr.detach();
	}

	destroy():void
	{
		this.componentRef.destroy();
	}

	public getEnabled():boolean
	{
		return this.node.attributes.enable;
	}
	
	public getDOM():HTMLElement
	{
		return this.elementRef.nativeElement;
	}
	
	// @HostListener('tap', ['$event'])
	
	onTap(event:any):void
	{
		if(!this.canScoring())
		{
//			this.context.service.alertService.alert("NOT ALLOWED");
			return;
		}
		this.tapDelay.next(event);
	}

	onTapDelay(event:any):void
	{
		console.log("onTapDelay", event, "tapCount", event.tapCount);
		if(event.tapCount > 1)
		{
			this.setFullScore();
		} else {
			this.showEvaluationDialog();
		}
	}

	private setFullScore():void
	{
		var com:ROComponent = this.parent;
		if(com.canVerify()) return;
		if(com.hasScoring() == false) return;
		
		console.log("set full score");
		// var result:any = this.parent.resultObject; 
		var page:ROPageComponent = <ROPageComponent> this.page;
		var source:AnswerSource = page.source;
		
		var totalScore:any = this.parent.getFullScore();
		var result:any;
		if(this.parent.resultObject)
		{
			result = ObjectUtils.clone(this.parent.resultObject);
		} else {
			result = { maxScore: totalScore , correct:-1};
		}
		
		var evaluateType:string = "free";
		result.score = totalScore;
		result.correct = 2;
		delete result.correction;
		delete result.answer;
		if(com instanceof ROScoreCom )
		{
			var scoreCom:ROScoreCom = com;
			var ctx:any = scoreCom.ctx;
			evaluateType = ctx.type
			this.scoreCom = scoreCom;
			if(evaluateType == "mc")
			{
				if(!ctx.mcAnswer)
				{
					this.context.service.alertService.alert( "ro.components.score-com.mc-no-default-answer", null, null, true );
					return;
				}
				result.answer = (typeof ctx.mcAnswer == "string") ? ctx.mcAnswer : ctx.mcAnswer.join(","); 
			} else if(evaluateType == "full" || evaluateType == "tf" )
			{
				var correctAnswer = ctx.hasOwnProperty("answer") ? ctx.answer : "T";
				result.answer = correctAnswer;
			}
			if(!scoreCom.correctionAns) {
				com.data = "{}";
			}
		
		}
		console.log(evaluateType, 'new result', result);
		this.saveStudentResult(source, result).then((o:any)=>{
			com.resultObject = result;
			this.showResult(result);
		}).catch((reason)=>{
			console.log("save student result failed because ", reason);
		}).then(()=>{
			// this.context.subject.next({type:"notify", })
			this.context.subject.next({
				type:"notify", 
				notify:{
					type:"scoreUpdated"
				}
			});
		})
	}

	private showEvaluationDialog():void
	{
		var com:ROComponent = this.parent;
		if(com.canVerify()) return;
		if(com.hasScoring() == false) return;
		
		// var result:any = this.parent.resultObject; 
		var page:ROPageComponent = <ROPageComponent> this.page;
		var source:AnswerSource = page.source;
		
		var totalScore:any = this.parent.getFullScore();
		var result:any;
		if(this.parent.resultObject)
		{
			result = ObjectUtils.clone(this.parent.resultObject);
		} else {
			result = { maxScore: totalScore , correct:-1};
		}
		
		var evaluateType:string = "free";
		if(com instanceof ROScoreCom )
		{
			var scoreCom:ROScoreCom = com;
			evaluateType = scoreCom.ctx.type
			console.log(evaluateType);
			this.scoreCom = scoreCom;
		}
		
		var ob:Observable<any> = this.evaluate(com, evaluateType, result, totalScore);
		if(!ob) return;
		var sub:Subscription = ob.subscribe({
			next:(data)=>{
				if(data.type == "confirm")
				{
					// console.log(data, result);
					if(com.node.tag == "ScoreCom") {
						if(!(<ROScoreCom>com).correctionAns) {
							com.data = "{}";
						}
						//if(!com.data)
						//	com.data  = "{}";
					}
					
					// this.showResult(result);
					this.saveStudentResult(source, result).then((o:any)=>{
						sub.unsubscribe();
						com.resultObject = result;
						this.showResult(result);
					}).catch((reason)=>{
						console.log("save student result failed because ", reason);
					}).then(()=>{
						// this.context.subject.next({type:"notify", })
						this.context.subject.next({
							type:"notify", 
							notify:{
								type:"scoreUpdated"
							}
						});
					})
				} else if(data.type == "cancel")
				{
					sub.unsubscribe();
				}
			},
			error:(reason)=>{
				console.log("evaluation error", reason);
				setTimeout(()=>{
					sub.unsubscribe();
				}, 0);
				
			}
		});
		/*
		this.evaluate(result, score, totalScore).then((data:any)=>{
			if(data.type == "confirm")
			{
				debugger;
				var value  = data.value;
				if(value >= 0)
				{
					result.score = value;
				} else {
					delete result.score;
				}
				if(com.node.tag == "ScoreCom") com.data = "{}";
				this.showScore(-1, value);
				
			} else if(data.type == "clear")
			{
				// ["455948", 2151429 ,1761,1,"2151430","B788F546-93AB-4FF5-9E74-000103F28484","37FF31B2-06D9-6615-7A29-773C4CB3FED6","{}",null,"ScoreCom"]
				// ["455948","2151429",1761,1,"2151430","B788F546-93AB-4FF5-9E74-000103F28484","37FF31B2-06D9-6615-7A29-773C4CB3FED6","{}",null,"ScoreCom"]"
				result.correct = -1;
				delete result.score;
				if(com.node.tag == "ScoreCom") com.data = "{}";
				this.showScore(-1, value);
				result = null;
				
			}
			return this.saveStudentResult(source, result).then((o:any)=>{
				com.resultObject = result;
				this.showScore(result.correct, result.score);
			});
			
		}).catch((reason:any)=>{
			console.error(reason);
		});
		*/
	}

	public cancelEpen(event:any):void
	{
		if(this.context && this.canScoring()) {
			event.preventDefault();
			event.stopImmediatePropagation();

		}
	}
	
	public saveStudentResult(source:AnswerSource, newResult:any, data:any = undefined):Promise<any>
	{
		var com:ROComponent = this.parent;
		var page:ROPageComponent = <ROPageComponent> this.page;
		var index:any = this.context.config.index;
		var shareID:any = this.context.config.share.id;
		var bookID:any = this.context.config.book.id;
		var student:any = this.context.config.student;
		var chapterID:any = page.chapter.id;
		var pageID:any = page.douid;
		var componentID:any = com.douid;
		var tag:string = com.node.tag;
		if(data === undefined) data = com.data;
		// var oldResult:any = com.resultObject; // 
		
		return this.context.service.roBookReviewService.save(
			shareID,  bookID, student.uid,  index,
			chapterID, pageID,  componentID, 
			data, newResult, tag
		).then(()=>{
			source.setAnswer(chapterID, pageID, componentID, data, newResult);
			return newResult;
		});
		/*
		console.log(oldResult, newResult);
		return this.context.service.dataService.call(
			"ROBookShare.update_student_result_by_index",
			shareID,  bookID, student.uid,  index,
			chapterID, pageID,  componentID, 
			data, newResult, tag, false
		).then(()=>{
			source.setAnswer(chapterID, pageID, componentID, data, newResult);
			return newResult;
		});
		*/
	}
	/**
	 * $bsid, $book_id, $uid, $index,
			$bid, $pid, $cid, 
			$data, $result, $tag = null)

	api=ROBookShare.update_student_result_by_index
	[
		"455948", // bsid
		2151429, book_id
		1761, uid
		1, // index
		"2151430", // chapter id
		"B788F546-93AB-4FF5-9E74-000103F28484", // page id
		"1403A120-0EED-B363-1D30-EF4A93180CC7", // component id
		"{}", // data 
		{"maxScore":2,"correct":-1,"score":1.5,"correction":true}, // new result object
		"ScoreCom" // tag
	]
	*/
	private evaluate(com:ROComponent, evaluateType:string, result:any, max:any):Observable<any>
	{
		var definition:any = null;
		if(evaluateType == "mc")
		{
			var scoreCom:ROScoreCom = <ROScoreCom>com;
			if(!scoreCom.ctx.mcAnswer)
			{
				this.context.service.alertService.alert(
					"ro.components.score-com.mc-no-default-answer", null, null, true
				);
				return new Observable((subscriber) => {
					subscriber.error("no default mc answer");
				});
			}
			definition = ROScoreMCEditPopup;
		} else if(evaluateType == "full" || evaluateType == "tf" )
		{
			definition = ROScoreTFEditPopup;
		} else if(evaluateType == "free")
		{
			definition = ROScoreFreeEditPopup;
		} else {
			return new Observable((subscriber) => {
				subscriber.error("not support");
			});
		}
		
		
		return new Observable((subscriber) => {
			var dcs:DynamicComponentService = this.context.service.dcs;
			var comp:any = dcs.create(definition, {
				context:this.context,
				target:this.elementRef.nativeElement,
				result:result,
				max:max
			});
			dcs.open(comp);
			var instance:IROScoreEditPopup = comp.compRef.instance;
			instance.component = com;
			var subscription:Subscription = new Subscription(()=>{
				dcs.destroy(comp);
			});
			subscription.add(
				instance.emitter.subscribe({
					next:(param)=>{
						subscriber.next(param);
					},
					error:(reason)=>{
						subscriber.next(reason);
					},
					complete:()=>{
						subscription.unsubscribe();
					}
				})
			);
			return ()=>{
				instance.close();
			}
		});
			
		// })
		
		
		
	}
	
	public getTagNames():string []
	{
		return ["QuestionScore"];
	}
	public updatePosition():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		let style:any = dom.style;
		var options:any = this.node.attributes;
		style.position = 'absolute';

		var position:string = options.position;
		var firstLineHeight:number = this.parent.getFirstLineHeight();
		if (position == "auto")
		{
			dom.classList.add("auto-position");

			this.x = -10;
			this.y = firstLineHeight >= 60 ? 0 : (firstLineHeight - 60)/2;
			style.left = this.x + "px";
			style.top = this.y + "px";
		} else if (position == "manual")
		{
			dom.classList.add("manual-position");
			style.left = (options.x ) + "px";
			style.top = (options.y  )+ "px";
			this.x = options.x ;
			this.y = options.y;
		} else if (position == "relative")
		{
			dom.classList.add("relative-position");
			style.left = (options.x ) +"px";
			style.top = (options.y ) + "px";
			this.x = (options.x );
			this.y = (options.y );

		} else if (position == "absolute")
		{
			dom.classList.add("absolute-position");
			style.left = (options.x ) +"px";
			style.top = (options.y ) + "px";
			this.x = (options.x );
			this.y = (options.y );
		} else if (position == "page")
		{
			dom.classList.add("page-position");
			var pageW:number = this.page.node.attributes.w;
			var pageH:number = this.page.node.attributes.h;
			var offsetX:number = pageW - 80 - this.parent.x ;
			var offsetY:number = options.y;
			style.left = offsetX + "px";
			style.top = offsetY + "px";
			this.x = offsetX;
			this.y = offsetY;
		} else {
			dom.classList.add("other-position");
			style.left = options.x  +"px";
			style.top = options.y  + "px";
			this.x = options.x ;
			this.y = options.y;
		}
	}
	protected numberBallSizeArray:number[] = [22, 29, 36, 44, 52];
	

	public build():void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.appendChild(document.createComment(JSON.stringify(this.node.attributes)));
		this.updatePosition();
		this.hideScore();
	}

	/*public showFor(result:boolean, pie:boolean):void {
		let style:any = this.elementRef.nativeElement.style;
		style.visibility = result ? 'visible' : 'hidden';
	}*/
	public _showState:string = 'hide';
	
	public hideScore():void {
		if(this.parent instanceof ROScoreCom)
			return;
		this.showState = 'hide';
	}
	public set showState(value:string)
	{
		this.switchState(value);
	}
	public get showState():string
	{
		return this._showState;
	}
	
	public switchState(state:string):void
	{
		var dom:HTMLElement = this.getDOM();
		dom.classList.remove(this._showState);
		this._showState = state;
		dom.classList.add(this._showState);
	}

	public showScoring(_showScoring:boolean, viewMode:string, resultObj:any):void {
		if(!resultObj) {
			console.log("input error");
			return;
		}

		if(!_showScoring) {
			// 顯示已提交
			this.showUpload();
			return;
		}

		// 顯示分數
		if(resultObj.correct == -1) {
			if(resultObj.hasOwnProperty('score') && resultObj.score!=-1) {
				// 比左分
				
			} else if(viewMode!="scoring") {
				// 非批改模式
				this.showUpload();
				return;
			} else {
				// 批改模式
				
			}
			
		}
		
		if(this.node.attributes.enable)
			this.showScore(resultObj.correct, resultObj.score);
	}

	public updateScoreCanMoveState():void {
		var viewMode:string = this.context.config.viewMode;
		var subViewMode:string = this.context.config.subViewMode;

		if(this.parent instanceof ROScoreCom) {
			this.scoreCom = <ROScoreCom>this.parent;

			this.canMoveScore = false;
			if(subViewMode == "marking1" || (viewMode=="scoring" && subViewMode == "correction")) {
				if(!this.dd)
					this.dd = new DragManager();
				this.canMoveScore = true;
			}
				
		}

	}

	public showResult(r:any):void
	{
		// {\"maxScore\":3,\"correct\":2,\"correction\":0,\"answer\":\"A\",\"score\":3}
		
		if(this.type == "mc" && r)
		{
			var viewMode = this.context.config.viewMode;
			if(viewMode == "view")
			{
				this.mcAnswer = r.answer ? r.answer : "";
			} else if(viewMode == "scoring")
			{
				this.mcAnswer = r.answer ? r.answer : "";
			} else if(viewMode == "preview")
			{
				this.mcAnswer = r.answer ? r.answer : "";
			} else {
				this.mcAnswer = r && r.answer ? r.answer : "";
			}
		} else if(this.type == "full")
		{
			this.mcAnswer = r && r.answer ? r.answer : "";
			
		} else {
			this.mcAnswer = "";
		}
		// add space after , 
		if(this.mcAnswer)this.mcAnswer = this.mcAnswer.replace(/[\,]/g, ", ");
		
		if(r) {
			this.showScore(r.correct, r.score);
		} else {
			this.showScore(-1, -1);
		}

	}

	public showText(correctState:number, text:string):void{
		let iconColor:string;
		this.label = text;
		if (correctState == 0) 
		{
			// 答錯
			iconColor = '#FF5858';
			
		} else if (correctState == 1) 
		{
			// 部份答錯
			iconColor = '#FFA801';
			
		} else if (correctState == 2) 
		{
			// 答對
			iconColor = '#65AC10';
		}
		else if (correctState == -1) 
		{
			iconColor = '#999999';
		} else {
			iconColor = '#0CB8E2';
		}
		this.changeDisplay(this.label, iconColor, 'score');
	}
	public showScore(correctState:number, score:number):void {
		let iconColor:string;
		if (correctState == 0) {
			// 答錯
			this.label = (Math.floor(score*10)/10).toString();
			iconColor = '#FF5858';
			
		} else if (correctState == 1) {
			// 部份答錯
			this.label = "+" + (Math.floor(score*10)/10).toString();
			iconColor = '#FFA801';
			
		} else if (correctState == 2) {
			// 答對
			this.label = "+" + (Math.floor(score*10)/10).toString();
			iconColor = '#65AC10';

		} else {
			// 上載 / 手動評分
			if(score === null || score == -1 || isNaN(score)) {
				// 未評分
				iconColor = '#999999';
				this.label = "?";
			} else if(score == 0) {
				iconColor = '#0CB8E2';
				this.label = "0";
			} else {
				iconColor = '#0CB8E2';
				this.label = "+" + (Math.floor(score*10)/10).toString();
			}
		}
		this.changeDisplay(this.label, iconColor, 'score');
		/*if (resultObject && resultObject.answer)
		{
			mcScoreSkin.setValue("answer", resultObject.answer);
			this.showMCScore();
			this.resetColor();
		}*/
	}

	public showNoScore():void {
		this.changeDisplay('S', '#BBBBBB', 'noscore');
	}

	public showUpload():void {
		this.changeDisplay('', '#0CB8E2', 'upload');
	}

	protected changeDisplay(_label:string, _color:string, state:string):void {
		this.label = _label;
		this.elementRef.nativeElement.style.setProperty('--icon-color', _color);
		this.showState = state;
		this.color = _color;
	}

	public updatePositionByLineHeight(lineHeight:number):void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		let style:any = this.elementRef.nativeElement.style;
		let attr:any = this.node.attributes;
		//style.left = "-60px";
		style.top = (lineHeight > 60 ? 0 : (lineHeight - 60) / 2) + "px";
	}
	
	public moveBy(px:number, py:number):void
	{
		this.x += px;
		this.y += py;
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.left = this.x+"px";
		dom.style.top  = this.y+"px";
	}


	// =========================
	// correction relative function
	// =========================
	public moveCorrection(event):void {
		event.stopImmediatePropagation();
		this.scoreCom.dataChanging();
		var correctionAns = (<ROScoreCom>this.parent).correctionAns;
		
		var originalPosition:any = {x:correctionAns.x, y:correctionAns.y};
		
		this.dd.monitorMovement((<ROScoreCom>this.parent).getElementRef().nativeElement, event, (type, movement)=> {
			var restorePosition:boolean = false;
			var fn = null;;
			if(type == "cancel" || type == "timeout"){
				restorePosition = true;
			} else {
				
				if(type == "end")
				{
					var userInfo:any = this.context.service.dataService.userInfo;
					var userRole = userInfo ? userInfo.user_role : null;
					if(userRole == 3 || userRole == "teacher")
					{
						// 新 code 是老師移動分數組件
						/// teacher code
						fn = ()=>{
							var page:ROPageComponent = <ROPageComponent> this.page;
							var source:AnswerSource = page.source;
							this.saveStudentResult(source, this.scoreCom.resultObject, this.scoreCom.data);
							
						}
					} else if(userRole == 2 || userRole == "student")
					{
						// 舊 code 是學生移動分數組件，會 call save function
						/// student code 
						fn = ()=>{
							this.scoreCom.dataChanged();
						}
						
					} else {
						restorePosition = true;
					}
				}
			}

			if(restorePosition)
			{
				// restore original position
				correctionAns.x = originalPosition.x;
				correctionAns.y = originalPosition.y;
				this.scoreCom.setPosition(correctionAns.x, correctionAns.y);

			} else {
				correctionAns.x = Math.max(0, Math.min(this.page.w - 40, correctionAns.x+movement.x));
				correctionAns.y = Math.max(20, Math.min(this.page.h - 40, correctionAns.y +movement.y));
				this.scoreCom.setPosition(correctionAns.x, correctionAns.y);
				if(fn !== null)
				{
					fn();
					
				}
			}
			
			
		});
	}

	protected canScoring():boolean {
		var config:ROBookConfig = this.context.config;
		return config.viewMode == "scoring" && config.subViewMode == "marking1";
	}

}


class  DecimalNumber 
{
	private static BASIC_NUMBER_REG:RegExp = /^([\-]{0,1})([0-9]{1,})([\.]{0,1})([0-9]{0,})$/;
	public sign:string;
	public upper:string;
	public dot:string;
	public lower:string;
	public dp:number = 1;
	
	public roundScore(score:number):number
	{
		return Math.round(score * 10 ) / 10;
	}

	public adjustScore(score:number):void
	{
		var num:number = parseFloat(this.toString());
		if(isNaN(num)) num = 0;
		num += score;
		if(num < 0) num = 0;
		num = this.roundScore(num);
		this.setValue(num);
	}

	public boundedAdjustScore(score:number, max:number):void
	{
		var num:number = parseFloat(this.toString());
		if(isNaN(num)) num = 0;
		num += score;
		if(num < 0) num = 0;
		if(num > max) num = max;
		num = this.roundScore(num);
		this.setValue(num);
	}

	public getValue():any
	{
		var num:number = parseFloat(this.toString());
		if(isNaN(num)) return null;
		return num;
	}

	public setValue(number:number):void
	{
		var s:string = number.toString();
		this.parse(s);
	}
	
	private simplify(txt:string):string
	{
		return txt ? txt : "";
	}
	
	public parse(text:string):void
	{
		if(text){
			var s:string = text +"";
			var match:any [] = s.match(DecimalNumber.BASIC_NUMBER_REG);
			if (match)
			{
				this.sign = this.simplify(match[1]);
				this.upper = this.simplify(match[2]);
				this.dot = this.simplify(match[3]);
				this.lower = this.simplify(match[4]);
			} else {
				this.clear();
			}
		} else {
			this.clear();
		}
	}
	
	public clear():void
	{
		this.sign = "";
		this.upper = "";
		this.dot = "";
		this.lower = ""
	}
	
	public toggleSign():void
	{
		if (this.sign)
		{
			this.sign = "";
		} else {
			this.sign = "-"
			
		}
	}
	
	public toString():string
	{
		return this.sign + this.upper + this.dot + this.lower;
	}
	
	public addNumber(num:string):void 
	{
		if (this.dot)
		{
			if(this.lower.length < this.dp)
			{
				this.lower = this.lower + num;
			}
		} else {
			this.upper = this.upper == "0" ? num : this.upper + num;
		}
	}
	
	public backspace():string
	{
		var s:string = this.toString();
		if (s.length)
		{
			s = this.spliceLastCharacter(s);
			this.parse(s);
		}
		return s;
	}
	
	public addDecimal():void 
	{
		if (!this.upper)
		{
			this.upper = "0";
		}
		this.dot = ".";
	}
	
	private spliceLastCharacter(text:string):string
	{
		return text.substring(0, text.length - 1);
	}
}

export enum CorrectionStateType {
	CORRECT = 2,
	INCORRECT = 0,
	SUBMITTED = -1,
	PARTIAL_CORRECT = 1
}