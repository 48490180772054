
import { AfterViewInit, ApplicationRef, Component, ComponentRef, ElementRef, EventEmitter, HostListener, Injector, Input, isDevMode, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewContainerRef } from "@angular/core";
import { DataService } from "src/app/service/data.service";

import { RODocumentService } from 'src/app/sharedModule/roModule/RODocumentService';
import { HammerControl } from "src/app/sharedModule/panZoomModule/HammerControl";
import { HammerTransformControl } from "src/app/sharedModule/panZoomModule/HammerTransformControl";
import { fromEvent, merge, pipe, Subject, Subscription } from "rxjs";
import { DynamicComponentService } from "src/app/service/dynamicComponent.service";
import { DOMHelper } from "src/app/common/DOMHelper";
import { AlertService } from "src/app/service/alert.service";
import { ROPageComponentContainer } from "./ROPage";
import { ResourceSource, ROAnswerAssetUpload, ROComponentDefinition, ROContext, ROContextService } from "./ROContext";
import { ROComponent, ROComponentMap, ROGraphicComponent, ROImageComponent, ROPageComponent, ROShapeCircleComponent, ROShapeCustomComponent, ROShapeRectangleComponent, ROSuperTextComponent, ROTLFTextComponent, ROUnknownComponent } from "./ROComponent";

import { AnswerSource, ROBookConfig } from "./ROBookConfig";
import { XMLNode } from "./xml/XMLNode";
import {Title} from "@angular/platform-browser";

import { AllROComponents } from "./AllROComponent";
import { IAudioPlayer, ROAudioPlayerManager } from "./TTSManager";
import { FileIOService } from "src/app/service/FileIO.service";
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';
import { TranslateService } from "@ngx-translate/core";
import { PinYinKeyboardService } from "./keyboard/PinYinKeyboardService";
import { TopLayer } from "src/app/common/TopLayer";
import { StyleUtils } from "./StyleUtils";
import { RODocument } from "./RODocument";
// import { Observable } from "src/assets/tinymce/tinymce";
import { debounceTime } from 'rxjs/operators';
import { MRUCache } from "src/app/common/LinkedList";

import { ByteArrayUtils } from "src/app/ro/hk/openknowledge/utils/ByteArrayUtils";
import { RODoc } from "src/app/ro/hk/openknowledge/ro/RODoc";
import {OupService} from "../../service/oup.service";
@Component({
	selector: 'ro-page-slider',
	templateUrl : "./ROPageSliderComponent.html",
	// template:`
	// <div style="position:fixed;left:10px">
	// 	<button type="button">
	// 		Click Me!
	// 	</button>
	// </div>
	// <div #touch class="host" (resized)="onResized($event)" [style.maxWidth]="maxWidth">
	// 	<!-- style="border:solid 1px red" -->
	// 	<!-- 
	// 		<div style="
	// 			padding:0px 10px;position:absolute;top:-10px;left:50%;height:20px;font-size:12px; transform:translate(-50%, 0px);background-color:white;border-radius:5px;">
	// 			visual-touch area
	// 		</div>
	// 	-->
	// 	<div class="slider-container absolute" >
			
	// 		<div class="absolute scroll">
	// 			<div class="absolute"  [style.left.px]="-containerWidth">
	// 				<div class="left-container slide" [style.transform]="leftTransform">
	// 					<ng-container #leftVC></ng-container>
	// 				</div>
					
	// 			</div>

	// 			<div 
	// 				class="absolute" 
	// 				[style.left.px]="containerWidth">
	// 				<div class="right-container slide" [style.transform]="rightTransform">
	// 					<ng-container #rightVC></ng-container>
	// 				</div>
	// 			</div>
	// 		</div>
	// 		<div 
	// 			class="transformContainer absolute" 
	// 			[class.busy]="controlState.busy"
	// 			>
	// 			<div class="absolute center-page-container"  [style.transform]="'scale('+scale+')'">
	// 				<div class="center-container slide" (tap)="onTap($event)">
	// 					<ng-container #centerVC></ng-container>
	// 					<div class="scoringBorder" *ngIf="context && context.config && context.config.markingEnabled && context.config.viewMode=='scoring'"></div>
	// 				</div>
	// 			</div>
	// 		</div>
	// 	</div>
	// </div>
	
	// <div 
	// 	*ngIf="context && context.showDefaultAnswer"
	// 	class="default-answer-watermark"> 
	// 	{{"ro.page.default_answer_label"|translate}}
	// </div>
	// <ro-audio-player *ngIf="!embeded" class="top-audio-bar" [playerManager]="playerManager"></ro-audio-player>



	// <!--
	// <div class="book-index">
	// 	index:{{bookConfig.index}}
	// </div>
	// <div class=" absolute" style="left:0px;top:0px; background-color:white">
	// 		<div (click)="button1()">scale</div>
	// 		<div (click)="button2()">translate</div>
	// 		<div (click)="button2x()">translate2</div>
			
	// 		<div (click)="button3()">translate and scale</div>
	// 		<BR>
	// 		<div #test class="absolute" style="background:red;top:100px" >
	// 			transform
	// 		</div>
	// 	info:{{info|json}}
	// </div>
	// -->
	// `,
	styleUrls:["./ROPageSliderComponent.scss"]
})

export class ROPageSliderComopnent implements OnInit, AfterViewInit, OnChanges, OnDestroy{
	
	@ViewChild("touch", {static: false}) touch: ElementRef;
	@ViewChild("leftVC", {read: ViewContainerRef, static: false}) leftVC: ViewContainerRef;
	@ViewChild("rightVC", {read: ViewContainerRef, static: false}) rightVC: ViewContainerRef;
	@ViewChild("centerVC", {read: ViewContainerRef, static: false}) centerVC: ViewContainerRef;
	
	@Output() public emitter:EventEmitter<any> = new EventEmitter();
	@Output() public commonScaleY:number = 1;
	@Input() public zoom:number = 1;
	@Output() public zoomChange:EventEmitter<number> = new EventEmitter();
	@Input() public padding:number = 50;
	@Input() public maxWidth: string = 'none';
	@Input() bookConfig:ROBookConfig;
	@Input() panAction:string = "page";
	@Input() rawDataAndSettings:any;
	@Input() debugName:string = "";
	@Input() isTransformEnable: boolean;
	@Input() show_change_xml_button: boolean = true;
	@Input() show_good_work_bar: boolean = true;
	@Output() on_transform_start = new EventEmitter();

	public zIndex:number = 0;
	
	public info:any = {};

	public containerHeight:number = 1024;
	public containerWidth:number = 1024;
	
	public pageScale:number = 1;
	private hammerControl: HammerControl;
	private hammerTransformControl: HammerTransformControl;
	
	public leftOn:boolean;
	public rightOn:boolean;
	
	public pages:any [];
	public scale:number = 0.1;
	public centerOffsetY:number = 0;
	public leftScale:number = 1;
	public rightScale:number = 1;
	
	public leftTransform:string = "";
	public rightTransform:string = "";
	
	public leftPage:any;
	public rightPage:any;
	public centerPage:any;

	public currentPageScale:number = 1;
	public context:ROContext;
	public playerManager:ROAudioPlayerManager;
	
	@Output() topZIndex: number;

	protected _viewFitType:string="zoom_all";
	public embeded:boolean = false;
	public controlState:any;
	public mruCache:MRUCache;

	public book_xml = "";
	public chapterID: number | null;

	public good_work_data = null;
	constructor(
		private pinyinKeyboardService:PinYinKeyboardService,
		private uls:UploadService,
		private fileIO:FileIOService,
		private alertService:AlertService,
		// private ngZone:NgZone,
		public elementRef:ElementRef,
		private dataService:DataService,
		private dcs:DynamicComponentService,
		private title:Title,
		private documentService:RODocumentService,
		private translate:TranslateService,
		private roContextService:ROContextService,
		public oup: OupService,
	) {
		this.mruCache = new MRUCache(2, this.onTearDown.bind(this));
		this.controlState = {
			busy:false,
			transforming:false,
			drawing:false,
			on:new Subject(),
			off:new Subject(),
		};
		this.controlState.observable = merge(
			this.controlState.on,
			this.controlState.off.pipe(debounceTime(300))
		);
		var container:HTMLElement = this.elementRef.nativeElement;
		this.pinyinKeyboardService.container = container;
		
		
		this.context = new ROContext();
		this.context.service = this.roContextService;

		this.controlState.observable.subscribe((o:any)=>{
			this.controlState.busy =  this.controlState.drawing || this.controlState.transforming;
		})
		this.context.subject.subscribe((data:any)=>{
			if(data.type == "action")
			{
				this.processAction(data.action, data);
			} else if(data.type == "notify")
			{
				this.processNotify(data);
			}
		})

		this.context.assetUploader = new ROAnswerAssetUpload(dataService, uls);
		// this.context.alertService = this.alertService;
		// this.context.resourceSource = new ResourceSource(dataService, this.roContextService.fileIO);
		// this.context.documentService = documentService;
		// this.context.dataService = this.dataService;
		// this.context.dcs = dcs;
		// this.context.translateService = translate;
		// this.context.map = new ROComponentMap();
		this.playerManager = this.context.playerManager = new ROAudioPlayerManager(
			this.roContextService.resourceSource
		);//  new TTSManager();
		// this.context.fileIO = this.fileIO;
		var tmp = AllROComponents.concat(
			ROComponentDefinition
		);
		var componentMap:any = {};
		tmp.forEach((_constructor)=>{
			var names = _constructor.prototype.getTagNames();
			// console.log(_constructor, names);
			names.forEach((name:string)=>{
				componentMap[name] = _constructor;
			});
		})
		
		this.context.map.init(componentMap, ROUnknownComponent);
		this.zIndex = TopLayer.getNextIndex();
		var currentZIndex:number = this.zIndex;
		var list:string[] = ["basic", "popup-button", "popup-note","popup", "ui", "button",  "dragging-item", "verify",  "keyboard", "top"];
		this.context.layers = {};
		list.forEach((name:string, index:number)=>{
			this.context.layers[name] = index + currentZIndex;
		});
		this.context.service.numberPadService.setupContainer(this.elementRef.nativeElement);
	}
	ngOnDestroy(): void {
		if(this.pages)
			this.pages.forEach((page:any)=>{
				if(page && page.ref)
				{
					var componentRef:ComponentRef<ROPageComponentContainer> = page.ref;
					componentRef.instance.deactivate();
					componentRef.destroy();
					page.ref = null;
				}
			});

	}

	@Input() public set loadReference(input:any){
		if(input)
		{
			setTimeout(()=>{
				this.setPageData(input.index, input.student, input.source);
			}, 100);
		}
		/*
		this.loadReference = {
			index:index,
			student:studAns,
			source:source
		};
		// this.slider2.setPageData(index, studAns, source);
		this.bookConfig2.index = index;
		this.bookConfig2.student = studAns;
		this.bookConfig2.dataSource = source;
		*/
	};

	getCurrentPageInfo(): any {
		return {
			book:this.bookConfig.book,
			page:this.centerPage.page,
			chapter:this.centerPage.page.chapter
		};
	}
	
	public getContext():ROContext
	{
		return this.context;
	}
	private findClassnameElement(className:string, dom:HTMLElement):HTMLElement
	{
		while(dom)
		{
			if(dom.classList.contains(className))
			{
				return dom;
			}
			dom = dom.parentElement;
		}
		return null;
		/*
		if(dom.classList.contains("pinyin-keyboard"))
		{
			this.pinyinKeyboardService.switchTarget(dom);
		} else {
			this.pinyinKeyboardService.switchTarget(null);
		}
		*/
		// var found:any = DOMHelper.test(".pinyin-keyboard", dom);
		return dom;
	}
	
	onTap(event:any):void
	{
		if(!this.context.isAnswerEnabled()) return;

		var dom:HTMLElement = event.target;
		var pinyinElement = this.findClassnameElement("pinyin-keyboard", dom);
		this.pinyinKeyboardService.switchTarget(pinyinElement);
	}
	private processAction(action:any, data:any):void
	{
		if(action == "button")
		{

			/*
			{
				type:"action", 
				action:"button",
				data:{
					type:"page",
					page:options.pageID
				}
			}
			*/
			var tmp:any = data.data;
			var flag:boolean;
			if(tmp.type == "page")
			{
				if(tmp.page.indexOf("|") != -1)
				{
					flag = this.goToPageByID(tmp.page.split("|")[1]);
				} else {
					flag = this.goToPageByID(tmp.page);
				}
				if(!flag)
				{
					this.alertService.alert("page not found");
				}
			}
			// this.goToPageByID()
		} else if(action == "next-page")
		{
			this.nextPage();
		} else if(action == "prev-page")
		{
			this.prevPage();
		} else if(action == "scoringToolbar") {
			let page:any = this.centerPage;
			let pc:ROPageComponentContainer = page.ref.instance;
			if(pc) pc.markingLayer.setSelectTool(data.data);

			if(this.hammerTransformControl)
				this.hammerTransformControl.setPanEnable(data.data == 'app-hand');
				
		}
	}
	
	clearEpen() {
		// this.centerPage = this.replace(this.pageIndex, this.centerPage, this.centerVC);
		var pageComponent:ROPageComponent = this.getCurrentPageComponent();
		pageComponent.clearEpen();
	}
	undoEPen():void
	{
		var pageComponent:ROPageComponent = this.getCurrentPageComponent();
		pageComponent.undoEPen();
	}

	processNotify(data:any):void
	{
		if(data && data.notify && data.notify.type)
		{
			var notifyType = data.notify.type;
			if(notifyType == "ePenStart")
			{
				this.controlState.drawing = true;
				this.controlState.on.next(null);

			} else if(notifyType == "ePenEnd")
			{
				this.controlState.drawing = false;
				this.controlState.off.next(null);
			} else if(notifyType == "transformStart")
			{
				this.controlState.transforming = true;
				this.controlState.on.next(null);
			} else if(notifyType == "transformEnd")
			{
				this.controlState.transforming = false;
				this.controlState.off.next(null);
			}
			
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.zoom) {
			this.onZoomInputChanged();
		}
		
		if(changes.panAction && this.hammerTransformControl) {
			// this.hammerTransformControl.panAction = this.panAction;
			this.hammerTransformControl.release();
				
		}

		if(changes.rawDataAndSettings && changes.rawDataAndSettings.previousValue==null && changes.rawDataAndSettings.currentValue!=null) {
			if(this.rawDataAndSettings.rawData)
				this.openByRawData(this.rawDataAndSettings.book,this.rawDataAndSettings.rawData, this.rawDataAndSettings.setting);
		}

		if(changes.isTransformEnable !== undefined && this.hammerTransformControl) {
			this.hammerTransformControl.enabled = this.isTransformEnable;
		}
			
	}

	onZoomInputChanged() {
		this.currentPageScale = this.zoom / this.scale;
		/*if(this.centerPage && this.centerPage.dom)
		{
			var dom:HTMLElement = this.elementRef.nativeElement;
			var page:HTMLElement = dom.querySelector(".transformContainer");
			var transform:any = DOMHelper.getElementTransform3D(page);
			page.style.transform = `translate3d(${transform.x}px, ${transform.y}px, 0px) scale(${this.currentPageScale})`;
		}*/
	}

	public setZoom(value:number):void {
		this.hammerTransformControl.setZoom(value);
	}

	public refresh():void
	{
		this.rebuildCurrentPage();
	}
	rebuildCurrentPage():void
	{
		this.destoryCurrentPage();
		this.rebuildPages("refresh",true);
	}
	

	private getPageByIndex(index:number):any
	{
		if(index < 0)
		{
			return null;
			
		} else if(index >= this.pages.length)
		{
			return null;
		}
		return this.pages[index];
	}
	ngOnInit(): void {
		
	}

	ngAfterViewInit(): void {
		
		this.emitter.next({
			type:"afterViewInit",
			view:this
		});

		if(this.bookConfig) {
			setTimeout(()=>{
				this.initVariables();
				this.emitter.next({
					type:"context",
					view:this,
					context:this.context
				});
			}, 0);
		}
	}

	private initVariables():void
	{
		// console.log(" this.abc instanceof ViewContainerRef ? ", this.abc instanceof ViewContainerRef);
		this.context.answerEnabled = ["view", "preview", "correction", 'lesson'].indexOf(this.bookConfig.viewMode) >= 0;
		
		this.initPage();
		if(!this.embeded)
			this.initTouchHandler();
	
		// var dom:HTMLElement = this.elementRef.nativeElement;
		// var centerContainer:HTMLElement = dom.querySelector(".center-container");
		// var leftContainer:HTMLElement = dom.querySelector(".left-container");
		// var rightContainer:HTMLElement = dom.querySelector(".right-container");
	
		this.context.updateBookPageNumber(this.bookConfig);
		this.rebuildPages("init");
		this.viewFitType = "zoom_all";
		
		this.pageIndexChange.emit({pageIndex:this.pageIndex, by:"init"});

		this.title.setTitle(this.bookConfig.book.title);
	}

	public set viewFitType(value:string) {
		this._viewFitType = value;
		var dom:HTMLElement = this.elementRef.nativeElement;
		var scroll:HTMLElement = dom.querySelector(".scroll");
		var page:HTMLElement = dom.querySelector(".transformContainer");
		this.currentPageScale = 1;
		this.updateScale();
		this.updateZoom();
		scroll.style.transform = "translate3d(0px, 0px, 0px)";
		page.style.transform = `translate3d(0px, ${this.centerOffsetY}px, 0px)`;
		/*
		scroll.style.transform = "translate3d(0px, 0px, 0px)";
		if(this._viewFitType == "zoom_all") {
			// 顯示全頁, 預設位置置中
			page.style.transform = "translate3d(0px, 0px, 0px)";
			this.updateZoom();
			
		} else {
			// 闊對齊, 預設位置頁頂
			var p2:number = this.padding * 2;
			var size:any = {
				width:this.containerWidth - p2, 
				height:this.containerHeight - p2
			};
			var w:number = this.centerPage.page.pageNode.getAttribute("w");
			var h:number = this.centerPage.page.pageNode.getAttribute("h");
			var wScale:number = size.width / w;
			var hScale:number = size.height / h;
			var targetScale:number = wScale <= hScale ? hScale : wScale;
			this.currentPageScale = targetScale/this.scale;
			this.hammerTransformControl.setZoom(this.currentPageScale);
			
			var info:any = DOMHelper.getElementTransform3D(page);
			var maxYOffset:number = (targetScale * this.centerPage.page.pageNode.getAttribute("h") - this.containerHeight)/2;
			page.style.transform = `translate3d(0px, ${maxYOffset}px, 0px) scale(${this.currentPageScale})`;
		}
		*/
		
	}
	public get viewFitType():string {
		return this._viewFitType;
	}
	
	private updateScale():void
	{
		var p2:number = this.padding * 2;
		var size:any = {
			width:this.containerWidth - p2, 
			height:this.containerHeight - p2
		};
		var info:any 
		info = this.calculateTransformInfo(this.centerPage, size);
		this.scale = info.scale;
		this.centerOffsetY = info.offsetY;

		info = this.calculateTransformInfo(this.leftPage, size);
		this.leftScale = info.scale;
		this.leftTransform =  `matrix(${info.scale}, 0, 0, ${info.scale}, 0, ${info.offsetY})`;
		
		info = this.calculateTransformInfo(this.rightPage, size);
		this.rightScale = info.scale;
		this.rightTransform =  `matrix(${info.scale}, 0, 0, ${info.scale}, 0, ${info.offsetY})`;
	}

	private updateZoom():void
	{
		this.zoom = this.scale * this.currentPageScale;
		this.zoomChange.emit(this.zoom);
	}

	onResized(event:any):void
	{
		this.containerHeight = event.newHeight;
		this.containerWidth = event.newWidth;
		this.updateScale();
		this.updateZoom();
	}

	private calculateTransformInfo(page:any, size:any):any
	{
		if(!page) return {scale:1, offsetY:0};
		var w:number = page.page.pageNode.getAttribute("w");
		var h:number = page.page.pageNode.getAttribute("h");
		var wScale:number = size.width / w;
		var hScale:number = size.height / h;
		if(this._viewFitType == "zoom_fitwidth"){
			return {
				scale:wScale,
				offsetY:wScale <= hScale ? 0 : (h * wScale - size.height) / 2
			}
		} else {
			return {
				scale:wScale <= hScale ? wScale : hScale,
				offsetY:0
			}
		}
	}

	private initPage():void
	{
		this.context.config  = this.bookConfig;
		this.pages = this.bookConfig.pages.map((page:any, index:number)=>{
			// <ro-page #pagesComponents [scale]="pageScale" [page]="page" *ngFor="let page of pageConfig.pages" >
			/*
			var componentRef:ComponentRef<ROPageComponent> = this.dcs.createComponentRef(
				ROPageComponent, 
				{
					scale:0.5,
					page:page
				}
			);
			*/
			return {
				// ref:componentRef,
				index:index,
				page:page
			};
		})
	}
	private initTouchHandler():void
	{
		if (this.isTransformEnable) {
			this.hammerControl = new HammerControl(this.touch.nativeElement);
			var dom:HTMLElement = this.elementRef.nativeElement;
			var page:HTMLElement = dom.querySelector(".transformContainer");
	
			var scroll:HTMLElement = dom.querySelector(".scroll");
			this.hammerTransformControl = new HammerTransformControl(this.touch.nativeElement, page);
			console.log(this.hammerTransformControl);
			// this.hammerTransformControl.panAction = this.panAction;
			this.hammerTransformControl.pinZoomEnable = true;
			this.hammerTransformControl.wheelZoomEnabled = true;
			// var pageSize:number = 400;
			var halftPage:number = this.containerWidth/2;

			var pageW:number;
			var pageH:number;
			var isTransforming = false;
			this.hammerTransformControl.transformStart.subscribe((d:any)=>{
				pageW = this.centerPage.page.pageNode.getAttribute("w");
				pageH = this.centerPage.page.pageNode.getAttribute("h");
				this.stop(page);
				this.stop(scroll);
				scroll.style.willChange = "transform";
				page.style.willChange = "transform";
				isTransforming = false;
				// page.classList.add("transforming");
				// this.transforming = true;
				this.context.subject.next({
					type:"notify",
					notify:{
						type:"transformStart"
					}
				});
				// this.processing = false;
			});

			this.hammerTransformControl.transformCancel.subscribe(()=>{
				page.classList.remove("transforming");
				this.stop(page);
				this.stop(scroll);
				this.context.subject.next({
					type:"notify",
					notify:{
						type:"transformEnd"
					}
				});
			})
			/*
			this.hammerTransformControl.subscriptionEnd.subscribe(()=>{
				if(!this.processing)
				{
					this.stop(page);
					this.stop(scroll);
				}
			})
			*/
			this.hammerTransformControl.transform.subscribe((t:any)=>{
				if(isTransforming == false)
				{
					isTransforming = true;
					page.classList.add("transforming");
				}
				if(t.scale > 2)
				{
					t.scale = 2;
				} else if(t.scale < 0.5)
				{
					t.scale = 0.5;
				}
				var localScale:number = t.scale * this.scale;
				var scaledPageW:number = localScale * pageW;
				var scaledPageH:number = localScale * pageH;

				var scaledHalfPageH:number = scaledPageH / 2;
				var halfPageW:number = Math.max(scaledPageW, this.containerWidth)/2;
			
				var halfContainerH:number = (this.containerHeight)/2;
				var maxYOffset:number = scaledHalfPageH - halfContainerH;
			
				var left:number = t.x - halfPageW;
				var right:number = t.x + halfPageW;
			
				this.leftOn = left > -halftPage;
				this.rightOn = right < halftPage;
				var px:number
				if(maxYOffset <= 0)
				{
					t.y = 0;
				} else if(t.y > maxYOffset)
				{
					t.y = maxYOffset;
				} else if(t.y < -maxYOffset)
				{
					t.y = -maxYOffset;
				}

				if(this.leftOn)
				{
					px = (left + halftPage );
					scroll.style.transform = `translate3d(${px}px, 0px, 0px)`;
				} else if(this.rightOn)
				{
					px = (right - halftPage);
					scroll.style.transform = `translate3d(${px}px, 0px, 0px)`;
				} else {
					scroll.style.transform = `translate3d(0px, 0px, 0px)`;
				}
				this.currentPageScale = t.scale;
				this.updateZoom();
			});
			this.hammerTransformControl.transformEnd.subscribe((info:any)=>{
				page.classList.remove("transforming");
				var t:any = info.transform;
				var evt:any = info.evt;
				if(evt && evt.gesture)
				{
					t.x += evt.gesture.overallVelocityX * 200;
				}
			
				var scaledPageW:number = t.scale* pageW * this.scale;
				var scaledPageH:number = t.scale * pageH * this.scale;
				var halfPageW:number = Math.max(scaledPageW, this.containerWidth)/2;
				var halfPageH:number = Math.max(scaledPageH, this.containerHeight)/2;
			
				var left:number = t.x - halfPageW;
				var right:number = t.x + halfPageW;

				var top:number = t.y - halfPageH;
				var bottom:number = t.y + halfPageH;
			
				this.leftOn = left > 0;
				this.rightOn = right < 0;
				var pan:boolean = false;
			
				// if(this.panAction=="page") {
				if(left > (- this.containerWidth / 2)) 
				{
					pan = true;
					t.x -= (left + this.containerWidth / 2);
				} else if(right < (this.containerWidth / 2)) 
				{
					pan = true;
					t.x -= (right - (this.containerWidth/2)) ;
				}
	
				if(top > (- this.containerHeight / 2))
				{
					pan = true;
					t.y -= (top + this.containerHeight / 2);
				} else if(bottom < (this.containerHeight / 2))
				{
					pan = true;
					t.y -= (bottom - (this.containerHeight/2)) ;
				}
				/*
				} else if(this.panAction=="student") {
					if(left > (- this.containerWidth / 4))
					{
						pan = true;
						t.x -= (left + this.containerWidth / 2);
					} else if(right < (this.containerWidth / 4))
					{
						pan = true;
						t.x -= (right - (this.containerWidth/2)) ;
					}

					if(top > (- this.containerHeight / 2))
					{
						pan = true;
						t.y -= (top + this.containerHeight / 2);
					} else if(bottom < (this.containerHeight / 2))
					{
						pan = true;
						t.y -= (bottom - (this.containerHeight/2)) ;
					}
				} else {
					if(left > (- this.containerWidth / 4))
					{
						pan = true;
						t.x -= (left + this.containerWidth / 4);
					} else if(right < (this.containerWidth / 4))
					{
						pan = true;
						t.x -= (right - (this.containerWidth/4)) ;
					}

					if(top > (- this.containerHeight / 4))
					{
						pan = true;
						t.y -= (top + this.containerHeight / 4);
					} else if(bottom < (this.containerHeight / 4))
					{
						pan = true;
						t.y -= (bottom - (this.containerHeight/4)) ;
					}
				}
				*/
			
				if(this.leftOn)
				{
					if(this.panAction=="page") {
						if(!this.prevPage())
						{
							this.transformTo(scroll, `translate3d(0px, 0px, 0px)`);
							this.transformTo(page, `translate3d(0px, ${t.y}px, 0px) scale(${t.scale})`);
						} else {
							// this.stop(scroll);
							// this.stop(page);
						}

					} else if(this.panAction=="student") {
						this.targetPageIndex = this.pageIndex - 1;
						// this.stop(scroll);
						this.transformTo(page, `translate3d(${this.containerWidth}px, 0px, 0px) scale(${t.scale})`);
					} else {
						// this.stop(scroll);
						// this.stop(page);
						this.transformTo(page, `translate3d(0px, 0px, 0px) scale(${t.scale})`);
					}
				} else if(this.rightOn) 
				{
					if(this.panAction=="page") {
						if(!this.nextPage())
						{
							this.transformTo(scroll, `translate3d(0px, 0px, 0px)`);
							this.transformTo(page, `translate3d(0px, ${t.y}px, 0px) scale(${t.scale})`);
						} else {
							// this.stop(scroll);
							//this.stop(page);
						}
					} else if(this.panAction=="student") {
						this.targetPageIndex = this.pageIndex + 1;
						this.transformTo(page, `translate3d(${-this.containerWidth}px, 0px, 0px) scale(${t.scale})`);
						// this.stop(scroll);
					} else {
						// this.stop(scroll);
						// this.stop(page);
						this.transformTo(page, `translate3d(0px, 0px, 0px) scale(${t.scale})`);
					}
				} else {
					var currentScrollTransform:any = DOMHelper.getElementTransform3D(scroll);
					if(currentScrollTransform.x != 0)
					{
						this.transformTo(scroll, `translate3d(0px, 0px, 0px)`);
					} else {
						this.stop(scroll);
					}
					if(pan){
						this.transformTo(page,  `translate3d(${t.x}px, ${t.y}px, 0px) scale(${t.scale})`);
					} else {
						this.stop(page);
					}
				}

				// 加入標記動畫完成時是做轉頁/轉人
				page.setAttribute("transitionendAction", this.panAction);

				this.context.subject.next({
					type:"notify",
					notify:{
						type:"transformEnd"
					}
				});

			})
		
			this.monTransitionEnd(page);
			this.monTransitionEnd(scroll);
			fromEvent(page, "transitionend").subscribe((o:any)=>{
				var t:any = DOMHelper.getElementTransform3D(page);

				if(page.getAttribute("transitionendAction") =="student") {
					if(this.targetPageIndex < this.pageIndex) {
						this.targetPageIndex = this.pageIndex;
						this.emitter.emit({type:'prevAnsweredStudent'});
	
						page.style.transform = `translate3d(0px, ${this.centerOffsetY}px, 0px) scale(${t.scale}) `;
	
					} else if(this.targetPageIndex > this.pageIndex) {
						this.targetPageIndex = this.pageIndex;
						this.emitter.emit({type:'nextAnsweredStudent'});
	
						page.style.transform = `translate3d(0px, ${this.centerOffsetY}px, 0px) scale(${t.scale})`;
					}
				} else {
					this.rebuildPages("transitionend");this.updateScale();
				}
			
				// this.transforming = false;
				this.context.subject.next({
					type:"notify",
					notify:{
						type:"transformEnd"
					}
				});
			});
		}
	}
	// private slideChanged:boolean = true;
	private rebuildPages(byMethod:string, force:boolean = false):void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var scroll:HTMLElement = dom.querySelector(".scroll");
		var page:HTMLElement = dom.querySelector(".transformContainer");
		if(this.targetPageIndex != this.pageIndex || force)
		{
			/*
			if(this.context.showDefaultAnswer)
				this.context.showDefaultAnswer = false;
			*/
			this.pageIndex = this.targetPageIndex;

			this.emptyContainerRef(this.centerVC, this.centerPage, true);
			this.emptyContainerRef(this.leftVC, this.leftPage, false);
			this.emptyContainerRef(this.rightVC, this.rightPage, false);
			
			this.leftPage = this.replace(this.pageIndex-1, this.leftPage, this.leftVC, false);
			this.rightPage = this.replace(this.pageIndex+1, this.rightPage, this.rightVC, false);
			this.centerPage = this.replace(this.pageIndex, this.centerPage, this.centerVC, true);
			
			this.addToCache([this.centerPage]);
			
			this.updateScale();
			
			this.currentPageScale = 1;
			scroll.style.transform = "translate3d(0px, 0px, 0px)";
			page.style.transform = `translate3d(0px, ${this.centerOffsetY}px, 0px)`;
			
			this.updateZoom();
			this.pageIndexChange.emit({pageIndex:this.pageIndex, by:byMethod});
			this.onPageBuilt();
		}
	}
	addToCache(elements: any[]) {
		elements.forEach((element)=>{
			if(element)
			{
				element.active = 1;
				var key = "X" + element.index;
				this.mruCache.removeCache(key);
			}
		})

		elements.forEach((element)=>{
			if(element)
			{
				var key = "X" + element.index;
				this.mruCache.addCache(key, element);
			}
		})
	}
	

	setPageData(index:any, student: any, source:AnswerSource) {
		this.bookConfig.index = index;
		this.bookConfig.dataSource = source;
		this.bookConfig.student = student;
		var page:any = this.centerPage;
		var componentRef = page.ref;
		var pageComponent:ROPageComponentContainer = componentRef.instance;

		pageComponent.setPageData(source);
		// 如果是顯示答案狀態，自動開返 default answer。
		if(this.bookConfig && this.bookConfig.subViewMode == "answer")
			this.showDefaultAnswer();
		this.context.subject.next({
			type:"notify", 
			notify:{
				type:"pageDataChanged"
			}
		});
	}

	onPageBuilt() {
		var pageID:string = this.centerPage.page.attributes.douid;
		var chapterID:string = this.centerPage.page.chapter.id;
		var pageCom:ROPageComponent = this.centerPage.ref.instance.pageCom;
		
		this.emitter.next({
			type:"pageInfo", 
			pageInfo:{
				chapterID:chapterID,
				pageID:pageID,
				questionCount:pageCom.getQuestionCount()
			},
			pageSlide:this
		});
	}

	private emptyContainerRef(containerRef:ViewContainerRef, pageObject:any, deactivateFlag:Boolean):void
	{
		if(pageObject) {
			pageObject.active = 0;
		}
		if(deactivateFlag && pageObject && pageObject.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = pageObject.ref;
			componentRef.instance.deactivate();
		}
		while(containerRef.length)
		{
			containerRef.detach(0);
		}
	}

	private onTearDown(pageObject:any)
	{
		if(pageObject && !pageObject.active)
		{
			this.destoryPageReference(pageObject);
		}
	}

	private destoryPageReference(pageObject:any):void
	{
		pageObject.active = 0;
		if(pageObject && pageObject.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = pageObject.ref;
			componentRef.instance.deactivate();
			componentRef.destroy();
			pageObject.ref = null;
		}
	}

	private destoryCurrentPage()
	{
		// this.centerPage = this.replace(this.pageIndex, this.centerPage, this.centerVC);
		var page = this.centerPage;
		// var page:any = this.getPageByIndex(this.pageIndex);	
		if(page == null) return page;
		if(page.ref)
		{
			var componentRef:ComponentRef<ROPageComponentContainer> = page.ref;
			componentRef.instance.deactivate();
			componentRef.destroy();
			page.ref = null;
		}
	}

	private replace(pageIndex:number, currentPageObject, vc:ViewContainerRef, isCenter:boolean):any
	{
		var page:any = this.getPageByIndex(pageIndex);	// XMLNode
		if(page == null) return page;
		var componentRef:ComponentRef<ROPageComponentContainer>
		if(page.ref)
		{
			componentRef = page.ref;
		} else {
			if(isCenter)
			{
				componentRef  = this.dcs.createComponentRef(
					ROPageComponentContainer, 
					{
						book:this.bookConfig.book,
						page:page.page,
						context:this.context
					}
				);
				page.ref = componentRef;
				componentRef.instance.build();
			}
		}

		
		
		if(isCenter) {
			if(this.bookConfig.dataSource && componentRef.instance.hasData == false)
			{
				componentRef.instance.setPageData(this.bookConfig.dataSource);
			}
			
			var pageInstance:ROPageComponentContainer = componentRef.instance;
			pageInstance.activate();

			this.context.showDefaultAnswer = false;
			pageInstance.pageCom.dataComponents.forEach((c:ROComponent) => {
				c.hideDefaultAnswer();
			});
		}
		if(componentRef)
		{
			var containerElement:HTMLElement = vc.element.nativeElement.parentElement;
			var pageNode:any = page.page.pageNode;
			var w:number = pageNode.getAttribute("w");
			var h:number = pageNode.getAttribute("h");
			containerElement.style.left = (-w/2) +"px";
			containerElement.style.top = (-h/2) +"px";
			
			vc.insert(componentRef.hostView);
		}
		return page;
		
	}

	public pageIndex:number = -1;
	//public targetPageIndex:number = 0;
	public get targetPageIndex():number {
		if(!this.bookConfig)
			debugger; // not ready
		//console.log("get pageIndex >>>>>>>>>>>>>>>> ", this.bookConfig,this.debugName);
		return this.bookConfig ? this.bookConfig.pageIndex : 0;
	}

	public set targetPageIndex(val:number) {
		if(this.bookConfig)
			this.bookConfig.pageIndex = val;
		else
			debugger; // not ready
	}

	@Output() pageIndexChange:EventEmitter<any> = new EventEmitter();

	private monTransitionEnd(element:HTMLElement):void
	{
		fromEvent(element, "transitionend").subscribe(
			(event:any)=>{
				this.stop(element);
			}
		);
	}

	private stop(element:HTMLElement):void
	{
		element.style.transition = "";
		element.style.willChange = "";
	}

	private transformTo(element:HTMLElement, transform:string):void
	{
		if(transform != element.style.transform)
		{
			element.style.transition = "transform 0.2s";
			element.style.willChange = "transform";
			element.style.transform = transform;
		} else {
			element.style.willChange = "";
		}
	}

	public hasPrevPage():boolean
	{
		if(!this.pages) return false;
		return this.pageIndex > 0;
	}

	public hasNextPage():boolean
	{
		if(!this.pages) return false;
		return this.pageIndex + 1 < this.pages.length;
	}
	
	public prevPage():boolean
	{
		if(this.hasPrevPage())
		{
			var dom:HTMLElement = this.elementRef.nativeElement;
			var page:HTMLElement = dom.querySelector(".transformContainer");
		
			var scroll:HTMLElement = dom.querySelector(".scroll");
			// pan to right
			this.targetPageIndex = this.pageIndex - 1;
			page.setAttribute("transitionendAction", "page");
			this.transformTo(scroll, `translate3d(${this.containerWidth}px, 0px, 0px)`);
			this.transformTo(page, `translate3d(${this.containerWidth}px, 0px, 0px)`);
			return true;
		} else {
			return false;
		}
		
	}

	
	public nextPage():boolean
	{
		if(this.hasNextPage())
		{
			var dom:HTMLElement = this.elementRef.nativeElement;
			var page:HTMLElement = dom.querySelector(".transformContainer");
			var scroll:HTMLElement = dom.querySelector(".scroll");
			this.targetPageIndex = this.pageIndex + 1;
			page.setAttribute("transitionendAction", "page");
			this.transformTo(scroll, `translate3d(${-this.containerWidth}px, 0px, 0px)`);
			this.transformTo(page, `translate3d(${-this.containerWidth}px, 0px, 0px)`);
			return true;
		} else {
			return false;
		}
	}

	
	
	goToPageByID(douid:string):boolean
	{
		var pageIndex:number = this.getPageIndexByID(douid);
		if(pageIndex == -1) return false;
		this.targetPageIndex = pageIndex;
		this.rebuildPages("goToPageByID");
		return true;
	}

	private getPageIndexByID(douid:string):number 
	{
		for(var i:number = 0; i < this.bookConfig.pages.length;i++)
		{
			var page:any = this.bookConfig.pages[i];
			var pageNode:XMLNode = page.pageNode;
			if(pageNode.getAttribute("douid") == douid)
			{
				return i;
			}
		}
		return -1;
	}
	onTransforming(info:any):void
	{
		console.log("onTransforming", info);
	}

	@HostListener('window:info', ['$event'])
	onResize(event:CustomEvent) {
		var detail:any = event.detail;
		if(detail.key == "action")
		{
			if(detail.value == "clear")
			{
				this.info = {};
			}
		} else {
			this.info[detail.key] = detail.value;
			var tmp = this.info;
			this.info = null;
			this.info = tmp;
		}
		
	}

	public getCurrentPageComponent():ROPageComponent
	{
		return this.centerPage.ref.instance.pageCom;
	}


	toggleDefaultAnswer() {
		if(this.context.showDefaultAnswer)
		{
			this.hideDefaultAnswer();
		} else {
			this.showDefaultAnswer();
		}
	}

	showDefaultAnswer() {
		this.context.showDefaultAnswer = true;
		var page:ROPageComponent = this.getCurrentPageComponent();
		page.showDefaultAnswer();
	}
	
	hideDefaultAnswer() {
		this.context.showDefaultAnswer = false;
		var page:ROPageComponent = this.getCurrentPageComponent();
		page.hideDefaultAnswer();
	}
	
	// =========================
	// data function
	// =========================
	public submitPage():any {
		let page:any = this.centerPage;
		let pc:ROPageComponentContainer = page.ref.instance;
		return this.context.submitPage(pc.pageCom, this.currentPageScale);
	}

	public updateChangeToDataSource():any {
		let page:any = this.centerPage;
		let pc:ROPageComponentContainer = page.ref.instance;
		return this.context.updateChangeToDataSource(pc.pageCom);
	}


	clearAllPagesData() {
		this.resetSpecificPage(this.leftPage, false);
		this.resetSpecificPage(this.rightPage, false);
		this.resetSpecificPage(this.centerPage, false);
	}
	
	private resetSpecificPage(o:any, save:boolean = true):void
	{
		let page:any = o;
		if(!page || !page.ref) return;
		let pc:ROPageComponentContainer = page.ref.instance;
		if(this.bookConfig.viewMode == "correction")
			this.context.resetCorrectionData(pc.pageCom);
		else
			this.context.resetPage(pc.pageCom, save);
		pc.hasData = false;
	}

	public resetPage():void
	{
		this.resetSpecificPage(this.centerPage, true);
		
	}
	print()
	{
		this.alertService.alert("PRINT");
	}
	updatePinYinStatus()
	{
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "pinyin-opacity", this.context.showPinYin ? 1 : 0);
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "pinyin-text-opacity", this.context.showPinYinText ? 1 : 0);
	}
	togglePinYin() {
		this.context.showPinYin = !this.context.showPinYin;
		StyleUtils.setStyleVariable(this.elementRef.nativeElement, "pinyin-opacity", this.context.showPinYin ? 1 : 0);
	}
	setPinYinStatus(options:any)
	{
		this.context.showPinYin = options.showPinYin;
		this.context.showPinYinText = options.showPinYinText;
		this.updatePinYinStatus();
	}

	isDev(){
		return isDevMode()
	}
	get_book_xml(){
		this.book_xml = ""
		this.chapterID = null
		let id = this.bookConfig.book.id
		// id = 2984105;
		console.log(this.bookConfig);
		if(id){
			this.documentService.open({id:id}).then((bookObject:any)=>{
					console.log(">>>> bookObject", bookObject);
					var chapter = bookObject['chapters'][0];
					this.chapterID = chapter.id;
					this.book_xml = chapter.xml; 
					console.log(this.book_xml);
					
			});	
		}
	}
	save_book_xml(){
		let xml = this.book_xml.trim()
		let id = this.bookConfig.book.idleService
		// id = 2984105;
		console.log(xml);
		// debugger
		let list = []
		if (this.chapterID && this.book_xml) {
			list.push({
				version:0,
				id:this.chapterID,
				content:ByteArrayUtils.toBase64(RODoc.toNoCompressBytes(xml)),
				reference:[]
			});
		}
		this.dataService.call("ROBook.update_items", list).then((result:any)=>{
			console.log("save success ====");
		});
	}

	// =========================
	// open embed page function
	// =========================
	public openByRawData(book:any,rawData:any, setting:any):void {
		// 外層在初始時設定 rawDataAndSettings
		var roDocument:RODocument = new RODocument({book:book,chapters:[{title:"",xml:rawData,id:book.id}]}, this.dataService);
		this.bookConfig = roDocument.initAllPage(book, setting);
		this.embeded = setting.embeded;
	}
}


