import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
//import { LoadingModule } from 'src/app/sharedModule/loadingModule/loading.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WhitePopupModule } from '../whitePopupModule/whitePopup.module';
import { FileFormatterComponent } from './fileFormatter.component';
import { BobbleBoxModule } from 'src/app/sharedModule/bobbleBoxModule/bobbleBox.module';
import { FileFormatter2Component } from './fileFormatter2.component';

@NgModule({
    imports: [
        CommonModule, TranslateModule, FormsModule,
		//LoadingModule,
		FontAwesomeModule, 
		BobbleBoxModule,WhitePopupModule
		
    ],
    declarations: [
		FileFormatterComponent, FileFormatter2Component
    ],
    exports: [
		FileFormatterComponent, FileFormatter2Component
    ],
    providers: [
    ],
    entryComponents: [ 
		FileFormatterComponent, FileFormatter2Component
	]
})
export class FileFormatterModule {
}
