import { Component, OnInit, AfterViewInit, ViewChild, Output, EventEmitter, Input, QueryList, ViewContainerRef, ViewChildren, ComponentRef, ElementRef, HostListener, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';

// =======================================
// icon
// =======================================
import { faCamera, faImage, faUp, faCheck, faXmark, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus, faCircleMinus, faPaste } from '@fortawesome/pro-light-svg-icons';
import { faPen, faVolume } from '@fortawesome/pro-solid-svg-icons';
import { faCopy, faPaste as faPasteRegular, faChevronLeft, faChevronRight, faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import lang_tc from './lang/tc.json';
import { TranslateService } from '@ngx-translate/core';
import { FileIOService } from 'src/app/service/FileIO.service.js';
import { CameraCaptureComponent } from '../CameraCaptureModule/CameraCapture.component.js';
import { AlertService } from 'src/app/service/alert.service.js';
import { ObjectUtils } from 'src/app/common/ObjectUtils.js';
import { trace } from 'console';
import { isNumber } from 'util';
import { CdkFixedSizeVirtualScroll } from '@angular/cdk/scrolling';
import { CompactLanguageDetector, MyTTSTextPlayer } from 'src/app/sharedModule/roBookModule/TTSManager.js';
import { TTSService } from 'src/app/service/TTS.service';
import { ROPageComponentContainer } from '../roBookModule/ROPage';

import { ROPageSliderComopnent } from '../roBookModule/ROPageSliderComponent';
import { ROPageComponent } from '../roBookModule/ROComponent';
import { ROAnswerAssetUpload, ROContext, ROContextService } from '../roBookModule/ROContext';
import { StyleUtils } from '../roBookModule/StyleUtils';
import { WhitePopupComponent } from '../whitePopupModule/whitePopup.component';
import { WhitePopupService } from '../whitePopupModule/whitePopup.service';
import { ThemeService } from 'src/app/service/theme.service';
import { CommonService } from 'src/app/service/common.service';
import { SettingService } from 'src/app/service/setting.service';
import { Subject, Subscription } from "rxjs";
import { takeUntil, debounceTime } from 'rxjs/operators';
import { UserInfoHelperService } from 'src/app/service/UserInfoHelper.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { DragManager } from '../roBookModule/DragManager';
import { DOMHelper } from "src/app/common/DOMHelper";
import { MarkingLayer } from '../roBookModule/epen/MarkingLayer';
import { NoteLayerController } from '../roBookModule/epen/NoteLayerController';
import { AppStatusComponent } from "../appStatusModule/AppStatus.component";
import { StickerBase } from '../roBookModule/epen/StickerBase';
import { AnswerSource } from '../roBookModule/ROBookConfig';
import { UploadService } from '../uploadModule/upload.service';
import { PrintBookOption, ROBookPrintOptionDialog } from '../roBookModule/dialog/print_book_dialog';
import { StudentInfo } from "src/app/common/StudentInfo";
import { ROStudentDataSourceManager } from 'src/app/coreModule/BookViewerModule/ROStudentDataSourceManager';
import { PrintService, PrintTask } from '../printModule/PrintService';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { PrintAssessmentView } from './PrintAssessmentView';
import { ProgressBox2Component } from '../progressBoxModule/progressBox2.component';
import { StickerImageService } from 'src/app/service/StickerImageService';

@Component({
	selector: 'AssessmentViewer',
	templateUrl: './AssessmentViewer.component.html',
	styleUrls: ['./AssessmentViewer.component.scss', '../roBookModule/ROComponent.scss'],
})

export class AssessmentViewerComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
	@ViewChild('cameraCapture', { static: true }) public cameraCapture: CameraCaptureComponent;
	@ViewChildren('ps') public ps: QueryList<ROPageSliderComopnent>;
	@ViewChild('epenSwitchBounds', { static: false }) epenSwitchBounds!: ElementRef;
	@ViewChild('viewSettingPopup', { static: false }) viewSettingPopup!: BubbleBox2Component;
	@ViewChildren('markingLayerRefs') public markingLayerRefs: MarkingLayer[];
	@ViewChild('bookPrintDialog', { static: false }) bookPrintDialog!: ROBookPrintOptionDialog;
	@ViewChild('scaleRef', { static: false }) scaleRef!: ElementRef;
	@Input() viewerID: number;
	@Input() ownerID: number;
	@Input() viewMode: string; // preview, print, answer
	@Input() entryAndContent: any;
	@Input() share: any;
	@Input() fontSize: number = 26;
	@Input() speechSpeed: number = 1.1;
	@Input() public onoff_student_list: boolean = false;
	@Input() public onoff_marking_layer: boolean = true;
	@Input() public onoff_teacher_sticker_list: boolean = false;
	@Input() public forceReadOnly: boolean = false;
	@Input() public data;
	@Output() onScoreChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() onProgressChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() public emitter: EventEmitter<any> = new EventEmitter();
	@Output() public optionChange: EventEmitter<any> = new EventEmitter<any>();
	public faCamera: IconDefinition = faCamera;
	public faImage: IconDefinition = faImage;
	public faUp: IconDefinition = faUp;
	public faCirclePlus: IconDefinition = faCirclePlus;
	public faCircleMinus: IconDefinition = faCircleMinus;
	public faCheck: IconDefinition = faCheck;
	public faXmark: IconDefinition = faXmark;
	public faPen: IconDefinition = faPen;
	public faVolume: IconDefinition = faVolume;
	public faPaste: IconDefinition = faPaste;
	public faPasteRegular: IconDefinition = faPasteRegular;
	public faCopy: IconDefinition = faCopy;
	public faLeft: IconDefinition = faChevronLeft;
	public faRight: IconDefinition = faChevronRight;
	public faDown: IconDefinition = faChevronDown;
	public questionList: any;
	public change: boolean = false;
	public questionVar: any;
	protected sndChannel = new Audio();
	public langData: any;
	public isCommentObjEditable: any = {};
	public teacherCommentsObject: any = {};

	protected dataSource: any;
	public uid: string;

	@Output() onScrollEnd: EventEmitter<any> = new EventEmitter<any>();

	protected layerInited: boolean = false;
	public showManualScore: boolean = false;
	public showAutoScore: boolean = false;
	public wpHandle: Promise<any>;
	public canEdit: boolean = false;
	public scoringMode2Options: any = [];
	public scoringMode2 = 'marking1';
	public questionPointer = 1;
	public onDestroy = new Subject();
	public onScrollSubject = new Subject();
	public toolbarConfig: any;
	public zoomBarVal = 0;
	private dd = new DragManager();
	public context: ROContext;
	public onMarkingChangeSubject: Subject<any> = new Subject();
	public noteLayerController: NoteLayerController;
	@Input() public sourceIndex = 0;
	@Input() public bookViewerDataSource: any;
	public printingState:any = {
		message:"",
		progress:50
	};
	public schoolStickerURL = '';
	constructor(public datas: DataService, private sans: DomSanitizer,
		public translate: TranslateService,
		public fileio: FileIOService,
		private alertService: AlertService,
		private ttsService: TTSService,
		public elementRef: ElementRef,
		private whitePopupService: WhitePopupService,
		public themeService: ThemeService,
		public coms: CommonService,
		public setting: SettingService,
		public userInfo: UserInfoHelperService,
		public roContextService: ROContextService,
		public uls: UploadService,
		public prints: PrintService,
		public dcs: DynamicComponentService,
		public translateService: TranslateService,
		protected stickerImageService: StickerImageService,
	) {
		this.langData = lang_tc;

		themeService.applyStyleObj({
			"default": {
				"edit-formBackground": "#F5F9F4",
				"qSide-background": "#fff",
				"text-color": "#000"
			},
			"dark": {
				"edit-formBackground": "#F5F9F4",
				"qSide-background": "#fff",
				"text-color": "#000"
			}
		}, elementRef.nativeElement);
	}

	@HostListener('window:resize', ['$event']) winResize($event) {
		this.updateViewSize();
	}
	public onScroll($event) {
		this.onScrollSubject.next($event);
	}

	public updateViewSize(): void {
		var e: any = this.elementRef.nativeElement;
		if (this.viewMode != 'print'){
			e.style.setProperty("--viewWidth", e.clientWidth + "px");
			e.style.setProperty("--viewHeight", e.clientHeight + "px");
		}
		this.updateScrollEnd();
	}

	ngOnInit() {
		// data init
		this.noteLayerController = new NoteLayerController();
		if (this.data) {
			this.initContext();
		}
		this.resetQVar();
		this.loadSchoolSettings()
		this.canEdit = !(["scoring", "review"].indexOf(this.viewMode) >= 0 || (this.share && ["aiChecking", "aiError", "checking", "aiMarked"].indexOf(this.share.status) >= 0));
		this.scoringModeListUpdate();
		this.onScrollSubject.pipe(debounceTime(50), takeUntil(this.onDestroy)).subscribe(event => {
			const lastQuestionPointer = this.questionPointer;
			this.questionPointer = 1;
			let eles: any = document.querySelectorAll('.q[qNum]');
			for (let i = 0; i < eles.length; i++) {
				const ele = eles[i];
				const qNum = ele.getAttribute('qNum');
				if (!isNaN(qNum) && qNum != '') {
					const rect = ele.getBoundingClientRect();
					if (rect.top > 0) {
						this.questionPointer = qNum;
						break;
					}
				}
			}
			if (lastQuestionPointer != this.questionPointer) {
				this.updateScoringToolbarState();
			}
			this.updateScrollEnd();
		});

		this.initToolBar();
		this.onMarkingChangeSubject.pipe(
			takeUntil(this.onDestroy),
			debounceTime(1000)).subscribe((params: any) => {
				if (Array.isArray(params)) {
					AppStatusComponent.callAPI('ROBookShare.update_student_result_by_index', params).then((res: any) => {
					}).catch(reason => {
					});
				}
			});
	}

	ngAfterViewInit() {
		this.updateViewSize();
		if (this.questionPointer > 1) {

		}
		window.setTimeout(() => {
			this.updateScrollEnd();
			const ele = document.querySelector('studentanswerctrl');
			if (ele) {
				ele.classList.add('isAssesssViewerMode');
			}
		}, 0);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.context && this.context.config) {
			this.updateBookConfig(this.context.config, this.scoringMode2);
		}
		if (changes.viewMode) {
			// share.status: open(無限期), checking(批改中), aiChecking, finished(已完成)
			this.showAutoScore = this.viewMode == "scoring" || this.viewMode == "preview" || this.viewMode == "previewOnly" ||
				(this.share && (
					this.share.live_verify == 1 || this.share.status == "finished" ||
					(this.share.status.indexOf("checking") >= 0 && this.share.live_verify == 0 && this.datas.userInfo.user_role == 3) ||
					this.share.type == 'normal'
				));

			this.showManualScore = this.viewMode == "scoring" || this.viewMode == "preview" || this.viewMode == "previewOnly" ||
				(this.share && (
					this.share.status == "finished" ||
					(this.share.status.indexOf("checking") >= 0 && this.datas.userInfo.user_role == 3) ||
					this.share.type == 'normal'
				));
		}
	}

	ngOnDestroy(): void {
		this.onDestroy.next();
		this.onDestroy.complete();
	}

	public static isPageQuestionComponent(type: string): boolean {
		return ['QBDragLine', 'DragDropA', 'DragDropC'].indexOf(type) >= 0;
	}

	public copyText(text:string):void {
		text = text.replace(/<\/?[^>]+(>|$)/g, "");
		navigator.clipboard.writeText(text);
		this.alertService.toastSuccess(this.translate.instant("QBEditor.copied") + " \"" + text + "\"");
	}

	public pasteText(qObj: any, grp: number): void {
		navigator.clipboard.readText().then(clipText => {
			qObj.var.inputs[grp] = clipText.replace(/&nbsp;/g, ' ');
			this.markQChange(qObj.var);
			this.countCompletedAnswers();
		});
	}

	protected updateScrollEnd(): void {
		var e: any = this.elementRef.nativeElement.firstElementChild;
		var scrollEnd: boolean = e.clientHeight + e.scrollTop >= e.scrollHeight - 80;
		this.onScrollEnd.next(scrollEnd);
	}

	loadSchoolSettings() {
		this.datas.post('ROSchoolSetting.get_school_setting_value', ['ASSESSMENT_AI_CHECKING']).subscribe((res: any) => {
			if (res.setting) {
				this.coms.waitFor(() => this.setting.schoolSettings, 10, 500).then(() => {
					this.setting.schoolSettings.ASSESSMENT_AI_CHECKING = res.setting
				})
			}
		})
	}

	protected resetQVar(): void {
		const okdPageEle = document.querySelector('.okdPage');
		const lastScrollTop = okdPageEle ? okdPageEle.scrollTop : 0;
		this.questionVar = [];
		if (this.entryAndContent && this.entryAndContent.content && this.entryAndContent.content.questions) {
			this.questionList = [];
			this.entryAndContent.content.questions.forEach(q => {
				let cpy: any = {};
				ObjectUtils.copy(q, cpy);
				this.questionTextFormatConvert(cpy);
				this.questionList.push(cpy);
			});

			this.questionList.forEach(q => {
				if (['QBTextMC', 'QBGraphicMC', 'QBVoiceMC'].indexOf(q.type) >= 0) {
					q.var = { selected: [] };
					let ary: any = [];
					for (let i: number = 1; i <= q.totalOptions; i++)
						ary.push(this.continuesSpaceConvert(q["opt" + i]));
					q.var.canChooseOptions = ary;

				} else if (q.type == 'QBRecorder') {
					q.var = { state: "rec", position: 0, duration: 0, file: null, positionText: "00:00"/*,assets:null*/ };

				} else if (q.type == 'QBTakePhoto') {
					q.var = {/*state:"takePhoto",q:null,*/file: null };
					////					this.setCameraImageByURL(this.questionVar.length-1, null);

				} else if (q.type == 'QBToggleOptions') {
					let dropDownLabels: string[] = [];
					let corrects = [];
					q.question.forEach(e => {
						if (e.type == "grp") {
							dropDownLabels.push("&nbsp;");
							corrects.push(false);
						}

					});
					q.var = { inputs: [], corrects: corrects, dropDownLabels: dropDownLabels };

				} else if (q.type == "QBFillingBlank") {
					let inputs = [];
					let corrects = [];
					q.question.forEach(e => {
						if (e.type == "input") {
							inputs.push("");
							corrects.push(false);
						}

					});
					q.var = { inputs: inputs, corrects: corrects };

				} else if (['QBShortAnswer', 'QBLongQuestion'].indexOf(q.type) >= 0) {
					q.var = { input: "" };

					//				} else if(['QBDragLine','QBDragDrop'].indexOf(q.type)>=0) {
					//					this.questionVar.push({});
				} else if (q.type == "QBTrueFalse") {
					q.var = { selected: -1 };

				} else {
					q.var = {};
				}

				this.questionVar.push(q.var);
			});

			this.questionVar.forEach(qVar => { this.markQChange(qVar) });
			this.countCompletedAnswers();
		}
		if (okdPageEle) {
			setTimeout(() => { okdPageEle.scrollTop = lastScrollTop; }, 10);
		}
	}

	public getText(key: string): string {
		return this.langData ? this.translate.parser.getValue(this.langData, key) : key;
	}

	private splitBracketContent(content: string): string[] {
		const result: string[] = [];
		let currentOpt = '';
		let inTag = false;

		for (let i = 0; i < content.length; i++) {
			const char = content[i];

			if (char === '<') {
				inTag = true;
			} else if (char === '>') {
				inTag = false;
			}

			if (char === '/' && !inTag) {
				if (currentOpt.trim()) {
					result.push(currentOpt.trim());
				}
				currentOpt = '';
			} else {
				currentOpt += char;
			}
		}

		if (currentOpt.trim()) {
			result.push(currentOpt.trim());
		}

		return result;
	}

	protected questionTextFormatConvert(data: any): void {
		let text: string = this.continuesSpaceConvert(data.question.replaceAll("\n", "<br/>"))
		// 當舊有題型存有div tag, 改做p tag
		text = text.replace(/<div>/g, '<p>').replace(/<\/div>/g, '</p>');

		data.isPageComponent = false;
		if (data.type == "QBToggleOptions" || data.type == "QBFillingBlank") {
			// 文字切換題
			let parts: any = [];
			let pos: number = 0;
			let re = /(\[[^\]]+\])/ig;
			let myArray;
			let grp: number = 0;
			while ((myArray = re.exec(text)) !== null) {
				// 前面有普通字串
				if (pos < myArray.index)
					parts.push({ type: "text", str: text.substring(pos, myArray.index) });
				// option
				let bracketContent = text.substring(myArray.index + 1, re.lastIndex - 1);
				let opts = this.splitBracketContent(bracketContent);

				if (data.type == "QBToggleOptions") {
					var sub: any[] = [];
					var style: string = data.blank.type == "h" ? "optStyle1" : "optStyle2";
					opts.forEach((opt, index) => {
						if (opt.substr(0, 1) == "#")
							sub.push({ type: style, id: index, answer: true, str: opt.substr(1), innerHtml: this.formatHtml(opt.substr(1)) });
						else
							sub.push({ type: style, id: index, answer: false, str: opt, innerHtml: this.formatHtml(opt) });
						if (index + 1 < opts.length && data.blank.type == "h")
							sub.push({ type: "splitter", str: "/", innerHtml: this.formatHtml("/") });
					});
					parts.push({ type: "grp", grp: grp, options: sub });

				} else {
					parts.push({ type: "input", grp: grp, answer: opts });
				}
				grp++;
				pos = re.lastIndex;
			}
			if (pos < text.length) {
				parts.push({ type: "text", str: text.substring(pos, text.length) });
			}

			// 處理填充題分行問題
			let previousTextPart = null;

			parts.forEach(part => {
				if (["input", "grp"].includes(part.type) && previousTextPart) {
					//Modify the last <p> in the 'before' text object to <span>
					// Split the string by closing </p> tags
					let parts = previousTextPart.str.split(/(<\/p>)/);

					// Process each part
					previousTextPart.str = parts.map(part => {
						// Check if the part starts with <p> and is not followed by </p>
						if (part.startsWith('<p>') && !part.includes('</p>')) {
							// Replace opening <p> with <span>
							return part.replace('<p>', '<span>');
						}
						// Return other parts unchanged
						return part;
					}).join('');

					previousTextPart.innerHtml = this.formatHtml(previousTextPart.str);

				}

				if (part.type === "text") {
					previousTextPart = part; // Update previousTextPart to the current text part
				}
			});

			const updatedOutput = parts.map(itm => ({
				...itm,
				innerHtml: itm.type == 'text' ? this.formatHtml(itm.str) : ""
			}));

			data.question = updatedOutput;

		} else if (AssessmentViewerComponent.isPageQuestionComponent(data.type)) {
			data.question = [{ type: "text", str: text, innerHtml: this.formatHtml(text) }];
			data.rawDataAndSettings = {
				book: {
					id: this.entryAndContent.id, title: this.entryAndContent.title,
					viewerID: this.viewerID, ownerID: this.ownerID, viewMode: this.viewMode, share: this.share, course: 0, serverData: null
				},
				rawData: data.rawData,
				setting: { autoSave: false, referenceLine: false, fontSize: this.fontSize, qSize: 3, scoreSize: 3, embeded: true }
			};
			data.isPageComponent = true;

		} else if (data.type == "QBInfoBlock") {
			let myArray: any[] = text.split("<br/>");
			let output: any[] = [];
			myArray.forEach(t => {
				if (t.indexOf("http://") == 0 || t.indexOf("https://") == 0)
					output.push({ type: "url", str: t, innerHtml: this.formatHtml(t) });
				else {

					var start: number = t.indexOf("[");
					var end: number = t.indexOf("]");
					if (start != -1 && end != -1) {
						if (start > 0)
							output.push({ type: "text", str: t.substring(0, start) });

						var subText: string[] = t.substring(start + 1, end).split(",");
						subText.forEach((st, i) => {
							var st2: string = st.trim();
							if (st2 == "")
								output.push({ type: "text", str: st });
							else
								output.push({ type: "ctext", str: st2 });
						});

						if (end + 1 < t.length)
							output.push({ type: "text", str: t.substring(end + 1) });
					} else
						output.push({ type: "text", str: t });


				}
				output.push({ type: "text", str: "<br/>" });
			});
			if (output.length > 1)
				output.pop();
			const updatedOutput = output.map(itm => ({
				...itm,
				innerHtml: this.formatHtml(itm.str)
			}));

			data.question = updatedOutput;

		} else {
			data.question = [{ type: "text", str: text, innerHtml: this.formatHtml(text), }];
		}
	}

	public speechQ(qparts: any[]): void {
		var text: string = "";
		var engine: any = { name: "auto" };
		qparts.forEach(q => {
			if (q.type == 'text') {
				text += q.str;
			}
		});

		if (text != "") {
			text = (text as any).replaceAll('&nbsp;', '');
			text = text.replace(/<[^>]*>/g, '')
			this.ttsService.play(text, "0", { speed: this.speechSpeed }).then(() => {

			}, reason => {
				this.alertService.alert("mediaSelectTts.failToDownloadTtsFile");
			});
		}
	}

	public showDropDown(e: any, qIndex: number, grpIndex: number): void {
		if (this.wpHandle)
			return;

		var qObj: any = this.questionList[qIndex];
		var qVar: any = qObj.var;
		let grpOpt = qObj.question.find(e => e.type == "grp" && e.grp == grpIndex);
		if (grpOpt) {
			var wpOptions: any[] = grpOpt.options.map((opt, i) => {
				return { titleKey: opt.str.replace(/<[^>]*>/g, ''), id: i };
			});
			this.wpHandle = this.whitePopupService.showSelection(
				e.target,
				wpOptions
			);
			this.wpHandle.then(sel => {
				// 文字切換題
				// filter 走同組
				qVar.inputs = qVar.inputs.filter(e => parseInt(e.grp) != grpIndex);
				qVar.inputs.push({ grp: grpIndex, id: sel.id });
				qVar.dropDownLabels[grpIndex] = sel.titleKey;
				qVar.corrects[grpIndex] = sel.titleKey;
				this.markQChange(qVar);
				this.countCompletedAnswers();

				this.wpHandle = null;
			}).catch(e => {
				// no sel
				this.wpHandle = null;
			});
		}
	}

	// =======================================
	// mc function
	// =======================================
	public continuesSpaceConvert(str: string): string {
		var ary: string[] = str.split("");
		ary.forEach((t, i) => {
			if (t == " ") {
				if (i + 1 < ary.length && ary[i + 1] == " ")
					ary[i] = "&nbsp;";
			}
		});
		return ary.join("");
	}

	public getOptionName(index: number): any {
		return String.fromCharCode(0x41 + index) + "."; // A, B, C, D
	}

	public getOptionAssetFiles(qObj: any, index: number): any {
		let asset: any = qObj["opt" + (index + 1) + "_asset"];
		return (asset && asset.files) ? asset.files : [];
	}

	public answerIsSelected(q: number, index: number, subIndex: number = 0): boolean {
		let qVar: any = this.questionVar[q];

		if (this.questionList[q].type == "QBToggleOptions") {
			// 文字切換題
			return qVar.inputs.find(e => parseInt(e.grp) == index && parseInt(e.id) == subIndex);
		}

		// MC
		return qVar.selected.find(e => parseInt(e) == (index + 1));
	}

	public answerClick(q: number, index: number, subIndex: number = 0): void {
		if (!this.canEdit) return;

		let qVar: any = this.questionVar[q];
		this.markQChange(qVar);

		if (this.questionList[q].type == "QBToggleOptions") {
			// 文字切換題
			// filter 走同組
			qVar.inputs = qVar.inputs.filter(e => parseInt(e.grp) != index);
			qVar.inputs.push({ grp: index, id: subIndex });

		} else if (this.questionList[q].type == "QBTextMC") {
			// MC
			index++;
			if (parseInt(this.questionList[q].multiSelect) == 1) {
				// multi select
				let i: number = qVar.selected.indexOf(index);
				if (i >= 0)
					qVar.selected.splice(i, 1);
				else
					qVar.selected.push(index);
				qVar.selected.sort((a, b) => {
					return a - b;
				});
			} else {
				// single select
				qVar.selected = [index];
			}

		} else if (this.questionList[q].type == "QBTrueFalse") {
			qVar.selected = (qVar.selected == index) ? -1 : index;

		}
		this.countCompletedAnswers();
	}

	public isMCAnswer(q: number, index: number, subIndex: number = 0): boolean {
		let qObj: any = this.questionList[q];

		if (qObj.type == "QBToggleOptions") {
			// 文字切換題
			let grpOpt = qObj.question.find(e => e.type == "grp" && e.grp == index);
			if (grpOpt) {
				grpOpt = grpOpt.options.find(e2 => e2.type != "splitter" && e2.id == subIndex);
				return grpOpt && grpOpt.answer;
			}
			return false;

		} else if (["QBTextMC", "QBGraphicMC", "QBVoiceMC"].indexOf(qObj.type) >= 0) {
			// MC
			if (typeof qObj.answer !== 'string')
				return false;
			return qObj.answer.indexOf((index + 1).toString()) >= 0;
		}

		return false;
	}

	// format the question
	public formatHtml(string): SafeHtml {
		let safeHtml = this.sans.bypassSecurityTrustHtml(string)
		return safeHtml
	}

	// format textMC options
	public formatMCOptions(optIndex: number, ans: string) {
		// only replace the first p tag to span
		let modifyOption = ans.replace(/<p>(.*?)<\/p>/, '<span>$1</span>');
		const option = `${this.getOptionName(optIndex)} ${modifyOption}`
		return this.formatHtml(option)
	}
	// =======================================
	// sound recorder function
	// =======================================
	public playSnd(q: number): void {
		let qVar: any = this.questionVar[q];
		qVar.state = "playing";

		qVar.positionText = "00:00";
		qVar.timer = setInterval(() => {
			qVar.positionText = this.toTimeText(Math.floor(this.sndChannel.currentTime));
			if (qVar.duration == 0 && this.sndChannel.duration)
				qVar.duration = this.sndChannel.duration;
		}, 100);

		if (qVar.file || qVar.url) {
			this.sndChannel.src = qVar.url ? this.fileio.getResourceServer(qVar.url) + qVar.url : URL.createObjectURL(qVar.file);
			this.sndChannel.controls = false;
			this.sndChannel.addEventListener('ended', () => {
				this.sndChannel.pause();
				qVar.state = "play-and-rec";

				if (qVar.timer) {
					clearInterval(qVar.timer);
					delete qVar.timer;
				}
			});
			this.sndChannel.play();
		}
	}

	public stopSnd(q: number): void {
		let qVar: any = this.questionVar[q];
		if (qVar.state == "recording") {
			qVar.state = "converting";
			this.fileio.stopRecord();

		} else {
			this.sndChannel.pause();
			qVar.state = "play-and-rec";

			if (qVar.timer) {
				clearInterval(qVar.timer);
				delete qVar.timer;
			}
		}
	}

	public recSnd(q: number): void {
		let qVar: any = this.questionVar[q];
		this.markQChange(qVar);

		qVar.state = "recording";
		qVar.duration = 0;
		qVar.file = null;
		qVar.position = 0;
		qVar.positionText = this.toTimeText(qVar.position);
		let timer = setInterval(() => {
			qVar.position++;
			qVar.positionText = this.toTimeText(qVar.position);
		}, 1000);

		this.fileio.record().then((success: any) => {
			qVar.duration = success.duration;
			qVar.url = null;
			qVar.file = success.file;
			qVar.state = "play-and-rec";
			qVar.position = 0;
			qVar.positionText = "00:00";
			clearInterval(timer);
			this.countCompletedAnswers();
		}, (reason: any) => {
			qVar.state = reason.msg;
			clearInterval(timer);
		});
	}

	public getDurationText(q: number): string {
		return this.questionVar[q].duration == 0 ? "--:--" : this.toTimeText(Math.round(this.questionVar[q].duration));
	}

	protected toTimeText(num: number): string {
		return this.toTwoDigi(Math.floor(num / 60)) + ":" + this.toTwoDigi(num % 60);
	}

	protected toTwoDigi(num: number): string {
		return num < 10 ? "0" + num : num.toString();
	}

	public getRecStateText(q: number): string {
		let state: string = this.questionVar[q].state;
		if (state == "") {
			return "";
		}
		return "";
	}

	protected setRecorderSoundByURL(qIndex: number, url: string, duration: number): void {
		let qVar: any = this.questionVar[qIndex];
		qVar.url = url;
		delete qVar.file;

		qVar.state = "play-and-rec";
		qVar.position = 0;
		qVar.duration = duration;
		qVar.positionText = "00:00";
		if (qVar.timer) {
			clearInterval(qVar.timer);
			delete qVar.timer;
		}
	}

	// =======================================
	// camera function
	// =======================================
	public takePhoto(qIndex: number): void {
		this.getImageResult(qIndex, this.cameraCapture.lanuch());
	}

	public selectImage(qIndex: number): void {
		this.getImageResult(qIndex, this.fileio.getLocalFile({ fileType: 'image' }));
	}

	protected getImageResult(qIndex: number, p: Promise<any>): void {
		let qVar: any = this.questionVar[qIndex];

		p.then((success) => {
			this.markQChange(qVar);
			qVar.url = null;
			qVar.file = success.file;
			this.countCompletedAnswers();
		}, (reason) => {
			if (reason.msg != "cancel" && reason.msg != "cancel-by-close-window")
				this.alertService.alert(this.getText(reason.msg));
		});
	}

	protected setCameraImageByURL(qIndex: number, url: string): void {
		let qVar: any = this.questionVar[qIndex];
		qVar.url = url;
		delete qVar.file;
	}

	public getCameraImageURLCSS(qIndex: number): any {
		let qVar: any = this.questionVar[qIndex];
		let url: string;
		if (qVar.file) {
			if (qVar.url)
				url = qVar.url;
			else
				url = qVar.url = URL.createObjectURL(qVar.file);
		} else if (qVar.url)
			url = this.fileio.getResourceServer(qVar.url) + qVar.url;

		if (url)
			return this.sans.bypassSecurityTrustStyle("url('" + url + "')");
		return "none";
	}

	// =======================================
	// set data source function
	// =======================================
	public setDataSource(uid: number, data: any): void {
		this.ownerID = uid;
		this.dataSource = data;
		this.uid = data.uid
		this.sndChannel.pause();

		this.resetQVar();// reset answer

		// 顯示返答案
		this.questionList.forEach((q: any, qIndex: number) => {
			let qVar: any = this.questionVar[qIndex];

			// 更新 answer
			////			let ans:any = this.dataSource.records.find(e => e.pid == "P"+q.douid && e.cid == q.douid);
			let ans: any = this.dataSource.records.find(e => e.cid == q.douid && e.tag != '#marking#');
			if (ans) {
				qVar.scoreResult = ans.result;

				// 有答案，顯示答案
				try {
					if (q.type == "QBTextMC" || q.type == "QBGraphicMC" || q.type == "QBVoiceMC") {
						if (ans.data == "NaN") {
							qVar.selected = [];
						} else {
							qVar.selected = ans.data.indexOf(",") >= 0 ? ans.data.split(",") : [ans.data];
							qVar.selected = qVar.selected.map(e => { return parseInt(e); });
							qVar.submitted = true;
						}

					} else if (q.type == "QBRecorder" || q.type == "QBTakePhoto") {
						if (ans.data == "file data") {
						} else {
							ans = JSON.parse(ans.data);
							if (ans && ans.assets && ans.assets.length > 0) {
								let url: string = ans.assets[0].url;

								if (q.type == "QBRecorder") {
									this.setRecorderSoundByURL(qIndex, url, ans.assets[0].hasOwnProperty("duration") ? ans.assets[0].duration : 0);
								} else {
									this.setCameraImageByURL(qIndex, url);
								}
								qVar.submitted = true;
							}

						}

					} else if (q.type == "QBToggleOptions" || q.type == "QBFillingBlank") {
						qVar.inputs = JSON.parse(ans.data);
						qVar.submitted = true;

					} else if (q.type == "QBShortAnswer" || q.type == "QBLongQuestion") {
						qVar.input = ans.data;
						qVar.submitted = true;

					} else if (q.type == "QBTrueFalse") {
						qVar.selected = ans.data;
						qVar.submitted = true;

					} else if (AssessmentViewerComponent.isPageQuestionComponent(q.type)) {
						q.rawDataAndSettings.book.serverData = {
							markings: [],
							data: this.dataSource.records
						};
						qVar.submitted = true;
					}


				} catch (e) {
				}
				this.questionVar[qIndex] = qVar
			}

		});

		//		this.questionVar.forEach(q => {q.submitted = true});
		window.setTimeout(() => {
			const answerSource: AnswerSource = this.context.config.dataSource;
			// const markings = answerSource.getMarking()
			this.questionList.forEach((q: any, qIndex: number) => {
				if (q.type != 'QBInfoBlock') {
					let records = this.dataSource.records.filter(e => e.tag == '#marking#' && e.cid && e.cid.indexOf(q.douid) > -1);
					if (records.length > 0) {
						records.forEach(e => {
							const arr = e.cid.split('#');
							e.creator = arr[2];
						});
					}
					const markingLayer: MarkingLayer = this.markingLayerRefs.find(e => e.assessmentCom.itm.douid == q.douid);
					markingLayer.initEpenMarkingsAndCorrection(records, null, null);
					const markings = data.markings.filter(e => e.pid == 'P' + q.douid);
					markingLayer.data2 = markings;
				}
			});
			this.getAnswers();
		}, 100);

	}

	public markQChange(qVar: any): void {
		if (isNumber(qVar))
			qVar = this.questionVar[qVar];
		qVar.submitted = false;
		qVar.scoreResult = null;
		qVar.showVerify = false;
	}

	// =======================================
	// scoring function
	// =======================================
	public updateScore(scoringCom: any): void {
		let data: any = this.dataSource.records.find(e => e.cid == scoringCom.questionSetting.douid)
		// 當AI評分後，處理老師分數（將老師分數拆開）
		scoringCom.scoreResult.teacherScore = scoringCom.scoreResult.hasOwnProperty('score') ? scoringCom.scoreResult.score : null
		// 處理成績表顯示的題目分數(會影響總分)
		// 老師沒有評分 && AI評了分
		if (!scoringCom.scoreResult.hasOwnProperty('score') && scoringCom.scoreResult.hasOwnProperty('aiScore') && scoringCom.scoreResult.aiScore !== null) {
			const aiScore = parseFloat(scoringCom.scoreResult.aiScore)
			scoringCom.scoreResult.score = aiScore
			// 全對
			if (aiScore == scoringCom.scoreResult.maxScore) {
				scoringCom.scoreResult.correct = 2
			} else if (aiScore == 0) {
				// 全錯
				scoringCom.scoreResult.correct = 0
			} else {
				// 部分正確
				scoringCom.scoreResult.correct = 1
			}
		}
		if (data) {
			data.result = scoringCom.scoreResult
		}

		this.datas.post2({
			data: {
				api: 'ROBookShare.update_student_result_by_index', loading: true,
				json: [
					this.dataSource.shareID, this.dataSource.bookID, this.ownerID, data.index,
					data.bid, data.pid, data.cid,
					data.data, data.result
				]
			}
		}).then((res: any) => {
			this.getAnswers(true)
		});

	}

	public showVerify(qIndex: number): boolean {
		return this.isSubmitted(qIndex) ? this.questionVar[qIndex].showVerify : false;

		/*let submitted:boolean

		switch (this.viewMode) {
			case "view":
				submitted = false
				break
			case "scoring": 
				submitted = true
				break
			default:
				submitted = this.isSubmitted(qIndex)
				break
		}

		return submitted ? this.questionVar[qIndex].showVerify : false;*/
	}

	public getScoreResult(qIndex: number): any {
		let qVar: any = this.questionVar[qIndex];
		if (this.viewMode == 'review' || (this.share && this.share.type == 'normal')) {
			return qVar.scoreResult
		}
		return qVar.showVerify ? qVar.scoreResult : null;
	}

	public isSubmitted(qIndex: number): boolean {
		return this.questionVar[qIndex].submitted;
	}

	public isGrpCorrect(qIndex: number, grpIndex: number): boolean {
		let qObj: any = this.questionList[qIndex];
		let qVar: any = this.questionVar[qIndex];

		if (qObj.type == "QBFillingBlank") {
			let grpOpt = qObj.question.find(e => e.type == "input" && e.grp == grpIndex);
			return grpOpt && this.strAnswerCompare(grpOpt.answer, qVar.inputs[grpIndex]);
		}

		return false;
	}

	// =======================================
	// prepare answer for submit function
	// =======================================
	public getAssetFile(submitRecord: any): File {
		let ret: any;
		this.questionList.forEach((qObj: any, index: number) => {
			if (qObj.douid == submitRecord.com) {
				ret = this.questionVar[index].file;
			}
		});
		return ret;
	}

	public setAssetURL(submitRecord: any, url: string): void {
		submitRecord.answer = JSON.stringify({ assets: [{ url: url }] });
		this.questionList.forEach((qObj: any, index: number) => {
			if (qObj.douid == submitRecord.com) {
				let qVar: any = this.questionVar[index];
				qVar.url = url;
				delete qVar.file;
				// 要更新顯示？
			}
		});
	}

	public getHaveAssetRecord(submitRecords: any): any {
		let haveAssetRecords: any = [];
		submitRecords.forEach((itm: any, index: number) => {
			if ((itm.tag == "QBRecorder" || itm.tag == "QBTakePhoto") && itm.answer == "file data") {
				haveAssetRecords.push(itm);
			}
		});

		return haveAssetRecords;
	}

	protected getResultObjectAndAnswer(qIndex: number, isVerifyPsComponent = true): any {
		let qObj: any = this.questionList[qIndex];
		let qVar: any = this.questionVar[qIndex];
		let ret: any = {
			answerForSubmit: null,
			correct: 0,
			wrong: 0,
			totalAns: 1
		};
		let breakDownScore: boolean = parseInt(qObj.scoringType) == 2;

		if (qObj.type == "QBTextMC" || qObj.type == "QBGraphicMC" || qObj.type == "QBVoiceMC") {
			let qans: any[] = qObj.answer.indexOf(',') ? qObj.answer.split(",") : [qObj.answer];
			ret.totalAns = parseInt(qObj.scoringType) == 1 ? 1 : qans.length;
			ret.answerForSubmit = qVar.selected.join(",");
			if (ret.answerForSubmit != "") {
				// 不是細項計分當一題, 細項計分用幾多個答案
				if (parseInt(qObj.multiSelect) == 1 && parseInt(qObj.scoringType) != 1) {
					// 多選題 個別答案計分
					let maxAns: number = 0;

					qVar.selected.forEach(ans => {
						if (qans.indexOf(ans.toString()) >= 0)
							ret.correct++;
						else
							ret.wrong++; // 選擇錯誤
					});

				} else if (qObj.answer != ret.answerForSubmit) {
					ret.wrong = 1;
				} else {
					ret.correct = 1;
				}
			}

		} else if (qObj.type == "QBToggleOptions") {
			ret.totalAns = 0;
			qObj.question.forEach(e => {
				if (e.type == "grp")
					ret.totalAns = Math.max(e.grp + 1, ret.totalAns);
			});
			ret.answerForSubmit = JSON.stringify(qVar.inputs);
			qVar.inputs.forEach(e => {
				let grpOpt = qObj.question.find(e2 => e2.type == "grp" && e2.grp == e.grp);
				grpOpt = grpOpt.options.find(e2 => e2.type != "splitter" && e2.id == e.id);
				if (grpOpt && grpOpt.answer)
					ret.correct++;
				else
					ret.wrong++; // 選擇錯誤
				qVar.corrects[e.grp] = grpOpt && grpOpt.answer;
				qVar.dropDownLabels[e.grp] = grpOpt.innerHtml;
			});


		} else if (qObj.type == "QBFillingBlank") {
			ret.totalAns = 0;
			qObj.question.forEach(e => {
				if (e.type == "input")
					ret.totalAns = Math.max(e.grp + 1, ret.totalAns);
			});
			ret.answerForSubmit = JSON.stringify(qVar.inputs);
			qVar.inputs.forEach((e, inputIndex) => {
				if (e != "") {
					let grpOpt = qObj.question.find(e2 => e2.type == "input" && e2.grp == inputIndex);
					console.log("check QBFillingBlank>>", qObj.blank.type, qVar.inputs, e, inputIndex);
					var lastCorrect: number = ret.correct;
					if (qObj.blank.type == "T") {
						// 注意: 答案可多過一個可能
						if (grpOpt && this.strAnswerCompare(grpOpt.answer, e, qObj.blank.caseSensitive == 1, qObj.blank.symbolSensitive == 1))
							ret.correct++;
						else
							ret.wrong++; // 填錯
					} else if (qObj.blank.type == "keyword") {
						this.getKeywordAnswerResult(ret, grpOpt.answer[0], e, qObj.blank.caseSensitive == 1, qObj.blank.symbolSensitive == 1);
					}
					qVar.corrects[inputIndex] = lastCorrect != ret.correct;
				} else {
					qVar.corrects[inputIndex] = false;
				}
			});

		} else if (qObj.type == "QBShortAnswer") {
			ret.totalAns = 1;
			ret.answerForSubmit = qVar.input = qVar.input.trim();
			if (qObj.context.type == "exact") {
				if (qObj.answer == "")
					ret.wrong = 1;
				else if (this.strAnswerCompare([qObj.answer], qVar.input, qObj.context.caseSensitive == 1, qObj.context.symbolSensitive == 1))
					ret.correct = 1;
				else
					ret.wrong = 1;
			} else if (qObj.context.type == "keyword") {
				this.getKeywordAnswerResult(ret, qObj.answer, qVar.input, qObj.context.caseSensitive == 1, qObj.context.symbolSensitive == 1);
				ret.totalAns = qObj.answer.split(",").length;
				// 這個不分細項計分，也會給會部份分。
				breakDownScore = true;

			}

		} else if (qObj.type == "QBLongQuestion") {
			ret.answerForSubmit = qVar.input;
			ret.correct = -1;

		} else if (qObj.type == "QBRecorder" || qObj.type == "QBTakePhoto") {
			//			if(qVar.file || qVar.url)
			ret.correct = -1;
			let asset: any = qObj.type == "QBRecorder" ? { url: qVar.url, duration: qVar.duration } : { url: qVar.url };
			ret.answerForSubmit = (qVar.file ? "file data" : JSON.stringify({ assets: [asset] }));

		} else if (qObj.type == "QBTrueFalse") {
			ret.totalAns = 1;
			ret.answerForSubmit = qVar.selected;
			var myans: number = parseInt(ret.answerForSubmit);
			if (parseInt(qObj.answer) == myans)
				ret.correct = 1;
			else if (myans >= 0)
				ret.wrong = 1;
			// else 無答

		} else if (AssessmentViewerComponent.isPageQuestionComponent(qObj.type)) {
			// 暫時只是一頁一題，一頁多題要修改
			// QBDragLine, DragDropA, DragDropC
			let pIndex: number = 0;
			for (var q = 0; q < qIndex; q++) {
				if (AssessmentViewerComponent.isPageQuestionComponent(this.questionList[q].type))
					pIndex++;
			}
			if (this.ps) {
				let ary = this.ps.toArray();
				let com: ROPageSliderComopnent = ary[pIndex];
				let pc: ROPageComponentContainer = com.centerPage.ref.instance;
				let pageCom: ROPageComponent = pc.pageCom;

				pageCom.dataComponents.forEach((c: any) => {
					ret.answerForSubmit = c.data;

					if (ret.answerForSubmit) {
						if (c.canVerify() && isVerifyPsComponent) {
							var res: any = c.getCorrectWrongStatus(true);
							ret.totalAns = res.total;
							ret.correct = res.numOfCorrect;
							ret.wrong = res.numOfIncorrect;
							c.isVerify = true;
						} else {
							var res: any = c.getCorrectWrongStatus(false);
							ret.totalAns = res.total;
							ret.correct = res.numOfCorrect;
							ret.wrong = res.numOfIncorrect;
							c.isVerify = false;
							c.correctState = ""
						}
					}

				});

				com.context.subject.subscribe((data: any) => {
					if (data.type == "action" && data.action == "answerChanged") {
						this.markQChange(qVar);
						this.countCompletedAnswers();
					}
				});
			}
		}


		// 計算得分
		if (ret.correct == -1) // 無指定答案
			ret.result = { correction: false, correct: -1, maxScore: qObj.fullScore, teacherScore: null };

		else if (ret.correct == ret.totalAns && ret.wrong == 0) // 全對
			ret.result = {
				correction: false,
				score: parseFloat(qObj.fullScore),
				correct: 2,
				maxScore: qObj.fullScore
			};

		else if (ret.correct == 0 || !breakDownScore) // 全錯
			ret.result = {
				correction: true, // Incorrect 要改正
				score: 0,
				correct: 0,
				maxScore: qObj.fullScore
			};
		else {
			// 有對及錯
			let correctCount: number = ["QBTextMC", "QBGraphicMC", "QBVoiceMC"].indexOf(qObj.type) >= 0 ? Math.max(0, ret.correct - ret.wrong) : ret.correct;
			ret.result = {
				correction: true, // Incorrect 要改正
				score: Math.round(10 * parseFloat(qObj.fullScore) * correctCount / ret.totalAns) / 10,
				correct: 1,
				maxScore: qObj.fullScore
			};
		}

		return ret;
	}

	protected getKeywordAnswerResult(result: any, answer: string, input: string, caseSensitive: boolean = false, symbolSensitive: boolean = false): void {
		var cmp: string[] = answer.split(",");
		cmp = cmp.map(s => {
			s = s.trim()
			if (!caseSensitive) {
				s = s.trim().toLocaleLowerCase()
			}
			if (!symbolSensitive) {
				s = s.normalize('NFKC')
			}
			return s
		});

		if (!caseSensitive)
			input = input.toLocaleLowerCase();
		if (!symbolSensitive)
			input = input.normalize('NFKC');

		var last_correct: number = result.correct;
		cmp.forEach(s => {
			if (input.indexOf(s) >= 0)
				result.correct++;
		});
		if (last_correct == result.correct)
			result.wrong++;
	}

	protected strAnswerCompare(answers: string[], yourAns: string, caseSensitive: boolean = false, symbolSensitive: boolean = false): boolean {
		yourAns = yourAns.trim();
		if (!caseSensitive)
			yourAns = yourAns.toLocaleLowerCase();
		// 區分全半形
		if (!symbolSensitive) {
			yourAns = yourAns.normalize('NFKC');
		}
		for (var i: number = 0; i < answers.length; i++) {
			var ans: string = (answers[i] as any).replaceAll("&nbsp;", "").trim();
			if (!caseSensitive)
				ans = ans.toLocaleLowerCase();
			if (!symbolSensitive) {
				ans = ans.normalize('NFKC');
			}
			if (ans == yourAns)
				return true;
		}
		return false;
	}

	// update and get the info for the display score
	// 執行完這個先決定是否顯示答案及分數
	public getAnswers(markSubmitted: boolean = false): any {
		if (markSubmitted)
			this.questionVar.forEach(q => { q.submitted = true });

		// preview 即時核對
		// view 看 share.live_verify
		// review 看 share.live_verify 及是否批改左
		// scoring 即時核對
		//if(this.viewMode == "review" && this.share.status=="open") {

		//}


		let submitRecords: any = [];
		// when teacher did not score the longQ, will count the ai score
		let scoreRecord: number = 0
		let totalScore: number = 0
		let aiScore: number = 0
		let isAnyQuestionScored = false
		let isLongQScored: "noLongQ" | "notScored" | "onlyAI" | "scored" = 'noLongQ'
		this.questionList.forEach((qObj: any, qIndex: number) => {
			if (qObj.type != "QBInfoBlock") {
				let result: any = this.getResultObjectAndAnswer(qIndex, this.showAutoScore);

				let qVar: any = this.questionVar[qIndex];
				// if the score is existing, then use the score
				if (!qVar.scoreResult) {
					qVar.scoreResult = result.result;
				}

				// 無 datasource 是 preview ，不需 upload data
				const studentId = this.dataSource ? this.dataSource.uid : 0;
				const commentId = `${studentId}-${qObj.douid}`
				// set each question's comment editable value
				if (!this.isCommentObjEditable.hasOwnProperty(commentId)) {
					this.isCommentObjEditable[commentId] = false
				}
				// add the teacherComment field for teacher to input comment
				// push the data to the state
				if (!qVar.scoreResult.hasOwnProperty('teacherComment')) {
					qVar.scoreResult.teacherComment = ""
					this.teacherCommentsObject[commentId] = ""
				} else {
					if (!this.teacherCommentsObject.hasOwnProperty(commentId)) {
						this.teacherCommentsObject[commentId] = qVar.scoreResult.teacherComment
					}
				}


				if (/*(this.viewMode == "preview" || this.viewMode == "view") &&*/ result.correct == 0 && result.wrong == 0) {
					qVar.showVerify = false;
				} else {
					qVar.showVerify = (qVar.scoreResult.correct == -1) ? this.showManualScore : this.showAutoScore;
				}
				// console.log(qIndex, ">>",qVar.showVerify,this.viewMode,this.showManualScore , this.showAutoScore);

				// QB version 1
				//var pid = ['QBDragLine','QBDragDrop'].indexOf(qObj.type)!=-1 ? "P"+qObj.douid : qObj.douid;
				// QB version 2
				var pid = "P" + qObj.douid;
				submitRecords.push({
					type: "add", tag: qObj.type,
					doc: this.entryAndContent.id, page: pid, com: qObj.douid,
					answer: result.answerForSubmit
					, result: qVar.scoreResult
				}, {
					"lesson_id": 0, "doc": this.entryAndContent.id, "page": pid, "mode": "none", "ref_id": 0, "type": "submit"
				});
				if (qVar.scoreResult) {
					if (qVar.scoreResult.score && qVar.submitted) {
						scoreRecord += parseFloat(qVar.scoreResult.score)
					}

					if (qVar.scoreResult.maxScore) {
						totalScore += parseFloat(qVar.scoreResult.maxScore)
					}

					if (qObj.type == "QBLongQuestion") {
						const hasScore = qVar.scoreResult.hasOwnProperty('score')
						const hasAI = qVar.scoreResult.hasOwnProperty('aiScore')
						const hasTeacher = qVar.scoreResult.hasOwnProperty('teacherScore')
						const scoreField = qVar.scoreResult.score
						const aiField = qVar.scoreResult.aiScore
						const teacherField = qVar.scoreResult.teacherScore

						// 老師未俾分而AI俾咗分
						if ((!hasScore || scoreField == null) && hasAI && aiField !== null) {
							scoreRecord += parseFloat(qVar.scoreResult.aiScore)
						}
						// 長問答狀態： 
						// 如果有一題被老師評了分，則是scored
						// 如果只有AI評了分，則是onlyAI
						// 如果都沒有，則是notScored

						// 仍未有老師評了分
						if (!isAnyQuestionScored) {
							// AI評了分
							if (hasAI && aiField !== null) {
								if (hasTeacher && teacherField !== null) {
									// 老師同時亦評了分
									isAnyQuestionScored = true
									isLongQScored = "scored"
								} else {
									isLongQScored = "onlyAI"
								}
							} else {
								if ((hasTeacher && teacherField !== null) || hasScore && scoreField !== null) {
									isAnyQuestionScored = true
									isLongQScored = "scored"
								} else {
									isLongQScored = 'notScored'
								}
							}
						}
					}

					if (qVar.scoreResult.aiScore) {
						aiScore += parseFloat(qVar.scoreResult.aiScore)
					}
				}
			}

		});
		this.onScoreChange.emit({
			score: scoreRecord,
			totalScore: totalScore,
			isLongQScored: isLongQScored,
			submitRecords: submitRecords,
			aiScore: aiScore,
		})
		this.countCompletedAnswers(this.showAutoScore)
		return submitRecords;
	}

	public countCompletedAnswers(isAllowVerify = false) {
		let totalQuestions: number = 0
		let completedQuestions: number = 0
		this.questionList.forEach((qObj: any, qIndex: number) => {
			if (qObj.type != "QBInfoBlock") {
				let result = this.getResultObjectAndAnswer(qIndex, isAllowVerify);

				if (qObj.type == 'QBTrueFalse') {
					if (result.answerForSubmit !== -1 && result.answerForSubmit !== 'NaN' && result.answerForSubmit !== '-1') {
						completedQuestions += 1;
					}
				} else {
					// count the completed answer in teacher mode
					if (!(/^(-1$)|^$/).test(result.answerForSubmit) &&
						result.answerForSubmit !== null && result.answerForSubmit !== 'NaN' &&
						!(result.answerForSubmit.startsWith('[') && (JSON.parse(result.answerForSubmit).length === 0 ||
							JSON.parse(result.answerForSubmit).every(item => item === ""))
						) &&
						!(result.answerForSubmit.startsWith('{"assets":') &&
							(JSON.parse(result.answerForSubmit).assets.length === 0 ||
								JSON.parse(result.answerForSubmit).assets.some(asset =>
									Object.keys(asset).length === 0 || asset.duration === 0
								)
							)
						)
					) {
						completedQuestions += 1;
					}
				}
				totalQuestions += 1
			}
		})
		this.onProgressChange.emit({
			totalQuestions,
			completedQuestions
		})
		return
	}


	public imgLoad(event: any, asset: any): void {
		var dom = event.target;
		if (!asset.hasOwnProperty("width") || asset.width == 0) {
			var w: number = Math.min(dom.naturalWidth, 368);
			var h: number = w * dom.naturalHeight / dom.naturalWidth;
			asset.width = w;
			asset.height = h;
		}

		dom.style.width = asset.width + "px";
		dom.style.height = asset.height + "px";
	}


	public onPageSliderEvent(data): void {
		if (data.type == "context" && !this.layerInited) {
			this.layerInited = true;

			var context: ROContext = data.context;
			this.context = context;
			var dom = this.elementRef.nativeElement;
			for(var name in context.layers)
				StyleUtils.setStyleVariable(dom, name + "-z-index", context.layers[name]);

		}
	}

	// show the correct/ wrong/ partialCorrect Icon
	// based on teacher score or ai score
	public showLongQVerify(qIndex: number): string {
		const qVar = this.questionVar[qIndex]

		const checkScore = (score: number) => {
			// 滿分
			if (score == qVar.scoreResult.maxScore) {
				return "maxScore"
			} else if (score == 0) {
				// 無分
				return "fail"
			} else {
				return "partialCorrect"
			}
		}
		if (qVar && qVar.scoreResult) {
			if (qVar.scoreResult.hasOwnProperty('score') && qVar.scoreResult.score !== null) {
				return checkScore(qVar.scoreResult.score)
			} else if (qVar.scoreResult.hasOwnProperty('aiScore') && qVar.scoreResult.aiScore !== null) {
				return checkScore(qVar.scoreResult.aiScore)
			}
		} else {
			return ""
		}
	}

	// teacher comment
	public changeCommentEditable(status: boolean, item: any) {
		const studentId = this.dataSource.uid
		const commentId = `${studentId}-${item.douid}`
		this.isCommentObjEditable[commentId] = status
	}

	public isTeacherCommentDisable(item: any): boolean {
		const studentId = this.dataSource.uid
		const commentId = `${studentId}-${item.douid}`

		if (this.viewMode === 'review') return true
		if (this.isCommentObjEditable[commentId]) return false

		return true
	}

	public saveComment(qIndex: number, item: any) {
		const studentId = this.dataSource.uid
		const commentId = `${studentId}-${item.douid}`

		this.questionVar[qIndex].scoreResult.teacherComment = this.teacherCommentsObject[commentId]
		// update server
		let data: any = this.dataSource.records.find(e => e.cid == item.douid)
		if (data) {
			data.result = this.questionVar[qIndex].scoreResult
		}

		this.datas.post2({
			data: {
				api: 'ROBookShare.update_student_result_by_index', loading: true,
				json: [
					this.dataSource.shareID, this.dataSource.bookID, this.ownerID, data.index,
					data.bid, data.pid, data.cid,
					data.data, data.result
				]
			}
		}).then((res: any) => {
			// close input
			this.changeCommentEditable(false, item)
		});
	}

	public cancelComment(qIndex: number, item: any) {
		const studentId = this.dataSource.uid
		const commentId = `${studentId}-${item.douid}`

		this.teacherCommentsObject[commentId] = this.questionVar[qIndex].scoreResult.teacherComment
		this.changeCommentEditable(false, item)
	}

	/**
	 * AI Function
	 */
	// check the ai setting
	// display the ai score when ai return the score
	public isDisplayAIScore(qIndex: number): boolean {
		const qVar: any = this.questionVar[qIndex]
		const isAllowDisplayScore = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("isAllowScore") && qVar.scoreResult.isAllowScore
		const shouldDisplayMode = this.viewMode == 'scoring' || this.viewMode == 'correction'

		const inReviewMode = this.viewMode == 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const isTeacherScored = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty('teacherScore') && qVar.scoreResult.teacherScore !== null
		if (this.setting && this.setting.schoolSettings && this.setting.schoolSettings.ASSESSMENT_AI_CHECKING == '1') {
			if (inReviewMode && !isTeacherScored) {
				return true
			}

			if (isAllowDisplayScore && shouldDisplayMode) {
				return true
			}
		}
		return false
	}

	public isDisplayTeacherScore(qIndex: number): boolean {
		const qVar: any = this.questionVar[qIndex]
		// display when teacher scored
		const inReviewMode = this.viewMode == 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const isTeacherScored = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty('teacherScore') && qVar.scoreResult.teacherScore !== null
		if (inReviewMode) {
			if (isTeacherScored) return true
			return false
		}
		return true
	}

	// display the ai comment when ai return the comment
	public isDisplayAIComment(qIndex: number): boolean {
		const qVar: any = this.questionVar[qIndex]
		const isAllowDisplayComment = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("isAllowComment") && qVar.scoreResult.isAllowComment
		const inScoringMode = this.viewMode == 'scoring'
		const inReviewMode = this.viewMode == 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const isTeacherCommented = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("teacherComment") && qVar.scoreResult.teacherComment !== ""
		if (this.setting && this.setting.schoolSettings && this.setting.schoolSettings.ASSESSMENT_AI_CHECKING == '1') {
			if (isAllowDisplayComment) {
				if (inScoringMode) return true
				if (inReviewMode) {
					// handle teacher comment or ai comment
					if (isTeacherCommented) return false
					return true
				}
				return false
			}
		}
		return false
	}

	public isDisplayCommentContainer(qIndex: number): boolean {
		const qVar: any = this.questionVar[qIndex]
		const isAICommentExist = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("aiComment") && qVar.scoreResult.aiComment !== ""
		const isTeacherCommented = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("teacherComment") && qVar.scoreResult.teacherComment !== ""
		const inReviewMode = this.viewMode == 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const inScoringMode = this.viewMode == 'scoring'

		if (inScoringMode && this.isSubmitted(qIndex)) return true
		if (this.showManualScore && inReviewMode && (isAICommentExist || isTeacherCommented)) {
			return true
		}
		return false
	}

	public isDisplayTeacherComment(qIndex: number): boolean {
		const qVar: any = this.questionVar[qIndex]
		const inScoringMode = this.viewMode == 'scoring'
		const inReviewMode = this.viewMode == 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const isTeacherCommented = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("teacherComment") && qVar.scoreResult.teacherComment !== ""

		if (inScoringMode) return true

		if (inReviewMode) {
			if (isTeacherCommented) return true
			return false
		}
	}

	// get AI comment 
	public getAIComment(qIndex: number): string {
		const qVar: any = this.questionVar[qIndex]
		if (qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("aiComment") && qVar.scoreResult.aiComment) {
			return qVar.scoreResult.aiComment
		}

		return ""
	}

	public isAllowCommentAudio(qIndex: number, item: any): boolean {
		const qVar: any = this.questionVar[qIndex]
		const isInReviewMode = this.viewMode === 'review' || (this.viewMode == 'view' && this.share && this.share.type == 'normal')
		const isLongQuestion = item.type === 'QBLongQuestion'
		const isAICommentExist = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("aiComment") && qVar.scoreResult.aiComment !== ""
		const isTeacherCommented = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("teacherComment") && qVar.scoreResult.teacherComment !== ""

		return isInReviewMode && isLongQuestion && (isAICommentExist || isTeacherCommented)
	}

	// play comment audio
	public playCommentAudio(qIndex: number) {
		const qVar: any = this.questionVar[qIndex]
		const isAICommentExist = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("aiComment") && qVar.scoreResult.aiComment !== ""
		const isTeacherCommented = qVar && qVar.scoreResult && qVar.scoreResult.hasOwnProperty("teacherComment") && qVar.scoreResult.teacherComment !== ""
		// play teacher comment or ai comment 
		let comment = ""
		if (isTeacherCommented) {
			comment = qVar.scoreResult.teacherComment
		} else if (isAICommentExist) {
			comment = qVar.scoreResult.aiComment
		}

		this.ttsService.play(comment).then(() => {
		}).catch(e => {
			const msg = { tc: '字數太多, 引致無法朗讀', sc: '字數太多, 引致無法朗讀', en: 'Word limit exceed.' }[this.datas.lang];
			this.alertService.toastError(msg);
		});
	}

	// update the ai answer after ai grading
	public updateAIAnswer(updatedInfo: any[]) {
		if (updatedInfo && updatedInfo.length > 0) {
			updatedInfo.forEach(info => {
				let index = this.questionList.findIndex(q => q.douid == info.record.cid)
				let data: any = this.dataSource.records.find(e => e.cid == info.record.cid)
				const { record, ...resultObject } = info
				if (data) {
					data.result.aiComment = resultObject.aiComment
					data.result.aiScore = resultObject.aiScore
					data.result.isAllowComment = resultObject.isAllowComment
					data.result.isAllowScore = resultObject.isAllowScore
				}
				// prevent ai data be covered by user
				// this.datas.post2({data:{
				// 	api:'ROBookShare.update_student_result_by_index', loading: true,
				// 	json:[
				// 		this.dataSource.shareID, this.dataSource.bookID, this.ownerID, data.index,
				// 		data.bid, data.pid, data.cid,
				// 		data.data, data.result
				// 	]
				// }})
			})
			this.getAnswers()
		}
	}

	/**
	 * 供詞填充
	 */
	// find the nearest info block for "供詞填充"
	private findNearestInfoBlock(index) {
		for (let i = index - 1; i >= 0; i--) {
			if (this.questionList[i].type == 'QBInfoBlock') {
				return this.questionList[i]
			}
		}
		return null
	}

	// count input answer length
	public getInputWidth(answer: string, index: number) {
		let answerStr: string = answer.toString()
		if (this.isAllowCopyPaste) {
			// get the longest answer
			const infoBlock = this.findNearestInfoBlock(index)
			if (infoBlock && Array.isArray(infoBlock.question) && infoBlock.question.length > 0) {
				const found = infoBlock.question.filter(item => item.type === 'ctext')
				if (found.length > 0) {
					const longest = found
						.reduce((longest, current) => {
							return current.str.length > longest.str.length ? current : longest
						})
					answerStr = longest.str
				}
			}
		}
		const padding = 20
		const englishCharWidth = 25
		const chineseCharWidth = 40
		const numberCharWidth = 25

		let width = 0
		for (let i = 0; i < answerStr.length; i++) {
			const char = answerStr[i]
			if (/\d/.test(char)) { // number
				width += numberCharWidth
			} else if (/[a-zA-Z]/.test(char)) { // English letter
				width += englishCharWidth
			} else { // Chinese character or other characters (e.g. punctuation, symbols)
				width += chineseCharWidth
			}
		}

		return width + padding
	}
	// check if the button can be display
	public isAllowCopyPaste() {
		const isTeacherMode = this.viewMode == 'scoring' || this.viewMode == 'correction' || this.viewMode == 'review'
		return !isTeacherMode
	}

	public scoringModeListUpdate(): void {
		var list: any[] = [];

		if (this.isRater()) {
			list.push("original", "marking1", "correction" /*, "marking2"*/);
		} else {
			list.push("marking1", "correction");
		}

		this.scoringMode2Options = [];
		list.forEach(itm => {
			this.scoringMode2Options.push({
				label: this.translate.instant("bookviewer.scoring_mode." + itm),
				value: itm,
				active: true
			});
		});


	}

	public isRater(): boolean {
		// 當老師批改學生，或學生互評
		if (this.datas.userInfo.user_role == 3)
			return true;
		return false;
	}

	public scoringMode2Change() {

	}

	showToolbar(setShow = 1) {
		this.toolbarConfig.show = setShow ? 1 : 0;
	}

	goQNum(qNum, behavior: ScrollBehavior = 'smooth') {
		const lastQuestionPointer = this.questionPointer;
		const ele = document.querySelector('.q[qNum="' + qNum + '"]');
		if (ele) {
			// const wrapperEle = document.querySelector('.okdPage');
			const wrapperEle = this.elementRef.nativeElement;
			const wrapperRect = wrapperEle.getBoundingClientRect();
			const eleRect = ele.getBoundingClientRect();
			const offsetPosition = eleRect.top - wrapperRect.top + wrapperEle.scrollTop - 20;
			wrapperEle.scrollTo({ behavior: behavior, top: offsetPosition });
			this.questionPointer = qNum;
			if (lastQuestionPointer != this.questionPointer) {
				this.updateScoringToolbarState();
			}
		}
	}

	showQSelection($event) {
		// const items = this.
		const ele = $event.target;
		const parser = new DOMParser();
		const wpOptions = this.questionList.filter(e => e.type != 'QBInfoBlock').map(e => {
			const value = e.questionIndex + 1;
			let title = value + '. ' + this.translate.instant('ro.components.name.' + e.type);
			if (e.question && e.question.length > 0) {
				const found = e.question.find(e => e.type == 'text');
				if (found && found.str) {
					let parseDom = parser.parseFromString('<!doctype html><body>' + found.str + '</body>', 'text/html');
					let str = parseDom.body.textContent;
					str = str.slice(0, 16) + (str.length > 16 ? '...' : '');
					title += ' ' + str;
				}
			}
			return { title: title, value: value, labelStyle: { textAlign: 'left' } };
		});
		this.wpHandle = this.whitePopupService.showSelection(
			ele,
			wpOptions
		);
		this.wpHandle.then(sel => {
			if (sel) {
				this.goQNum(sel.value);
			}
		}).finally(() => {
			this.wpHandle = null;
		});
	}

	initToolBar() {
		let items: any = ['app-hand', 'app-red-epen', 'app-purple-epen', 'app-blue-epen', 'app-straight-line',
			'app-eraser',
			'app-clear', "app-undo", "app-redo",
			'separator', 'app-sticker-like',
			'app-sticker-star', 'app-print'];
		items = items.map(e => {
			return {
				name: e,
				enable: (e != "app-undo" && e != "app-redo")
			};
		});
		let epenSwitchSettings: any = { x: 0, y: 58, xAlign: 'left', yAlign: 'bottom' };
		this.toolbarConfig = { items: items, toolSelected: 'app-hand', show: 0, epenSwitchSettings: epenSwitchSettings, epenSwitchMode: 'hand', epenSwitchLastEpenTools: 'app-red-epen', zoom: 1 };
		const min_val: number = 0.5;
		const max_val: number = 2;
		const ZOOM_BAR_RANGE = 240;
		const vw = window.innerWidth;
		this.toolbarConfig.zmAllVal = (((vw / 920) - min_val) * ZOOM_BAR_RANGE) / (max_val - min_val);
		this.toolbarConfig.zmFitVal = 24.29;
		this.userInfo.requestUserInfo(this.datas.userInfo.uid).then(info => {
			this.toolbarConfig.profileImg = this.userInfo.getProfileImgObject(info, this.datas.lang);
		});
	}

	public scoringToolbarAction(event, name: string): void {
		const toolConfig = this.toolbarConfig;
		if (name == '?') {
			var ele = document.elementFromPoint(event.center.x, event.center.y);
			if (ele.hasAttribute("btnName")) {
				name = ele.getAttribute("btnName");
			} else
				return;
		}
		if (name == 'app-hand') {
			toolConfig.epenSwitchMode = 'hand';
			this.elementRef.nativeElement.classList.remove('epen');
		} else if (['app-clear', 'app-undo', 'app-redo'].indexOf(name) == -1){
			toolConfig.epenSwitchMode = 'epen';
			toolConfig.epenSwitchLastEpenTools = name;
			this.elementRef.nativeElement.classList.add('epen');
		}

		if (name == 'app-correction-camera') {
			// this.cameraCapture.lanuch().then(result=> {
			// 	// {file, width, height}
			// 	var pc:ROPageComponentContainer = this.slider.centerPage.ref.instance as ROPageComponentContainer;
			// 	pc.markingLayer.addCorrectionPhoto(result.file, result.width, result.height);

			// }).catch(reason =>{
			// });
			// this.cameraCapture._backdropZindex = 1000000;
			return;
		}

		// if(this.slider.context.config.markingEnabled) {
		// 	this.slider.context.subject.next({
		// 		type:"action", 
		// 		action:"scoringToolbar",
		// 		data:name
		// 	});
		// } else if(this.slider2 && this.slider2.context.config.markingEnabled){
		// 	this.slider2.context.subject.next({
		// 		type:"action", 
		// 		action:"scoringToolbar",
		// 		data:name
		// 	});
		// }
		if (this.context.config.markingEnabled) {
			const markingLayer = this.getCurrentMarkingLayer;
			if (markingLayer) {
				markingLayer.setSelectTool(name);
			}
		}

		if (name == "app-print") {
			this.print();
			return;
		}
		this.optionChange.emit({ key: 'scoringToolbar' });
		if (['app-clear', 'app-undo', 'app-redo'].indexOf(name) == -1) {
			toolConfig.toolSelected = name;
			// this.scoringToolChange();
		} else {
			this.updateScoringToolbarState();
		}

	}

	public epenSwitchInit(): void {
		let lastValue: any = this.datas.getPersonalSetting("correctionEpenSwitchSetting");
		this.toolbarConfig.epenSwitchSetting = (lastValue && lastValue.hasOwnProperty("ver") && lastValue.ver == 1) ?
			lastValue : this.datas.getPersonalSetting("correctionEpenSwitchSetting", true);

		if (this.viewMode == 'scoring')
			this.readyToRecalEpenSwitchPosition();
	}

	public readyToRecalEpenSwitchPosition() {
		if (this.epenSwitchBounds) {
			const epenSwitchSettings = this.toolbarConfig.epenSwitchSettings;
			if (epenSwitchSettings.x + 68 > this.epenSwitchBounds.nativeElement.offsetWidth) {
				var tmpx: number = this.epenSwitchBounds.nativeElement.offsetWidth - 68;
				if (tmpx >= 0)
					epenSwitchSettings.x = tmpx;
				else {
					epenSwitchSettings.x = 0;
				}

				this.datas.setPersonalSetting("correctionEpenSwitchSetting", epenSwitchSettings);
			}

		} else {
			setTimeout(() => {
				this.readyToRecalEpenSwitchPosition();
			}, 30);
		}
	}

	public epenSwitchPositionChange(event) {
		const epenSwitchSettings = this.toolbarConfig.epenSwitchSettings;
		epenSwitchSettings.x = event.data.x;
		epenSwitchSettings.y = event.data.y;
		if (event.event == "end")
			this.datas.setPersonalSetting("correctionEpenSwitchSetting", epenSwitchSettings);
	}
	public switchEpen(): void {
		const toolbarConfig = this.toolbarConfig;
		if (toolbarConfig.epenSwitchMode == "hand") {
			toolbarConfig.epenSwitchMode = "epen";
			toolbarConfig.toolSelected = toolbarConfig.epenSwitchLastEpenTools;
			this.elementRef.nativeElement.classList.add('epen');
		} else {
			toolbarConfig.epenSwitchMode = "hand";
			toolbarConfig.toolSelected = 'app-hand';
			this.elementRef.nativeElement.classList.remove('epen');
		}
		/*
		if(this.slider.context.config.markingEnabled) {
			this.slider.context.subject.next({
				type:"action", 
				action:"scoringToolbar",
				data:this.scoringToolbarSel
			});
		} else if(this.slider2 && this.slider2.context.config.markingEnabled){
			this.slider2.context.subject.next({
				type:"action", 
				action:"scoringToolbar",
				data:this.scoringToolbarSel
			});
		}*/
		this.optionChange.emit({ key: 'scoringToolbar' });
	}

	public changeToSelectSchoolSticker(id: number): void {
		this.toolbarConfig.toolSelected = 'selectSchoolSticker#' + id;
		this.schoolStickerURL = null;
		this.stickerImageService.requestStickerImage(id).then(success => {
			this.schoolStickerURL = success.image;
		});

	}

	public openViewSetting(event) {
		const ele = event.target;
		if (!this.viewSettingPopup.opened) {
			this.viewSettingPopup.open(ele).then(() => {
			}).catch((e) => {
			});
		} else {
			this.viewSettingPopup.close();
		}
	}

	public optionChange_emit(data) {
		if (data.key == "onoff_marking_layer") {
			this.onoff_marking_layer = data.value;
			this.markingLayerRefs.forEach(ref=>{
				ref.context.showMarkingLayer = data.value;
			});
		}
		this.optionChange.emit(data);
	}

	public setViewFitType(name: string, $event): void {
		const vw = window.innerWidth;
		const min_val: number = 0.5;
		const max_val: number = 2;
		const ZOOM_BAR_RANGE = 240;
		if (name == 'zoom_fitwidth') {
			this.toolbarConfig.zoom = 1;
			this.zoomBarVal = 24.29;
		} else if (name == 'zoom_all') {
			this.toolbarConfig.zoom = vw / 1092;
		}
		this.zoomBarVal = ((this.toolbarConfig.zoom - min_val) * ZOOM_BAR_RANGE) / (max_val - min_val);
		this.afterScale();
	}

	public dragZoomBar(event): void {
		event.stopImmediatePropagation();
		const subject: Subject<any> = this.dd.pointerStart(event);
		const offset: any = { x: 0, y: 0 };
		const min_val: number = 0.5;
		const max_val: number = 2;
		const ZOOM_BAR_RANGE = 240;
		const vw = window.innerWidth;
		this.toolbarConfig.zmAllVal = (((vw / 920) - min_val) * ZOOM_BAR_RANGE) / (max_val - min_val);
		const subscription: Subscription = subject.subscribe((o: any) => {
			const pt = DOMHelper.getLocalPoint(event.target.parentElement, o.point);
			if (o.type == "start") {
				offset.x = Math.round(pt.x) - this.zoomBarVal;
			}

			this.zoomBarVal = Math.min(ZOOM_BAR_RANGE, Math.max(0, Math.round(pt.x) - offset.x));
			const zoom = min_val + (max_val - min_val) * this.zoomBarVal / ZOOM_BAR_RANGE;
			this.toolbarConfig.zoom = zoom;
			this.afterScale();

			if (o.type == "cancel" || o.type == "timeout" || o.type == "end") {
				subscription.unsubscribe();
			}
		});
	}

	public afterScale(): void {
		console.log('zoom:'+this.toolbarConfig.zoom);
		const scaleEle = this.scaleRef.nativeElement;
		const rect = document.querySelector('.okdPage').getBoundingClientRect();
		scaleEle.style.height = rect.height + 'px';
		const left = (rect.width - scaleEle.offsetWidth) / 4;
		// scaleEle.style.left = left + 'px';
	}

	private initContext() {
		this.context = new ROContext();
		this.context.service = this.roContextService;
		let clonedShare = JSON.parse(JSON.stringify(this.data.share));
		let clonedBook:any = JSON.parse(JSON.stringify(this.data.entry));
		this.context.assetUploader = new ROAnswerAssetUpload(this.datas, this.uls);
		this.context.config = {
			viewMode: this.viewMode,
			markingEnabled: true,
			showMarking: true,
			readOnly: false,
			index: this.data.sourceIndex,
			share: clonedShare,
			book: clonedBook,
			viewerID: this.data.viewerID 
		};

		this.updateBookConfig(this.context.config, this.scoringMode2);
		this.context.subject.subscribe(d => {
			if (d.type == "action") {
				if (d.action == "showEPen") {
					this.showEPen();
					// } else if (d.action === "submitAfterRecord") {
					// this.autoSubmitPage()
					// } else if (d.action === "nextQuestion") {
					// this.nextQuestion()
					// } else if (d.action === "close") {
					// this.close()
					// } else if (d.action === "reTry"){
					// this.reTry()
				}
			} else if (d.type == "scoringEpenUndoRedo") {
				this.updateScoringToolbarState();

			} else if (d.type == "takePhoto") {
				this.cameraCapture.lanuch().then(d.callback, (reason) => { });
				this.cameraCapture._backdropZindex = 1000000;

			} else if (d.type == "getCurrentScoringTool") {
				d.target.selectTool = this.toolbarConfig.toolSelected;

				// } else if(d.type == "previewFile") {
				// 	var url:string = d.fileObj.url;
				// 	this.preview.setUrlPrefix((url.substr(0,4)=="http" || url.substr(0,3)=="//:") ? '' : this.fileio.getResourceServer(url));
				// 	this.preview.setFile(d.fileObj);
				// 	this.preview.open();
				// 	this.preview._backdropZindex = 1000000;

			} else if (d.type == "notify") {
				if (d.notify.type == "scoreUpdated") {
					this.emitter.next({ type: "updateSelectedStudentRecordInList" });
				} else if (d.notify.type == "ePenNoteRequest") {
					// var page:ROPageComponent = this.slider.getCurrentPageComponent();
					// var noteLayer:NoteLayer = page.getNoteLayer();
					// var touchLayer = this.context.epenState.mode == "E" ? noteLayer.getSVGLayer() : null;
					// noteLayer.setup(this.context.epenState, d.notify.createObservable(touchLayer));
					debugger;

				}
			} else if (d.type == "datasourceReady" || d.type == "isAbsent") {
				this.emitter.next({
					type: d.type, callback: d.callback
				});

			}
		});
	}

	public updateBookConfig(config: any, mode: string) {
		config.subViewMode = mode;
		if (['view', 'preview', 'review'].includes(config.viewMode)) {
			config.markingEnabled = false;
			config.showMarking = true;
			config.readOnly = config.viewMode == 'review';
		} else if (['scoring', 'submission_review'].includes(config.viewMode)) {
			config.markingEnabled = true;
			config.showMarking = true;
			config.readOnly = false;
		} else {
			//correction
			if (mode == "marking1") {
				// marking1: 學生答案 + 老師 marking (無改正 box)
				config.markingEnabled = false;
				config.showMarking = true;
				config.readOnly = true;

			} else if (mode == "correction") {
				// correction: 學生答案 + 老師 marking + correction (有改正 box)
				config.markingEnabled = true; // correction epen
				config.showMarking = true;
				config.readOnly = false;
			}
		}
		config.index = this.sourceIndex;

		if (this.forceReadOnly || config.viewMode == "submission_review")
			config.readOnly = true;
	}

	ePressOrTouchOnMarkingLayer(event: any, qnum = ''): void {
		console.log(event)
		if (qnum === '') {
			return;
		}
		const zoom = this.toolbarConfig.zoom;
		const markingLayer = this.markingLayerRefs.find(e => e.assessmentCom.qnum == qnum);
		if (markingLayer && (this.context.config.viewMode == "scoring" || this.context.config.viewMode == "correction")) {
			markingLayer.setSelectTool(this.toolbarConfig.toolSelected);
			if (event.pointerType == 'touch' || event.pointerType == 'pen') {
				markingLayer.ePress2(event, event.pointerType);
			} else {
				markingLayer.ePress(event);
			}
		}
	}

	blockEvent($event){ //block contextmenu
		if ($event.touches && $event.pointerType == 'pen'){ //contextmenu event with apple pencil
			$event.preventDefault();
			$event.stopPropagation();
		}
	}

	get getCurrentMarkingLayer() {
		return this.markingLayerRefs.find(e => e.assessmentCom.qnum == this.questionPointer);
	}

	get getVisibleMarkingLayer() {
		let markingLayerRefs = [];

		return [];
	}

	protected updateScoringToolbarState(): void {
		if (this.toolbarConfig && this.toolbarConfig.items) {
			const markingLayer = this.getCurrentMarkingLayer;
			if (markingLayer) {
				this.toolbarConfig.items.forEach(e => {
					if (e.name == "app-undo")
						e.enable = markingLayer.epenUndoStep.length > 0;
					else if (e.name == "app-redo")
						e.enable = markingLayer.epenRedoStep.length > 0;
				});
			}
		}
	}

	showEPen() {
		this.context.epenState = {
			mode: "S",
			color: "#1F58AD",
			strokeWidth: 10,
			enabled: true
		};
		// this.epenToolbar.model = this.context.epenState;
		this.noteLayerController.context = this.context;
		const ele = <HTMLElement>document.querySelector('q.onQuestionPointer');
		this.noteLayerController.initTouchContainer(ele);
		// this.toolbarStacks.push(ToolbarType.EPEN);
	}

	public markingChange(event, itm): void {
		const config = this.context.config;
		const chapterId = config.book.id;//試卷chaperId是bookId
		const pageId = 'P' + itm.douid;
		const dataSource = this.context.config.dataSource;
		const qNum = itm.questionIndex + 1;
		if (event.type == "epenStart") {
			this.onMarkingChangeSubject.next(null);
		} else if (event.type == "epen") {
			const markingLayer = this.markingLayerRefs.find(e => e.assessmentCom.qnum == qNum);
			let updateEpen: any = markingLayer.getChangedEpenMarkings();
			const tagName: string = updateEpen.tag;
			const comID: string = updateEpen.tag == "#correction#" ? tagName : `${tagName}${updateEpen.creator}#${itm.douid}`;

			// 更新記憶體內記錄
			dataSource.setAnswer(chapterId, pageId, comID, updateEpen.data, null);
			const stuData = this.bookViewerDataSource.index[config.index].docs[chapterId].students[dataSource.student.uid];
			let tmpRecord = stuData.records.find(e => e.tag == '#marking#' && e.cid == comID);
			if (tmpRecord) {
				// tmpRecord.data = updateEpen.data;
			}
			tmpRecord = this.dataSource.records.find(e => e.tag == '#marking#' && e.cid == comID);
			if (tmpRecord) {
				// tmpRecord.data = updateEpen.data;
			}
			// 更新 DB 記錄
			var params: any[] = [
				config.share.id, config.book.id, dataSource.student.uid, config.index,
				chapterId, pageId, comID, updateEpen.data, null, tagName
			];

			this.onMarkingChangeSubject.next(params);
			/*
			this.clearDelayUpdateTimer();
			this.delayUpdateTimer = window.setTimeout((params)=> {
				AppStatusComponent.callAPI('ROBookShare.update_student_result_by_index', params).then((res:any)=>{
					console.log("update marking success", res);
				}).catch(reason=>{
				});
			}, 1000, params);
			*/
			markingLayer.redraw(event.redraw ? true : false);

		} else if (event.type == "addSticker") {
			var sticker: StickerBase = event.data;
			var lesson_id = 0;// todo
			const pageNum = this.questionList.findIndex(e => e.douid == itm.douid) + 1;

			AppStatusComponent.callAPI('Prize.addStudentMarking',
				[sticker.sourceJSON.type, sticker.sourceJSON.remark.id, sticker.sourceJSON.num, dataSource.student.uid,
				config.share.school_year, config.share.tags[0].id,
					lesson_id, config.share.id, config.book.id, chapterId, pageId, config.index, pageNum, sticker.sourceJSON.remark
				]).then((res: any) => {
					console.log("add sticker success", res);
					sticker.dataID = res.log_id;
					sticker.createTime = res.createTime;
					// todo 如果是星星，要更新畫面星星數量
				}).catch(reason => {
				});

		} else if (event.type == "updateSticker") {
			var sticker: StickerBase = event.data;
			if (sticker.dataID == -1) {
				if (sticker.retryCount > 3) {
					sticker.retryCount = 0; // 等有人叫佢再試
					return;
				}

				sticker.retryCount++;
				window.setTimeout(() => {
					this.markingChange(event, itm);
				}, 500);
				return;
			}

			AppStatusComponent.callAPI('Prize.updateStudentMarking', [sticker.dataID, sticker.sourceJSON.remark]).then((res: any) => {
				console.log("update sticker success", res);
			}).catch(reason => {
			});

		} else if (event.type == "removeSticker") {
			var sticker: StickerBase = event.data;
			AppStatusComponent.callAPI('Prize.updateStudentMarking', [sticker.dataID, null]).then((res: any) => {
				console.log("remove sticker success", res);
				// todo 如果是星星，要更新畫面星星數量
			}).catch(reason => {
			});
		}
		const lastQuestionPointer = this.questionPointer;
		this.questionPointer = qNum;
		if (lastQuestionPointer != this.questionPointer) {
			this.updateScoringToolbarState();
		}
	}

	tmpToggleDefaultAnswer(event) {
		event.preventDefault();
		event.stopImmediatePropagation();

		this.context.showDefaultAnswer = true;

		var subject: Subject<any> = this.dd.pointerStart(event);
		var subscription: Subscription = subject.subscribe((o: any) => {
			if (o.type == "cancel" || o.type == "timeout" || o.type == "end") {
				subscription.unsubscribe();
				this.context.showDefaultAnswer = false;
			}
		});
	}

	print(): Promise<any> {
		return this.getPrintOptions().then((printOption: PrintBookOption) => {

			var context: ROContext = this.context.clone();
			context.showSticker = printOption.showSticker;
			context.showMarkingLayer = printOption.showMarking ? true : false;
			context.config = this.cloneBookConfig(this.context.config);

			if (printOption.submissionIndex == 1) {
				context.config.subViewMode = "marking1";
			} else if (printOption.submissionIndex == 2) {
				context.config.subViewMode = "correction";
			}
			
			return this.getJobs(printOption).then((_jobs) => {
				printOption.assessmentViewerParams = this.cloneParams();
				
				var task: PrintTask = this.prints.printComponent(
					document.body.querySelector("#printContainer"),
					this.dcs,
					PrintAssessmentView,
					{
						printOption: printOption,
						bookConfig: this.context.config,
						context: context,
						jobs: _jobs
					},
					null
				);
				this.printingState.message = this.translateService.instant("commonService.printStart");
				this.printingState.progress = 0;

				var progressDialog: ProgressBox2Component = this.dcs.create(ProgressBox2Component, {
					hasCancelButton: true,
					frontText: this.printingState.message,
					progressPt: this.printingState.progress
				});

				progressDialog.open(this.elementRef.nativeElement);
				var subscription: Subscription = new Subscription();
				var update = () => {
					progressDialog.frontText = this.printingState.message;
					progressDialog.progressPt = this.printingState.progress;
				}
				subscription.add(progressDialog.onCancel.subscribe((a: any) => {
					view.stopped = true;
				}));
				var view: PrintAssessmentView = task.reference;
				subscription.add(
					view.subject.subscribe((d: any) => {
						if (d.type == "message") {
							this.printingState.message = this.translateService.instant("commonService.printing", { status: d.message });
							this.printingState.progress = view.progress;
							update();
						} else if (d.type == "cancel") {
							task.cancel();
							subscription.unsubscribe();
							progressDialog.close();
						} else if (d.type == "ready") {
							progressDialog.close();
							task.print();
							subscription.unsubscribe();
						}
					})
				);
			});

		});
	}

	private getPrintOptions(): Promise<any> {
		this.bookPrintDialog.model.book = this.context.config.book;
		this.bookPrintDialog.model.assessmentViewerParams = this.cloneParams();
		const ele = document.querySelector('.studentRecord.selected');
		const selectedUid = ele ? ele.getAttribute('uid') : null;
		this.bookPrintDialog.model.allStudents = this.data.bv.students.map(e => {
			let cloneStudent = JSON.parse(JSON.stringify(e));
			cloneStudent.displayname = StudentInfo.getStudentName(cloneStudent, this.datas.lang);
			if (e.uid == selectedUid) {
				this.bookPrintDialog.model.individuals = [cloneStudent];
			}
			return cloneStudent;
		});

		this.bookPrintDialog.model.title = this.context.config.book.title;
		this.bookPrintDialog.model.bookPageCount = 1;
		this.bookPrintDialog.model.chapterPageCount = 1;
		this.bookPrintDialog.viewMode = this.viewMode;
		this.bookPrintDialog.bookType = this.context.config.book.type;
		this.bookPrintDialog.multiChapter = false;

		return new Promise((resolve: Function, reject: Function) => {
			var subscription: Subscription = this.bookPrintDialog.subscribe(
				(response: any) => {
					if (response.type == "confirm") {
						subscription.unsubscribe();
						resolve(response.data)
					} else if (response.type == "cancel") {
						subscription.unsubscribe();
						reject(response.type);
					} else {
						this.bookPrintDialog.close();
						subscription.unsubscribe();
						reject(null);
					}
				}
			);
		});
	}

	private cloneBookConfig(config) {
		var keys: string[] = ["isMainSlide",
			"splitViewEnable",
			"index",
			"student",
			"dataSource",
			"document",
			"book",
			"chapters",
			"pages",
			"context",
			"pageIndex",
			"viewerID",
			"ownerID",
			"viewMode",
			"subViewMode",
			"course",
			"share",
			"setting",
			"markingEnabled",
			"showMarking",
			"readOnly"];
		var newConfig: any = {};
		keys.forEach((key: string) => {
			newConfig[key] = config[key];
		})
		return newConfig;
	}

	getJobs(printOption: PrintBookOption): Promise<any[]> {
		if (this.viewMode == "scoring") {
			// var source:AnswerSource = this.bookConfig.dataSource;
			var bsid = this.context.config.share.id;
			var bookID = this.context.config.book.id;
			var printStudents: any[] = printOption.individuals;
			if (printOption.printStudentRange == "all") {
				printStudents = [ ...printOption.allStudents ];
			} else if (printOption.printStudentRange == "individual") {
				printStudents = [ ...printOption.individuals ];
			} else {
				throw ("unknow printStudentRange value");
			}

			// printOption.showSticker
			var studentIDArray = printStudents.map((student) => { return student.uid });
			// var studentIDArray = [42];
			return this.context.service.dataSourceService.loadAllStudentData(
				bsid, bookID, studentIDArray // [42, 100877, 591, 296209]
			).then((response) => {
				var map = {};
				var list: any[] = printStudents.map((student) => {
					var o = {
						showStudent: true,
						submissions: [],
						student: student
					};
					map[student.uid] = o;
					return o;
				});
				var manager: ROStudentDataSourceManager = this.context.service.dataSourceService.submssionToDataSoureManager(
					response.submissions
				);
				response.submissions.forEach((submission: any) => {
					if (!map.hasOwnProperty(submission.uid)) return; // ignored
					var o = map[submission.uid];
					o.submissions.push(submission);
					// var source1 = manager.getSource(1, o.student);
					// var source2 = manager.getSource(2, o.student);
					// o.source1 = source1;
					// o.source2 = source2;
					o.source = manager.getSource(printOption.submissionIndex, o.student);;
				})
				return list;
			}).then((list: any[]) => {
				// filter answer that does not have submission
				list = list.filter((element) => {
					return element.submissions && element.submissions.length;
				});
				if (list.length <= 0) {
					this.alertService.alert("No Sumbission");
					return Promise.reject();
				}
				return list;
			});
		} else { //暫時應不會出現
			if (printOption.withAnswer) {
				return Promise.resolve([{
					showStudent: false,
					student: printOption.allStudents,
					source: this.context.config.dataSource
				}]);
			} else {
				return Promise.resolve([{
					showStudent: false,
					student: null,
					source: null
				}]);
			}
		}
	}

	cloneParams(){
		const properties = Object.keys(this).filter(key=> typeof this[key] !== 'function');
		let obj:any = {};
		properties.forEach(key=>{
			obj[key] = this[key];
		});
		return obj;
	}

	build(){
		
	}

}