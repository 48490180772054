import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from "@angular/core";
import { speechBox } from "./speechBox";
import { AlertService } from "src/app/service/alert.service";
import { ChatService } from "src/app/service/chatGPT.service";
import { DataService } from "src/app/service/data.service";
import { StorageService } from "src/app/service/storage.service";
import { LoadingService } from "../../loadingModule/loading.service";
import * as RecordRTC from 'recordrtc';
import { UploadService } from "../../uploadModule/upload.service";
import { ThemeService } from "src/app/service/theme.service";
import { ObjectUtils } from "src/app/common/ObjectUtils";
import * as moment from 'moment';

@Component({
	selector: "conversationPractice",
	templateUrl: "./conversationPractice.html",
	styleUrls: ["./conversationPractice.scss"],
})
export class conversationPractice implements OnChanges, OnDestroy {
	@Input() public parent;
	@Input() public context;
	@Input() public redoConversation;
	@Input() public studentLevel;
	@Input() public objective;
	@Input() public defaultQuestion;
	@Input() public EDBScore;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	@ViewChild("speechBox", { static: false }) speechBoxContainer: speechBox;
	@ViewChild('dialogContainer', { static: false }) dialogContainer!: any;
	@ViewChild('canvas', { static: false }) canvas: ElementRef<HTMLCanvasElement>;

	public is_objective_box_open = false;

	// For media
	public recorder: any = null;
	public stream: any = null;
	public soundWaveStream: any = null;
	public timer;
	public recordingTime = 30;
	public recordTimer;
	public isResReceived = false;
	public status: string = "idle";							// "idle" / "recording" / "loading" / "playing"
	public robotImageStatus: string = "idle";				// "idle" / "talk" / "correct" / "wrong" / "wrong_last"
	public hintQuota = 3;
	public audio: HTMLAudioElement = new Audio();
	public speed = 1.1;
	public AiSpeedOptions: any[] = [
		{ label: "Slow", value: 0.9 },
		{ label: "Normal", value: 1.1 },
		{ label: "Fast", value: 1.3 },
	];

	// For EDB Question
	public objectiveUsed = [];

	// For Default Question
	public questionIndex = 0;

	// Config
	public userName;
	public instruction = "";
	public starNumberInExercise = 0;
	public totalStarNumber;
	public audioInfo = [];
	public promptLog = [];
	public conversation = [];
	public eachQuestionScore = [];
	public questionNumber = 1;

	public recordEnable = false;
	public isAnswerDefaultQuestion = false;
	public userGreetingPass = false;
	public userGreetingLimit = Math.floor(Math.random() * 2) + 3;
	public userGreetingCounter = 1;
	public userFirstEDBPass = false;
	public userEDBLimit = Math.floor(Math.random() * 2) + 3;
	// public userEDBLimit = 1;
	public userEDBCounter = 1;
	// public userEDB_retry_limit = 2;
	public userEDB_retry_limit = 0;
	public userEDB_retry = 0;
	public userSecondEDBPass = false;
	public userDefaultQuestion_retry_limit = 3;
	public userDefaultQuestion_retry = 0;
	public defaultQuestionFinish = false;
	public receiveMyData = false;
	public isInstructionShown = false;
	public isContentShown = false;
	public isConfirmButtonShown = false;

	public aiOralChar = 'student';
	public networkCheckOn: boolean = false;

	// For Sound Wave
	private audioContext: AudioContext | null = null;
	private analyserNode: AnalyserNode | null = null;
	private mediaStreamAudioSourceNode: MediaStreamAudioSourceNode | null = null;
	private animationFrameId: number | null = null;
	private frameCount: number = 0;
	private framesToSkip: number = 4;
	private isDestroyed:boolean= false;



	constructor(
		private chat: ChatService, public dataService: DataService, public storage: StorageService, private lds: LoadingService,
		private alert: AlertService, public upload: UploadService, public datas: DataService, private eleRef: ElementRef, private theme: ThemeService
	) {
		this.theme.applyStyleObj({
			"default": { "section-maincolor": "#0052AF" },
			"dark": { "section-maincolor": "#0052AF" }
		}, this.eleRef.nativeElement);
	}

	async ngOnInit() {
		try {
			let viewMode = this.context.config.viewMode;
			this.userName = this.dataService.userInfo.nickname;
			this.aiOralChar = sessionStorage.getItem('aiOralChar') || '1';
			this.preloadImage();
			this.setInstruction();

			if (viewMode !== "scoring" && viewMode !== "preview" && viewMode !== "review") {
				this.soundWaveStream = await navigator.mediaDevices.getUserMedia({ audio: true });
				this.audioContext = new AudioContext();
				this.mediaStreamAudioSourceNode = this.audioContext.createMediaStreamSource(this.soundWaveStream);
				this.analyserNode = this.audioContext.createAnalyser();
				this.analyserNode.fftSize = 1024;
				this.mediaStreamAudioSourceNode.connect(this.analyserNode);

				this.isInstructionShown = true;
			} else if (viewMode === "review") {
				console.log(this.parent.myData)
				this.conversation = this.parent.myData.conversationRecord;
				this.isInstructionShown = false;
				this.isContentShown = true;
			}
		} catch (err) {
			console.error('Error accessing microphone:', err);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log(changes)
		let redo = changes.redoConversation;
		if (redo && redo.previousValue === false && redo.currentValue === true) {
			this.hintQuota = 3;
			this.objectiveUsed = [];
			this.EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

			this.audioInfo = [];
			this.promptLog = [];
			this.conversation = [];
			this.eachQuestionScore = [];
			this.questionNumber = 1;

			this.isAnswerDefaultQuestion = false;
			this.userGreetingPass = false;
			this.userGreetingLimit = Math.floor(Math.random() * 2) + 3;
			this.userGreetingCounter = 1;
			this.userFirstEDBPass = false;
			this.userEDBLimit = Math.floor(Math.random() * 2) + 3;
			this.userEDBCounter = 1;
			// this.userEDB_retry_limit = 2;
			this.userEDB_retry_limit = 0;
			this.userEDB_retry = 0;
			this.userSecondEDBPass = false;
			this.userDefaultQuestion_retry_limit = 3;
			this.userDefaultQuestion_retry = 0;
			this.defaultQuestionFinish = false;
			this.isConfirmButtonShown = false;
			// this.intro();
			this.greeting();
		} else if (this.context.config.viewMode === "scoring") {
			this.conversation = this.parent.myData.conversationRecord;
			this.isContentShown = true;
		}
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.audio.pause();
		this.audio.currentTime = 0;
		if (this.audioContext) this.closeMicrophone();
	}

	/////////////////////////////////////////
	//             Media function          //
	/////////////////////////////////////////
	public record(): void {
		this.status = "recording";
		navigator.mediaDevices.getUserMedia({ video: false, audio: true }).then((stream) => {
			let options = {
				mimeType: "audio/wav",
				numberOfAudioChannels: 1,
				desiredSampRate: 16000,
			};
			//Start Actuall Recording
			let StereoAudioRecorder: any = RecordRTC.StereoAudioRecorder;
			this.recorder = new StereoAudioRecorder(stream, options);
			this.stream = stream;
			this.recorder.record();
		});

		if (this.timer) {
			clearInterval(this.timer)
		}
		this.timer = setInterval(() => {
			if (this.recordingTime === 0) {
				this.finishRecording();
			} else {
				this.recordingTime = this.recordingTime - 1;
			}
		}, 1000);
	}

	startChecking() {
		this.status = "recording";
		if (this.analyserNode) {
			const canvas = this.canvas.nativeElement;
			const canvasCtx = canvas.getContext('2d');
			const data = new Float32Array(this.analyserNode.fftSize);

			const drawWaveform = () => {
				this.frameCount++;
				if (this.frameCount % this.framesToSkip === 0) {
					this.analyserNode.getFloatTimeDomainData(data);
					canvasCtx.clearRect(0, 0, canvas.width, canvas.height);
					const barWidth = (canvas.width / data.length) * 80;
					const barGap = 15;
					let x = 0;
					for (let i = 0; i < data.length; i++) {
						const v = data[i] * 1000.0;
						canvasCtx.fillStyle = 'rgb(255, 255, 255)';
						canvasCtx.fillRect(x, canvas.height / 2 - v / 2, barWidth, v);
						x += barWidth + barGap;
					}
				}
				this.animationFrameId = requestAnimationFrame(drawWaveform);
			};
			drawWaveform();
		}
	}

	recordAction() {
		this.record();
		this.startChecking();
	}

	public finishRecording(): void {
		this.status = "loading";
		this.recordEnable = false;

		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}

		this.recordingTime = 30;
		clearInterval(this.timer);
		this.recorder.stop((blob) => {
			let file: File = new File([blob], "record.mp3");

			let reference: string = this.context.createReference();
			let uid = this.context.service.dataService.userInfo.uid;

			if (this.parent.myData == null) {
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
				this.parent.myData = {
					user: uid,
					key: this.parent.fileReference.key,
					reference: this.parent.fileReference.reference
				}
			} else {
				this.parent.myData.user = uid;
				this.parent.myData.reference = reference;
				this.parent.fileReference = {
					key: this.parent.douid,
					reference: reference,
					file: file,
					index: 0
				};
			}

			this.parent.answerChanged = true;

			this.upload.uploadFileObject(file).then((res) => {
				const hostname = location.href.indexOf('localhost') > -1 ? 'dev.openknowledge.hk' : location.hostname;
				let filePath = '//' + hostname + '/RainbowOne/' + res.path;
				if (this.parent.subType === "oralAI") {
					//free conversation
					let file: File = new File([blob], "test.mp3");
					this.getSttApi(file, filePath);
				} else {
					this.ttsApi(filePath);
				}
			});

			let tracks = this.stream.getTracks();
			for (let track of tracks) track.stop();
		});
		this.status = "idle";
	}

	stopChecking() {
		if (this.animationFrameId) {
			cancelAnimationFrame(this.animationFrameId);
			this.animationFrameId = null;
		}
	}

	stopAction() {
		this.finishRecording();
		this.stopAction();
	}

	async closeMicrophone() {
		if (!this.soundWaveStream) return;
		try {
			console.log("closeMicrophone")
			await this.audioContext.close();
			this.soundWaveStream.getTracks().forEach(track => track.stop());			
		} catch (error) {
			console.error('Error accessing microphone:', error);
		}
	}

	private getSttApi(wavFile: File, filePath): Promise<void> {
		let lang = "en-US";
		let data: FormData = new FormData();
		data.append('api', 'ROSpeechRecognition.recognize_tts');
		data.append("json", JSON.stringify([filePath, "", lang]));
		const delayTime = 2000;

		return this.datas.post2({ data: data, loading: false }).then(async (res: any) => {
			console.log("delayTime", delayTime);
			var message: string = "";

			if (!res.result || !res.result.DisplayText) {
				setTimeout(() => {
					this.alert.alert2(this.translateText(
						"偵測不到聲音，請重新再試。",
						"偵測不到聲音，請重新再試。",
						"Cannot detect the sound recording, please try again"
					), null, { btns: [["ok"]] });
				}, 2000);
			} else {
				message = res.result.DisplayText;

			}
			this.promptLog.push({ role: "user", content: message })
			this.conversation.push({ role: "user", content: message, audioPath: filePath })

			let promptInput = [...this.promptLog].filter(message => !message.isHint).slice(-4);
			promptInput.unshift(this.promptLog[0])
			const robotRes = await this.sentPromptApi(promptInput);
			this.scrollToBottom();
			let content = JSON.parse(robotRes).choices[0].message.content;

			let audio_res = await this.playAudio(content, this.replaceSymbol(content), false);
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
			await audio_res.is_finish;
			this.recordEnable = true;
			return;
		}).catch((err: any) => {
			return;
		});
	}

	async ttsApi(filePath: string) {
		let lang = "en-US";
		let dataWithAnswer: FormData = new FormData();
		let dataWithoutAnswer: FormData = new FormData();
		dataWithAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		dataWithoutAnswer.append("api", "ROSpeechRecognition.recognize_tts");
		if (this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].answer, lang]));
		} else {
			dataWithAnswer.append("json", JSON.stringify([filePath, this.defaultQuestion[this.questionIndex].name, lang]));
		}
		dataWithoutAnswer.append("json", JSON.stringify([filePath, "", lang]));

		try {
			this.isResReceived = false;
			let firstRecordTime = Date.now();

			const recordTimeCount = setInterval(async () => {
				this.recordTimer = Date.now();
				console.log(this.recordTimer - firstRecordTime)

				// 超時
				if (this.recordTimer - firstRecordTime > 10000 && !this.isResReceived) {
					clearInterval(recordTimeCount);
					this.alert.toastError(this.translateText(
						"網絡繁忙，自動重試中，請稍候",
						"网络繁忙，自动重试中，请稍后",
						"Network latency, repeat processing automatically, result is going to release"
					));
				}
			}, 1000);

			if (this.isAnswerDefaultQuestion) {
				const firstRequest = await this.dataService.post2({ data: dataWithAnswer, loading: false });
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([firstRequest, secondRequest]).then(([firstRequest, secondRequest]) => {
					if (!firstRequest.result || !secondRequest.result ||
						firstRequest.result.RecognitionStatus === "InitialSilenceTimeout" ||
						secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						let userAnswer = this.handleUserAnswer(secondRequest.result);
						// console.log(userAnswer)
						if (this.parent.subType === "oralSentence") {
							this.conversation.push(
								{
									role: "user",
									content: this.defaultQuestion[this.questionIndex].name,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else if (this.parent.subType === "oralParagraph") {
							this.conversation.push(
								{
									role: "user",
									content: this.defaultQuestion[this.questionIndex].name,
									isParagraph: true,
									recognition: firstRequest.result.NBest[0].Words,
									audioPath: filePath
								}
							)
						} else {
							this.conversation.push({ role: "user", content: userAnswer, audioPath: filePath })
						}
						this.promptLog.push({ role: "user", content: userAnswer })

						// console.log(firstRequest.result)
						this.parent.foulLanguageCheck(firstRequest.result.NBest[0]);
						this.eachQuestionScore[this.questionIndex] = {
							PronScore: Math.round(firstRequest.result.NBest[0].PronScore),
							AccuracyScore: Math.round(firstRequest.result.NBest[0].AccuracyScore),
							CompletenessScore: Math.round(firstRequest.result.NBest[0].CompletenessScore),
							FluencyScore: Math.round(firstRequest.result.NBest[0].FluencyScore)
						};

						this.scrollToBottom();
						if (this.parent.subType === "oralVocab" || this.parent.subType === "oralFillIn" || this.parent.subType === "oralMc") {
							// console.log(secondRequest.result)
							this.checkPronunciation(secondRequest.result);
						} else if (this.parent.subType === "oralSentence" || this.parent.subType === "oralParagraph") {
							// console.log(firstRequest.result)
							this.checkPronunciation(firstRequest.result);
						}
					}
				})
			} else {
				const secondRequest = await this.dataService.post2({ data: dataWithoutAnswer, loading: false });
				Promise.all([secondRequest]).then(([secondRequest]) => {
					if (!secondRequest.result || secondRequest.result.RecognitionStatus === "InitialSilenceTimeout") {
						this.alert.alert2(this.translateText(
							"偵測不到聲音，請重新再試。",
							"偵測不到聲音，請重新再試。",
							"Cannot detect the sound recording, please try again"
						), null, { btns: [["ok"]] });
						this.recordEnable = true;
						clearInterval(recordTimeCount);
					} else {
						clearInterval(recordTimeCount);
						this.isResReceived = true;

						// console.log(secondRequest.result)
						this.parent.foulLanguageCheck(secondRequest.result.NBest[0]);
						this.promptLog.push({ role: "user", content: secondRequest.result.DisplayText });
						this.conversation.push({ role: "user", content: secondRequest.result.DisplayText, audioPath: filePath });

						this.scrollToBottom();
						if (!this.userGreetingPass) this.checkUserGreeting(secondRequest.result.DisplayText);
						else if (!this.userFirstEDBPass) this.checkUserFirstEDB(secondRequest.result.DisplayText);
						// else if (!this.userSecondEDBPass) this.checkUserSecondEDB(secondRequest.result.DisplayText);
					}
				})
			}
		} catch (err) {
			console.error("Assessment Fail", err);
		}
	}

	/////////////////////////////////////////
	//           GPT chat function         //
	/////////////////////////////////////////
	async sentPromptApi(prompt: any[]) {
		let res = await this.chat.oralChat(prompt, "EP_AI_ENG_CHAT");
		if (JSON.parse(res)['error']) {
			this.alert.alert2(this.translateText(
				"偵測到不適合用詞，請重新再試。",
				"侦测到不适合用词，请重新再试。",
				"Inappropriate word detected, please try again."
			), null, { btns: [["ok"]] });
			this.recordEnable = true;
			return `{"choices":[{"message":{"content":"Inappropriate word detected, please try again.","error":true}}]}`
		}
		return res
	}

	// async intro() {
	// 	let promptA = {
	// 		role: "system",
	// 		content: `You are a teacher, you are having an oral training with a student of primary 6 student. From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`
	// 	}

	// 	let promptB = {
	// 		role: "system",
	// 		content: `The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): Foul: Please be polite and speak in a positive way. Yes: Could you explain more please, so I can better understand? No: Are you sure? I promise it will be fun! I don't know: I'm sorry to hear that. What do you need help on? idk: I'm sorry to hear that. What do you need help on? What?: Would you like me to repeat that? I don't mind. Um, like, you know: You've got a point! Just try to say it more directly without the fillers. Inappropriate words: Thank you for sharing! But that language isn't suitable for the discussion. Can you try again? Overly long response: That's a rich answer! It could be even better with some trimming.`
	// 	}
	// 	this.promptLog.push(promptA);
	// 	this.promptLog.push(promptB);

	// 	try {
	// 		const res = await this.sentPromptApi(this.promptLog);
	// 		let content = JSON.parse(res).choices[0].message.content;
	// 		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

	// 		if (res) {
	// 			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
	// 			if (audioRes) setTimeout(() => this.greeting(), audioRes.duration * 1000);
	// 		}
	// 	} catch (error) {
	// 		console.log(error);
	// 	}

	// }

	async greeting() {
		if (this.parent.subType !== "oralAI") {
			let background = { role: "system", content: "" };
			if (this.studentLevel === "p4") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of primary 4 student. The topic is "Food and Drink: Favorite food and drink". From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
			} else if (this.studentLevel === "p5") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of primary 5 student. The topic is "We Love Hong Kong: Traveling around". From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
			} else if (this.studentLevel === "s1") {
				background.content = `Your name is Chat Bot. You are a teacher, you are having an oral training with a student of secondary 1 student. The topic is "Study, School Life, and Work: Study problems". From now on, all the sentences that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
			}
			let scenario = {
				role: "system",
				content: `If the student answer you in a good response, please smoothly start the topic. The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.`
			};
			this.promptLog.push(background);
			this.promptLog.push(scenario);
			let show_datetime = false;
			if(Math.random() < 0.3) {
				show_datetime = true;
			}

			this.promptLog.push({
				role: "system",
				content: `
					Please greet the student and include his/her name ${this.userName} without starting the topic.
					${show_datetime ? `Current Date Time: ${moment().format('YYYY-MM-DD HH:mm A')}
					You can make various greetings according to the time.` : ''}
					Do not use emoji. Be more creative on the greeting. 
					Let the student respond to your greeting.`
			});
		}

		if (this.parent.subType === "oralAI") {
			if (this.studentLevel === "p4") {
				this.promptLog.push({
					role: "system",
					content: `Assistant is user's best friend John, Assistant has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. Assistant misses the local food in Hong Kong and wants user to take him to user's favorite restaurant. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "p5") {
				this.promptLog.push({
					role: "system",
					content: `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Assistant is going to ask user, is there any disadvantages of this suggestion. Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			} else if (this.studentLevel === "s1") {
				this.promptLog.push({
					role: "system",
					content: `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Assistant is going to ask user, which way is more effective and how do the user like to study? Answer and ask question in Less than 20 words for each response. If user's input is unrelated to the topic, gently guide user back to the topic.`
				});
			}
		}
		try {
			let content = "";
			if (this.parent.subType === "oralAI") {
				if (this.studentLevel === "p4") {
					content = `Hi ${this.userName}, It's so great to be back in Hong Kong! I've really missed the food here. What restaurant do you recommend we go to?`
				} else if (this.studentLevel === "p5") {
					content = `To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Given this, what do you think are the disadvantages of this suggestion?`;
				} else if (this.studentLevel === "s1") {
					content = `Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Which do you think is more effective? How do you like to study?`;
				}

				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })
				await audioRes.is_finish;
				this.recordEnable = true;
			} else {
				const res = await this.sentPromptApi(this.promptLog);
				content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserGreeting(content: string) {
		const prompt = {
			role: "system",
			content: `
				The student response is "${content}".
				Please determine whether the student's response correctly or not. 

				If the student gave you a correct greet, please reply <correctGreet>. 
				If the student gave you a wrong greet and said something inappropriate or irrelevant or you cannot understand or not even a sentence, reply <wrongGreet>. 
				If the student did not greet and say something that need caring, please reply <correctGreet>.

				Please give your reason and then reply <correctGreet>, <wrongGreet>.`,
		}
		this.promptLog.push(prompt);
		try {
			const res = await this.sentPromptApi(this.promptLog);

			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) {
				return;
			}

			const content = res_obj.choices[0].message.content;

			// console.log(content);
			// debugger;

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

			let result = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			let resPrompt = { role: "system", content: "", };
			if (result.includes("caring")) {
				
			} else if (result.includes("wrongGreet")) {
				if (this.userGreetingCounter === this.userGreetingLimit) {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. Also tell the student that he/she can not talk more like this and we must start our discussion topic now. (important) Do not use interrogative sentence or ask any question.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.userGreetingPass = true;
								this.firstEDBQuestion();
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					resPrompt.content = `Determine the 'student scenarios' of student greet: ${content}. According to the scenario to respond but do not use the same words in the scenario list. If you don't understand what the student is talking about, please ask the student to explain, otherwise try to encourage the student to greet again. Do not start the topic.`
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						const content = JSON.parse(res).choices[0].message.content;
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
							if (audioRes) setTimeout(() => {
								this.recordEnable = true;
								this.userGreetingCounter++;
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			} else if (result.includes("correctGreet")) {
				resPrompt.content = `Please respond to the student's greeting without questions. Also, answer their question if you can. If the student needs attention, use a sentence or two to comfort him or her. Finally, describe the topic in one sentence without asking the student any questions. No question allowed. Please respond to the student smoothly. Please maintain coherence and fluency in the overall content.`
				this.promptLog.push(resPrompt);

				try {
					const res = await this.sentPromptApi(this.promptLog);
					const content = JSON.parse(res).choices[0].message.content;
					this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) })

					if (res) {
						let audioRes = await this.playAudio(content, `${this.replaceSymbol(content)} 🙂`, false);
						if (audioRes) setTimeout(() => {
							this.userGreetingPass = true;
							this.firstEDBQuestion();
						}, audioRes.duration * 1000);
					}
				} catch (error) {
					console.log(error);
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async firstEDBQuestion() {
		let curriculum = ``;
		let training_material = ``;
		if (this.studentLevel === 'p4') {
			curriculum = `
				1. use appropriate register when speaking to familiar interlocutors such as teachers and peers (e.g. May I go to the toilet?)
				2. apply grammar rules such as subject-verb agreement correctly (e.g. Peter plays football every Sunday.)
				3. connect ideas by using cohesive devices (e.g. also, at last, before)
				4. use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings
				5. open an interaction by greeting someone in an appropriate manner
				6. open an interaction by introducing oneself giving some details
				7. open an interaction by eliciting a response by asking questions or providing information on a topic (e.g. I've borrowed three very interesting books. Would you like to have a look?)
				8. maintain an interaction by controlling participation in an interaction or group activities, e.g. taking one's turn at the right moment
				9. maintain an interaction by recognising others' desire to speak (e.g. It's my turn . . . It's your turn now.)
				10. maintain an interaction by asking and responding to others' opinions (e.g. Do you like that book? What do you think of (name of a character in the book)?)
				11. maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate
				12. maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood
				13. maintain an interaction by predicting the likely development of a conversation and responding accordingly
				14. close an interaction by using appropriate formulaic expressions (e.g. See you tomorrow.)
				15. close an interaction by giving reasons (e.g. Sorry, I have to see my teacher now.)`;
			training_material = `
				Introducing their own favourite restaurants: Students can introduce their favourite restaurants with the AI while given hints on various aspects they are expected to share, such as their favourite menu, the restaurant's location, the atmosphere of the restaurant, etc. They can be provided with interesting opportunities to talk about their familiar restaurants and elaborate on their everyday observations, strengthening their ability to express their feelings and describe a certain object, hence developing their language skills.
				Grammar:
				-Use “would” and “could” to politely express offers, requests, and ask for permission
				-Present tense + future tense because the scene is taking place while speaking, rather than describing a past restaurant experience
				Vocabulary Exercise (Easy)
				Restaurant Waiter Breakfast Lunch Dinner
				Cup Bottle Glass Plate Taste
				Sweet Spicy Juice Coffee Egg
				Can you name 5 more things you can see in a restaurant?
				Vocabulary Exercise (Difficult)
				Chef a professional cook in a restaurant
				Organic produced without the use of chemicals
				Menu the list of food available
				Condiment a substance that is used to add flavour to food
				Brunch a late morning meal between breakfast and lunch
				Omelette a dish made by mixing eggs together and frying them, often with small pieces of other food such as cheese or vegetables
				Stir-fry to fry small pieces of meat, vegetables, etc. quickly while mixing them around
				Order to ask for something to be made, supplied, or delivered, especially in a restaurant or shop
				Savour to enjoy food slowly, in order to enjoy it as much as possible
				Feast a special meal with very good food or a large meal for many people
				Serve to provide food or drinks; to work for
				Etiquette the set of rules or customs that tells you what you should do in a particular situation, e.g. at the dinner table
				Tender (of meat or vegetables) easy to cut or chew
				Crispy very crunchy in a pleasant way, like potato chips
				Buffet a meal where people serve themselves different types of food
				Short Phrases Exercise
				This restaurant is always open and serves breakfast, lunch and dinner.
				Waiter, may I have some scrambled eggs, a cup of coffee, and two glasses of orange juice?
				This special curry dish tastes both sweet and spicy.
				Careful, sir. The plate is hot.
				There are 2 bottles.
				There is an extra charge if you wish to bring your own bottle of wine.
				Short Phrases Exercise (Difficult)
				I would like to order some crispy chicken to go along with my stir-fried.
				The brunch menu says the omelette is their specialty.
				This buffet is a feast, and I intend to savour every bite.
				My compliments to the chef, for preparing such a tender steak.
				It is typical restaurant etiquette to be seated before ordering.
				Single Word Answer Exercise (Easy)
				My wife and I would like a table for (讀答案) please. Two
				May I have a () of water, please? Glass
				Careful, sir. The () is hot. Plate
				We just stopped () breakfast, sir. Serving
				Enjoy your (), sir. Meal
				Single Word Answer Exercise (Difficult)
				I'd like some deep-fried (停一陣) chicken. Crispy
				All our foods are sourced locally, completely () without chemicals. Organic
				We do not serve () to minors. Alcohol
				Ketchup, mustard, and mayonnaise are different kinds of (). Condiments
				It's bad (____) to chew with your mouth open. Etiquette
				Short Phrase Answer Exercise (Easy) (
				Good morning. Would you like some water? (聽題目)
				A: I don't drink water. (不用聽)
				B: Yes, I would like some water.*
				Are you ready to order?
				A: Not yet. Could you give me a few more minutes?*
				B: I don't want to order.
				How is your meal?
				A: It is yum yum, so no thank you.
				B: It's delicious, thank you for asking.*
				Is there anything else I can get you?
				A: No, thank you.*
				B: No, I want more to drink.
				Would you like the bill?
				A: Yes, please. I would like to pay by credit card.*
				B: Yes, but I don't want to pay.
				Short Phrase Answer Exercise (Difficult)
				I'm sorry sir, but we are fully booked.
				A: I don't care. I demand to be seated!
				B: We didn't have time to make a reservation. Can you help us out?
				C: That's alright. How long do I need to wait, then?*
				D: What kind of terrible service is this?
				We don't start serving lunch until noon.
				A: I see. I'll have some breakfast instead, then.*
				B: Can't you make an exception for me?
				C: Let me speak to your manager.
				D: Give me lunch, or give me death!
				Excuse me, sir, but your credit card has been declined.
				A: That's your problem, not mine.
				B: I will pay by cash, then.*
				C: There must be something wrong with your machine.
				D: Run! We need to run now!
				AI Conversation Exercise (Easy)
				The AI is the cashier at a fast food restaurant, and the student has to order a meal. The menu would be provided on the screen, and students can choose what meal to order, as long as they successfully make their order.
				AI Conversation Exercise (Difficult)
				John, your best friend has been living in Germany for five years and will be returning to Hong Kong during the Easter holidays. He misses the local food in Hong Kong and wants you to take him to your favorite restaurant. Which restaurant would you recommend? What aspects particularly appeal to you?
				Speaking Exercise
				Read the following Passage
				Tonight Mum taught me how to make pumpkin soup.
				First, I heated the butter on a saucepan over medium heat. When it melted, Mum added some chopped onion and cooked them for two minutes. Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat. I did what happy Mum asked me to do and she prepared a small bowl for me.
				I stirred the flour, ginger, corn and some salt together in the bowl. Mum mixed some milk and an egg together, then added the mixture to the soup. We cooked the soup over a low heat for 10 minutes until it became hot.
				We also made some garlic bread, peach salad and vegetable spaghetti. We had a tasty meal tonight!
				Mum and Dad clapped their hands and called me 'Cherry the Best Cook !' I am so happy!`
		} else if (this.studentLevel === 'p5') {
			curriculum = `
				1. use appropriate register when speaking to familiar interlocutors such as teachers and peers (e.g. May I go to the toilet?)
				2. apply grammar rules such as subject-verb agreement correctly (e.g. Peter plays football every Sunday.)
				3. connect ideas by using cohesive devices (e.g. also, at last, before)
				4. use appropriate intonation and stress, and vary volume, tone of voice and speed to convey intended meanings and feelings
				5. open an interaction by greeting someone in an appropriate manner
				6. open an interaction by introducing oneself giving some details
				7. open an interaction by eliciting a response by asking questions or providing information on a topic (e.g. I've borrowed three very interesting books. Would you like to have a look?)
				8. maintain an interaction by controlling participation in an interaction or group activities, e.g. taking one's turn at the right moment
				9. maintain an interaction by recognising others' desire to speak (e.g. It's my turn . . . It's your turn now.)
				10. maintain an interaction by asking and responding to others' opinions (e.g. Do you like that book? What do you think of (name of a character in the book)?)
				11. maintain an interaction by acknowledging, agreeing or disagreeing, asking questions, replying, adding or giving examples and explaining, using formulaic expressions where appropriate
				12. maintain an interaction by self-correcting or rephrasing questions and answers if they are not understood
				13. maintain an interaction by predicting the likely development of a conversation and responding accordingly
				14. close an interaction by using appropriate formulaic expressions (e.g. See you tomorrow.)
				15. close an interaction by giving reasons (e.g. Sorry, I have to see my teacher now.)`;
			training_material = `
				Discovering special places in Hong Kong: There are many special and hidden places that we have never gone to, or even heard of. Students in P4 can be provided with more information about different places that are rarely visited, such as Shek Kwu Chau, Jade Market Hong Kong, etc. hence knowing more about the place they live in.
				Grammar:
				- Ability to use "directional words" and correctly identify and describe attractions or locations
				- Present tense and future tense: the questions answered should be based on facts (to give directions to pedestrians) and need to plan future trips and place orders
				Vocabulary exercise (easy)
				Market Street Island Tour Mountain
				Hill Walk Map Journey Trip
				Ferry Attractions Tourist Crowded Countryside
				Can you name 5 methods of travel in Hong Kong?
				Vocabulary exercise (difficult)
				Reservoir a place for storing liquid, especially a natural or artificial lake providing water for a city or other area
				Shed a small building, usually made of wood, used for storing things
				Promenade a path for walking on, especially one built next to the sea
				Avenue a wide road with trees or tall buildings on both sides or a wide country path or road with trees on both sides
				Peninsula a long piece of land that sticks out from a larger area of land into the sea or into a lake
				Amble to walk in a slow and relaxed way
				Stroll to walk in a slow, relaxed manner, especially for pleasure
				Itinerary a detailed plan or route of a journey
				Destination the place where someone is going
				Budget the amount of money you are available to spend
				Landmark a building or place that is easily recognized, especially one that you can use to judge where you are
				Barren a place that is unable to produce plants or fruit
				Urban relating to a city or town
				Remote far away in distance or time, or not closely related
				Vacation a time when someone is free to do what they want, such as travel or relax
				Short phrase exercise (easy)
				The market is at the end of the street.
				Let's take a walk around the countryside.
				I hope the attractions aren't too crowded.
				The island can only be reached by ferry.
				I can't find the main street on the map.
				Short phrase exercise (difficult)
				The peninsula is very remote and difficult to reach.
				I managed to plan an itinerary for our vacation according to our budget.
				Taking a stroll on the promenade clears my head.
				The shed over there is an urban landmark.
				We have finally arrived at our destination.
				Single word answer (easy)
				The only way to get to Cheung Chau is by (_____). ferry
				It's not the destination so much as the (_______). journey
				Causeway Bay is in Hong Kong (______). Island
				We can buy traditional clothes in Stanley (_____). Market
				The International Commerce (_____) is the tallest building in Hong Kong. Centre
				Single word answer (difficult)
				The area over there is a (_______) wasteland. barren
				People get their arms stuck in Cheung Po Tsai (_____) Cave. Pirate
				You can find delicious cuisine in Chungking (______). Mansion
				The Victoria (______) is a unique scenery of Hong Kong. Harbour
				I have never actually visited the Plover Cove (______). Reservoir
				Short Phrase answer exercise (easy)
				Should we take the bus or the MTR?
				A: It doesn't matter.
				B: Let's take the bus.*
				The Tai Tong EcoPark is beautiful.
				A: Yes, the trees are terrible.
				B: I agree. The view is stunning.*
				Are you tired yet?
				A: Yes, this trail is too steep.*
				B: Yes, let's walk faster.
				Should we take some photos?
				A: Yes, please. Did you bring your camera?*
				B: No! It's dumb to take photos.
				Can you see the sunset?
				A: Yes, it's breathtaking.*
				B: No, I don't really care about it.
				Short Phrase answer exercise (difficult)
				Excuse me, can you show me the way to the promenade?
				A: Ask another person.
				B: Yes, sure. Just turn left and walk across the park, and you will see it.*
				C: I'm busy, just go away.
				D: The Big Buddha is very near.
				What can we buy at the Jade Market?
				A: We can buy accessories there.*
				B: I don't know.
				C: Do your own research, I'm not going to answer your question.
				D: We can buy some Marvel movie tapes.
				Sorry, you are not allowed to enter this building unless you have a permit.
				A: No way! I've planned this trip for so long.
				B: Okay, bye-bye.
				C: I forgot about this. Can you teach me how?*
				D: Are you kidding me?
				Please do not touch any exhibits with your hand.
				A: I paid for the ticket! I have the right!
				B: Those exhibits are disgusting. Ew!
				C: Ok, Thank you for the reminder.*
				D: You are welcome.
				There are a lot of interactive devices here in Tai Kwun.
				A: Ok. The devices are shut down.
				B: Wow! That is impressive. Should we give them a try?*
				C: Tai Kwun is too far. I'm lazy.
				D: Yes. Tai Kwun used to be a police station.
				AI Conversion exercise (easy)
				The AI is a pedestrian who lost their way. A map of a small area near Tsim Sha Tsui Promenade is provided. The AI will ask for directions to a random place. Students should be able to guide them from their spot to their destination.
				AI Conversion exercise (difficult)
				Speaking of Hong Kong's famous attractions, people always think of the attractions in the city center, such as Victoria Peak and the Avenue of Stars. To keep tourism blooming, some suggest promoting rural areas to showcase Hong Kong's cultural convergence, such as the historic Hakka village Lai Chi Wo. Given this, what do you think are the disadvantages of this suggestion?
				Speaking Exercise 
				Read the following passage
				Whenever I'm on holiday, I want to see the sights.
				So I find the mode of transport, to take me where I like.
				Sometimes it's an aeroplane, if I'm going far.
				But if it's a short distance, I may just take a car.
				For the times I'm in New York, I'll take a yellow cab.
				Or if I go to Hong Kong, It's the MTR I'll grab.
				In the Alps in Switzerland, it snows, so I can ski.
				But if I'm going hiking, My feet are good enough for me!`;
		} else if (this.studentLevel === 's1') {
			curriculum = `
				1. convey ideas and information in conversations or discussions
				2. use words and expressions appropriate to the context
				3. use appropriate discourse markers
				4. use correct pronunciation, intonation and register for different purposes
				5. seek and give clarification, explain what information one requires and why, rephrase one's questions when necessary, sum up points made and redirect the discussion when the need arises
				6. make a balanced contribution without either dominating the discussion or being too reticent
				7. express, elicit and respond to ideas, opinions and feelings in a group discussion`;
			training_material = `
				Study, School Life, and Work: Study problems
				This topic helps students better navigate academic challenges and develop problem-solving skills. It also encourages students to discuss study obstacles and solutions, as well as explore peer pressure and seek help from senior figures. It would also enhance rational thinking by analyzing problems critically and developing effective solutions.
				Grammar: Use of SVO
				Vocabulary Exercise (Easy)
				Examinations Homework Assignment Sponsorship Stressful
				Anxiety Peer Grades Tuition Failure
				Expectation Competition Support Exercises Break
				Name 5 types of school work you encountered.
				Vocabulary Exercise (Difficult)
				Productivity The rate at which a person, company, or country does useful work
				Motivation Enthusiasm or willingness to do something
				Distraction Something that prevents someone from giving their attention to something else
				Procrastination The act of delaying something that must be done
				Time-management The practice of using the available time you have in a useful and effective way
				Schedule A plan that gives events or activities and the times that they will happen or be done
				Counselor Someone who is trained to listen to people and give them advice about their problems
				Communication The act of talking about your thoughts and feelings to help people to understand them
				Meditation The act of giving your attention to only one thing, either as a religious activity or as a way of becoming calm and relaxed
				Guidance Help and advice about how to do something or to deal with problems
				Concentration The act of focusing all your attention on a particular object or activity
				Relaxation A pleasant activity that makes you calm and less worried
				Burnout Extreme tiredness or mental or physical illness caused by working too hard or trying to do too much
				Reward Something that is given in return for good service, effort, or achievement
				Visualization The process of forming a picture of something or someone in your mind
				Short Phrases (Easy)
				Pay attention in class.
				I enjoy English lessons.
				I am nervous about the exams.
				We couldn't agree on a topic for our group project.
				My dog ate my homework.
				Short Phrases (Difficult)
				My teachers will regard me as a failure if I don't do well on the test.
				I have scheduled an appointment for you with the school counselor.
				Stop procrastinating and get on with your work!
				You're distracting your sister from her studies.
				Sometimes, I relax by visualizing what I'd like to be in the future.
				Single Word Answer Exercises (Easy)
				We should not give in to _____ pressure. peer
				The teacher gave us too much _________. homework
				You should ______ your friends when they are down. support
				I need to get more _______ so I can get stronger. exercises
				Having good ______ isn't everything. grades
				Single Word Answer Exercises (Difficult)
				Some students need the proper ________ to succeed. motivation
				A _______ calms your mind and focuses your senses. meditation
				Don't work too hard, or you'll suffer from ______. burnout
				The problem we have is a lack of proper _________. communication
				I would like to claim the _______ for finding your lost wallet. Reward
				Short Phrase Answer Exercises (Easy)
				We've been studying for an hour. Would you like a rest?
				A: Yes, a short break will do me wonders.*
				B: No, I can keep going until my eyes bleed!
				Can you afford this university? It has a high tuition fee.
				A: I think so after I apply for sponsorship.*
				B: Don't worry, we're gonna be rich after I hit the casino.
				How should I deal with my anxiety levels?
				A: Just ignore it, it will go away.
				B: Try taking deep breaths and talking with someone.*
				It's Friday. Should I do my homework now or later?
				A: Do it now, so you can enjoy your weekend.*
				B: It's Friday, enjoy yourself! Your homework will still be there tomorrow.
				I had good grades last time. Does that mean I don't need to study?
				A: Why study when you can play video games instead?
				B: You need to study in order to keep up your grades.*
				Short Phrases Answers (Difficult)
				How could I communicate better as a group leader?
				A: Just keep quiet and leave everyone else alone.
				B: Be as loud as possible and shout if you want.
				C: Make sure everyone is heard and let them feel important.*
				D: Who wants to be a leader? Step down!
				I'm looking for a guidance counselor. Do you know one?
				A: Her office is back there at the end of the hallway.*
				B: I can be your guidance counselor if you want.
				C: You don't need one. Just keep being you!
				D: Do you need guidance? What a lost little lamb.
				I think I'm suffering from burnout. I feel mentally exhausted.
				A: Be quiet or I will literally burn you.
				B: You need a vacation to clear your mind and relax.*
				C: I don't care. Do whatever you want.
				D: Push through it, and you won't feel exhausted anymore.
				We need to communicate better. I don't understand what you mean half the time!
				A: I don't understand what you mean either!
				B: That just means we understand each other fine the other half.
				C: You're right. We need to be clear and ask questions if we're not sure.*
				D: We're better off not communicating at all.
				How do I find my motivation in life?
				A: You don't. Just give up.
				B: Just wander through life aimlessly. No one cares.
				C: Copy what other people do, and you'll be fine.
				D: Take your time, explore your interests, and don't give up.*
				Conversation
				Some students study by memorizing as much as possible, while others prefer studying by understanding basic principles and building from there. Which do you think is more effective? How do you like to study?
				Speaking Exercise
				Read the following passage
				Classroom Rules for 1A
				1. Be polite, courteous and respectful at all times. Treat others the way you want to be treated.
				2. If you have something to share with the class or a question to ask during the lesson, raise your hand. Wait until the teacher calls on you.
				3. Respect other students' opinions. Listen quietly when others are speaking. You will have your turn to speak, too.
				4. Come to class on time and be prepared for each lesson. Have what you need for each lesson on your desk before the teacher comes in.
				5. Complete all homework assignments neatly and on time.
				6. Respect each other's property. Do not touch what is not yours.
				7. Help keep our classroom clean and tidy. Put away things after you use them and throw rubbish into the bin.
				8. Do your best and have fun!`;
		}

		let background = {
			role: "system",
			content: `This is the English Speaking skills curriculum from the Education Bureau in Hong Kong (Known as the curriculum) please remember it. Present information, ideas and feelings clearly and coherently: ${curriculum}`
		};
		let material = {
			role: "system",
			content: `This is the training material. ${training_material}`
		};
		let prompt = {
			role: "system",
			content: `Generate 1 question base on the curriculum point number you chose and the topic. Please refer to the training material, be creative. Put the curriculum point number inside <> and then put your question after that.`
		};
		this.promptLog.push(background);
		this.promptLog.push(material);
		
		if (this.objectiveUsed.length > 0) {
			let limitation = {
				role: "system",
				content: `Choose a curriculum point number according to the training material. Do not choose the curriculum point number which is included in ${this.objectiveUsed}. Try to use different English speaking skills curriculum.`
			};
			this.promptLog.push(limitation);
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);
			const content = JSON.parse(res).choices[0].message.content;
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
			this.objectiveUsed.push(EDBNumber)
			console.log(EDBNumber, this.objectiveUsed)
			let question = content.replace(/<.*?>\s*/g, '');

			if (res) {
				let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
				if (audioRes) setTimeout(() => this.recordEnable = true, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error);
		}
	}

	async checkUserFirstEDB(reply: string) {
		const prompt = {
			role: "system",
			content: `Rate students performance within 10 to 100 according to the point your selected, at least 10 marks should be given and only give the mark.`,
		}
		this.promptLog.push(prompt);

		try {
			const res = await this.sentPromptApi(this.promptLog);

			const content = JSON.parse(res).choices[0].message.content;
			let error = JSON.parse(res).choices[0].message.error
			if (error) {
				this.promptLog.pop()
				return
			}

			this.EDBScore[this.objectiveUsed[this.objectiveUsed.length - 1] - 1] = parseInt(`${content}`);
			console.log(this.EDBScore)

			let resPrompt = { role: "system", content: "", };
			if (res) {
				if (this.userEDBCounter >= this.userEDBLimit + this.userEDB_retry) {
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, you must add *{retry} in your reply and encourage student to retry.
							Your reply should related to the last question you asked. No question allowed.
							Do not give any advice here. For example: "Many prefer chicken nuggets instead" should be avoided.
						`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can. 
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) Do not ask student to retry and end this section. 
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, tell him tactfully that we need to move forward to another section.
						`;
					}
					// resPrompt.content = `Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario and end this section but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave. (important) Do not use interrogative sentence or ask any question. All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.`;
					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						if (content.includes("*{retry}")) {
							content = content.replace("*{retry}", "");
							this.userEDB_retry++
							need_retry = true
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == true) {
									resPrompt = {
										role: "system",
										content: `Try to rephrase previous question you asked and make it instructive. For example:"What is your favourite food? How do you think of fried chicken and burgers?". Put the curriculum point number inside <> and then put your question after that.`
									}
								}
								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								console.log(EDBNumber, this.objectiveUsed)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						} else {
							this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
							if (res) {
								let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
								if (audioRes) setTimeout(() => {
									this.userFirstEDBPass = true;
									this.defaultQuestionSession();
								}, audioRes.duration * 1000);
							}
						}
					} catch (error) {
						console.log(error);
					}
				} else {
					let allow_retry = this.userEDB_retry < this.userEDB_retry_limit
					let need_retry = false
					if (allow_retry) {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, you must add *{retry} in your reply and encourage student to retry.
							Do not give any advice here. For example: "Many prefer chicken nuggets instead" should be avoided.`;
					} else {
						resPrompt.content = `
							Answer their question with humor if you can.
							Determine the 'student scenarios' of student answer: ${reply} based on the list of inappropriate responses. Give response according to the scenario but do not use the same words in the scenario list. Do not mark the curriculum point number and No need to include mark you gave.
							(important) Do not use interrogative sentence or ask any question. For example: "How about ice-cream?" should be avoided.
							All the response that you generate must be within 10 words and each word within 3 syllables and 6 characters.
							(important) Do not ask student to retry.
							(important) If the student answer is out of topic or not making sense or not appropriate or not answering your question, tell him tactfully that we need to move forward to another question.`;
					}

					this.promptLog.push(resPrompt);

					try {
						const res = await this.sentPromptApi(this.promptLog);
						let content = JSON.parse(res).choices[0].message.content;

						if (content.includes("*{retry}")) {
							content = content.replace("*{retry}", "");
							this.userEDB_retry++
							need_retry = true
						}
						this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

						if (res) {
							let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
							if (audioRes) setTimeout(async () => {
								if (need_retry == false) {
									resPrompt = {
										role: "system",
										content: `Generate 1 question base on the English speaking skills curriculum. Please refer to the training material, be creative. Do not use the same question in the training material. Put the curriculum point number inside <> and then put your question after that.`
									}
									// Share your idea on that question.
									// this.userEDB_retry_limit++
								} else {
									resPrompt = {
										role: "system",
										content: `Try to rephrase previous question you asked and make it instructive. For example:"What is your favourite food? How do you think of fried chicken and burgers?". Put the curriculum point number inside <> and then put your question after that.`
									}
								}

								this.promptLog.push(resPrompt);
								const res = await this.sentPromptApi(this.promptLog);
								const content = JSON.parse(res).choices[0].message.content;
								this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

								let EDBNumber = content.substring(content.indexOf('<') + 1, content.indexOf('>'));
								this.objectiveUsed.push(EDBNumber)
								console.log(EDBNumber, this.objectiveUsed)
								let question = content.replace(/<.*?>\s*/g, '');

								if (res) {
									let audioRes = await this.playAudio(question, this.replaceSymbol(question), false, EDBNumber);
									if (audioRes) setTimeout(() => {
										this.recordEnable = true;
										this.userEDBCounter++;
									}, audioRes.duration * 1000);
								}
							}, audioRes.duration * 1000);
						}
					} catch (error) {
						console.log(error);
					}
				}
			}
		} catch (error) {
			console.log(error);
		}
	}

	async defaultQuestionSession() {
		this.isAnswerDefaultQuestion = true;
		try {
			if (this.questionIndex < 1) {
				const prompt = {
					role: "system",
					content: `Your approach is warm and encouraging, making students feel comfortable and supported. Your task is to inform ${this.userName} that you are going to start the exercise about today's topic. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 15 words.`
				};
				this.promptLog.slice(0, 1);
				this.promptLog.push(prompt);

				const res = await this.sentPromptApi(this.promptLog);
				const content = JSON.parse(res).choices[0].message.content;
				this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

				if (res) {
					let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					if (audioRes) setTimeout(() => this.askDefaultQuestion(), audioRes.duration * 1000);
				}
			} else {
				this.askDefaultQuestion();
			}
		} catch (error) {
			console.log(error)
		}
	}

	async askDefaultQuestion() {
		let intro = "";
		let question = this.defaultQuestion[this.questionIndex].name;
		let reading = this.defaultQuestion[this.questionIndex].reading;
		let audioContent;

		if (this.parent.subType === "oralFillIn") intro = `Please answer the missing part of the phrase:`
		else if (this.parent.subType === "oralMc") intro = `Which response would you choose, if someone says:`
		else if (this.parent.subType === "oralParagraph") intro = `Please read this:`
		else if (this.parent.subType === "oralSentence") intro = `Please read the phrase:`
		else if (this.parent.subType === "oralVocab") intro = `Please read the vocab:`
		this.promptLog.push({ role: "assistant", content: this.replaceSymbol(intro) });

		if (this.parent.subType === "oralFillIn") {
			const split = question.split("(");
			let firstPartReadingText = split[0].trim();
			let secondPartReadingText = split[1].split(")")[1].trim();
			let fillInTypeTopic = `${intro} ${firstPartReadingText} ${''} ${secondPartReadingText}`;

			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${reading}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: fillInTypeTopic, question: `${intro} ${question}` };
			}
		} else if (this.parent.subType === "oralMc") {
			audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
		} else {
			if (this.parent.difficulty !== "hard") {
				audioContent = { intro: `${intro} ${question}`, question: `${intro} ${question}` };
			} else {
				audioContent = { intro: intro, question: `${intro} ${question}` };
			}
		}

		let audioRes = await this.playAudio(audioContent.intro, audioContent.question, true);
		if (audioRes) setTimeout(async () => {
			this.parent.totalPageNumber = this.defaultQuestion.length;
			this.parent.pageIndex = this.questionNumber;
			if (this.parent.subType === "oralMc") {
				if (this.parent.difficulty !== "hard") {

					for (let content of this.get_display_choices(this.defaultQuestion[this.questionIndex].choice)) {
						let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
						await audioRes.is_finish;
					}

					this.recordEnable = true;

					// let content = `A:${this.defaultQuestion[this.questionIndex].choice[0]}`
					// let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					// if (audioRes) setTimeout(async () => {
					// 	let content = `B:${this.defaultQuestion[this.questionIndex].choice[1]}`
					// 	let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
					//
					// 	if (audioRes) setTimeout(async () => {
					// 		this.recordEnable = true;
					// 	}, audioRes.duration * 1000)
					// }, audioRes.duration * 1000)
				} else {
					for (let content of this.get_display_choices(this.defaultQuestion[this.questionIndex].choice)) {
						this.conversation.push({role: "assistant", content: content});
					}
					// let contentA = `A:${this.defaultQuestion[this.questionIndex].choice[0]}`
					// this.conversation.push({ role: "assistant", content: contentA });
					// let contentB = `B:${this.defaultQuestion[this.questionIndex].choice[1]}`
					// this.conversation.push({ role: "assistant", content: contentB });

					this.recordEnable = true;
					this.scrollToBottom();
				}
			} else {
				this.recordEnable = true;
			}
		}, audioRes.duration * 1000);
	}

	async checkPronunciation(content: any) {
		let d_prompt = { role: "system", content: "", };
		let correct = false
		if (content.DisplayText.replace(".", "") == this.defaultQuestion[this.questionIndex].name || content.DisplayText == this.defaultQuestion[this.questionIndex].name) {
			correct = true
		} else {
			d_prompt.content = `
				(important) reply "<correct>" if the student's answer is correct else reply "<wrong>".
				Correct answer does not have to be exactly the same as question answer.
				reply "<correct>" or "<wrong>" only. Do not rely anything else.
				The question answer is "${this.defaultQuestion[this.questionIndex].name}"
				The student's answer is "${content.DisplayText}"
			`
			this.promptLog.push(d_prompt)
			const d_res = await this.sentPromptApi(this.promptLog);
			let d_res_obj = JSON.parse(d_res);
			if (d_res_obj.choices[0].message.error) {
				return
			}
			let d_content = d_res_obj.choices[0].message.content;
			if (d_content.includes("<correct>")) {
				correct = true
			}
		}
		this.defaultQuestion[this.questionIndex]['pass_status'] = (correct ? 'pass' : 'failed')

		let prompt = { role: "system", content: "", };
		if (this.parent.subType === "oralVocab") {
			prompt.content = `
			The question type is oral vocabulary.
			The question answer is "${this.defaultQuestion[this.questionIndex].name}"
			The student's answer is "${content.DisplayText}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralSentence") {
			prompt.content = `
			The question type is oral sentence.
			The question is "${this.defaultQuestion[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content.DisplayText}"
			The student's pronunciation score is "${content.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralFillIn") {
			let userAnswer = content.NBest[0].Lexical.charAt(0).toUpperCase() + content.NBest[0].Lexical.slice(1);

			prompt.content = `
			The question type is oral fill in.
			The question is "${this.defaultQuestion[this.questionIndex].name}"
			The question answer is "${this.defaultQuestion[this.questionIndex].answer}"
			The student's answer is "${userAnswer}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralMc") {
			let choices = this.get_display_choices(this.defaultQuestion[this.questionIndex].choice).join("\n");

			prompt.content = `
			The question type is oral multiple choice.
			The question is "${this.defaultQuestion[this.questionIndex].name}"
			The choices are: ${choices}
			The question answer is "${this.defaultQuestion[this.questionIndex].answer}"
			The student's answer is "${content.DisplayText}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		} else if (this.parent.subType === "oralParagraph") {
			prompt.content = `
			The question type is oral paragraph.
			The question answer is "${this.defaultQuestion[this.questionIndex].name}"
			The question pronunciation pass level is ${this.parent.ctx.passLevel}
			The student's answer is "${content.DisplayText}"
			The student's pronunciation score is "${content.NBest[0].PronScore}"
			Base on the result, respond to the student and end this question. Use your own word. Do not end with interrogative sentence. Avoid using symbol like: -/' and ensure the response is limited in 5 words. (important: Do not ask the student to retry.)`
		}
		let is_last_question = this.questionIndex === this.defaultQuestion.length - 1;
		if (is_last_question) {
			this.promptLog.push({
				role: "system",
				content: `This is the last question of this exercise.`
			});
		}
		this.promptLog.push(prompt);
		this.promptLog.push({
			role: "system",
			content: `
				The student answer is determined as ${correct?"correct":"incorrect"}.
				If you need to encourage the student, do not use the same words before to encourage the student. Please try to use different words or sentences to encourage or point out the mistake of the student. If the student needs attention, use a sentence or two to comfort him or her.The below is also a list of inappropriate responses. Whenever the student answered something that matched 'student scenarios', please generate a respond with similar meanings to the 'AI Responses'. These are the scenarios and responses pair (Student scenarios: AI Responses): <Foul>: Please be polite and speak in a positive way. <Yes>: Could you explain more please, so I can better understand? <No>: Are you sure? I promise it will be fun! <I don't know>: I'm sorry to hear that. What do you need help on? <What>: Would you like me to repeat that? I don't mind. <Um, like, you know>: You've got a point! Just try to say it more directly without the fillers. <Overly long response>: That's a rich answer! It could be even better with some trimming.
				Don't ask student to retry
			`
		})

		try {
			const res = await this.sentPromptApi(this.promptLog);
			console.log(this.promptLog);
			console.log(res);
			// debugger
			let res_obj = JSON.parse(res);
			if (res_obj.choices[0].message.error) {
				this.askDefaultQuestion();
				return;
			}
			let content = res_obj.choices[0].message.content;

			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });
			if (res) {
				let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
				if (audioRes) setTimeout(() => {
					if (this.questionIndex < this.defaultQuestion.length - 1) {
						this.questionIndex++;
						this.questionNumber++;
						this.defaultQuestionSession();
					} else {
						this.finishing();
					}
				}, audioRes.duration * 1000);
			}
		} catch (error) {
			console.log(error)
		}
	}
	
	public async finishing() {
		try {
			let content = "Your exercise is finished! Here is your result."
			this.promptLog.push({ role: "assistant", content: this.replaceSymbol(content) });

			let audioRes = await this.playAudio(content, this.replaceSymbol(content), false);
			await audioRes.is_finish;
			this.submission();
		} catch (error) {
			console.log(error);
		}
	}

	submission() {
		this.parent.myData.conversationData = this.eachQuestionScore;
		this.parent.myData.objectiveUsed = [...(new Set(this.objectiveUsed)).values()];
		this.parent.myData.EDBScore = this.EDBScore;

		let passStatus = this.defaultQuestion.map(item => item.pass_status);
		let stringifyStatus = JSON.stringify(passStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(this.conversation);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.answerChanged = true;
		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	testSubmission() {
		let conversationData = [
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 97,
				"AccuracyScore": 95,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 99,
				"AccuracyScore": 98,
				"CompletenessScore": 100,
				"FluencyScore": 100
			},
			{
				"PronScore": 3,
				"AccuracyScore": 14,
				"CompletenessScore": 0,
				"FluencyScore": 0
			},
			{
				"PronScore": 87,
				"AccuracyScore": 78,
				"CompletenessScore": 100,
				"FluencyScore": 100
			}
		];
		let objectiveUsed = ['10', '11', '12'];
		let EDBScore = [0, 0, 0, 0, 0, 0, 0, 0, 0, 30, 50, 10, 0, 0, 0];
		let questionPassStatus = ['pass', 'pass', 'pass', 'failed', 'failed'];
		let conversationRecord = [
			{
				"role": "assistant",
				"content": "Good evening, Mary Lam! How has your day gone?",
				"loading": false
			},
			{
				"role": "user",
				"content": "Hello, how are you?",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181331-671f63cb-1671f63cb9e827-5c891a4e.mp3"
			},
			{
				"role": "assistant",
				"content": "I'm doing well, thank you! Today, we will talk about food and drink, especially our favorite dishes and beverages. It's a fun topic that everyone enjoys! 🙂",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you like most about your favorite restaurant?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181401-671f63e9-1671f63e9d032e-7f988147.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's chat about something else, then.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What do you think makes food really delicious?",
				"loading": false,
				"EDBNumber": "11"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181436-671f640c-1671f640c4d9b4-4330797c.mp3"
			},
			{
				"role": "assistant",
				"content": "That's fine! Let's switch to a new topic now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Can you share your thoughts on your favorite snack?",
				"loading": false,
				"EDBNumber": "10"
			},
			{
				"role": "user",
				"content": "I don't know.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181610-671f646a-1671f646ab0479-64523c15.mp3"
			},
			{
				"role": "assistant",
				"content": "No worries! Let's turn to a new question now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "What is your favorite drink and why do you like it?",
				"loading": false,
				"EDBNumber": "12"
			},
			{
				"role": "user",
				"content": "Test.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181648-671f6490-1671f64901e9fc-252d68d7.mp3"
			},
			{
				"role": "assistant",
				"content": "It looks like we need to change topics now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Now, we will begin the exercise about our favorite food and drink. Let's enjoy!",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Chef",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/chef.png"
			},
			{
				"role": "user",
				"content": "Chef.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181740-671f64c4-1671f64c467751-216e6d17.mp3"
			},
			{
				"role": "assistant",
				"content": "Great job, Mary! You are doing well.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Organic",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/organic.png"
			},
			{
				"role": "user",
				"content": "Organic.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181753-671f64d1-1671f64d176b05-2803cc12.mp3"
			},
			{
				"role": "assistant",
				"content": "Well done, Mary! Keep it up.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Menu",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/menu.png"
			},
			{
				"role": "user",
				"content": "Menu.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181807-671f64df-1671f64df4ef1b-adb6754.mp3"
			},
			{
				"role": "assistant",
				"content": "Nice work, Mary! You're excelling today.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Condiment",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/condiment.png"
			},
			{
				"role": "user",
				"content": "Ha ha.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181820-671f64ec-1671f64ec874cb-4e157540.mp3"
			},
			{
				"role": "assistant",
				"content": "No problem at all! Let's move on.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Please read the vocab: Brunch",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/questionPicture/hard/brunch.png"
			},
			{
				"role": "user",
				"content": "Lunch.",
				"audioPath": "//dev.openknowledge.hk/RainbowOne/tmp_upload/2024/10/2024-10-28-181841-671f6501-1671f6501e50e7-746975dc.mp3"
			},
			{
				"role": "assistant",
				"content": "That's okay! Let's continue onward now.",
				"loading": false
			},
			{
				"role": "assistant",
				"content": "Your exercise is finished! Here is your result.",
				"loading": false
			}
		];

		this.eachQuestionScore = conversationData;
		this.parent.myData.conversationData = conversationData;
		this.parent.myData.objectiveUsed = [...(new Set(objectiveUsed)).values()];;
		this.parent.myData.EDBScore = EDBScore;

		let stringifyStatus = JSON.stringify(questionPassStatus);
		let uriEncodeStatus = encodeURIComponent(stringifyStatus);
		let encodeStatus = btoa(uriEncodeStatus);
		this.parent.myData.questionPassStatus = encodeStatus;

		let stringifyResult = JSON.stringify(conversationRecord);
		let uriEncodeResult = encodeURIComponent(stringifyResult);
		let encodeResult = btoa(uriEncodeResult);
		this.parent.myData.conversationRecord = encodeResult;

		let stringifyFoul = JSON.stringify(this.parent.foulLanguage);
		let uriEncodeFoul = encodeURIComponent(stringifyFoul);
		let encodeFoul = btoa(uriEncodeFoul);
		this.parent.myData.foulLanguage = encodeFoul;

		this.parent.conversationData = this.eachQuestionScore;
		this.parent.myData.conversationFinish = true;
		this.parent.myData.objective = [...this.objective]

		this.parent.pronScore = this.handlePronunciationScore('PronScore');
		this.parent.accuracyScore = this.handlePronunciationScore('AccuracyScore');
		this.parent.completenessScore = this.handlePronunciationScore('CompletenessScore');
		this.parent.fluencyScore = this.handlePronunciationScore('FluencyScore');
		
		console.log(this.parent.pronScore, this.parent.accuracyScore, this.parent.completenessScore, this.parent.fluencyScore, this.parent.myData)
		
		this.parent.autoSubmit();

		setTimeout(() => {
			this.parent.isConversationReportOpen = true;
			this.emitter.emit({ action: "close", isFinalReportOpen: true });
		}, 5000);
	}

	/////////////////////////////////////////
	//          js handling function       //
	/////////////////////////////////////////
	scrollToBottom(): void {
		if (this.dialogContainer) {
			const container = this.dialogContainer.nativeElement;
			setTimeout(() => {
				container.scrollTop = container.scrollHeight;
			}, 500);
		}
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.parent.lang];
		return text;
	}

	setRobotImageStatus(status: string) {
		if (this.robotImageStatus === status) return;
		this.robotImageStatus = status;
		if (status === "wrong") {
			setTimeout(() => {
				this.setRobotImageStatus("wrong_last");
			}, 3000);
		} else if (status === "correct") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			});
		} else if (status === "wrong_last") {
			setTimeout(() => {
				this.setRobotImageStatus("idle");
			}, 3000);
		}
	}

	startGreeting() {
		this.isInstructionShown = false;
		this.isContentShown = true;
		this.emitter.emit({ action: "loadAudio" });
		this.greeting();
		// this.firstEDBQuestion();
		// this.defaultQuestionSession();
		// this.submission();
		// this.testSubmission();
	}

	async playAudio(text: string, conversationContent: string, askDefaultQuestion: boolean, EDBNumber?: null | string) {
		if (this.isDestroyed) return;
		let preLoad = { role: "assistant", content: "", loading: true };
		this.conversation.push(preLoad);
		this.scrollToBottom();

		try {
			let res = await this.getAudioDuration(text)
			if (res) {
				preLoad.loading = false;
				this.audioInfo.push({ url: res.url, duration: res.duration })

				if (EDBNumber) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), EDBNumber: EDBNumber }, preLoad
					);
				} else if (this.parent.subType === "oralParagraph" && this.isAnswerDefaultQuestion) {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent), isParagraph: true }, preLoad
					);
				} else {
					ObjectUtils.copyTo(
						{ role: "assistant", content: this.replaceSymbol(conversationContent) }, preLoad
					);
				}

				if (askDefaultQuestion) {
					if (this.parent.subType === "oralVocab") {
						this.conversation.push({ role: "assistant", content: this.defaultQuestion[this.questionIndex].url });
					}
				}

				this.scrollToBottom();
				this.status = 'playing';
				this.setRobotImageStatus("talk");
				this.audio.setAttribute("src", res.url);
				this.audio.play();
				res.is_finish = new Promise((resolve) => {
					setTimeout(() => resolve(true), res.duration * 1000);
				})

				setTimeout(() => {
					this.setRobotImageStatus("idle");
					this.status = 'idle';
				}, res.duration * 1000);

				return (res);
			}
		} catch (error) {
			console.log(error)
		}
	}

	getAudioDuration(text: string): Promise<any> {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		return new Promise((resolve) => {
			this.datas.get2({ url: url, data: data, jwt: false, loading: false }).then((res: any) => {
				if (res.token && res.token.url) {
					let statusUrl: string = res.token.url;
					if (statusUrl.substr(0, 7) == "http://") statusUrl = "https://" + statusUrl.substring(7);
					this.datas.get2({ url: statusUrl, jwt: false, loading: false }).then((res: any) => {
						if (res.url) {
							const audio = new Audio(`${res.url}`);
							audio.addEventListener('loadedmetadata', () => {
								const url = res.url;
								const duration = Math.round(audio.duration);
								resolve({ url, duration });
							});
						}
					});
				}
			});
		});
	}

	listenButtonClicked(filePath) {
		this.audio.setAttribute("src", filePath);
		this.audio.play();
	}

	replaceSymbol(content) {
		try {
			if (content.includes('’')) content = content.replace(/’/g, "'");
			if (content.includes('"')) content = content.replace(/"/g, "");
			if (content.includes(';')) content = content.replace(/-/g, ",");
			return content;
		} catch (error) {
			console.error(error);
			return "";
		}
	}

	handleUserAnswer(userAnswer: any) {
		if (this.parent.subType === "oralFillIn") {
			let checkingNumber = parseInt(userAnswer.NBest[0].ITN);
			let question = this.defaultQuestion[this.questionIndex].reading;
			let answer = this.defaultQuestion[this.questionIndex].answer;
			let isIncluded = question.toLowerCase().includes(answer.toLowerCase());
			let index = question.toLowerCase().indexOf(answer.toLowerCase());
			let isUppercase = false;
			if (index !== -1) {
				isUppercase = question[index] === question[index].toUpperCase();
			}

			if (isUppercase) {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical.charAt(0).toUpperCase() + userAnswer.NBest[0].Lexical.substring(1)}.`;
				}
				return userAnswer.DisplayText;
			} else {
				if (checkingNumber) {
					return `${userAnswer.NBest[0].Lexical}.`;
				}
				return userAnswer.DisplayText.charAt(0).toLowerCase() + userAnswer.DisplayText.slice(1);
			}
		} else if (this.parent.subType === "oralMc") {
			return userAnswer.DisplayText;
		} else {
			return userAnswer.DisplayText;
		}
	}

	handleRecognitionWord(word: Array<any>) {
		let recognitionWordArray = []
		for (let item of word) {
			recognitionWordArray.push(item);
		}
		// console.log(recognitionWordArray);
		return recognitionWordArray;
	}

	handlePronunciationScore(scoreType: string) {
		return Math.round(this.eachQuestionScore.reduce((sum, item) => sum + item[scoreType], 0) / this.eachQuestionScore.length);
	}

	readText(text: string): void {
		let url: string = "//rainbowone.azurewebsites.net/CI2/index.php/TTS/request_token";
		let data: any = {
			gender: 'F',
			txt: text,
			speed: this.speed,
			lang: 'en-GB',
			pitch: 1,
			name: "en-GB-LibbyNeural",
			style: "",
		};
		this.setRobotImageStatus("talk");

		this.datas.get2({ url: url, data: data, jwt: false }).then((res: any) => {
			if (res.token && res.token.url) {
				let statusUrl: string = res.token.url;
				if (statusUrl.substr(0, 7) == "http://")
					statusUrl = "https://" + statusUrl.substring(7); //statusUrl = statusUrl.replace('http://', 'https://');
				this.datas
					.get2({ url: statusUrl, jwt: false })
					.then((res: any) => {
						if (res.url) {
							this.audio.setAttribute("src", res.url);
							this.audio.play();
							setTimeout(() => {
								this.setRobotImageStatus("idle");
							}, 5000);

							//});
						}
					});
			}
		});
	}

	getHint() {
		if (this.hintQuota <= 0) {
			return
		}
		//只用最後4條訊息獲取對話 節省tokens
		var tempConversation = [...this.conversation].filter(message => !message.isHint).slice(-4);
		tempConversation.push(
			{
				role: 'user',
				content: "How should I respond your question? give me an example in 20 words"
			}
		)

		this.chat.oralChat(tempConversation).then(res => {
			let content = JSON.parse(res).choices[0].message.content;
			this.conversation.push({ role: "user", content: this.replaceSymbol(content), isHint: true });
			this.hintQuota--;
			this.scrollToBottom();
		});
	}

	not_repeat_hint(hint, hint_idx) {
		for (const index in this.objectiveUsed) {
			if (index >= hint_idx) {
				break
			}
			if (this.objectiveUsed[index] == hint) {
				return false
			}
		}
		return true
	}

	get_display_choices(choices) {
		let choice_letter = "A";
		choices = choices.map(choice => {
			let display_choice = `${choice_letter}: ${choice}`;
			choice_letter = String.fromCharCode(choice_letter.charCodeAt(0) + 1);
			return display_choice;
		})
		return choices;
	}

	preloadImage() {
		let url = ['oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_waiter_talk.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_correct.png', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong.gif', 'oka.blob.core.windows.net/media/ai-composition/2024/07/22/school(272)/robot_waiter_wrong_last.png'];
		if (this.aiOralChar == '2') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/panda_talking.png');
		} else if (this.aiOralChar == '3') {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/robot_talk.png');
		} else {
			url.push('oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/student_talk.png');
		}
		url.forEach(e => {
			const imgEle = new Image();
			imgEle.src = '//' + e;
		});
	}

	setInstruction() {
		if (this.parent.subType === "oralVocab") {
			this.instruction = "Speak aloud the vocabulary asked by the bot.";
		} else if (this.parent.subType === "oralSentence") {
			this.instruction = "Speak aloud the phrase asked by the bot.";
		} else if (this.parent.subType === "oralFillIn") {
			this.instruction = "Speak aloud your answer when the bot asks you questions.";
		} else if (this.parent.subType === "oralMc") {
			this.instruction = "Speak aloud your answer when the bot asks you questions. No need to speak out the alphabet.";
		} else if (this.parent.subType === "oralParagraph") {
			this.instruction = "Speak aloud the paragraph asked by the bot.";
		} else if (this.parent.subType === "oralAI") {
			this.instruction = "Press the close button when you want to finish.";
		}
	}
}
