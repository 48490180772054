import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx-js-style';
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class XlsxService {

  //-----------------------------------------------------------------------------------------------
	constructor() {}

    public saveXLSXFromJSON(options:any):void {
        var wb = XLSX.utils.book_new();
		options.sheets.forEach((s:any)=>{
            let ws:any;
            if (s.replaceHeader) {
                //Had to create a new workbook and then add the header
                //XLSX.utils.sheet_add_aoa(wb, s.replaceHeader);
                ws = XLSX.utils.aoa_to_sheet([s.replaceHeader]);
                //Starting in the second row to avoid overriding and skipping headers
                XLSX.utils.sheet_add_json(ws, s.data, { origin: 'A2', skipHeader: true, header:s.header });
            } else {
                ws = XLSX.utils.json_to_sheet(s.data, { header:s.header, skipHeader:s.skipHeader });
            }

            XLSX.utils.book_append_sheet(wb, ws, s.name);
        });
        if (options.beforeSave) wb = options.beforeSave(wb);
        var wopts:XLSX.WritingOptions = { bookType:'xlsx', bookSST:false, type:'array', cellStyles: true };
        var wbout = XLSX.write(wb,wopts);

        /*var wbout = XLSXStyle.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "buffer",
            cellStyles: true
        });*/

        FileSaver.saveAs(new Blob([wbout],{type:"application/octet-stream"}), options.filename);
    }
		
    public loadFirstSheetAsJSON(file:File=null):Promise<any> {
        return this.loadWorkbook(file).then(
            this.getFirstWorksheet
        ).then(
            this.sheetToJSON
        );
    }

    public loadFirstSheetAsArray(file:File=null):Promise<any> {
        return this.loadWorkbook(file).then(
            this.getFirstWorksheet
        ).then(
            this.sheetToArray
        );
    }

    public openFile():Promise<File> {
        return new Promise((resolve, reject)=>{
            let element = document.createElement('input');
            element.setAttribute('type', 'file');
            element.accept = ".xlsx";
            element.onchange = (event)=>{
                if (event.target['files'][0]) {
                    resolve(event.target['files'][0]);
                } else {
                    reject();
                }
            };
            element.click();
        });
    }

    private readAsBinaryString(file:File):Promise<string> {
        return new Promise((resolve, reject)=>{
            let reader = new FileReader();
            reader.onload = (onloadEvent:any) => {
                try {
                    resolve(onloadEvent.target.result);
                } catch (err) {
                    reject(err);
                }
            };
            reader.onerror = (reason:any) =>{
                reject(reason);
            }
            reader.readAsBinaryString(file);
        });
    }

    public loadWorkbook(file:File=null):Promise<any> {
        return new Promise((resolve, reject)=>{
            let readXlsx:Function = (file:File)=>{
                this.readAsBinaryString(file).then((string:string)=>{
                    let workbook = XLSX.read(string, {type: 'binary'});
                    resolve(workbook);
                });
            };
            if (file) {
                readXlsx(file);
            } else {
                this.openFile().then((file:File)=>readXlsx(file));
            }
        });
    }

    public getFirstWorksheet(workbook:any):any {
        var name = workbook.SheetNames[0];
        return workbook.Sheets[name];
    }
    
    public loadSheet(sheetName:string):Promise<any> {
        return this.loadWorkbook().then((workbook:any)=>{
            return workbook.Sheets[sheetName];
        })
    }

    public sheetToJSON(sheet:any):any[] {
        return XLSX.utils.sheet_to_json(sheet);
    }

    public sheetToArray(sheet:any):any[] {
        return XLSX.utils.sheet_to_json(sheet, {header:1});
    }

}
