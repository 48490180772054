import { Component, OnInit, AfterViewInit, Input, ElementRef } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { ThemeService } from 'src/app/service/theme.service';
import { SettingService } from 'src/app/service/setting.service';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition, faPen } from '@fortawesome/pro-solid-svg-icons';
import { CommonService } from 'src/app/service/common.service';
import { ScriptService } from 'src/app/service/script.service';
import { tap } from 'rxjs/operators';
import { AlertService } from 'src/app/service/alert.service';
import { OupService } from 'src/app/service/oup.service';
@Component({
	selector: 'user-menu',
	template: `
  <div class="personal" *ngIf="datas.userInfo" [ngStyle]="style" (click)="personalClick()">
    <div [ngClass]="['personal__photo', photoClass, gendorRole]" [style.backgroundImage]="photo"></div>
    <div *ngIf="showName" class="personal__name">
      	<span [style.color]="style.color" [style.borderColor]="style.color" [style.fontSize]="style.fontSize">
			{{ oups.isOupUser ? oups.get_user_display_name(datas.userInfo.uid) : datas.getUserDisplayName() }}
		</span>
    </div>
  </div>
  <div class="layer forPositionTest"><div class="logout-modal"></div></div>
  <div class="layer" *ngIf="logoutModal" (click)="layerClick($event)">
    <div class="logout-modal">
    <div class="logout-modal__triangle" *ngIf="logoutModal && logoutModal.left" [style.left.px]="logoutModal.left"></div>
        <div class="logout-modal__content">
            <div class="logout-modal__photo" (click)="datas.userInfo && datas.userInfo.user_role==3 && !oups.isOupUser && ($event.stopPropagation() || userIconEditor.open($event.target))">
                <div [ngClass]="['logout-modal__image', photoClass, gendorRole]" [style.backgroundImage]="photo">
					<fa-icon class="editIcon" [style.backgroundColor]="setting.homeLayout && setting.homeLayout.homePageThemeColor || '#eee'" *ngIf="datas.userInfo && datas.userInfo.user_role==3 && !oups.isOupUser" [icon]="faPen"></fa-icon>
				</div>
            </div>
            <div class="logout-modal__name">
				<span>{{ oups.isOupUser ? oups.get_user_display_name(datas.userInfo.uid) : datas.getUserDisplayName() }}</span>
            </div>
            <div *ngIf="displaySchoolName" class="logout-modal__school-name">
				<span>{{ oups.isOupUser ? oups.get_school_name() : displaySchoolName }}</span>
            </div>
            <div class="logout-modal__text forTags" *ngIf="myTags.length>0" [class.moreThan5Tags]="myTags.length>5" (click)="$event.stopPropagation()">
                <ng-container *ngFor="let tag of myTags; let index=index;">
					<div *ngIf="isMyTagsOpened || index<5">
						{{ oups.isOupUser ? oups.get_class_name(tag.title) : tag.title }}
					</div>
                </ng-container>
                <fa-icon *ngIf="myTags.length>5" [icon]="(isMyTagsOpened?faChevronUp:faChevronDown)" (click)="isMyTagsOpened=!isMyTagsOpened"></fa-icon>
            </div>
            <div class="logout-modal__text" (click)="$event.stopPropagation();">
                {{'home-screen.dark-mode'|translate}}
                <ui-switch [checked]="thms.theme$.value=='dark'" [color]="setting.homeLayout.homePageThemeColor" style="margin-left:20px;" (change)="thms.themeSelected=(thms.theme$.value=='dark'?'light':'dark')"></ui-switch>
            </div>
            <div class="logout-modal__text" (click)="networkCheckOn=true">{{'home-screen.check-connection-speed'|translate}}</div>
			<div class="logout-modal__text" *ngIf="allowChangePhone" (click)="changePhoneClick($event)">{{'home-screen.change-phone'|translate}}</div>
            <div class="logout-modal__text" *ngIf="allowChangePassword" (click)="changePasswordClick($event)">{{'home-screen.change-password'|translate}}</div>
			<div class="logout-modal__text">
				{{'voiceTool.language'|translate}}:
				<span
					*ngFor="let lang of langOptions"
					class="langBtn"
					[class.selected]="datas.lang==lang.value"
					[style.borderColor]="datas.lang==lang.value ? (setting.homeLayout && setting.homeLayout.homePageThemeColor || '#eee') : '#eee'"
					(click)="changeLang(lang.value)"
				>{{lang.title}}</span>
			</div>
            <div class="logout-modal__text" (click)="logoutClick($event)">{{'home-screen.logout'|translate}}</div>
        </div>
    </div>
  </div>
  <div class="update-wrapper" *ngIf="resetPasswordModal || editPhoneModal">
  	<reset-password2 *ngIf="resetPasswordModal" (close)="resetPasswordClose($event)"></reset-password2>
  	<edit-phone *ngIf="editPhoneModal" (close)="editPhoneClose($event)"></edit-phone>
  </div>
  <network-check *ngIf="networkCheckOn" (close)="networkCheckOn=false"></network-check>
  <userIconEditor #userIconEditor (onEditorClose)="updatePersonalIcon().subscribe()"></userIconEditor>
  `,
	styleUrls: ['./user-menu.component.scss'],
})

export class UserMenuComponent implements OnInit, AfterViewInit {
	public faChevronUp: any = faChevronUp;
	public faChevronDown: any = faChevronDown;
	public faPen:IconDefinition = faPen;

	// public displayName = '';
	public displaySchoolName = '';
	@Input() data: any;
	// @Output() changePassword: EventEmitter<void> = new EventEmitter();
	public logoutModal;
	public resetPasswordModal;
	public editPhoneModal;
	public photo;
	public photoClass = 'circle';
	@Input() public style: any = { top: 0, height: 0, right: 0 };
	@Input() public showName: boolean = true;
	public gendorRole = 'student-male';
	public networkCheckOn: boolean = false;
	public myTags: any[] = [];
	public isMyTagsOpened: boolean = false;
	public allowChangePassword: boolean = true;
	public allowChangePhone: boolean = false;
	public langOptions:any[] = [ { value:"tc", title:"繁" }, { value:"sc", title:"簡" }, { value:"en", title:"英" } ];

	constructor(public datas: DataService, private sans: DomSanitizer, private router: Router, public thms: ThemeService, public setting: SettingService, public coms: CommonService, private script: ScriptService, private als: AlertService, public oups: OupService) {

	}

	ngOnInit() {
		this.coms.userMenuComponent = this;
		let userInfo = this.datas.userInfo;
		this.initStyle(this.data);
		this.gendorRole = 'teacher-male';
		if (userInfo) {
			this.prepareData(userInfo);
		} else {
			let promise = new Promise((resolve, reject) => {
				let interval = setInterval(() => {
					if (this.datas.userInfo) {
						clearInterval(interval);
						resolve(this.datas.userInfo);
						this.prepareData(this.datas.userInfo);
					}
				}, 500);
				setTimeout(() => {
					if (this.datas.userInfo == null) {
						reject();
					}
				}, 3000);
			}).then(userInfo => {
				this.prepareData(userInfo);
			})
		}
		this.getMyTagsOfCurYear();
		this.coms.waitFor(()=> this.setting.schoolSettings, 200, 500).then(settings=>{
			if (this.datas.userInfo.user_role == 2){
				this.allowChangePassword = settings.ACCESS_STUDENT_RESET_PASSWORD == 1;
			} else if (this.datas.userInfo.user_role == 3){
				this.allowChangePassword = settings.ACCESS_TEACHER_RESET_PASSWORD == 1;
			}
		});
	}

	ngAfterViewInit() {

	}

	prepareData(userInfo) {
		this.gendorRole = (userInfo.user_role == 2 ? 'student' : 'teacher') + '-' + (userInfo.gender == 1 ? 'male' : 'female');
		// if (this.datas.lang == 'en') {
		// 	this.displayName = userInfo.first_name + ' ' + userInfo.last_name;
		// } else {
		// 	this.displayName = userInfo.c_last_name + userInfo.c_first_name;
		// }
		this.displaySchoolName = userInfo.school_name;
		const path = localStorage.getItem('common_photo');
		if (path) {
			this.photo = this.sans.bypassSecurityTrustStyle("url('" + path + "')"); //preload image
		}
		
		if (userInfo.m && sessionStorage.getItem('common_photo_done') == null) { //if exist, will reload image even loaded.
			let arr = userInfo.m.split('/');
			let okgId = arr[arr.indexOf('id') + 1];
			this.datas.post('ROHomeScreen.getOKGImage', [okgId]).subscribe((res: any) => {
				if (res.image) {
					if (res.image.url) {
						const path = environment.resourceServer[res.image.server] + res.image.url;
						const url = 'url("' + path + '")';
						localStorage.setItem('common_photo', path);
						sessionStorage.setItem('common_photo_done', '1');
						this.photo = this.sans.bypassSecurityTrustStyle(url);
					}
				}
			});
		}
		if (userInfo.user_role == 3 && (userInfo.school_id == 272 || userInfo.school_id == 1675) ){
			this.allowChangePhone = true;
		}
	}

	initStyle(style) {
		this.style.height = (this.style && this.style.height) || (style && style.height) || '40px';
		this.style.top = (this.style && this.style.top) || (style && style.top) || '15px';
		this.style.right = (this.style && this.style.right) || (style && style.right) || '22px';//request by hung
		this.style.color = (this.style && this.style.color) || (style && style.color) || '#fff';
		this.style.fontSize = (this.style && this.style.fontSize) || (style && style.fontSize) || '15px';
		this.photoClass = (this.style && this.style.mask) || (style && style.mask) || 'circle';
	}

	personalClick() {
		const el = <HTMLElement>document.querySelector('.personal__photo');
		const logoutModal = <HTMLElement>document.querySelector('.logout-modal');
		const logoutModalClientRect = logoutModal.getBoundingClientRect();
		const elInfo = el.getBoundingClientRect();
		const left = elInfo.left + (elInfo.width / 2) - logoutModalClientRect.left - 10;
		this.logoutModal = { left: left };
		this.thms.reloadSetting()
	}

	changePasswordClick($event) {
		$event.stopPropagation();
		this.logoutModal = null;
		this.resetPasswordModal = {};
	}

	changePhoneClick($event) {
		$event.stopPropagation();
		this.logoutModal = null;
		this.editPhoneModal = {};
	}

	logoutClick($event = null) {
		if ($event) {
			$event.stopPropagation();
		}
		this.datas.post('Account.logout').subscribe((res: any) => {
			setTimeout(()=>{ //avoid error 
				this.datas.userInfo = null;
				this.datas.jwt = '';
				this.datas.jwtObj = null;
				if (localStorage) {
					localStorage.removeItem('common_jwt');
					localStorage.removeItem('common_uid');
					localStorage.removeItem('common_schoolId');
					localStorage.removeItem('common_schoolLogo');
					localStorage.removeItem('common_photo');
					sessionStorage.removeItem('common_photo_done');
					sessionStorage.removeItem('common_schoolLogo_done');
					localStorage.removeItem('homeScreen_data');
				}
				if (this.oups.isOupUser) {
					this.oups.oupLogout();
				} else {
					// let url = location.origin + '/login/' + this.datas.lang + '/' + this.datas.appId2;
					let url = location.origin + '/login';
					if (location.port == "") {
						const href = window.location.href;
						url = href.replace(/roWeb.*/, 'roWeb/login');
					}
					location.href = url;
				}
			},10);
		});
	}

	layerClick($event) {
		$event.stopPropagation();
		this.logoutModal = null;
	}

	resetPasswordClose($event) {
		if ($event == null) {
			this.resetPasswordModal = null;
			return;
		} else {
			const email = this.datas.userInfo.uacc_username;
			this.datas.post('ROAppReg.resetPassword', [email, $event.password]).subscribe((res: any) => {
				if (res) {
					this.resetPasswordModal = null;
					this.logoutClick();
				}
			});
		}
	}

	editPhoneClose($event) {
		this.editPhoneModal = null;
	}

	private getMyTagsOfCurYear(): void {
		this.myTags = [];
		this.datas.post('ROHomeScreen.getMyTagsOfCurYear').subscribe((res: any) => {
			if (res && res[0]) {
				this.myTags = res.filter(e => e.type < 11); //ignore virtual tag
			}
		});
	}

	public updatePersonalIcon(){
		const uid = this.datas.userInfo.uid;
		return this.datas.post('KenCore.getPhotos', [ [uid] ]).pipe( tap((res:any)=>{
			const row = res.photo[0];
			let roleGender = row.user_role > 2 && row.user_role != 6 ? 'teacher' : 'student';
			roleGender = roleGender + '_' + (row.gender > 1 ? 'female' : 'male');
			let url = this.script.imgUrl + 'avatar_' + roleGender + '.svg';
			if (row.url) {
				url = environment.resourceServer[row.server] + row.url;
			}
			localStorage.setItem('common_photo', `https:${url}`);
			this.photo = this.sans.bypassSecurityTrustStyle(`url('${url}')`);
		}));
	}

	public changeLang(langCode:string):void {
		let langString = '繁'
		if (langCode === 'en') {
			langString = '英'
		} else if (langCode === 'sc') {
			langString = '簡'
		}

		this.als.alert2('commonService.reload-to-apply-prompt', {lang: langString}, 
			{ btns:[ [{key: 'yes', labelKey: 'commonService.switch', ngStyle:{backgroundColor: 'var(--section-maincolor)'}}, "cancel"] ] },
			document.querySelector("app-root div.router ng-component") || null
		).then((key:string)=>{
			if (key=='yes') {
				this.datas.setLang(langCode);
				location.reload();
			}
		});
	}

}
