import { HttpHeaders } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, TemplateRef, ViewChild, ViewChildren } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

// =======================================
// service
// =======================================
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import downloadjs from "downloadjs";
import * as moment from 'moment';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { SettingService } from 'src/app/service/setting.service';
import { ThemeService } from 'src/app/service/theme.service';
import { UploadService } from 'src/app/sharedModule/uploadModule/upload.service';

// =======================================
// component
// =======================================
import { PreviewComponent } from 'src/app/sharedModule/previewModule/preview.component';

// =======================================
// icon
// =======================================
import { faRedo, faSearch, faClone, IconDefinition, faBold, faItalic, faUnderline, faFont, faPen } from '@fortawesome/pro-regular-svg-icons';
import { faFile, faBars, faTrash, faGripDots, faImage, faFilm, faListMusic, faChevronDown, faChevronUp, faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { faFortAwesome } from '@fortawesome/free-brands-svg-icons';
import { faCheckCircle, faCircleMinus } from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaDown,faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';


// =======================================
// AgGrid use items
// =======================================
import { CellValueChangedEvent, ColDef, GetDataPath, RowNode } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridService } from 'src/app/service/agGrid.service';
import { CheckBoxHeader } from 'src/app/sharedModule/customAgGridModule/checkbox.header';
import { CheckBoxRenderer } from 'src/app/sharedModule/customAgGridModule/checkbox.renderer';
import { FileFormatterComponent } from 'src/app/sharedModule/customAgGridModule/fileFormatter.component';
import { IconBtnRenderer } from 'src/app/sharedModule/customAgGridModule/iconBtn.renderer';
import { OkaPulldown2Editor } from 'src/app/sharedModule/customAgGridModule/okaPulldown2.editor';
// =======================================

import { GUID } from 'src/app/class/hk/openknowledge/encryption/GUID';
import { FileIOService } from 'src/app/service/FileIO.service.js';
import { SoundRecorderPopupComponent } from 'src/app/sharedModule/soundRecorderPopupModule/soundRecorderPopup.component.js';
import { SubjectService } from 'src/app/sharedModule/subjectModule/subject.service.js';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubjectSelector3Component } from 'src/app/sharedModule/subjectSelector3Module/subjectSelector3.component';
import { WhitePopupComponent } from 'src/app/sharedModule/whitePopupModule/whitePopup.component';
import { ComponentMenuBarComponent } from 'src/app/sharedModule/componentMenuBarModule/componentMenuBar.component';
import { NoRowsOverlay } from 'src/app/sharedModule/customAgGridModule/noRows.overlay';
import { Modal2Component } from 'src/app/sharedModule/modal2Module/modal2.component';
import { ROBookEditWorkspace } from 'src/app/sharedModule/roBookModule/ROBookEditWorkspace';
import { ROBookConfig } from 'src/app/sharedModule/roBookModule/ROBookConfig';
import { SelectMarkerComponent } from 'src/app/sharedModule/roBookModule/SelectMarker.component';
import { LearningObjectivePopupSelector } from 'src/app/sharedModule/subjectModule/node-selector.component';
import { ArrayUtils } from 'src/app/common/ArrayUtils';
import { PopupSelectRenderer } from 'src/app/sharedModule/customAgGridModule/popupSelector.renderer';
import { ROComponent, ROImageComponent, ROPageComponent } from 'src/app/sharedModule/roBookModule/ROComponent';
import { ROSound } from 'src/app/sharedModule/roBookModule/ROSound';
import { ROSimpleDragLine } from 'src/app/sharedModule/roBookModule/ROSimpleDragLine';
import { ROPageComponentContainer } from 'src/app/sharedModule/roBookModule/ROPage';
import { RODragDropA, RODragDropC } from 'src/app/sharedModule/roBookModule/RODragDrop';
import { TopLayer } from 'src/app/common/TopLayer';
import { StyleUtils } from 'src/app/sharedModule/roBookModule/StyleUtils';
import { AssessmentViewerComponent } from 'src/app/sharedModule/AssessmentViewerModule/AssessmentViewer.component';
import { QBSetFilterComponent } from './customFilter/QuestionBankCustomFilter.component';
import { ObjectUtils } from 'src/app/common/ObjectUtils';
import { AssessmentService } from './service/Assessment.service';
import { OkaPulldown2Component } from 'src/app/sharedModule/okaPulldownModule/okaPulldown2.component';
import { OKDQBComponent } from 'src/app/sharedModule/AssessmentViewerModule/OKDQB.component';
import { QuestionBankMoveImagePopUpComponent } from './QuestionBankMoveImagePopUp/QuestionBankMoveImagePopUp.component';
import { ColorPickerComponent } from 'src/app/sharedModule/colorPickerModule/color-picker.component';

@Component({
	selector: 'QuestionBankEditGrid',
	templateUrl: './QuestionBankEditGrid.component.html',
	styleUrls: ['./QuestionBankEditGrid.component.scss'],
})


export class QuestionBankEditGridComponent implements OnInit,OnChanges/*, AfterViewInit*/ {
	@ViewChild('comMenu', {static:false}) public comMenu:ComponentMenuBarComponent;
	@ViewChild('whitePopup', {static:false}) public whitePopup:WhitePopupComponent;
	@ViewChild('componentListPopup', {static:false}) public componentListPopup:WhitePopupComponent;
	@ViewChild('componentListPopup2', {static:false}) public componentListPopup2:WhitePopupComponent;
	@ViewChild('preview', {static:true}) public preview:PreviewComponent;
	@ViewChild('sndRecorder', {static:true}) public sndRecorder:SoundRecorderPopupComponent;
	@ViewChild('subjectSelector', {static:true}) public  subjectSelector:SubjectSelector3Component;
	@ViewChild('learningObjectiveSelector', {static:true}) public  learningObjectiveSelector:LearningObjectivePopupSelector;
	@ViewChild('lpfSelector', {static:true}) public lpfSelector:LearningObjectivePopupSelector;
	@ViewChild('editComponents', {static:false}) public editComponents:Modal2Component;
	@ViewChild('editWorkSpace', {static:false}) public editWorkSpace:ROBookEditWorkspace;
	@ViewChild('selectMarker', {static:false}) public selectMarker:SelectMarkerComponent;
	@ViewChild('moveImagePopUp', {static:false}) public moveImagePopUp: QuestionBankMoveImagePopUpComponent;
	@ViewChild('colorPicker', {static:false}) public colorPicker: ColorPickerComponent;
	@ViewChild('backgroundPicker', {static:false}) public backgroundPicker: ColorPickerComponent;

	@ViewChildren(OKDQBComponent) questionSheet: QueryList<OKDQBComponent>;

	@Input() public mode:string = "questionBank";
	@Input() public uiView:string = "grid";
	@Input() public permission:any = null;
	@Input() public bookSetting:any;
	@Input() public parent;
	@Input() public fetchOptions:any;
	@Output() public onSelectionChanged:EventEmitter<any> = new EventEmitter<any>();
	@Output() public onDataChanged:EventEmitter<any> = new EventEmitter<any>();
	@Output() public onLearningObjectSelected:EventEmitter<any> = new EventEmitter<any>();
	

	public users:any[] = [];
	public schools:any[] = [];
	public records:any[] = [];

	protected activeFilter:number;

	public faSearch:IconDefinition = faSearch;
	public faRedo:IconDefinition = faRedo;
	public faBars:IconDefinition = faBars;
	public faTrash:IconDefinition = faTrash;
	public faClone:IconDefinition = faClone;
	public faCheckCircle:IconDefinition = faCheckCircle;
	public faCheckCircleLight:IconDefinition = faCheckCircleLight;
	public faGripDots:IconDefinition = faGripDots;
	public faImage:IconDefinition = faImage;
	public faFilm:IconDefinition = faFilm;
	public faListMusic:IconDefinition = faListMusic;
	public faChevronUp:IconDefinition = faChevronUp;
	public faChevronDown:IconDefinition = faChevronDown;
	public faItalic:IconDefinition = faItalic;
	public faUnderline:IconDefinition = faUnderline;
	public faBold:IconDefinition = faBold;
	public faFont:IconDefinition = faFont;
	public faPen:IconDefinition = faPen;
	public faCircleArrowRight:IconDefinition = faCircleArrowRight;
	
	protected tmpFiles:any[] = [];
	protected delFiles:any[] = [];

	public state:string = "no change";
	public agGridOptions:any = null;
	public gridColumns:any;
	// for the color picker
	public textColor: string = '#000'
	public backgroundColor: string = '#ffffff'

	// 題號位置 q:{x:0,y:0,freezed:1,color:6011353,show:true}
	// 分數位置 s:{x:963.15,y:0,freezed:1,offset:null,reference:"rt", show:1, enable:1}
	// 題號次序資料 qInfo:{level:0,rootIndex:0,root:0, id:0,pid:0,index:0,order:0}
//	protected langData:any;
	public detectNew:string = "qid";

	public questionList:any;
	public answers:any;
	public change:boolean = false;

//	public selectionPanelConfig:any;
	public selectedItems: any[] = [];

	public editable:any;
	public notSameSchool:any;
	public cellClassRules:any;
	public editableCSS:any;
	public linkedQuestionRule:any;

	public pls_select:string;
	public currentQuestionObj:any;
	public subjectSelectorModelData:any;

	public focusEdit:any;

	public scoreList:any[] = [
		{label:1,value:1},
		{label:2,value:2},
		{label:3,value:3},
		{label:4,value:4},
		{label:5,value:5},
		{label:6,value:6},
		{label:7,value:7},
		{label:8,value:8},
		{label:9,value:9},
		{label:10,value:10}
	];
	public typeList:any[] = [];
	public gradeList:any[] = [];
	public levelList:any[] = [];
	public componentList:any = [
		//{titleKey:"QBEditor.option.type_opt.QBInfoBlock"},
		{titleKey:"ro.components.name.QBInfoBlock"},
		{titleKey:"ro.components.name.QBTextMC"},
		{titleKey:"ro.components.name.QBRecorder"},
		{titleKey:"ro.components.name.QBTakePhoto"},
		{titleKey:"ro.components.name.QBShortAnswer"},
		{titleKey:"ro.components.name.QBLongQuestion"},
		{titleKey:"ro.components.name.QBFillingBlank"},
		{titleKey:"ro.components.name.QBTrueFalse"},
		{titleKey:"ro.components.name.QBToggleOptions"},
		{titleKey:"ro.components.name.QBDragLine"},
		{titleKey:"ro.components.name.DragDropA"},
		{titleKey:"ro.components.name.DragDropC"}
	];
	public componentList2:any = [
		{titleKey:"ro.components.name.TLFText"},
		{titleKey:"ro.components.name.Image"},
		{titleKey:"ro.components.name.Sound"}
	];

	public getLocaleText:any = (params:any) => {
		// aggrid 舊版無 params.key 和 params.defaultValue
		return this.translate.instant("agGrid."+params);
	};
	public editWorkSpaceReady:boolean = false;
	public menu:string = 'basic';
	public currentClipboardData:any[];
	public isPropertyHide = false;

	constructor(
		public assService:AssessmentService,
		private sans: DomSanitizer, 
		protected subjectService:SubjectService,
		public translate:TranslateService, 
		public datas:DataService,
		public coms:CommonService,
		private ags:AgGridService,
		private alertService:AlertService,
		private uls:UploadService,
		public themeService:ThemeService,
		public fileio:FileIOService,
		private route:ActivatedRoute,
		private eleRef:ElementRef,
		protected changeDetectorRef:ChangeDetectorRef) {

		themeService.applyStyleObj({
			"default": {
				"aggrid-headerbgcolor": "#EDEDED",
				"aggrid-headertextcolor": "#000",
				"aggrid-textcolor": "#000",
				"aggrid-linecolor": "#e2e2e2",
				"aggrid-bordercolor": "#e2e2e2",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"ag-foreground-color": "#999999",
				"ag-checkbox-checked-color":"var(--section-maincolor)",
				//"aggrid-filtericoncolor":"var(--section-maincolor)"
				"edit-formBackground": "color-mix(in srgb, var(--section-maincolor) 5%, transparent)",
				"qSide-background": "#fff",
				"panel-background": "var(--section-popupbgcolor)",
				"text-color": "#000",
				"property-bgcolor": "#255612",
				"property-header-bgcolor": "#255612",
				"property-lv1-bgcolor": "#3C8420",
				"property-lv2-bgcolor": "#244F13",
				"property-lv3-bgcolor":  "#18350D",
				"property-lv4-bgcolor":  "#0C1A06",
				"property-selected-color":  "#F9A503",
				"property-button-color":  "#F9A503",
				"subtext-color": "#F9A503"
			},
			"dark": {
				"aggrid-headerbgcolor": "#121219",
				"aggrid-headertextcolor": "#A0A0A0",
				"aggrid-textcolor": "#ffffff",
				"aggrid-linecolor": "#53536A",
				"aggrid-bordercolor": "#53536A",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"ag-foreground-color": "#999999",
				"ag-checkbox-checked-color":"var(--section-maincolor)",
				//"aggrid-filtericoncolor":"var(--section-maincolor)"
				"edit-formBackground": "#2D2C43",
			}
		}, eleRef.nativeElement);
		this.themeService.getThemeJson("okaPulldownModule.json");
	
		this.notSameSchool = (params) => {
			return (this.datas.userInfo && params.data.school_id!=this.datas.userInfo.school_id)
				|| !this.permission ;
		};
		this.linkedQuestionRule = (params) => {
			if(this.isQuestionBank())
				return false;
			let field:any = params.colDef.field;
			//return params.data && params.data.qid && params.data.qid!=0 && 
			//	field!='questionIndex' && field!='fullScore' && field!='learningObjective' && field!='lpf' && field!='scoringType';
			return params.data && params.data.qid && params.data.qid!=0 && 
				field!='questionIndex' && field!='fullScore' && field!='level' && field!='scoringType';
		};
		this.editableCSS = (params) => {
			if(!params.data)
				return false;
			
			let field:any = params.colDef.field;
			if(field == 'asset') {
				if(AssessmentViewerComponent.isPageQuestionComponent(params.data.type))
					return false;

				return !this.linkedQuestionRule(params);
			}
				
		
			if((field == 'opt1' || field == 'opt2' || field == 'opt3' || field == 'opt4' || field == 'totalOptions' || field == 'answer') 
				&& ['QBTakePhoto', 'QBRecorder', 'QBShortAnswer', 'QBLongQuestion', 'QBFillingBlank', 'QBToggleOptions','QBInfoBlock',
				'QBDragLine','DragDropA','DragDropC'].indexOf(params.data.type)>=0)
				return false;
			if(params.data.type == 'QBTrueFalse' && (field == 'opt1' || field == 'opt2' || field == 'opt3' || field == 'opt4' || field == 'totalOptions'))
				return false;
			
			if(["opt1_asset","opt2_asset","opt3_asset","opt4_asset"].indexOf(field)>=0) {
				if(['QBTextMC','QBGraphicMC','QBVoiceMC'].indexOf(params.data.type)>=0) {
					if(field == 'opt3_asset')
						return parseInt(params.data.totalOptions)>=3;
					if(field == 'opt4_asset')
						return parseInt(params.data.totalOptions)>=4;
					return true;
				}
				return false;
			}
		
			if(field == 'multiSelect')
				return params.data && ['QBTextMC','QBGraphicMC','QBVoiceMC'].indexOf(params.data.type)>=0;
			
			if(field == 'opt3')
				return parseInt(params.data.totalOptions)>=3;
			if(field == 'opt4')
				return parseInt(params.data.totalOptions)>=4;
			return true;
		};
		this.editable = (params) => {
			if(!this.permission)
				return false;
			
			if(!params.data || !params.data[this.detectNew] )
				return false;
			
			if(this.isQuestionBank()) {
				if(this.notSameSchool(params) || params.data.public == "2")
					return false;
			} else {
				let field:any = params.colDef.field;
				if(params.data.qid && params.data.qid!=0)
					//return (field == 'learningObjective' || field == 'lpf' || field == 'fullScore' || field == 'scoringType');
					return (field == 'level' || field == 'fullScore' || field == 'scoringType');
			}
		
			return this.editableCSS(params);
		};
		
		this.cellClassRules = {
			'editDisable': (params) => {
				return !this.editableCSS(params);
			},
			'asset': (params) => {
				if(!this.editableCSS(params))
					return false;
				return [/*"asset",*/"opt1_asset","opt2_asset","opt3_asset","opt4_asset"].indexOf(params.colDef.field)>=0;
			},
			'notSameSchool':(params) => {
				if(!this.isQuestionBank())
					return false;
				return this.notSameSchool(params);
			},
			'linkedQuestion': this.linkedQuestionRule
		};
		
		this.route.params.subscribe((params) => {
			this.datas.lang = params['lang'];
			this.datas.appId = params['appId'];

			
		});


		var currentZIndex:number = TopLayer.getNextIndex();
		var list:string[] = ["basic", "popup-button", "popup-note","popup", "ui", "button",  "dragging-item", "verify",  "keyboard", "top"];
		var dom:HTMLElement = this.eleRef.nativeElement;
		list.forEach((name:string, index:number)=>{
			StyleUtils.setStyleVariabe(dom, name + "-z-index", index + currentZIndex);
		});
	}

	public focusIn(): void {

		this.changeDetectorRef.reattach();
	}
	public focusOut(): void {
		this.changeDetectorRef.detach();
	}
	
	public getText(key:string):string {
		return this.translate.instant("QBEditor."+key);
	}

	public getStyleValue(key:string):any {
		return this.themeService.getStyleValue(key, this.eleRef.nativeElement);
	}

	public isQuestionBank():boolean {
		return this.mode == "questionBank";
	}

	ngOnInit() {
		this.detectNew = this.isQuestionBank() ? "qid" : "douid";
		this.buildAgGridOptions();

		// init panel value
		this.assService.initPanelValue();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.permission) {
			if(this.agGridOptions && this.agGridOptions.api)
				this.agGridOptions.api.refreshCells({force:true});
		}
		if (changes.parent) {
			this.themeService.reloadSetting()
		}
	}

	ngAfterViewChecked() {
		this.changeDetectorRef.detectChanges();
	}
	
	// =======================================
	// ag grid function
	// =======================================
	public getDataPath: GetDataPath = (data: any) => {
		console.log("getDataPath >>>>>>>>>>", data.douid);

		//return ["root",data.qid];
		if(data.qid=="13096F81-3EA0-7C8E-4056-4ACEFD928EAC")
			return [data.douid];
		return ["13096F81-3EA0-7C8E-4056-4ACEFD928EAC", data.douid];
	};
	public autoGroupColumnDef:any = {
		rowDrag: false,
		headerName: '',
		width: 100,
		cellRendererParams: {
			suppressCount: true,
			innerRenderer: params => {
				const eDiv = document.createElement('div');
				eDiv.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M64 32c17.7 0 32 14.3 32 32V416H288c17.7 0 32 14.3 32 32s-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32z"/></svg>';
				return eDiv;
			}
		},
		suppressColumnsToolPanel:true,
		lockPosition: 'left', editable:false, resizable: false,pinned: 'left', lockPinned:true, 
		suppressSizeToFit: true,filter:null
	};
	private buildAgGridOptions():void {
		this.gridColumns = [
			{
				width: 70, field:"questionIndex", headerName:"",suppressColumnsToolPanel:true,
				lockPosition: 'left', editable:false, resizable: false,pinned: 'left', lockPinned:true, 
				cellEditorParams:{data_type: "questionIndex"}
				,filter:null // hide filter
			},
			{
				width: 35, headerName:"",suppressColumnsToolPanel:true,
				lockPosition: 'left', editable:false, resizable: false,pinned: 'left', lockPinned:true, 
				suppressSizeToFit: true,
				cellRendererFramework: CheckBoxRenderer, 
				cellRendererParams: {},
				headerComponent: 'checkBoxHeader',
				checkboxSelection: (params)=>{
					params.node.selectable = params.data && params.data[this.detectNew]!=null;
					return false; // 舊的 UI 唔顯示
				}
				,filter:null // hide filter
				,showDisabledCheckboxes: true
			},
			{
				width: 150, field:"type",
				cellEditorParams:{
					data_type: "options",
					items:[
						'QBInfoBlock',
						'QBTextMC','QBRecorder','QBTakePhoto', 'QBShortAnswer', 'QBLongQuestion', 'QBFillingBlank', 'QBToggleOptions',
						'QBDragLine','DragDropA','DragDropC']
					/*
					OKDDragItem 自由拖放
					OKDDragDropA 一對一
					OKDDragDropB 拖放重用
					OKDDragDropc 拖放指定分組
					OKDDragDropD 拖放互換分組
					OKDDragDropE 拖放排序
					OKDDragDropF 拖放計數
					drag-a		drop-A
					drag-b		drop-b
					可重複？
					可放置的目標
					核對方法
					*/
				}, editable:false
			},
			{field:"learningObjective", cellEditorParams:{multiRecords:true, data_type: "learningObjective"}},
//			{field:"lpf", cellEditorParams:{multiRecords:true, data_type: "lpf"}},
			{field:"src",cellEditorParams:{data_type: "src"}, editable:false, 
				suppressColumnsToolPanel:this.isQuestionBank(),
				hide:this.isQuestionBank()},
			{
				width: 150, field:"subject",
				cellEditorParams:{data_type: "subject", multiRecords:true}
			},
			{width: 150, field:"question", cellEditorParams:{data_type: "multiline",maxLength: 5000}},
			{field:"asset", cellEditorParams:{data_type: "multiFile", multiAssets:true}},
			{width: 80, field:"multiSelect", 
				cellEditorParams:{
					data_type: "options",
					items:['0','1']
				}
			},
			{field:"totalOptions",
				cellEditorParams:{
					data_type: "options",
					items:['2','3','4']
				}
			},
			{field:"opt1", cellEditorParams:{data_type: "string"}},
			{field:"opt1_asset", cellEditorParams:{data_type: "multiFile", multiAssets:true}},
			{field:"opt2", cellEditorParams:{data_type: "string"}},
			{field:"opt2_asset", cellEditorParams:{data_type: "multiFile", multiAssets:true}},
			{field:"opt3", cellEditorParams:{data_type: "string"}},
			{field:"opt3_asset", cellEditorParams:{data_type: "multiFile", multiAssets:true}},
			{field:"opt4", cellEditorParams:{data_type: "string"}},
			{field:"opt4_asset", cellEditorParams:{data_type: "multiFile", multiAssets:true}},
			{field:"answer", cellEditorParams:{data_type: "options"}},
			{field:"fullScore", cellEditorParams:{data_type: "float"}},
			{field:"scoringType", cellEditorParams:{data_type: "options", items:[1,2]}},
			{field:"grade", 
				cellEditorParams:{
					data_type: "options",
					items:['none',
						'p1','p2','p3','p4','p5','p6',
						's1','s2','s3','s4','s5','s6'
					]
				}
			},
			{field:"level", cellEditorParams:{data_type: "options", items:['0','1','2']}}, // 難易度
			{field:"public", cellEditorParams:{data_type: "options", items: ['0', '1']},
				suppressColumnsToolPanel:!this.isQuestionBank(),
				editable: params => params.data.public == "1"
			},
			{field:"active", cellEditorParams:{data_type: "options", items:['1','2','0']},
				suppressColumnsToolPanel:!this.isQuestionBank(),
				hide:!this.isQuestionBank()},
			{field:"school_id", cellEditorParams:{data_type: "school"}, editable:false
				, hide:!this.isQuestionBank()},
			{field:"createBy", cellEditorParams:{data_type: "user"}, editable:false
				, hide:!this.isQuestionBank()},
			{field:"createDate", cellEditorParams:{data_type: "date"}, editable:false, hide:true},
			{field:"lastModifyBy", cellEditorParams:{data_type: "user"}, editable:false, hide:true},
			{field:"modifyDate", cellEditorParams:{data_type: "date"}, editable:false, hide:true},
			// delete button
			{
				hide:this.isQuestionBank(),
				width:30, headerName:"",suppressColumnsToolPanel:true,
				lockPosition: 'right', editable:false, resizable: false,pinned: 'right', lockPinned:true, 
				cellClass: 'iconBtn',
				suppressSizeToFit: true,
				cellRendererFramework:IconBtnRenderer,
				cellRendererParams: {
					iconType: 'fa',
					icon: faTrash,
					iconStyle: {},
					hideIf: (params)=>params.data && !params.data.douid,
					onClick: (params)=>{
						this.deleteRows([params.data]);
					}
				}
				,filter:null // hide filter
			},
			{field:"remark", hide:!this.isQuestionBank(), cellEditorParams:{data_type: "string"}},
			{field:"analysisBar", editable:false, hide:!this.isQuestionBank(), width:120, suppressColumnsToolPanel:true,
				cellRenderer: params => {
					const eDiv = document.createElement('div');
					if(params.data[this.detectNew]!=null) {
						let green = 0;
						let orange = 0;
						let red = 0;
						let blue = 0;

						if(params.data.analysisBar) {
							let total = parseInt(params.data.analysisBar.green) + parseInt(params.data.analysisBar.orange) + 
								parseInt(params.data.analysisBar.red) +  parseInt(params.data.analysisBar.blue);
							green = 100*params.data.analysisBar.green / total;
							orange = 100*params.data.analysisBar.orange / total;
							red = 100*params.data.analysisBar.red / total;
							blue = 100*params.data.analysisBar.blue / total;
						}
						eDiv.innerHTML = '<div style="width:100px;height:16px;margin-top:7px;background-color:#c2c2c2;display:flex;flex-direction: row;">'+
							'<div style="width:'+green+'%;height:100%;background-color:#7fc42d;"></div>'+
							'<div style="width:'+orange+'%;height:100%;background-color:#ffa801;"></div>'+
							'<div style="width:'+red+'%;height:100%;background-color:#fa6565;"></div>'+
							'<div style="width:'+blue+'%;height:100%;background-color:#0cb8e2;"></div>'+
							'</div>';
					}
					return eDiv;
				}
				,filter:null // hide filter
			}
		];
		
		
		this.agGridOptions = {
			rowDragManaged: true,
			animateRows: true,
			rowSelection: 'multiple',
			singleClickEdit: true,
			suppressRowTransform: true,
			suppressRowClickSelection: true,
			stopEditingWhenCellsLoseFocus: true,
			suppressDragLeaveHidesColumns: true,
			headerHeight: 30,
			rowHeight: 30,
			defaultColDef: {
				editable: true,
				resizable: true,
				suppressMenu: true,
				width: 100,
				floatingFilter: true,
				enableRowGroup:true,
				filter:'agTextColumnFilter',
			},
			
			/*onRowDragMove: (params)=>{
				console.log("onRowDragMove");
				if(!this.isQuestionBank()) {
					// 更改次序
					let movingNode:any = params.node;
					let overNode:any = params.overNode;
					// 要不是 dummy 行先可交換位置
					let rowNeedsToMove:boolean = movingNode !== overNode;// && overNode && overNode.data.douid!=null;

					if(rowNeedsToMove)
					{
						//let fromIndex:number = movingNode.rowIndex;//movingNode.data.questionIndex;
						//let toIndex:number = overNode.rowIndex;//overNode.data.questionIndex;
						let fromIndex:number = movingNode.data._rowID;
						let toIndex:number = overNode.data._rowID;

						let newStore:any = this.records.slice();
						let element:any = newStore[fromIndex];
						newStore.splice(fromIndex, 1);
						newStore.splice(toIndex, 0, element);
						this.records = newStore;

						params.api!.setRowData(newStore);
						params.api!.clearFocusedCell();
						this.updateDataSequence();
					}
					
				}
			},*/
			onRowDragEnd: (params)=>{
				console.log('onRowDragEnd');
				if(!this.isQuestionBank()) {
					// 更改次序
					var fromIndex = this.records.indexOf(params.node.data);
					var toIndex = params.overIndex;
					if(fromIndex!=toIndex) {
						// 只改 data list 次序
						this.records.splice(fromIndex, 1);
						this.records.splice(toIndex, 0, params.node.data);
						this.updateDataSequence();
						this.refreshCells();
					}
				}
			},

			/*onFilterChanged(params) {
				console.log('onFilterChanged');
				console.log( params.api.filterManager);
				console.log( params.api.filterManager.allFilters);
				console.log(params.api.getFilterInstance('subject'));
				//console.log(params.api.getFilterInstance('subject').getModel().values[]);
			},*/

			columnDefs: (()=>{
				// 根據每個類型做特定設定
				return this.gridColumns.map((column)=>this.buildAgGridColDef(column));
			})(),
			frameworkComponents: {
				checkBoxHeader:CheckBoxHeader,
				checkBoxRenderer: CheckBoxRenderer,
				iconBtnRenderer:IconBtnRenderer,
				popupSelectRenderer:PopupSelectRenderer,
				okaPulldown2Editor:OkaPulldown2Editor,
				noRowsOverlay: NoRowsOverlay,
				qbSetFilter: QBSetFilterComponent
			},
			noRowsOverlayComponent: 'noRowsOverlay',
			noRowsOverlayComponentParams: { text: 'QBEditor.no_question' },
			sideBar: {
				toolPanels: [
					// side menu tab panel
					{
						id: 'columns',
						//labelDefault: this.getText('columns'),
						labelKey: 'columns',
						iconKey: 'columns',
						toolPanel: 'agColumnsToolPanel',
						toolPanelParams: {
							suppressRowGroups: true,
							suppressValues: true,
							suppressPivots: true,
							suppressPivotMode: true,
							suppressColumnFilter: true,
							suppressColumnSelectAll: true,
							suppressColumnExpandAll: true
						}
					}
				]
			},
			onCellValueChanged: (event:CellValueChangedEvent) => {
				//console.log(">> onCellValueChanged", event);

				// 取得有多少答案
				if(event.data.type=="QBInfoBlock") {
					event.data.fullScore = event.data.scoreN = event.data.unitScore = 0;
				} else {
					let totalAns:number = 0;
					if(["QBFillingBlank","QBToggleOptions"].indexOf(event.data.type)>=0) {
						let text:string = event.data.question.replaceAll("\n","<br/>");
						let re = /(\[[^\]]+\])/ig;
						let ary:any;
						while ((ary = re.exec(text)) !== null)
							totalAns++;
					} else if(event.data.type=='QBTextMC')
						totalAns = event.data.answer.split(",").length;
					else
						totalAns = 1;
					event.data.scoreN = Math.max(1, totalAns);
					event.data.unitScore = event.data.scoringType == 1 ? event.data.fullScore : event.data.fullScore / totalAns;

				}
				
				// 試題庫要即時 DB 處理
				if(this.isQuestionBank())
					this.updateRecord(event.data);
				else {
					event.data._.dirty = true;
					this.updateDisplayValues(this.records);
				}
					
			},
			onSelectionChanged: (event: any) => {
				event.api.refreshHeader();
				event.api.refreshCells();
				let rowNodes: RowNode[] = event.api.getSelectedNodes();
				this.selectedItems = rowNodes.map((rowNode: RowNode) => rowNode.data);
				this.onSelectionChanged.emit(this.selectedItems);
			},
			isRowSelectable: (params) => {
				// 試題庫時，公開題目或相同學校
				// 試卷時，就不需要禁止選擇
				return this.isQuestionBank() ? (parseInt(params.data.public)==1 || !this.notSameSchool(params)) : true;
			},
		}
		if(this.agGridOptions) {
			this.agGridOptions.onGridReady = (event) => {
				this.addBottomLine();
			};
			
		}
	}

	public updateDataSequence(change:boolean = true) {
		let num:number = 0;
		for(let i:number=0; i<this.records.length; i++) {
			this.records[i]._rowID = i;
			if(this.records[i].type == "QBInfoBlock")
				this.records[i].questionIndex = 0;
			else {
				this.records[i].questionIndex = num;
				num++;
			}
		}
		
		this.refreshCells(); // 唔refresh ，有時 row Index 會錯
		if(change)
			this.dispatchDataChange();
	}

	public componentPropertyChange(itm:any):void {
		ObjectUtils.setObjectValueByPath(this.focusEdit, itm.valName, itm.value);
		this.assService.saveComponentPanelValueName(this.focusEdit);
		this.dispatchDataChange(this.focusEdit);
	}

	public dispatchDataChange(record = null):void {
		if(this.uiView == "form") {
			if(this.focusEdit && this.focusEdit.hasOwnProperty("_"))
				this.focusEdit._.dirty = true;
		}

		this.state = "have change";
		// update the hints
		const currentOKDQB = this.questionSheet.find(e => {
			return this.isQuestionBank() 
				? e.qObj.qid === this.focusEdit.qid 
				: e.qObj.douid === this.focusEdit.douid;
		})
		if (currentOKDQB) {
			currentOKDQB.getHints()
		}
		this.onDataChanged.emit();

		if (this.isQuestionBank() && record) {
			this.updateRecord(record);
		}
	}

	// 多選
	public onToggleMultiSelect(input) {
		input.multiSelect = (input.multiSelect == 1 ? 0 : 1)
		if (input.multiSelect == 0) {
			input.scoringType = 1
		}
		this.dispatchDataChange()
	}

	// 可以細項計分
	public isAllowMultiScoring(input) {
		if (input.type == 'QBTextMC') {
			if (input.multiSelect == 1) return true
			return false
		}
		
		// 短答只限關鍵字
		if (input.type == 'QBShortAnswer' && input.context.type && input.context.type == 'keyword') {
			return true
		}

		if (["QBDragLine", "DragDropA", "DragDropC", "QBToggleOptions"].includes(input.type)) {
			return true
		}
		return false
	}

	// 隱藏細項分數
	public isHidingUnitScore(input) {
		if (input.type == 'QBTextMC') {
			if (input.multiSelect == 1 && input.scoringType == 2) return false
			return true
		}
		
		// 短答只限關鍵字
		if (input.type == 'QBShortAnswer' && input.context.type && input.context.type == 'keyword' 
			&& input.scoringType == 2) {
			return false
		}

		if (["QBDragLine", "DragDropA", "DragDropC", "QBToggleOptions"].includes(input.type) && input.scoringType == 2) {
			return false
		}
		return true
	}

	public onToggleMultiScoring(input) {
		input.scoringType = (input.scoringType == 2 ? 1 : 2)
		let totalAns:number = 0;
		// auto count the full score when allow multi scoring
		if (input.scoringType == 2) {
			if (["QBDragLine", "DragDropA", "DragDropC", "QBTextMC"].includes(input.type)) {
				totalAns = input.totalOptions
			} else if (input.type == 'QBShortAnswer') {
				const parts = input.answer.split(",")
				totalAns = parts.length
			} else if (input.type == 'QBToggleOptions') {
				let text:string = input.question.replaceAll("\n","<br/>").replace(/<\/?p>/g, '')
					.replace(/<\/?span[^>]*>/g, '');
				const matches = text.match(/\[(.*?)\]/);
				if (matches) {
					const options = matches[1].split('/');
					totalAns = options.length;
				}
			}
			let scoreN = Math.max(1, totalAns);
			input.fullScore = scoreN * input.unitScore
		}
		this.dispatchDataChange()
	}

	// 當細項計分更新，總分都要更新
	public onUpdateUnitScore(input) {
		let totalAns:number = 0;
		if (["QBDragLine", "DragDropA", "DragDropC", "QBTextMC"].includes(input.type)) {
			totalAns = input.totalOptions
		} else if (input.type == 'QBShortAnswer') {
			const parts = input.answer.split(",")
			totalAns = parts.length
		} else if (input.type == 'QBToggleOptions') {
			let text:string = input.question.replaceAll("\n","<br/>").replace(/<\/?p>/g, '')
				.replace(/<\/?span[^>]*>/g, '');
			const matches = text.match(/\[(.*?)\]/);
			if (matches) {
				const options = matches[1].split('/');
				totalAns = options.length;
			}
		}
		let scoreN = Math.max(1, totalAns);
		input.fullScore = scoreN * input.unitScore
		this.dispatchDataChange(input)
	}

	public createGUID():string {
		return GUID.create("OKD guid");
	}

	// 定義 column 的設定及處理
	private buildAgGridColDef(columnObj:any):any {
		//預定設定
		let colDef:any = columnObj;
		if(!colDef.hasOwnProperty('headerName'))
			colDef.headerName = this.getText("option."+columnObj.field);
		colDef.suppressSizeToFit = true;

		if(!colDef.cellEditorParams)
		{
			return colDef;
		}
		if(!colDef.hasOwnProperty('editable'))
			colDef.editable = this.editable;
		colDef.cellClassRules = this.cellClassRules;
		
		let dataType:string = colDef.cellEditorParams.data_type;

		colDef.valueSetter = (params) => {
			// grid cell 更改內容處理
			if (params.newValue || params.newValue == "") {
				if(params.colDef.field)
					params.data[params.colDef.field] = 
						params.colDef.cellEditorParams.typeConvert ? params.colDef.cellEditorParams.typeConvert(params.newValue) : params.newValue;
				
				if(params.data[this.detectNew]==null)
				{
					// 新加資料填補資料
					if(this.isQuestionBank()) {
						// 試題庫要即時處理
						params.data.qid = this.createGUID();
						this.addRecord(params.data).then((res:any)=>{
							if(res.code==0) {
//								params.data.qid = res.qid;
								params.node.setData(params.data);
								this.updateDataSequence(true);
							}
						});

					} else {
						// 試卷
						params.data.douid = this.createGUID();
						params.node.setData(params.data);
						this.updateDataSequence(true);
					}

				}

				this.dispatchDataChange();
			}
			return true;
		};
	
		//跟據column的data_type設定editor及參數
		if (dataType == 'multiline') {
			colDef.cellEditorSelector = (params)=>{
				if (AssessmentViewerComponent.isPageQuestionComponent(params.data.type)) {
					this.changeFocusEdit(params.data);
					this.editRAWData(params.data);
					return null;
				}
				return {component: 'agLargeTextCellEditor'};
			};

			colDef.valueGetter =  (params) => {
				return params.data[params.colDef.field].replace(/<\/?[^>]+(>|$)/g, "");
			}

			colDef.valueFormatter = (params)=>{
				if(!params.data || !params.data[this.detectNew] || !this.editableCSS(params))
					return ""; // 在新行時隱藏
				if(AssessmentViewerComponent.isPageQuestionComponent(params.data.type))
					return this.translate.instant("QBEditor.editq");
				return params.data[params.colDef.field].replace(/<\/?[^>]+(>|$)/g, "");
			};
		} else if (dataType == 'string' || dataType == 'multiline') {
			colDef.cellEditor = dataType == 'string' ? 'agTextCellEditor' : 'agLargeTextCellEditor';
			colDef.valueGetter =  (params) => {
				if (params.data[params.colDef.field]) {
					return params.data[params.colDef.field].replace(/<\/?[^>]+(>|$)/g, "");
				}
				return params.data[params.colDef.field]
			}
			colDef.valueFormatter = (params)=>{
				if(!params.data || !params.data[this.detectNew] || !this.editableCSS(params) || !params.data.hasOwnProperty(params.colDef.field))
					return ""; // 在新行時隱藏
				return params.data[params.colDef.field].replace(/<\/?[^>]+(>|$)/g, "");
			};

		} else if (dataType == 'float') {
			colDef.cellEditorParams.typeConvert = parseFloat;
			colDef.valueFormatter = (params)=>{
				return !params.data || params.data[this.detectNew] == null ? "" : params.data[params.colDef.field];
			};

		} else if (dataType == 'date') {
			colDef.valueFormatter = (params)=>{
				if(!params.data)
					return '';
				let str:any = params.data[params.colDef.field];
				return str && params.data[this.detectNew] != null ? str.replaceAll('-', '/') :'';
			};

		} else if (dataType == 'user') {
			colDef.valueFormatter = (params)=>{
				return !params.data || params.data[params.colDef.field]==0 ? "" : this.getUserName(params.data[params.colDef.field]);
			};
			colDef.filter='agSetColumnFilter';
			colDef.filterParams = {
				valueFormatter:(params)=>{
					return this.getUserName(params.value);
				}
			};

		} else if (dataType == 'school') {
			colDef.valueFormatter = (params)=>{
				return !params.data || params.data[params.colDef.field]==0 ? "" : this.getSchoolName(params.data[params.colDef.field]);
			};
			colDef.filter='agSetColumnFilter';
			colDef.filterParams = {
				valueFormatter:(params)=>{
					return this.getSchoolName(params.value);
				}
			};

		} else if (dataType == 'src') {
			colDef.valueGetter = (params)=>{
				//console.log(params);
				if(params.data && params.data[this.detectNew])
				{
					if(params.data.copyFromQB && params.data.copyFromQB!=0)
						return "src_link";
					else if(params.data.src && params.data.src=="new")
						return "src_new";
					else
						return "src_myschool";
				}
				return null;
			};
			colDef.valueFormatter = (params)=>{
				if(params && params.value)
					return this.getText(params.value);
				return null;
			};
			colDef.filter='agSetColumnFilter';
			colDef.filterParams = {
				valueFormatter:(params)=>{
					if(params && params.value)
						return this.getText(params.value);
					return null;
				}
			};

		} else if (dataType == 'questionIndex') {
			// 更改預設顯示值
			if(!this.isQuestionBank()) {
				colDef.rowDrag = (params)=>{
					return params.data && params.data[this.detectNew]!=null;
				};
				colDef.valueGetter = (params)=>{
					if(params.data && params.data.douid) {
						let rowIndex:number = this.records.indexOf(params.data)+1;
						return rowIndex;
					}
					return this.getText("addRecord"); // 新行顯示
				};
			}
			colDef.cellRenderer = params => {
				const eDiv = document.createElement('div');
				if(params.data && params.data[this.detectNew]) {
					eDiv.innerHTML = params.rowIndex+1;
					
				} else {
					eDiv.style.position = 'absolute';
					eDiv.style.left = '10px';
					eDiv.style.top = '0';
					eDiv.style.color = "#999999";
					eDiv.innerHTML = (this.permission && this.permission.add) ? this.getText("addRecord") : "";
				}
				
				return eDiv;
			};
			colDef.colSpan = params => {
				return params.data && params.data[this.detectNew] ? 1 : 50;
			};


		} else if (dataType == 'subject') {
			colDef.valueFormatter = (params)=>{
				//console.log("valueFormatter update>>>", params.data[params.colDef.field]);
				if(!params.data)
					return "";
				return params.data[params.colDef.field].map(e=> e=="" ? "" : this.getSubjectText(this.getSubjectData(e))).join(", ");
			};
			colDef.filter='agSetColumnFilter';
			colDef.filterParams = {
				valueGetter:(params)=>{
					return params.data && params.data.subject && params.data.subject.length>0 ? 
						params.data.subject : [''];
				},
				valueFormatter:(params)=>{
					return params.value ? this.getSubjectText(this.getSubjectData(params.value)) : null;
				}
				
			};
			colDef.keyCreator = (params)=>{
				return params.value.map(e=> {
					let parts = e.split("-");
					if(parts[0]==0)
						return e;
					return parts[0]+"-0";
				});
			};
			colDef.cellEditorFramework = PopupSelectRenderer;
			colDef.cellEditorParams.openSelector = (cell, popupLayer, data):Promise<any>=>{
				this.currentQuestionObj = null;
				// 將 id 格式轉換成 object  [id,id,...] => [{id:id}, {id:id}, ...]
				this.subjectSelectorModelData = data.map(e=>{return{id:e}});
				this.subjectSelector.allowForAll = false;
				return this.subjectSelector.open(cell, popupLayer).then((o:any)=>{
					// 將 object 格式轉換成 id
					return Promise.resolve(o.map(e=>e.id));
				}).catch((reason:any) =>{
					return Promise.reject(reason);
				});
			};

		} else if(dataType == "learningObjective") {
			colDef.cellEditorFramework = PopupSelectRenderer;
			
			colDef.cellRenderer = params => {
				const eDiv = document.createElement('div');
				if(params.data && params.data[this.detectNew]) {
					var value = params.data[params.colDef.field];
					this.subjectService.getLearningObjectiveNames(value).then((name:string)=>{
						eDiv.innerText = name.trim();
					});
				}
				return eDiv;
			};

			colDef.filter = this.isQuestionBank() ? "qbSetFilter" : 'agSetColumnFilter';
			colDef.filterParams = {
				valueFormatter:(params)=>{
					return params.value ? this.subjectService.getLearningObjectiveNameFromCache(params.value) : null;
				},
				applyMiniFilterWhileTyping: false,
				gridFilterChange: (params) => {
					if (params.isAll) {
						params.api.setRowData(this.records)
					} else {
						if (params.filterValue.length > 0) {
							const {srcFilter, subjectFilter, gradefilter, activeFilter} = this.fetchOptions
							return this.loadColumnRecords(srcFilter, subjectFilter, gradefilter, activeFilter, params.filterValue).then((res) => {
								params.api.setRowData(res)
							})
						} else {
							params.api.setRowData([])
						}
					}

				}
			};
			if (this.isQuestionBank()) {
				colDef.filterParams.fetchOptions = () => this.fetchOptions
				colDef.filterParams.values = (params) => {
					const {srcFilter, subjectFilter, gradefilter, activeFilter} = this.fetchOptions
					return this.loadColumnOptions("lo", srcFilter, subjectFilter, gradefilter, activeFilter).then((res:any) => {
						// supply values to the set filter
						res.push(null)
						params.success(res);
						return res
					})
				}
			} else {
				colDef.filterParams.valueGetter = (params)=>{
					return params.data && params.data.learningObjective && params.data.learningObjective.length>0 ? 
						params.data.learningObjective : [''];
				}
			}
			
			colDef.cellEditorParams.openSelector = (cell, popupLayer, data):Promise<any>=>{
				this.learningObjectiveSelector.selectedItems = data;
				this.learningObjectiveSelector.returnFormat = (pt:any)=>{
					return `${pt.assessment_form_id}-${pt.point_id}`;
				};
				return this.learningObjectiveSelector.open(cell, popupLayer);
			};

		} else if(dataType == "lpf") {
			colDef.cellEditorFramework = PopupSelectRenderer;
			
			colDef.cellRenderer = params => {
				const eDiv = document.createElement('div');
				if(params.data && params.data[this.detectNew]) {
					var value = params.data[params.colDef.field];
					this.subjectService.getLearningObjectiveNames(value).then((name:string)=>{
						eDiv.innerText = name.trim();
					});
				}
				return eDiv;
			};

			colDef.filter='agSetColumnFilter';
			colDef.filterParams = {
				valueGetter:(params)=>{
					return params.data && params.data.lpf && params.data.lpf.length>0 ? 
						params.data.lpf : [''];
				},
				valueFormatter:(params)=>{
					return params.value ? this.subjectService.getLearningObjectiveNameFromCache(params.value) : null;
				}
			};
			colDef.cellEditorParams.openSelector = (cell, popupLayer, data):Promise<any>=>{
				this.lpfSelector.selectedItems = data;
				return this.lpfSelector.open(cell, popupLayer);
			};

		} else if (dataType == 'options') {
			colDef.cellEditorFramework = OkaPulldown2Editor;
			

			if(colDef.field == "answer") {
				colDef.cellEditorParams = (params) => {
					if(params.data.type == 'QBTrueFalse')
					{
						return {data_type: "options", items:[
							{value:1, label:this.getText("option."+colDef.field+"_opt.true")},
							{value:0, label:this.getText("option."+colDef.field+"_opt.false")}
						]};
					}

					let items:any = ["1","2","3","4"];

					if(params.data.multiSelect == '1') {
						items = items.concat(["1,2","1,3","1,4","2,3","2,4","3,4"]);
						if(parseInt(params.data.totalOptions) >2)
							items = items.concat(["1,2,3","1,2,4","1,3,4","2,3,4"]);
						if(parseInt(params.data.totalOptions) >3)
							items.push("1,2,3,4");
					}
/*
					// 1 options
					let items:any = ["1","2","3","4","5","6"];

					if(params.data.multiSelect == '1') {
						// 2 options
						items = items.concat(["1,2","1,3","1,4","1,5","1,6","2,3","2,4","2,5","2,6","3,4","3,5","3,6","4,5","4,6","5,6"]);
						// 3 options
						if(parseInt(params.data.totalOptions) >2)
							items = items.concat(["1,2,3","1,2,4","1,3,4","2,3,4"]);
						// 4 options
						if(parseInt(params.data.totalOptions) >3)
							items.push("1,2,3,4");
						// 5 options
						if(parseInt(params.data.totalOptions) >4)
							items.push("1,2,3,4,5");
						// 6 options
						if(parseInt(params.data.totalOptions) >5)
							items.push("1,2,3,4,5,6");
					}*/
					items = items.map(e => {
						return {value:e, label:this.getText("option."+colDef.field+"_opt."+e)};
					});
					return {data_type: "options", items:items};
				};

			} else if(colDef.field == "active") {
				colDef.cellEditorParams = (params) => {
					let items:any = ["1","2","0"];

					var val:number = parseInt(params.data.active);
					if(val==1) {
						// active
						if(!this.permission.del)
							items = items.filter(e => e!="0");
						if(!this.permission.archive)
							items = items.filter(e => e!="2");
					} else if(val==2) {
						// archive
						if(!this.permission.del)
							items = items.filter(e => e!="0");
					} else if(val==0) {
						// deleted
						if(!this.permission.archive)
							items = items.filter(e => e!="2");
					}
					items = items.map(e => {
						return {value:e, label:this.getText("option."+colDef.field+"_opt."+e)};
					});
					return {data_type: "options", items:items};
				};

			} else {
				colDef.cellEditorParams.items = colDef.cellEditorParams.items.map(e => {
					return {value:e, label:this.getText("option."+colDef.field+"_opt."+e)};
				});
				if(colDef.field == "type")
					this.typeList = colDef.cellEditorParams.items;
				else if(colDef.field == "grade")
					this.gradeList = colDef.cellEditorParams.items;
				else if(colDef.field == "level")
					this.levelList = colDef.cellEditorParams.items;
				
			}
			
			colDef.valueFormatter = (params)=>{
				if(!params.data || !params.data[this.detectNew] || !this.editableCSS(params))
					return ""; // 在新行時隱藏
				let key:any = params.value ? params.value : params.data[params.colDef.field];
				if(params.data.type == 'QBTrueFalse' && colDef.field=="answer")
					return this.getText("option."+params.colDef.field+"_opt."+(key==0 ? "false" : "true"));
				return this.getText("option."+params.colDef.field+"_opt."+key);
			};

			colDef.filter='agSetColumnFilter';
			if(colDef.cellEditorParams /*&& colDef.cellEditorParams.items*/) {
				colDef.filterParams = {
					//values:colDef.cellEditorParams.items.map(e => e.label),
					suppressSorting:true,
					valueFormatter:(params)=>{
						let key:any = params.value ? params.value : params.data[params.colDef.field];
						return this.getText("option."+params.colDef.field+"_opt."+key);
					}
				};
			}
					
		} else if (dataType == 'multiFile') {
			// media resource path:
			// resource/2022/07/10/uid(533)/time(143221)-cs(BC6056F5).asset
			colDef.valueGetter = (params)=>{ //需要有valueGetter，setQuickFilter才可以search到檔名
				if(!params.data)
					return "";
				let jsonObj:any = params.data[params.colDef.field];
				return (jsonObj && jsonObj.files) ? jsonObj.files.map((file:any)=>file.name).join('') : '';
			};
			colDef.cellRendererFramework = FileFormatterComponent;
			colDef.cellRendererParams  = (params)=>{
				if(!params.data)
					return null;

				let jsonObj:any = params.data[params.colDef.field];
				if(!jsonObj || !jsonObj.files)
					jsonObj = {files:[]};
				params.data[params.colDef.field] = jsonObj;
				let cellRendererParams = {
					sectionColor:this.getStyleValue("section-maincolor"),
					hideIf: (params)=>{
						if(!params.data[this.detectNew] || // 在新行時隱藏
							['QBTakePhoto', 'QBRecorder', 'QBShortAnswer', 'QBLongQuestion', 'QBFillingBlank', 'QBToggleOptions',
							'QBInfoBlock'].indexOf(params.data.type)>=0)
						// 在新行時隱藏
						if(!params.data[this.detectNew])
							return true;
						return false;
					},
					jsonObj:jsonObj,
					onFileAdd: (params)=>this.onFileAdd(params, jsonObj),
					allowPreview: (params, fileObj:any)=>{
						return !(!fileObj || !fileObj.name);
					},
					onFilePreview: (params, fileObj:any, fileObjList:any)=>{
						this.preview.setUrlPrefix(this.fileio.getResourceServer(fileObj.url));
						this.preview.setFile(fileObj);
						this.preview.setFileList(fileObjList);
						let setBtns:Function = (fileObj:any):void => {
							if(params.data.copyFromQB && params.data.copyFromQB!=0) {
								// linked question
								this.preview.setBtns([
									{ title:this.translate.instant('profile.download'), icon:'assets/img/preview/btnicon_download_white.svg', callback:()=>cellRendererParams.onFileDownload(params, fileObj) }
								]);
							}
							else
							{
								this.preview.setBtns([
									{ title:this.translate.instant('profile.replace'), icon:'assets/img/preview/btnicon_upload_white.svg', callback:()=>cellRendererParams.onFileReplace(params, fileObj) },
									{ title:this.translate.instant('profile.download'), icon:'assets/img/preview/btnicon_download_white.svg', callback:()=>cellRendererParams.onFileDownload(params, fileObj) },
									{ title:this.translate.instant('profile.delete'), icon:'assets/img/preview/btnicon_trash_white.svg', callback:()=>{cellRendererParams.onFileDelete(params, fileObj); this.preview.close();} }
								]);
							}
						}
						setBtns(fileObj);
						this.preview.onFileChanged = (fileObj:any)=>setBtns(fileObj);
						this.preview.open();
					},
					onFileReplace: (params, fileObj:any)=>this.onFileReplace(params, fileObj),
					onFileDownload: (params:any, fileObj:any)=>{
						let host:string = this.fileio.getResourceServer(fileObj.url);
						this.datas.get2({
							data:{ api: 'ROMediaLibrary.getFile', json: JSON.stringify([ encodeURI(host + fileObj.url) ]) },
							option: { headers: new HttpHeaders(), responseType: 'blob', },
						}).then((res:any)=>downloadjs(res, fileObj.name));
					},
					onFileDelete: (params:any, fileObj:any)=>this.onFileDelete(params, jsonObj, fileObj),
					onSoundRecord:(params:any, fileObj:any)=>this.onSoundRecord(params, jsonObj, fileObj)
				};
				return cellRendererParams;
			};
		}

		return colDef;
	}

	public addBottomLine():void {
		if(this.agGridOptions && this.agGridOptions.api) {
			var tmp = this.getDummyRecord();
			tmp.school_id = 0;
			tmp.createBy = 0;
			tmp.lastModifyBy = 0;
			this.agGridOptions.api.setPinnedBottomRowData([tmp]);
		}
	}

	public deselectAll():void {
		if(this.agGridOptions && this.agGridOptions.api) {
			this.agGridOptions.api.deselectAll();
			this.agGridOptions.api.refreshCells();
		}

		console.log("deselectAll");
		this.selectedItems = [];
		this.onSelectionChanged.emit(this.selectedItems);
	}
		
	getDummyRecord() {
		let now:string = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
		let record:any = {
			type:"QBTextMC",
			question:"",totalOptions:"2",opt1:"",opt2:"",opt3:"",opt4:"",multiSelect:"0",answer:"1",
			asset:{files:[]}, opt1_asset:{files:[]}, opt2_asset:{files:[]}, opt3_asset:{files:[]}, opt4_asset:{files:[]}, 
			public:"0", active:"1", 

			createBy:this.datas.userInfo.uid,
			createDate:now,
			lastModifyBy:this.datas.userInfo.uid,
			modifyDate:now,

			school_id:this.datas.userInfo.school_id,
			copyFromQB:0,
			copyFromAssessment:0,
			subject:[],
			
			//// linked question 可修改項目
			hasScoring:"true",scoringType:1,scoreN:1,unitScore:1,fullScore:1,
			learningObjective:[],lpf:[],grade:"none",level:"0", remark:""
		};
		
		if(this.isQuestionBank()) {
			record.qid = null;
		} else {
			record.src = "new";
			record.douid = null;
			record.questionIndex = this.records.length;
		}
		return record;
	}

	// =======================================
	// asset function
	// =======================================
	protected markDeleteFile(url:string):void {
		if(url.indexOf("tmp_upload/")!=0)
			this.delFiles.push(url);
	}

	private handleUploadErr(err):void {
		if (err.msg=='cancel-by-close-window') {
			return; //用戶取消
		} else {
			let translateParams:any = {};
			if (err.msg=='file-size-exist') translateParams.maxFileSizeInMb = Math.round(err.size/(1024*1024));
			this.alertService.alert2(this.translate.instant('profile.upload_' + err.msg, translateParams), null, { btns: [['ok']] }, this.eleRef.nativeElement);
		}
	}
	
	private selectAssetFile():Promise<any> {
		return this.selectFile(null);
	}
	
	private selectFile(type:string):Promise<any> {
		let fileOption:any = {toMediaServer:null};
		if(type=="Image") {
			fileOption.size = 50*1024*1024;
			fileOption.fileType = ".jpg,.jpeg,.gif,.png";
		} else if(type=="Sound") {
			fileOption.size = 50*1024*1024;
			fileOption.fileType = ".mp3,.m4a";
		} else if(type=="video") {
			fileOption.size = 200*1024*1024;
			fileOption.fileType = ".mov,.mp4,.m4v";
		} else {
			fileOption.size = 50*1024*1024;
			fileOption.fileType = ".jpg,.jpeg,.gif,.png,.mp3,.m4a,.mov,.mp4,.m4v";
		}

		return this.uls.upload(fileOption);
	}

	public fileProcess():Promise<any> {
		if((this.tmpFiles.length>0 || this.delFiles.length>0)) {
			let files:any[] = this.tmpFiles.map(itm => itm.url);

			return this.datas.post2({
				data: { 
					api:'QuestionBank.moveAndRemoveToMediaServer', 
					json:["resource", files, this.delFiles]
				}, loading:true
			}).then((res:any)=>{
				if (res && res.code==0) {
					this.tmpFiles.forEach(itm => {
						let move:any = res.movedFiles.find(m => m.from == itm.url);
						if(move) {
							itm.url = move.to;
						}
					});

					this.tmpFiles = [];
					this.delFiles = [];
					this.refreshCells();
					return res.movedFiles;
				}

				return false;
			});
		}

		return new Promise((resolve, reject)=>{
			this.refreshCells();
			resolve([]);
		});
	}

	protected refreshCells():void {
		if(this.agGridOptions && this.agGridOptions.api)
			this.agGridOptions.api.refreshCells();
	}

	private fileNeedProcess(rowdata:any):Promise<any> {
		this.dispatchDataChange();
		
		// 檢查是否要即時處理 file 改變
		if(this.isQuestionBank()) {
			// 試題庫要即時處理
			return this.fileProcess().then((res:any)=>{
				if(res) {
					this.updateRecord(rowdata);
					this.updateROPageResources(res);
				}
				
			});
		}
		
		// 試卷在儲存時處理
		return new Promise((resolve, reject)=>{
			this.refreshCells();
			resolve(true);
		});
	}

	// =======================================
	// data convert function
	// =======================================
	public updateDisplayValues(ary:any, forceUpdate:boolean = false):void {
		if(!this.pls_select)
			this.pls_select = this.translate.instant("workspace.pls-select");
		ary.forEach((e,index) => {
			if(!e.hasOwnProperty("_") || !e._.hasOwnProperty("dirty") || e._.dirty || forceUpdate) {
				e._ = {
					dirty:false,
					schoolName:this.getSchoolName(e.school_id),
					type:this.translate.instant('ro.components.name.'+e.type),
					level:this.findOptionLabel('level', e.level),
					grade:this.findOptionLabel('grade', e.grade),
					analystPie:this.drawAnalystPie(e),
					subject:e.subject.length==0 ? `${this.pls_select}...` : e.subject.map(e=> e=="" || e==null ? "" : this.getSubjectText(this.getSubjectData(e))).join(", "),
					learningObjective: e.hasOwnProperty("_") && e._.hasOwnProperty("learningObjective") ? e._.learningObjective : this.pls_select,
					lpf: e.hasOwnProperty("_") && e._.hasOwnProperty("lpf") ? e._.lpf : this.pls_select,
					displayObjective: e.hasOwnProperty("_") && e._.hasOwnProperty("displayObjective") ? e._.displayObjective : []
				};
				if(!e.learningObjective || e.learningObjective.length==0)
					e._.learningObjective = this.pls_select;
				else
					this.subjectService.getLearningObjectiveNames(e.learningObjective).then((name:string)=>{
						e._.learningObjective = name.trim();
					});
				if(!e.lpf || e.lpf.length==0)
					e._.lpf = this.pls_select;
				else
					this.subjectService.getLearningObjectiveNames(e.lpf).then((name:string)=>{
						e._.lpf = name.trim();
					});
				
				// handle lpf and learning Objective with the new panel property
				const lo = (e.learningObjective && e.learningObjective.length > 0) ? e.learningObjective : []
				const lpf = (e.lpf && e.lpf.length > 0) ? e.lpf : []
				if (lo || lpf) {
					this.getLearningObjectiveDetails(lo, lpf).then(res => {
						e._.displayObjective = res
					})
				}
			}
			this.assService.saveComponentPanelValueName(e);
		});
	}

	// for get the details for 
	public getLearningObjectiveDetails(lo, lpf) {
		const points:any = [...lo, ...lpf].map(obj => ({"learning_objective": obj}))
		return new Promise (resolve => {
			this.datas.post("ROResultPanel.get_point_details_for_objectiveManagerModal", [points, true]).subscribe((res: any) => {
				const groupedResults: any = {};
				points.forEach(pt => {
					const found = res.find(e => e.learning_objective == pt.learning_objective)
					if (found){
						pt.title = found.title;
						pt.form_id = found.form_id;
						pt.form_name = found.form_name || this.translate.instant('workspace.custom-node');
						pt.rootName = found.rootName || this.translate.instant('workspace.custom-node');
					} else if (pt.id.indexOf('custom|') === 0){
						pt.title = pt.id.split('|')[1];
						pt.form_id = 'custom';
						pt.form_name = this.translate.instant('workspace.custom-node');
						pt.rootName = pt.title;
					}
					pt.type = found.type; 

					if (!groupedResults[pt.form_id]) {
						const type = pt.type == "LO" ?  this.translate.instant('common.school') : pt.type
						const form_name = pt.form_name.includes(this.translate.instant('workspace.custom-node')) ? pt.form_name :`${pt.form_name} (${type})`
						groupedResults[pt.form_id] = {
							rootName: form_name,
							type: pt.type,
							objectives: []
						};
					}

					groupedResults[pt.form_id].objectives.push({
						learning_objective: pt.learning_objective,
						title: pt.title,
						form_id: pt.form_id,
						form_name: pt.form_name,
						rootName: pt.rootName,
						type: pt.type
					});
				})

				// Convert the grouped object back to an array
				const resultArray = Object.keys(groupedResults).map(key => ({
					form_id: key,
					...groupedResults[key]
				}));
				resolve(resultArray)
			})
		})
	}

	// check if the display Objective has valid form title
	public hasFormTitle(objective) {
		return objective.objectives.some(point => point.title)
	}

	public getSubjectData(subjectID:string):any {
		if(!this.subjectService.subjects)
			return null;
		let subject_parts:any = subjectID.split("-");
		let subObj:any = null;
		if(subject_parts[0]==0)
			subObj = this.subjectService.subjects.find(e=> e.school_subject_id == subject_parts[1]); // use school subject id
		else
			subObj = this.subjectService.subjects.find(e=> e.public_subject_id == subject_parts[0]); // use public subject id
		return subObj;
	}

	public getSubjectText(subjectObj:any):string {
		if(subjectObj)
		{
			if(subjectObj.level)
				return this.translate.instant('workspace.subject-level-'+subjectObj.level)
					+ "(" + this.translate.instant('workspace.subject-type-'+subjectObj.type)
					+ ") - "+subjectObj.title;

			return subjectObj.title;
		}
		return this.getText("school_subject");
	}

	public getSchoolName(school_id:number):string {
		let ret:any = this.schools ? this.schools.find(e => e.school_id==school_id) : null;
		return ret ? ret.title : "";
	}

	protected getUserName(uid:number):string {
		let ret:any = this.users ? this.users.find(e => e.uid==uid) : null;
		return !ret ? "" :
			(this.translate.currentLang == "tc" || this.translate.currentLang == "sc") ? 
			ret.c_last_name+ret.c_first_name : ret.first_name+" "+ret.last_name;
	}
		
	/*
	短答題 shortanswer / longquestion3	
	- 問題
	- 參考答案
	- 底線/顏色/透明
	- 完全一致/關鍵字/手寫(短)/手寫(長)/自由/數字/數字(完全一樣)/數式
	- 行數

	填充題 fillingblank
	- 問題混合答案 [/]
	- 底線/顏色/透明
	- 文字/手寫(短)/手寫(長)/數字/數字(完全一樣)/數式

	文字切換題 toggleoptions2
	- 橫/直
	- 預設/透明
	- 問題混合答案 [#/]
	/\[(.*?)\]/g
	*/
	gridRecordToDBRecord(gRecord:any):any {
		let record:any = {content:{}};
		Object.keys(gRecord).forEach(key => {
			if(key != "_") { // 暫存值不用複制
				if(key == "qid" || key == "subject" || key == "level" || key == "active" || key == "grade" || key == "public" ||  key == "type" || key == "remark" || key == "approval") {
					// 以上 column 就是 record 用
					record[key] = gRecord[key];
					if(key == "subject")
						record[key] = Array.isArray(gRecord[key]) ? gRecord[key].join(",") : gRecord[key];
					else
						record[key] = gRecord[key];
				}
				
				if(!(key == "qid" ||  key == "school_id" || 
					key == "createBy" || key == "createDate" || key == "lastModifyBy" || key == "modifyDate" || key == "remark"))
				{
					// 不是以上 column 就是 question 用
					record.content[key] = gRecord[key];
				}
			}
		});
		return record;
	}

	// QB version 2
	createBookStructure(bookName:string, pid:string, questions:any, reference:any = null):any
	{
		let p_list:any = [];
		questions.forEach((com,index) => {
			let type = com.copyFromQB!=0 ? reference[index].type : com.type;
			if(type!="QBInfoBlock") {
				if(!com.hasOwnProperty("lpf"))
					com.lpf = [];
				if(!com.hasOwnProperty("learningObjective"))
					com.learningObjective = [];
				pid = "P"+com.douid;
				let obj:any ={
					////q:JSON.parse(com.getAttribute("q")),
					////s:JSON.parse(com.getAttribute("s")),
					qInfo:{level:0,rootIndex:0,root:0, id:com.questionIndex,pid:0,index:com.questionIndex,order:0},
					score: {
						type:com.scoringType.toString(),
						n:parseInt(com.scoreN),
						unit:parseInt(com.unitScore),
						full:parseInt(com.fullScore)
					},

					cid:com.douid,
					name:(parseInt(com.questionIndex)+1).toString(), // 正式要計出來
					page:pid,
					order:parseInt(com.questionIndex),
					tag:type,
					learningObjective:com.learningObjective.concat(com.lpf),
					type:type,
					////ce:com.getAttribute("coordinateExpression"),
					pageNumber:1,
					hasScoring:com.hasScoring
				};
				if (obj.score.full == 0)
					obj.score.full = 1;
				////if(com.hasAttribute("questionLabelTitle"))
				////	obj.name = com.getAttribute("questionLabelTitle");
				p_list.push({
					pageNumber:p_list.length+1,
					id:pid,
					title:"",
					components:[obj]
				});
			}
		});

		return {
			version:"0.0.1", // book structure version
			book:{
				title:bookName,
				chapters:[{
					// chapter 0
					pageCount:p_list.length,
					title:"",
					pages:p_list
				}]
			}
		};
	}

	/*
	// QB version 1
	createBookStructure_old(bookName:string, pid:string, questions:any, reference:any = null):any
	{
		let qs_list:any = [];
		questions.forEach((com,index) => {
			let type = com.copyFromQB!=0 ? reference[index].type : com.type;
			if(type!="QBInfoBlock") {
				if(!com.hasOwnProperty("lpf"))
					com.lpf = [];
				let obj:any ={
					////q:JSON.parse(com.getAttribute("q")),
					////s:JSON.parse(com.getAttribute("s")),
					qInfo:{level:0,rootIndex:0,root:0, id:com.questionIndex,pid:0,index:com.questionIndex,order:0},
					score: {
						type:com.scoringType.toString(),
						n:parseInt(com.scoreN),
						unit:parseInt(com.unitScore),
						full:parseInt(com.fullScore)
					},

					cid:com.douid,
					name:(parseInt(com.questionIndex)+1).toString(), // 正式要計出來
					page:pid,
					order:parseInt(com.questionIndex),
					tag:type,
					learningObjective:com.learningObjective.concat(com.lpf),
					type:type,
					////ce:com.getAttribute("coordinateExpression"),
					pageNumber:1,
					hasScoring:com.hasScoring
				};
				if (obj.score.full == 0)
					obj.score.full = 1;
				////if(com.hasAttribute("questionLabelTitle"))
				////	obj.name = com.getAttribute("questionLabelTitle");
				qs_list.push(obj);

			}
		});

		return {
			version:"0.0.1", // book structure version
			book:{
				title:bookName,
				chapters:[{
					// chapter 0
					pageCount:1,
					title:"",
					pages:[{
						// only one page
						pageNumber:1,
						id:pid,
						title:"",
						components:qs_list
					}]
				}]
			}
		};
	}
	*/

	// =======================================
	// data function (試題庫功能)
	// =======================================
	getAnalysis() {
		let qids:any = [];
		this.records.forEach(e => {
			if(e.qid)
				qids.push(e.qid);
		});
		if (this.records.length > 0) {
			this.handleWhiteSpace(this.records[0])
			this.focusEdit = this.records[0]
		}
		if(qids.length>0) {
			this.datas.post2({
				data:{
					api:"QuestionBank.getAnalysisResult", 
					json:[qids]
				},
				loading: true
			}).then((res:any)=>{
				if(res.code==0) {
					res.data.forEach(d => {
						let r:any = this.records.find(e => e.qid==d.qid);
						if(r)
							r.analysisBar = d;
					});
					
					if(this.uiView == "grid")
						this.agGridOptions.api.redrawRows();

					this.updateDisplayValues(this.records, true);
				}
			});
		}
	}

	loadQBRecords(srcFilter:any, subjectFilter:any, gradefilter:any, activeFilter:number) {
		this.activeFilter = activeFilter;

		this.deselectAll();

		this.datas.post2({
			data:{
				/*api:'QuestionBank.service',
				json:[
					{api:"check_login"},
					{api:"fetch", json:[this.selectedSrc.value, this.subjectFilter.subjects, this.selectedGrade.value, this.selectedActive.value]}
				]
				*/
				api:"QuestionBank.fetch", 
				json:[srcFilter, subjectFilter, gradefilter, activeFilter, new Date().getTimezoneOffset()]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				let rows:any = [];
				let learningObjective:any[] =[];

				res.data.forEach(ele => {
					try{
						let d:any = JSON.parse(ele.content);
						d.qid = ele.qid;
						d.school_id = ele.school_id;
						d.createBy = ele.createBy;
						d.createDate = ele.createDate;
						d.lastModifyBy = ele.lastModifyBy;
						d.modifyDate = ele.modifyDate;
						d.approval = ele.approval;
						d.subject = ele.subject ? ele.subject.split(",") : [];
						d.remark = ele.remark;
						if(!d.hasOwnProperty("lpf"))
							d.lpf = [];
						rows.push(d);
						learningObjective = learningObjective.concat(d.learningObjective, d.lpf);
					} catch(e) {
						debugger;
					}
					
				});

				//console.log("learningObjective",learningObjective);
				this.subjectService.getLearningObjectiveNames(ArrayUtils.unique(learningObjective));

				res.users.forEach(row => {
					if(!this.users.find(e => e.uid == row.uid)) {
						this.users.push(row);
					}
				});
				res.schools.forEach(row => {
					if(!this.schools.find(e => e.school_id == row.school_id)) {
						this.schools.push(row);
					}
				});
				this.records = rows;
				this.updateDataSequence(false);
				this.updateDisplayValues(this.records, true)
			} else {
				// 載入失敗
			}

		});
	}

	loadColumnOptions(column:any, srcFilter:any, subjectFilter:any, gradefilter:any, activeFilter:number) {
		this.activeFilter = activeFilter;

		return this.datas.post2({
			data:{
				api:"QuestionBank.getColumnOptions", 
				json:[column, srcFilter, subjectFilter, gradefilter, activeFilter, new Date().getTimezoneOffset()]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				if (column == "lo") {
					this.subjectService.getLearningObjectiveNames(ArrayUtils.unique(res.data));
				}
				return res.data
			} else {
				// 載入失敗
			}
		});
	}

	loadColumnRecords(srcFilter:any, subjectFilter:any, gradefilter:any, activeFilter:number, loList:any = null) {
		this.activeFilter = activeFilter;

		return this.datas.post2({
			data:{
				api:"QuestionBank.fetch", 
				json:[srcFilter, subjectFilter, gradefilter, activeFilter, new Date().getTimezoneOffset(), loList]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				let rows:any = [];
				let learningObjective:any[] =[];

				res.data.forEach(ele => {
					try{
						let d:any = JSON.parse(ele.content);
						d.qid = ele.qid;
						d.school_id = ele.school_id;
						d.createBy = ele.createBy;
						d.createDate = ele.createDate;
						d.lastModifyBy = ele.lastModifyBy;
						d.modifyDate = ele.modifyDate;
						d.approval = ele.approval;
						d.subject = ele.subject ? ele.subject.split(",") : [];
						d.remark = ele.remark;
						if(!d.hasOwnProperty("lpf"))
							d.lpf = [];
						rows.push(d);
						learningObjective = learningObjective.concat(d.learningObjective, d.lpf);
					} catch(e) {
						debugger;
					}
					
				});
				this.subjectService.getLearningObjectiveNames(ArrayUtils.unique(learningObjective));
				return rows
			}
		})
	}

	addRecord(record:any):Promise<any> {
		this.deselectAll();
		return this.datas.post2({
			data:{
				api:"QuestionBank.addRecord", 
				json:[this.gridRecordToDBRecord(record)]
			},
			loading: true
		});
	}

	updateRecord(record:any) {
		this.deselectAll();
		if(record.qid && record.qid!=-1)
			this.updateRecords([this.gridRecordToDBRecord(record)]);
	}

	updateRecords(rows:any) {
		this.datas.post2({
			data:{
				api:"QuestionBank.updateRecords", 
				json:[rows]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				this.state = "saved";
				this.updateFilterRecords();
			}
		});
		this.deselectAll();
	}

	saveToTemp() {
		let qids:any = this.selectedItems.filter(e => e.qid!=null).map(e=> e.qid);
		this.datas.post2({
			data:{
				api:"QuestionBank.saveMySelect", 
				json:[qids]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				this.deselectAll();
			}
		});
	}

	removeTempRecord() {
		let qids:any = this.selectedItems.filter(e => e.qid!=null).map(e=> e.qid);
		this.datas.post2({
			data:{
				api:"QuestionBank.removeMySelect", 
				json:[qids]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				this.agGridOptions.api.applyTransaction({remove: this.selectedItems});
				this.deselectAll();
			}
		});
	}

	submitApproval(rowQid?: string) {
		let qid:any;
		if (!rowQid) {
			qid = this.selectedItems.filter(e => e.qid!=null).map(e=> e.qid);
		} else {
			qid = [rowQid]
		}
		return this.datas.post2({
			data: {
				api: "QuestionBank.submitApproval",
				json: [qid]
			},
			loading: true
		})
	}

	cancelApproval() {
		let qid:any = this.selectedItems.filter(e => e.qid!=null).map(e=> e.qid);
		return this.datas.post2({
			data: {
				api: "QuestionBank.cancelApproval",
				json: [qid]
			},
			loading: true
		})
	}

	changeApprovalState(state:string) {
		let qid:any = this.selectedItems.filter(e => e.qid!=null).map(e=> e.qid);
		return this.datas.post2({
			data: {
				api: "QuestionBank.updateApprovalState",
				json: [qid, state]
			},
			loading: true
		})
	}

	quickSearch(str:string) {
		if (this.agGridOptions && this.agGridOptions.api) this.agGridOptions.api.setQuickFilter(str);
		this.deselectAll();
	}

	protected updateFilterRecords():void {
		if(this.agGridOptions.api) {
			let removeRows:any = [];
			this.agGridOptions.api.forEachNode((rowNode:any, index: number) => {
				if((this.activeFilter == -1 && parseInt(rowNode.data.active)==0) ||
					(this.activeFilter != -1 && this.activeFilter!=parseInt(rowNode.data.active))) {
					removeRows.push(rowNode.data);
				}
			});

			if(removeRows.length>0) {
				this.agGridOptions.api.applyTransaction({remove: removeRows});
				for(let i:number = this.records.length-1; i>=0; i--) {
					let e:any = this.records[i];
					if(e.qid) {
						if((this.activeFilter == -1 && parseInt(e.active)==0) ||
							(this.activeFilter != -1 && this.activeFilter!=parseInt(e.active))) {
							this.records.splice(i,1);
						}
					}
				}

				this.agGridOptions.api.redrawRows();
			}
		}
	}

	// =======================================
	// data function (試卷功能)
	// =======================================
	copyToQB(assessmentID:number):Promise<any> {
		return new Promise((resolve, reject) => {
			let str:string = this.translate.instant(this.state == "have change" ? "QBEditor.CFM.linkQB_autosave" : "QBEditor.CFM.linkQB_autosave2");

			this.alertService.alert2(str, 
				{}, { btns: [['ok', 'cancel']] }, 
				this.eleRef.nativeElement).then((key:string)=>{
				if (key=='ok') {
					this._copyToQB(assessmentID).then(success => {
						resolve(success);
					}, reason =>{
						reject(reason);
					});
				} else {
					reject("cancel");
				}
			});
		});
	}

	protected _copyToQB(assessmentID:number):Promise<any> {
		let _records:any = [];
		let _qid:string;
		this.selectedItems.map(e => {
			let r:any = this.gridRecordToDBRecord(e);
			r.active = 1;
			r.content.active = 1;
			r.content.copyFromAssessment = assessmentID;
			//delete r.content.douid; // douid 要保留，因要對返邊題
//			r.qid = null;
			r.qid = r.content.douid;
			_qid = r.content.douid;
			if(r.hasOwnProperty("remark"))
				r.remark = "";
			_records.push(r);
		});

//		console.log(_records);

		return this.datas.post2({
			data:{
				api:"QuestionBank.addRecords", 
				json:[_records]
			},
			loading: true
		}).then((res:any)=>{
			if(res.code==0) {
				if(this.agGridOptions && this.agGridOptions.api) {
					let rowNodes: RowNode[] = this.agGridOptions.api.getSelectedNodes();

					res.data.map(e => {
						let r:any = this.records.find(e2 => e.douid==e2.douid);
						r.copyFromQB = e.qid;
						r.qid = e.qid;
						let n:RowNode = rowNodes.find(e2 => e.douid==e2.data.douid);
						n.setData(r);
					});

				} else {
					res.data.map(e => {
						let r:any = this.records.find(e2 => e.douid==e2.douid);
						r.copyFromQB = e.qid;
						r.qid = e.qid;
					});
				}

				this.deselectAll();
				this.onSelectionChanged.emit(this.selectedItems);
				this.dispatchDataChange();
				return {qid: _qid};
			}
		});
	}

	clone() {

		let srcAssets:any = [];
		
		var addIndex:number = 0;
		this.selectedItems.forEach(e => {
			addIndex = Math.max(this.records.indexOf(e), addIndex);
		});
		addIndex++;
		// copy record
		this.selectedItems.map(e => {
			let now:string = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
			let newRecord:any = JSON.parse(JSON.stringify(e)); // 複製並移除 object 原有關係
			newRecord.createBy = this.datas.userInfo.uid;
			newRecord.createDate = now;
			newRecord.lastModifyBy = this.datas.userInfo.uid;
			newRecord.modifyDate = now;
			newRecord.school_id = this.datas.userInfo.school_id;
			newRecord.douid = this.createGUID();
			newRecord.questionIndex = this.records.length;
			newRecord.public = "0"

//			srcAssets = srcAssets.concat(newRecord.asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt1_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt2_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt3_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt4_asset.files);

        	// Clone assets and ensure they are unique
			newRecord.asset = this.cloneAsset(e.asset);
			newRecord.opt1_asset = this.cloneAsset(e.opt1_asset);
			newRecord.opt2_asset = this.cloneAsset(e.opt2_asset);
			newRecord.opt3_asset = this.cloneAsset(e.opt3_asset);
			newRecord.opt4_asset = this.cloneAsset(e.opt4_asset);

			// asset 不 copy
			// newRecord.asset = {files:[]};
			// newRecord.opt1_asset = {files:[]};
			// newRecord.opt2_asset = {files:[]};
			// newRecord.opt3_asset = {files:[]};
			// newRecord.opt4_asset = {files:[]};

			newRecord.copyFromQB = 0;
			newRecord.copyFromAssessment = 0;
			newRecord.qid = 0;
			newRecord.src = "new";

			delete newRecord.analysisBar;
			delete newRecord._; // 移除暫存資料

			// update grid data
			if(this.uiView == "grid" && this.agGridOptions && this.agGridOptions.api)
				this.agGridOptions.api.applyTransaction({add: [newRecord], addIndex:addIndex});
			// push into list
			//this.records.push(newRecord);
			this.records.splice(addIndex, 0, newRecord);
			addIndex++;
		});

		this.updateDataSequence(true);
		this.deselectAll();
		// focus on the clone item 
		const focusIndex = addIndex - 1 >= 0 ? addIndex - 1 : 0
		this.focusEdit = this.records[focusIndex]
		this.onSelectionChanged.emit(this.selectedItems);
	}

	public toClipboard():void {
		var items:any[] = this.selectedItems.map(e => {
			let now:string = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
			let newRecord:any = JSON.parse(JSON.stringify(e)); // 複製並移除 object 原有關係
			newRecord.createBy = this.datas.userInfo.uid;
			newRecord.createDate = now;
			newRecord.lastModifyBy = this.datas.userInfo.uid;
			newRecord.modifyDate = now;
			newRecord.school_id = this.datas.userInfo.school_id;
			newRecord.douid = this.createGUID();
			newRecord.questionIndex = this.records.length;
			newRecord.public = "0"

//			srcAssets = srcAssets.concat(newRecord.asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt1_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt2_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt3_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt4_asset.files);
			// asset 不 copy
			// newRecord.asset = {files:[]};
			// newRecord.opt1_asset = {files:[]};
			// newRecord.opt2_asset = {files:[]};
			// newRecord.opt3_asset = {files:[]};
			// newRecord.opt4_asset = {files:[]};

			newRecord.copyFromQB = 0;
			newRecord.copyFromAssessment = 0;
			newRecord.qid = 0;
			newRecord.src = "new";

			delete newRecord.analysisBar;
			delete newRecord._; // 移除暫存資料
			delete newRecord._rowID;
			return newRecord;
		});
		var cb:any = navigator.clipboard;
		cb.writeText(JSON.stringify(items));
		this.deselectAll();
	
		// var cb:any = navigator.clipboard;
		// var cbItem:any = window["ClipboardItem"];
		// var blob:Blob = new Blob(["<ro-assessment>"+JSON.stringify("123")+"</ro-assessment>"], {type:"text/html"});
		// cb.write([new cbItem({["text/html"]:blob})]);
	}

	public async toClipboardAsync(): Promise<void> {
		const items = await Promise.all(this.selectedItems.map(async e => {
			let now:string = moment(new Date().getTime()).format('YYYY-MM-DD HH:mm:ss');
			let newRecord:any = JSON.parse(JSON.stringify(e)); // 複製並移除 object 原有關係
			newRecord.createBy = this.datas.userInfo.uid;
			newRecord.createDate = now;
			newRecord.lastModifyBy = this.datas.userInfo.uid;
			newRecord.modifyDate = now;
			newRecord.school_id = this.datas.userInfo.school_id;
			newRecord.douid = this.createGUID();
			newRecord.questionIndex = this.records.length;
			newRecord.public = "0"

//			srcAssets = srcAssets.concat(newRecord.asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt1_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt2_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt3_asset.files);
//			srcAssets = srcAssets.concat(newRecord.opt4_asset.files);

			// Clone assets and ensure they are unique
			newRecord.asset = await this.cloneAssetAsync(newRecord.asset);
			newRecord.opt1_asset = await this.cloneAssetAsync(newRecord.opt1_asset);
			newRecord.opt2_asset = await this.cloneAssetAsync(newRecord.opt2_asset);
			newRecord.opt3_asset = await this.cloneAssetAsync(newRecord.opt3_asset);
			newRecord.opt4_asset = await this.cloneAssetAsync(newRecord.opt4_asset);
			
			newRecord.copyFromQB = 0;
			newRecord.copyFromAssessment = 0;
			newRecord.qid = 0;
			newRecord.src = "new";

			delete newRecord.analysisBar;
			delete newRecord._; // 移除暫存資料
			delete newRecord._rowID;
			return newRecord;
		}));

		if (localStorage) {
			localStorage.setItem('questionBankEditGrid_copyItem', JSON.stringify(items))
		}
		this.deselectAll();
	}

	public getLocalStorageClipboard() {
		this.currentClipboardData = null;
		return new Promise((resolve, reject)=> {
			try {
				const clipboardData = localStorage.getItem('questionBankEditGrid_copyItem')
				if (clipboardData) {
					this.currentClipboardData = JSON.parse(clipboardData);
					resolve(this.currentClipboardData);
				} else {
					reject("no data");
				}
			} catch (e) {
				reject("no data");
			}
		})
	}

	public getClipboardData():Promise<any> {
		this.currentClipboardData = null;
		return new Promise((resolve, reject)=>{
			try {
				var cb:any = navigator.clipboard;
				cb.readText().then(cbItems=> {
					if (cbItems) {
						this.currentClipboardData = JSON.parse(cbItems);
						resolve(this.currentClipboardData);
					} else {
						reject("no data");
					}
				// 	// cbItems[0].getType("text/html").then(blob=> {
				// 	// 	blob.text().then(html=> {
				// 	// 		var matches = html.match(/<ro-assessment[^>]*>(.*?)<\/ro-assessment>/);
				// 	// 		if(matches) {
				// 	// 			// this.currentClipboardData = JSON.parse(jsonString);
				// 	// 			resolve(this.currentClipboardData);
				// 	// 		} else
				// 	// 			reject("no data");
				// 	// 	}, reject);
				// 	// }, reject);
				}, reject);

			} catch(e) {
				reject("no data");
			}
		});
	}

	public async clipboardPaste() {
		if(this.currentClipboardData) {
			const data = await Promise.all(this.currentClipboardData.map(async e => {
				let newRecord = {...e}
				// Clone assets and ensure they are unique
				newRecord.asset = await this.cloneAssetAsync(newRecord.asset);
				newRecord.opt1_asset = await this.cloneAssetAsync(newRecord.opt1_asset);
				newRecord.opt2_asset = await this.cloneAssetAsync(newRecord.opt2_asset);
				newRecord.opt3_asset = await this.cloneAssetAsync(newRecord.opt3_asset);
				newRecord.opt4_asset = await this.cloneAssetAsync(newRecord.opt4_asset);
				return newRecord
			}));
			data.forEach(newRecord => {
				// update grid data
				if(this.uiView == "grid" && this.agGridOptions && this.agGridOptions.api)
					this.agGridOptions.api.applyTransaction({add: [newRecord]});
				// push into list
				const index = this.records.findIndex(ele => 
					this.isQuestionBank() 
					? ele.qObj.qid === this.focusEdit.qid 
					: ele.douid == this.focusEdit.douid)
				this.records.splice(index !== -1 ? index + 1 : 0, 0, newRecord)
			})
			this.updateDataSequence(true);
		}
	}

	deleteSelected() {
		// 刪除記錄
		this.deleteRows(this.selectedItems);
	}

	public deleteRows(rows:any) {
		if(rows.length==1)
			var msg:string = this.translate.instant("QBEditor.CFM.delete_q", {q: this.records.indexOf(rows[0])+1});
		else 
			msg = this.translate.instant("QBEditor.CFM.delete_multiq", {q: rows.length});
		
		this.alertService.alert2(msg, 
			{}, { btns: [['delete', 'cancel']] }, 
			this.eleRef.nativeElement).then((key:string)=>{
			if (key=='delete') {
				let index = null
				rows.forEach(rowData => {
					// 刪除記錄
					// 記錄要刪除的 file
					["asset","opt1_asset","opt2_asset","opt3_asset","opt4_asset"].forEach(e => {
						if(rowData[e])
							rowData[e].files.forEach(fileObj => this.removeAsset(fileObj.url));
					});
					if(this.agGridOptions && this.agGridOptions.api)
						this.agGridOptions.api.applyTransaction({remove: [rowData]});
					for(let i:number = 0; i<this.records.length; i++) {
						if(this.records[i].douid == rowData.douid) {
							if (index === null || index > i) {
								index = i;
							}
							this.records.splice(i,1);
							break;
						}
					}
				});
				this.updateDataSequence();
				// focus at least one question
				this.focusEdit = this.records[index > 0 ? index - 1 : 0];

				if(this.selectedItems && this.selectedItems.length>0)
					this.deselectAll();
			}
		});
	}




	// =======================================
	// function
	// =======================================
	// form view 使用
	public openSubjectSelector(label, qObj):void {
		this.currentQuestionObj = qObj;
		// 轉換做新的格式
		this.subjectSelectorModelData = qObj.subject.map(e=>{return{id:e}});
		this.subjectSelector.allowForAll = false;
		this.subjectSelector.open(label, this.eleRef.nativeElement).then(success=>{
			this.currentQuestionObj.subject = success.map(e => e.id);
			this.dispatchDataChange();
		}).catch(cancel=>{});
	}

	public openLearningObjectivesSelector(label, qObj):void {
		this.currentQuestionObj = qObj;
		this.learningObjectiveSelector.selectedItems = qObj.learningObjective;
		this.learningObjectiveSelector.open(label, this.eleRef.nativeElement).then((learningObjectives:any[])=>{
			this.currentQuestionObj.learningObjective = learningObjectives;
			this.dispatchDataChange();
		}).catch((reason)=>{});
	}

	public openLPFSelector(label, qObj):void {
		this.currentQuestionObj = qObj;
		this.lpfSelector.selectedItems = qObj.lpf;
		this.lpfSelector.open(label, this.eleRef.nativeElement).then((learningObjectives:any[])=>{
			this.currentQuestionObj.lpf = learningObjectives;
			this.dispatchDataChange();
		}).catch((reason)=>{});
	}

	public openLOSelector(label, qObj):void {
		this.currentQuestionObj = qObj;
		const concatItems = qObj.learningObjective.concat(qObj.lpf);
		this.learningObjectiveSelector.selectedItems = concatItems;
		this.learningObjectiveSelector.open(label, this.eleRef.nativeElement).then((learningObjectives:any[])=>{
			this.getLearningObjectiveDetails([], learningObjectives).then((res: any)=> {
				this.currentQuestionObj._.displayObjective = res
				let lpfArray = []
				let loArray = []
				if (res && res.length) {
					res.forEach(objective => {
						if (objective.type == 'LPF') {
							lpfArray = objective.objectives.map(point => point.learning_objective)
						} else {
							loArray = objective.objectives.map(point => point.learning_objective)
						}
					})
				}
				this.currentQuestionObj.lpf = lpfArray;
				this.currentQuestionObj.learningObjective = loArray;
				this.dispatchDataChange(this.currentQuestionObj);
			}).catch()
		}).catch((reason)=>{});
	}

	public onOpenLearningObjectives(data, event) {
		if (this.isQuestionBank()) {
			if (data.public != '2') {
				this.openLOSelector(event.target, data)
			}
		} else {
			this.onLearningObjectSelected.emit(data)
		}
	}

	public updateGridSelected():void {
		if(this.agGridOptions) {
			this.agGridOptions.onGridReady = (event) => {
				this.agGridOptions.onGridReady = null;
				
				this.agGridOptions.api.forEachNode((rowNode:any, index: number) => {
					if(this.selectedItems.find(e=>e == rowNode.data))
						rowNode.setSelected(true);
				});
				this.agGridOptions.api.redrawRows();
			};
			
		}
		
	}

	// =======================================
	// form edit function
	// =======================================
	public drop(event: CdkDragDrop<string[]>) {
		moveItemInArray(this.records, event.previousIndex, event.currentIndex);
		this.updateDataSequence();
	}

	public onDragStart(qObj:any):void {
		this.changeFocusEdit(qObj);
	}

	public findOptionList(key:string):any {
		let opts:any = this.gridColumns.find(e => e.field == key);
		if(!opts)
			return [];
		return opts.cellEditorParams.items
	}

	public findOptionLabel(key:string, value:any):string {
		let opts:any = this.findOptionList(key);
		if(opts.length==0)
			return key;

		let label:any = opts.find(e => e.value == value);
		return label ? this.translate.instant(label.label) : key;
	}

	public drawAnalystPie(qObj:any):SafeStyle {
		//console.log("drawAnalystPie");
		if(!qObj.analysisBar)
			return "";

		var analysisBar = qObj.analysisBar;
		let green = 0;
		let orange = 0;
		let red = 0;
		let blue = 0;
		let total = parseInt(analysisBar.green) + parseInt(analysisBar.orange) + 
			parseInt(analysisBar.red) +  parseInt(analysisBar.blue);
		green = 100*analysisBar.green / total;
		orange = 100*analysisBar.orange / total;
		red = 100*analysisBar.red / total;
		blue = 100*analysisBar.blue / total;

		orange+=green;
		red+=orange;
		blue+=red;

		return this.sans.bypassSecurityTrustStyle(
			"conic-gradient("+
			"#7fc42d 0% "+green+"%,"+
			"#ffa801 "+green+"% "+orange+"%,"+
			"#fa6565 "+orange+"% "+red+"%,"+
			"#0cb8e2 "+red+"%)");
	}

	public qbChange(event):void {
		const lang = this.datas.lang;
		if(event.type == "delMCOption") {
			if(parseInt(event.qObj.totalOptions)==2) {
				this.alertService.alert(this.translate.instant("QBEditor.ERR.optLowerThanLimit"));
			} else {
				// delete assets
				let assets:any = event.qObj["opt"+event.optIndex+"_asset"];
				assets.files.forEach(fileObj => this.removeAsset(fileObj.url));
				assets.files = [];
				// shift data
				for(let i=event.optIndex+1; i<=event.qObj.totalOptions; i++) {
					event.qObj["opt"+(i-1)] = event.qObj["opt"+i];
					event.qObj["opt"+(i-1)+"_asset"] = event.qObj["opt"+i+"_asset"];
				}
				// total option decrease
				event.qObj.totalOptions--;
				this.fileNeedProcess(event.qObj);
			}

		} else if(event.type == "addAsset") {
			this.whitePopup.menuItems = [{
				hideIf: (data:any)=>false, titleKey: {tc:'上載媒體',sc:'上载媒体',en:'Upload media'}[lang],
				click: (data:any)=>this.onFileAdd({data:event.qObj}, event.qObj[event.key])
			},{
				hideIf: (data:any)=>false, titleKey: {tc:'即時錄音',sc:'即时录音',en:'Record audio'}[lang],
				click: (data:any)=>this.onSoundRecord({data:event.qObj}, event.qObj[event.key], null)
			}];
	
			this.whitePopup.open(event.target);

		} else if(event.type == "comMenu") {
			if(event.qObj.copyFromQB!=0)
				return; // linked question
			
			let assetType:string = "QBEditor.img";
			let replaceIcon:any = faImage;

			if(this.fileio.isSound(event.asset.name)) {
				assetType = "QBEditor.snd";
				replaceIcon = faListMusic;
			} else if(this.fileio.isVideo(event.asset.name)) {
				assetType = "QBEditor.mov";
				replaceIcon = faFilm;
			}
			this.comMenu.menuItems = [{
				type:"name", name: assetType
			},{
				type:"btn", icon: replaceIcon, event:event,
				click: (data:any)=>this.onFileReplace({data:event.qObj}, event.asset)
			},{
				type:"btn", icon: faTrash, event:event,
				click: (data:any)=>this.onFileDelete({data:event.qObj}, event.qObj[event.key], event.asset)
			}];

			if (!this.fileio.isSound(event.asset.name) && !this.fileio.isVideo(event.asset.name)) {
				// add the move image btn
				this.comMenu.menuItems.splice(2, 0, {
					type: "btn",
					icon: faCircleArrowRight,
					event: event,
					click: (data: any) => this.onImageMove(data, event.asset, event.key, event)
				});
			}

			this.comMenu.open(event.target, event);

			// {type:"comMenu", qObj:this.qObj, key:key, asset:asset, target:target})
			if(event.target.nodeName == "IMG" || event.target.nodeName == "VIDEO" || event.target.nodeName == "AUDIOPLAYER") {
				this.selectMarker.selectTarget(event);
			}
				

		} else if(event.type == "resize") {
			event.asset.width = event.width;
			event.asset.height = event.height;
			event.target.style.width = event.width+"px";
			event.target.style.height = event.height+"px";
		} else if(event.type == "resizeEnd") {
			this.dispatchDataChange();

		} else if(event.type == "showPageComponentEditPopup") {
			this.editRAWData(event.qObj);

		} else if(event.hasOwnProperty("qObj")) {
			// 題目修改更新
			
			this.dispatchDataChange();
		}
	}

	public onFormSelectionChanged(qObj:any):void {
		if(!this.selectedItems)
			this.selectedItems = [];
		let index:number = this.selectedItems.indexOf(qObj);
		if(index>=0) {
			this.selectedItems.splice(index,1);
		} else {
			this.selectedItems.push(qObj);
		}

		this.onSelectionChanged.emit(this.selectedItems);
	}

	public isFormSelected(qObj:any):boolean {
		return this.selectedItems.indexOf(qObj)>=0;
	}

	public cloneQ(qObj:any):void {
		// 複製一個又不影響現在的選取
		let tmp = this.selectedItems;
		this.selectedItems = [qObj];
		this.clone();
		this.selectedItems = tmp;
		this.onSelectionChanged.emit(this.selectedItems);
	}
	public wrapComponent(douid:string, comXML:string):string
	{
		return ['<Doc>',
			'<Chapter title="">',
			'<Page douid="P'+douid+'" w="1024" h="768" questionCount="0" questionStartIndex="0" broadcast="true" slideTitle="" pageStyle="None" pageFitType="auto" color="#FFFFFF" teacherNote="">',
			comXML,
			'</Page>',
			'</Chapter>',
			'</Doc>'
		].join("");
	}
	public addComponent(type:string):void {
		// 新加資料填補資料
		let newRecord:any = this.getDummyRecord();
		var guid:string = this.createGUID();
		newRecord[this.detectNew] = guid;
		newRecord.type = type;
		this.records.push(newRecord);

		if(AssessmentViewerComponent.isPageQuestionComponent(type)) {
			var comXML:string;
			if(type == "QBDragLine")
				comXML = ROSimpleDragLine.getDefaultXMLString(guid,false);
			else if(type == "DragDropA")
				comXML = RODragDropA.getDefaultXMLString(guid,false);
			else if(type == "DragDropC")
				comXML = RODragDropC.getDefaultXMLString(guid,false);
			else if(type == "QBInfoBlock")
			{
				
			} else if(type == "QBTextMC")
			{
				
			} else if(type == "QBRecorder")
			{
				
			} else if(type == "QBTakePhoto")
			{
				
			} else if(type == "QBShortAnswer")
			{
				
			} else if(type == "QBLongQuestion")
			{
				
			} else if(type == "QBFillingBlank")
			{
				// debugger;
			} else if(type == "QBToggleOptions")
			{
				// debugger;
			} else if(type == "QBFillingBlank")
			{
				// debugger;
			} else if(type == "QBToggleOptions")
			{
				// debugger;
			} else if(type == "QBFillingBlank")
			{
				// debugger;
			} else if(type == "QBInfoBlock")
			{
				// debugger;
			} else if(type == "QBLongQuestion")
			{
				// debugger;
			} else if(type == "QBRecorder")
			{
				// debugger;
			} else if(type == "QBTakePhoto")
			{
				// debugger;
			}
			if(comXML){
				newRecord.rawData = this.wrapComponent(guid, comXML);
			}
			// newRecord.rawData = '<Doc><Chapter title=""><Page douid="P'+guid+'" w="1024" h="768" questionCount="0" questionStartIndex="0" broadcast="true" slideTitle="" pageStyle="None" pageFitType="auto" color="#FFFFFF" teacherNote="">'+
			// 	comXML+'</Page></Chapter></Doc>';
		}

		this.assService.fillComponentDefaultValue(newRecord, true);

		if(this.uiView=="grid") {
			this.agGridOptions.api.applyTransaction({add: [newRecord]});

			if(this.isQuestionBank()) {
				// 試題庫要即時處理
				this.addRecord(newRecord);
			}
		}
		this.updateDataSequence(true);
		
		if(this.uiView=="grid") {
			this.agGridOptions.api.ensureIndexVisible(this.records.length-1);
		} else {
			this.changeFocusEdit(newRecord);
			window.setTimeout(() => {
				var ele = document.getElementsByClassName("formPaper")[0].parentNode as Element;
				console.log(ele.scrollTop, ele.scrollHeight);
				ele.scrollTop = ele.scrollHeight;
			},33);
		}
		
		this.dispatchDataChange();
	}

	protected changeFocusEdit(qObj:any):void {
		this.handleWhiteSpace(qObj)
		this.focusEdit = qObj;
		// panel 資料更新
		if(this.assService.componentsPanel.hasOwnProperty(qObj.type)) {
			var itms:any = this.assService.componentsPanel[qObj.type];
			itms.forEach(itm=>{
				itm.value = ObjectUtils.getObjectValueByPath(qObj, itm.valName);
			});
		}
	}

	public handleWhiteSpace(qObj:any): void {
		// 處理空白鍵問題
		qObj.question = qObj.question.replace(/ /g, '&nbsp;');
	}

	// =======================================
	// asset function
	// =======================================
	protected onFileAdd(params:any, assets:any):void {
		const lang = this.datas.lang;
		// 有設定 toMediaServer，uplod 去 temp 後就會同時移去 media server
		this.selectAssetFile().then((res:any)=>{
			if (res.originFilename && res.url) {
				let fileObj:any = { name:res.originFilename, url: res.url };
				// Validate media type and content
				this.validateMediaFile(fileObj).then(isValid => {
					if (!isValid) {
						const msg = {tc:'上載媒體格式錯誤',sc:'上载媒体格式错误',en:'Upload media format error'}[lang];
						this.alertService.toastError(msg);	
						return
					}
					assets.files.push(fileObj); // update cell record
					this.tmpFiles.push(fileObj); // tmp file move to media server

					if(this.fileio.isGraphic(fileObj.name)) {
						fileObj.width = 0;
						fileObj.height = 0;
					}

					this.fileNeedProcess(params.data); // change marked
				})
			}
		}, (err:any)=>this.handleUploadErr(err));
	}

	// Helper function to validate the media file
	private validateMediaFile(fileObj: any): Promise<boolean> {
		return new Promise((resolve) => {
			const assetUrl = this.fileio.getAssetsUrl(fileObj)
			// Create an image or audio element to validate
			if (this.fileio.isGraphic(fileObj.name)) {
				const img = new Image();
				img.src = assetUrl;
				img.onload = () => resolve(true); // Valid image
				img.onerror = () => resolve(false); // Invalid image
			} else if (this.fileio.isSound(fileObj.name)) {
				const audio = new Audio(assetUrl);
				audio.onloadedmetadata = () => resolve(true); // Valid audio
				audio.onerror = () => resolve(false); // Invalid audio
			} else if (this.fileio.isVideo(fileObj.name)) {
				const video = document.createElement('video');
				video.src = assetUrl;
				video.onloadedmetadata = () => resolve(true); // Valid video
				video.onerror = () => resolve(false); // Invalid video
			} else {
				resolve(false); // Not a valid media type
			}
		});
	}

	protected onFileReplace(params:any, fileObj:any):void {
		this.selectAssetFile().then((res:any)=>{
			if (res.originFilename && res.url) {
				this.markDeleteFile(fileObj.url);
				fileObj.name = res.originFilename;
				fileObj.url = res.url; // tmp path
				this.tmpFiles.push(fileObj); // tmp file move to media server

				if(this.fileio.isGraphic(fileObj.name)) {
					fileObj.width = 0;
					fileObj.height = 0;
				}
					
				this.fileNeedProcess(params.data);
			}
		}, (err:any)=>this.handleUploadErr(err));
	}
	
	protected onFileDelete(params:any, assets:any, fileObj:any):void {
		this.alertService.alert2('profile.cfmDeleteFileMsg', {filename:fileObj.name}, { btns: [['delete', 'cancel']] }, this.eleRef.nativeElement).then((key:string)=>{
			if (key=='delete') {
				this.removeAsset(fileObj.url);
				assets.files = assets.files.filter((file:any)=>file!=fileObj);
				this.fileNeedProcess(params.data);
			}
		});
	}

	protected onImageMove(data, fileObj, key:string, event):void {
		const bubbleBox = this.comMenu.bubbleBox2.contentContainer.nativeElement
		if (bubbleBox) {
			const targetElement = document.querySelector(`.popupBtn`) as HTMLElement;
			this.moveImagePopUp.open(this.records, event.target, data.qObj, fileObj, key, this.isQuestionBank());
		}
	}

	public onCancelMoveImage() {
		this.comMenu.bubbleBox2.close()
	}
	
	public onMoveImageConfirmed(event) {
		const { original, target, asset, originalKey, targetKey } = event
		// remove the image from the original
		original[originalKey].files = original[originalKey].files.filter((file:any)=> file != asset)
		// add the image to the target
		if (!Array.isArray(target[targetKey].files)) {
			target[targetKey].files = [];
		}
		target[targetKey].files.push(asset)
		this.fileNeedProcess(original)
		this.fileNeedProcess(target)
		this.comMenu.bubbleBox2.close()
	}

	// Helper function to clone assets and ensure they are unique
	protected cloneAsset(asset: any): any {
		if (!asset || !asset.files) return { files: [] };

		let newAsset = { files: [] };
		// Clone each file and ensure a new URL or identifier
		asset.files.forEach(file => {
			let newFile = { ...file };
			// get the asset path
			let fileServerPath:string = this.fileio.getAssetsUrl(newFile);
			this.fileio.downloadAsset(fileServerPath).subscribe(blob => {
				let option = { toMediaServer:null }
				// convert the blob to file type
				const file = new File([blob], newFile.name, { type: blob.type });
				// upload file
				this.uls.uploadFileObject(file).then((res: any) => {
					if (res.filename && res.path) {
						let fileObj:any = {...newFile, ...option, name:res.filename, url: res.path };
						newAsset.files.push(fileObj);
						this.tmpFiles.push(fileObj)
					}
				})
			})
		});
		return newAsset;
	}

	protected async cloneAssetAsync(asset: any): Promise<any> {
		if (!asset || !asset.files) return { files: [] };
	
		let newAsset = { files: [] };
		
		// Process each file sequentially
		for (const file of asset.files) {
			try {
				// Get the asset path
				let fileServerPath: string = this.fileio.getAssetsUrl(file);
				
				// Download the asset
				const blob = await this.fileio.downloadAsset(fileServerPath).toPromise();
				
				// Convert blob to file
				const newFile = new File([blob], file.name, { type: blob.type });
				
				// Upload file
				const res = await this.uls.uploadFileObject(newFile);
				
				if (res.filename && res.path) {
					let fileObj: any = {
						...file,
						toMediaServer: null,
						name: res.filename,
						url: res.path
					};
					newAsset.files.push(fileObj);
					this.tmpFiles.push(fileObj);
				}
			} catch (error) {
				console.error('Error cloning asset:', error);
			}
		}
	
		return newAsset;
	}

	protected onSoundRecord(params:any, assets:any, fileObj:any):void {
		this.sndRecorder.lanuch().then((success:any) => {
			this.uls.uploadFileObject(success.file).then((sndUploadSuccess:any) => {
				// /RainbowOne/tmp_upload/xxxxx
				// url 要 tmp_upload/xxxxx
				// success.duration

				if(!fileObj) {
					// new file
					fileObj = { name:"record.mp3", url: sndUploadSuccess.path};
					assets.files.push(fileObj); // update cell record
					this.tmpFiles.push(fileObj); // tmp file move to media server
					this.fileNeedProcess(params.data);

				} else {
					// replace file
					this.markDeleteFile(fileObj.url);
					fileObj.name = "record.mp3";
					fileObj.url = sndUploadSuccess.path; // tmp path
					this.tmpFiles.push(fileObj); // tmp file move to media server
					this.fileNeedProcess(params.data);
				}
			}, (err:any)=>this.handleUploadErr(err));
		});
	}

	protected addAsset(asset:any):void {
		this.tmpFiles.push(asset); // save 時上載
	}
	protected replaceAsset(oldAsset:any, name:string, url:string):any {
		if(oldAsset)
			this.removeAsset(oldAsset.url);
		else
			oldAsset = {};
		oldAsset.name = name;
		oldAsset.url = url;
		this.addAsset(oldAsset);
		return oldAsset;
	}
	protected removeAsset(url:string):void {
		if(url) {
			if(url.indexOf("tmp_upload/")==0)
			　　this.tmpFiles = this.tmpFiles.filter(itm => itm.url!=url); // 將原本將會上載的檔案移除
			else
				this.delFiles.push(url); // save 時移除 server 上的檔案
		}
	}

	// =======================================
	// edit work space function
	// =======================================
	public openAddComponentPopup(event):void {
		if(this.menu!='none')
			this.componentListPopup2.open(event.target);
	}

	public editRAWData(qObj:any):void {
		this.menu = "basic";
		this.editWorkSpaceReady = true;
		window.setTimeout(()=>{
			this.editWorkSpace.editPageFromRAWData("edit", qObj.rawData);
			this.editComponents.open();
		},10);
	}

	public rawEditComplete():void {
		debugger;
		var d = this.editWorkSpace.getRAWData();
		
		let totalAns:number = 0;
		let pc:ROPageComponentContainer = this.editWorkSpace.centerPage.ref.instance;
		// update question total answer
		pc.pageCom.dataComponents.forEach((c:any) => {
			var type:string = this.focusEdit.type;
			if(c instanceof ROSimpleDragLine && type == "QBDragLine") {
				totalAns += (<ROSimpleDragLine>c).lines.length;

			} else if(c instanceof RODragDropA && type == "DragDropA") {
				totalAns += (<RODragDropA>c).lines.length;
				
			} else if(c instanceof RODragDropC && type == "DragDropC") {
				totalAns += (<RODragDropC>c).lines.length;
			}
		});

		this.focusEdit.scoreN = totalAns;
		this.focusEdit.unitScore = this.focusEdit.scoringType == 1 ? this.focusEdit.fullScore : this.focusEdit.fullScore / totalAns;
		this.focusEdit.rawData = d;
		this.editWorkSpaceClose();
		this.dispatchDataChange();
	}

	public editWorkSpaceClose():void {
		this.editWorkSpaceReady = false;
		this.editComponents.close();
	}

	public addComponent2(type:string):void {
		if(type == "Image" || type == "Sound") {
			this.selectFile(type).then((res:any)=>{
				if (res.originFilename && res.url) {
					let asset:any = { name:res.originFilename, url: res.url};
					this.addAsset(asset);
					// 記住 component ，當 save 時更新 path
					asset.com = this.editWorkSpace.editLayer.addComponentByTag(type, res.url);
					this.dispatchDataChange();
				}
			}, (err:any)=>this.handleUploadErr(err));

		} else {
			this.editWorkSpace.editLayer.addComponentByTag(type);
			this.dispatchDataChange();
		}
	}

	public onEditWorkSpaceAction(event:any) {
		if(event.target == "editor" || event.type == "action") {
			var action:string = event.action;
			let com:ROComponent = event.component;

			if(action == "changeImage" || action == "changeSound") {
				this.selectFile(action.substring(6)).then((res:any)=>{
					if (res.originFilename && res.url) {
						let url:string = com.getPropertiesThroughPanel("assetSrc");
						let oldAsset:any = this.tmpFiles.find(itm => itm.url == url);
						this.replaceAsset(oldAsset, res.originFilename, res.url).com = com;
						com.setPropertiesThroughPanel("assetSrc", res.url);
					}
				}, (err:any)=>this.handleUploadErr(err));

			} else if(action == "remove") {
				if(com instanceof ROImageComponent || com instanceof ROSound)
					this.removeAsset(com.getPropertiesThroughPanel("assetSrc"));

			} else if(action == "changeAddComponentMenu") {
				this.menu = event.data;
			}

			this.dispatchDataChange();
		}
	}

	public updateROPageResources(changes:any[]):void {
		console.log("updateROPageResources >>>>>>>>>>>>>>>");
		console.log(changes);
		/// "OKG://id/23119433/checksum/12D040D3/len/97208/token/8B0A0426/type/1/server/1/title/"

		this.records.forEach(itm =>{
			if(AssessmentViewerComponent.isPageQuestionComponent(itm.type)) {
				var rawData:string = itm.rawData;
				changes.forEach(ass =>{
					console.log(ass.from, ">", ass.to);
					rawData = rawData.replace(ass.from, ass.to);
				});
				itm.rawData = rawData;
			}
		});
	}

	// =======================================
	// Quill Editor functions
	// =======================================
	// change text style of each OKDQB
	public openColorPicker(style: "background" | "color") {
		const currentColor = this.getTextColor(style)
		if (style == 'background') {
			this.backgroundColor = currentColor
			this.backgroundPicker.onClick()
		} else {
			this.textColor = currentColor
			this.colorPicker.onClick()
		}
	}

	public toggleFontStyle(style: "background" | "color" | "bold" | "italic" | "underline", color = "") {
		const currentOKDQB = this.questionSheet.find(e => {
			return this.isQuestionBank() 
				? e.qObj.qid === this.focusEdit.qid 
				: e.qObj.douid === this.focusEdit.douid;
		})
		if (currentOKDQB) {
			const editors = currentOKDQB.customQuillEditors.filter(e => e.editorId === this.focusEdit.douid);
			let editor = editors[0]
			
			if (editors.length > 1) {
				editor = editors.find(e => e.isFocus)
			}
			
			if (editor) {
				editor.formatText(style, color, currentOKDQB.qObj.type)
				if (style == 'background') {
					this.backgroundColor = color
				} else {
					this.textColor = color
				}
			}
		}
	}

	// detect the current text style
	public getTextStyleStatus(style: 'bold' | 'italic' | 'underline' | 'focus'): boolean {
		if (this.questionSheet && this.focusEdit) {
			const currentOKDQB = this.questionSheet.find(e => {
				return this.isQuestionBank() 
					? e.qObj.qid === this.focusEdit.qid 
					: e.qObj.douid === this.focusEdit.douid;
			});
	
			if (currentOKDQB) {
				const editors = currentOKDQB.customQuillEditors.filter(e => e.editorId === this.focusEdit.douid);
				let editor = editors[0]
	
				if (editors.length > 1) {
					editor = editors.find(e => e.isFocus)
				}
	
				if (editor) {
					const selection = editor.getSelection()
	
					switch(style) {
						case 'bold':
							return editor.isBold
						case 'italic':
							return editor.isItalic
						case 'underline':
							return editor.isUnderline
						case 'focus':
							return editor.isFocus
					}
				}
			}
		}
    	return false;
	}

	public getTextColor(type: 'color' | "background") {
		const defaultTextColor = "#000"
		const defaultBackgroundColor = "#ffffff"
		const currentOKDQB = this.questionSheet.find(e => {				
			return this.isQuestionBank() 
				? e.qObj.qid === this.focusEdit.qid 
				: e.qObj.douid === this.focusEdit.douid;
		});
		if (currentOKDQB) {
			const editors = currentOKDQB.customQuillEditors.filter(e => e.editorId === this.focusEdit.douid);
			let editor = editors[0]

			if (editors.length > 1) {
				editor = editors.find(e => e.isFocus)
			}

			if (editor) {
				const selection = editor.getSelection()

				switch(type) {
					case 'color':
						if (editor.textColor == null) {
							this.textColor = defaultTextColor
						} else {
							this.textColor = editor.textColor
						}
						return editor.textColor || defaultTextColor
					case 'background':
						if (editor.backgroundColor == null) {
							this.backgroundColor = defaultBackgroundColor
						} else {
							this.backgroundColor = editor.backgroundColor
						}
						return editor.backgroundColor || defaultBackgroundColor
				}
			}
		}

		switch(type) {
			case 'color':
				return defaultTextColor
			case 'background':
				return defaultBackgroundColor
		}
	}
}
