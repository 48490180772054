import {TimeColorStroke} from "./TimeColorStroke";
import {EPenDataType} from "./EPenDataType";
import {SVGEPenPathCreator} from "./SVGEPenPathCreator";
import {EventEmitter} from "@angular/core";

export class PlaybackController {
	svg: HTMLElement | Element;
	steps: any[];

	timeline = [];
	duration = 0;
	current_time = 0;
	is_playing = false;
	speed = 1;
	on_end = new EventEmitter();

	constructor(svg: HTMLElement | Element, steps: any[]) {
		this.svg = svg;
		this.steps = steps;
		this.init_timeline();
	}

	init_timeline() {
		let start_time = null;
		let end_time = null;
		let steps = this.steps.filter(step => step.time !== 39632917);

		this.timeline = steps.map((step) => {
			let data = step.data;
			let step_time = step.time;
			end_time = step_time;
			if (data instanceof TimeColorStroke) {
				let clone_data = new TimeColorStroke(data.type, data.color, data.thickness);
				if (start_time === null) {
					start_time = data.pts[0].time;
				}
				step_time = data.pts[0].time;
				clone_data.pts = data.pts.map(pt => {
					end_time = pt.time;
					return {
						...pt,
						time: pt.time - start_time,
					}
				});
				data = clone_data;
			}

			return {
				...step,
				time: step_time - start_time,
				data,
			}
		});

		this.duration = end_time - start_time;
	}

	get is_end() {
		return this.current_time >= this.duration;
	}

	get_past_steps(time) {
		let list = this.timeline.filter(step => step.time <= time).map(step => {
			let clone_data = step.data;
			if (step.type === EPenDataType.DATA_TYPE_EPEN || step.type === EPenDataType.DATA_TYPE_HIGHLIGHT) {
				clone_data = new TimeColorStroke(step.data.type, step.data.color, step.data.thickness);
				if (step.data.pts.length > 0) {
					let pts = step.data.pts;
					clone_data.pts = [];
					for (let i = 0; i < pts.length; i++) {
						if (pts[i].time <= time) {
							clone_data.pts.push(pts[i]);
						}
					}
				}
			}
			return {
				...step,
				data: clone_data,
			}
		});

		return list;
	}

	move_to(time) {
		this.current_time = time;
		this.svg.innerHTML = this.step_to_svg(this.get_past_steps(time));
		if(this.current_time >= this.duration) {
			this.on_end.emit();
		}
	}

	step_to_svg(steps) {
		var paths: string[] = [];

		steps.forEach((step) => {
			if (step.type == EPenDataType.DATA_TYPE_EPEN || step.type == EPenDataType.DATA_TYPE_HIGHLIGHT) {
				var path: string = this.getPath(step);
				paths.push(path);
			} else if (step.type == EPenDataType.DATA_TYPE_DEL) {
				let index = steps.findIndex(s => s.id === this.steps[step.data].id);
				paths.splice(index, 1);
				//delete paths[step.data];
			} else if (step.type == EPenDataType.DATA_TYPE_CLEAR) {
				paths = [];
			} else if (step.type == EPenDataType.DATA_TYPE_UNDO) {
				paths.pop();
			} else if (step.type == EPenDataType.DATA_TYPE_REDO) {
				// redo not support
				// debugger;
			} else if (step.type == EPenDataType.DATA_TYPE_MARKING) {
				throw "not implement yet";
				// debugger;
			} else if (step.type == EPenDataType.DATA_TYPE_MOVE_OBJ) {

			}
		})
		return `${paths.join("")}`;
	}

	async play() {
		if (this.is_playing) {
			return;
		}
		this.is_playing = true;
		do {
			let time = this.current_time;
			if (time === this.duration) {
				time = 0;
			}
			this.move_to(time);
			await this.timeout(1000 / 60);
			if (!this.is_playing) {
				return;
			}
			this.current_time += (1000 / 60) * this.speed;
		} while (this.current_time < this.duration);
		this.move_to(this.current_time);
		this.current_time = Math.min(this.current_time, this.duration);

		this.is_playing = false;
	}

	pause() {
		this.is_playing = false;
	}

	timeout(ms) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	private async playStep(step: any, previousTime): Promise<void> {
		if (step.type === EPenDataType.DATA_TYPE_EPEN || step.type === EPenDataType.DATA_TYPE_HIGHLIGHT) {
			if (step.data.pts.length > 0) {
				let pts = step.data.pts;
				step.data.pts = [];
				let existing_content = this.svg.innerHTML;
				let prev_time = pts[0].time;
				await this.timeout(prev_time - previousTime);
				console.log('playback start drawing', prev_time, previousTime, prev_time - previousTime);
				for (let i = 0; i < pts.length; i++) {
					await this.timeout(pts[i].time - prev_time);
					step.data.pts.push(pts[i]);
					this.svg.innerHTML = existing_content + this.getPath(step);
					prev_time = pts[i].time;
				}
			}
		} else if (step.type === EPenDataType.DATA_TYPE_DEL) {
			await this.timeout(step.time - previousTime);
			console.log('playback start deleting', step.time, previousTime, step.time - previousTime);
			let del_index = step.data;
			let del_step = this.steps[del_index];
			const elements = this.svg.querySelectorAll(`[stroke-id="${del_step.id}"]`);
			elements.forEach(element => element.remove());
		} else if (step.type === EPenDataType.DATA_TYPE_CLEAR) {
			await this.timeout(step.time - previousTime);
			console.log('playback start clearing', step.time, previousTime, step.time - previousTime);
			this.svg.innerHTML = "";
		} else if (step.type === EPenDataType.DATA_TYPE_UNDO) {
			await this.timeout(step.time - previousTime);
			console.log('playback start undoing', step.time, previousTime, step.time - previousTime);
			const lastElement = this.svg.lastElementChild;
			if (lastElement) {
				lastElement.remove();
			}
		}
	}


	getPath(step) {
		if (step.type == EPenDataType.DATA_TYPE_EPEN || step.type == EPenDataType.DATA_TYPE_HIGHLIGHT) {

			if (step.type == EPenDataType.DATA_TYPE_HIGHLIGHT) {
				let path2 = new SVGEPenPathCreator().createPathFromPtsForHighlight(step.data.pts, {width: 20, height: 40});
				let hexColor = this.toHexColor(step.data.color);
				let thickness = step.data.thickness;

				return `<path 
					class="note-path"
					stroke-id="${step.id}"
	                d="${path2}" 
	                fill="${hexColor}"
	                opacity="0.5"
	                />`;
			} else {

				let path = new SVGEPenPathCreator().createMarkerPath(step.data.pts);
				let hexColor = this.toHexColor(step.data.color);
				let thickness = step.data.thickness;

				return `<path 
					class="note-path"
					stroke-id="${step.id}"
	                d="${path}" 
	                stroke="${hexColor}"   
	                stroke-width="${thickness}"
					stroke-linecap="round"
	                fill="none"
	                />`;
			}

		} else {
			return `<path d="M150 0 L75 200 L225 200 Z" style="fill:none;stroke:green;stroke-width:3" />`;
		}
	}

	toHexColor(color: number) {
		return "#" + color.toString(16).padStart(6, "0");
	}
}