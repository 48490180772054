import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {SeatingPlanService} from "../../../service/SeatingPlan.service";

@Component({
	selector: 'student-group-flag',
	template: `
		<div class="group-flag" [style.background-color]="group_color"></div>
	`,
	styleUrls: ['./student-group-flag.component.scss']
})

export class StudentGroupFlagComponent implements OnInit {

	@Input() group = -1;

	group_color = null;

	constructor(
		public seating_plan: SeatingPlanService,
	) {
		this.update_group_color()
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.group) {
			this.update_group_color();
		}
	}

	ngOnInit() {
		this.update_group_color();
	}

	update_group_color() {
		this.group_color = this.seating_plan.get_color(this.group);
	}
}