import { LinkedList, LinkedListNode } from "src/app/common/LinkedList";

export class EnglishNumberSplitter
{
	private map:any = this.initMap();
	
	constructor()
	{
		
	}
	
	private initMap():any 
	{
		var s:string =[
			"ten hundred hundreds thousand thousands million millions billion billions trillion trillions",
			"zero one two three four five six seven eight nine",
			"twenty thirty forty fifty sixty seventy eighty ninety",
			"ELEVEN TWELVE THIRTEEN FOURTEEN FIFTEEN SIXTEEN SEVENTEEN EIGHTEEN NINETEEN"
		].join(" ");
		
		var parts:any [] = s.split(/[ \t\r\n]/g);
		var len:number = parts.length;
		var map:any = {};
		for (var i:number = 0; i < len; i++)
		{
			var element:string = parts[i]
			if (element)
			{
				var key:string = element.toLowerCase();
				map[key] = 1;
			}
		}
		return map;
	}
	
	
	
	
	private format(text:String):any 
	{
		var key:string = text.toLowerCase();
		/*
		if (key == "and")
		{
			return {text:text, type:"and"};
		} else 
		*/
		if (this.map.hasOwnProperty(key))
		{
			return {text:text, type:"number"};
		} else {
			return {text:text, type:"text"};
		}
	}
	
	private regExec(reg:RegExp, text:string , fn:Function):void
	{
		var m:any ;
		while (m = reg.exec(text))
		{
			fn.call(null, m[0], m.index, m.input);
		}
	}
	
	private splitWith(string:string , reg:RegExp):any []
	{
		var output:any []  = [];
		var array:any []  = string.split(reg);
		var arrayIndex:number = 0;
		var match:any 
		while (match = reg.exec(string))
		{
			output.push(array[arrayIndex]);
			output.push(match[0]);
			arrayIndex++;
		}
		output.push(array[arrayIndex]);
		return output;
	}
	
	public split(s:string):any []
	{
		var list:LinkedList = new LinkedList();
		
		var parts:any []  = this.splitWith(s, /[ \.\r\n]/g);
		parts.forEach((element:string )=>
		{
			if (/[ \.\r\n]/.test(element))
			{
				list.insertLast({text:element, type:"connector"});
			} else {
				// list.push(element);
				list.insertLast(this.format(element));
			}
		});
		
		list.insertLast({type:"end"});
		this.joinNumbers(list);
		
		var array:any []  = this.linkListToArray(list);
		array.pop();
		var len:number = array.length;
		for (var i:number = 0; i < len; i++)
		{
			var element:any  = array[i];
			array[i] = element.text;
		}
		return array;
	}
	
	private joinBetween(startNode:LinkedListNode, endNode:LinkedListNode):void
	{
		if (startNode == endNode) return;
		
		var node:LinkedListNode = startNode;
		var nodeValue:any  = node.value;
		var array:any []  = [];
		while (node)
		{
			array.push(node.value);
			if (node == endNode)
			{
				break;
			}
			node = node.next;
		}
		//trace(array);
		var textArray:any []  = array.map((o:any )=>
		{
			return o.text;
		});
		var text:string = textArray.join("");
		startNode.value = {
			text:text,
			type:nodeValue.type
		};
		startNode.next = endNode.next;
		endNode.next.prev = startNode;
	}
	
	private linkListToArray(ll:LinkedList):any []
	{
		var pointer:LinkedListNode = ll.head;
		var array:any []  = [];
		while (pointer && pointer.value)
		{
			//var value:any  = pointer.value;
			array.push(pointer.value);
			pointer = pointer.next;
		}
		return array;
	}
	
	private joinNumbers(list:LinkedList):void
	{
		var currentNode:LinkedListNode = list.head;
		var firstNode:LinkedListNode;
		var lastNode:LinkedListNode;
		var lastNonEmptyNode:LinkedListNode;
		var endNode:LinkedListNode;
		var array:any []  = [];
		firstNode = currentNode;
		lastNonEmptyNode = currentNode;
		while (currentNode && currentNode.value)
		{
			if (currentNode.value.type != "connector")
			{
				if (
					lastNonEmptyNode.value.type != currentNode.value.type 
				)
				{
					endNode = lastNonEmptyNode;
					array.push({
						first:firstNode,
						end:endNode
					});
					firstNode = currentNode;
					endNode = currentNode;
				}
				
				lastNonEmptyNode = currentNode;
				
			}
			currentNode = currentNode.next;
		}
		
		array.forEach((element:any )=>
		{
			this.joinBetween(element.first, element.end);
		});
		
		
		/// join non number elements
		array = [];
		currentNode = list.head;
		firstNode = currentNode;
		lastNonEmptyNode = currentNode;
		while (currentNode && currentNode.value)
		{
			if (
				(lastNonEmptyNode.value.type == "number") != (currentNode.value.type == "number")
			)
			{
				endNode = lastNonEmptyNode;
				array.push({
					first:firstNode,
					end:endNode
				});
				firstNode = currentNode;
				endNode = currentNode;
			}
			lastNonEmptyNode = currentNode;
			currentNode = currentNode.next;
		}
		
		array.forEach((element:any)=>
		{
			this.joinBetween(element.first, element.end);
		});
		
		
		
		
	}
	/*
	 * return {text:text, type:"and"};
		} else if (this.map.hasOwnProperty(key))
		{
			return {text:text, type:"number"};
		} else {
			return {text:text, type:"text"};
	 * */
	
}
