import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { DataService } from './data.service';
import { SettingService } from './setting.service';

@Injectable()
export class JwtResolver implements Resolve<any> {
	constructor(private datas:DataService, private router:Router, private settings:SettingService) {
	}

    resolve(aRoute: ActivatedRouteSnapshot) {
        if (aRoute.queryParams.jwt) {
            this.datas.jwt = aRoute.queryParams.jwt;
            localStorage.setItem('common_jwt', this.datas.jwt);
        } else {
            this.datas.jwt = localStorage.getItem('common_jwt');
        }
        if (this.datas.jwt) {
            try {
            let jwts:string[] = this.datas.jwt.split('.');
            this.datas.jwtObj = JSON.parse(atob(jwts[1]));
            this.settings.loadData();
            } catch (e) {
                this.router.navigate(['login']);    
            }
        } else {
			this.router.navigate(['login']);
		}

		return true;
    }

}
