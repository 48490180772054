import { ChangeDetectorRef, Component, ComponentRef, ElementRef, Input, ViewContainerRef } from "@angular/core";
import { ROComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

import { ROContext } from "./ROContext";

export class QuestionSectionParser
{
	public parseSectionInfo(section:string):any{
		var output:any = {};
		if(!section) return output;
		const regex = /([A-Z]{0,})([0-9]{1,})/gm;
		let m;
		
		while ((m = regex.exec(section)) !== null) {
		    // This is necessary to avoid infinite loops with zero-width matches
		    if (m.index === regex.lastIndex) {
		        regex.lastIndex++;
		    }
			var key = m[1] ? m[1] : "X";
		    var value = parseInt(m[2]);
			output[key] = value;
		}
		return output;
	}
}
@Component({
	template:`{{getLabel()}}`,
	styles:[
		`
		:host{
			color:#fff;
			text-align:center;
			background-color:var(--bg-color);
		}
		:host.section-A{
			background-color:#5BB9D9;
		}
		:host.section-B{
			background-color:#FFA346;
		}
		:host.section-C{
			background-color:#7085C8;
		}
		:host.section-D{
			background-color:#FC6E9D;
		}
		:host.section-E{
			background-color:#68C3C4;
		}
		:host.section-F{
			background-color:#84C870;
		}
		:host.section-G{
			background-color:#F0B837;
		}
		:host.section-H{
			background-color:#EB6F86;
		}
		:host.section-I{
			background-color:#90B7DE;
		}
		:host.section-J{
			background-color:#AD90DE;
		}
		:host.section-K{
			background-color:#BBD379;
		}
		:host.section-L{
			background-color:#F28D6D;
		}
		:host.section-M{
			background-color:#FFA2A2;
		}
		:host.section-N{
			background-color:#2383A4;
		}
		:host.section-O{
			background-color:#E57D0A;
		}
		:host.section-P{
			background-color:#4D63A5;
		}
		:host.section-Q{
			background-color:#D8366C;
		}
		:host.section-R{
			background-color:#1E9C9C;
		}
		:host.section-S{
			background-color:#4CA233;
		}
		:host.section-T{
			background-color:#D09104;
		}
		:host.section-U{
			background-color:#D4556C;
		}
		:host.section-V{
			background-color:#5C8DBD;
		}
		:host.section-W{
			background-color:#7B5FB3;
		}
		:host.section-X{
			background-color:#9EBA57;
		}
		:host.section-Y{
			background-color:#D56E4D;
		}
		:host.section-Z{
			background-color:#E87979;
		}


		:host.hidden {visibility: hidden;}
		:host.ball{
			width:36px;
			height:36px;
			line-height:36px;
			margin:12px;
			border-radius: 50%;
			font-size:18px;
		//	outline: solid 1px rgba(0,0,0,0.5) !important;
			/*box-shadow: rgba(255,255,255,0.5) 0px 3px 0px 0px inset;*/
			background-image: url('assets/ro/component/question_ball.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
		:host.ball:after{
			position: absolute;
			top:0;
			left:0;
			content: '';
			width:34px;
			height:34px;
			border-radius: 50%;
			/*border: solid 1px rgba(0,0,0,0.5);*/
		}
		:host.mini{
			transform:translate(0px, -17px);
			width:28px;
			height:18px;
			font-size:10px;
			line-height:18px;
			border-radius:3px;
			font-family: 'Noto Sans TC';
		}
		:host{
			left:var(--q-left);
			top:var(--q-top);
		}
		:host.mini{
			left: 6px;
    		top: 1px;
		}
		`
		// 5BB9D9
	]
})
export class ROQuestionNumber //extends ROComponent
{
	@Input() public parent:ROComponent;
	@Input() public page:ROComponent;
	@Input() public node:XMLNode;
	@Input() public context:ROContext;

	protected numberBallSizeArray:number[] = [22, 29, 36, 44, 52];
	public componentRef:ComponentRef<any>;
	public x:number;
	public y:number;
	// todo 支編號未支援
	constructor(protected cdr:ChangeDetectorRef, protected elementRef:ElementRef)
	{
		
	}
	
	reattach():void
	{
		this.cdr.reattach();
		
	}
	detach():void
	{
		this.cdr.detach();
	}
	destroy():void
	{
		this.componentRef.destroy();
	}

	getSection(): any {
		this.node.getAttribute("section", null);
	}
	setQuestionSection(section:string):void
	{
		var dom:HTMLElement = this.elementRef.nativeElement;
		var classList:string[] = [];
		dom.classList.forEach((className:string)=>{
			if(className.indexOf("section") == 0) classList.push(className);
		});
		classList.forEach((className:string)=>{
			dom.classList.remove(className);
		})
		if(section) dom.classList.add("section-"+section);
	}
	setSection(section: any) {
		this.node.setAttribute("section", section);
	}

	public getTagNames():string []
	{
		return ["QuestionNumber"];
	}

	public build():void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		let style:any = this.elementRef.nativeElement.style;
		let attr:any = this.node.attributes;
		style.position = 'absolute';
		// Remove existing classes
		dom.classList.remove("hidden", "mini", "ball");

		if(!attr.show) {
			dom.classList.add("hidden");
			this.x = -60;
			this.y = 0;
		} else if(attr.type != "mini") {
			dom.classList.add("ball");
			this.x = 0;
			this.y = 0;
		} else {
			dom.classList.add("mini");
		}

		if(attr.type != "mini") {
			//if(attr.freezed) {
			//	style.left = "-60px";
			//	style.top = "0px";
			//} else {
				// 要找 component component first line 去計
				// line >60 -> top = 0
				// else top = (60 - line height)/2
				
			if(attr.freezed)
			{
				this.x = attr.x; 
				var firstLineHeight:number = this.parent.getFirstLineHeight();
				if(firstLineHeight > 60)
					this.y = 0;
				else 
					this.y = (firstLineHeight - 60)/2;
			} else {
				this.x = attr.x;
				this.y = attr.y;
			}
			
			StyleUtils.setStyleVariable(dom, "q-left", this.x + "px");
			StyleUtils.setStyleVariable(dom, "q-top", this.y + "px");
			//}
		}
		
		let color = attr.color
		if (color === 'noColor') {
			StyleUtils.setStyleVariable(dom, "bg-color", "#5AB8D8");
		} else if (typeof color === 'string' && /^#([0-9A-F]{3}){1,2}$/i.test(color)) {
			StyleUtils.setStyleVariable(dom, "bg-color", color);
		} else {
			StyleUtils.setStyleVariable(dom, "bg-color", StyleUtils.colorCodeToHex(color));
		}
		this.setQuestionSection(this.parent.node.attributes.questionSection);
	}

	public updatePositionByLineHeight(lineHeight:number):void {
		var dom:HTMLElement = this.elementRef.nativeElement;
		let style:any = this.elementRef.nativeElement.style;
		let attr:any = this.node.attributes;
		if(attr.type != "mini") {
			this.x = -60;
			this.y = (lineHeight > 60 ? 0 : (lineHeight - 60) / 2);
			style.left = this.x + "px";
			style.top = this.y + "px";
			console.log("updatePositionByLineHeight");
		}
	}
	public parseSectionInfo(section:string):any{
		return new QuestionSectionParser().parseSectionInfo(section);
		/*
		var output:any = {};
		if(!section) return output;
		const regex = /([A-Z]{0,})([0-9]{1,})/gm;
		let m;
		
		while ((m = regex.exec(section)) !== null) {
		    // This is necessary to avoid infinite loops with zero-width matches
		    if (m.index === regex.lastIndex) {
		        regex.lastIndex++;
		    }
			var key = m[1] ? m[1] : "X";
		    var value = parseInt(m[2]);
			output[key] = value;
		}
		return output;
		*/
	}
	public getLabel():string {
		
		if(this.parent && this.parent.node instanceof XMLNode) {
			var pageNode:XMLNode = this.page.node;
			
			var node:XMLNode = this.parent.node;
			let attr = node.attributes;
			var section:string = node.getAttribute("questionSection", null);
			var pageStartIndex:number = pageNode.getAttribute("questionStartIndex", 0);
			var sectionStart:string = pageNode.getAttribute("questionSectionStart", null);
			var sectionInfo:any = sectionStart ? this.parseSectionInfo(sectionStart) : {X:pageStartIndex};
			var pageSectionOffset:number =  0;
			if(!section) section = "X";
			if(sectionInfo.hasOwnProperty(section))
			{
				pageSectionOffset = sectionInfo[section];
			}
			if(attr.hasOwnProperty("questionLabelTitle"))
				return attr.questionLabelTitle;
			var section:string = attr.hasOwnProperty("questionSection") ? attr.questionSection : "";
			if(attr.hasOwnProperty("questionIndex")){
				return section + (pageSectionOffset + attr.questionIndex+1).toString();
			}
			if(attr.hasOwnProperty("questionNumber"))
				return section + attr.questionNumber;
		}

		return "?";
	}

	public hide():void {
		this.node.setAttribute("show", false);
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.classList.add("hidden");
		dom.classList.remove("ball");
		dom.classList.remove("mini");
	}

	public moveBy(px:number, py:number):void
	{
		this.x += px;
		this.y += py;
		
		var dom:HTMLElement = this.elementRef.nativeElement;
		dom.style.left = this.x+"px";
		dom.style.top  = this.y+"px";
	}

	/*
	// from okdpage
			list.forEach(
				function(obj:Object, listIndex:int, reference:Array):void
				{
					
					var com:OKDComponent = obj.com;
					var title:String = obj.title;
					var index:int = obj.index;
					var info:Object = obj.info;
					
					var id:int = obj.id;
					var pid:int = obj.pid;
					
					var level:int = obj.level;
					var hasChildren:int = obj.children.length;
					var show:Boolean = obj.show;
					
					var error:Boolean = info.error;
					if (show)
					{
						var subfix:String = hasChildren && level == 0 ? " a" : "";
						if (title)
						{
							com.setPropertiesThroughPanel("q.label", title + subfix);
						} else {
							if (error)
							{
								com.setPropertiesThroughPanel("q.label", "?"+ subfix);
							} else if (level == 0)
							{
								trueQuestionNumber = index + start + 1;
								com.setPropertiesThroughPanel("q.label", trueQuestionNumber + subfix);
							} else if (level == 1)
							{
								com.setPropertiesThroughPanel("q.label", TextStyleCreator.lowerAlphaString(index + 2));
							} else if (level == 2)
							{
								com.setPropertiesThroughPanel("q.label", TextStyleCreator.lowerRomanString(index + 1));
							} else if (level == 3)
							{
								com.setPropertiesThroughPanel("q.label", TextStyleCreator.cjkHeavenlyStemString(index + 1));
								// com.setPropertiesThroughPanel("q.label", "x");
							}
						}
						
					}
				}
			);

	*/

}