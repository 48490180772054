import { Component, ViewChild, TemplateRef, ViewContainerRef, EmbeddedViewRef, ElementRef, HostListener, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';

import { FileIOService } from 'src/app/service/FileIO.service';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'SoundRecorderPopup',
	template: `
 		<div class="backdrop" *ngIf="_isShowing" [class.show]="_isShowing" [style.zIndex]="_backdropZindex">
			<div class="popup">
				<ng-container *ngIf="state=='ready'">
					<div class="msg">{{getText("ready")}}</div>
					<div class="largeText countDown">{{timeStr}}</div>
					<div class="cancel" (click)="close()">{{getText("cancel")}}</div>
				</ng-container>

				<ng-container *ngIf="state=='recording'">
					<div class="msg">{{getText("recording")}}</div>
					<div class="largeText time">{{timeStr}}</div>
					<div class="stop" (click)="stop()">{{getText("stop")}}</div>
				</ng-container>
				
				<ng-container *ngIf="state=='recorded'">
					<div class="msg">{{getText("recorded")}}</div>
					<div class="player">
						<audio controls="" #audio></audio>
					</div>
					<div class="btns">
						<div class="upload" (click)="upload()">{{getText("upload")}}</div>
						<div class="record" (click)="record()">{{getText("record")}}</div>
					</div>
				</ng-container>
			</div>
		</div>
   `,
	styleUrls: ['./soundRecorderPopup.component.scss'],
})

export class SoundRecorderPopupComponent implements OnInit{
	protected _audio:HTMLAudioElement;
	@ViewChild("audio", {static:false}) set audio(ele:ElementRef<HTMLAudioElement>) {
		if(ele) {
			this._audio = ele.nativeElement;
		}
	}

	protected lang_tc:any = {
		"ready":"準備錄音",
		"recording":"錄音中...",
		"recorded":"錄音完成",
		"cancel":"取消",
		"stop":"停止",
		"upload":"上傳",
		"record":"重新錄音"
	};

	protected lang_sc:any = {
		"ready":"准备录音",
		"recording":"录音中...",
		"recorded":"录音完成",
		"cancel":"取消",
		"stop":"停止",
		"upload":"上传",
		"record":"重新录音"
	};

	protected lang_en:any = {
		"ready":"Ready to record",
		"recording":"Recording...",
		"recorded":"Recorded",
		"cancel":"Cancel",
		"stop":"Stop",
		"upload":"Upload",
		"record":"Re-record"
	};


	public _isShowing:boolean = false;
	public _backdropZindex:number = 100;
	public state:string = 'ready';

	private _resolveFn:Function = null;
	private _rejectFn:Function = null;
	public countDown:number;
	public countDownTimer:any;
	public timeStr:string;
	public sec:number;
	public snd:any;

	constructor(
		public translate:TranslateService, 
		private coms:CommonService,
		public fileio:FileIOService,
		public themeService: ThemeService,
		public eleRef:ElementRef,
	) {
	}

	ngOnInit() {
		this.initializeTheme()
	}

	private async initializeTheme(): Promise<void> {
		const [alert2StyleObj] = await Promise.all([
			this.themeService.getThemeJson("alert2Module.json")
		]);
		
		this.themeService.applyStyleObj(alert2StyleObj, this.eleRef.nativeElement);
	}

	public getText(key:string):string {
		if(this.translate.currentLang == "en")
			return this.translate.parser.getValue(this.lang_en, key);
		else if(this.translate.currentLang == "sc")
			return this.translate.parser.getValue(this.lang_sc, key);
		return this.translate.parser.getValue(this.lang_tc, key);
	}

	public lanuch():Promise<any> {
		return new Promise((resolve:Function, reject:Function)=>{
			this._resolveFn = resolve;
			this._rejectFn = reject;

			if(location.protocol == "http:" && location.port == '') { //allow "ng serve" can run
				this._rejectFn({msg:"alert.NEED_SSL_TO_PROVIDE_FUN"});
				return;
			}

			this._backdropZindex = this.coms.getZIndex();
			this._isShowing=true;
			this.record();
		});
	}

	public close() {
		this.clearCountDownTimer();
		this._isShowing=false;
		this._backdropZindex = 0;
		this.snd = null;
		this.state = "ready";
	}

	public stop() {
		this.state = "recorded";
		this.fileio.stopRecord();
	}

	public upload() {
		this._resolveFn(this.snd);
		this.close();
	}

	public record() {
		this.snd = null;
		this.state = "ready";
		this.countDown = 30;
		this.timeStr = "3";
		this.clearCountDownTimer();
		this.countDownTimer = setInterval(()=> {
			this.countDown--;
			this.timeStr = Math.ceil(this.countDown/10).toString();

			if(this.countDown==0) {
				this.clearCountDownTimer();
				this.state = "recording";
				this.sec = 0;
				this.timeStr = this.toTimeText(this.sec);

				this.countDownTimer = setInterval(() => {
					this.sec++;
					this.timeStr = this.toTimeText(this.sec);
				}, 1000);
				
				this.fileio.record().then((success:any)=> {
					this.snd = {
						duration:success.duration,
						file:success.file,
						url:URL.createObjectURL(success.file)
					};
					if(this._audio)
						this._audio.src = this.snd.url;
					this.clearCountDownTimer();
		
				},(reason:any) => {
					this.clearCountDownTimer();
				});
			}

		}, 100);
	}

	protected clearCountDownTimer() {
		if(this.countDownTimer) {
			clearInterval(this.countDownTimer);
			this.countDownTimer = null;
		}	
	}

	protected toTimeText(num:number):string {
		return this.toTwoDigi(Math.floor(num/60))+":"+this.toTwoDigi(num%60);
	}

	protected toTwoDigi(num:number):string {
		return num<10 ? "0"+num : num.toString();
	}

}