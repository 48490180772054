import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { debounceTime } from 'rxjs/operators';
import * as isbnUtils from 'isbn-utils';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { AbstractExtendedWebDriver } from 'protractor/built/browser';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { CodeScanService } from '../codeScanModule/codeScan.service';
import { UploadService } from '../uploadModule/upload.service';
import { ThemeService } from 'src/app/service/theme.service';
import { faBarcodeRead } from '@fortawesome/pro-regular-svg-icons';
import { faCamera, faBook, faTrash, faUpload } from '@fortawesome/pro-solid-svg-icons';
import { CommonService } from 'src/app/service/common.service';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';
import { ScriptService } from 'src/app/service/script.service';
import { url } from 'inspector';
import { LoadingService } from '../loadingModule/loading.service';
import { Router } from '@angular/router';
import { RoService } from 'src/app/service/ro.service';
import { SettingService } from 'src/app/service/setting.service';

@Component({
	selector: 'student-read-report-modal',
	templateUrl: './student-read-report.modal.html',
	styleUrls: ['./student-read-report.modal.scss'],
})

/*
	data = {bookTitle:string, bookAssigned: 1|0, cover: string, name: string, content: any, bsid: string, book_id:string, website:string, remark: string}

*/

export class StudentReadReportModal implements OnInit, AfterViewInit, OnDestroy {
	@Input() data: any;
	@ViewChild('audioRef', { static: false }) audioRef;
	@ViewChild('pSilderRef', { static: false }) pSilderRef;
	@ViewChild('preview', { static: false }) preview;
	@Output() close = new EventEmitter<any>();
	public modalTitle = 'open-book.add-reading-report';
	public faBarcodeRead = faBarcodeRead;
	public faCamera = faCamera;
	public faBook = faBook;
	public faTrash = faTrash;
	public faUpload = faUpload;
	public okaPulldownStyle = { width: '245px' };
	public clickSubject = new Subject();
	public okaPulldownMenuStyle = {};
	public bookAssigned;
	public name = '';
	public website = '';
	public filename = '';
	public remark = '';
	public inputMode = 'free';
	public inputMethod = 'text';
	public recordStatus;
	public star = 0;
	public photo;//backgroundImage
	public cover;//url
	public text;
	public photoObj: any;
	public audioObj: any;
	public videoObj: any;
	public recordObj: any;
	public structure: any;
	public submission: any;
	public readOnly = false;
	public commentId = 0;
	public bookFromLibrary;
	public selectLibraryModal;
	public ready = false;
	public hideApprovedStatusLabel = true;
	public submitButtonText = 'open-book.ok-confirm';
	public inputMethods = [{ label: 'open-book.im-text', value: 'text' }, { label: 'open-book.im-photo', value: 'photo' }, { label: 'open-book.im-audio', value: 'audio' }, { label: 'open-book.im-video', value: 'video' }];

	public not_editable = false;
	public origin_comment_type: any = "";
	public origin_content: any = "";
	public origin_cover: any = "";
	public origin_book_id: any = "";
    public isCover = false;
	public barcodeSource = '';
	private codePool = 'both';
	public bookType = 'physical';
	public suggestions:any = [];
	public approved:any = null;
	public installAppModal:any = null;
	constructor(public script: ScriptService, private san: DomSanitizer, public upload: UploadService, public codeScan: CodeScanService, private trans: TranslateService, public datas: DataService, private http: HttpClient, private als: AlertService, private eleRef: ElementRef, private theme: ThemeService, private common: CommonService, private svgReg: InlineSvgService, private lds: LoadingService, private router: Router, private ros: RoService, public setting: SettingService) {
	}

	ngOnInit() {
		this.readOnly = !!this.data.readOnly;
		this.initTheme(this.eleRef.nativeElement);
		this.bookAssigned = this.data.bookAssigned ? 1 : 0;
		if (this.bookAssigned) {
			this.name = this.data.bookTitle ? this.data.bookTitle : '';
			if (this.data.cover) {
				this.cover = this.data.cover;
				this.cover = this.cover.replace('storage.openknowledge.hk', 'oka.blob.core.windows.net');
				let imageUrl = "url('" + this.cover + "')";

				if (this.cover.indexOf('//') == -1) {
					this.photo = this.san.bypassSecurityTrustUrl('//oka.blob.core.windows.net/media/library-for-reading-report/' + this.cover);
				} else {
					this.photo = this.san.bypassSecurityTrustUrl(this.cover);
				}
			}
		}
		if (this.data.bookType !== undefined){
			this.bookType = this.data.bookType;
			this.website = this.data.website?this.data.website:'';
			if (this.data.file && this.data.file.url && (this.bookType == 'file' || this.bookType == 'powerpoint')){
				if (this.data.file.url.indexOf('//') == 0){
					this.website = this.data.file.url;
				} else if (this.data.file.url.indexOf('/') == 0){
					this.website = 'https://oka.blob.core.windows.net/media/' + this.data.file.url;
				} else {
					this.website = 'https://oka.blob.core.windows.net/media/' + this.data.file.url;
				}
				this.filename = this.data.file.originFilename;
			}
			this.remark = this.data.remark?this.data.remark:'';
		}
		this.inputMode = this.data.inputMode ? this.data.inputMode : 'free';
		this.inputMethod = this.inputMode != 'free' ? this.inputMode : 'text';
		if (this.data.bsid) {
			this.submitButtonText = 'open-book.ok-complete';
			this.modalTitle = 'open-book.edit-reading-report';
			this.svgReg.regSvgFile('course/icon_choose_book_library.svg', 'icon_choose_book_library');
			this.getData();
		} else if (this.data.comment) {
			let okaPath = '//oka.blob.core.windows.net/media/';
			this.submitButtonText = 'open-book.ok-complete';
			this.modalTitle = 'open-book.edit-reading-report';
			this.svgReg.regSvgFile('course/icon_choose_book_library.svg', 'icon_choose_book_library');
			this.data.book_id = this.data.comment.submission_book_id;
			this.data.bsid = this.data.comment.bsid;
			this.commentId = this.data.comment.id;
			this.approved = this.data.comment.approved;
			if (this.data.comment.approved === 1 || this.data.comment.approved === '1'){
				this.approved = 1;
				this.readOnly = true;
			} else if (this.data.comment.approved === 0 || this.data.comment.approved === '0'){
				this.approved = 0;
			}
			if (this.data.comment.file){
				this.data.file = this.data.comment.file;
			}
			this.origin_comment_type = this.data.comment.comment_type
			this.origin_content = this.data.comment.content
			this.origin_cover = this.data.comment.book_cover || this.data.comment.cover
			this.origin_book_id = this.data.comment.book_id

			this.name = this.data.comment.book_name || this.data.comment.title
			this.star = this.data.comment.star
			let url = ""
			if (this.data.comment.book_cover && this.data.comment.book_cover.indexOf('//storage')>=0) {
				url = this.data.comment.book_cover.replace('storage.openknowledge.hk', 'oka.blob.core.windows.net');
			} else if (this.data.comment.book_id) {
				this.bookFromLibrary = { id: this.data.comment.book_id }
				this.not_editable = true
				url = "https://oka.blob.core.windows.net/media/library-for-reading-report/" + (this.data.comment.book_cover || this.data.comment.cover)
			} else {
				this.bookFromLibrary = { id: null }
                if (this.data.comment.book_cover && this.data.comment.book_cover.indexOf('/')<=0) {
                    url = "https://oka.blob.core.windows.net/media/library-for-reading-report/" + this.data.comment.book_cover 
                }else{
                    url = "https://oka.blob.core.windows.net/media/" + (this.data.comment.book_cover || this.data.comment.cover)
                }
			}
			if (this.data.comment.book_cover && this.data.comment.book_cover!='blank' || this.data.comment.cover) {
                this.cover = this.data.comment.book_cover || this.data.comment.cover
				this.photo = this.san.bypassSecurityTrustUrl(url);
                this.isCover = true
			} else {
                this.photo = null
				this.cover = "blank"
			}
            console.log(this.cover);
            

			switch (this.data.comment.comment_type) {
				case "image":
					this.inputMethod = "photo"
					let photo = this.san.bypassSecurityTrustStyle("url('" + okaPath + this.data.comment.content + "')");
					this.photoObj = { method: "upload", url: this.data.comment.content, photo: "https://oka.blob.core.windows.net/media/" + this.data.comment.content };
					break;
				case "text":
					this.inputMethod = this.data.comment.comment_type
					this.text = this.data.comment.content
					break
				case "audio":
					this.inputMethod = this.data.comment.comment_type
					let audioUrl = okaPath + this.data.comment.content;
					this.audioObj = { url: this.data.comment.content, audio: audioUrl, current: 0, stopByCode: false };
					break
				case "video":
					this.inputMethod = this.data.comment.comment_type
					let video = this.san.bypassSecurityTrustUrl(okaPath + this.data.comment.content);
					this.videoObj = { url: this.data.comment.content, video: video };
					break
				default:
					break
			}
			const voiceObj = this.data.comment.voice?JSON.parse(this.data.comment.voice):null;
			if (voiceObj && voiceObj.bookType){
				this.bookType = voiceObj.bookType;
				this.remark = voiceObj.remark;
				if (voiceObj.file){
					if (voiceObj.file.url.indexOf('//') == 0){
						this.website = voiceObj.file.url;
					} else if (voiceObj.file.url.indexOf('/') == 0){
						this.website = 'https://oka.blob.core.windows.net/media/' + voiceObj.file.url;
					} else {
						this.website = 'https://oka.blob.core.windows.net/media/' + voiceObj.file.url;
					}
					this.filename = voiceObj.file.originFilename;
				}
			}
			if (voiceObj && voiceObj.website){
				this.website = voiceObj.website;
			}
			this.getData();
		} else {
			this.svgReg.regSvgFile('course/icon_choose_book_library.svg', 'icon_choose_book_library').then(() => {
				this.ready = true;
			});
		}
		if (this.data.modalTitle){
			this.modalTitle = this.data.modalTitle;
		}		
		this.datas.post('ROLibrary.getCodePool').subscribe((res:any)=>{
			this.codePool = res.setting?res.setting:'both';
		});
		if (this.data.workspace_book_id){
			this.name = this.data.bookTitle;
			this.datas.post('ROBookshelf.get_single_book_cover3', [this.data.workspace_book_id]).subscribe((res:any)=>{
				if (res.url){
					this.cover = res.url;
					this.photo = this.san.bypassSecurityTrustUrl(this.cover);
				}
			});
		}
	}

	ngAfterViewInit() {
		this.clickSubject.pipe(debounceTime(100)).subscribe(e => {
			if (e == 'confirm') {
				this.confirm();
			} else if (e == 'take-cover') {
				this.takeCover();
			} else if (e == 'load-isbn') {
				this.loadIsbn();
			} else if (e == 'upload-photo') {
				this.hideApprovedStatusLabel = true;
				this.uploadFile('image');
			} else if (e == 'take-photo') {
				this.hideApprovedStatusLabel = true;
				this.takePhoto();
			} else if (e == 'record-start') {
				this.hideApprovedStatusLabel = true;
				this.recordStart();
			} else if (e == 'record-complete') {
				this.recordObj.status = () => 'submit';
			} else if (e == 'record-cancel') {
				this.recordObj.status = () => 'cancel';
			} else if (e == 'upload-video') {
				this.hideApprovedStatusLabel = true;
				this.uploadFile('video');
			} else if (e == 'take-video') {
				this.hideApprovedStatusLabel = true;
				this.takeVideo();
			} else if (e == 'select-library') {
				this.hideApprovedStatusLabel = true;
				this.selectLibraryModal = {};
			} else if (e == 'upload-cover') {
				this.hideApprovedStatusLabel = true;
				this.uploadCover();
			} else if (e == 'clear-cover'){
                this.isCover = false;
				this.clearCover();
			}
		});
		
	}

	ngOnDestroy(): void {
		this.clickSubject.unsubscribe();
	}

	getData() {
		let bsid = this.data.bsid;
		let bookId = this.data.book_id;
		let uid = this.data.uid ? this.data.uid : this.datas.userInfo.uid;
		this.datas.post('ROLibrary.getStudentData', [bsid, bookId, uid]).subscribe((res: any) => {
			try{
                this.structure = JSON.parse(res.structure);
            } catch(e){
                res.structure = res.structure.replace(/:"",/g, 'regex1').replace(/""/g, '"').replace(/regex1/g, ':"",');
                this.structure = JSON.parse(res.structure);
            }
			if (res.comment){
				this.hideApprovedStatusLabel = false;
				if (res.comment.approved === 1 || res.comment.approved === '1'){
					this.approved = 1;
					this.readOnly = true;
				} else if (res.comment.approved === 0 || res.comment.approved === '0'){
					this.approved = 0;
				}
				this.inputMethod = res.comment.comment_type == 'image'?'photo':res.comment.comment_type;
				if (this.bookAssigned == false){
					this.name = res.comment.book_name;
					this.cover = res.comment.book_cover;
					this.packSafeCover(this.cover);
				}
				this.commentId = res.comment.id;
				if (this.inputMethod == 'text') {
					this.text = res.comment.content;
				} else if (this.inputMethod == 'photo') {
					let image = this.san.bypassSecurityTrustUrl('//oka.blob.core.windows.net/media/' + res.comment.content);
					this.photoObj = { url: res.comment.content, photo: image };
				} else if (this.inputMethod == 'audio') {
					let audioUrl = '//oka.blob.core.windows.net/media/' + res.comment.content;
					this.audioObj = { url: res.comment.content, audio: audioUrl, current: 0 };
				} else if (this.inputMethod == 'video') {
					let video = this.san.bypassSecurityTrustUrl('//oka.blob.core.windows.net/media/' + res.comment.content + '#t=0.05');
					this.videoObj = { url: res.comment.content, video: video };
				}
				if (res.submission) {
					let data = JSON.parse(res.submission.data);
					this.star = data.star;
				}
				if (res.comment.voice){
					try{
						const voiceObj = JSON.parse(res.comment.voice);
						if (voiceObj.website && (this.website == '' || this.website == null)){
							this.website = voiceObj.website;
						}
						if (voiceObj.file && (this.filename == '' || this.filename == null)){
							this.filename = voiceObj.file.originFilename;
						}
					} catch (e){

					}
				}
			}
			
			this.svgReg.regSvgFile('course/icon_choose_book_library.svg', 'icon_choose_book_library').then(() => {
				this.ready = true;
			});
			this.test();
		});
	}

	test() {
		// this.remark = 'test remark';
	}

	async confirm() {
		if (this.bookType != 'physical' && this.bookType != 'library'){
			this.name = this.website;
			const imgMap = {powerpoint: 'fileicon2_ppt', video: 'fileicon2_video', website: 'icon_weblink_24x24'};
			this.cover = '//dev.openknowledge.hk/RainbowOne/webapp/2.8/roWeb/assets/img/fileicon/' + imgMap[this.bookType]+ '.svg';
		}
		if (this.name == '' && this.bookFromLibrary == null && (this.bookType == 'physical' || this.bookType == 'library') ){
			this.als.alert('open-book.report-need-title');
			return;
		}
		if ( (this.inputMethod == 'photo' && this.photoObj == null) || (this.inputMethod == 'audio' && this.audioObj == null) || (this.inputMethod == 'video' && this.videoObj == null) ){
			this.als.alert('open-book.report-no-content');
			return;
		}
		// if edit
		this.lds.add('confirm');
		let change_data = {};
		if (this.data.comment) {
			console.log(this.data);
			console.log(this.cover);
			console.log(this.data.comment.book_cover);

			// check book id and name
			if (this.data.comment.book_name == this.name || (this.data.comment.book_id == this.bookFromLibrary.id && this.bookFromLibrary.id != null)) {
				change_data['book_change'] = false
			} else {
				change_data['book_change'] = true
				change_data['book_new'] = { id: this.bookFromLibrary.id, name: this.name }
				change_data['book_old'] = { id: this.data.comment.book_id, name: this.data.comment.book_name }
			}
			// check cover
			if ((this.cover == "blank" && this.data.comment.book_cover == null) || this.data.comment.book_cover == this.cover) {
				change_data['cover_change'] = false
			} else {
                console.log(this.cover);
				change_data['cover_change'] = true
                if(this.cover == 'blank'){
                    change_data['cover_new'] = null
                }else{
                    change_data['cover_new'] = this.cover
                }		
				change_data['cover_old'] = this.data.comment.book_cover
			}
			// check star
			if (this.data.comment.star == this.star) {
				change_data['star_change'] = false
			} else {
				change_data['star_change'] = true
				change_data['star_new'] = this.star
				change_data['star_old'] = this.data.comment.star
			}
			// check comment type
			if (this.data.comment.comment_type == this.inputMethod || this.data.comment.comment_type == "image" && this.inputMethod == "photo") {
				change_data['type_change'] = false
				change_data['type_old'] = this.data.comment.comment_type
				// check content
				if (this.inputMethod == "text") {
					if (this.data.comment.content == this.text) {
						change_data['content_change'] = false
					} else {
						change_data['content_change'] = true
						change_data['content_old'] = this.data.comment.content
						change_data['content_new'] = this.text
					}
				} else {
					if (this.data.comment.content == this[this.inputMethod + "Obj"]['url']) {
						change_data['content_change'] = false
					} else {
						change_data['content_change'] = true
						change_data['content_old'] = this.data.comment.content
						change_data['content_new'] = this[this.inputMethod + "Obj"]['url']
					}
				}
			} else {
				change_data['type_change'] = true
				change_data['content_change'] = true
				if (this.inputMethod == "photo") {
					change_data['type_new'] = "image"
				} else {
					change_data['type_new'] = this.inputMethod
				}
				change_data['type_old'] = this.data.comment.comment_type
				change_data['content_old'] = this.data.comment.content
				if (this.inputMethod == "text") {
					change_data['content_new'] = this.text
				} else {
					change_data['content_new'] = this[this.inputMethod + "Obj"]['url']
				}

			}

			if (!change_data['content_change'] && !change_data['type_change'] && !change_data['star_change'] && !change_data['cover_change'] && !change_data['book_change']) {
				console.log("nothing changed");
				this.close.emit();
				return
			}
			await this.datas.post('ROKeiKokLibrary.update_comment', [this.data.comment.id, this.data.comment.uid, change_data]).toPromise().then(res => {
				console.log(change_data);
				console.log(res['message']);
				change_data['action'] = "edit"
				change_data['id'] = this.data.comment.id
				// this.als.okAlert('open-book.submit-read-report');
				this.close.emit(change_data);
			})

			// return
		}

		let bsid = this.data.bsid;
		let bookId = this.data.book_id;
		let content = this.text?this.text:'';
		if (this.inputMethod == 'photo') {
			content = this.photoObj.url;
		} else if (this.inputMethod == 'audio') {
			content = this.audioObj.url;
		} else if (this.inputMethod == 'video') {
			content = this.videoObj.url;
		}
		let method = this.inputMethod == 'photo'?'image':this.inputMethod;
		let answer: any = { name: this.name, cover: this.cover, star: this.star, method: method, content: content, comment_id: this.commentId, library_book_id: this.bookFromLibrary ? this.bookFromLibrary.id : null };

		this.saveCommentData(answer).subscribe((res: any) => {
			if (this.data.bsid) {
				let pageId = this.structure.book.chapters[0].pages[0].id;
				let compId = this.structure.book.chapters[0].pages[0].components[0].cid;
				let result:any = { correction: true, score: 0, correct: -1, forceNotPass: 1 };
				let answerString = JSON.stringify(answer);
				let addRecord = { type: 'add', tag: 'report', doc: bookId, page: pageId, com: compId, answer: answerString, result: result };
				let submitRecord = { lesson_id: 0, doc: bookId, "page": pageId, mode: "none", ref_id: 0, type: "submit" };

				let saveBookShare = this.datas.post('ROBookShare.save', [bsid, [addRecord, submitRecord], bookId]).subscribe((res: any) => {
					const summary = {score: 0, green_count: 0, red_count: 0, orange_count: 0,blue_count: 1, total_count: 1, forceNotPass: 1};
					this.datas.post('ROCourse.start_then_end_book_session',[bsid, bookId, summary]).subscribe((res:any)=>{
						let data = change_data?change_data:answer;
						this.lds.remove('confirm');
						this.close.emit(data);
					});
				});
			} else {
				let data = change_data?change_data:answer;
				this.lds.remove('confirm');
				// this.als.okAlert('open-book.submit-read-report');
				this.close.emit(data);
			}
		});
	}

	cancelClick(event) {
		event.stopPropagation();
		this.close.emit();
	}

	starClick(num) {
		this.hideApprovedStatusLabel = true;
		if (num == this.star) {
			this.star = 0;
		} else {
			this.star = num;
		}
	}

	loadIsbn() {
		let options = {fps: 5, width: 400, height: 200, codeType: 'barcode', style: {width: '400px', height: '200px'} };
		this.codeScan.scan(options).then((e: any) => {
			if (e.code == 1) {
				if (this.codePool == 'book_id' || this.codePool == 'both'){
					this.checkbookRefId(e.text);
				} else {
					this.checkBarcode(e.text);
				}
			} else if (e.msg != 'cancel') {
				this.als.okAlert(e.msg);
			}
		}).catch(err => {
			this.als.okAlert(err);
		});
	}

	checkbookRefId(decodedText){
		this.datas.post('ROLibrary.getBookRef',[decodedText]).subscribe((res:any)=>{
			if (res.book){
				this.not_editable = false;
				this.bookFromLibrary = res.book;
				this.name = res.book.title;
				this.cover = res.book.cover;
				this.packSafeCover(this.cover);
			} else if (this.codePool == 'both'){
				this.checkBarcode(decodedText);
			} else {
				let msg = this.trans.instant('common.not-valid-isbn', { isbn: decodedText });
				this.als.okAlert(msg);
			}
		});
	}

	checkBarcode(decodedText) {
		let code = decodedText.trim();
		const myIsbnUtils = isbnUtils.parse(code);
		let isValidIsbn = false;
		if (myIsbnUtils) {
			if (myIsbnUtils.isIsbn10() || myIsbnUtils.isIsbn13()) {
				isValidIsbn = true;
			}
		}
		if (isValidIsbn) {
			let url = '//www.googleapis.com/books/v1/volumes?q=isbn:' + code;
			this.http.get(url).subscribe((res: any) => {
				if (res.items) {
					this.not_editable = false;
					this.bookFromLibrary = null;
					this.parseBookInfo(res, code);
				} else {
					let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
					this.als.okAlert(msg);
				}
			});
		} else {
			let msg = this.trans.instant('common.not-valid-isbn', { isbn: code });
			this.als.okAlert(msg);
		}
	}

	parseBookInfo(res, isbn) {
		let cover = null;
		if (res.items) {
			let item = res.items[0];
			if (item.volumeInfo) {
				this.name = item.volumeInfo.title;
				if (item.volumeInfo.imageLinks && item.volumeInfo.imageLinks.thumbnail) {
					this.cover = item.volumeInfo.imageLinks.thumbnail;
					this.photo = this.san.bypassSecurityTrustUrl(this.cover);
				}
			}
		}
	}

	takeCover() {
		this.upload.capture({ toMediaServer: 'reading-report' }).then((data: any) => {
			this.cover = data.url;
			this.not_editable = false;
			this.bookFromLibrary = null;
			let url = '//oka.blob.core.windows.net/media/' + data.url;
			this.photo = this.san.bypassSecurityTrustUrl(url);
		});
	}

	uploadCover(){
		this.upload.upload({ fileType: 'image', toMediaServer: 'reading-report'}).then((data:any)=>{
			this.cover = data.url;
			this.not_editable = false;
			this.bookFromLibrary = null;
			let url = '//oka.blob.core.windows.net/media/' + data.url;
			this.photo = this.san.bypassSecurityTrustUrl(url);
		});
	}

	clearCover(){
		this.cover = null;
		this.photo = null;
		this.bookFromLibrary = null;
		this.not_editable = false;
		this.name = '';
	}

	uploadFile(fileType = 'image') {
		let maxFileSize = (fileType == 'image' ? 10 : 50) * 1024 * 1024;
		let options = { fileType: fileType, size: maxFileSize, toMediaServer: 'reading-report' };
		this.upload.upload(options).then((data: any) => {
			let url = '//oka.blob.core.windows.net/media/' + data.url;
			let safeObj = this.san.bypassSecurityTrustUrl(url);
			if (fileType == 'image') {
				this.photoObj = null;
				setTimeout(() => {
					this.photoObj = { url: data.url, photo: safeObj, size: data.size, method: 'upload' };
				}, 500);
			} else if (fileType == 'video') {
				this.videoObj = null;
				setTimeout(() => {
					this.videoObj = { url: data.url, video: safeObj, size: data.size, method: 'upload' };
				}, 500);
			}
		});
	}

	takePhoto() {
		this.upload.capture({ toMediaServer: 'reading-report' }).then((data: any) => {
			let url = '//oka.blob.core.windows.net/media/' + data.url;
			let photo = this.san.bypassSecurityTrustUrl(url);
			this.photoObj = { url: data.url, photo: photo, method: 'take' };
		}).catch(err => {
			if (err && err.msg) {
				if (err.msg == "cancel") {
					// pass
				}
			}
		});
	}

	takeVideo() {
		let options = { toMediaServer: 'reading-report' };
		this.upload.recordVideo(options).then((data: any) => {
			console.log(data);
			let url = '//oka.blob.core.windows.net/media/' + data.url + '#t=0.05';
			this.videoObj = null;
			let video = this.san.bypassSecurityTrustUrl(url);
			setTimeout(() => {
				this.videoObj = { url: data.url, video: video, method: 'take' };
			}, 1000);
		});
	}

	recordStart() {
		this.hideApprovedStatusLabel = true;
		this.audioObj = null;
		if (this.recordObj) {
			this.recordObj.status = () => 'cancel';
		} else {
			this.recordObj = { currentTime: 0, limit: 3600, status: () => '', toMediaServer: 'reading-report' };
			this.upload.record(this.recordObj).then((data: any) => {
				//{ url: string, size: int, len: int }
				this.audioObj = data;
				let url = '//oka.blob.core.windows.net/media/' + data.url;
				// this.audioObj.audio = this.san.bypassSecurityTrustUrl(url);
				this.audioObj.audio = url;
				this.recordObj = null;
			}).catch(e => {
				this.recordObj = null;
				console.log(e);
			});
		}
	}

	initTheme(ele) {
		/*this.theme.applyStyleObj({
			default:{
				"color-333-fff":"#333"
			},
			dark:{
				"color-333-fff":"#fff"
			}
		}, document.body);*/
		let sectionMainColor = this.theme.getStyleValue('section-maincolor', document.body);
		console.log(sectionMainColor);

		this.theme.applyStyleObj({
			default: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#333",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-selectedtextcolor": "var(--section-maincolor)",
				"pulldown-overbgcolor": "#e2e2e2",
				"popupform-panelbgcolor":"#f5f5f5",
				"color-ccc-38374f":"#ccc",
				"popupform-rowbgcolor":"#fff",
				"popupframe-headerbgcolor":"#fff",
				"popupframe-titlecolor":"#333",
				"popupform-subtitlecolor":"#333",
				"popupform-subtitlesize":"15px",
				"popupform-subtitleweight":"bold",
				"popupform-textcolor":"var(--section-maincolor)",
				"color-f9f9f9-1e1e29":"#f9f9f9",
				"color-333-fff":"#333",
				"pulldown-selectedbgcolor": "var(--section-maincolor)"
			},
			dark: {
				"pulldown-labelcolor": "var(--section-maincolor)",
				"pulldown-textcolor": "#fff",
				"pulldown-bgcolor": "#38374f",
				"pulldown-selectedtextcolor": "#fff",
				"pulldown-overbgcolor": "#1e1e29",
				"popupform-panelbgcolor":"#38374f",
				"color-ccc-38374f":"#38374f",
				"popupform-rowbgcolor":"#1e1e29",
				"popupframe-headerbgcolor":"#1e1e29",
				"popupframe-titlecolor":"#fff",
				"popupform-subtitlecolor":"#a0a0a0",
				"popupform-subtitlesize":"15px",
				"popupform-subtitleweight":"bold",
				"popupform-textcolor":"#fff",
				"color-f9f9f9-1e1e29":"#1e1e29",
				"color-333-fff":"#fff",
				"pulldown-selectedbgcolor": "#fff"
			}
		}, ele);

	}

	saveCommentData(answer) {
		let commentAnswer: any = { star: answer.star, comment_type: answer.method, content: answer.content, comment_id: answer.comment_id };
		commentAnswer.book_name = this.name ? this.name : null;
		commentAnswer.book_cover = this.cover ? this.cover : null;
		commentAnswer.book_id = answer.library_book_id ? answer.library_book_id : null;
		commentAnswer.book_id = this.data.workspace_book_id?this.data.workspace_book_id:commentAnswer.book_id;
		commentAnswer.bsid = this.data.bsid ? this.data.bsid : null;
		commentAnswer.submission_book_id = this.data.book_id ? this.data.book_id : null;
		if (this.data.num){ //是課程的書
			commentAnswer.json = JSON.stringify({num: this.data.num, bookItemName: this.data.bookItemName, remark: this.remark, file: this.data.file, bookType: this.bookType, website: this.website});
		} else if (this.data.comment && this.data.comment.voice){ //在閱讀感想section開課程的書
			commentAnswer.json = this.data.comment.voice;
		}
		return this.datas.post('ROLibrary.saveCommentData', [commentAnswer]).pipe((tap((res: any) => {
			this.commentId = res.comment_id ? res.comment_id : this.commentId;
			answer.comment_id = this.commentId;
			if (res.bsid) {
				this.data.bsid = res.bsid;
				this.data.book_id = res.book_id;
			}
		})));
	}

	selectLibraryClose(data) {
		if (data) {
			this.photo = null;
			this.bookFromLibrary = data;
			this.name = data.title;
			this.cover = data.cover ? data.cover : 'blank';
			this.not_editable = true;
			this.packSafeCover(this.cover);
		}
		this.selectLibraryModal = null;
	}

	packSafeCover(cover) {
		if (cover) {
			if (cover.indexOf('//') > -1) {
				cover = cover.replace('storage.openknowledge.hk','oka.blob.core.windows.net');
				this.photo = this.san.bypassSecurityTrustUrl(cover);
			} else if (cover.indexOf('/') > -1) {
				this.photo = this.san.bypassSecurityTrustUrl('//oka.blob.core.windows.net/media/' + cover);
			} else if (cover.indexOf('/') == -1 && cover != 'blank') {
				this.photo = this.san.bypassSecurityTrustUrl("//oka.blob.core.windows.net/media/library-for-reading-report/" + cover);
			}
		}
	}

	loadDataFromComment(comment) {

	}

	getSuggestions($event){
		console.log($event);
		this.datas.post('ROLibrary.getBookTitleSuggestions', [$event.query]).subscribe((res:any)=>{
			this.suggestions = res.suggestions;
		});
	}

	openClick(){
		if (this.data.workspace_book_id){
			if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb){
				const previewUsingWeb = false; //待可以preview on Web便可改為true
				if (previewUsingWeb) {
					this.router.navigate(["bookViewer", 0, this.data.workspace_book_id, 0, "preview"]);
				} else {
					this.lds.add('call-api');
					this.datas.post('ROBook.create_preview_url', [this.data.workspace_book_id]).subscribe((res: any) => {
						this.lds.remove('call-api');
						let href = res.url.replace('https', this.setting.getOpenAppProtocol(this.datas.appId2, false));
						window.location.href = href;
						setTimeout(() => {
							if (document.hasFocus()) {
								this.installAppModal = {href: href};
							}
						}, 1000);
					});
				}
			} else {
				this.lds.add('call-api');
				this.ros.request('preview', { id: this.data.workspace_book_id, title: this.data.bookTitle }).then(() => {
					this.lds.remove('call-api');
				});
			}
		} else if (this.bookType == 'website'){
			setTimeout(()=>{
				const website = this.website != '' && this.website != null?this.website:this.name;
				window.open(website, '_blank');
			})
		} else if (this.bookType == 'file' || this.bookType == 'powerpoint'){
			let serverUrl = '//oka.blob.core.windows.net/media/';
			this.preview.setUrlPrefix(serverUrl);
			const [ext] = this.filename.split('.').slice(-1);
			const file = { name: this.filename, url: this.data.file.url, ext: ext };
			this.preview.setFileList([file]);
			this.preview.open();
		}

	}

}
