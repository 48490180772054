import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Modal2Component} from "../../../../../sharedModule/modal2Module/modal2.component";
import {ROPageSliderComopnent} from "../../../../../sharedModule/roBookModule/ROPageSliderComponent";
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {DataService} from "../../../../../service/data.service";
import {WebLessonService} from "../../services/WebLesson.service";
import {environment} from "../../../../../../environments/environment";
import {LoadingService} from "../../../../../sharedModule/loadingModule/loading.service";
import {ThemeService} from "../../../../../service/theme.service";
import {OupService} from "../../../../../service/oup.service";
import {AlertService} from "../../../../../service/alert.service";

@Component({
	selector: 'good-work-post-modal',
	template: `
		<modal2
			#modal
			(cancelClick)="modal.close()"
			(confirmClick)="create()"
			[maxHeight]="'calc(100dvh - 40px)'"
			[width]="'444px'"
			[contentStyle]="{'overflow-y': 'auto', padding: '26px 30px 10px'}"
			[title]="trans.instant('web-lesson-tools.good-work.title')"
		>
			<ng-container *ngIf="is_ready">
				<div class="image-container">
					<div class="badge"></div>
					<img [src]="image_path" style="width: 100%"/>
				</div>
				<div class="field-group">
					<div class="label">
						{{ 'web-lesson-tools.good-work.student' | translate }}:
					</div>
					<img *ngIf="!oup.isOupUser" class="profile-image" [src]="student_profile_image"/>
					<div class="student-name" [class.has-padding-left]="!oup.isOupUser">{{ web_lesson.get_student_display_name(bookConfig.student) }}</div>
				</div>
				<div class="field-group" style="align-items: flex-start; flex-wrap: wrap; margin-bottom: 0;">
					<div class="label" style="margin-top: 12px;">
						{{ 'web-lesson-tools.good-work.comment' | translate }}:
					</div>
					<img *ngIf="!oup.isOupUser" class="profile-image" [src]="teacher_profile_image"/>
					<textarea class="comment-input" [class.has-error]="comment.length > comment_max_length" [(ngModel)]="comment"></textarea>
					<div class="indicator" [class.has-error]="comment.length > comment_max_length">
						<span class="current-length">{{ comment.length }}</span>/{{ comment_max_length }}
					</div>
				</div>
				<div class="field-group">
					<div class="label">
						{{ 'web-lesson-tools.good-work.share_for' | translate }}:
					</div>
					
					<radioBox
						[(value)]="share_for"
						[options]="share_for_options"
					></radioBox>
				</div>
				<div class="field-group">
					<div class="label">
						{{ 'web-lesson-tools.good-work.post_to_news' | translate }}:
					</div>
					
					<radioBox
						[(value)]="post_to_news"
						[options]="post_to_news_options"
					></radioBox>
				</div>
			</ng-container>
		</modal2>
	`,
	styleUrls: ['./good-work-post-modal.component.scss']
})

export class GoodWorkPostModalComponent implements OnInit {

	slider: ROPageSliderComopnent;
	bookConfig: any;
	image_path = null;
	image_file = null;
	student_profile_image = null;
	teacher_profile_image = null;
	share_for = null;
	share_for_options = [];
	is_ready = false;
	post_to_news = 1;
	post_to_news_options = [];
	comment = null;
	comment_max_length = 50;

	@ViewChild('modal', {static: false}) modal: Modal2Component;

	constructor(
		public dom_sanitizer: DomSanitizer,
		public trans: TranslateService,
		public datas: DataService,
		public web_lesson: WebLessonService,
		public loading: LoadingService,
		public theme: ThemeService,
		public eleRef: ElementRef,
		public oup: OupService,
		public alert: AlertService,
	) {
		this.trans.use(this.datas.lang);
	}

	ngOnInit() {
		this.theme.reloadSetting();
		this.theme.applyStyleObj({
			"default": {
				"section-maincolor": "#A12E51",
				"popupform-rowbgcolor": "#FFFFFF",
				"section-popupbgcolor": "#f5ecf0",
			},
			"dark": {
				"section-maincolor": "#A12E51",
				"popupform-rowbgcolor": "#1e1e29",
				"section-popupbgcolor": "#2D2C43",
			}
		}, this.eleRef.nativeElement)
	}

	async open(slider, bookConfig) {
		if (!bookConfig.student || `${bookConfig.student.user_role}` !== "2") {
			this.alert.toastError(this.trans.instant('web-lesson-tools.good-work.please_select_student'));
			return;
		}

		console.log({
			slider, bookConfig
		})
		this.slider = slider;
		this.bookConfig = bookConfig;
		this.post_to_news = 1;
		this.share_for = 'group';
		this.comment = this.trans.instant('web-lesson-tools.good-work.default_comment');
		await Promise.all([
			this.capture_image(),
			this.init_profile_images(),
			this.init_options(),
		]);

		this.is_ready = true;
		return this.modal.open();
	}

	async init_options() {
		this.share_for_options = [
			{label: this.trans.instant('web-lesson-tools.good-work.share_for_group'), value: 'group'},
			{label: this.trans.instant('web-lesson-tools.good-work.share_for_school'), value: 'school'},
		];
		this.post_to_news_options = [
			{label: this.trans.instant('web-lesson-tools.good-work.yes'), value: 1},
			{label: this.trans.instant('web-lesson-tools.good-work.no'), value: 0},
		];

	}

	async init_profile_images() {
		let map = await this.web_lesson.get_user_photos([this.bookConfig.student.uid, this.datas.userInfo.uid]);
		let student_url = null;
		let teacher_url = null;
		if (map[this.bookConfig.student.uid]) {
			student_url = map[this.bookConfig.student.uid];
		} else {
			student_url = this.web_lesson.user_info_helper.getProfileImgPath(this.bookConfig.student);
		}
		if (map[this.datas.userInfo.uid]) {
			teacher_url = map[this.datas.userInfo.uid];
		} else {
			teacher_url = this.web_lesson.user_info_helper.getProfileImgPath(this.datas.userInfo);
		}
		this.student_profile_image = this.dom_sanitizer.bypassSecurityTrustResourceUrl(student_url);
		this.teacher_profile_image = this.dom_sanitizer.bypassSecurityTrustResourceUrl(teacher_url);

	}

	async capture_image() {
		console.log('slider.currentPageScale', this.slider.currentPageScale)

		let image = await this.slider.centerPage.ref.instance.pageCom.capture_full(1);
		console.log({image});
		let url = URL.createObjectURL(image);
		this.image_path = this.dom_sanitizer.bypassSecurityTrustResourceUrl(url);
		this.image_file = image;
	}

	async upload_image() {
		let cls: Function = this.loading.add();

		let url: string = `//${environment.serverHost}/RainbowOne/index.php/transport/Slave/upload/4`;
		let fileName: string = "good_work_" + (+new Date()).toString() + ".png";

		let formData: FormData = new FormData();
		formData.append('Filedata', this.image_file, fileName);
		formData.append('Filename', fileName);

		let asset = await this.datas.post2({url: url, data: formData, jwt: false})
		cls();
		return asset
	}

	async create() {
		if (this.comment.length > this.comment_max_length) {
			this.alert.toastError(this.trans.instant('web-lesson-tools.good-work.comment_too_long', {max_length: this.comment_max_length}));
			return;
		}

		this.loading.setZindex(1000000);
		this.loading.add('good_work_post_modal_create');
		let asset = await this.upload_image();

		let dataType = 'goodwork';
		let share = this.bookConfig.share;
		let share_id = share.id;
		let tag_id = share.tags[0].id;
		let book_id = this.bookConfig.book.id;
		let page_id = this.slider.centerPage.page.pageNode.attributes.douid;
		let owner_id = this.bookConfig.student.uid;
		let description = this.comment;
		let pageData = {
			ver: 1,
			dataType: dataType,
			bid: book_id,
			entry_id: this.slider.centerPage.page.chapter.id,
			pid: page_id,
			title: this.bookConfig.book.title,
			pageNum: this.slider.centerPage.page.pageNo,
			xml: this.slider.centerPage.page.pageNode.toXMLString(),
		};
		let extraInfo = {
			ver: 1,
			title: this.bookConfig.book.title,
			pageNum: this.slider.centerPage.page.pageNo,
		};

		let response = await this.datas.post2({
			data: {
				api: "RODisplayBoard.create",
				json: [dataType, this.share_for, share_id, tag_id, book_id, page_id, owner_id, description, JSON.stringify(pageData), JSON.stringify(extraInfo), asset, this.post_to_news]
			}
		})

		console.log({response});
		this.modal.close();
		this.loading.remove('good_work_post_modal_create');
	}
}