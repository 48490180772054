import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { SettingService } from 'src/app/service/setting.service';
import { faXmarkLarge, faList } from '@fortawesome/pro-regular-svg-icons';
import { faGridHorizontal } from '@fortawesome/pro-solid-svg-icons';
import { RoService } from 'src/app/service/ro.service';

@Component({
	selector: 'mainMenu',
	template: `
<div
    class="backdrop"
    [class.isShow]="isShow"
    [style.zIndex]="zIndex"
	*ngIf="datas.userInfo && sts.homeLayout"
    (click)="close()"
>
    <div
        #mainMenu
        class="mainMeun"
        [class.listView]="iconDisplayMode=='list'"
        [style.backgroundImage]="'linear-gradient(to bottom right, ' + color1 + ', ' + color2 + ', ' + color3 + ')'"
        (click)="$event.stopPropagation()"
    >
        <!--<img class="ro-logo {{datas.appId}}"/>-->
        <div class="school-logo-wrapper">
            <img class="school-logo" *ngIf="schoolLogoUrl" [src]="schoolLogoUrl"/>
            <div *ngIf="appInfo" class="appInfo">
                <span>{{"home-screen.main-app"|translate}}: v.{{appVersionFormatter(appInfo.version)}}</span>
                <span>{{"home-screen.install-date"|translate}}: {{appInfo.installDate.split(' ')[0]}}</span>
                <span *ngIf="isUpdateAvailable()" class="updateBtn" (click)="roUpdate()">{{"home-screen.update-to"|translate}}: {{appVersionFormatter(appInfo.latestVersion)}}</span>
            </div>
        </div>
        <div class="school-name" *ngIf="!schoolLogoUrl && sts && sts.account && sts.account.school_name">{{sts.account.school_name}}</div>
        <div #iconWrapper class="icon-wrapper" *ngIf="sts.ready">
            <perfect-scrollbar class="width-4" [ngClass]="(datas.dev.isMobile?'mobile':'')" [disabled]="datas.dev.isMobile">
                <div class="icon" (click)="backToHome()">
                    <div class="icon__image home-screen"></div>
                    <div class="icon__label">{{'home-screen.home-screen'|translate}}</div>
                </div>
                <div class="icon" [class.disabled]="isInWeb && !icon.route" *ngFor="let icon of sts.icons" (click)="sts.iconClick(icon)">
                    <div class="icon__image {{icon.label + '-' + sts.homeLayout.iconClass}}"></div>
                    <div class="icon__label">
						<ng-container *ngIf="icon.customLabel == null"> {{('home-screen.' + icon.label)|translate}}</ng-container>
						<ng-container *ngIf="icon.customLabel != null"> {{ icon.customLabel|translate}}</ng-container>
					</div>
                </div>
            </perfect-scrollbar>
        </div>
        <div class="topRightBtns">
			<div class="switchGroup">
				<span class="label">{{'home-screen.open-in-new-tab'|translate}}</span>
				<ui-switch [color]="sts.homeLayout.homePageThemeColor" [(ngModel)]="openModuleInNewTab" (ngModelChange)="toggleOpenModuleInNewTab()"></ui-switch>
			</div>
            <fa-icon [icon]="(iconDisplayMode=='grid' ? faList : faGridHorizontal)" (click)="toggleIconDisplayMode()"></fa-icon>
            <fa-icon [icon]="faXmarkLarge" (click)="close()"></fa-icon>
        </div>
    </div>
</div>
    `,
	styleUrls: ['./mainMenu.component.scss']
})

export class MainMenuComponent {
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@HostListener('window:resize', ['$event']) onResize(event) {
		this.setIconWrapperSize();
	}
	@ViewChild('mainMenu', { static: false }) public mainMenu: ElementRef<HTMLDivElement>;
	@ViewChild('iconWrapper', { static: false }) public iconWrapper: ElementRef<HTMLDivElement>;
	public faXmarkLarge: any = faXmarkLarge;
	public faList: any = faList;
	public faGridHorizontal = faGridHorizontal;

	public isShow: boolean = false;
	public zIndex: number = -100;

	@Input() public color1: string = "#00C1C8";
	@Input() public color2: string = "#008DE2";
	@Input() public color3: string = "#005D98";

	public dots: any[] = [];
	public schoolLogoUrl: string = '';

	public iconDisplayMode: string = 'grid'; // grid or list
	public openModuleInNewTab:boolean = false;

	public isInWeb: boolean = false;
	public isInApp: boolean = false;
	public appInfo: any = window['appInfo'];

	constructor(private coms: CommonService, public sts: SettingService, public datas: DataService, private router: Router, private ros: RoService) {
		this.getSchoolLogoUrl();
		this.sts.getPersonalSetting('mainMenuIconDisplayMode').then((mode: string) => {
			if (mode) this.iconDisplayMode = mode;
		});

		this.sts.getPersonalSetting('openModuleInNewTab').then((value:boolean) => {
			this.openModuleInNewTab = value || false;
		});

		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			this.isInApp = false;
			this.isInWeb = true;
		} else {
			this.isInApp = true;
			this.isInWeb = false;
		}
	}

	public open(): void {
		if (this.isInApp){
			setTimeout(() => { window.location.hash = 'ro/exit' }, 500);
		}
		this.setIconWrapperSize();
		this.zIndex = this.coms.getZIndex();
		this.isShow = true;
	}

	public close(): void {
		this.isShow = false;
		setTimeout(() => {
			if (!this.isShow) this.zIndex = -100;
		}, 500);
	}

	public backToHome(): void {
		if (this.datas.appId == 'happyreading') {
			this.router.navigate(['keiKokLibrary', this.datas.lang, this.datas.appId]);
		} else {
			// this.router.navigate(['home-screen', this.datas.lang, this.datas.appId]);
			this.router.navigate(['home-screen']);
		}
	}

	public getSchoolLogoUrl(retry: number = 0): void {
		if (this.sts.ready) {
			let result: string = ''
			if (this.sts && this.sts.homeLayout) {
				for (let key in this.sts.homeLayout) {
					let layout: any = this.sts.homeLayout[key];
					if (layout && layout.objects && layout.objects.length > 0) {
						let schoolObj: any = layout.objects.find((obj: any) => obj.name == 'school');
						result = (schoolObj && schoolObj.url) || '';
					}
				}
			}
			this.schoolLogoUrl = result;
		} else if (retry < 10) {
			setTimeout(() => this.getSchoolLogoUrl(retry + 1), 500);
		}
	}

	private setIconWrapperSize(retry: number = 0): void {
		if (this.mainMenu && this.mainMenu.nativeElement && this.iconWrapper && this.iconWrapper.nativeElement) {
			let mainMenuEle: HTMLDivElement = this.mainMenu.nativeElement;
			let iconWrapperEle: HTMLDivElement = this.iconWrapper.nativeElement;
			let availWidth: number = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 100; //padding=50
			let availHeight: number = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - iconWrapperEle.offsetTop;
			if (this.iconDisplayMode == 'grid') {
				let iconSize: number = 120;

				let numOfIcon: number = (this.sts.icons.length || 0) + 1;
				let iconOfRow: number = 7;
				let maxIconOfRow: number = Math.floor(availWidth / iconSize);
				let availRow: number = Math.floor(availHeight / iconSize);

				let rowNeeded: number = Math.ceil(numOfIcon / iconOfRow);
				while ((rowNeeded > availRow) && (iconOfRow + 1 <= maxIconOfRow)) {
					iconOfRow++;
					rowNeeded = Math.ceil(numOfIcon / iconOfRow)
				}

				mainMenuEle.style.width = (iconOfRow * iconSize + 100) + 'px';
				iconWrapperEle.style.height = Math.min((rowNeeded * iconSize), availHeight) + 'px';
			} else if (this.iconDisplayMode == 'list') {
				mainMenuEle.style.width = '300px';
				iconWrapperEle.style.height = availHeight + 'px';
			}
		} else if (retry < 10) {
			setTimeout(() => this.setIconWrapperSize(retry + 1), 500);
		}
	}

	public toggleIconDisplayMode(): void {
		this.iconDisplayMode = (this.iconDisplayMode == 'grid') ? 'list' : 'grid';
		this.sts.savePersonalSetting('mainMenuIconDisplayMode', this.iconDisplayMode);
		setTimeout(() => this.setIconWrapperSize());
	}

	public toggleOpenModuleInNewTab(): void {
		this.sts.savePersonalSetting('openModuleInNewTab', this.openModuleInNewTab);
	}

	public appVersionFormatter(version: number): string {
		try {
			let verStr: string = version.toString();
			let major: number = parseInt(verStr.substring(0, 1));
			let minor: number = parseInt(verStr.substring(1, 4));
			let patch: number = parseInt(verStr.substring(4, 6));
			return `${major}.${minor}.${patch}`;
		} catch (e) {
			return "";
		}
	}

	public roUpdate(): void {
		this.ros.request("roUpdate", {}).catch((e: any) => { });
	}

	public isUpdateAvailable(): boolean {
		let appInfo: any = window['appInfo'];
		if (appInfo && appInfo.version && appInfo.latestVersion) {
			return (this.appVersionFormatter(appInfo.version) != this.appVersionFormatter(appInfo.latestVersion));
		} else {
			return false;
		}
	}

}
