import {DOMHelper} from "src/app/common/DOMHelper";
import {MarkingSticker} from "./MarkingSticker";
import {SVGEPenPathCreator} from "./SVGEPenPathCreator";
import {map} from "rxjs/operators";
import {EPenDataType} from "./EPenDataType";
import {TimeColorStroke} from "./TimeColorStroke";
import {ByteArray} from "openfl";
import {EPenState} from "./EPenState";
import {MarkingScore} from "./MarkingScore";
import {EventEmitter} from "@angular/core";
import {PlaybackController} from "./PlaybackController";

export class NoteLayer
{

	
	note:any;
	currentWritingSVG: Element;
	writingSVG: Element;
	teacher_note_svg: Element;
	teacher_note_current_writing_svg: Element;
	student_note_svg: Element;
	student_note_current_writing_svg: Element;
	steps:any[];
	strokeIndex:number;
	public changed:boolean;
	public onRedraw = new EventEmitter();
	public onDrawing = new EventEmitter();
	public onErased = new EventEmitter();

	public can_accept_new_input = true;

	constructor(private dom:HTMLElement)
	{
		this.init();
		this.strokeIndex = 0;
	}
	hasSteps():boolean
	{
		return this.steps && this.steps.length > 0;
	}
	createStrokeID()
	{
		this.strokeIndex++;
		return this.strokeIndex;
	}
	init()
	{
		this.note = null;
		this.writingSVG = this.createSVG();
		this.currentWritingSVG = this.createSVG();
		this.teacher_note_svg = this.createSVG();
		this.teacher_note_current_writing_svg = this.createSVG();
		this.student_note_svg = this.createSVG();
		this.student_note_current_writing_svg = this.createSVG();
		this.dom.innerText = "";
		this.dom.append(this.writingSVG);
		this.dom.append(this.currentWritingSVG);
		this.dom.append(this.teacher_note_svg);
		this.dom.append(this.teacher_note_current_writing_svg);
		this.dom.append(this.student_note_svg);
		this.dom.append(this.student_note_current_writing_svg);
	}
	reset() {
		this.changed = true;
		this.steps = [];
		this.redraw();
	}

	clear() {
		// temp usage - 
		// history not support yet
		this.changed = true;
		this.steps = [];
		this.redraw();
		// add clear operation history
	}
	/**
	 * {
	 * 		steps:[
	 * 			{
	 * 				type:"add/remove/undo/redo/clear"
	 * 				time:[{start, end}],
	 * 				id:stepID
	 * 			}
	 * 		]
	 * }
	 * 
	 */

	undo() {
		// temp usage - 
		// history not support yet
		if(this.steps.length)
		{
			this.steps.pop();
			this.redraw();
		}
		
	}

	private createSVG()
	{
		var xmlns = "http://www.w3.org/2000/svg";
	    var svg = document.createElementNS(xmlns, "svg");
		svg.setAttribute("overflow", "visible");
		svg.classList.add("top-left", "absolute");
		return svg;
	}
	
	getSVGLayer() {
		return this.writingSVG;
	}
	
	compareSteps(steps1:any,steps2:any):boolean
	{
		if(steps1.length != steps2.length)
		{
			return false;
		}
		for(var i =0 ;i < steps1.length ;i++)
		{
			if(!this.compareSingleStep(steps1[i], steps2[i]))
			{
				return false;
			}
		}
		return true;
	}
	compareSingleStep(step1: any, step2: any) {
		console.log(step1, step2);
		if(!step1 || !step2 )
		{
			debugger;
		}
		var keys:string[] = [
			"active", 
			"id", // not match
			"time","type"
		];
		if(keys.find((key:string):boolean=>{
			return (step1[key] !== step2[key]);
		}) !== undefined)
		{
			return false;
		}
		if(step1.data && step2.data)
		{
			var stroke1:TimeColorStroke = step1.data;
			var stroke2:TimeColorStroke = step2.data;
			var diff:string = ["typeID", "color", "thickness","alpha"].find((key)=>{
				return stroke1[key] != stroke2[key];
			});
			if(diff !== undefined)
			{
				return false;
			}
			if(stroke1.pts && stroke2.pts)
			{
				if(stroke1.pts.length != stroke2.pts.length)
				{
					return false;	
				}
			}
		}
		
		return true;
	}
	testData(note: any) {
		if(note)
		{
			this.note = note;
			this.steps = this.bytesToSteps(note.epen);
			// var ba:ByteArray = this.stepsToByteArray(this.steps);
			// var steps2 = this.bytesToSteps(ba);
			// this.compareSteps(this.steps, steps2);
		} else {
			this.steps = [];
		}
		/*
		if(this.steps && this.steps.length)
		{
			var ba:ByteArray = this.stepsToByteArray(this.steps);
			
			return { epen:ba };
		}
		return { epen:"" };
		*/
	}

	public set data(note:any)
	{
		this.changed = false;
		if(note)
		{
			this.note = note;
			this.steps = this.bytesToSteps(note.epen);
		} else {
			this.steps = [];
		}
		this.redraw();
	}

	public get data():any
	{
		if(this.steps && this.steps.length)
		{
			var ba:ByteArray = this.stepsToByteArray(this.steps);
			
			return { epen:ba };
		}
		return { epen:"" };
	}
	private stepsToByteArray(steps:any[]):ByteArray
	{
		
		var ba:ByteArray = new ByteArray();
		for (var i:number = 0; i < steps.length; i++)
		{
			var obj:any = steps[i];
			if (obj.type == EPenDataType.DATA_TYPE_EPEN || obj.type == EPenDataType.DATA_TYPE_HIGHLIGHT)
			{
				// ba.writeByte(obj.type);
				obj.data.writeBytes(ba);
			}
			else if (obj.type == EPenDataType.DATA_TYPE_DEL)
			{
				ba.writeByte(obj.type);
				ba.writeUnsignedInt(obj.data); // index
				ba.writeUnsignedInt(obj.time);
			}
			else if(obj.type == EPenDataType.DATA_TYPE_CLEAR || obj.type == EPenDataType.DATA_TYPE_UNDO || obj.type == EPenDataType.DATA_TYPE_REDO)
			{
				// CLEAR:4, UNDO:5, REDO:6
				ba.writeByte(obj.type);
				ba.writeUnsignedInt(obj.time);
			}
			else if (obj.type == EPenDataType.DATA_TYPE_MARKING)
			{
				ba.writeByte(obj.type);
				ba.writeUnsignedInt(obj.time);
				obj.data.writeBytes(ba);
			}
			else if (obj.type == EPenDataType.DATA_TYPE_MOVE_OBJ)
			{
				ba.writeByte(obj.type);
				ba.writeUnsignedInt(obj.time);
				ba.writeUnsignedInt(obj.data); // index
				ba.writeDouble(obj.x);
				ba.writeDouble(obj.y);
			}
		}
		return ba;
	}

	public redraw()
	{
		if(this.steps && this.steps.length)
		{
			var svgCode = this.stepToSVG(this.steps);
			this.writingSVG.innerHTML = svgCode;
		} else {
			this.writingSVG.innerHTML = "";
		}
		this.currentWritingSVG.innerHTML = "";

		this.onRedraw.emit();
		console.log("redraw", this.steps);
	}
	private pointsToStroke(typeID:number, color:number, thickness:number, points:any[]):TimeColorStroke
	{
		var alpha:number = ((color >> 24) & 0xff) / 255;
		var ms:TimeColorStroke = new TimeColorStroke(typeID, color & 0xffffff, thickness, alpha);
		ms.pts = points;
		ms.build();

		// { type:type, data:stroke, time:stroke.getLatestTime() }
		// var stroke:TimeColorStroke
		return ms;
	}
	setup(state:EPenState, observable: any) {
		if(
			state.mode == "S" ||
			state.mode == "M" ||
			state.mode == "L" ||
			state.mode == "H" // highlight
		)
		{
			this.setupMarker(state, observable);
		} else if(state.mode == "E")
		{
			this.setupEraser(state, observable);
		}
	}
	setupEraser(state:EPenState, observable: any)
	{
		this.dom.classList.add("erasing");
		observable.subscribe((data)=>{
			if(!this.can_accept_new_input) {
				return ;
			}
			var event:any = data.event;
			console.log(event);
			var element:HTMLElement = event.target;
			if(element.classList.contains("note-path") && element.nodeName == "path")
			{
				let parent = element.closest("svg")
				if (parent != this.writingSVG) {
					return;
				}

				var strokeID:string = element.getAttribute("stroke-id");
				var step:any = this.removeStep(strokeID, element);
				element.remove();
				this.changed = true;
				step.removed = true;
				// this.redraw();
				this.onErased.emit(step);
			}
			if(data.type == "end")
			{
				this.dom.classList.remove("erasing");
			}
		});
	}
	private foundSteps(fn:Function):any
	{
		var length = this.steps.length;
		for(var i = 0;i < length;i++)
		{
			var step:any = this.steps[i];
			if(fn.call(this, step))
			{
				return step;
			}
		}
		return null;
		
	}
	private removeStep(strokeID:string, element:HTMLElement):any
	{
		var index:number = 0;
		var step:any = this.foundSteps((step:any)=>{
			if(!step.active) return false;
			var found:boolean = step.id == strokeID;
			if(found)
			{
				return true;
			} else {
				index++;
				return false;
			}
		});
		if(step)
		{
			var childIndex = this.getChildIndex(element.parentElement, element);
			// Array.from(document.body.children).indexOf(document.body.children.item(1))
			step.active = 0;
			this.steps.push( { 
				id:this.createStrokeID(), 
				type:EPenDataType.DATA_TYPE_DEL, 
				data:index, 
				time:this.getTimer() 
			});
			return step;
		}
		return null;
	}
	getChildIndex(container, child)
	{
		return Array.from(container.children).indexOf(child);
	}
	private getStepIndex(strokeID:string):number
	{
		var length = this.steps.length;
		for(var i = 0;i < length;i++)
		{
			var step:any = this.steps[i]
			if(step.id == strokeID) return i;
		}
		return -1;
	}
	getTimer()
	{
		return new Date().getTime();
	}
	// opacity:number = 1;
	setupMarker(state:EPenState, observable: any) 
	{
		var strokeWidth;
		var typeID:number;
		var lineCap:string = "round";
		var opacity:number = 1;
		
		if(state.mode == "S")
		{
			strokeWidth = 2;
			typeID = EPenDataType.DATA_TYPE_EPEN ;
			opacity = 1;
		} else if(state.mode == "M")
		{
			strokeWidth = 10;
			typeID = EPenDataType.DATA_TYPE_EPEN ;
			opacity = 1;
		} else if(state.mode == "L")
		{
			strokeWidth = 20;
			typeID = EPenDataType.DATA_TYPE_EPEN ;
			opacity = 1;
		} else if(state.mode == "H")
		{
			strokeWidth = 20;
			typeID = EPenDataType.DATA_TYPE_HIGHLIGHT;
			opacity = 0.5;
			lineCap = "butt";
		}
		var hexColor:string = state.color;
		var color:number = parseInt("0x"+hexColor.replace("#", ""));

		var points:any [] = [];
		var info = DOMHelper.getLocalPoint(this.dom, {x:0, y:0});
		var scale = 1/info.scale;
		observable.pipe(map(
			(data:any)=>{
				var pt:any = data.point;
				var px = pt.x * scale + info.x;
				var py = pt.y * scale + info.y;
				var time = this.getTimer();
				return {
					type:data.type,
					point:{x:px, y:py, time:time}
				};
			}
		)).subscribe((data)=>{
			if(!this.can_accept_new_input) {
				return ;
			}

			points.push(data.point);
			if(data.type == "end")
			{
				var stroke = this.pointsToStroke(typeID, color, strokeWidth, points );
				this.steps.push({
					active:1, id:this.createStrokeID(), type:typeID, data:stroke, time:stroke.getLatestTime() 
				});
				this.changed = true;
				this.redraw();
			} else {
				// 123456789
				// var path:string = "";
				var pathObject:any = {
					opacity:opacity
					// fill-opacity
					// "fill-opacity":opacity
				};
				if(typeID == EPenDataType.DATA_TYPE_EPEN)
				{
					pathObject.stroke = hexColor;
					pathObject.fill = "none";
					pathObject["stroke-width"] = strokeWidth;
					pathObject["stroke-linecap"] = lineCap;
					

					if(points.length > 0)
					{
						pathObject.d = new SVGEPenPathCreator().createMarkerPath(points);
					}
				} else if(typeID == EPenDataType.DATA_TYPE_HIGHLIGHT){
					pathObject["stroke-width"] = 1;
					pathObject.fill = hexColor;
					pathObject.d = new SVGEPenPathCreator().createPathFromPtsForHighlight(points, {width:20, height:40});
				}
				var p:string [] = ["<path"];
				for(var key in pathObject)
				{
					var value = pathObject[key];
					p.push(`${key}="${value}"`);
				}
				p.push("/>");
				this.currentWritingSVG.innerHTML = p.join(" ");
				this.onDrawing.emit(this.currentWritingSVG.innerHTML);
			}
		});
	}

	stepToSVG(steps) {
       	var paths:string[] = [];
		
        steps.forEach((step) => {
			if(step.type == EPenDataType.DATA_TYPE_EPEN || step.type == EPenDataType.DATA_TYPE_HIGHLIGHT)
			{
				if(!step.removed)
				{
					var path:string = this.getPath(step);
					paths.push(path);	
				}
			} else if(step.type == EPenDataType.DATA_TYPE_DEL)
			{
				paths.splice(step.data, 1);
				//delete paths[step.data];
			} else if(step.type == EPenDataType.DATA_TYPE_CLEAR)
			{
				paths = [];
			} else if(step.type == EPenDataType.DATA_TYPE_UNDO)
			{
				paths.pop();
			} else if(step.type == EPenDataType.DATA_TYPE_REDO)
			{
				// redo not support
				// debugger;
			} else if(step.type == EPenDataType.DATA_TYPE_MARKING)
			{
				throw "not implement yet";
				// debugger;
			} else if(step.type == EPenDataType.DATA_TYPE_MOVE_OBJ)
			{
				
			}
        })
        return  `${paths.join("")}`;
    }
	getPath(step)
    {
        if (step.type == EPenDataType.DATA_TYPE_EPEN || step.type == EPenDataType.DATA_TYPE_HIGHLIGHT ) {
            
			if(step.type == EPenDataType.DATA_TYPE_HIGHLIGHT)
			{
				var path2 = new SVGEPenPathCreator().createPathFromPtsForHighlight(step.data.pts, {width:20, height:40});
	            var hexColor = this.toHexColor(step.data.color);
	            var thickness = step.data.thickness;
				
	            return `<path 
					class="note-path"
					stroke-id="${step.id}"
	                d="${path2}" 
	                fill="${hexColor}"
	                opacity="0.5"
	                />`;
			} else {
				
				var path = new SVGEPenPathCreator().createMarkerPath(step.data.pts);
				
	            var hexColor = this.toHexColor(step.data.color);
	            var thickness = step.data.thickness;
				
				return `<path 
					class="note-path"
					stroke-id="${step.id}"
	                d="${path}" 
	                stroke="${hexColor}"   
	                stroke-width="${thickness}"
					stroke-linecap="round"
	                fill="none"
	                />`;
			}
        
        } else {
            return `<path d="M150 0 L75 200 L225 200 Z" style="fill:none;stroke:green;stroke-width:3" />`;
        }
    }
	

	calculatePoint(box, direction, vector:any)
	{
		if(direction)
		{
			return {
				direction:direction,
				a:{x:box.x + vector.x, y:box.y - vector.y}, // 右上
				b:{x:box.x - vector.x, y:box.y + vector.y} // 左下
			}
		} else
		{
			return {
				direction:direction,
				a:{x:box.x - vector.x, y:box.y - vector.y}, // 左上
				b:{x:box.x + vector.x, y:box.y + vector.y} // 右下
			}
		}
	}
	


	/*
    createPathFromPts(points)
    {
		if(points.length == 0) return "";
		if(points.length == 1)
		{
			return "M"+points.map((pt)=>{
				return `${pt.x.toFixed(2)} ${pt.y.toFixed(2)}`;
				}).join(" ");	
		} 
		var items:any = [];
		points.reduce((prev, pt, index)=>{
			if(index == 0)
			{
				items.push(`M${pt.x.toFixed(2)} ${pt.y.toFixed(2)}`);
			} else {
				var md = {x:(pt.x + prev.x)/2, y:(pt.y + prev.y)/2};
				if(index == 1)
				{
					items.push(`L${md.x.toFixed(2)} ${md.y.toFixed(2)}`);
				} else {
					items.push(`Q${prev.x.toFixed(2)} ${prev.y.toFixed(2)} ${md.x.toFixed(2)} ${md.y.toFixed(2)}`);
				}
			}
			return pt;
		}, null)
		return items.join(" ");	
    }
	*/
    toHexColor(color:number)
    {
        return "#"+color.toString(16).padStart(6, "0");
    }
	protected bytesToSteps(bytes:ByteArray):any []
	{
		console.log('bytesToSteps');
		var stepData:any [] = [];
		if (bytes) {
			bytes.position = 0;
		}
		this.strokeIndex = 0;
		while (bytes.bytesAvailable > 0)
		{
			var type:number = bytes.readByte();

			if (type == EPenDataType.DATA_TYPE_EPEN || type == EPenDataType.DATA_TYPE_HIGHLIGHT)
			{
				console.log("DATA_TYPE_EPEN", type);

				// EPEN:1, HIGHLIGHT:2
				var stroke:TimeColorStroke = TimeColorStroke.fromBytes(type, bytes);
				stepData.push( { 
					active:1,
					id:this.createStrokeID(),
					type:type, 
					data:stroke, 
					time:stroke.getLatestTime() 
				} );
			}
			else if(type == EPenDataType.DATA_TYPE_DEL)
			{
				// DELETE:3
				var index:number = bytes.readUnsignedInt();
				stepData.push( { id:this.createStrokeID(), type:type, data:index, time:bytes.readUnsignedInt() } );
			}
			else if(type == EPenDataType.DATA_TYPE_CLEAR || type == EPenDataType.DATA_TYPE_UNDO || type == EPenDataType.DATA_TYPE_REDO)
			{
				// CLEAR:4, UNDO:5, REDO:6
				stepData.push( { id:this.createStrokeID(), type:type, time:bytes.readUnsignedInt() } );
			}
			else if(type == EPenDataType.DATA_TYPE_MARKING)
			{
				// MARKING:7
				var time:number = bytes.readUnsignedInt();
				var typeID:number = bytes.readByte();
				// 0:marking pen data
				if (typeID == 1)
				{
					// extera score data
					stepData.push( { active:1, id:this.createStrokeID(), type:type, time:time, data:MarkingScore.fromBytes(bytes) } );
				}
				else if ((typeID & 0x80)!=0)
				{
					// sticker data
					stepData.push( { active:1, id:this.createStrokeID(), type:type, time:time, data:MarkingSticker.fromBytes(typeID, bytes) } );
				}
				
			}
			else if(type == EPenDataType.DATA_TYPE_MOVE_OBJ)
			{
				time = bytes.readUnsignedInt();
				index = bytes.readUnsignedInt();
				var _x:Number = bytes.readDouble();
				var _y:Number = bytes.readDouble();
				stepData.push( {id:this.createStrokeID(), type: EPenDataType.DATA_TYPE_MOVE_OBJ, data:index, x:_x, y:_y, time:time } );
			} else {
				console.log("unknown type", type);
			}
		}
		return stepData;
	}

	show() {
		this.onoff_svgs([this.writingSVG, this.currentWritingSVG], true);
	}

	show_teacher_note() {
		this.onoff_svgs([this.teacher_note_svg, this.teacher_note_current_writing_svg], true);
	}

	show_student_note() {
		this.onoff_svgs([this.student_note_svg, this.student_note_current_writing_svg], true);
	}

	hide() {
		this.onoff_svgs([this.writingSVG, this.currentWritingSVG], false);
	}

	hide_teacher_note() {
		this.onoff_svgs([this.teacher_note_svg, this.teacher_note_current_writing_svg], false);
	}

	hide_student_note() {
		this.onoff_svgs([this.student_note_svg, this.student_note_current_writing_svg], false);
	}

	onoff_svgs(svgs, show = true) {
		if (!Array.isArray(svgs)) {
			svgs = [svgs]
		}

		svgs.forEach((svg: HTMLElement) => {
			svg.style.display = show ? "block" : "none";
		});
	}


	public playback() {
		if (!this.steps || this.steps.length === 0) {
			return null;
		}

		// this.onoff_svgs([this.currentWritingSVG], true);
		// this.onoff_svgs([this.writingSVG], false);
		// this.currentWritingSVG.innerHTML = "";
		//
		// let steps = this.steps.filter(step => step.time !== 39632917);
		// console.log("playback", steps);
		//
		// let previousTime = steps[0].time;
		//
		// for (let i = 0; i < steps.length; i++) {
		// 	const step = steps[i];
		// 	await this.playStep(step, previousTime);
		// 	previousTime = step.time;
		// }
		//
		// this.onoff_svgs([this.writingSVG, this.currentWritingSVG], true);
		// this.currentWritingSVG.innerHTML = "";

		return new PlaybackController(this.currentWritingSVG, this.steps);
	}


}
