import { Injectable, ComponentFactoryResolver, ApplicationRef, ComponentRef, Injector, EmbeddedViewRef, NgZone } from '@angular/core';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { LoadingService } from 'src/app/sharedModule/loadingModule/loading.service';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { throwError, Subject } from 'rxjs';
import { catchError, delay, take, filter } from 'rxjs/operators';
import { ProgressModal } from './progress.modal';
import { AlertService } from 'src/app/service/alert.service';
import { CommonService } from 'src/app/service/common.service';
import { UploadAlertModal } from './upload-alert.modal';
import { DomSanitizer } from '@angular/platform-browser';
import { ScriptService } from 'src/app/service/script.service';
import { CameraCaptureComponent } from '../CameraCaptureModule/CameraCapture.component';
import { CaptureComponent } from './capture.component';
import { options } from '@mobiscroll/angular';
declare var WebAudioRecorder;
declare var window:any;
declare var ImageTools:any;
@Injectable({ providedIn: 'root' })


export class UploadService {
	private upload$;
	private cancelUpload$ = new Subject();
	private progressModal: any;
	private forceCancelUpload = false;
	private recordObj;
	private webAudioRecorder;
	constructor(private datas: DataService, private ros: RoService, private http: HttpClient, private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector, private als: AlertService, private coms: CommonService, private sans: DomSanitizer, private script: ScriptService, private zone: NgZone, private lds: LoadingService) {
		this.loadScript();
	}

	private loadScript() {
		this.script.load('web-audio-recorder/WebAudioRecorder.min.js');
	}

	public test() {
		let modalObj = {
			type: 'alert',
			title: 'upload.exceed-limit-1',
			confirm: () => {
				console.log('test')
			}
		};
		this.initUploadAlertModal(modalObj);
	}

	public upload(options: any = {}) {
		/* options
			size: int 限制file size
			fileType: image|video|audio|(.png|.jpg|...)
			toMediaServer: prefix:string|false
			width: int //resize to
			height: int //resize to 
		*/
		this.cancelUpload$ = new Subject();
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			return this.uploadOnWeb(options);
		} else {
			return this.uploadOnApp(options);
		}
	}

	public record(obj: any = {}) {
		/* obj
			status: ()=> 'submit'|'cancel'|true
			limit: int(second)
			curentTime: int(second)
			toMediaServer: prefix:string|false
		*/
		obj.status = obj.status ? obj.status : () => true;
		obj.currentTime = obj.currentTime ? obj.curentTime : 0;
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			return this.recordOnWeb(obj);
		} else {
			return this.recordOnApp(obj);
		}
	}

	public capture(obj: any = {}) {
		/* obj
		  toMediaServer: prefix:string|false
		*/
		obj.status = obj.status ? obj.status : () => true;
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			return this.captureOnWeb(obj);
		} else {
			return this.captureOnApp(obj);
		}
	}

	uploadOnWeb(options: any = {}) {
		return new Promise((resolve, reject) => {
			let el = document.createElement('input');
			el.style.visibility = 'hidden';
			el.setAttribute('type', 'file');
			if (['image', 'video', 'audio'].indexOf(options.fileType) > -1) {
				el.setAttribute('accept', options.fileType + '/*');
			} else if (options.fileType) {
				if (options.fileType.indexOf('.') === -1){
					el.setAttribute('accept', "." + options.fileType);
				} else {
					el.setAttribute('accept', options.fileType);
				}
			}
			document.body.appendChild(el);
			el.onchange = (event: any) => {
				console.log("event", event)
				let file: any = event.target.files[0];
				console.log("file",file)
				if (options.size) {
					if (file.size > options.size) {
						reject({ msg: 'file-size-exist',  size:options.size});
						return;
					}
				}
				if (options.fileType && ['image', 'video', 'audio'].indexOf(options.fileType) === -1){
					const arr = file.name.split('.');
					const ext = arr[arr.length - 1];
					const fileTypeArray = options.fileType.replace(/\./g, "").split(','); 
					if (fileTypeArray.indexOf(ext) == -1){
						reject({ msg: 'file-type-wrong'});
						return;
					}
				}
				if (this.upload$) {
					this.upload$.unsubscribe();
				}
				if (options.resizeRatio){
					const reader = new FileReader();
					reader.onload = ()=>{
						const image = new Image();
						image.onload = ()=>{
							options.width = (image.width || 0) * options.resizeRatio;
							options.height = (image.height || 0) * options.resizeRatio;
							this.resizeImageFile(file, options).then(resizedFile=>{
								this.upload$ = this.httpCall(resizedFile, options).subscribe((res: any) => {
									this.upload$.unsubscribe();
									this.upload$ = null;
									document.body.removeChild(el);
									el = null;
									let obj: any = {
										url: res.body.url,
										size: res.body.size,
										originFilename: file.name,
										type: file.type,
										width: options.width,
										height: options.height
									};
									resolve(obj);
								});
							});
						};
						image.src = '' + reader.result;
					};
					reader.readAsDataURL(file);
				} else if (options.width){
					const resizeCanvas = document.createElement('canvas');
					resizeCanvas.width = options.width;
					resizeCanvas.height = 500;
					this.resizeImageFile(file, options).then((resizedFile:any)=>{
						const imageEl = new Image();
						const url = URL.createObjectURL(resizedFile);
						imageEl.onload = ()=>{
							const width = imageEl.width;  
							const height = imageEl.height;  
							URL.revokeObjectURL(url);
							this.upload$ = this.httpCall(resizedFile, options).subscribe((res: any) => {
								this.upload$.unsubscribe();
								this.upload$ = null;
								document.body.removeChild(el);
								el = null;
								let obj: any = {
									url: res.body.url,
									size: res.body.size,
									originFilename: file.name,
									type: file.type,
									width: width,
									height: height
								};
								resolve(obj);
							});
						};
						imageEl.src = url;
					});
				} else {
					console.log("4444", file)
					this.upload$ = this.httpCall(file, options).subscribe((res: any) => {
						console.log("http call res", res)
						this.upload$.unsubscribe();
						this.upload$ = null;
						document.body.removeChild(el);
						el = null;
						let obj: any = {
							url: res.body.url,
							size: res.body.size,
							originFilename: file.name,
							type: file.type
						};
						console.log(obj);
						resolve(obj);
					});
				}
			};

			const elClickTimestamp = +new Date();
			el.oncancel = ()=>{
				if (+new Date() - elClickTimestamp > 500){
					reject({ msg: 'cancel-by-close-window' });
				}
			};
			el.click();
		});
	}

	uploadFileObject(file: File, showAlert = true): Promise<any> {
		return new Promise((resolve, reject) => {
			let options: any = { fileType: "file" };
			var extension:string = file.name.indexOf(".") != -1 ? file.name.split(".").pop().toLowerCase() :null;
			var uploadType:any = 0;
			if (["pdf", "doc", "docx"].indexOf(extension) != -1) 
			{
				options = { fileType: "file" };
				uploadType = "DOC";
			} else if (["mp3", "m4a", "wav"].indexOf(extension) != -1) {
				options = { fileType: "audio" };
				uploadType = 2;
			} else if ( [ "jpg", "jpeg", "png", "gif"].indexOf(extension ) != -1) 
			{
				options = { fileType: "image" };
				uploadType = 1;
			} else if ( ["mp4", "mov"].indexOf(extension) != 1) {
				options = { fileType: "video" };
				uploadType = 3;
			}

			var hostName:string = location.hostname;
			let url: string ; // = uploadController + "/transport/Slave/upload/" + uploadType;
			if (["localhost", "127.0.0.1"].indexOf(hostName) != -1 || location.port != '')
			{
				url = `//ro2.azurewebsites.net/RainbowOne/index.php/transport/Slave/upload/${uploadType}`;
			} else {
				url = `//${hostName}/RainbowOne/index.php/transport/Slave/upload/${uploadType}`;
			}
			

			let fd = new FormData();
			fd.append('Filename', file.name);
			fd.append('Filedata', file);

			let headers = new HttpHeaders();
			if (this.datas.jwt) {
				headers = headers.append("Authorization", 'Bearer ' + this.datas.jwt);
			}
			this.http.post(url, fd, {
				headers: headers,
				reportProgress: true,
				observe: 'events'
			}).pipe(
				filter((event: any) => {
					let result = false;
					switch (event.type) {
						case HttpEventType.Sent:
							this.zone.run(() => {
								if (showAlert) {
									this.initProgressModal(file, options, () => {
										if (this.upload$) {
											this.upload$.unsubscribe();
										}
										this.upload$ = null;
									});
								}
							});
							break;
						case HttpEventType.UploadProgress:
							this.zone.run(() => {
								let eventTotal = event.total ? event.total : 1;
								let progress = Math.round(event.loaded / eventTotal * 100);
								console.log(`Uploaded! ${progress}%`);
								if (this.progressModal) {
									this.progressModal.data = { completedSize: event.loaded, totalFileSize: eventTotal, options: options };
								}
							});
							break;
					}
					if (event.body && event.body.url){
						console.log('Uploaded OK');
						if (this.progressModal) {
							if (this.cancelUpload$) {
								this.cancelUpload$.unsubscribe();
								this.cancelUpload$ = null;
							}
							if (this.progressModal.nativeElement) {
								document.body.removeChild(this.progressModal.nativeElement);
							}
							this.progressModal = null;
						}
						result = true;
					}
					return result;
				}),
				catchError((error) => {
					let errorMessage = '';
					if (error.error instanceof ErrorEvent) {
						errorMessage = error.error.message;
					} else {
						errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
					}
					return throwError(errorMessage);
				})
			).pipe(catchError((err: any) => {
				reject({ msg: 'upload-fail' });
				return throwError(err);
			})).subscribe((res: any) => {
				console.log(">> upload OK", res);
				resolve(res.body);

				/*if (res.body.url){
					resolve({url: res.body.url, size: file.size, originFilename: file.name});
				}*/
			});
		});
	}


	httpCall(file, options) {
		let url = this.datas.apiUrl;
		console.log("options", options)
		let fd = new FormData();
		fd.append('api', 'KenCore.fileUpload');
		fd.append('json', JSON.stringify([options.toMediaServer === true ? 1 : (options.toMediaServer || 0), options]));
		fd.append('file', file);
		let headers = new HttpHeaders();
		if (this.datas.jwt) {
			headers = headers.append("Authorization", 'Bearer ' + this.datas.jwt);
		}
		return this.http.post(url, fd, {
			headers: headers,
			reportProgress: true,
			observe: 'events'
		}).pipe(
			filter((event: any) => {
				let result = false;
				switch (event.type) {
					case HttpEventType.Sent:
						this.zone.run(() => {
							this.initProgressModal(file, options, () => {
								if (this.upload$) {
									this.upload$.unsubscribe();
								}
								this.upload$ = null;
							});
						});
						break;
					case HttpEventType.UploadProgress:
						this.zone.run(() => {
							let eventTotal = event.total ? event.total : 1;
							let progress = Math.round(event.loaded / eventTotal * 100);
							console.log(`Uploaded! ${progress}%`);
							if (this.progressModal) {
								this.progressModal.data = { completedSize: event.loaded, totalFileSize: eventTotal, options: options };
							}
						});
						break;
				}
				if (event.body && event.body.url){
					console.log('Uploaded OK!');
					if (this.progressModal) {
						if (this.cancelUpload$) {
							this.cancelUpload$.unsubscribe();
							this.cancelUpload$ = null;
						}
						if (this.progressModal.nativeElement) {
							document.body.removeChild(this.progressModal.nativeElement);
						}
						this.progressModal = null;
					}
					result = true;
				}
				return result;
			}),
			catchError((error) => {
				let errorMessage = '';
				if (error.error instanceof ErrorEvent) {
					errorMessage = error.error.message;
				} else {
					errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
				}
				return throwError(errorMessage);
			})
		);
	}

	initProgressModal(file, options, cancel, displayLabel = null) {
		this.zone.run(() => {
			let componentRef = this.componentFactoryResolver
				.resolveComponentFactory(ProgressModal)
				.create(this.injector);
			this.appRef.attachView(componentRef.hostView);
			this.progressModal = componentRef.instance;
			this.progressModal.data = { completedSize: 0, totalFileSize: file.size, options: options };
			if (displayLabel) {
				this.progressModal.data.displayLabel = displayLabel;
			}
			let subscription = this.progressModal.cancel.subscribe(() => {
				cancel();
				if (this.progressModal.nativeElement) {
					document.body.removeChild(this.progressModal.nativeElement);
				}
				this.progressModal = null;
				subscription.unsubscribe();
			});
			this.progressModal.nativeElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			document.body.appendChild(this.progressModal.nativeElement);
		});
	}

	initUploadAlertModal(obj) {
		this.zone.run(() => {
			let componentRef = this.componentFactoryResolver
				.resolveComponentFactory(UploadAlertModal)
				.create(this.injector);
			this.appRef.attachView(componentRef.hostView);
			let uploadAlertModal: any = componentRef.instance;
			uploadAlertModal.nativeElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			uploadAlertModal.data = {
				type: obj.type,
				title: obj.title,
				confirmText: obj.confirmText ? obj.confirmText : 'confirm',
				cancelText: obj.cancelText ? obj.cancelText : 'cancel',
				confirm: () => {
					if (obj.confirm) {
						obj.confirm();
					}
					uploadAlertModal.data.close();
				},
				close: obj.close ? obj.close : () => { document.body.removeChild(uploadAlertModal.nativeElement); }
			};
			document.body.appendChild(uploadAlertModal.nativeElement);
		});
	}

	uploadOnApp(options: any = {}) {
		return new Promise((resolve, reject) => {
			let roApi = 'prepareCameraRoll';
			if (options.fileType == 'video') {
				roApi = 'prepareVideoRoll';
			}
			this.ros.request(roApi).then((o: any) => {
				this.forceCancelUpload = false;
				this.ros.request('uploadFileWithStatus', o).then(o2 => {
					const checkUploadStatus = (data) => {
						if (this.forceCancelUpload) {
							return;
						}
						this.ros.request('checkUploadStatus', data).then(res => {
							if (!this.forceCancelUpload && this.progressModal == null) {
								let file = { size: res.totalFileSize };
								this.initProgressModal(file, options, () => {
									this.forceCancelUpload = true;
									this.ros.request('cancelUpload', data);
									reject({ msg: 'cancel' });
								});
							}
							if (res.status == 'completed' && this.forceCancelUpload == false) {
								if (this.progressModal) {
									this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
								}
								setTimeout(() => {
									if (this.progressModal) {
										if (this.progressModal.nativeElement) {
											document.body.removeChild(this.progressModal.nativeElement);
										}
										this.progressModal = null;
										if (this.cancelUpload$) {
											this.cancelUpload$.unsubscribe();
										}
										this.cancelUpload$ = null;
									}
									this.upload$ = null;
									let url = res.asset.url.substr(res.asset.url.indexOf('tmp_upload'));
									resolve({ url: url, size: res.totalFileSize, len: res.asset.duration, originFilename: res.asset.filename });
								}, 1000);
							} else if (this.forceCancelUpload) {
								this.cancelUpload$.next();
							} else if (res.status == 'uploading' || res.status == 'pending' || res.status == 'uploadCompleted') {
								this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
								this.upload$ = setTimeout(() => { checkUploadStatus(data); }, 1500);
							} else if (res.status == 'cancel') {
								this.cancelUpload$.next();
							} else if (res.status == 'error') {
								this.cancelUpload$.next();
							} else {
								this.cancelUpload$.next();
								this.als.alert('unknown response:' + JSON.stringify(res));
							}
						});
					};
					this.upload$ = setTimeout(() => { checkUploadStatus(o) }, 10);
				});
			}).catch(reason => {
				if (reason != 'cancel') {
					reject({ type: 'no-permission' });
					let modalObj = {
						type: 'confirm',
						title: 'upload.set-image-permission',
						confirmText: 'setting',
						cancelText: 'cancel',
						confirm: () => { this.ros.request('openPermissionSetting'); },
					};
					this.initUploadAlertModal(modalObj);
				}
				this.lds.removeAll();
			});
		});
	}

	recordOnWeb(obj) {
		if (this.webAudioRecorder) {
			this.webAudioRecorder.cancelRecording();
		}
		return new Promise((resolve, reject) => {
			if (location.protocol == 'http:' && location.port == '') {
				this.als.okAlert('common.support-https-only');
				reject({ msg: 'support-https-only' });
				return;
			}
			if (navigator.mediaDevices == null || navigator.mediaDevices.getUserMedia == null) {
				this.als.okAlert('upload.not-support-audio-record');
				reject({ msg: 'not-support-audio-record' });
				return;
			}
			let timer$;
			navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream) => {
				let audioContext = null;
				if (window.AudioContext) {
					audioContext = new window.AudioContext();
				}
				if (audioContext == null && window.webkitAudioContext) {
					audioContext = new window.webkitAudioContext();
				}
				if (audioContext == null) {
					reject({ msg: 'not-support-audio-record' });
				}
				let input = audioContext.createMediaStreamSource(stream);
				this.webAudioRecorder = new WebAudioRecorder(input, { encoding: 'mp3', workerDir: this.script.assetsUrl + 'web-audio-recorder/' });
				this.webAudioRecorder.onComplete = (recorder, blob) => {
					const file = new File([blob], 'record.mp3', { type: blob.type });
					let options = { fileType: 'audio', toMediaServer: obj.toMediaServer ? obj.toMediaServer : false };
					this.lds.removeAll();
					this.httpCall(file, options).pipe(catchError((err: any) => {
						reject({ msg: 'upload-fail' });
						return throwError(err);
					})).subscribe((res: any) => {
						console.log(res.body);
						if (res.body.url) {
							resolve({ url: res.body.url, size: file.size, len: obj.currentTime * 1000 });
						} else {
							debugger;
						}
					});
				};
				this.webAudioRecorder.setOptions({
					timeLimit: obj.limit ? obj.limit : 3600,
					encodeAfterRecord: true,
					mp3: { bitRate: 160 }
				});
				this.webAudioRecorder.startRecording();
				const checkComplete = () => {
					obj.currentTime = obj.currentTime + 1;
					let action = obj.status();
					if (action == 'submit') {
						this.lds.add('recordOnWeb');
						stream.getAudioTracks()[0].stop();
						this.webAudioRecorder.finishRecording();
					} else if (obj.currentTime >= obj.limit) {
						stream.getAudioTracks()[0].stop();
						this.webAudioRecorder.finishRecording();
						let msg = 'upload.record-exceed-limit-' + obj.limit;
						this.als.okAlert(msg);
					} else if (action == 'cancel') {
						reject({ msg: 'cancel' });
					} else {
						timer$ = setTimeout(() => { checkComplete(); }, 1000);
					}
				};
				setTimeout(() => { checkComplete(); }, 1000);
			}).catch(err => {
				this.lds.removeAll();
				if (err['message']) {
					this.als.okAlert(err['message']);
				} else if (err['msg']) {
					this.als.okAlert(err['msg']);
				} else if (err !== 'cancel') {
					this.als.okAlert(err);
				}
				reject({ msg: err });
			});
		});
	}

	recordOnApp(obj) {
		return new Promise((resolve, reject) => {
			this.ros.request('recordCancel').then(() => {
				setTimeout(() => {
					this.ros.request('recordStart').then(() => {
						let timer$ = setInterval(() => {
							let action = obj.status();
							if (action == 'cancel') {
								clearInterval(timer$);
								this.ros.request('recordCancel').then(() => {
									resolve({ msg: 'cancel' });
								});
							} else if (action == 'submit') {
								clearInterval(timer$);
								this.ros.request('prepareAudioSubmit').then((o) => {
									this.forceCancelUpload = false;
									let options = { fileType: 'audio' };
									this.ros.request('uploadFileWithStatus', o).then(o2 => {
										const checkUploadStatus = (data) => {
											if (this.forceCancelUpload) {
												return;
											}
											this.ros.request('checkUploadStatus', data).then(res => {
												if (!this.forceCancelUpload && this.progressModal == null) {
													let file = { size: res.totalFileSize };
													this.initProgressModal(file, options, () => {
														this.forceCancelUpload = true;
														this.ros.request('cancelUpload', data);
														reject({ msg: 'cancel' });
													});
												}
												if (res.status == 'completed' && this.forceCancelUpload == false) {
													if (this.progressModal) {
														this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
													}
													setTimeout(() => {
														if (this.progressModal) {
															if (this.progressModal.nativeElement) {
																document.body.removeChild(this.progressModal.nativeElement);
															}
															this.progressModal = null;
															if (this.cancelUpload$) {
																this.cancelUpload$.unsubscribe();
															}
														}
														this.upload$ = null;
														let url = res.asset.url.substr(res.asset.url.indexOf('tmp_upload'));
														resolve({ url: url, size: res.totalFileSize, len: res.asset.duration });
													}, 1000);
												} else if (this.forceCancelUpload) {
													this.cancelUpload$.next();
												} else if (res.status == 'uploading' || res.status == 'pending' || res.status == 'uploadCompleted') {
													this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
													this.upload$ = setTimeout(() => { checkUploadStatus(data); }, 1500);
												} else if (res.status == 'cancel') {
													this.cancelUpload$.next();
												} else if (res.status == 'error') {
													this.cancelUpload$.next();
												} else {
													this.cancelUpload$.next();
												}
											});
										};
										this.upload$ = setTimeout(() => { checkUploadStatus(o); }, 10);
									});
								}).catch(err => {
									this.als.okAlert(err);
								});
							} else if (obj.limit != null && obj.currentTime > obj.limit) {
								clearInterval(timer$);
								let modalObj = {
									type: 'alert',
									title: 'upload.exceed-limit-' + obj.limit,
									confirm: () => {
										this.ros.request('prepareAudioSubmit').then((o) => {
											this.forceCancelUpload = false;
											let options = { fileType: 'audio' };
											this.ros.request('uploadFileWithStatus', o).then(o2 => {
												const checkUploadStatus = (data) => {
													if (this.forceCancelUpload) {
														return;
													}
													this.ros.request('checkUploadStatus', data).then(res => {
														if (!this.forceCancelUpload && this.progressModal == null) {
															let file = { size: res.totalFileSize };
															this.initProgressModal(file, options, () => {
																this.forceCancelUpload = true;
																this.ros.request('cancelUpload', data);
																reject({ msg: 'cancel' });
															});
														}
														if (res.status == 'completed' && this.forceCancelUpload == false) {
															if (this.progressModal) {
																this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
															}
															setTimeout(() => {
																if (this.progressModal) {
																	if (this.progressModal.nativeElement) {
																		document.body.removeChild(this.progressModal.nativeElement);
																	}
																	this.progressModal = null;
																	if (this.cancelUpload$) {
																		this.cancelUpload$.unsubscribe();
																	}
																	this.cancelUpload$ = null;
																}
																this.upload$ = null;
																let url = res.asset.url.substr(res.asset.url.indexOf('tmp_upload'));
																resolve({ url: url, size: res.totalFileSize, len: res.asset.duration });
															}, 1000);
														} else if (this.forceCancelUpload) {
															this.cancelUpload$.next();
														} else if (res.status == 'uploading' || res.status == 'pending' || res.status == 'uploadCompleted') {
															this.progressModal.data = { completedSize: res.completedSize, totalFileSize: res.totalFileSize, options: options };
															this.upload$ = setTimeout(() => { checkUploadStatus(data); }, 1500);
														} else if (res.status == 'cancel') {
															this.cancelUpload$.next();
														} else if (res.status == 'error') {
															this.cancelUpload$.next();
														} else {
															this.cancelUpload$.next();
														}
													});
												};
												this.upload$ = setTimeout(() => { checkUploadStatus(o) }, 10);
											});
										});
									}
								};
								this.initUploadAlertModal(modalObj);
							} else {
								obj.currentTime = obj.currentTime + 1;
							}
						}, 1000);
					}).catch((err) => {
						reject({ type: 'no-permission' });
						let modalObj = {
							type: 'confirm',
							title: 'upload.set-image-permission',
							confirmText: 'setting',
							cancelText: 'cancel',
							confirm: () => { this.ros.request('openPermissionSetting'); },
						};
						this.initUploadAlertModal(modalObj);
					});
				}, 10);
			});
		});
	}


	captureOnWeb(obj) {
		return new Promise((resolve, reject) => {
			if (location.protocol == 'http:' && location.port == '') {
				this.als.okAlert('common.support-https-only');
				return;
			}
			if (navigator.mediaDevices == null || navigator.mediaDevices.getUserMedia == null) {
				this.als.okAlert('upload.not-support-capture');
				reject({ msg: 'not-support-audio-record' });
				return;
			}
			if (document.querySelector('CameraCapture')) {
				reject({ msg: 'already-exist' });
				return;
			}
			let componentRef = this.componentFactoryResolver
				.resolveComponentFactory(CameraCaptureComponent)
				.create(this.injector);
			this.appRef.attachView(componentRef.hostView);
			let cameraCaptureComponent: any = componentRef.instance;
			cameraCaptureComponent.nativeElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			document.body.appendChild(cameraCaptureComponent.nativeElement);
			cameraCaptureComponent.lanuch().then(success => {
				let file = success.file;
				this.httpCall(file, obj).pipe(catchError((err: any) => {
					this.removeElement('CameraCapture');
					reject({ msg: 'upload-fail' });
					return throwError(err);
				})).subscribe((res: any) => {
					this.removeElement('CameraCapture');
					if (res.body.url) {
						resolve({ url: res.body.url, size: file.size });
					}
				});
			}).catch(err => {
				this.removeElement('CameraCapture');
				reject(err);
			});
		});
	}

	removeElement(selector) {
		let ele = document.querySelector(selector);
		if (ele) {
			document.body.removeChild(ele);
		}
	}

	captureOnApp(options) {
		return new Promise((resolve, reject) => {
			this.ros.request("supportUploadStatus", {}).then(
				(o: any) => {
					this.ros.request("prepareTakePhoto", {}).then((o) => {
						this.uploadWithProgress("image", o, options).then((data: any) => {
							resolve({ url: data.path, size: data.size });
						}).catch(err => {
							reject(err);
							return;
						});
					}).catch(err => {
						let msg: any = this.tranlateErr(err, 'prepareTakePhoto');
						let errorType = err ? err.type : '';
						if (errorType == 'no permission') {
							let modalObj = {
								type: 'confirm',
								title: 'upload.set-image-permission',
								confirmText: 'setting',
								cancelText: 'cancel',
								confirm: () => { this.ros.request('openPermissionSetting'); },
							};
							this.initUploadAlertModal(modalObj);
							reject({ type: 'no-permission' });
						} else if (errorType == 'function not support') {
							this.als.okAlert('upload.not-support-image-record');
							reject({ type: 'not-support-image-record' });
						} else if (errorType == 'no support') {
							this.als.okAlert('upload.no-image-record-device');
							reject({ type: 'no-image-record-device' });
						} else if (msg != 'cancel') {
							this.als.okAlert(msg);
							reject({ type: msg });
						}
					});
				}
			).catch(err => {
				err = this.tranlateErr(err, 'supportUploadStatus');
				this.als.okAlert(err);
				reject(err);
			});
		});
	}

	private uploadWithProgress(type: string, data: any, options: any = {}) {
		return new Promise((resolve, reject) => {
			this.ros.request("uploadFileWithStatus", data).then(o => {
				const checkUploadStatus = (type, data) => {
					this.ros.request('checkUploadStatus', data).then(res => {
						let modalData: any = res;
						modalData.uploadType = type;
						this.progressModal = {
							data: modalData, cancel: () => {
								setTimeout(() => { this.progressModal = null; }, 100);
								this.ros.request('cancelUpload', data);
								reject({ msg: 'cancel' });
								return;
							}
						};
						if (res.status == 'completed' && this.forceCancelUpload == false) {
							let asset = res.asset;
							let filename = asset.url.substr(asset.url.indexOf('tmp_upload'));
							if (options.toMediaServer) {
								this.datas.post('KenCore.move_tmp_upload_to_media_server', [filename, options.toMediaServer]).subscribe((res2: any) => {
									clearTimeout(uploadTimer);
									if (type == 'video') {
										resolve({ path: res2.url, size: res.totalFileSize, length: asset.duration ? asset.duration : 0 });
									} else if (type == 'image') {
										resolve({ path: res2.url, size: res.totalFileSize });
									} else {
										resolve({ path: res2.url });
									}
								});
							} else {
								clearTimeout(uploadTimer);
								this.cancelUpload$.next();
								if (type == 'video') {
									resolve({ path: filename, size: res.totalFileSize, length: asset.duration ? asset.duration : 0 });
								} else if (type == 'image') {
									resolve({ path: filename, size: res.totalFileSize });
								} else {
									resolve({ path: filename });
								}
								return;
							}

						} else if (res.status == 'uploading' || res.status == 'pending' || res.status == 'uploadCompleted') { //not-complete
							uploadTimer = setTimeout(() => { checkUploadStatus(type, data); }, 1500);
						} else if (res.status == 'cancel') {
							this.cancelUpload$.next();
							reject({ msg: 'cancel' });
						} else {
							this.cancelUpload$.next();
							let msg = JSON.stringify(res);
							this.als.okAlert(msg);
							reject({ msg: msg });
						}
					}).catch(err => {
						reject('checkUploadStatus:' + err);
					});
				};
				let uploadTimer = setTimeout(() => { checkUploadStatus(type, data); }, 10);
			}).catch(err => {
				reject('uploadFileWithStatus:' + err);
			});
		});
	}

	recordVideo(obj: any = {}) {
		/* obj
		  status: ()=> 'submit'|'cancel'|true
		  limit: int(second)
		  curentTime: int(second)
		  toMediaServer: prefix:string|false
		*/
		obj.status = obj.status ? obj.status : () => true;
		obj.currentTime = obj.currentTime ? obj.curentTime : 0;
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			if (this.datas.dev.isMobile) {
				return this.recordVideoOnWebInput(obj);
			} else {
				return this.recordVideoOnWebMR(obj);
			}
		} else {
			return this.recordVideoOnApp(obj);
		}
	}

	recordVideoOnWebMR(options) {
		return new Promise((resolve, reject) => {
			if (location.protocol == 'http:' && location.port == '') {
				let modalObj = {
					type: 'alert',
					title: 'support-https-only',
				};
				this.als.okAlert('common.support-https-only');
				reject({ msg: 'support-https-only' });
				return;
			}
			if (navigator.mediaDevices == null || navigator.mediaDevices.getUserMedia == null) {
				this.als.okAlert('upload.not-support-video-record');
				reject({ msg: 'not-support-video-record' });
				return;
			}
			if (document.querySelector('ng-capture')) {
				this.als.okAlert('upload.record-in-use');
				reject({ msg: 'already-exist' });
				return;
			}
			let componentRef = this.componentFactoryResolver
				.resolveComponentFactory(CaptureComponent)
				.create(this.injector);
			this.appRef.attachView(componentRef.hostView);
			let captureComponent: any = componentRef.instance;
			// captureComponent.options = obj;
			captureComponent.close.subscribe((e: any) => {
				if (typeof e == 'object') {
					this.removeElement('ng-capture');
					this.als.okAlert(e.message);
					reject({ msg: e.message });
					return;
				} else if (e == 'cancel') {
					this.removeElement('ng-capture');
					reject({ msg: 'cancel' });
					return;
				} else if (e == 'timeout') {
					this.removeElement('ng-capture');
					let msg = 'upload.record-exceed-limit-' + options.limit;
					this.als.okAlert(msg);
					reject({ msg: 'cancel' });
					return;
				} else if (e == 'submit') {
					let content = captureComponent.uInt8Array;
					let file = captureComponent.file;
					this.removeElement('ng-capture');
					this.httpCall(file, options).pipe(catchError((err: any) => {
						reject({ msg: 'upload-fail' });
						return throwError(err);
					})).subscribe((res: any) => {
						if (res.body.url) {
							resolve({ url: res.body.url, size: file.size });
						}
					});
				}
			});
			captureComponent.nativeElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
			document.body.appendChild(captureComponent.nativeElement);
		});
	}

	recordVideoOnWebInput(options: any = {}) {
		return new Promise((resolve, reject) => {
			let el = document.createElement('input');
			el.style.visibility = 'hidden';
			el.setAttribute('type', 'file');
			el.setAttribute('accept', 'video/mp4');
			el.setAttribute('capture', 'camcorder');
			document.body.appendChild(el);
			el.onchange = (event: any) => {
				let file: any = event.target.files[0];
				/*
				if (file.name.substr(-3) != 'mp4'){
				  let blob = file.slice(0, file.size, 'video/mp4');
				  file = new File([blob], 'video.mp4', { type: 'video/mp4' });
				}*/
				if (options.size) {
					if (file.size > options.size) {
						reject({ msg: 'file-size-exist',  size:options.size});
						return;
					}
				}
				if (this.upload$) {
					this.upload$.unsubscribe();
				}
				this.upload$ = this.httpCall(file, options).subscribe((res: any) => {
					this.upload$.unsubscribe();
					this.upload$ = null;
					document.body.removeChild(el);
					el = null;
					let obj: any = {
						url: res.body.url,
						size: res.body.size,
						originFilename: file.name,
						type: file.type
					};
					resolve(obj);
				});
			};
			const elClickTimestamp = +new Date();
			el.oncancel = ()=>{
				if (+new Date() - elClickTimestamp > 500){
					reject({ msg: 'cancel-by-close-window' });
				}
				reject({ msg: 'cancel-by-close-window' });
			};
			el.click();
		});
	}

	recordVideoOnApp(options) {
		return new Promise((resolve, reject) => {
			this.ros.request("supportUploadStatus", {}).then(
				(o: any) => {
					this.ros.request("prepareTakeVideo", {}).then((o) => {
						this.uploadWithProgress("video", o, options).then((data: any) => {
							resolve({ url: data.path, size: data.size, length: data.length });
						}).catch(err => {
							reject(err);
						});
					}).catch(err => {
						let msg: any = this.tranlateErr(err, 'prepareTakeVideo');
						let errorType = err ? err.type : '';
						if (errorType == 'no permission') {
							let modalObj = {
								type: 'confirm',
								title: 'upload.set-image-permission',
								confirmText: 'setting',
								cancelText: 'cancel',
								confirm: () => { this.ros.request('openPermissionSetting'); },
							};
							this.initUploadAlertModal(modalObj);
							reject({ type: 'no-permission' });
						} else if (errorType == 'function not support') {
							this.als.okAlert('upload.not-support-image-record');
							reject({ type: 'not-support-image-record' });
						} else if (errorType == 'no support') {
							this.als.okAlert('upload.no-image-record-device');
							reject({ type: 'no-image-record-device' });
						} else if (msg != 'cancel') {
							this.als.okAlert(msg);
							reject({ type: msg });
						}
					});
				},
				(reason: any) => {
					reason = this.tranlateErr(reason, 'supportUploadStatus');
					this.als.okAlert(reason);
					reject(reason);
				}
			);
		});
	}

	tranlateErr(err: any, api) {
		if (typeof err == 'object') {
			if (err.msg) {
				return err.msg;
			} else {
				return JSON.stringify(err);
			}
		} else if (err == 'Handler Not Found') {
			return api + ':' + err;
		}
	}

	resizeImageFile(file, options){
		return new Promise((resolve, reject)=>{
			this.script.load('js/imageTool.js').then(()=>{
				ImageTools.resize(file, options, (blob, didItResize)=>{
					resolve(blob);
				});
			});
		});
	}

	browseToFile(options:any = {}){
		return new Promise((resolve, reject)=>{
			let el:any = document.createElement('input');
			el.style.visibility = 'hidden';
			el.setAttribute('type', 'file');
			if (['image', 'video', 'audio'].indexOf(options.fileType) > -1) {
				el.setAttribute('accept', options.fileType + '/*');
			} else if (options.fileType) {
				el.setAttribute('accept', options.fileType);
			}
			document.body.appendChild(el);
			el.onchange = (event:any)=>{
				let file:any = event.target.files[0];
				if (options.size){
					if (file.size > options.size){
						const msg = {tc:'檔案太大',sc:'档案太大',en:'file size too large.'}[this.datas.lang];
						this.als.toastError(msg);
						reject({msg: msg});
						return;
					}
				}
				resolve(file);
			};

			const elClickTimestamp = +new Date();
			el.oncancel = ()=>{
				if (+new Date() - elClickTimestamp > 500){
					reject({ msg: 'cancel-by-close-window' });
				}
			};

			el.click();
		});
	}

}
