import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'lock-screen',
	template: `
		<div class="lock-screen"></div>
	`,
	styleUrls: ['./lock-screen.component.scss'],
})

export class LockScreenComponent implements OnInit {
	constructor() {
	}

	ngOnInit() {
	}
}