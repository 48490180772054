import { Component, Input, Output, EventEmitter, ViewChild,HostBinding, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/service/alert.service';
import { DataService } from 'src/app/service/data.service';
import { StorageService } from 'src/app/service/storage.service';
import { LoadingService } from '../loadingModule/loading.service';
import * as moment from 'moment';
import { ModalComponent } from '../modalModule/modal.component';
import { TagGroupService } from '../tagGroupModule/tagGroup.service';
import { SubjectService } from '../subjectModule/subject.service';
import { TargetSelectorComponent } from '../targetSelectorModule/targetSelector.component';
import { SettingService } from 'src/app/service/setting.service';
import { CommonService } from 'src/app/service/common.service';
import { OupService } from 'src/app/service/oup.service';

@Component({
	selector: 'create-share2',
	templateUrl: 'create-share2.component.html',
	styleUrls: ['create-share2.component.scss']
})

export class CreateShare2Component implements OnInit{
	@HostBinding('style.zIndex') public hostZIndex = 99;
	@HostBinding('style.display') public hostDisplay:string = 'none';
	@ViewChild('targetSelector', {static:false}) public targetSelector:TargetSelectorComponent;

	@ViewChild('modal', {static:false}) private modal:ModalComponent;
	@Input() shareModal: any;
	public shortUrlsModal: any;
	@ViewChild('dateTimePicker', {static: false}) dateTimePicker;
	@ViewChild('preset', {static: false}) preset;
	@Output() close: EventEmitter<any> = new EventEmitter<any>();

	public fontSizeOpt:any = [];
	public targetText:string;
	public lang = 'tc';
	public toggle;
	public toggles:any;
	public toggleOptions = {};
	public showRankingOptions;
	public showChartOptions;
	public showChartObj:any;
	constructor(public datas: DataService, private als: AlertService, 
		private tg:TagGroupService, public subjectService: SubjectService, public eleRef:ElementRef,
		private translate: TranslateService, private storage: StorageService, private load: LoadingService, public sts: SettingService, public coms: CommonService,
		public oup: OupService
	) {
		console.log(this.translate.currentLang);

		[15,16,17,18,19,20,22,24,26,28,30,32,34,36,38,40,44,48,52,58,66].forEach(e => {
			this.fontSizeOpt.push( {label:e.toString(),value:e});
		});
	}

	ngOnInit(): void {
		this.lang = this.datas.lang;
		const lang = this.lang;
		this.toggles = [{label: {tc: '基本', sc:'基本', en:'Basic'}[lang], value: 'basic'}, { label: {tc:'進階', sc:'進階', en: 'Advanced'}[lang], value: 'advanced'}];
		this.toggle = this.toggles[0].value;
		this.showChartOptions = [
			{label: {tc:'按校本',sc:'按校本',en:'School-based'}[lang], value: 'default'},
			{label: {tc:'顯示',sc:'顯示',en:'Display'}[lang], value: '1'},
			{label: {tc:'不顯示',sc:'不顯示',en:'Hide'}[lang], value: '0'}];
		this.showRankingOptions = [
			{label: {tc:'按校本',sc:'按校本',en:'School-based'}[lang], value: 'default'},
			{label: {tc:'顯示',sc:'顯示',en:'Display'}[lang], value: '1'},
			{label: {tc:'不顯示',sc:'不顯示',en:'Hide'}[lang], value: '0'}];
	}

	public open(bookID:number, bookName:string, bookType:string, subject:string):void {
		this.targetText = `${this.translate.instant("workspace.pls-select")}...`
        this.tg.getTags().then((obj:any)=>{
			this.storage.schoolYears = obj.schoolYears;
			this.storage.tags = obj.tags;
			this.storage.inBranch = this.storage.tags.find(t => t.my_tag == 1 && t.gtype != 'main') && !this.storage.tags.find(t => t.my_tag == 1 && t.gtype == 'main' && t.type != 1 && t.rid != null);

			let year = this.storage.schoolYears.find(y => y.id == this.datas.userInfo.school.school_year);
			if (year == null && this.storage.schoolYears.length > 0){
				year = this.storage.schoolYears.find(y=> {
					return (y.start_time && y.end_time) ? y.start_time.isBefore() && y.end_time.isAfter() : false;
				});
			}
			if (year == null && this.storage.schoolYears.length > 0){
				year = this.storage.schoolYears[this.storage.schoolYears.length - 1];
			} else if (this.storage.schoolYears.length == 0){
				this.als.alert('workspace.no-school-year');
			}

			this.shareModal = {
		
				item: {
					id: bookID, 
					type: bookType, 
					title: bookName
				}, 
				targets: [],
				subjects:subject ? [subject] : [],
				share: { font_size:26, share_type: 'homework', pass_level: '50', time_limit: 'null', star: 0, repeat_star: 0, live_verify: 1, show_default_answer: 1, auto_submit: 1, show_ranking: 'default', show_chart: 'default' }, 
				startTime: moment(), endTime: null, wholeDay: false, correctionStartTime: null, correctionEndTime: null, correctionWholeDay: false, schoolYear: year, 
				genShareUrl: false
			};

			this.initPersonalReportSettings();

			this.hostZIndex = 99;
			this.hostDisplay = 'flex';
			this.modal.open();
			this.modal.title = this.translate.instant( 'workspace.create-share');
		});
	}

	public openTargetSelector(label:any):void {
		this.targetSelector.open(label, this.eleRef.nativeElement).then(success=>{

		}).catch(cancel=>{})
	}
	/*
	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		const shareModal = this.shareModal;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let update$ = null;
		if (dateType == 'in-progress') {
			update$ = dateTimePicker.update.subscribe(dt => {
				if (dt.startDateTime) {
					shareModal.startTime = moment(dt.startDateTime);
				} else {
					shareModal.startTime = null;
				}
				if (dt.endDateTime) {
					shareModal.endTime = moment(dt.endDateTime);
				} else {
					shareModal.endTime = null;
				}
				shareModal.wholeDay = dt.wholeDay ? 1 : 0;
			});
			if (shareModal.startTime) {
				const last8Dight = shareModal.startTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.startTime.toDate();
			}
			if (shareModal.endTime) {
				const last8Dight2 = shareModal.endTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.endTime.toDate();
			}
		} else {
			update$ = dateTimePicker.update.subscribe(dt => {
				if (dt.startDateTime) {
					shareModal.correctionStartTime = moment(dt.startDateTime)
				} else {
					shareModal.correctionStartTime = null;
				}
				if (dt.endDateTime) {
					shareModal.correctionEndTime = moment(dt.endDateTime);
				} else {
					shareModal.correctionEndTime = null;
				}
				shareModal.correctionWholeDay = dt.wholeDay ? 1 : 0;
			});
			if (shareModal.correctionStartTime) {
				const last8Dight = shareModal.correctionStartTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.correctionStartTime.toDate();
			}
			if (shareModal.correctionEndTime) {
				const last8Dight2 = shareModal.correctionEndTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.correctionEndTime.toDate();
			}
		}
		dateTimePicker.open(el, startTime, endTime, wholeDay).then(data => {
			update$.unsubscribe();
		});
	}*/

	dateTimeClick(el, dateType) {
		const dateTimePicker = this.dateTimePicker;
		const shareModal = this.shareModal;
		let wholeDay = 0;
		let startTime: any = null;
		let endTime: any = null;
		let update$ = null;
		if (dateType == 'in-progress') {
			if (shareModal.startTime) {
				const last8Dight = shareModal.startTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.startTime.toDate();
			}
			if (shareModal.endTime) {
				const last8Dight2 = shareModal.endTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.endTime.toDate();
			}
		} else {
			if (shareModal.correctionStartTime) {
				const last8Dight = shareModal.correctionStartTime.format('HH:mm:ss');
				wholeDay = last8Dight == '00:00:00' ? 1 : 0;
				startTime = shareModal.correctionStartTime.toDate();
			}
			if (shareModal.correctionEndTime) {
				const last8Dight2 = shareModal.correctionEndTime.format('HH:mm:ss');
				wholeDay = last8Dight2 == '23:59:59' && wholeDay ? 1 : 0;
				endTime = shareModal.correctionEndTime.toDate();
			}
		}

		let needEndTime = !!endTime;
		let dtpOptions: any = { value: { startDateTime: startTime, endDateTime: endTime, isWholeDay: wholeDay, customMinutes: true, needEndTime: needEndTime }, skipLoadApi: true };
		dateTimePicker.open(el, dtpOptions).then(dt => {
			if (dateType == 'in-progress') {
				if (dt.startDateTime) {
					shareModal.startTime = moment(dt.startDateTime);
				} else {
					shareModal.startTime = null;
				}
				if (dt.endDateTime) {
					shareModal.endTime = moment(dt.endDateTime);
				} else {
					shareModal.endTime = null;
				}
				shareModal.wholeDay = dt.wholeDay ? 1 : 0;
			} else {
				if (dt.startDateTime) {
					shareModal.correctionStartTime = moment(dt.startDateTime)
				} else {
					shareModal.correctionStartTime = null;
				}
				if (dt.endDateTime) {
					shareModal.correctionEndTime = moment(dt.endDateTime);
				} else {
					shareModal.correctionEndTime = null;
				}
				shareModal.correctionWholeDay = dt.wholeDay ? 1 : 0;
			}
		});
	}

	cancelClick() {
		this.dispose(null);
	}

	protected dispose(result:any):void {
		this.hostZIndex = 0;
		this.hostDisplay = 'none';
		this.modal.close();
		this.close.emit(result);
	}

	targetSelectChange():void {
		this.targetText = this.shareModal.targets.filter(t => t.tType == 'tag').map(e => e.title).join(', ');
	}

	confirmClick() {
//		console.log(this.shareModal);
		let modal = this.shareModal;
		if (modal.targets.length == 0 || modal.startTime == null) {
			this.als.toastError('workspace.pls-fill*');
			return;
		}
		const options: any = JSON.parse(JSON.stringify(modal.share));
		options.type = options.share_type;
		options.time_limit = options.time_limit == 'null' ? null : options.time_limit;
		['start_time', 'end_time', 'correction_start_time', 'correction_end_time'].forEach(prop => {
			if (options[prop]) {
				options[prop] = options[prop].toISOString();
			}
		});
		const targets = modal.targets.filter(t => t.tType == 'tag').map(e => e.value);
		if (targets.length == 0) {
			this.als.alert('workspace.only-support-share-to-tag');
			return;
		}
		let subjects = [];
		const subject = this.subjectService.getSubjectById(this.shareModal.subjects[0]);
		if (subject) {
			subjects.push(subject);
		}

		this.checkShareExist(options, targets, subjects);
	}

	checkShareExist(options, targets, subjects) {
		let item = this.shareModal.item;
		let o = { entries: [{ id: item.id, type: item.type, title: item.title }], tags: targets, options: options, subjects: subjects };
		this.datas.post2({ data: { api: 'ROBookShare.find_book_share', json: [targets, item.id] } }).then((res: any) => {
			if (res.share) {
				const author = res.share.created_by[this.datas.lang == 'en' ? 'ename' : 'cname'];
				const shareType = this.translate.instant('workspace.share-type-' + res.share.type);
				const target = res.share.tags.length > 0 ? res.share.tags[0].title : '';
				let data = { author: author, shareType: shareType, target: target, time: res.share.created_at, type: 'confirm' }
				if (this.oup.isOupUser) {
					Object.defineProperty(data, 'target', {
						get: () => {
							return this.oup.get_class_name(target) || target;
						}
					})
				}
				let shareBtn = { key: 'ok', labelKey: this.translate.instant("workspace.share"), ngStyle:{backgroundColor:"var(--alert-okbuttoncolor)"}}
				this.als.alert2(this.translate.instant("workspace.confirm-create-share", data), null, { btns: [[shareBtn, 'cancel']] }).then((key: string) => {			
					this.createCreate(o);
				}).catch(err => { });
			} else {
				this.createCreate(o);
			}
		});
	}

	createCreate(obj) {
		this.datas.post2({ data: { api: 'ROBookShare.create_multi_share', json: [obj] } }).then((res: any) => {
			this.shareModal.shares = res.shares.map(sh=>{
				sh.short_url = null;
				sh.tagTitle = sh.tags ? sh.tags[0].title : 'n/a';
				return sh;
			});
			if (this.shareModal.genShareUrl) {
				this.genMultiShareUrl();
			} else {
				this.dispose(this.shareModal);
			}
		});
	}

	genMultiShareUrl() {
		const item = this.shareModal.item;
		const share = this.shareModal.shares.find(s => s.short_url == null);
		if (share) {
			this.datas.post2({ data: { api: 'ROBookShare.create_share_url', json: [share.id, item.id] } }).then((res: any) => {
				if (res.share){
					share.short_url = res.share.short_url;
					setTimeout(() => { this.genMultiShareUrl(); }, 10);
				} else if (res.message){
					this.als.alert(res.message).then(()=>{
						this.dispose(this.shareModal);
					});
				}
			});
		} else {
			this.load.remove('create-link');
			this.shortUrlsModal = { item: item, shares: this.shareModal.shares };
		}
	}

	copyToClipboard(copyValue, obj = null) {
		const inputEl: any = document.createElement("input");
		inputEl.value = copyValue;
		inputEl.style.opacity = "0";
		inputEl.readOnly = false;
		inputEl.contentEditable = true;
		document.body.appendChild(inputEl);
		inputEl.select();
		let range = document.createRange();
		range.selectNodeContents(inputEl);
		let s = window.getSelection();
		s.removeAllRanges();
		s.addRange(range);
		inputEl.setSelectionRange(0, 999999); // A big number, to cover anything that could be inside the element.
		try {
			const successful = document.execCommand('copy');
		} catch (err) {
		}
		document.body.removeChild(inputEl);
		if (obj) {
			obj.copied = true;
			setTimeout(() => { obj.copied = false; }, 1000);
		}
	}

	initPersonalReportSettings(){
		const lang = this.datas.lang;
		this.shareModal.share.show_ranking = 'default';
		this.shareModal.share.show_chart = 'default';
		this.shareModal.share.show_chart_chart1 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART1;
		this.shareModal.share.show_chart_chart2 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART2;
		this.shareModal.share.show_chart_chart3 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART3;
		this.shareModal.share.show_chart_chart4 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART4;
		this.shareModal.share.show_chart_chart5 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART5;
		this.shareModal.share.show_chart_chart6 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART6;
		for(let i = 1; i < 7; i++){
			if (this.shareModal.share['show_chart_chart' + i] === undefined){
				this.shareModal.share['show_chart_chart' + i] = this.sts.schoolSettings['SHOW_CHART_PERSONAL_REPORT_CHART' + i];
			}
		}

	}

	showChartChange(field){
		const lang = this.datas.lang;
		if (field == 'ranking'){
			const el:any = document.querySelector('okapulldown2.show-ranking span.label');
			const showRanking = this.shareModal.share.show_ranking;
			if (showRanking == 'default'){
				if (this.sts.schoolSettings.SHOW_RANKING_PERSONAL_REPORT == '1'){
					el.innerText = {tc:'*顯示 (按校本)', sc:'*显示 (按校本)', en:'*Display (School-based)'}[lang];
				} else {
					el.innerText = {tc:'*不顯示 (按校本)', sc:'*不显示 (按校本)', en:'*Hide (School-based)'}[lang];
				}
			} else if (showRanking == '1'){
				el.innerText = {tc:'顯示', sc:'显示', en:'Display'}[lang];
			} else {
				el.innerText = {tc:'不顯示', sc:'不显示', en:'Hide'}[lang];
			}
			return;
		}
		const showChart = this.shareModal.share.show_chart;
		const el:any = document.querySelector('okapulldown2.show-chart span.label');
		if (showChart == 'default'){
			if (this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT == '1'){
				el.innerText = {tc:'*顯示 (按校本)', sc:'*显示 (按校本)', en:'*Display (School-based)'}[lang];
			} else {
				el.innerText = {tc:'*不顯示 (按校本)', sc:'*不显示 (按校本)', en:'*Hide (School-based)'}[lang];
			}
			this.shareModal.share.show_chart_chart1 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART1;
			this.shareModal.share.show_chart_chart2 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART2;
			this.shareModal.share.show_chart_chart3 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART3;
			this.shareModal.share.show_chart_chart4 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART4;
			this.shareModal.share.show_chart_chart5 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART5;
			this.shareModal.share.show_chart_chart6 = this.sts.schoolSettings.SHOW_CHART_PERSONAL_REPORT_CHART6;
		} else if (showChart == '0'){
			el.innerText = {tc:'不顯示', sc:'不显示', en:'Hide'}[lang];
			this.shareModal.share.show_chart_chart1 = '0';
			this.shareModal.share.show_chart_chart2 = '0';
			this.shareModal.share.show_chart_chart3 = '0';
			this.shareModal.share.show_chart_chart4 = '0';
			this.shareModal.share.show_chart_chart5 = '0';
			this.shareModal.share.show_chart_chart6 = '0';
		} else {
			el.innerText = {tc:'顯示', sc:'显示', en:'Display'}[lang];
			this.shareModal.share.show_chart_chart1 = '1';
			this.shareModal.share.show_chart_chart2 = '1';
			this.shareModal.share.show_chart_chart3 = '1';
			this.shareModal.share.show_chart_chart4 = '1';
			this.shareModal.share.show_chart_chart5 = '1';
			this.shareModal.share.show_chart_chart6 = '1';
		}
	}

	toggleChange(){
		setTimeout(()=>{
			this.coms.waitFor(()=> document.querySelector('okapulldown2.show-ranking'), 100, 100).then(()=>{
				this.showChartChange('ranking');
				this.showChartChange('chart');
			}).catch(e=>{
				
			});
		}, 10);
	}

}
