import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { ThemeService } from 'src/app/service/theme.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { faCheckSquare } from '@fortawesome/pro-duotone-svg-icons';
import { faSquare } from '@fortawesome/pro-light-svg-icons';
// import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
// import { faSquare as farSquare, faCheckSquare as farCheckSquare, IconDefinition, faChartLine, faChartColumn } from '@fortawesome/pro-regular-svg-icons';
// import { faSquare as fasSquare, faCheckSquare as fasCheckSquare } from '@fortawesome/pro-solid-svg-icons';


// public farCheckSquare:IconDefinition = farCheckSquare;
// public farSquare:IconDefinition = farSquare;


@Component({
	selector: 'whitePopup',
	template: `
<bubbleBox2
  #bubbleBox2
  padding="0"
  (onClose)="onBubbleBoxClose($event)"
  [submitOnClose]="true" [elClass]="elClass"
  [autoTailColor]="bubbleBox2BgColor == ''"
  [backgroundColor]="bubbleBox2BgColor || themeService.getStyleValue('wpopup-bgcolor') || '#FFFFFF'"
  [boxShadow]="themeService.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"
>
	<div class="white-popup-item-container" [style.maxHeight]="maxHeight" >
		<ng-container *ngFor="let menuItem of menuItems">
			<div *ngIf="menuItem && (!menuItem.hideIf || !menuItem.hideIf(_curData))" class="menuItem" [ngStyle]="menuItem.menuStyle" 
			[class.disabled]="menuItem.disableIf && menuItem.disableIf(_curData)" (click)="menuClick($event, menuItem)"
			>
				<ng-container *ngIf="multiSelect">
					<!-- <checkbox-icon [selected]="menuItem.selected"></checkbox-icon> -->
					<fa-icon 
						class="checkbox"
						[icon]="menuItem.selected ? checkBoxSelected : checkBoxNormal " 
						*ngIf="multiSelect"></fa-icon>	
					<div class="flex auto-width inner-cell">
						<fa-icon *ngIf="menuItem.faIcon" [icon]="menuItem.faIcon" [ngStyle]="menuItem.iconStyle"></fa-icon>
							<div class="label" [ngStyle]="menuItem.labelStyle">{{menuItem[bindLabel] || (menuItem.titleKey|translate)}}</div>
						<ng-container *ngTemplateOutlet="menuItem.templateRef"></ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="!multiSelect">
					<fa-icon *ngIf="menuItem.faIcon" [icon]="menuItem.faIcon" [ngStyle]="menuItem.iconStyle"></fa-icon>
					<div class="label" [ngStyle]="menuItem.labelStyle">{{menuItem[bindLabel] || (menuItem.titleKey|translate)}}</div>
					<ng-container *ngTemplateOutlet="menuItem.templateRef"></ng-container>
				</ng-container>
			</div>

			

		</ng-container>
	</div>
</bubbleBox2>
    `,
	styleUrls: ['./whitePopup.component.scss']
})

export class WhitePopupComponent implements OnInit, OnDestroy {

	public checkBoxSelected: any = faCheckSquare;
	public checkBoxNormal: any = faSquare;

	@ViewChild('bubbleBox2', { static: false }) public bubbleBox2: BubbleBox2Component;
	@Input() maxHeight: string = '299px';
	@Input() multiSelect: boolean = false;
	@Input() public menuItems: any[] = [];
	@Input() public bindLabel: string = 'title';
	@Input() direction: 'auto' | 'auto-up-down' | 'top' | 'bottom' | 'left' | 'right' = 'auto';
	@Output() public onMenuClick: EventEmitter<any> = new EventEmitter();
	public _curData: any = null;
	private _selectedItem: any;
	@Input() public bubbleBox2BgColor: string = "";
	public emitter: EventEmitter<any> = new EventEmitter();
	@Input() public elClass = '';
	constructor(public translate: TranslateService, public themeService: ThemeService) {
	}

	ngOnInit(): void {
		this.emitter.emit({ type: "onInit" });
	}
	ngOnDestroy(): void {
		console.log("WhitePopupComponent.destroy");
	}
	public getSelectedItem(): any {
		return this._selectedItem;
	}

	public waitUntilReady(): Promise<any> {
		if (this.bubbleBox2) return Promise.resolve('ready');
		return new Promise((resolve: Function) => {
			var sub: Subscription = this.emitter.subscribe(() => {
				sub.unsubscribe();
				resolve('ready');
			})
		})

	}
	public open(targetEle: HTMLElement, data: any = null, appendToEle: HTMLElement = document.body): Promise<any> {
		if (this.direction) this.bubbleBox2.position = this.direction;
		this.emitter.emit({ type: "open" });
		this._curData = data;
		return this.bubbleBox2.open(targetEle, appendToEle).catch((e) => { });
	}

	public menuClick(event: any, menuItem: any): void {
		if (this.multiSelect) {
			this.toggle(menuItem);
		} else {
			this.select(menuItem);

		}
	}

	private select(menuItem: any) {
		this._selectedItem = menuItem;
		if (menuItem.click) menuItem.click(this._curData);
		this.onMenuClick.emit(menuItem);
		if (menuItem.closeOnClick != false) this.bubbleBox2.close();
	}

	public toggle(menuItem: any): void {
		menuItem.selected = menuItem.selected ? false : true;
		this.onMenuClick.emit(menuItem);
		this.emitter.emit({ type: "update", selections: this.menuItems });
	}

	onBubbleBoxClose(event: any): void {
		this.emitter.emit({ type: "close" });
		this.emitter.complete();
	}

}


@Component({
	selector: 'whitePopupForm',
	template: `
	<bubbleBox2
	  #bubbleBox2
	  padding="0"
	  (onClose)="onBubbleBoxClose($event)"
	  [submitOnClose]="true"
	  [backgroundColor]="themeService.getStyleValue('wpopup-bgcolor') || '#FFFFFF'"
	  [boxShadow]="themeService.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)'"
	>
		<div class="white-popup-item-container" [style.maxHeight]="maxHeight">
			<ng-container *ngFor="let field of options.fields">
				<div class="menuItem form" *ngIf="field.type == 'boolean'" >
					<div class="name-field" >{{field.name}}</div>
					<ui-switch 
						class="value-field switch" 
						[(ngModel)]="options.value[field.key]"
						(valueChange)="onChange(options.value, field.key, $event)"
						></ui-switch>
				</div>
			</ng-container>
		</div>
	</bubbleBox2>
    `,
	styleUrls: ['./whitePopup.component.scss']
})

export class WhitePopupFormComponent implements OnInit, OnDestroy {

	@ViewChild('bubbleBox2', { static: false }) public bubbleBox2: BubbleBox2Component;
	@Input() maxHeight: string = '299px';
	@Input() public options: any = {};
	@Input() public menuItems: any[] = [];
	@Input() public bindLabel: string = 'title';
	@Output() public onMenuClick: EventEmitter<any> = new EventEmitter();
	public bubbleBox2BgColor: string = "#FFF";
	public emitter: EventEmitter<any> = new EventEmitter();
	public subject: Subject<any> = new Subject();
	public fields: any[];
	constructor(public translate: TranslateService, public themeService: ThemeService) {

	}

	ngOnInit(): void {
		this.emitter.emit({ type: "onInit" });
	}
	ngOnDestroy(): void {
		console.log('WhitePopupFormComponent.destroy');
	}
	onChange(reference: any, key: string, value: any): void {
		reference[key] = value;
		this.subject.next(reference);
	}

	public getValue(): any {
		return this.options.value;
	}

	public waitUntilReady(): Promise<any> {
		if (this.bubbleBox2) return Promise.resolve('ready');
		return new Promise((resolve: Function) => {
			var sub: Subscription = this.emitter.subscribe(() => {
				sub.unsubscribe();
				resolve('ready');
			})
		})

	}

	public open(targetEle: HTMLElement): Promise<any> {
		this.emitter.emit({ type: "open" });
		return this.bubbleBox2.open(targetEle).catch((e) => { });
	}

	onBubbleBoxClose(event: any): void {
		this.emitter.emit({ type: "close" })
		this.emitter.complete();
		this.subject.complete();
	}
}
