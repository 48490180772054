import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UiSwitchModule } from 'ngx-ui-switch';
import { QuestionBankMoveImagePopUpComponent } from './QuestionBankMoveImagePopUp.component';
import { BubbleBox2Module } from 'src/app/sharedModule/bubbleBox2Module/bubbleBox2.module';

@NgModule({
    imports: [
		UiSwitchModule,
		CommonModule,
		TranslateModule,
		BubbleBox2Module,
		FontAwesomeModule
    ],
    declarations: [
		QuestionBankMoveImagePopUpComponent
    ],
    exports: [
		QuestionBankMoveImagePopUpComponent
    ],
    providers: [
    ],
    entryComponents: [QuestionBankMoveImagePopUpComponent]
})
export class QuestionBankMoveImagePopUpModule {
}
