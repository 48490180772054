import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertService } from './alert.service';
import { CommonService } from './common.service';
import { DataService } from './data.service';
import { RoService } from './ro.service';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '../sharedModule/loadingModule/loading.service';
import { ErudaService } from './eruda.service';
import { PermissionService } from './permission.service';

@Injectable({ providedIn: 'root' })
export class SettingService {
	public ready: boolean = false;
	public account: any;
	public homeLayout: any;
	public homeSettings: any[];
	public icons: any[];
	public iconWrapperStyle: any;
	public iconStyle: any;
	public iconImageStyle: any;
	public otherObjs: any[] = [];
	public version = '';
	public schoolSettings: any; // can store settings here when you need
	public current_year_id = null;
	constructor(private datas: DataService, private coms: CommonService, private router: Router, private ros: RoService, private als: AlertService, private sans: DomSanitizer, private trans: TranslateService, private load: LoadingService, private eruda: ErudaService, private pm: PermissionService) {
		let getUserInfo: Function = (trial: number = 0) => {
			if (this.datas.userInfo) this.loadData();
			else if (trial < 10) setTimeout(() => getUserInfo(trial + 1), 500);
		}
		getUserInfo();
		this.loadImportantSchoolSettings();
	}
	private _pending: Promise<any>;
	public loadData(): Promise<any> {
		if (this._pending) {
			return this._pending;
		}
		this._pending = new Promise((resolve, reject) => {
			let lastDataJson = localStorage.getItem('homeScreen_data');
			if (lastDataJson) {
				try {
					let lastData = JSON.parse(lastDataJson);
					if (lastData.account && lastData.account.school_id == this.datas.userInfo.school_id) {
						this.handleData(lastData);
					}
				} catch (e) { }
			}
			this.datas.post('Account.getUpdateSetting').subscribe((res: any) => {
				if (res.account) {
					if (res.account.jwt) {
						if (this.datas.jwt != res.account.jwt) {
							var newJWT = res.account.jwt;
							localStorage.setItem('common_jwt', newJWT);
							this.datas.jwt = newJWT;
						}
					}

					let homeLayout = res.webHomeLayout ? res.webHomeLayout : res.homeLayout;
					res.homeLayout = homeLayout;
					let found = res.settings.find(s => s.key == 'HOME_PAGE_THEME_COLOR');
					if (found) {
						let obj = JSON.parse(homeLayout);
						obj.homePageThemeColor = found.value.replace(/[\\\"]/g, '');
						homeLayout = JSON.stringify(obj);
						res.homeLayout = homeLayout;
					}
					let data = { account: res.account, homeLayout: homeLayout, homeSettings: res.homeSettings };
					if (res.account.personalSettings) {
						try {
							res.account.personalSettings = JSON.parse(res.account.personalSettings);
						} catch (e) {
							res.account.personalSettings = {};
						}
					}
					let dataJson = JSON.stringify(data);
					localStorage.setItem('homeScreen_data', dataJson);
					this.handleData(res).then(obj => {
						resolve(obj);
					});
				}
			});
		});
		this._pending.finally(() => {
			this._pending = null;
		})
		return this._pending;
	}

	public handleData(res) {
		return new Promise((resolve, reject) => {
			this.datas.userInfo = res.account;
			if (typeof this.datas.userInfo.personalSettings == 'string') {
				this.datas.userInfo.personalSettings = JSON.parse(this.datas.userInfo.personalSettings);
			}
			this.account = res.account;
			if (this.account && sessionStorage.getItem('common_photo_done') == null) {
				if (this.account.m) {
					let arr = res.account.m.split('/');
					let okgId = arr[arr.indexOf('id') + 1];
					this.datas.post('ROHomeScreen.getOKGImage', [okgId]).subscribe((res: any) => {
						if (res.image && res.image.url) {
							const path = environment.resourceServer[res.image.server] + res.image.url;
							localStorage.setItem('common_photo', path);
							sessionStorage.setItem('common_photo_done', '1');
						}
					});
				}
			}
			try {
				this.homeLayout = JSON.parse(res.homeLayout);
				this.homeLayout.ratio = '4:3';
				let swap = this.homeLayout;
				if (this.datas.appId2 == 'readingstar') {
					this.homeLayout = this.readingStarWebLayout;
				}
				if (this.homeLayout.lang) {
					setTimeout(() => {
						this.datas.lang = this.homeLayout.lang;
						this.trans.use(this.homeLayout.lang);
					}, 10);
				}
				this.homeSettings = JSON.parse(res.homeSettings);
				this.homeSettings = this.homeSettings.filter(s => s.name != 'Bills');
				this.initStyle(this.homeLayout);
				this.initIcons(this.homeSettings);
				resolve({ homeLayout: this.homeLayout, homeSettings: this.homeSettings });
			} catch (e) {
				this.homeSettings = [];
				reject(e);
			}
			this.ready = true;
		});
	}


	initStyle(homeLayout) {
		const px = 'px';
		const ratio = homeLayout.ratio;
		if (homeLayout[ratio].apps) {
			const apps = homeLayout[ratio].apps;
			let height = 'calc(100% - ' + (apps.top + apps.bottom) + px + ')';
			let width = 'calc(100% - ' + (apps.left + apps.right + 20) + px + ')';
			let right = apps.right + 20 + px;
			this.iconWrapperStyle = { 'top': apps.top + px, 'bottom': apps.bottom + px, left: apps.left + px, right: apps.right + px, height: height, width: width };
			if (apps.icon) {
				this.iconImageStyle = { width: apps.icon.width + px, height: apps.icon.height + px };
				this.iconStyle = { marginLeft: apps.icon.marginX + px, marginRight: apps.icon.marginX + px, marginTop: apps.icon.marginY + px, marginBottom: apps.icon.marginY + px };
				this.homeLayout.iconClass = apps.icon.style ? apps.icon.style : 'cartoon';
				localStorage.setItem('homeScreen_iconClass', this.homeLayout.iconClass);
			}
		}
		/*if (homeLayout[ratio].bg) {
		  const bg = homeLayout[ratio].bg;
		  let bgStyle: any = {};
		  if (bg.fit == 'cover') {
			bgStyle.backgroundSize = 'cover';
		  }
		  if (bg.url) {
			localStorage.setItem('homeScreen_bg', bg.url);
			bgStyle.backgroundImage = this.sans.bypassSecurityTrustStyle("url('" + bg.url + "')");
		  } else if (this.datas.appId == 'rainbowone'){
	
		  } else if (this.datas.appId == 'rainbowstar'){
	
		  }
		  this.bgStyle = bgStyle;
		}*/
		/*if (homeLayout[ratio].news) {
		  const news = homeLayout[ratio].news;
		  let height = 'calc(100% - ' + (news.top + news.bottom) + px + ')';
		  let right = news.right + 10 + px; //10 is shadow
		  let width = news.width - 20 + px; // 20 is shadow
		  let bottom = news.bottom - 10 + px; // 10 is shadow
		  this.newsObj = { style: { top: news.top + px, bottom: news.bottom + px, right: right, width: width, height: height } };
		  this.initWindowData();
		}*/
		if (homeLayout[ratio].objects) {
			let objects = homeLayout[ratio].objects;
			let px = 'px';
			objects.forEach(obj => {
				let newObj: any = {};
				if (this.otherObjs[obj.name]) {
					this.otherObjs[obj.name + '2'] = newObj;
				} else {
					this.otherObjs[obj.name] = newObj;
				}
				for (let prop in obj) {
					if (['top', 'bottom', 'left', 'right', 'height', 'width'].indexOf(prop) > -1) {
						newObj[prop] = obj[prop] + px;
					}
				}
				if (obj.name == 'shadow') {
					newObj.backgroundColor = obj['color'];
					newObj.borderRadius = obj['radius'] + px;
					newObj.opacity = obj['alpha'];
				} else if (obj.name == 'connection') {
					let arr = obj.url.split('/');
					let elClass = arr[arr.length - 1].split('.')[0];
					newObj.elClass = elClass;
				} else if (obj.name == 'version') {
					newObj.text = obj.text == '$VERSION' ? this.version : obj.text;
					newObj.color = obj.color;
					newObj.fontSize = obj.fontSize + px;
				} else if (obj.name == 'school') {
					newObj.text = this.datas.userInfo.school.title;
					newObj.fontSize = obj.fontSize + px;
					newObj.color = obj.color;
					let schoolLogo = this.datas.userInfo.school.schoolLogo;
					if (schoolLogo) {
						if (localStorage) {
							let path = localStorage.getItem('common_schoolLogo');
							if (path) {
								path = path.replace('storage.openknowledge.hk', 'oka.blob.core.windows.net');
								newObj.image = this.sans.bypassSecurityTrustStyle("url('" + path + "')");
							}
						}
						let arr = schoolLogo.split('/');
						let okgId = arr[arr.indexOf('id') + 1];
						if (sessionStorage.getItem('common_schoolLogo_done') == null) {
							this.datas.post('ROHomeScreen.getOKGImage', [okgId]).subscribe((res: any) => {
								if (res.image) {
									if (res.image.url) {
										const path = environment.resourceServer[res.image.server] + res.image.url;
										const url = 'url("//' + path + '")';
										if (localStorage) {
											localStorage.setItem('common_schoolLogo', path);
										}
										sessionStorage.setItem('common_schoolLogo_done', '1');
										newObj.image = this.sans.bypassSecurityTrustStyle(url);
									}
								}
							});
						}
					}
				} else if (obj.name == 'user') {
					newObj.fontSize = obj.fontSize + px;
				} else if (obj.name == 'footnote') {
					newObj.text = obj.text;
					newObj.fontSize = obj.fontSize + px;
					newObj.color = obj.color;
				}
			});
		}
	}

	/*initWindowData() {
	  this.newsObj.data = {};
	  let data = this.newsObj.data;
	  data.jwt = this.datas.jwt;
	  data.homeSettings = this.storage.homeSettings;
	  data.appId = this.datas.appId;
	  data.lang = this.datas.lang;
	  data.role = this.datas.userInfo.user_role;
	  data.schoolId = this.datas.userInfo.school_id;
	  data.uid = this.datas.userInfo.uid;
	}*/

	public initIcons(settings) {
		if (this.datas.appId2 == 'readingstar') {
			if (this.account.user_role == 2 || this.account.user_role == 6) {
				settings = [
					{ "name": "Course" },
					{ "name": "Book Review" },
					{ "name": "Lesson" },
					{ "name": "Bookshelf" },
					{ "name": "Prize" }
				];
			} else {
				settings = [
					{ "name": "Course" },
					{ "name": "Book Review" },
					{ "name": "Lesson" },
					{ "name": "Bookshelf" },
					{ "name": "Prize" },
					{ "name": "Book Information" },
					{ "name": "File Manager" },
					{ "name": "Users and Groups" },
					{ "name": "School settings" }
				];
			}
		}
		let nameMap = [];
		if (this.homeLayout && this.homeLayout['4:3'] && this.homeLayout['4:3'].apps && this.homeLayout['4:3'].apps.names) {
			nameMap = this.homeLayout['4:3'].apps.names;
		}
		this.icons = settings.map(e => {
			let found = nameMap.find(item => item.name == e.name);
			let label = e.name.replace(/\s/g, '-').toLowerCase();
			let obj: any = { name: e.name, label: label, route: this.getIconRoute(e.name) };
			if (found && found.label && found.label[this.datas.lang]) {
				obj.customLabel = found.label[this.datas.lang];
			}
			return obj;
		});

		if (this.datas.userInfo.user_role == 2){
			const teacherOnlyRoutes = ['Book Information','Teaching Plan','School settings'];
			this.icons = this.icons.filter(e=> teacherOnlyRoutes.indexOf(e.name) === -1);
		}

		this.coms.waitFor(() => this.schoolSettings, 200, 300).then(settings => {
			if (settings.ACCESS_ADMIN_FILE_MANAGER == 1 && this.datas.userInfo.isAdmin != 1) {
				this.icons = this.icons.filter(e => e.name != 'File Manager');
			}
			if (settings.ACCESS_ADMIN_SCH_CFG == 1 && this.datas.userInfo.isAdmin != 1) {
				this.icons = this.icons.filter(e => e.name != 'School settings');
			}
			if (settings.ACCESS_ADMIN_USER_MGT == 1 && this.datas.userInfo.isAdmin != 1) {
				this.icons = this.icons.filter(e => e.name != 'Users and Groups');
			}
			if (settings.ACCESS_DISABLE_STUDENT_BOOKSTORE == 1 && this.datas.userInfo.user_role == 2) {
				this.icons = this.icons.filter(e => e.name != 'Bookstore');
			}
		});

		if (this.datas.userInfo.school_id == 1675) { //hard code 天虹admin & 課程主任才能去學習重點
			this.coms.waitFor(() => this.pm.loaded, 20, 300).then(() => {
				if (this.datas.userInfo.isAdmin != '1' && this.pm.role_id != '302') {
					this.icons = this.icons.filter(e => e.name != 'Learning Points');
				}
			});
		}

		/*
		if(!environment.production)
		{
			this.icons.push({
				name: 'Objectives', 
				label: 'Objectives', 
				route: {
					iconName: "Objectives",
					moduleName	: 	"objectives2"
				}
			});
		}
		*/
	}

	private getIconRoute(iconName: string): any {
		let arr = this.coms.getVersion().split('.');
		let version = arr[0].replace(/[a-zA-Z]/g, '') + '.' + arr[1].replace(/[a-zA-Z]/g, '');
		let lang = this.datas.lang;
		let appId = this.datas.appId;
		let hostname = window.location.hostname.indexOf('localhost') > -1 ? 'ro2.azurewebsites.net' : window.location.hostname;
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			if (hostname == 'ro2.azurewebsites.net') {
				hostname = 'dev.openknowledge.hk';
			}
		}
		let webappUrl = '//' + hostname + '/RainbowOne/webapp/' + version;
		let jwt = this.datas.jwt;
		const ts = +new Date();
		let langAppIdJwt = lang + '/' + appId + '?jwt=' + jwt + '&homeScreenVersion=' + version + '&time=' + ts;
		let iconRoutes = [
			{ iconName: 'File Manager', moduleName: 'workspace' },
			{ iconName: 'File Manager2', moduleName: 'workspace' },
			{ iconName: 'Bookshelf', moduleName: 'bookshelf' },
			{ iconName: 'Bookstore', routeUrl: webappUrl + '/bookStore/' + langAppIdJwt },
			{ iconName: 'Prize', moduleName: 'redeemNew' },
			{ iconName: 'Lesson', moduleName: 'lesson' },
			{ iconName: 'Learn Progress', routeUrl: webappUrl + '/learn-progress/' + langAppIdJwt },
			//{ iconName:'Statistics', routeUrl:webappUrl + '/roAnalyticsChart/' + langAppIdJwt },
			{ iconName: 'Statistics', moduleName: 'analytics' },
			{ iconName: 'Course', moduleName: 'course' },
			{ iconName: 'Course Student', moduleName: 'course' },
			{ iconName: 'Rank', moduleName: 'ranking' },
			{ iconName: 'Li Sing Avatar', moduleName: 'avatar' },
			// { iconName: 'Li Sing Avatar', routeUrl:  webappUrl + '/avatar/' + langAppIdJwt },
			{ iconName: 'Users and Groups', moduleName: 'userAndGroup' },
			{ iconName: 'Users and Groups2', moduleName: 'userAndGroup' },
			{ iconName: 'Media Library', moduleName: (this.datas.userInfo.school.id == '2496' || this.datas.userInfo.school.plan_type=='oup')?'mediaLibraryNew':'mediaLibrary' },
			{ iconName: 'School settings', moduleName: 'school-setting' },
			// { iconName:'Objectives', routeUrl:webappUrl + '/learningObjectiveEditor/' + langAppIdJwt },
			{ iconName: 'Objectives', moduleName: 'objectives' },
			{ iconName: 'Learning Points', moduleName: 'learningPoints' },
			{ iconName: 'LPF', moduleName: 'lpf' },

			{ iconName: 'Bookmark', moduleName: 'bookmark' },
			{ iconName: 'Good Work', moduleName: 'displayBoard' },
			{ iconName: 'Profile', moduleName: 'profile' },
			{ iconName: 'Calendar', moduleName: 'calendar' },
			{ iconName: 'Approval', routeUrl: '//' + hostname + '/RainbowOne/webapp/bookApprove/' + langAppIdJwt },
			{ iconName: 'Online Dictionary', routeUrl: webappUrl + '/dictionary/' + langAppIdJwt },
			{ iconName: 'Speech Tool', moduleName: 'voiceTool', renamePath: 'voiceToolNew' },
			{ iconName: 'Report', moduleName: 'transcript' },
			{ iconName: 'Teaching Plan', moduleName: 'teachingPlan' },
			{ iconName: 'Question Bank', moduleName: 'questionBank' },
			{ iconName: 'Enroll', moduleName: 'enroll' },
			{ iconName: 'Book Information', moduleName: 'library' },
			{ iconName: 'Book Review', moduleName: 'bookcomment' },
			{
				iconName: 'Sales', routeUrl:
					window.location.hostname.indexOf('localhost') > -1 ?
						'//' + window.location.host + '/erp/' + lang + '/sales/' :
						webappUrl + '/roWeb/erp/' + lang + '/sales/'
			},
			{
				iconName: 'Accounting', routeUrl:
					window.location.hostname.indexOf('localhost') > -1 ?
						'//' + window.location.host + '/erp/' + lang + '/accounting/' :
						webappUrl + '/roWeb/erp/' + lang + '/accounting/'
			},
			{ iconName: 'Schedule', moduleName: 'schedule' }
		];

		if (environment.production == false) {

		}

		if (this.datas.userInfo.user_role == 2){
			const teacherOnlyRoutes = ['Book Information','Teaching Plan','School settings'];
			iconRoutes = iconRoutes.filter(e=> teacherOnlyRoutes.indexOf(e.iconName) === -1);
		}

		if (this.datas.userInfo.school_id == 20982) { //教大用舊成績表
			const transcriptRoute = iconRoutes.find(e => e.iconName == 'Report');
			transcriptRoute.moduleName = 'report';
		}

		return iconRoutes.find((item: any) => item.iconName == iconName);
	}

	iconClick(icon) {
		console.log("iconClick!!!!!", icon);
		let arr = this.coms.getVersion().split('.');
		let version = arr[0].replace(/[a-zA-Z]/g, '') + '.' + arr[1].replace(/[a-zA-Z]/g, '');
		let lang = this.datas.lang || 'tc';
		let appId = this.datas.appId || 'rainbowone';
		let routArr = [lang, appId];
		let hostname = window.location.hostname.indexOf('localhost') > -1 ? 'ro2.azurewebsites.net' : window.location.hostname;
		if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			if (hostname == 'ro2.azurewebsites.net') {
				hostname = 'dev.openknowledge.hk';
			}
		}
		let webappUrl = '//' + hostname + '/RainbowOne/webapp/' + version;
		let jwt = this.datas.jwt;
		const ts = +new Date();
		let langAppIdJwt = lang + '/' + appId + '?jwt=' + jwt + '&homeScreenVersion=' + version + '&time=' + ts;
		let key = icon.name;
		this.load.add('navigate');
		let iconRoute: any = this.getIconRoute(key);
		if (iconRoute) {
			if (iconRoute.renamePath) {
				routArr.unshift(iconRoute.renamePath);
			} else if (iconRoute.moduleName) {
				routArr.unshift(iconRoute.moduleName);
			} else if (iconRoute.routeUrl) {
				window.location.href = iconRoute.routeUrl;
			} else {
				this.load.remove('navigate');
			}
			/*if (key == 'File Manager2' || key == 'File Manager') {
				routArr.unshift('workspace');
			} else if (key == 'Bookshelf') {
				window.location.href = webappUrl + '/bookshelf/' + langAppIdJwt;
			} else if (key == 'Bookstore') {
				window.location.href = webappUrl + '/bookStore/' + langAppIdJwt;
			} else if (key == 'Prize') {
				routArr.unshift('redeem');
			} else if (key == 'Lesson') {
				window.location.href = webappUrl + '/lesson/' + langAppIdJwt;
			} else if (key == 'Learn Progress') {
				window.location.href = webappUrl + '/learn-progress/' + langAppIdJwt;
			} else if (key == 'Statistics') {
				window.location.href = webappUrl + '/roAnalyticsChart/' + langAppIdJwt;
			} else if (key == 'Course') {
				window.location.href = webappUrl + '/course/' + langAppIdJwt;
			} else if (key == 'Course2') {
				routArr.unshift('course');
			} else if (key == 'Rank') {
				window.location.href = webappUrl + '/ranking/' + langAppIdJwt;
			} else if (key == 'Li Sing Avatar') {
				window.location.href = webappUrl + '/avatar/' + langAppIdJwt;
			} else if (key == 'Users and Groups2' || key == 'Users and Groups') {
				routArr.unshift('userAndGroup');
			} else if (key == 'Media Library') {
				routArr.unshift('mediaLibrary');
			} else if (key == 'School settings') {
				routArr.unshift('school-setting');
			} else if (key == 'Objectives') {
				window.location.href = webappUrl + '/learningObjectiveEditor/' + langAppIdJwt;
			} else if (key == 'Bookmark') {
				window.location.href = webappUrl + '/bookmarks/' + langAppIdJwt;
			} else if (key == 'Good Work') {
				routArr.unshift('displayBoard');
			} else if (key == 'Profile') {
				routArr.unshift('profile');
			} else if (key == 'Approval') {
				window.location.href = '//' + hostname + '/RainbowOne/webapp/bookApprove/' + langAppIdJwt;
			} else if (key == 'Online Dictionary') {
				window.location.href = webappUrl + '/dictionary/' + langAppIdJwt;
			} else if (key == 'Speech Tool') {
				routArr.unshift('voiceTool');
			} else if (key == 'Report') {
				routArr.unshift('report');
			} else if (key == 'Question Bank') {
				routArr.unshift('questionBank');
			} else if (key == 'Enroll') {
				routArr.unshift('enroll');*/
		} else if (sessionStorage.getItem('in-app') || !this.datas.isInWeb) {
			this.ros.request('navigate', { route: key }).then((res: any) => {
				this.load.remove('navigate');
				this.coms.log('navigate:' + key);
			}).catch(err => {
				this.load.remove('navigate');
				if (typeof err === 'object') {
					if (err.msg) {
						let key = this.trans.instant('home-screen.' + icon.label);
						this.als.alert('home-screen.' + err.msg, { key: key });
					}
				}
			});
		} else {
			this.load.remove('navigate');
		}
		if (routArr.length > 2) {
			this.getPersonalSetting('openModuleInNewTab').then((value: boolean) => {
				// 以下module不加lang及appId於url
				const moduleName:string = routArr[0]
				if ([ 'bookshelf', 'userAndGroup', 'workspace', 'learningPoints', 'course', 'school-setting' ].includes(moduleName)) {
					routArr.pop();
					routArr.pop();
				}
				
				if ((this.router.url.indexOf('home-screen') < 0) && value) {
					let baseHref: string = document.querySelector('base[href]').getAttribute('href');
					let url = window.location.origin + baseHref + routArr.join('/');
					window.open(url, "_blank");
					this.load.remove('navigate');
				} else {
					this.router.navigate(routArr).then(() => {
						this.load.remove('navigate');
					});
				}
			});
		}
	}

	public savePersonalSetting(key: string, value: any): Promise<any> {
		if (!this.account.personalSettings || typeof this.account.personalSettings != 'object' || Array.isArray(this.account.personalSettings)) this.account.personalSettings = {};
		this.account.personalSettings[key] = value;
		return this.datas.post2({
			data: { api: 'Account.savePersonalSetting', json: [this.account.personalSettings] }, loading: false
		});
	}

	public getPersonalSetting(key: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this.ready) {
				resolve((this.account && this.account.personalSettings && this.account.personalSettings[key]) || '');
			} else {
				setTimeout(() => resolve(this.getPersonalSetting(key)), 500);
			}
		});
	}

	public getSchoolSettings(keys: string[] = []): Promise<any> {
		const schoolIdFromJsonObj = this.datas.jwtObj && this.datas.jwtObj.school_id?this.datas.jwtObj.school_id:'0';
		return new Promise((resolve, reject) => {
			this.datas.post('KenCore.getSchoolSettings', [keys, schoolIdFromJsonObj]).subscribe((res: any) => {
				if (this.datas.userInfo && this.datas.userInfo.school.common_school_type == 'Special') {
					const found = res.rows.find(e => e.key == 'DEFAULT_OPEN_APP' && e.school_id == '0');
					if (found) {
						found.value = '"RS"';
					}
				}
				let output: any = {};
				res.rows.forEach(r => {
					output[r.key] = r.value;
				});
				if (res.current_year_id){
					this.current_year_id = res.current_year_id;
				}
				resolve(output);
			});
		});
	}

	async loadImportantSchoolSettings() {
		let importantSchoolSettings: any = [
			'WEB_ROBOOK_PREVIEW_ENABLED', 'ACCESS_ADMIN_FILE_MANAGER', 'ACCESS_ADMIN_USER_MGT',
			'ACCESS_DISABLE_STUDENT_BOOKSTORE', 'ACCESS_ADMIN_SCH_CFG',
			'ACCESS_TEACHER_RESET_PASSWORD', 'ACCESS_STUDENT_RESET_PASSWORD', 'PASSING_RATE',
			'ENFORCE_TWO_FACTOR_AUTHENTICATION', 'ADD_ASSESSMENT', 'ADD_AI_SHARE_AND_ENTRY',
			'DEFAULT_OPEN_APP', 'USE_TEACHING_PLAN', 'SHOW_RANKING_PERSONAL_REPORT', 'SHOW_CHART_PERSONAL_REPORT',
			'SHOW_CHART_PERSONAL_REPORT_CHART1','SHOW_CHART_PERSONAL_REPORT_CHART2','SHOW_CHART_PERSONAL_REPORT_CHART3',
			'SHOW_CHART_PERSONAL_REPORT_CHART4','SHOW_CHART_PERSONAL_REPORT_CHART5','SHOW_CHART_PERSONAL_REPORT_CHART6',
			'ASSESSMENT_AI_CHECKING','ADD_AI_ORAL','ADD_AI_TRANSFORM_ASSESSMENT',"SUPPORT_QEF_FEATURE"
		];
		const schoolSettings = await this.getSchoolSettings(importantSchoolSettings);
		this.schoolSettings = this.schoolSettings ? this.schoolSettings : {};
		for (let prop in schoolSettings) {
			this.schoolSettings[prop] = schoolSettings[prop];
		}
	}

	public getOpenAppProtocol = (appId, withSlash = true) => {
		if (this.schoolSettings.DEFAULT_OPEN_APP) {
			if (this.schoolSettings.DEFAULT_OPEN_APP == 'RS' || this.schoolSettings.DEFAULT_OPEN_APP == '"RS"') {
				return 'rainbowstar' + (withSlash ? '://' : '');
			} else {
				return 'rainbowone' + (withSlash ? '://' : '');
			}
		} else if (appId == 'readingstar' || appId == 'rainbowstar') {
			return 'rainbowstar' + (withSlash ? '://' : '');
		} else {
			return 'rainbowone' + (withSlash ? '://' : '');
		}
	};

	private readingStarWebLayout = { ratio: "4:3", "4:3": { "bg": { "fit": "cover", "url": "https:\/\/oka.blob.core.windows.net\/media\/school_asset\/school(19781)\/profile\/main-teacher-wallpaper\/2022\/09\/15_165848_-8D471674-_bg_readingstar.png" }, "objects": [{ "type": "rectangle", "name": "shadow", "radius": 10, "left": 10, "right": 10, "bottom": 10, "height": 66, "color": "#000000", "alpha": 0.6 }, { "type": "text", "name": "version", "left": 10, "top": 10, "height": 18, "text": "$VERSION", "color": "#ffffff", "fontSize": 11 }, { "type": "user", "name": "user", "top": 5, "right": 10, "height": 40, "color": "#ffffff", "fontSize": 15, "mask": "circle", "action": "logout" }, { "type": "school", "name": "school", "color": "#ffffff", "fontSize": 16, "url": "https:\/\/oka.blob.core.windows.net\/media\/school_asset\/school(19781)\/profile\/main-teacher-logo\/2022\/09\/19_160455_-76D6C909-logo.png", "left": 12, "top": 30, "height": 380, "display": "block" }, { "type": "img", "name": "connection", "url": "theme\/system\/wifi.png", "right": 18, "bottom": 18, "width": 50, "height": 50, "action": "checkNetwork" }, { "type": "img", "name": "fullscreen", "url": "theme\/system\/fullscreen.png", "right": 68, "bottom": 18, "width": 50, "height": 50, "action": "fullscreen" }, { "type": "img", "name": "ro_logo", "url": "$LOGO", "top": 5, "xCenter": 0 }], "update": { "left": 10, "top": 34 }, "apps": { "icon": { "style": "reading-platform", "width": 76, "height": 78, "marginX": 31, "marginY": 20 }, "fontSize": 15, "color": "#619fdf", "names": [], "left": 0, "right": 300, "top": 400, "bottom": 0 }, "news": { "right": 10, "top": 70, "bottom": 20, "width": 320 } } };

}
