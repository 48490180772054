import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
	selector: 'dash-line',
	template: `
		<svg [ngStyle]="{height: dash_width + 'px'}">
			<line x1="0" y1="0" x2="100%" y2="0"/>
		</svg>
	`, styles: [`
        :host {
            display: block;
            width: 100%;
			position: relative;
        }

        svg {
            width: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
        }

        line {
            stroke: var(--color);
            stroke-dasharray: var(--dash-array);
            stroke-width: var(--dash-width);
        }
	`]
})

export class DashLineComponent implements OnInit {

	@Input() color = '#fff';
	@Input() length = 10;
	@Input() gap = 4;
	@Input() dash_width = 3;

	constructor(
		public eleRef: ElementRef,
	) {
		this.setStyle();
	}

	// onchange
	ngOnChanges() {
		this.setStyle();
	}

	setStyle() {
		this.eleRef.nativeElement.style.setProperty('--color', this.color);
		this.eleRef.nativeElement.style.setProperty('--dash-array', `${this.length} ${this.gap}`);
		this.eleRef.nativeElement.style.setProperty('--dash-width', this.dash_width);
		this.eleRef.nativeElement.style.setProperty('height', this.dash_width + 'px');

	}

	ngOnInit() {
	}
}