import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeService {

	//用作介面設定不同的theme，介面可以直接bind到"themeSelected"
	private _themeSelected: string; //'system'|'light'|'dark'
	public get themeSelected(): string { return this._themeSelected; }
	public set themeSelected(value: string) {
		if (this._themeSelected != value) {
			this._themeSelected = value;
			if (value == 'system') this.theme$.next((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) ? 'dark' : 'light');
			else this.theme$.next(value);
			this.saveSetting();
		}
	}
	//現正在用的theme名稱，與themeSelected不同，themeSelected是用戶選擇設定，option是包含'system'系統預設，而theme$則是系統真正用的theme名
	public theme$: BehaviorSubject<string> = new BehaviorSubject<string>('light');
	//可用的theme，應themeSelected
	public themeOptions: any[];


	//-----------------------------------------------------------------------------------------------
	constructor(private http: HttpClient) {
		this.getThemeJson("global.json").then((styleObj: any) => {
			this.buildOptions(styleObj);
			this.applyStyleObj(styleObj, document.body);
			this.loadSetting();
		});

		if (window.matchMedia('(prefers-color-scheme: dark)').addEventListener) {
			window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
				if (this.themeSelected == 'system') this.theme$.next(event.matches ? "dark" : "light");
				const lsInWeb = localStorage.getItem('in-web');
				if (lsInWeb === '0') {
					this.themeSelected = 'default';
				}
			});
		}
	}

	private _themeJsonDownloaded: any = {};
	private _themeJsonDownloading: string[] = [];
	public getThemeJson(filename: string): Promise<any> {
		return new Promise((resolve, reject) => {
			if (this._themeJsonDownloaded[filename]) {
				resolve(this._themeJsonDownloaded[filename]);
			} else if (this._themeJsonDownloading.indexOf(filename) > -1) {
				setTimeout(() => resolve(this.getThemeJson(filename)), 100);
			} else {
				this._themeJsonDownloading.push(filename);
				this.http.get("./assets/themes/" + filename + '?ts=' + (+new Date())).toPromise().then((themeJson: any) => {
					this._themeJsonDownloading = this._themeJsonDownloading.filter((name: string) => name != filename);
					this._themeJsonDownloaded[filename] = themeJson;
					resolve(themeJson);
				});
			}
		});
	}

	private buildOptions(styleObj): void {
		let options: any[] = [{ title_tc: "系統預設", title_sc: "系统预设", title_en: "System Default", value: "system" }];
		Object.keys(styleObj).filter((key: string) => key != 'default').map((key: string) => {
			options.push({ title_tc: styleObj[key].title_tc, title_sc: styleObj[key].title_sc, title_en: styleObj[key].title_en, value: key })
		});
		this.themeOptions = options;
	}

	private loadSetting(): any {
		try {
			let setting: any = JSON.parse(localStorage.getItem('themeSetting'));
			this.themeSelected = setting.themeSelected;
			const lsInWeb = localStorage.getItem('in-web');
			if (lsInWeb === '0') {
				this.themeSelected = 'default';
			}
		} catch (e) {
			//因應reading star account俾QEF做檢查，暫將預設改為用'light'
			//this.themeSelected = 'system';
			this.themeSelected = 'light';
			this.saveSetting();
		}
	}

  public reloadSetting() {
	try {
		let setting:any = JSON.parse(localStorage.getItem('themeSetting'));
		this.themeSelected = setting.themeSelected;
	  } catch(e){
		  //因應reading star account俾QEF做檢查，暫將預設改為用'light'
		  //this.themeSelected = 'system';
		  this.themeSelected = 'light';
		  this.saveSetting();
	  }
  }

  private saveSetting():void {
    localStorage.setItem('themeSetting', JSON.stringify({
      themeSelected: this.themeSelected,
	  themeSelectedFinal: this.theme$.value
    }));
  }

	public applyStyleObj(styleObj: any, ele: HTMLElement = document.body): Subscription {
		let subscription: Subscription = this.theme$.subscribe((theme: any) => {
			if (!ele && subscription) {
				subscription.unsubscribe();
				return;
			}
			let temp: any = { ...(styleObj.default || {}), ...(styleObj[theme] || {}) };
			Object.keys(temp).map((key: string) => {
				if (ele.style.getPropertyValue('--' + key) != temp[key]) ele.style.setProperty('--' + key, temp[key]);
			});
		});
		return subscription;
	}

	public getStyleValue(cssVarName: string, ele: HTMLElement = document.body): any {
		let result: any = ele.style.getPropertyValue('--' + cssVarName);
		if (result) {
			return result;
		} else {
			if (ele != document.body && ele.parentElement) {
				return this.getStyleValue(cssVarName, ele.parentElement);
			} else {
				return '';
			}
		}
	}

}
