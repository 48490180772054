export class EnglishNumberParser
{
	private map:any;
	
	public constructor()
	{
		this.map = this.initMap();
		
	}
	
	public parse(numberString:string):number
	{
		var parts:any[]  = numberString.split(/[ \-]/);
		return this.parseArray(parts);
	}
	
	public parseArray(array:any[] ):number
	{
		var list:any[]  = [];
		/*
		merge(multipliers, "m", map);
		merge(nTensMap, "t", map); 
		*/
		var temp:boolean;
		array.forEach((element:string, index:number):void=>
		{
			if (!element) return;
			var key:string = element.toLowerCase();
			if (this.map.hasOwnProperty(key))
			{
				var e:any = this.map[key];
				if (e.type == "m" || e.type == "t" )
				{
					temp = true;
				}
				list.push({
					index:index,
					element:e
				});
			}
		});
		if (temp)
		{
			return this.analyze(list, 0, list.length - 1);
		} else {
			var s:string = list.map((o:any)=>
			{
				return o.element.value;
			}).join("");
			return parseInt(s);
		}
		
	}
	
	private analyze(array:any[] , start:number , end:number ):number
	{
		var obj:any ;
		var i:number ;
		/*
		var numberString:string = "fifty-one thousand million five hundred seventy-eight thousand three hundred two";
		// 51,578,302
				  m	       m           
		( ((51) * 10 ^ 3) * 10 ^ 6 ) +  ( (5 * 10 ^ 2) + 70 + 8 ) * 1000 + (3 * 100) + 2
		*/
		var max:any = null;
		for (i = start; i <= end; i++)
		{
			obj = array[i];
			if (obj)
			{
				if (obj.element)
				{
					if (obj.element.type == "m")
					{
						if (max == null || obj.element.value > max.element.value)
						{
							max = obj;
						}
					}
				} else {
					// trace(2);
				}
			} else {
				// trace(1);
			}
		}
		
		if (max)
		{
			/*
			if (max.index == start || max.index == end)
			{
				
			}
			*/
			if (max.index == start)
			{
				throw new Error("unknown number");
			} else if (max.index == end)
			{
				return this.analyze(array, start, max.index - 1  ) * max.element.value ;// million, thousand, hundred, 
			}
			return ( 
				this.analyze(array, start, max.index - 1  ) * max.element.value // million, thousand, hundred, 
			) + this.analyze(array, max.index + 1, end )
		} else {
			
			var list:any[]  = [];
			var value:number = 0;
			for (i = start; i <= end; i++)
			{
				obj = array[i];
				list.push(obj);
				value += obj.element.value;
			}
			return value;
		}
	}
	
	public canBeNumber(n:string):Boolean
	{
		return this.map.hasOwnProperty(n);
	}
	
	public isNumber(numberString:string):boolean
	{
		var parts:any[]  = numberString.split(/[ \-]/);
		var len:number  = parts.length;
		var hasEnglishNumber:boolean;
		var hasNonEnglishNumber:boolean;
		for (var i:number  = 0; i < len; i++)
		{
			var element:string = parts[i];
			if (element)
			{
				var key:string = element.toLowerCase();
				if (key == "and")
				{
					
				} else {
					if (this.map.hasOwnProperty(key))
					{
						hasEnglishNumber = true;
					} else {
						hasNonEnglishNumber = true;
					}
				}
			}
		}
		return hasEnglishNumber && (!hasNonEnglishNumber);
	}
	
	private  initMap():void
	{
		var multipliers:any = {
			"ten": 10,
			"hundred": 100,
			"hundreds": 100,
			"thousand": 1000,
			"thousands": 1000,
			"million": 1000000,
			"millions": 1000000,
			"billion": 1000000000,
			"billions": 1000000000,
			"trillion": 1000000000000,
			"trillions": 1000000000000
		};
		
		var unitMap:any = {};
		var unitString:string = "zero one two three four five six seven eight nine";
		unitString.split(" ").forEach((element:string, index:number)=>
		{
			unitMap[element.toLowerCase()] = index;
		});
		var nTensMap:any = {};
		
		"twenty thirty forty fifty sixty seventy eighty ninety".split(" ").forEach(
			(element:string, index:number)=>
			{
				nTensMap[element.toLowerCase()] = (index+2) * 10;
			}
		);
		
		var teenMap:any = {};
		"ELEVEN TWELVE THIRTEEN FOURTEEN FIFTEEN SIXTEEN SEVENTEEN EIGHTEEN NINETEEN".split(" ").forEach(
			(element:string, index:number)=>
			{
				teenMap[element.toLowerCase()] = (index+11);
			}
		);
		
		var map:any =  {};
		this.merge(multipliers, "m", map);
		this.merge(nTensMap, "t", map);
		this.merge(unitMap, "u", map);
		// merge(unitMap, "u", map);
		map["and"] = {
			type:"c"
		};
		return map;
	}
	
	private  merge(from:any , type:string, target:any ):void
	{
		for (var key in from)
		{
			target[key] = {
				type:type,
				value:from[key]
			};
		}
	}
}