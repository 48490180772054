
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from 'src/app/service/modal.service';
import { FormsModule } from '@angular/forms';
import { RODocumentService } from 'src/app/sharedModule/roModule/RODocumentService';
import { AngularResizedEventModule } from 'angular-resize-event';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { PanZoomModule } from 'src/app/sharedModule/panZoomModule/PanZoomModule';
import { ROBookPreviewComponent } from './ROBookPreviewComponent';// ROPageComponent,  
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ROPageComponentContainer } from './ROPage';
import { ROPageSliderComopnent } from './ROPageSliderComponent';
import { ROBookPreviewDemoComponent } from './ROBookPreviewDemoComponent';
import { ROComponent, ROContainerComponent, ROUnknownComponent, ROVerifyIcon} from './ROComponent';
import { ROBookComponent } from './ROBookComponent';
import { WhitePopupModule } from '../whitePopupModule/whitePopup.module';
import { AllROComponents } from './AllROComponent';
import { ROPrintBtn } from './ROPrintBtn';
import { ROQuestionNumber } from './ROQuestionNumber';
import { ROScoreFreeEditPopup, ROQuestionScore, ROScoreMCEditPopup, ROScoreTFEditPopup } from './ROQuestionScore';
import { SubmitResponseModule } from '../SubmitResponseModule/SubmitResponse.module';
import { ROAudioPlayerComponent } from './ROAudioPlayerComponent';
import { PinYinKeyboardDirective } from './keyboard/PinYinKeyboardDirective';
import { PinYinKeyboardService, PinYinKeyboardLayoutComponent, PinYinKeyboardComponent } from './keyboard/PinYinKeyboardService';
import { TranslateModule } from '@ngx-translate/core';
import { CameraCaptureModule } from '../CameraCaptureModule/CameraCapture.module';
import { ROExamPaper } from './ROExamPaper';
import { ROBookEditWorkspace } from './ROBookEditWorkspace';
// import { HTMLContainer2 } from './speech-practice/speechPracticeROSTT';
import { ROScoreCom, ROTotalScoreCom } from './ROScoring';
import { CacheResourceSource, ROBookReviewService, ROClipboardService, ROComponentDefinition, ROContextService, RODataSourceService, ResourceSource } from './ROContext';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { SelectMarkerModule } from './SelectMarker.module';
import { ROBookPrintOptionDialog } from './dialog/print_book_dialog';
import { ROBrowserComopnent, ROBrowserService } from './ROBrowserService';
import { Modal2Module } from '../modal2Module/modal2.module';
import { ComponentMenuBarModule } from '../componentMenuBarModule/componentMenuBar.module';
import { MarkingLayer } from './epen/MarkingLayer';
import { SubjectModule } from '../subjectModule/subject.module';
import { NumberPadModule } from '../numberPadModule/numberPad.module';
import { AudioModule } from '../audioModule/audio.module';
import { ReportPersonalModule } from 'src/app/sharedModule/reportPersonalModule/report-personal.module';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { QuillModule } from 'ngx-quill';
import { AppStatusModule } from '../appStatusModule/AppStatus.module';
import { WindowUnloadService } from 'src/app/service/WindowUnloadService';
import { UiSwitchModule } from 'ngx-ui-switch';
import { PrintModule } from '../printModule/Print.module';
import { RadioBoxModule } from '../radioBoxModule/radioBox.module';
import { PrintBookView } from './PrintBookView';
import { PreviewModule } from '../previewModule/preview.module';
import { DirectiveModule } from 'src/app/directive/DirectiveModule';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ProgressBoxModule } from '../progressBoxModule/progressBox.module';
import { multiSelectPullDownModule } from '../multiSelectPullDownModule/multiSelectPullDown.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BookIconModule } from 'src/app/coreModule/workspaceModule/book-icon.module';
import { ROAsset } from './ROAsset';
import { ROInfo } from './ROInfo';
import { QText } from './QText';
import { QMediaAsset, QMediaAssets } from './QMediaAsset';
import { ROLongQuestion } from './ROLongQuestion';
import { AiOralPracticeModule } from '../aiOralPracticeModule/ai-oral-practice.module';
import { speechPracticeInstruction } from './speech-practice/instruction';
import { exercisePractice } from './speech-practice/exercisePractice';
import { conversationPractice } from './speech-practice/conversationPractice';
import { speechBox } from './speech-practice/speechBox';
import { speechPracticeHeader } from './speech-practice/header';
import { speechPracticeFinalReport } from './speech-practice/finalReport';
import { YellowStarSvgModule } from './speech-practice/svg_component/yellow_star.module';
import { DictionaryPopupModule } from '../dictionaryPopupModule/dictionaryPopup.module';
import { NetworkCheckModule } from '../networkCheckModule/network-check.module';
import { HTMLContainer2, ROSTT } from './ROSTT';
import {ToolbarSettingModalComponent} from "./ToolbarSettingModal.component";
import {TabViewModule} from "../tabViewModule/TabView.module";
import {LockScreenModule} from "../../coreModule/lessonModule/WebLessonModule/BookViewerTools/LockScreen/LockScreen.module";
import {BookTocModule} from "../../coreModule/lessonModule/WebLessonModule/BookViewerTools/BookTOC/BookTOC.module";
import {WebLessonModule} from "../../coreModule/lessonModule/WebLessonModule/WebLesson.module";

// AllROComponents
@NgModule({
	imports: [
		BookIconModule,
		AngularSvgIconModule,
		multiSelectPullDownModule,
		ProgressBoxModule,
		RadioBoxModule,
		PrintModule,
		NumberPadModule,
		SubjectModule,
		BubbleBox2Module,
		FontAwesomeModule,
		PanZoomModule,
		AngularResizedEventModule,
		FormsModule,
		CommonModule,
		WhitePopupModule,
		SubmitResponseModule,
		TranslateModule,
		CameraCaptureModule,
		SelectMarkerModule,
		Modal2Module,
		ComponentMenuBarModule,
		ReportPersonalModule,
		AudioModule,
		OkaPulldownModule,
		QuillModule,

		AppStatusModule,
		UiSwitchModule,
		PreviewModule,
		DirectiveModule,
		PerfectScrollbarModule,

		AiOralPracticeModule,
		YellowStarSvgModule,
		DictionaryPopupModule,
		NetworkCheckModule,
		TabViewModule,
		LockScreenModule,
		BookTocModule,
		WebLessonModule
	],
    declarations: [
        ROComponent,
		ROContainerComponent,
		ROUnknownComponent,
		ROBookComponent,
		ROAudioPlayerComponent,
		// MySliderComponent, Slider, SlideComponent, PageSlideComponent, 
		ROPageComponentContainer, 
		ROBookPreviewComponent, 
		ROPageSliderComopnent,
		ROBookPreviewDemoComponent,
		AllROComponents,
		ROComponentDefinition,
		// , GalleryComponent
		ROPrintBtn,
		ROExamPaper,
		ROLongQuestion,
		ROQuestionNumber,
		ROQuestionScore, MarkingLayer,
		PinYinKeyboardDirective,
		PinYinKeyboardComponent,
		PinYinKeyboardLayoutComponent,
		ROBookEditWorkspace,
		
		ROScoreCom, ROTotalScoreCom,
		ROVerifyIcon,
		HTMLContainer2,
		conversationPractice,
		exercisePractice,
		speechBox,
		speechPracticeInstruction,
		speechPracticeHeader,
		speechPracticeFinalReport,
		ROScoreFreeEditPopup, ROScoreMCEditPopup, ROScoreTFEditPopup,
		ROBookPrintOptionDialog,
		ROBrowserComopnent,
		PrintBookView,
		ROAsset, ROInfo, QText, QMediaAssets, QMediaAsset,
		ToolbarSettingModalComponent,
    ],
    exports: [
		ROComponent,
		ROContainerComponent,
		
		ROUnknownComponent,
		
		ROBookComponent,
		ROAudioPlayerComponent,
		// MySliderComponent, 
		// Slider, SlideComponent, PageSlideComponent, 
		ROPageComponentContainer, 
		ROBookPreviewComponent, 
		ROPageSliderComopnent,
		ROBookPreviewDemoComponent,
		/*
		ROPageComponent,
		ROUnknownComponent,
		RODemoComponent,
		ROGroupComponent
		*/
		AllROComponents,
		ROComponentDefinition,
		
		ROExamPaper,
		ROLongQuestion,
		ROPrintBtn,
		ROQuestionNumber, ROQuestionScore, MarkingLayer,

		
		PinYinKeyboardDirective,
		PinYinKeyboardComponent,
		PinYinKeyboardLayoutComponent,
		ROBookEditWorkspace,

		ROScoreCom, ROTotalScoreCom,
		ROVerifyIcon,
		HTMLContainer2,
		speechPracticeInstruction,
		conversationPractice,
		exercisePractice,
		speechBox,
		speechPracticeHeader,
		speechPracticeFinalReport,
		ROScoreFreeEditPopup, ROScoreMCEditPopup, ROScoreTFEditPopup,
		ROBookPrintOptionDialog,
		ROBrowserComopnent,
		PrintBookView,
		ROAsset, ROInfo, QText, QMediaAssets, QMediaAsset,
		ROSTT
	],
	entryComponents: [ 
		ROComponent,
		ROContainerComponent,
		
		ROUnknownComponent,
		ROBookComponent,
		ROAudioPlayerComponent,
		ROPageSliderComopnent, 
		ROPageComponentContainer,
		ROBookPreviewDemoComponent,
		AllROComponents,
		ROComponentDefinition, 
		
		ROPrintBtn,
		ROExamPaper,
		ROLongQuestion,
		ROQuestionNumber,
		ROQuestionScore, MarkingLayer,
		
		PinYinKeyboardComponent,
		PinYinKeyboardLayoutComponent,
		/*
		ROPageComponent,
		ROUnknownComponent,
		ROTLFTextComponent,
		ROShapeRectangleComponent,
		ROImageComponent,
		ROGraphicComponent,
		ROSuperTextComponent,
		ROShapeCircleComponent,
		ROShapeCustomComponent,
		RODemoComponent,
		ROGroupComponent
		// SlideComponent
		*/

		ROBookEditWorkspace,
		ROScoreCom, ROTotalScoreCom,
		ROVerifyIcon,
		HTMLContainer2,
		speechPracticeInstruction,
		conversationPractice,
		exercisePractice,
		speechBox,
		speechPracticeHeader,
		speechPracticeFinalReport,
		ROScoreFreeEditPopup, ROScoreMCEditPopup, ROScoreTFEditPopup,
		ROBookPrintOptionDialog,
		ROBrowserComopnent,
		PrintBookView,
		ROAsset, ROInfo, QText, QMediaAssets, QMediaAsset,
		ROSTT
	],
    providers: [
		ModalService, RODocumentService, DynamicComponentService, PinYinKeyboardService, ROContextService, ROBrowserService, ROClipboardService, CacheResourceSource,
		WindowUnloadService, ROBookReviewService, RODataSourceService
	],
    bootstrap: []
})
export class ROBookModule { }

