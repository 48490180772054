import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DataService} from "../../../service/data.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TagGroupService} from "../../../sharedModule/tagGroupModule/tagGroup.service";
import {ThemeService} from "../../../service/theme.service";
import {CommonService} from "../../../service/common.service";
import {ROBookPreviewComponent} from "../../../sharedModule/roBookModule/ROBookPreviewComponent";
import {WebSocketService} from "../../../service/webSocket.service";
import {LoadingService} from 'src/app/sharedModule/loadingModule/loading.service';
import {DomSanitizer} from "@angular/platform-browser";
import {UserInfoHelperService} from "../../../service/UserInfoHelper.service";
import {WebLessonService} from "./services/WebLesson.service";

@Component({
	selector: 'web-lesson',
	template: `
		<select-book-page
			*ngIf="web_lesson.lesson_id && !selected_book"
			[student_list]="web_lesson.student_list"
			(btn_close_clicked)="leave_lesson()"
		></select-book-page>
	`,
	styleUrls: ['./web-lesson.component.scss']
})

export class WebLessonComponent implements OnInit {
	selected_book = null;

	@ViewChild('ro_book_preview', {static: false}) ro_book_preview: ROBookPreviewComponent;

	subscriptions = [];

	constructor(
		private datas: DataService,
		private trans: TranslateService,
		private router: Router,
		private route: ActivatedRoute,
		private tg: TagGroupService,
		public theme: ThemeService,
		public eleRef: ElementRef,
		public coms: CommonService,
		public wss: WebSocketService,
		public loading: LoadingService,
		private dom_sanitizer: DomSanitizer,
		public user_info_helper: UserInfoHelperService,
		public web_lesson: WebLessonService,
	) {
		this.trans.use(this.datas.lang);
		this.subscriptions.push(this.route.params.subscribe(params => {
			this.web_lesson.join_lesson(params['lesson_id']);
			this.web_lesson.on_message.subscribe(message => {
				if (message.content.action === 'update_lesson_state') {
					if (this.web_lesson.lesson_state.is_started) {
						let {share_id, book_id, published, lesson_id} = this.web_lesson.lesson_state;
						this.router.navigate(['bookViewer', share_id, book_id, published, lesson_id, 'lesson']);
					}
				}
			})
		}));
		this.theme.theme$.next(this.theme.themeSelected);
		this.initTheme(this.eleRef.nativeElement);

	}

	ngOnInit() {
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach(sub => sub.unsubscribe());
		this.subscriptions = [];
	}

	initTheme(ele) {
		this.theme.applyStyleObj({
			"default": {
				"section-maincolor": "#007464",
				"section-popupbgcolor": "#e5eeea",
				'alert-bgcolor': '#ffffff',
				'alert-buttontextcolor': '#ffffff',
				'alert-cancelbuttoncolor': '#1e1e29'
			},
			"dark": {
				"section-maincolor": "#159a81",
				"section-popupbgcolor": "#2D2C43",
				'alert-bgcolor': '#2D2C43',
				'alert-buttontextcolor': '#ffffff',
				'alert-cancelbuttoncolor': '#bebebe'
			}
		}, ele);

		this.theme.applyStyleObj({
			"default": {
				"section-maincolor": "#007464",
				"section-popupbgcolor": "#e5eeea",
				'alert-bgcolor': '#ffffff',
				'alert-buttontextcolor': '#ffffff',
				'alert-cancelbuttoncolor': '#1e1e29'
			},
			"dark": {
				"section-maincolor": "#159a81",
				"section-popupbgcolor": "#2D2C43",
				'alert-bgcolor': '#2D2C43',
				'alert-buttontextcolor': '#ffffff',
				'alert-cancelbuttoncolor': '#bebebe'
			}
		}, document.body);
		document.documentElement.style.setProperty('--section-maincolor', '#007464');
	}

	async leave_lesson() {
		await this.web_lesson.end_session();
		await this.web_lesson.leave_lesson();
		this.router.navigate(['lesson', this.datas.lang, this.datas.appId,]);
	}
}