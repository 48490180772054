import {Component, OnInit, ViewChild, Output, EventEmitter, ElementRef} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Modal2Component} from 'src/app/sharedModule/modal2Module/modal2.component';
import {ThemeService} from "../../../../../service/theme.service";
import {CommonService} from "../../../../../service/common.service";

@Component({
	selector: 'good-work-print-modal',
	template: `
		<modal2
			#modal
			(cancelClick)="modal.close()"
			(confirmClick)="submit()"
			[maxHeight]="'calc(100dvh - 40px)'"
			[width]="'520px'"
			[contentStyle]="{'overflow-y': 'auto', background: 'var(--section-popupbgcolor)'}"
			[title]="trans.instant('web-lesson-tools.good-work.print')"
		>
			<table class="form" *ngIf="setting">
				<tr>
					<th class="required">{{ 'web-lesson-tools.good-work.destination' | translate }}</th>
					<td>
						<radioBox
							[(value)]="setting.destination"
							[options]="destination_options"
							class="vertical"
							(valueChange)="init_options()"
						></radioBox>
					</td>
				</tr>
				<tr>
					<th class="required">{{ 'web-lesson-tools.good-work.book_name_and_page_no' | translate }}</th>
					<td>
						<radioBox
							[(value)]="setting.book_name_and_page_no"
							[options]="book_name_and_page_no_options"
						></radioBox>
					</td>
				</tr>
				<tr>
					<th class="required">{{ 'web-lesson-tools.good-work.date_time' | translate }}</th>
					<td>
						<radioBox
							[(value)]="setting.print_date_time"
							[options]="date_time_options"
						></radioBox>
					</td>
				</tr>
				<tr *ngIf="setting.destination === 'png'">
					<th class="required">{{ 'web-lesson-tools.good-work.resolution' | translate }}</th>
					<td>
						<radioBox
							[(value)]="setting.resolution"
							[options]="resolution_options"
						></radioBox>
					</td>
				</tr>
			</table>
		</modal2>
	`,
	styleUrls: ['./good-work-print-modal.component.scss']
})

export class GoodWorkPrintModalComponent implements OnInit {

	setting = null;

	destination_options = [];
	book_name_and_page_no_options = [];
	date_time_options = [];
	resolution_options = [];

	@ViewChild('modal', {static: true}) modal: Modal2Component;

	@Output() print = new EventEmitter();

	constructor(
		public trans: TranslateService,
		public coms: CommonService,
		public theme: ThemeService,
		public eleRef: ElementRef,
	) {

	}

	ngOnInit() {
		// this.init_options();
		this.theme.applyStyleObj({
			"default": {
				"section-popupbgcolor": "#F8EBF4",
			},
			"dark": {
				"section-popupbgcolor": "#2d2c43",
			}
		}, this.eleRef.nativeElement)
	}

	init_options() {
		this.destination_options = [
			{value: 'print', label: this.trans.instant('web-lesson-tools.good-work.destination_printer_or_pdf')},
			{value: 'png', label: this.trans.instant('web-lesson-tools.good-work.destination_png')},
		];

		this.book_name_and_page_no_options = [
			{value: 1, label: this.trans.instant('web-lesson-tools.good-work.option_print')},
			{value: 0, label: this.trans.instant('web-lesson-tools.good-work.option_not_print')},
		];

		this.date_time_options = [
			{value: 1, label: this.trans.instant('web-lesson-tools.good-work.option_print')},
			{value: 0, label: this.trans.instant('web-lesson-tools.good-work.option_not_print')},
		];

		this.resolution_options = [
			{value: 72, label: this.trans.instant('web-lesson-tools.good-work.resolution_low')},
			{value: 144, label: this.trans.instant('web-lesson-tools.good-work.resolution_medium')},
			// ...this.setting.destination === 'print' ? [{value: 300, label: this.trans.instant('web-lesson-tools.good-work.resolution_high')},] : [],
		];
		// if(this.setting.resolution === 300) {
		// 	this.setting.resolution = 144;
		// }

	}

	open() {
		this.setting = {
			destination: 'print',
			book_name_and_page_no: 1,
			resolution: 72,
			print_date_time: 1,
		};
		this.init_options();

		this.modal.open();
	}

	async submit() {
		this.modal.close();
		await this.coms.timeout(1);
		this.print.emit(this.setting);
	}
}