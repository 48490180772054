import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { faMinus, faPlus, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ThemeService } from 'src/app/service/theme.service';

@Component({
	selector: 'propertyScoreInput',
	templateUrl: './propertyScoreInput.component.html',
	styleUrls: ['./propertyScoreInput.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PropertyScoreInputComponent),
			multi: true
		}
	]
})

export class PropertyScoreInputComponent implements ControlValueAccessor {
	@Input() min: number = 1;
	@Input() max: number = 99999999999;
	@Input() step: number = 1;
	@Input() disabled: boolean = false;
	@Input() iconColor: string = "#fff";
	@Input() inputBackgroundColor: string = "#00000066";
	@Input() inputTextColor: string = "#fff";

	@Output() valueChange: EventEmitter<number> = new EventEmitter<number>();

	public faMinus: IconDefinition = faMinus;
	public faPlus: IconDefinition = faPlus;

	public _value: number = 1;
	private onChange: (_: any) => void = () => { };
	private onTouched: () => void = () => { };

	propagateOnChange: (value: any) => void = (_: any) => { };
	propagateOnTouched: (value: any) => void = (_: any) => { };

	constructor(public theme: ThemeService){
		this.theme.applyStyleObj(
			{
				default: {
					"number-input-icon-color": this.iconColor,
					"number-input-text-color": this.inputTextColor,
					"number-input-background-color": this.inputBackgroundColor,
				},
			},
			document.body
		);
	}


	plusOneStep() {
		if (typeof this._value === 'string') {
		  // Attempt to parse the string to a number
		  const parsedValue = parseFloat(this._value);
	  
		  if (isNaN(parsedValue)) {
			throw new Error('Invalid value. Cannot parse to number.');
		  }
	  
		  this._value = parsedValue;
		}
	  
		if (typeof this._value !== 'number') {
		  throw new Error('Invalid type. Expected number.');
		}
	  
		if (!this.disabled) {
		  if (!isNaN(this.max) && this._value + this.step <= this.max) {
			this._value += this.step;
		  } else {
			this._value = this.max;
		  }
	  
		  this.propagateOnChange(this._value);
		  this.valueChange.emit(this._value);
		}
	  }
	  

	  minusOneStep() {
		if (typeof this._value === 'string') {
		  // Attempt to parse the string to a number
		  const parsedValue = parseFloat(this._value);
	  
		  if (isNaN(parsedValue)) {
			throw new Error('Invalid value. Cannot parse to number.');
		  }
	  
		  this._value = parsedValue;
		}
	  
		if (typeof this._value !== 'number') {
		  throw new Error('Invalid type. Expected number.');
		}
	  
		if (!this.disabled) {
		  if (!isNaN(this.min) && this._value - this.step >= this.min) {
			this._value -= this.step;
		  } else {
			this._value = this.min;
		  }
	  
		  this.propagateOnChange(this._value);
		  this.valueChange.emit(this._value);
		}
	  }
	  

	get value(): any {
		return this._value;
	}

	set value(val: any) {
		if (val !== this._value) {
			this._value = val;
			this.onChange(val);
			this.valueChange.emit(this._value);
		}
	}

	writeValue(value: any) {
		this._value = value;
	}

	registerOnChange(fn: (_: any) => void) {
		this.onChange = fn;
		this.propagateOnChange = (value: any) => {
			fn(value);
		};
	}

	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
		this.propagateOnTouched = () => {
			fn();
		};
	}

	onInputChange(event: Event) {
		const input = (event.target as HTMLInputElement).valueAsNumber
		// Check if the input is a valid number
		const value = Number(input)
		
		if (isNaN(value)) {
			// If it's not a number, set to min
			this._value = this.min;
		  } else if (value < this.min) {
			this._value = this.min;
		  } else if (value > this.max) {
			this._value = this.max;
		  } else {
			this._value = value;
		}
	}

	onBlur() {
		if (!this._value) {
			this._value = this.min;
		} else if (this._value < this.min) {
			this._value = this.min;
		} else if (this._value > this.max) {
			this._value = this.max;
		}
		
		// Format the number based on step value
		if (this.step === 1) {
			this._value = Math.round(this._value); // Round to nearest whole number
		} else if (this.step === 0.1) {
			this._value = parseFloat(this._value.toFixed(1)); // One decimal place
		} else if (this.step === 0.5) {
			this._value = Math.round(this._value * 2) / 2; // Round to nearest 0.5
		}

		this.propagateOnChange(this._value);
		this.valueChange.emit(this._value);
	}
}
