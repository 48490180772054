import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { categories } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import {UserManager} from "@oup/oidc-client";
// @ts-ignore
import {MiddleLayerClient} from '@oup/oupc-middle-layer-client';
import { CommonService } from 'src/app/service/common.service';
import { DataService } from 'src/app/service/data.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class OupService {

	public userManager = null;
	public mlClient = null;
	public isOupUser:boolean = false;

	private _isRunningOupTokenLogin:boolean = false;
	private _isRunningLoginPostProcess:boolean = false;

	constructor(private coms:CommonService, private datas:DataService, private router:Router, private aRoute:ActivatedRoute) {
		this.init();
		
		this.checkIsOupUser();

		this.datas.jwt$.subscribe(()=>{
			this.checkIsOupUser();
		});

		this.datas.userInfo$.subscribe(()=>{
			if (this.isOupUser) this.loginPostProcess();
		});
	}
	
    private createUserManager(endpoint: string, clientID: string):UserManager|null {
        if (!endpoint || !clientID) return null;

        const currentUrl = window.location.href;
        const url = new URL(currentUrl);

        return new UserManager({
            authority: `${endpoint}/auth/v1`,
            client_id: clientID,
            redirect_uri: `${url.origin}/redirect`,
            popup_redirect_uri: `${url.origin}/redirect`,
            post_logout_redirect_uri: `${url.origin}/login`,
            response_type: 'code',
            scope: 'openid',
            // Set automaticSilentRenew to true
            // The client library would renew access token with refresh token when the access token is about to expire
            //
            // When user is idle for one minute, the renewal would be paused
            // It would resume once the user is active again
            automaticSilentRenew: true,
            // Uncomment next line to customize the idle time of pausing token renewal, 60 * 1000 = 60s
            // pauseSilentRenewIdleTimeout: 60 * 1000
        });
    }

	public init():void {
		
		let endpoint:string = "https://dev.account.oupchina.com.hk/";
		let clientID:string = "dev/activityplatform";
		let apiKey:string = "dev/activityplatform.e0kzc5aKPBCRnKWXqjIhrGmeJivjwnVB";

		this.userManager = this.createUserManager(endpoint, clientID);
		this.mlClient = this.getMLClient(endpoint, this.userManager, apiKey);
	}

    public getMLClient(endpoint: string, userManager: null | UserManager, apiKey: string):MiddleLayerClient{
        if (endpoint === '' || endpoint == null || userManager == null) {
            return null;
        }
    
        const mlClient = new MiddleLayerClient({
            dataEndpoint: `${endpoint}/data/v1`,
            authEndpoint: `${endpoint}/auth/v1`,
            apiKey: apiKey,
            language: 'zh-hant'
        });
        mlClient.linkOIDCUserManager(userManager);
        return mlClient;
    }

	public checkIsOupUser():Promise<boolean> {
		return new Promise((resolve, reject)=>{
			this.coms.waitFor(()=>this.datas && this.datas.userInfo && this.datas.userInfo.school).then(()=>{
				if (this.datas.userInfo.school.plan_type=='oup' && !this.isOupUser) {
					this.isOupUser = true;
					// this.signInRedirectCallback();

					this.userManager.getUser().then((user:any)=>{
						// console.log(user);
						// console.log(this.mlClient);
						if (user && !user.expired) {
							//如果token未expire，可繼續用
							this.mlClient.accessToken = user.access_token;

							this.oupTokenLogin(
								user.profile["https://oupchina.com.hk/school_id"],
								user.profile.userID,
								user.profile["https://oupchina.com.hk/user_type"],
								user.access_token
							);
						} else {
							this.userManager.signinRedirect({
								prompt: 'none',
								state: window.location.toString()
							});
						}
			
			
					});

				} else if (this.datas.userInfo.school.plan_type!='oup' && this.isOupUser) {
					this.isOupUser = false;
					this.oupLogout();
				}
			}).catch(()=>{
				this.isOupUser = false;
				// this.oupLogout();
			}).finally(()=>{
				resolve(this.isOupUser);
			});
		});
	}

	public signInRedirectCallback():void {
		this.userManager
		.signinRedirectCallback()
		.then((user: any) => {
			// console.log(user);
			// {
			// 	"id_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ii0yT0lnYjNLdENJWTJqMnZOdTQtUFJtSXZUNnRIVkphaDBZa25NZzhHY0EifQ.eyJjbGllbnRJRCI6ImRldi9hY3Rpdml0eXBsYXRmb3JtIiwibm9uY2UiOiJhOGVhNWJkYTNhMzM0NTAxYTI4NDQ1ODk5NmUwZDViYSIsImlhdCI6MTcyNjgyODMyOCwiZXhwIjoxNzI2ODMxOTI4LCJhdWQiOiJkZXYvYWN0aXZpdHlwbGF0Zm9ybSIsImlzcyI6Imh0dHBzOi8vZGV2LmFjY291bnQub3VwY2hpbmEuY29tLmhrIiwic3ViIjoiZDljYTg4ODgtNWNhMi00OWM4LTg1YWMtNTFlYmZkZTFlNTVjIn0.YLpeyZyu8pPPX8FRjyUK8Z1wQtCKx-9wZssKgIFdjMviJYgYucGvyDCKkXhv8XUrJQEci3Kd0sb3wyay-MrW6Sxj2AL1aJNV2lrwahivo6r-wu4hNqEqhwuWMzDA4oVi2dCnZVqphDx_9Y56s9kLpWlJ3PjQX9LT-zvdoBgLuqYu36E6YQnWJmLOsJhARxUsNfr-qcGH-WqDiDmfqyHFVei-cIKoHdsnIxG8LiBZuMVu3nfYVZrSco-jbVI6oD43ricjecV0o9n9hHv87LHj0zCKhI8GEwlXgEqvETuLCG-1JOBGd2pwqR4PUwUHrABHlx_LBO4HO5UGrE1tcqNcXw",
			// 	"access_token": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ii0yT0lnYjNLdENJWTJqMnZOdTQtUFJtSXZUNnRIVkphaDBZa25NZzhHY0EifQ.eyJzY29wZSI6Im9wZW5pZCIsImdyYW50X3R5cGUiOiJhdXRob3JpemF0aW9uX2NvZGUiLCJpYXQiOjE3MjY4MjgzMjgsImV4cCI6MTcyNjgyODQxOCwiYXVkIjoiZGV2L2FjdGl2aXR5cGxhdGZvcm0iLCJpc3MiOiJodHRwczovL2Rldi5hY2NvdW50Lm91cGNoaW5hLmNvbS5oayIsInN1YiI6ImQ5Y2E4ODg4LTVjYTItNDljOC04NWFjLTUxZWJmZGUxZTU1YyJ9.b-uj85wFDLpIochEsDuIHMoKnJLYDTG4a-Mx50T3X5Y9rEgKrBlFcSNiyuTaTvGAYXkp4_GXp0zkKUgGsGWJn8f8gwd4wZPGfHKoNIeWY_VWwy6fzsDFUoQwhI2s0d3y2jRU7o2srB1QK4YXG6YYWSryuLN3xCMolm6Q2-ma8Nh9b58AE0XDlqm0bDbfEEaRDTfB-ZCOyQROUiCYzq7y82XbqKX6lyX-4-nUvek1IFFF5Kvz1SNanbdwO2l3i-3yHF-q18bHPUXuap72N5N0CIzeIp-DVK-uf41L1Q10WAUP8OQp1N--frn_inpFYloed9dXNl1ciqRM7tI1CQXcLw",
			// 	"refresh_token": "ZbACmByhZZ9tdZTfkn1P45lLwq4k0oRB.UpzB9dj7fFbGrsPvKn6zZIeU7VPzPjfL",
			// 	"token_type": "bearer",
			// 	"scope": "openid",
			// 	"profile": {
			// 		"clientID": "dev/activityplatform",
			// 		"sub": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 		"userID": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 		"https://oupchina.com.hk/identity_provider": "esas",
			// 		"https://oupchina.com.hk/identity_provider_user_data": {
			// 			"usertoken": 20033912,
			// 			"loginID": "act_test_t1"
			// 		},
			// 		"https://oupchina.com.hk/school_id": "5ec9a3c2-32d4-4adf-8176-41d145c2a770",
			// 		"https://oupchina.com.hk/school#en": "(demo) Activity School",
			// 		"https://oupchina.com.hk/school#zh-Hant": "(demo) Activity School",
			// 		"https://oupchina.com.hk/user_type": "teacher",
			// 		"https://oupchina.com.hk/name#zh-Hant": "act_test_t1",
			// 		"https://oupchina.com.hk/name#en": "act_test_t1",
			// 		"https://oupchina.com.hk/academic_year": "2024-2025",
			// 		"https://oupchina.com.hk/teacher_position": "Teacher",
			// 		"https://oupchina.com.hk/keep_me_signed_in": false,
			// 		"https://oupchina.com.hk/initial_login_client_id": "dev/activityplatform"
			// 	},
			// 	"expires_at": 1726828417
			// }
			if (user.state != null) {
				// if user.state is not null
				// it carries the state set when signin start
				//
				// if the signin is triggered by userManager.signinRedirectWithESASToken
				// the user.state would be the url object of the location right before signin
			}

			this.isOupUser = true;

			this.oupTokenLogin(
				user.profile["https://oupchina.com.hk/school_id"],
				user.profile.userID,
				user.profile["https://oupchina.com.hk/user_type"],
				user.access_token
			);
			
		})
		.catch((error: any) => console.log('login error', error));
	}

	public signinRedirectWithESASToken():void {
		this.userManager
		.signinRedirectWithESASToken()
		.then((user: any) => {
			if (user.state != null) {
				// if user.state is not null
				// it carries the state set when signin start
				//
				// if the signin is triggered by userManager.signinRedirectWithESASToken
				// the user.state would be the url object of the location right before signin
			}

			this.isOupUser = true;

			this.oupTokenLogin(
				user.profile["https://oupchina.com.hk/school_id"],
				user.profile.userID,
				user.profile["https://oupchina.com.hk/user_type"],
				user.access_token
			);
			
		})
		.catch((error: any) => console.log('login error', error));
	}

	public oupTokenLogin(schoolId:string, userId:string, userType:string, token:string):void {
		if (this._isRunningOupTokenLogin) return;
		this._isRunningOupTokenLogin = true;

		let apiData:any = [
			"oup",
			{
				"school_id": schoolId,
				"user_id": userId,
				"user_type": userType,
				"token": token
			}

		];
		this.datas.post2({ data: { api: 'Account.login_by', json: apiData } }).then((res:any)=>{
			if (res.account) {
				this.datas.userInfo = res.account;
				if (typeof this.datas.userInfo.personalSettings == 'string'){
					this.datas.userInfo.personalSettings = JSON.parse(this.datas.userInfo.personalSettings);
				}
				this.datas.jwt = res.account.jwt;
				let homeLayout: any = res.webHomeLayout ? res.webHomeLayout : res.homeLayout;
				if (localStorage) {
					localStorage.setItem('common_jwt', this.datas.jwt);
					localStorage.setItem('common_uid', res.account.uid);
					localStorage.setItem('common_schoolId', res.account.school_id);
					// if (this.schoolModal) {
					// 	let found = this.schoolModal.items.find(item => item.uid == uid);
					// 	if (found && found.url) {
					// 		localStorage.setItem('common_photo', found.url);
					// 	}
					// }
					let found = res.settings.find(s => s.key == 'HOME_PAGE_THEME_COLOR');
					if (found) {
						let obj = JSON.parse(homeLayout);
						obj.homePageThemeColor = found.value.replace(/[\\\"]/g, '');
						homeLayout = JSON.stringify(obj);
					}
					let homeScreenData = { account: res.account, homeLayout: homeLayout, homeSettings: res.homeSettings };
					localStorage.setItem('homeScreen_data', JSON.stringify(homeScreenData));
				}
				try { this.datas.jwtObj = JSON.parse(this.datas.jwt); } catch (e) { }
				// this.storage.insertLoginRecord(res.account.uid);
				// let switchAccount = this.datas.post('RODevice.switch_account', [this.data.jwtDeviceToken, this.datas.jwt]);
				// zip(this.tg.loadSchoolYear(), switchAccount).subscribe((res: any) => {
				// 	try {
				// 		homeLayout = JSON.parse(homeLayout);
				// 	} catch (e) { }
				// 	// if (this.eruda.commands) {
				// 	// 	this.tg.resetDataifSchoolChange(); //reset school year
				// 	// 	let path = this.eruda.commands.path;
				// 	// 	this.eruda.commands = null;
				// 	// 	if (path.indexOf('login') == -1) {
				// 	// 		this.router.navigate([path]);
				// 	// 		return;
				// 	// 	}
				// 	// }
				// 	let homeScreen = 'icon';
				// 	if (homeLayout.options) {
				// 		if (homeLayout.options.main_page_mode) {
				// 			homeScreen = homeLayout.options.main_page_mode;
				// 		}
				// 	}
				// 	this.storage.redirectMain(homeScreen);
				// });
				// } else if (res.code == 10) {
				// 	this.lds.remove('load');
				// 	this.alertModal = {
				// 		type: 'confirm', msg: 'confirm-force-login', confirm: () => {
				// 			this.login(1);
				// 		}
				// 	};
				// } else if (res.code == 16) {
				// 	this.lds.remove('load');
				// 	this.als.alert('login.login-msg-16', null, { btns: [['ok']] });
				// } else if (res.code == 11) {
				// 	this.lds.remove('load');
				// 	this.als.alert('login.msg-account-not-exist', null, { btns: [['ok']] });
				// } else {
				// 	this.lds.remove('load');
				// 	this.als.alert('Error code:' + res.code, null, { btns: [['ok']] });
				// }
				// });
				if (res.message=="LoginSuccess") {
					this.router.navigate(['../../home-screen'], {relativeTo: this.aRoute});
					this.loginPostProcess();
				}
			}
			this._isRunningOupTokenLogin = false;
		});
	}

	public oupLogout():void {
		// Clear local storage
        // localStorage.clear();

        // // Clear cookies
        // const cookies = document.cookie.split(';');
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i];
        //     const eqPos = cookie.indexOf('=');
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
        // }

        // // Clear session storage
        // sessionStorage.clear();

		// window.location.href = "login";
        // navigate('/')
        // Refresh the page
        // window.location.reload();

		this.userManager.signoutRedirect();
	}

	private loginPostProcess():void {
		if (this._isRunningLoginPostProcess) return;
		this._isRunningLoginPostProcess = true;

		this.updateUserInfo().then((oupUser:any)=>{

			//取得User的Entitlements
			//https://dev.account.oupchina.com.hk//data/v1/users/d9ca8888-5ca2-49c8-85ac-51ebfde1e55c/entitlements?categories%5B%5D=D6&year=2024-2025
			this.mlClient.fetchEntitlementsByUserID(oupUser.userID, {
				year: oupUser["https://oupchina.com.hk/academic_year"],
				categories: ["D6"]
			}).then((result:any) => {
				// console.log(result);
			});
	
			//取得學校的classes
			//https://dev.account.oupchina.com.hk//data/v1/schools/5ec9a3c2-32d4-4adf-8176-41d145c2a770/classes?year=2024-2025
			this.mlClient.fetchClassesBySchoolID(oupUser["https://oupchina.com.hk/school_id"], {
				year: oupUser["https://oupchina.com.hk/academic_year"]
			}).then((result:any) => {
				// console.log(result);
			});
		}).catch((e:any)=>{
			console.log(e);
		}).finally(()=>{
			this._isRunningLoginPostProcess = false;
		});


	}
	
	// public fetchUserInfo():Promise<any> {
	// 	return new Promise((resolve:Function, reject:Function):void=>{
	// 		if (this._apiData.fetchUserInfo!=undefined) {
	// 			resolve(this._apiData.fetchUserInfo);
	// 		} else {
	// 			this.mlClient.fetchUserInfo().then((oupUser:any)=>{
	// 				this._apiData.fetchUserInfo = oupUser;
	// 				resolve(this._apiData.fetchUserInfo);
	// 			});
	// 		}
	// 	});
	// }

	public updateUserInfo():Promise<void> {
		if (!this.isOupUser) return Promise.reject("notOupUser");

		return this.mlClient.fetchUserInfo().then((oupUser:any)=>{
			// console.log(userInfo);
			// userInfo: {
			// 	"userID": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c",
			// 	"https://oupchina.com.hk/identity_provider": "esas",
			// 	"https://oupchina.com.hk/identity_provider_user_data": {
			// 		"usertoken": 20033912,
			// 		"loginID": "act_test_t1"
			// 	},
			// 	"https://oupchina.com.hk/school_id": "5ec9a3c2-32d4-4adf-8176-41d145c2a770",
			// 	"https://oupchina.com.hk/school#en": "(demo) Activity School",
			// 	"https://oupchina.com.hk/school#zh-Hant": "(demo) Activity School",
			// 	"https://oupchina.com.hk/user_type": "teacher",
			// 	"https://oupchina.com.hk/name#zh-Hant": "act_test_t1",
			// 	"https://oupchina.com.hk/name#en": "act_test_t1",
			// 	"https://oupchina.com.hk/academic_year": "2024-2025",
			// 	"https://oupchina.com.hk/teacher_position": "Teacher",
			// 	"clientID": "dev/activityplatform",
			// 	"https://oupchina.com.hk/keep_me_signed_in": false,
			// 	"https://oupchina.com.hk/last_login_client_id": "dev/activityplatform",
			// 	"https://oupchina.com.hk/initial_login_client_id": "dev/activityplatform",
			// 	"sub": "d9ca8888-5ca2-49c8-85ac-51ebfde1e55c"
			// }

			this.datas.userInfo.ename = oupUser["https://oupchina.com.hk/name#en"];
			this.datas.userInfo.cname = oupUser["https://oupchina.com.hk/name#zh-Hant"];
			this.datas.userInfo.first_name = oupUser["https://oupchina.com.hk/name#en"];
			this.datas.userInfo.c_first_name = oupUser["https://oupchina.com.hk/name#zh-Hant"];
			this.datas.userInfo.last_name = "";
			this.datas.userInfo.c_last_name = "";
			this.datas.userInfo.gender = 1;
			this.datas.user_role = oupUser["https://oupchina.com.hk/teacher_position"] == "Teacher" ? 3 : 2;

			if (this.datas.getLang() == "en") {
				this.datas.userInfo.school.title = oupUser["https://oupchina.com.hk/school#en"];
				this.datas.userInfo.school_name = oupUser["https://oupchina.com.hk/school#en"];
			} else {
				this.datas.userInfo.school.title = oupUser["https://oupchina.com.hk/school#zh-Hant"];
				this.datas.userInfo.school_name = oupUser["https://oupchina.com.hk/school#en"];
			}
			
			//供OUP用
			this.datas.userInfo.oup = oupUser;

			return this.datas.userInfo.oup;
		});
	}


}
