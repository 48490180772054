import { ScriptService } from 'src/app/service/script.service';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { UniqueID } from 'src/app/common/UniqueID';
import { AssetLoader } from 'src/app/common/ScriptLoader';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: "colorPicker",
	template: `
		<input #colorPicker type="text"
			id="{{itemId}}" name="color-{{itemId}}"
			[(ngModel)]="selectedColor"
		>
	`,
	styleUrls:["./color-picker.component.scss"]
})

export class ColorPickerComponent implements OnChanges, AfterViewInit {
	@ViewChild('colorPicker', { static: true }) colorPicker
	
	@Input() colors: any[] = []
	@Input() showDefaultColor: boolean = true
	@Input() selectedColor: any
	@Input() isAllowClear: boolean = false
	@Output() colorChange: EventEmitter<any> = new EventEmitter()

	public itemId: string
	
	constructor(private scriptService: ScriptService, private trans: TranslateService) {
		this.itemId = `id_${UniqueID.getNextID()}`
	}

	ngAfterViewInit(): void {
		let view = this.colorPicker.nativeElement
		view.id = this.itemId
		view.addEventListener('change', this.onViewChange.bind(this))
		view.addEventListener('click', this.onClick.bind(this))
		
		let basePath: string = this.scriptService.assetsUrl
		AssetLoader.load(
			[
				{ type:"css", url: basePath + "coloris/coloris.css" }, 
				{ type:"js", url:basePath + "coloris/coloris.js", name:"Coloris" }
			]
		).then(result => {
			let c = window["Coloris"]
			let colors = this.showDefaultColor ? this.generateDefaultColors() : this.colors;

			c({
				el: '#'+this.itemId, 
				wrap: true,
				theme: 'default',
				themeMode: 'light',
				margin: 2,
				format: 'hex',
				formatToggle: false,
				alpha: false,
				swatchesOnly: false,
				focusInput: false,
				clearButton: {
					show: this.isAllowClear,
					label: this.trans.instant('commonService.clear')
				},
				swatches: colors,
			})
		})
	}

	ngOnChanges(changes: SimpleChanges): void {
		let view = this.colorPicker.nativeElement
		view.value = this.selectedColor
		view.dispatchEvent(new Event("setColor", { cancelable: true, bubbles: true}))
	}

	onViewChange(event: any):void {
		this.selectedColor = event.target.value
		this.colorChange.emit(this.selectedColor)
	}	

	onClick() {
		this.colorPicker.nativeElement.click()
	}

	onClose() {
		const c = window['Coloris'];
		if (c) {
		  c.close();
		}
	}
	
	/** generate default selected colors */
	private generateDefaultColors() {
		return [
			"#ffffff", "#F5EDDA", "#D9D9D9", "#BCC0C3", "#6B6B6B", "#000000", 
			"#FFB1DF", "#F47FC5", "#EB41AB", "#EA008A", "#AC0061", "#7E1450", 
			"#F7BABC", "#F68D91", "#F0535A", "#EB1C24", "#A51218", "#6D0B0F", 
			"#FAD8B7", "#F8C492", "#F3A75B", "#F07A26", "#AA6218", "#804509", 
			"#FCEECB", "#F7DB96", "#F3C75B", "#F0B626", "#C29011", "#9C7208", 
			"#FFFBB6", "#FFF87F", "#FFF43F", "#FFF100", "#E0D300", "#B3A900", 
			"#E8F0C2", "#DBE99E", "#C8DE6F", "#B4D334", "#96B417", "#80952E",
			"#D0EEC2", "#AFDCA3", "#86CB70", "#60BA48", "#45AD29", "#438231",
			"#C9EEDB", "#82D4A9", "#57BB86", "#34A86B", "#1E8C52", "#1F5B05",
			"#B9E5E2", "#80D2CC", "#3BB9B6", "#00A89B", "#009387", "#01766D",
			"#B4E8FC", "#80D6F7", "#3FC1F2", "#00ADEF", "#0991C6", "#0079A6",
			"#ACDAF7", "#6CBCF0", "#3F94CB", "#1F73AA", "#02558A", "#003D64",
			"#A2B2E2", "#7E92D0", "#5B74BF", "#2E50B6", "#173691", "#0B2369",
			"#BDBAE7", "#9696C8", "#6267BF", "#4245AD", "#222372", "#10114D",
			"#DABDE5", "#BA95C8", "#9762AA", "#762E91", "#522065", "#3A094D",
			"#D6A1D9", "#C77DCB", "#B65BBB", "#A842AD", "#922498", "#741579",
			"#D2B494", "#A67C52", "#8A6338", "#69401C", "#4F2B10", "#2E1402"
		]
	}
}