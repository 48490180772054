import { faClone, faText, faVolume, faCopy, faFile, faBars, faTrash, faGripDots, faImage, 
	faFilm, faListMusic , faPencil, faCheck, faAdd, faArrowLeft, faPen, faMinus, faPlus,
	faAlignLeft, faAlignCenter, faAlignRight, faAlignJustify, faPalette, faLockKeyholeOpen, 
	faLockKeyhole, faChevronLeft, faListUl, faListOl, faIndent, faOutdent, faSuperscript, faSubscript } from '@fortawesome/pro-solid-svg-icons';
import { faBold, faItalic, faUnderline, faStrikethrough } from '@fortawesome/pro-regular-svg-icons';
export class PropertyPanelIcon{
	static COMPLETE_ICON:any = faCheck;
	static DELETE_ICON:any = faTrash;
	static DUPLICATE_ICON:any = faClone;
	static PENCIL_ICON:any = faPen;
	static IMAGE_ICON:any = faImage;
	static SOUND_ICON:any = faListMusic;
	static MOVIE_ICON:any = faFilm;
	static ADD_ICON:any = faAdd;
	static BACK_ICON:any = faArrowLeft;
	static TEXT_ICON:any = faText;
	static VOLUME_ICON:any = faVolume;
	static MINUS_ICON:any = faMinus;
	static PLUS_ICON:any = faPlus;
	static BOLD_ICON:any = faBold;
	static ITALIC_ICON:any = faItalic;
	static UNDERLINE_ICON:any = faUnderline;
	static STRIKETHROUGH_ICON:any = faStrikethrough;
	static ALIGN_LEFT_ICON:any = faAlignLeft;
	static ALIGN_CENTER_ICON:any = faAlignCenter;
	static ALIGN_RIGHT_ICON:any = faAlignRight;
	static ALIGN_JUSTIFY_ICON:any = faAlignJustify;
	static PALETTE_ICON:any = faPalette;
	static LOCK_OPEN_ICON:any = faLockKeyholeOpen;
	static LOCK_CLOSE_ICON:any = faLockKeyhole;
	static CHEVRON_LEFT_ICON:any = faChevronLeft;
	static LIST_UL_ICON:any = faListUl;
	static LIST_OL_ICON:any = faListOl;
	static INDENT_ICON:any = faIndent;
	static OUTDENT_ICON:any = faOutdent;
	static SUPERSCRIPT_ICON:any = faSuperscript;
	static SUBSCRIPT_ICON:any = faSubscript;
}