import { CharacterType } from "../CharacterType";
import { ChineseNumber } from "./ChineseNumber";
import { EnglishNumberParser } from "./EnglishNumberParser";
import { NumberSplitter, NumberType } from "./NumberSplitter";

export class STTTokenizer
{
	public splitter:NumberSplitter ;
	public cn: ChineseNumber;
	public en: EnglishNumberParser;
	constructor(private lang:string)
	{
		// var cn:ChineseNumber = new ChineseNumber();
		this.splitter  = new NumberSplitter();
		this.cn = this.splitter.cNumberParser;
		this.en = this.splitter.eNumberParser;

	}
	tokenize(str:string)
	{
		var parts:any[] = this.splitter.split(str);
		var len:number = parts.length;
		var output:any [] = [];
		for (var i:number = 0; i < len; i++)
		{
			var item:string = parts[i];
			if (this.splitter.isEnglishNumber(item))
			{
				output.push(item);
			} else if (this.splitter.isNumber(item))
			{
				output.push(item);
			} else {
				this.splitText(item, output);
			}
		}
		return this.reformatOutput(output);
	}

	private reformatOutput(input:any []):any []
	{
		
		var len:number = input.length;
		var output:any[] = [];
		for (var i:number = 0; i < len; i++)
		{
			var item:string = input[i];
			var numberType:number = this.splitter.isNumber(item);
			if (numberType == NumberType.ENGLISH_NUMBER)
			{
				output.push({type:"number", text:item, number:this.en.parse(item)});
			} else if (numberType == NumberType.CHINESE_NUMBER)
			{
				output.push({type:"number", text:item, number:this.cn.toNumber(item)});
			} else if (numberType == NumberType.ROMAN_NUMBER)
			{
				output.push({type:"number", text:item, number:item});
			} else if (numberType == NumberType.CHINESE_VERSION_NUMBER)
			{
				output.push({type:"number", text:item, number:this.cn.versionToNumber(item)});
			} else if (numberType == NumberType.ROMAN_VERSION_NUMBER)
			{
				output.push({type:"number", text:item, number:item});
			} else if (CharacterType.SYMBOL.indexOf(item) != -1)
			{
				output.push({type:"symbol", text:item});
			} else if (item == " ")
			{
				output.push({type:"space", text:item});
			} else {
				output.push({type:"text", text:item});
			}
		}
		return output;
	}
	
	private splitText(text:String, output:any []):void
	{
		var includeSpace:boolean;
		//var output:Array = [];
		var lastItem:any[] = [];
		var parts:any[] = text.split('');
		var len:number = parts.length;
		//var output:Array = [];
		for (var i:number = 0; i < len; i++)
		{
			var item:string = parts[i];
			var code:number = item.charCodeAt(0);
			if (code == 0x20)
			{
				if (lastItem.length)
				{
					output.push(lastItem.join(""));
					lastItem = [];
				}
				if (includeSpace)
				{
					output.push(item);
				}
			} 
			else if (CharacterType.SYMBOL.indexOf(item) != -1)
			{
				if (lastItem.length)
				{
					output.push(lastItem.join(""));
				}
				output.push(item);
				lastItem = [];
			} 
			else 
			{
				
				if (/[0-9]/g.test(item) || code > 0xFF)
				{
					if (lastItem.length)
					{
						output.push(lastItem.join(""));
						output.push(item);
						lastItem = [];
					} else {
						output.push(item);
					}
					//trace(JSON.stringify(item), "> 0xFF");
				} else {
					lastItem.push(item);
					//trace(JSON.stringify(item), code.toString(16));
				}
			} 
		}
		if (lastItem.length)
		{
			output.push(lastItem.join(""));
		}
		
		/*
		return array.map(
			function(element:Object, index:int, reference:Array):Object
			{
				return {
					text:element,
					value:element
				}
			}
		);
		*/
		//return array;
	}
	
	
}