import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { url } from "inspector";
import { CommonService } from "src/app/service/common.service";
import { DataService } from "src/app/service/data.service";
import { ScriptService } from "src/app/service/script.service";
import { InlineSvgService } from "../../inlineSvgModule/inlineSvg.service";
import { faXmark } from "@fortawesome/pro-light-svg-icons";
import { faPeriod } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "finalReport",
	templateUrl: "./finalReport.html",
	styleUrls: ["./finalReport.scss"]
})
export class speechPracticeFinalReport implements OnChanges {
	@Input() public parent;
	@Input() public isFinalReportOpen;
	@Input() public userTotalScore;
	@Input() public totalScoreInEachQuestion: any;
	@Input() public conversationData;
	@Input() public objectiveUsed;
	@Input() public totalStarNumber;
	@Input() public aiOralfinalScore;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	// For exercise practice
	public starNumber = "0";
	public firstStatement = "";
	public secondStatement = "";
	public totalResult = 0;
	public finalResult = 0;
	public isPass;

	public courseSuccessBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_success_board.svg')`;
	public courseSuccessPeople = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_success_people.svg')`;
	public courseFailBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_fail_board.svg')`;
	public courseFailPeople = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_fail_people.svg')`;
	public courseEndBackground = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/course_end_board.svg')`;
	public yellowStar = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/star_yellow.svg')`;
	public grayStar = `url('https://oka.blob.core.windows.net/media/roWebAssets/course/aiEnglishSpeech/star_gray.svg')`;

	// For conversation practice
	public oralVocabAccuracyScore;
	public oralVocabCompletenessScore;
	public oralVocabFluencyScore;
	public oralVocabPronunciationScore;
	public faXmark = faXmark;
	public faPeriod = faPeriod;
	public isDetailShown = false;
	public isBackgroundChange = false;

	public ding_audio;
	public pass_game_audio;
	public fail_game_audio;
	public animation_stages = []
	public animation_stage = ""
	public display_level = 0;

	// For both practice
	public lang = this.dataService.lang;
	public conversationPractice = ["oralVocab", "oralSentence", "oralFillIn", "oralMc", "oralParagraph"];

	constructor(public dataService: DataService, public common: CommonService, public script: ScriptService, public svgReg: InlineSvgService) { }
	
	ngOnInit() {
		this.ding_audio = <HTMLVideoElement>document.getElementById("ding_audio");
		this.pass_game_audio = <HTMLVideoElement>document.getElementById("pass_game_audio");
		this.fail_game_audio = <HTMLVideoElement>document.getElementById("fail_game_audio");
	}
	
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.isFinalReportOpen && changes.isFinalReportOpen.currentValue) {
			if (this.parent.exerciseType.includes(this.parent.subType)) {
				this.handleExercise();
			} else {
				this.handleConversation();
			}
		}
	}

	close() {
		this.emitter.emit({ action: "close", status: false });
	}

	reTry() {
		this.emitter.emit({ action: "reTry" });
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}

	handleExercise() {
		this.totalResult = this.parent.totalPageNumber * 100;
		this.finalResult = Math.round(this.userTotalScore / this.totalResult * 100);

		if (this.finalResult >= 25) this.starNumber = "1";
		if (this.finalResult >= 50) this.starNumber = "2";
		if (this.finalResult >= 75) this.starNumber = "3";
		this.isPass = this.starNumber !== "0";

		this.firstStatement = this.isPass ? this.translateText(
			"恭喜你!",
			"恭喜你!",
			`Congratulations! You won ${this.starNumber} stars!`
		) : this.translateText(
			"未能成功通過:",
			"未能成功通过:",
			`Try to win 3 stars!`
		)
		this.secondStatement = this.isPass ? this.translateText(
			`下一節: 下一節名稱`,
			`下一节: 下一节名称`,
			`Next level: Next level`
		) : this.translateText(``, ``, ``)
	}

	handleConversation() {
		this.finalResult = this.parent.pronScore;
		this.playAnimation();
	}

	setObjective() {

	}

	playAnimation() {
		console.log(this.parent.conversationPracticeContainer.learningObjective)
		console.log(this.parent.myData.objectiveUsed[0])
		this.isBackgroundChange = true;
		this.display_level = this.totalStarNumber;
		
		if (this.finalResult >= 25) {
			this.pass_game_audio.pause()
			this.pass_game_audio.currentTime = 0
			this.pass_game_audio.play()

			this.animation_stages = ['start', 'repel', 'after_repel', "show_level"]
			this.animation_stages.push("add1_start", "add1_end")
			if (this.finalResult >= 50) this.animation_stages.push("add2_start", "add2_end")
			if (this.finalResult >= 75) this.animation_stages.push("add3_start", "add3_end")
			this.animation_stages.push("end")

			let animation_idx = 0
			this.animation_stage = this.animation_stages[animation_idx] //0
			animation_idx++

			let isiPad = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
				.includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
			let isSafari = this.dataService.device.browser === "Safari"
			
			let continue_animation = (idx, stages) => {
				let timeout = 400
				this.animation_stage = stages[idx]
				if (['add1_start', 'add2_start', 'add3_start'].indexOf(this.animation_stage) != -1) {
					this.display_level++
					timeout = 150
					if (isiPad || isSafari) {
						let star
						switch (this.animation_stage) {
							case 'add1_start':
								star = document.getElementById("first_star")
								break;
							case 'add2_start':
								star = document.getElementById("second_star")
								break;
							case 'add3_start':
								star = document.getElementById("third_star")
								break;
						}
						star.style.width = '108px';
						let star_number = document.getElementById("star_number")
						star_number.style.top = '-10px'
						setTimeout(() => {
							this.ding_audio.currentTime = 0
							this.ding_audio.play()
						}, 25);
					} else {
						this.ding_audio.pause()
						this.ding_audio.currentTime = 0
						this.ding_audio.play()
					}
				}
				if (['add1_end', 'add2_end', "add3_end"].indexOf(this.animation_stage) != -1) {
					if (isiPad || isSafari) {
						setTimeout(() => {
							let star
							switch (this.animation_stage) {
								case 'add1_end':
									star = document.getElementById("first_star")
									break;
								case 'add2_end':
									star = document.getElementById("second_star")
									break;
								case 'add3_end':
									star = document.getElementById("third_star")
									break;
							}
							star.style.width = '78px';
							let star_number = document.getElementById("star_number")
							star_number.style.top = '0px'
						}, 130);
						timeout = 200
					}
				}

				if (stages[idx] != 'end') {
					setTimeout(() => {
						continue_animation(idx + 1, stages)
					}, timeout);
				}
			}
			setTimeout(() => {
				this.animation_stage = this.animation_stages[animation_idx] //1
				animation_idx++
				setTimeout(() => {
					this.animation_stage = this.animation_stages[animation_idx] //2
					animation_idx++
					setTimeout(() => {
						this.animation_stage = this.animation_stages[animation_idx] //3
						animation_idx++
						setTimeout(() => {
							continue_animation(animation_idx, this.animation_stages)
						}, 100);
					}, 200);
				}, 300)
			}, 1);
		} else {
			this.fail_game_audio.pause()
			this.fail_game_audio.currentTime = 0
			this.fail_game_audio.play()
			let animation_idx = 0
			this.animation_stages = ['start', 'jump', 'end']

			this.animation_stage = this.animation_stages[animation_idx]
			animation_idx++
			setTimeout(() => {
				this.animation_stage = this.animation_stages[animation_idx]
				animation_idx++
				setTimeout(() => {
					this.animation_stage = this.animation_stages[animation_idx]
					animation_idx++
				}, 200);
			}, 100);

		}
	}
}