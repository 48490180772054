import { Component, EventEmitter, Input, Output } from "@angular/core";
import { DataService } from "src/app/service/data.service";

@Component({
	selector: "instruction",
	templateUrl: "./instruction.html",
	styleUrls: ["./instruction.scss"]
})
export class speechPracticeInstruction {
	@Input() public bookTitle;
	@Input() public subType;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	public lang = this.dataService.lang;

	public instruction = [
		{ value: "word", label: "Listen to the vocabulary, read along, and then press the record button to record." },
		{ value: "sentence", label: "Listen to the sentence, read along, and then press the record button to record." },
		{
			value: "fillIn", label: [
				"Listen to the sentence, and then press the record button to record the word within.",
				"Press the record button to record the word within the box."
			]
		},
		{ value: "mc", label: "Choose the correct answer, and then press the record button to record." },
		{ value: "paragraph", label: "Listen to the paragraph, read along, and then press the record button to record." },
		{ value: "aiOral", label: "Discuss with AI by pressing the record button to record your voice."},
		{ value: "oralVocab", label: "Discuss with AI by pressing the record button to record your voice."}
	]

	constructor(public dataService: DataService) {}

	ngOnInit() {}

	close() {
		this.emitter.emit({action: "exercisePracticeInstructionClose", status: false});
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}
}