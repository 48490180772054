import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import { environment } from '../../../environments/environment';
import { TagGroupService } from 'src/app/sharedModule/tagGroupModule/tagGroup.service';
import { forkJoin, from } from 'rxjs';
@Injectable()
export class LessonResolver implements Resolve<any> {
	constructor(private datas: DataService, private trans: TranslateService, private router: Router, private route: ActivatedRoute, private tg: TagGroupService) {
	}

	resolve(aRoute: ActivatedRouteSnapshot) {
		this.trans.use(this.datas.lang);
		const fetchSchoolYear = from(this.tg.loadSchoolYear());
		return forkJoin([this.trans.get('ready'), fetchSchoolYear]);
	}


}
