import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PropertyScoreInputComponent } from './propertyScoreInput.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
	  FormsModule
    ],
    declarations: [
        PropertyScoreInputComponent
    ],
    exports: [
        PropertyScoreInputComponent
    ],
})
export class PropertyScoreInputModule { }
