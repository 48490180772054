import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoadingModule } from 'src/app/sharedModule/loadingModule/loading.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MyDatePipe } from './my-date.pipe';
import { OkaPulldownModule } from 'src/app/sharedModule/okaPulldownModule/okaPulldown.module';
import { Modal2Module } from 'src/app/sharedModule/modal2Module/modal2.module';
import { TargetSelectorModule } from 'src/app/sharedModule/targetSelectorModule/targetSelector.module';
import { InlineSvgModule } from 'src/app/sharedModule/inlineSvgModule/inlineSvg.module';
import { PopupToggleModule } from 'src/app/sharedModule/popupToggleModule/popupToggle.module';
import { SubjectSelector3Module } from 'src/app/sharedModule/subjectSelector3Module/subjectSelector3.module';
import { UploadModule } from 'src/app/sharedModule/uploadModule/upload.module';
import { NumberInputModule } from 'src/app/sharedModule//numberInputModule/numberInput.module';
import { DateTimePicker3Module } from 'src/app/sharedModule//dateTimePicker3Module/dateTimePicker3.module';
import { BubbleBox2Module } from 'src/app/sharedModule//bubbleBox2Module/bubbleBox2.module';
import { AdditionRemarkBallModule } from 'src/app/sharedModule/additionRemarkBallModule/additionRemarkBall.module';
import { CreateGameModal } from './create-game.modal';
import { QuestionAggrid } from './question.aggrid';
import { AgGridModule } from 'ag-grid-angular';
import { CustomAgGridModule } from 'src/app/sharedModule/customAgGridModule/customAgGrid.module';
import { SubjectModule } from 'src/app/sharedModule/subjectModule/subject.module';
import { ColorPickerModule } from '../../colorPickerModule/color-picker.module';

@NgModule({
    imports: [
		CommonModule, TranslateModule, FormsModule,
		UiSwitchModule, LoadingModule, OkaPulldownModule,
		FontAwesomeModule, Modal2Module,
		TargetSelectorModule, InlineSvgModule, PopupToggleModule, SubjectSelector3Module,
		UploadModule, NumberInputModule, DateTimePicker3Module, BubbleBox2Module, CustomAgGridModule,
		AdditionRemarkBallModule, AgGridModule, SubjectSelector3Module, SubjectModule, ColorPickerModule, NumberInputModule
	],
    declarations: [
		CreateGameModal, QuestionAggrid, MyDatePipe
    ],
    exports: [
		CreateGameModal
    ],
    providers: [],
})
export class CreateGameModule {
}
