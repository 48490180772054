// import { XMLParser } from "fast-xml-parser";
import { XMLNode } from "./xml/XMLNode";
import { XMLJSParser, XMLNodeXMLParser } from "./xml/XMLParser";
// 1. PinYin 文字 - 只做顯示
// 2. 聲音播放器
// 3. 錄音 - 
// 4. 填充 - Solomon
// 5. 剔選題 - 
// 6. 語音Speech Regcon
// 1 月 30

export interface NodeCreator{
	createTextFlowDOM(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement;
}

/*
class TextFlowNodeCreator implements NodeCreator
{
	createTextFlowDOM(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement
	{
		return null;
	}
}
*/

export class TextFlowUtils
{
    private parser: XMLNodeXMLParser;
	private nodeCreatorMap:any = {};
    constructor()
    {
	    // this.parser = new FastXMLParser();
		this.parser = new XMLJSParser();
			
		this.nodeCreatorMap = {};
    }
	public mapFont(font:string):string
	{
		if(font == "Noto Sans T Chinese Regular")
		{
			// dom.style.fontFamily = "Microsoft Yahei, Microsoft JhengHei";
			return "'Noto Sans TC',sans-serif !important";
		} else if(font == "DFHKStdKai-B5")
		{
			// dom.style.fontFamily = "KaiTi";
			// return '"cwTeXKai", serif';
			return "DFHKStdKai";
		} else if(font == "DejaVu Sans YuanTi")
		{
			return '"cwTeXYen", sans-serif';
			// dom.style.fontFamily = "YouYuan, Yuanti SC, Yuanti SC";
		}
		return null;
	}
    public textFlowToDOM(textFlow:string, withBG:boolean):HTMLElement
	{
		textFlow = textFlow.replace('<TextFlow ', '<TextFlow xml:space="preserve" ')
		var textFlowNode:XMLNode = this.parser.parse(textFlow);
		return this.createTextFlowDOM(textFlowNode, null, textFlowNode, withBG);
	}

	public textFlowNodeToDOM(textFlowNode:XMLNode, withBG:boolean):HTMLElement
	{
		if(textFlowNode.tag == "TextFlow")
		{
			return this.createTextFlowDOM(textFlowNode, null, textFlowNode, withBG);
		} else {
			return this.textFlowToDOM(textFlowNode.text, withBG);
		}
		
	}

	public domToTextFlow(html:HTMLElement):string {
		/*
		<TextFlow fontFamily="DFHKStdKai-B5" fontSize="32" lineBreak="toFit" whiteSpaceCollapse="preserve" version="3.0.0" 
		xmlns="http://ns.adobe.com/textLayout/2008"><p><span>一般文字</span></p></TextFlow>

		<div class="textflow DFHKStdKai-B5" style="font-family: DFHKStdKai; --textflow-fontsize: 32px;">
			<pre>
				<p class="p"><span><dom class="t"></dom><dom class="b"></dom>一般</span></p>
				<p class="p"><span>abcd文字</span></p>
			</pre>
		</div>
		*/
		var str:string = html.outerHTML.replace(/<br>/gi, '<br/>');
		// console.log('===== DtoT START str');
		// console.log('str', this.parser.parse(str));
		// console.log('===== DtoT END str');
		return this.domNodeToTextFlow(this.parser.parse(str));
	}

	protected domNodeToTextFlow(domNode:XMLNode):string {
		if(domNode.hasOwnProperty('tag') && domNode.tag) {
			// 處理子項先
			var child:string = "";
			domNode.children.forEach(e => {
				child+=this.domNodeToTextFlow(e);
			});

			var classes:any[];
			var style:any[];
			// 處理自己的屬性
			if(domNode.attributes.hasOwnProperty("class"))
				classes = domNode.attributes.class.split(" ");
			if(domNode.attributes.hasOwnProperty("style")) {
				style = domNode.attributes.style.split("; ");
				style = style.map(e => e.replace(";", ""));
				style = style.map(e=> {
					return e.split(": ");
				});
			}

			// 處理自己的 tag
			if(domNode.tag=="div" && domNode.children.length>0 && classes && classes.indexOf("textflow")!=-1) {
				// TLF root
				var str:string = '<TextFlow version="3.0.0" xmlns="http://ns.adobe.com/textLayout/2008" lineBreak="toFit"';
				style.forEach(e=> {
					var attr:string = e[0];
					// if(attr == "font-family") {
					// 	if(e[1] == "DFHKStdKai")
					// 		str+=' fontFamily="DFHKStdKai-B5"';

					// } else 
					if(attr == "--textflow-fontsize") {
						str+=' fontSize="'+parseInt(e[1])+'"';
					} else {
						str+=' '+e[0].replace(/-./g, match => match.charAt(1).toUpperCase())+'="'+e[1]+'"';
					}
				});
				if(domNode.children.length>0 && domNode.children[0].tag == "pre") {
					// str+=' whiteSpaceCollapse="preserve"';
					if (style !== undefined) {
						style.forEach(e=> {
							str+=' '+e[0].replace(/-./g, match => match.charAt(1).toUpperCase())+'="'+e[1]+'"';
						});
					}
				}
				return str+'>'+child+'</TextFlow>';

			} else if(domNode.tag=="p") {
				// console.log('===== domNode.tag=="p" START');
				// console.log('domNode.children.length', domNode.children.length);
				// console.log('domNode.children[0].hasOwnProperty("tag")', domNode.children[0].hasOwnProperty("tag"));
				// console.log('domNode.children[0].tag', domNode.children[0]);
				// console.log('domNode.children[0].tag', domNode.children[0].tag);
				// console.log('===== domNode.tag=="p" END');
				if(domNode.children.length> 0 && (!domNode.children[0].hasOwnProperty('tag') || domNode.children[0].tag!="span")) {
					// 補 span ，唔係 text flow 會死
					// return '<p><span>'+child+'</span></p>';
					return '<p>'+child+'</p>';
				}
				var str:string = '<p';
				if (style !== undefined) {
					style.forEach(e=> {
						str+=' '+e[0].replace(/-./g, match => match.charAt(1).toUpperCase())+'="'+e[1]+'"';
					});
				}
				return str+'>'+child+'</p>';

			} else if(domNode.tag=="span") {
				// if(child=="<br/>" || child=="")
				// if(child=="<br/>")
					// return '<span/>';
					// return '<br/>';
					// return '<span class="t"></span><span class="b"></span><span></span>';
					// return '<span></span>';
					// return '';
				var str:string = '<span';
				if (style !== undefined) {
					style.forEach(e=> {
						if(e[0] == "--textflow-fontsize") {
							// str+=' fontSize="'+parseInt(e[1])+'"';
						} else {
							str+=' '+e[0].replace(/-./g, match => match.charAt(1).toUpperCase())+'="'+e[1]+'"';
						}
					});
				}
				return str+'>'+child+'</span>';

			} else if(domNode.tag=="br") {
				console.log('===== domNode.tag=="br" START');
				console.log('domNode.tag', domNode);
				console.log('===== domNode.tag=="br" END');
				return '<br/>';
			}

			// dom / pre (skip)
			return child;
		}

		return domNode.text;
	}
	
	
	public replaceHandler(tag:string, nodeCreator:NodeCreator):void
	{
		this.nodeCreatorMap[tag] = nodeCreator;
	}
	private encodeHtmlEntities(input:string):string
	{
		if(input)
		{
			return input.replace(/./gm, function(s) {
				// return "&#" + s.charCodeAt(0) + ";";
				return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
			});
		}
		
		return "";
	}

	createTextFlowDOM(rootNode:XMLNode, listElement:any, node:XMLNode, withBG:boolean):HTMLElement
	{
		// console.log('===== TtoD START textFlowNode');
		// console.log('textFlowNode', rootNode);
		// console.log('===== TtoD END textFlowNode');
		var dom:HTMLElement
		if(this.nodeCreatorMap.hasOwnProperty(node.tag))
		{
			var nodeCreator:NodeCreator = this.nodeCreatorMap[node.tag];
			dom = nodeCreator.createTextFlowDOM(rootNode, listElement, node, withBG);
		} else if(node.tag == "TextFlow")
		{
			dom = document.createElement("div");
			dom.classList.add("textflow");
			var pre = document.createElement("pre");
			dom.appendChild(pre);
			node.children.forEach((childNode:XMLNode)=>{
				var childDOM:HTMLElement = this.createTextFlowDOM(rootNode, listElement, childNode, withBG);
                if(childDOM){
                    pre.appendChild(childDOM);
                }
			})
		} else if( node.tag == "p" || node.tag == "list" || node.tag == "li" || node.tag == "a")
		{
			if(node.tag == "a")
			{
				dom = document.createElement("a");
			} else if(node.tag == "p")
            {
				// console.log('===== TtoD START node.tag == "p"');
				// console.log('node', node);
				// console.log('===== TtoD END node.tag == "p"');
                dom = document.createElement(node.tag);
            } else if(node.tag == "list")
            {
			    dom = document.createElement("ol");
                var listStyleType:string = node.getAttribute("listStyleType");
                if(listStyleType)
                {
					dom.classList.add("list-style-type-"+listStyleType);
                }
                var listStyleName:string = node.getAttribute("styleName");
                if(listStyleName)
                {
					if(listStyleName.indexOf("i.") == 0) dom.classList.add("style-name-i");
				    dom.classList.add("style-name-"+listStyleName.replace(".", "-"));
                }
				listElement = {
					dom:dom,
					node:node
				}
            } else if(node.tag == "li")
            {
                dom = document.createElement("li");
            }
            dom.classList.add(node.tag);
			node.children.forEach((childNode:XMLNode)=>{
				var childDOM:HTMLElement = this.createTextFlowDOM(rootNode, listElement, childNode, withBG);
				if(childDOM) dom.appendChild(childDOM);
			});
        } else if(node.tag == "listMarkerFormat")	
        {
           var num:string = node.children[0].getAttribute("counterReset")
			listElement.dom.start = parseInt(num.replace(/[A-z]/g, ""));
		} else if(node.tag == "span")	
		{
			// console.log('===== TtoD START node.tag == "span"');
			// console.log('node', node);
			// console.log('===== TtoD END node.tag == "span"');
			dom = document.createElement("span");

			var topDOM:HTMLElement = document.createElement("dom");
			topDOM.classList.add("t");
			dom.appendChild(topDOM);
			var bottomDOM:HTMLElement = document.createElement("dom");
			bottomDOM.classList.add("b");
			dom.appendChild(bottomDOM);
			

			if(node.children.length)
			{
				// console.log('===== TtoD START node.children.length');
				// console.log('node.children.length', node.children);
				// console.log('===== TtoD END node.children.length');
				node.children.forEach((childNode:XMLNode)=>{
					if(childNode.tag == "br")
					{
						dom.appendChild(document.createElement("br"));
					} else if(childNode.tag == "tab")
					{
						// dom.appendChild(document.createTextNode("\t"));
						var span = document.createElement("span");
						span.style.fontSize = "0px";
						span.innerText = "\t";
						dom.appendChild(span);
					} else {
						dom.appendChild(document.createTextNode(childNode.text));
					}
				});
			} else {
				// console.log('===== TtoD START else');
				// console.log('node.children.length', node.children);
				// console.log('===== TtoD END else');
				// dom.innerText = " ";
				var span = document.createElement("span");
				span.style.fontSize = "0px";
				span.innerText = " ";
				dom.appendChild(span);
				
			}
		
        } else {
			dom = document.createElement("div");
			dom.innerText = node.tag +"/ not support";
		}
		this.assignFormat(dom, node, withBG);
		return dom;
	}
	private assignFormat(dom:HTMLElement, node:XMLNode, withBG:boolean):void
	{
		if(!dom || !dom.style) return;
		var attributes:any = node.attributes;
		var value:any;
		// dom.style.margin = "0px";
		// dom.style.lineHeight="100%";
		for(var key in attributes)
		{
			// var consolelogIgnoreKeys:string[] = ["xml:space", "version", "xmlns","lineHeight", "fontSize", "textDecoration"];
			// var consolelogKeys:string[] = ["fontFamily"];
			// // if (!consolelogIgnoreKeys.includes(key))
			// if (consolelogKeys.includes(key))
			// {
			// 	console.log('===== START assignFormat key')
			// 	console.log(key, attributes[key], dom, node);
			// 	console.log('===== END assignFormat key')
			// }
			value = attributes[key];
			var ignoreKeys:string[] = ["xml:space", "version", "xmlns"];
			if(ignoreKeys.includes(key))
			{
				continue;
			}
			if(key == "lineHeight")
			{
				if(/^[0-9]{1}[0-9]{0,}%$/.test(value))
				{
					// var spanLineHeight = `${value}`;
					dom.style.lineHeight = value;
					// dom.style.setProperty("--span-line-height", value);
					// dom.style.setProperty("--extra-line-height", ((parseFloat(value)-100)/100).toString());
				}  else  {
					// not support
				}
				
			} else if(key == "textAlign")
			{
				dom.style.textAlign = value;// text-align: right;
			} else if(key == "fontFamily")
			{
				// var targetFont:string = this.mapFont(value);
				// if(targetFont)
				// {
				// 	dom.style.fontFamily = targetFont;
				// }
				dom.style.fontFamily = value;
				dom.classList.add(value.replace(/[ ]/g, "-"));
				/*
				if(value == "Noto Sans T Chinese Regular")
				{
					// dom.style.fontFamily = "Microsoft Yahei, Microsoft JhengHei";
					dom.style.fontFamily = "'Noto Sans TC',sans-serif !important";
				} else if(value == "DFHKStdKai-B5")
				{
					// dom.style.fontFamily = "KaiTi";
					dom.style.fontFamily = '"cwTeXKai", serif';
				} else if(value == "DejaVu Sans YuanTi")
				{
					dom.style.fontFamily = '"cwTeXYen", sans-serif';
					// dom.style.fontFamily = "YouYuan, Yuanti SC, Yuanti SC";
				}
				*/
			
			} else if(key == "color")
			{
				dom.style.color = value;
			} else if(key == "backgroundColor")
			{
				if(withBG){
					dom.style.backgroundColor = value;
				}
			} else if(key == "fontSize")
			{
				// var fontSize:string = value + "px";
				// dom.style.setProperty("--textflow-fontsize", fontSize);
				// dom.style.fontSize = value + "px";
				dom.style.fontSize = value;
			} else if(key == "baselineShift")
			{
				if(value == "superscript")
				{
					var dom2:HTMLElement = document.createElement("div");
					dom2.innerText = dom.innerText;
					dom.innerText = "";
					dom.appendChild(dom2);
					dom.classList.add("super");					
				} else if(value == "subscript")
				{
					var dom2:HTMLElement = document.createElement("div");
					dom2.innerText = dom.innerText;
					dom.innerText = "";
					dom.appendChild(dom2);
					dom.classList.add("sub");
				}
			} else if(key == "textDecoration")
			{
				if (value.includes("underline")) dom.style.textDecoration += " underline";
				if (value.includes("line-through")) dom.style.textDecoration += " line-through";
			} else if(key == "fontWeight")
			{
				if(value == "bold") dom.style.fontWeight = "bold";

			} else if(key == "fontStyle")
			{
				if(value == "italic") dom.style.fontStyle = "italic";
			} else 
			{
				dom.style[key] = value;
			}
		}
	}
}