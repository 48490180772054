import { Component, Input, OnInit } from '@angular/core';

@Component({
	// standalone: true,
	selector: 'yellowStarSVG',
	templateUrl:'./yellow_star.component.html',
	styleUrls: ['./yellow_star.component.scss']
})
export class YellowStarSvgComponent implements OnInit{
	
	@Input() class = '';
	ngOnInit(): void { }

	// fillColor = 'rgb(255, 0, 0)';

	// changeColor() {
	// 	const r = Math.floor(Math.random() * 256);
	// 	const g = Math.floor(Math.random() * 256);
	// 	const b = Math.floor(Math.random() * 256);
	// 	this.fillColor = `rgb(${r}, ${g}, ${b})`;
	// }
}