import {Component, Input, OnInit, ViewChild, Output, EventEmitter, ElementRef} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../service/data.service";
import {Modal2Component} from "../modal2Module/modal2.component";
import {WebLessonService} from "../../coreModule/lessonModule/WebLessonModule/services/WebLesson.service";
import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {ThemeService} from "../../service/theme.service";

@Component({
	selector: 'toolbar-setting-modal',
	template: `
		<modal2
			#modal [title]="trans.instant('bookviewer.toolbar_setting.title')"
			(cancelClick)="confirm()"
			(confirmClick)="confirm()"
			[width]="'calc(100% - 40px)'"
			[height]="'100%'"
			maxHeight="calc(100dvh - 40px)"
			[ngClass]="current_tab_key"
			[class.dark]="theme.themeSelected === 'dark'"
		>
			<div class="tab-container" *ngIf="web_lesson.is_teacher">
				<tab-view
					[tabs]="tabs"
					[current_key]="current_tab_key"
					(tab_changed)="current_tab_key = $event.key"
					[ngClass]="current_tab_key"
				></tab-view>
			</div>
			<div class="tab-content">
				<ng-container *ngIf="current_tab_key === 'teacher'">
					<div class="tools">
						<ng-container *ngFor="let tool of all_teacher_toolbar_tools">
							<ng-container *ngTemplateOutlet="tool_item; context: {tool: tool, tools: teacher_toolbar_tools}"></ng-container>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="current_tab_key === 'student'">
					<div class="tools">
						<ng-container *ngFor="let tool of all_student_toolbar_tools">
							<ng-container *ngTemplateOutlet="tool_item; context: {tool: tool, tools: student_toolbar_tools}"></ng-container>
						</ng-container>
					</div>
				</ng-container>
				<ng-container *ngIf="current_tab_key === 'settings'">
					<table class="form">
						<tr *ngIf="web_lesson.lesson_id && web_lesson.is_teacher">
							<th>
								{{ 'bookviewer.toolbar_setting.page_sync_method' | translate }}
							</th>
							<td>
								<radioBox
									[(value)]="web_lesson.lesson_state.page_sync_method"
									[options]="page_sync_method_options"
									(valueChange)="web_lesson.update_lesson_state();"
								></radioBox>
							</td>
						</tr>
						<tr>
							<th>
								{{ 'bookviewer.toolbar_setting.ui_size' | translate }}
							</th>
							<td>
								<radioBox
									[value]="ui_size"
									(valueChange)="ui_sizeChange.emit($event)"
									[options]="ui_size_options"
								></radioBox>
							</td>
						</tr>
					</table>
				</ng-container>
			</div>
		</modal2>
		
		<ng-template #tool_item let-tool="tool" let-tools="tools">
			<div class="tool"
				 [class.active]="tools.includes(tool)"
				 [ngClass]="['app-' + tool]"
				 (click)="toggle_tool(tools, tool)"
				 [ngStyle]="get_tool_style(tool)"
			>
				<div class="selected-check">
					<fa-icon [icon]="faCheck" *ngIf="tools.includes(tool)"></fa-icon>
				</div>
				<div class="tool-name">{{ ('bookviewer.tools.' + tool) | translate }}</div>
			</div>
		</ng-template>
	`,
	styles: [`
        :host ::ng-deep {
            modal2 {
                --popupform-allmargin: 0 !important;
                z-index: 100000 !important;

                .modal {
                    overflow: hidden;
                    max-height: calc(100dvh - 40px);
                    display: flex;
                    flex-direction: column;
					max-width: 1320px;

                    .header {
                        border-bottom: none !important;
                    }

                    .content {
                        flex: 1;
                        overflow-y: auto;
                        display: flex;
                        flex-direction: column;
                    }
                }

                &.teacher {
                    --section-maincolor: #388074;
                    --section-popupbgcolor: var(--popupframe-headerbgcolor);
                    --tab-content-color: #ffffff;
                    --tab-content-bg-color: #388074;
                }

                &.student {
                    --section-maincolor: #3A7691;
                    --section-popupbgcolor: var(--popupframe-headerbgcolor);
                    --tab-content-color: #ffffff;
                    --tab-content-bg-color: #3A7691;
                }

                &.settings {
                    --section-maincolor: #666666;
                    --section-popupbgcolor: var(--popupframe-headerbgcolor);
                    --tab-content-color: #888888;
                    --tab-content-bg-color: #C4C2C2;
                }

                &.dark {
                    --popupframe-titlecolor: #FFFFFF !important;
                    --popupframe-headerbgcolor: #1E1E29 !important;
                    --tab-content-bg-color: #2D2C43 !important;
                }
            }

            tab-view {
                &.teacher {
                    --active-bg-color: #388074;
                }

                &.student {
                    --active-bg-color: #3a7691;
                }

                &.settings {
                    --active-bg-color: #666666;
                }
            }
        }

        .tab-container {
            display: flex;
            justify-content: center;
            padding: 0 8px;
        }

        .tab-content {
            background: var(--tab-content-bg-color);
            padding: 20px;
            border-top: 1px solid var(--popupframe-linecolor, #0000001A)
            min-height: 140px;
			flex: 1;
        }

        .tools {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
        }

        .tool {
            width: 100px;
            height: 100px;
            cursor: pointer;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            overflow: hidden;
            padding: 5px 5px 9px 5px;
            background-size: 60px 60px;
            background-position: center 16px;
            background-repeat: no-repeat;

            &.app-open-book {
                background-size: 60px auto;
                /*background-position-y: 26px;*/
            }
			
			&.app-manage-student-grouping {
				background-size: 30px auto;
                background-position-y: 24px;
			}
        }

        .selected-check {
            display: flex;
            justify-content: flex-end;
            width: 100%;
        }

        .tool-name {
            white-space: nowrap;
            max-width: 100%;
            overflow: hidden;
        }

        .tool.active {
            background-color: rgba(0, 0, 0, 0.2);
        }

        table.form {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            border-radius: 10px;
            overflow: hidden;

            th, td {
                height: 46px;
                text-align: left;
                font-weight: 400;
                font-size: 15px;
                padding: 0 10px;
            }

            th {
                background: var(--popupform-panelbgcolor);
                color: var(--popupform-paneltextcolor);
                min-width: 160px;
            }

            td {
                background: var(--popupform-rowbgcolor);
                color: var(--common-textcolor);
                width: 100%;
            }

        }
	`]
})

export class ToolbarSettingModalComponent implements OnInit {
	public tabs = [];
	public current_tab_key = 'teacher';
	public page_sync_method_options = [];
	public ui_size_options = [];

	@Input() all_teacher_toolbar_tools = [];
	@Input() all_student_toolbar_tools = [];
	@Input() teacher_toolbar_tools = [];
	@Input() student_toolbar_tools = [];
	@Input() ui_size = 1;
	@Output() ui_sizeChange = new EventEmitter();

	@ViewChild('modal', {static: false}) modal: Modal2Component;

	constructor(
		public trans: TranslateService,
		public datas: DataService,
		public web_lesson: WebLessonService,
		public theme: ThemeService,
		public eleRef: ElementRef,
	) {
		this.trans.use(this.datas.lang).subscribe(() => {
			this.init_tabs();
			this.init_options();
		});
	}

	init_tabs() {
		this.tabs = [];
		let old_tab_key = this.current_tab_key;

		if (this.all_teacher_toolbar_tools.length) {
			this.tabs = [
				...this.tabs,
				{
					key: 'teacher',
					title: this.trans.instant('bookviewer.toolbar_setting.teacher'),
				},
			]
		}
		if (this.all_student_toolbar_tools.length) {
			this.tabs = [
				...this.tabs,
				{
					key: 'student',
					title: this.trans.instant('bookviewer.toolbar_setting.student'),
				},
			]
		}
		this.tabs = [
			...this.tabs,
			{
				key: 'settings',
				title: this.trans.instant('bookviewer.toolbar_setting.settings'),
			}
		];

		let current_tab = this.tabs.find(tab => old_tab_key && tab.key === old_tab_key);
		if (!current_tab) {
			this.current_tab_key = this.tabs[0].key;
		}
	}

	ngOnInit() {
		this.theme.reloadSetting();
		this.theme.applyStyleObj({
			"default": {
				"common-textcolor": "#333333",
				"common-textcolor2": "#666666",
				'section-maincolor-20pt': 'rgba(106, 36, 59)',
				'section-maincolor-opacity': 'rgba(161, 46, 81,0.1)',
				"section-popupbgcolor": "#F6EBDE",
				"section-popupbgcolor2": "#fff",
				"section-titlecolor": "#333",
				"pulldown-textcolor": "var(--common-textcolor)",
				"pulldown-trianglecolor": "#666666",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-labelcolor": "var(--common-textcolor)",
				"pulldown-bottomcolor": "#d0d0d0",
				"popupform-textcolor": "var(--section-maincolor)",
				"popupform-bgcolor": "#f1f1f7",
				"popupform-subtitlesize": "15px",
				"popupform-subtitleweight": "bold",
				"popupform-subtitlecolor": "#333333",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"popupframe-headerbgcolor": "#FFFFFF",
				"popupframe-titlesize": "20px",
				"popupframe-titlecolor": "#000000",
				"header-titlecolor": "#000",
				"color333": "#333",
				"color-333-fff": "#333",
				"color000": "#000",
				"aggrid-headerbgcolor": "#EDEDED",
				"aggrid-linecolor": "#E2E2E2",
				"aggrid-bordercolor": "#CCCCCC",
				"aggrid-headertextcolor": "#666666",
				"aggrid-textcolor": "#666",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"credit-board-bg": "#f1f1f7",
				"credit-color": "var(--section-maincolor)",
				"popupform-panelbgcolor": "#F5F5F5",
				"popupform-paneltextcolor": "#333333",
				"popupform-rowbgcolor": "#FFFFFF",
				"popuptoggle-textsize": "15px",
				"popuptoggle-textweight": "bold",
				"popuptoggle-buttonselectettextcolor": "var(--section-maincolor)",
				"popuptoggle-buttonnormaltextcolor": "#999999",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"alert-cancelbuttoncolor": "#BEBEBE",
				"text-color": "#FFFFFF",
				"alert-bgcolor": "#FFFFFF",
				"popup-head-bg": "#FFFFFF",
				"popup-text": "#000000",
				"book-icon-color": "#CCCCCC",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-1F8492-FFFFFF": "#1F8492",
				"color-d7e6e8-23323b": "#d7e6e8",
				"color-999999-FFFFFF": "#999999",
				"color-CCCCCC-666666": "#CCCCCC",
				"color-eee-1e1e29": "#eee",
				"aggrid-filterinputbgcolor-disabled": "rgba(255,255,255,0.5)",
				"aggrid-filterinputbordercolor-disabled": "rgba(204,204,204,0.4)",
				"alert-okbuttoncolor": "var(--section-maincolor)",
				"pulldown-selectedbgcolor": "var(--section-maincolor)",
				"pulldown-selectedtextcolor": "#FFFFFF",
				"pulldown-overbgcolor": "#E2E2E2",
				"pulldown-shadow": "0px 5px 30px rgba(0,0,0,0.3)",
				'DDCAB4-232334': "#DDCAB4",
				"color-CCCCCC-53536A": "#CCCCCC",
				"color-FFFFFF-1E1E29": "#FFFFFF",
				"color-F0F0F0-2D2C43": "#F0F0F0",
				"color-FFFFFF-2D2C43": "#FFFFFF",
				"color-E6E6E6-2D2C43": "#E6E6E6",
				"color-EDEDED-181821": "#EDEDED",
				"color-secmain-FFFFFF": "var(--section-maincolor)",
				"color-F5F5F5-35344d": "#F5F5F5",
				"color-E4CBD3-53536A": "#E4CBD3",
				"color-E7D0D7-232334": "#E7D0D7",
				"color-FFFFFF-232334": "#FFFFFF",
				"color-F4F4F4-2D2C43": "#F4F4F4",
				"color-CCCCCC-38374F": "#CCCCCC",
				"color-EBEBEB-2D2C43": "#EBEBEB",
				"color-181821-EDEDED": "#181821",
				"color-E0E0E0-5B5B5B": "#E0E0E0",
				"color-0000001A-FFFFFF1A": "#0000001A",
				"color-DF3354-FFFFFF": "#DF3354",
				"list-headerfontsize": "15px",
				"list-headerfontcolor": "#666666",
				"tickbox-selectedcolor": "var(--section-maincolor)",
				"tickbox-unselectecolor": "var(--common-textcolor)",
				"popupform-textmargin": "10px"
			},
			"dark": {
				"common-textcolor": "#FFFFFF",
				"common-textcolor2": "#999999",
				'section-maincolor-20pt': 'rgba(106, 36, 59)',
				'section-maincolor-opacity': 'rgba(161, 46, 81,0.1)',
				"section-popupbgcolor": "#2D2C43",
				"section-popupbgcolor2": "#2D2C43",
				"section-titlecolor": "#fff",
				"pulldown-textcolor": "var(--common-textcolor)",
				"pulldown-trianglecolor": "#FFFFFF",
				"pulldown-bgcolor": "#38374f",
				"pulldown-bottomcolor": "#000",
				"popupform-textcolor": "#fff",
				"popupform-bgcolor": "#f1f1f7",
				"popupform-subtitlesize": "15px",
				"popupform-subtitleweight": "bold",
				"popupform-subtitlecolor": "#A0A0A0",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"popupframe-headerbgcolor": "#1E1E29",
				"popupframe-titlesize": "20px",
				"popupframe-titlecolor": "#FFFFFF",
				"header-titlecolor": "#fff",
				"color333": "#999",
				"color-333-fff": "#fff",
				"color000": "#fff",
				"aggrid-headerbgcolor": "#181821",
				"aggrid-linecolor": "#53536A",
				"aggrid-bordercolor": "#53536A",
				"aggrid-headertextcolor": "#A0A0A0",
				"aggrid-textcolor": "#A0A0A0",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"credit-board-bg": "#26253a",
				"credit-color": "#fff",
				"popupform-panelbgcolor": "#38374F",
				"popupform-paneltextcolor": "#FFFFFF",
				"popupform-rowbgcolor": "#1E1E29",
				"popuptoggle-textsize": "15px",
				"popuptoggle-textweight": "bold",
				"popuptoggle-buttonselectettextcolor": "var(--section-maincolor)",
				"popuptoggle-buttonnormaltextcolor": "#999999",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"alert-cancelbuttoncolor": "#1E1E29",
				"text-color": "#FFFFFF",
				"alert-bgcolor": "#2D2C43",
				"popup-head-bg": "#1E1E29",
				"popup-text": "#FFFFFF",
				"book-icon-color": "#38374F",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-1F8492-FFFFFF": "#FFFFFF",
				"color-d7e6e8-23323b": "#23323b",
				"color-999999-FFFFFF": "#FFFFFF",
				"color-CCCCCC-666666": "#666666",
				"color-eee-1e1e29": "#1e1e29",
				"aggrid-filterinputbgcolor-disabled": "rgba(30, 30, 41, 0.5)",
				"aggrid-filterinputbordercolor-disabled": "rgba(83 83 106, 0.5)",
				"alert-okbuttoncolor": "var(--section-maincolor)",
				"pulldown-selectedbgcolor": "var(--section-maincolor)",
				"pulldown-selectedtextcolor": "#FFFFFF",
				"pulldown-overbgcolor": "#1E1E29",
				"pulldown-shadow": "0px 5px 30px rgba(0,0,0,0.3)",
				'pulldown-labelcolor': 'var(--common-textcolor)',
				'DDCAB4-232334': "#232334",
				"color-CCCCCC-53536A": "#53536A",
				"color-FFFFFF-1E1E29": "#1E1E29",
				"color-F0F0F0-2D2C43": "#2D2C43",
				"color-FFFFFF-2D2C43": "#2D2C43",
				"color-E6E6E6-2D2C43": "#2D2C43",
				"color-EDEDED-181821": "#181821",
				"color-secmain-FFFFFF": "#FFFFFF",
				"color-F5F5F5-35344d": "#35344d",
				"color-E4CBD3-53536A": "#53536A",
				"color-E7D0D7-232334": "#232334",
				"color-FFFFFF-232334": "#232334",
				"color-F4F4F4-2D2C43": "#2D2C43",
				"color-CCCCCC-38374F": "#38374F",
				"color-EBEBEB-2D2C43": "#2D2C43",
				"color-181821-EDEDED": "#EDEDED",
				"color-E0E0E0-5B5B5B": "#5B5B5B",
				"color-0000001A-FFFFFF1A": "#FFFFFF1A",
				"color-DF3354-FFFFFF": "#DF3354",
				"list-headerfontsize": "15px",
				"list-headerfontcolor": "#A0A0A0",
				"tickbox-selectedcolor": "var(--section-maincolor)",
				"tickbox-unselectecolor": "var(--common-textcolor)",
				"popupform-textmargin": "10px"
			}
		}, this.eleRef.nativeElement);
	}

	ngOnChanges() {
		this.init_tabs();
	}

	init_options() {
		this.page_sync_method_options = [
			{value: 'none', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_none')},
			{value: 'semi', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_semi')},
			{value: 'full', label: this.trans.instant('bookviewer.toolbar_setting.page_sync_method_full')},
		];

		let ui_size_options = [];
		ui_size_options.length = 10;
		this.ui_size_options = ui_size_options.fill(null).map((_, i) => {
			return {
				value: i + 1,
				label: `${i + 1}`,
			}
		});
	}

	open() {
		this.current_tab_key = 'teacher';
		this.init_tabs();
		return this.modal.open();
	}

	toggle_tool(tools, tool) {
		if (tools.includes(tool)) {
			tools.splice(tools.indexOf(tool), 1);
		} else {
			tools.push(tool);
		}
		if (this.web_lesson.is_teacher) {
			if (tools === this.student_toolbar_tools) {
				this.web_lesson.lesson_state.current_student_tools = this.web_lesson.lesson_state.current_student_tools.filter(t => {
					return this.student_toolbar_tools.includes(t);
				});
				this.web_lesson.update_lesson_state();
			}
		}
	}

	get_tool_style(tool) {
		let image_path = `./assets/ro/app_icons/app_${tool.split('-').join('_')}.svg`;
		if(tool === 'show-answer') {
			image_path = `./assets/ro/app_icons/app_show_answer.png`;
		}

		return {
			'background-image': `url(${image_path})`,
		}
	}

	public faCheck = faCheck;

	confirm() {
		this.modal.close();
		localStorage.setItem("bookviewer_toolbar_settings", JSON.stringify({
			uid: this.datas.userInfo.uid,
			teacher: this.teacher_toolbar_tools,
			student: this.student_toolbar_tools,
			ui_size: this.ui_size,
		}));
	}
}