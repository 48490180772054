import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { AlertService } from "src/app/service/alert.service";
import { DataService } from "src/app/service/data.service";
import { LoadingService } from "../../loadingModule/loading.service";
import { DateTimePicker3Component } from "../../dateTimePicker3Module/dateTimePicker3.component";
import { BubbleBox2Component } from "../../bubbleBox2Module/bubbleBox2.component";
import * as moment from "moment";
import { GUID } from "src/app/class/hk/openknowledge/encryption/GUID";
import { Base64ObjectUtils } from "../../roBookModule/ROComponent";
import { UploadService } from "../../uploadModule/upload.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
	selector: "create-speech-practice",
	templateUrl: "./create-speech-practice.component.html",
	styleUrls: ["./create-speech-practice.component.scss"],
})
export class CreateSpeechPracticeComponent implements OnInit {
	@Input() prop: any;
	@Output() cancel: EventEmitter<any> = new EventEmitter();

	@ViewChild("modal", { static: false }) modal;
	@ViewChild("targetSelector", { static: false }) targetSelector;
	@ViewChild("subjectSelector", { static: false }) subjectSelector;
	@ViewChild('learningObjectiveSelector', { static: false }) learningObjectiveSelector;
	@ViewChild('preset', { static: false }) preset;
	@ViewChild("inProgressTimeRef", { static: false }) inProgressTimeRef;
	@ViewChild("correctionTimeRef", { static: false }) correctionTimeRef;
	@ViewChild("dateTimePicker", { static: false }) dateTimePicker: DateTimePicker3Component;

	// variables for <modal2>
	public width = "740px";
	public height = "610px";
	public title = "";
	public cancelLabel = "";
	public confirmLabel = "";
	public targetSelecting = false;
	public subjectSelecting = false;
	public isEmptyField = false;

	// variables for <ng-container>
	public ready = false;

	// variables for create speech practice
	public lang = this.data.lang;
	public selectorPromise: any;
	public practiceTitle = "";
	public fontSizeOption = [
		{ value: "S", label: this.translateText("細", "細", "Small") },
		{ value: "N", label: this.translateText("中", "中", "Normal") },
		{ value: 'L', label: this.translateText("大", "大", "Large") },
	];
	public fontSize = {};
	public practiceTypeOption = [
		{ value: "word", label: this.translateText("單字", "单字", "Word") },
		{ value: "sentence", label: this.translateText("句子", "句子", "Sentence") },
		{ value: 'fillIn', label: this.translateText("填充", "填充", "Fill In") },
		{ value: 'mc', label: this.translateText("選擇", "选择", "Multiple Choice") },
		{ value: 'paragraph', label: this.translateText("段落", "段落", "Paragraph") },
		{ value: 'aiOral', label: this.translateText("會話", "会话", "Conversation") },
		{ value: 'oralVocab', label: this.translateText("單字會話", "单字会话", "Vocab Conversation") },
		{ value: "oralSentence", label: this.translateText("句子會話", "句子会话", "Sentence Conversation") },
		{ value: 'oralFillIn', label: this.translateText("填充會話", "填充会话", "Fill In Conversation") },
		{ value: 'oralMc', label: this.translateText("選擇會話", "选择会话", "Multiple Choice Conversation") },
		{ value: 'oralParagraph', label: this.translateText("段落會話", "段落会话", "Paragraph Conversation") },
		{ value: 'oralAI', label: this.translateText("AI會話", "AI会话", "AI Conversation") }
	];
	public practiceType = {};
	public difficultyOption = [
		{ value: "easy", label: this.translateText("容易", "容易", "Easy") },
		{ value: "hard", label: this.translateText("困難", "困难", "Hard") },
	];
	public difficulty = {};

	public target = [];
	public targetTitle = "";
	public subject = [];
	public subjectTitle = "";
	public learningObject = [];
	public learningObjectTitle = "";
	public startTime;
	public endTime;
	public inProgressTimeText = "";
	public correctionStartTime;
	public correctionEndTime;
	public correctionTimeText = "";

	public topic = "";
	public topicArray = [];
	public newTopicArray = [];
	public infoBallText = "";
	public cid;
	public cidArray = [];
	public popUpContent = [
		{
			label: this.translateText("訓練標題*", "训练标题*", "Practice topic*"),
			data: null,
			type: "title",
		},
		{
			label: this.translateText("訓練題型*", "训练题型*", "Practice type*"),
			data: null,
			type: "practiceType",
		},
		{
			label: this.translateText("難度*", "难度*", "Difficulty*"),
			data: null,
			type: "difficulty",
		},
		{
			label: this.translateText("字體大小", "字体大小", "Font Size"),
			data: "null",
			type: "fontSize",
		},
		{
			label: this.translateText("組別*", "组别*", "Group*"),
			data: null,
			type: "targetSelector",
		},
		{
			label: this.translateText("科目", "科目", "Subject"),
			data: null,
			type: "subjectSelector",
		},
		{
			label: this.translateText("學習重點*", "学习重点*", "Learning Objective*"),
			data: null,
			type: "learningObjective",
		},
		{
			label: this.translateText("類別及設定*", "类别及设定*", "Type & setting"),
			data: null,
			type: "setting",
		},
		{
			label: this.translateText("「進行中」時段", "「进行中」时段", "Open period"),
			data: "inProgress",
			type: "dateTimePicker",
		},
		{
			label: this.translateText("「改正」時段", "「改正」时段", "Correction period"),
			data: "correction",
			type: "dateTimePicker",
		}
	];
	public setting = {
		share_type: "homework",
		pass_level: "50",
		time_limit: "null",
		prize_type: "star",
		star: 0,
		ruby: 0,
		emberald: 0,
		crystal: 0,
		// 限制提交次數
		max_submission_count: "null",
		// 自動提交
		auto_submit: 1,
		// 即時核對答案
		live_verify: 1,
		hideDefaultAnswer: true,
	};

	public mediaUrl = "";
	public previewBackground;

	// topic input numbering
	public inputInitialNumbering = 1;
	public inputValue = `1.  `

	constructor(
		public data: DataService,
		public alert: AlertService,
		public loading: LoadingService,
		public upload: UploadService,
		public sans: DomSanitizer,
		private eleRef: ElementRef
	) {}

	ngOnInit() {
		console.log(this.prop)
		this.initTitle();
		this.initTarget();
		this.initSubject();
		this.initLearningObject();
		this.initInProgressTime();
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.modal.open();
		}, 10);
	}

	// Initialize
	initTitle() {
		this.title = this.translateText("新增朗讀訓練", "新增朗读训练", "Create Speech Practice");
		this.cancelLabel = this.translateText("取消", "取消", "Cancel");
		this.confirmLabel = this.translateText("新增", "新增", "Add");
	}

	initTarget() {
		if (this.target.length === 0) {
			this.targetTitle = {
				tc: "請選擇...",
				sc: "请选择...",
				en: "Please select",
			}[this.lang];
		} else {
			this.targetTitle = this.target.map((t) => t.title).join(",");
		}
	}

	initSubject() {
		if (this.subject.length === 0) {
			this.subjectTitle = {
				tc: "請選擇...",
				sc: "请选择...",
				en: "Please select",
			}[this.lang];
		} else {
			this.subjectTitle = this.subject.map((s) => s.title).join(",");
		}
	}

	initLearningObject() {
		if (this.learningObject.length === 0) {
			this.learningObjectTitle = {
				tc: "請選擇...",
				sc: "请选择...",
				en: "Please select",
			}[this.lang];
		}
	}

	initInProgressTime() {
		// reset timeStamp before initialize
		this.startTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
		this.correctionStartTime = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

		const startTimeMo = this.startTime
			? moment(this.startTime, "YYYY-MM-DD HH:mm:ss")
			: null;
		const endTimeMo = this.endTime
			? moment(this.endTime, "YYYY-MM-DD HH:mm:ss")
			: null;
		const correctionStartTimeMo = this.correctionStartTime
			? moment(this.correctionStartTime, "YYYY-MM-DD HH:mm:ss")
			: null;
		const correctionEndTimeMo = this.correctionEndTime
			? moment(this.correctionEndTime, "YYYY-MM-DD HH:mm:ss")
			: null;
		if (endTimeMo) {
			this.inProgressTimeText =
				startTimeMo.format("YYYY/M/D H:mma") +
				" - " +
				endTimeMo.format("YYYY/M/D H:mma");
			this.correctionTimeText =
				correctionStartTimeMo.format("YYYY/M/D H:mma") +
				" - " +
				correctionEndTimeMo.format("YYYY/M/D H:mma");
		} else {
			this.inProgressTimeText =
				startTimeMo.format("YYYY/M/D H:mma") + " - ∞";
			this.correctionTimeText =
				correctionStartTimeMo.format("YYYY/M/D H:mma") + " - ∞";
		}
	}

	// function for Navbar
	topicFormatHandler(bookEntry) {
		let subType = bookEntry.practiceType.type;
		this.topicArray = this.topic.split('\n');
		let rawInput = this.topicArray
			.map(vocab => vocab.replace(/^[^a-zA-Z]+/, ''))
			.map((vocab: string) => vocab.charAt(0).toUpperCase() + vocab.slice(1));

		let fillInInput = [
			{
				topic: "Some students need the proper () to succeed.",
				reading: "Some students need the proper  to succeed.",
				answer: "Motivation"
			},
			{
				topic: "A () calms your mind and focuses your senses.",
				reading: "A  calms your mind and focuses your senses.",
				answer: "Meditation"
			},
			{
				topic: "Don't work too hard, or you'll suffer from ().",
				reading: "Don't work too hard, or you'll suffer from  .",
				answer: "Burnout"
			},
			{
				topic: "The problem we have is a lack of proper ().",
				reading: "The problem we have is a lack of proper  .",
				answer: "Communication"
			},
			{
				topic: "I would like to claim the () for finding your lost wallet.",
				reading: "I would like to claim the  for finding your lost wallet.",
				answer: "Reward"
			},
		];

		let mcInput = [
			{
				topic: "How could I communicate better as a group leader?",
				answerChoice: [
					"Just keep quiet and leave everyone else alone.",
					"Be as loud as possible and shout if you want.",
					"Make sure everyone is heard and let them feel important.",
					"Who wants to be a leader? Step down!"
				],
				correctAnswer: "Make sure everyone is heard and let them feel important."
			},
			{
				topic: "I'm looking for a guidance counselor. Do you know one? ",
				answerChoice: [
					"Her office is back there at the end of the hallway.",
					"I can be your guidance counselor if you want.",
					"You don't need one. Just keep being you!",
					"Do you need guidance? What a lost little lamb."
				],
				correctAnswer: "Her office is back there at the end of the hallway."
			},
			{
				topic: "I think I'm suffering from burnout. I feel mentally exhausted.",
				answerChoice: [
					"Be quiet or I will literally burn you.",
					"You need a vacation to clear your mind and relax.",
					"I don't care. Do whatever you want.",
					"Push through it, and you won't feel exhausted anymore."
				],
				correctAnswer: "You need a vacation to clear your mind and relax."
			},
			{
				topic: "We need to communicate better. I don't understand what you mean half the time!",
				answerChoice: [
					"I don't understand what you mean either!",
					"That just means we understand each other fine the other half.",
					"You're right. We need to be clear and ask questions if we're not sure.",
					"We're better off not communicating at all."
				],
				correctAnswer: "You're right. We need to be clear and ask questions if we're not sure."
			},
			{
				topic: "How do I find my motivation in life?",
				answerChoice: [
					"You don't. Just give up.",
					"Just wander through life aimlessly. No one cares.",
					"Copy what other people do, and you'll be fine.",
					"Take your time, explore your interests, and don't give up."
				],
				correctAnswer: "Take your time, explore your interests, and don't give up."
			}
		]

		if (subType === "fillIn") {
			this.newTopicArray = fillInInput.map((item) => {
				return {
					displayTopic: item.topic,
					readingTopic: item.reading,
					answerChoice: [item.answer],
					correctAnswer: item.answer,
				}
			})
		} else if (subType === "mc") {
			this.newTopicArray = mcInput.map((item) => {
				return {
					displayTopic: item.topic,
					readingTopic: item.topic,
					answerChoice: item.answerChoice,
					correctAnswer: item.correctAnswer,
				}
			})
		} else {
			this.newTopicArray = rawInput.map((item) => {
				if (subType === "word" || subType === "sentence" || subType === "oralVocab" ||
					subType === "oralSentence" || subType === "oralFillIn" || subType === "oralMc" ||
					subType === "oralParagraph" || subType === "oralAI"
				) {
					return {
						displayTopic: item,
						readingTopic: item,
						answerChoice: [item],
						correctAnswer: item,
					}
				} else if (subType === "paragraph") {
					let a = `Tonight Mum taught me how to make pumpkin soup. First, I heated the butter on a saucepan over medium heat. When it melted, Mum added some chopped onion and cooked them for two minutes. Then Mum asked me to turn the heat to low and stir in the pumpkin and some chopped meat. I did what Mum asked me to do and she prepared a small bowl for me.`
					let b = `Be polite, courteous and respectful at all times. Treat others the way you want to be treated. If you have something to share with the class or a question to ask during the lesson, raise your hand. Wait until the teacher calls on you. Respect other students' opinions. Listen quietly when others are speaking. You will have your turn to speak, too.`
					return {
						displayTopic: b,
						readingTopic: b,
						answerChoice: [b],
						correctAnswer: b,
					}
				} else if (subType === "aiOral") {
					return {
						displayTopic: "",
						readingTopic: "",
						answerChoice: [""],
						correctAnswer: "",
					}
				}
			})
		}
		return this.newTopicArray;
	}

	confirmClick() {
		let missingField = false;

		let bookEntry = {
			bookType: "speechPractice",
			practiceType: this.practiceType,
			difficulty: this.difficulty,
			name: this.practiceTitle,
			setting: this.setting,
		};

		bookEntry["topic"] = this.topicFormatHandler(bookEntry);
		console.log(bookEntry);

		// if (
		// 	!this.practiceTitle ||							// 訓練標題
		// 	!this.practiceType.hasOwnProperty("type") ||	// 訓練題型
		// 	!this.difficulty.hasOwnProperty("level") ||		// 難度
		// 	this.target.length < 1 ||						// 組別
		// 	this.learningObject.length < 1 ||				// 學習重點
		// 	this.newTopicArray.length < 1   				// 題目
		// ) {
		// 	missingField = true;
		// }

		if (missingField) {
			this.alert.toastError("course.pls-fill-all-fields");
			return;
		}

		let targetValues = this.target
			.filter((t) => t.tType == "tag")
			.map((e) => e.value);

		this.createBook(bookEntry, targetValues);
	}

	createXML(bookEntry) {
		const context = {
			type: bookEntry.bookType,
			answerDisplayType: "text",
			recording: false,
			resultPosition: "end",
			playing: false,
			format: {
				fontFamily: "Noto Sans T Chinese Regular",
			},
			extraWidth: 0,
			lang: "en-US",
			showPercent: true,
			size: {
				width: 165,
				height: 50,
			},
			showSpeaker: false,
			layout: "all",
			state: "stop",
			passLevel: parseInt(this.setting.pass_level),
			backgroundImg: this.mediaUrl,
			fontSize: this.fontSize
		};

		const encryptedContext = Base64ObjectUtils.base64Encode(context);

		let xCoordinate = 0;
		let yCoordinate = 0;

		// create XML
		let xml = `<Doc douid="${GUID.create("OKD guid")}"><Chapter>`;
		for (let content of bookEntry.topic) {
			// design XML in each page
			xml += `<Page douid="${GUID.create("OKD guid")}" w="1024" h="768" broadcast="true" slideTitle="" pageFitType="auto" pageStyle="None" questionStartIndex="0" teacherNote="" questionCount="0" color="#ffffff">`;
			this.cid = GUID.create("OKD guid")
			this.cidArray.push(this.cid)
			xml += `<SpeechPractice douid="${this.cid}" coordinateExpression="UA UK KH KR X ${xCoordinate} Y ${yCoordinate} D T 300 L 350 H 768 W 1024" hasScoring="false" scoringType="1" scoreN="1" unitScore="1" fullScore="1" context="${encryptedContext}" q="{&quot;show&quot;:0,&quot;color&quot;:&quot;noColor&quot;,&quot;ballSize&quot;:36,&quot;freezed&quot;:1,&quot;level&quot;:0}" s="{&quot;offset&quot;:{&quot;x&quot;:0,&quot;y&quot;:0},&quot;enable&quot;:0,&quot;freezed&quot;:1,&quot;y&quot;:-5,&quot;reference&quot;:&quot;rt&quot;,&quot;x&quot;:358,&quot;optional&quot;:false}" isQuestionComponent="true" locked="false" questionIndex="0" qInfo="{&quot;pid&quot;:0,&quot;root&quot;:0,&quot;order&quot;:0,&quot;index&quot;:0,&quot;id&quot;:1,&quot;rootIndex&quot;:0,&quot;level&quot;:0}" sound="{&quot;tts&quot;:{&quot;setting&quot;:{&quot;speed&quot;:1,&quot;pitch&quot;:1,&quot;preprocess&quot;:0},&quot;engine&quot;:{&quot;n&quot;},&quot;custom&quot;:0,&quot;text&quot;:ame&quot;:&quot;en-GB-LibbyNeural&quot;,&quot;gender&quot;:&quot;F&quot;,&quot;lang&quot;:&quot;en-GB&quot;${content.displayTopic}&quot;},&quot;type&quot;:&quot;tts&quot;}">`;
			xml += `&lt;TextFlow fontFamily="Noto Sans T Chinese Regular" fontSize="32" lineBreak="explicit" whiteSpaceCollapse="preserve" version="3.0.0" xmlns="http://ns.adobe.com/textLayout/2008"&gt;&lt;p&gt;&lt;span fontSize="40"&gt;${content.displayTopic}&lt;/span&gt;&lt;/p&gt;&lt;/TextFlow&gt;`;
			xml += `</SpeechPractice>`;
			xml += `</Page>`;
		}
		xml += `</Chapter></Doc>`;
		return xml;
	}

	createBook(bookEntry, targetValues) {
		let shares = [];

		const startTime = this.startTime
			? moment(this.startTime, "YYYY-MM-DD HH:mm:ss").toISOString()
			: null;
		const endTime = this.endTime
			? moment(this.endTime, "YYYY-MM-DD HH:mm:ss").toISOString()
			: null;
		const correctionStartTime = this.correctionStartTime
			? moment(
				this.correctionStartTime,
				"YYYY-MM-DD HH:mm:ss"
			).toISOString()
			: null;
		const correctionEndTime = this.correctionEndTime
			? moment(
				this.correctionEndTime,
				"YYYY-MM-DD HH:mm:ss"
			).toISOString()
			: null;

		const options = {
			type: bookEntry.setting.share_type,
			title: bookEntry.name,
			share_type: bookEntry.setting.share_type,
			pass_level: bookEntry.setting.pass_level,
			time_limit: bookEntry.setting.time_limit,
			prize_type: bookEntry.setting.prize_type,
			star: bookEntry.setting.star,
			ruby: bookEntry.setting.ruby,
			emberald: bookEntry.setting.emberald,
			crystal: bookEntry.setting.crystal,
			max_submission_count: bookEntry.setting.max_submission_count,
			auto_submit: bookEntry.setting.auto_submit,
			live_verify: bookEntry.setting.live_verify,
			hideDefaultAnswer: bookEntry.setting.hideDefaultAnswer,
			start_time: startTime,
			end_time: endTime,
			correction_start_time: correctionStartTime,
			correction_end_time: correctionEndTime,
		};

		const bookStructure = {
			topic: bookEntry.topic,
			title: this.practiceTitle,
			bookType: bookEntry.bookType,
			mainType: "",
			subType: bookEntry.practiceType.type,
			difficulty: bookEntry.difficulty.level,
			cid: this.cidArray,
			learningObject: this.learningObject
		}

		if (this.loading.isLoading == false) {
			this.loading.add("ROBookShare.create_multi_share", 20000);
		}
		console.log(bookStructure)

		this.data.post2({ data: { api: "SpeechPractice.add_book_for_speech_practice", json: [null, bookEntry.name, this.createXML(bookEntry), bookStructure] } })
			.then((xmlBookRes) => {
				console.log(xmlBookRes)
				const obj = {
					entries: [
						{
							id: xmlBookRes.entry.id,
							type: bookEntry.bookType,
							title: bookEntry.name,
						},
					],
					tags: targetValues,
					options: options,
					subjects: this.subject,
				};

				this.data.post("ROBookShare.create_multi_share", [obj]).subscribe((res: any) => {
					console.log("ROBookShare.create_multi_share: ", res);
					if (res) {
						try {
							shares.push(...res.shares);

							console.log(bookEntry.bookType)
							if (bookEntry.practiceType.type.sub === "aiOral") {
								let form = {
									"basicInfo": {
										"tags": [
											{
												"tType": "tag",
												"type": "3",
												"value": "34922",
												"title": "Hung's class",
												"school_year_id": "2318",
												"ctype": null,
												"subject_id": null
											}
										],
										"gradeAndSkillDescription": "P4",
										"subject": [
											{
												"type": "public",
												"school_subject_id": null,
												"public_subject_id": "11",
												"level": "S1-3",
												"id": "11-0",
												"title": "English Language",
												"short_name": null,
												"selected": true
											}
										],
										// "topic": "How do you like to study",
										// "description": "Which one is more effective? Memorizing as much as possible or studying by understanding basic principles? How do you like to study?"
										"inProgressTime": "2024/8/19 11:45pm - ∞",
										"topic": "Food and drink",
										"description": "Food and drink."
									},
									"conversationSetting": {
										"inputMode": "speech",
										"language": "en-US",
										"voice": "en-US-AriaNeural",
										"speed": "normal",
										"display": "hide",
										"response": {
											"on": true,
											"sentences": "3",
											"words": "10"
										},
										"hints": {
											"on": true,
											"sentences": "3",
											"times": 1
										}
									},
									"scoreSetting": {
										"aiFinalComment": {
											"on": true,
											"words": "30",
											"times": 1
										},
										"finalScoring": "ai-grade-teacher-review",
										"scoringScale": "manual",
										"scoringScaleText": "",
										"passLevel": "50",
										"scoringItems": [
											{
												"title": "內容",
												"guidance": "",
												"score": 40
											},
											{
												"title": "文法",
												"guidance": "",
												"score": 30
											},
											{
												"title": "創意",
												"guidance": "",
												"score": 30
											}
										]
									}
								}
								this.createAIOralPractice(form, xmlBookRes.entry.id, "")
							}

							if (res.shares.length === targetValues.length) {
								this.loading.remove("ROBookShare.create_multi_share");
								const msg = {
									tc: "已新增朗讀訓練",
									sc: "已新增朗读训练",
									en: "Speech Practice was created.",
								}[this.data.lang];

								this.alert.toastSuccess(msg);

								if (this.prop.close) {
									this.prop.close(res.shares[0]);
								}
							}
						} catch (error) {
							console.log(error)
						}
					}
				});
			});
	}

	private async createAIOralPractice(form, bookId, mediaUrl) {
		const res = await this.data.post2({ data: { api: 'ROBookShelfNew.create_or_edit_ai_oral_practice', json: [form, this.data.userInfo.uid, bookId, mediaUrl, null] } })
		const scoringItems = form.scoreSetting.scoringItems;
		const template = {type: 'ai-oral', book_id: bookId, scoringItems: scoringItems, name: this.practiceTitle };
		const res2 = await this.data.post('ROBookShelfNew.addFakeBook', [ template ]).toPromise();
	}

	mediaUpload() {
		const uploadOptions = { size: 50 * 1024 * 1024, fileType: 'image', toMediaServer: 'ai-composition', resizeRatio: 1 };
		this.upload.upload(uploadOptions).then((res: any) => {
			let url = 'https://oka.blob.core.windows.net/media/';
			url = url + res.url;
			this.mediaUrl = url;
		});
	}

	cancelClick() {
		this.cancel.emit(null);
	}

	// function for create speech practice
	targetSelectorOpen($event) {
		if (this.selectorPromise && this.targetSelector) {
			this.targetSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.targetSelecting = false;
		} else {
			this.targetSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.targetSelector
					.open($event.target, this.eleRef.nativeElement)
					.then((data) => {
						this.selectorPromise = null;
						this.targetSelecting = false;
						this.initTarget();
					})
					.catch((err: any) => {
						this.selectorPromise = null;
					});
			}, 250);
		}
	}

	subjectSelectorOpen($event) {
		if (this.selectorPromise && this.subjectSelector) {
			this.subjectSelector.bubbleBox2.closeFn();
			this.selectorPromise = null;
			this.subjectSelecting = false;
		} else {
			this.subjectSelecting = true;
			setTimeout(() => {
				this.selectorPromise = this.subjectSelector
					.open($event.target, this.eleRef.nativeElement)
					.then((data) => {
						this.selectorPromise = null;
						this.subjectSelecting = false;
						this.initSubject();
					})
					.catch((err: any) => {
						this.selectorPromise = null;
						this.subjectSelecting = false;
					});
			}, 200);
		}
	}

	learningObjectivesSelectorOpen($event): void {
		setTimeout(() => {
			this.selectorPromise = this.learningObjectiveSelector
				.open($event.target, this.eleRef.nativeElement)
				.then((data) => {
					this.selectorPromise = null;
					this.learningObject = data;
					const selectedPoints = this.learningObjectiveSelector.treeContent.modalSelectedItems;
					if (selectedPoints.length > 0){
						this.learningObjectTitle = selectedPoints.map(e=>e.name).join(',');
					} else {
						this.learningObjectTitle = {
							tc: "請選擇...",
							sc: "请选择...",
							en: "Please select",
						}[this.lang];
					}
				})
				.catch((err: any) => {
					this.selectorPromise = null;
				});
		}, 200);
	}

	timeClick(pickerType: string) {
		const isDtpOpen =
			this.dateTimePicker.bubbleBox2.show &&
			this.dateTimePicker.bubbleBox2.boxPosX > 0;
		if (isDtpOpen) {
			return;
		}

		// inProgress timeStamp
		if (pickerType === "inProgress") {
			let ele = this.inProgressTimeRef.nativeElement;
			let wholeDay = 0;
			let startDateTime: any = this.startTime
				? moment(this.startTime, "YYYY-MM-DD HH:mm:ss").toDate()
				: null;
			let endDateTime: any = this.endTime
				? moment(this.endTime, "YYYY-MM-DD HH:mm:ss").toDate()
				: null;
			let options: any = {
				value: { startDateTime: startDateTime, endDateTime: endDateTime, isWholeDay: wholeDay }, skipLoadApi: true
			};
			this.dateTimePicker
				.open(ele, options)
				.then((data: any) => {
					this.startTime = moment(data.startDateTime).format(
						"YYYY-MM-DD HH:mm:ss"
					);
					if (data.endDateTime) {
						this.endTime = moment(data.endDateTime).format(
							"YYYY-MM-DD HH:mm:ss"
						);
					} else {
						this.endTime = null;
					}
					this.initInProgressTime();
				})
				.catch((err: any) => { });
		}
		// correction timeStamp
		else {
			let ele = this.correctionTimeRef.nativeElement;
			let wholeDay = 0;
			let startDateTime: any = this.correctionStartTime
				? moment(this.startTime, "YYYY-MM-DD HH:mm:ss").toDate()
				: null;
			let endDateTime: any = this.correctionEndTime
				? moment(this.endTime, "YYYY-MM-DD HH:mm:ss").toDate()
				: null;
			let options: any = {
				value: { startDateTime: startDateTime, endDateTime: endDateTime, isWholeDay: wholeDay }, skipLoadApi: true
			};
			this.dateTimePicker
				.open(ele, options)
				.then((data: any) => {
					this.correctionStartTime = moment(
						data.startDateTime
					).format("YYYY-MM-DD HH:mm:ss");
					if (data.endDateTime) {
						this.correctionEndTime = moment(
							data.endDateTime
						).format("YYYY-MM-DD HH:mm:ss");
					} else {
						this.correctionEndTime = null;
					}
					this.initInProgressTime();
				})
				.catch((err: any) => console.log(err));
		}
	}

	showInfo() {
		return {
			tc: "可輸入多條題目，請按Enter/Return鍵輸入新題目。",
			sc: "可输入多条题目，请按Enter/Return键输入新题目。",
			en: "You can enter multiple questions. Please enter new question by pressing Enter/Return."
		}[this.data.lang];
	}

	press(event: KeyboardEvent) {
		event.preventDefault();
		switch (event.key) {
			case 'Enter':
				this.inputInitialNumbering++;
				this.inputValue += `\n${this.inputInitialNumbering}.  `;
				this.topic = this.inputValue;
				break;
			case 'Backspace':
				if (this.inputInitialNumbering >= 1) {
					this.inputInitialNumbering--;
				}
				let lines = this.inputValue.split('\n');
				lines.pop();
				this.inputValue = lines.join('\n');
				if (this.inputInitialNumbering === 0) {
					this.inputInitialNumbering++;
					this.inputValue = `1.  `
				}
				this.topic = this.inputValue;
				break;
		}
	}

	inputUpdate(event) {
		this.inputValue = event.target.value;
		this.topic = this.inputValue;
	}

	translateText(tc: string, sc: string, en: string) {
		let text = { tc: tc, sc: sc, en: en }[this.lang];
		return text;
	}
}