import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import { ROComponent, ROPageComponent } from "./ROComponent";
import { StyleUtils } from "./StyleUtils";
import { XMLNode } from "./xml/XMLNode";

// =======================================
// icon
// =======================================
import { faCamera, faImage, faCrop, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { CameraCaptureComponent } from "../CameraCaptureModule/CameraCapture.component";
import { SafeStyle } from "@angular/platform-browser";
import { XMLJSNode } from "./xml/XMLParser";
import {SelectMarkerComponent} from "./SelectMarker.component";
import {SelectMarkerIcon} from "./SelectMarkerIcon";
import {DataService} from "../../service/data.service";
import {TranslateService} from "@ngx-translate/core";
import {CoordinateExpression} from "./CoordinateExpression";

@Component({
	template:`
	<div class="innerContent" [style.backgroundImage]="trustURLStyle"></div>
	<div class="btns">
		<fa-icon (click)="takePhoto()" [icon]="faCamera"></fa-icon>
		<fa-icon (click)="selectImage()" [icon]="faImage"></fa-icon>
		<fa-icon *ngIf="node.attributes.screenCaptureEnabled !== false" (click)="cropScreen()" [icon]="faCrop"></fa-icon>
	</div>
	<CameraCapture #cameraCapture></CameraCapture>
	`,
	styles:[
		`
		:host{
            --frameColor: #88CFEA;
            background-color: var(--frameColor);
			border-radius: 14px;
			position: relative;
			padding:7px;

            &.has-shadow {
                box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.3);
            }

            &.no-frame {
                padding: 0;
            }
		}
		.innerContent{
			width:100%;
			height:100%;
			background-color: #000;
			border-radius: 7px;
			background-repeat: no-repeat;
			background-size: contain;
			background-position: center;
		}
		.btns{
			position:absolute;
			top:18px;
			right:-10px;
			display:flex;
			flex-flow:column;
		}
		.btns>fa-icon{
			position: relative;
			display:flex;
			color:#fff;
			font-size: 20px;
			width:42px;
			height:42px;
			margin-bottom:8px;
//			outline: solid 1px #fff !important;
//			outline-offset: -1px;
			border-radius: 15px;
			background: linear-gradient(to top left, #095BAD 0%, #2080E0 50%, #53B4EB 90%);
			box-shadow: #0B4987 0px -2px 0px 0px inset;
			
		}
		.btns>fa-icon:after{
			position: absolute;
			top:0;
			left:0;
			content: '';
			width:calc(100% - 2px);
			height:calc(100% - 2px);
			border-radius: 13px;
			border: solid 1px #fff;
		}

		:host ::ng-deep fa-icon svg{
			margin: auto;
			filter: drop-shadow(3px 3px 5px rgba(19,88,157,10));
		}
		`
	]
})

export class ROCamera extends ROComponent
{
	@ViewChild('cameraCapture', {static:true}) public cameraCapture:CameraCaptureComponent;
	
	public faCamera:IconDefinition = faCamera;
	public faImage:IconDefinition = faImage;
	public faCrop:IconDefinition = faCrop;
	public ref:any;
	protected file:File; // for upload
	public trustURLStyle:SafeStyle;
	public frame_options = [];
	public yes_no_options = [];
	public defaultFrameColor = "#88CFEA";

	constructor(cdr: ChangeDetectorRef, elementRef: ElementRef, public datas: DataService, public trans: TranslateService)
	{
		super(cdr, elementRef);
		console.log('RO Camera lang', this.datas.lang);
		this.canEditInside = false;
		this.trans.use(this.datas.lang).subscribe(() => {
			this.frame_options = [
				{value: 'blue', label: this.trans.instant('bookEditor.ROCamera.frame_1')},
				{value: 'white', label: this.trans.instant('bookEditor.ROCamera.frame_2')},
				{value: 'none', label: this.trans.instant('bookEditor.ROCamera.frame_none')},
			];
		})
	}
	public getTagNames():string []
	{
		return ["Camera"];
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"ver": "1.1.1",
				"cameraMode": "image",
				"fileUrl": "",
				"frameStyle": "blue",
				"hasScoring": false,
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KR X 0 Y 0 D T 0 L 0 W 383 H 268",
				"q": "{\"x\":-60,\"color\":43163,\"show\":false,\"level\":1,\"y\":0,\"type\":\"auto\",\"ballSize\":36,\"freezed\":1}",
				"s": "{\"y\":0,\"optional\":false,\"x\":383,\"offset\":{\"y\":0,\"x\":0},\"enable\":false,\"reference\":\"rt\",\"freezed\":1}",
				"isQuestionComponent": true,
				"locked": false,
				"questionIndex": 0
				//"qInfo": "{\"index\":0,\"rootIndex\":0,\"pid\":5,\"level\":1,\"name\":\"a\",\"root\":0,\"order\":0,\"id\":3}"
			},
			tag:"Camera"
		});
		this.node.createElement();
	}

	protected buildContent() {
		let frameColor = this.defaultFrameColor;
		if (this.node.attributes.frameStyle === "blue" && this.node.attributes.frameColor) {
			frameColor = this.rgbToHex(`${this.node.attributes.frameColor}`);
		}
		if (this.node.attributes.frameStyle === "none") {
			frameColor = "transparent";
			this.elementRef.nativeElement.classList.add('no-frame');
		} else {
			this.elementRef.nativeElement.classList.remove('no-frame');
		}

		if (this.node.attributes.frameStyle === "white") {
			frameColor = "#ffffff";
			this.elementRef.nativeElement.classList.add('has-shadow');
		} else {
			this.elementRef.nativeElement.classList.remove('has-shadow');
		}
		this.elementRef.nativeElement.style.setProperty('--frameColor', frameColor);
	}

	public takePhoto():void {
		this.getImageResult(this.cameraCapture.lanuch());
	}

	public selectImage():void {
		this.getImageResult(this.context.service.fileIO.getLocalFile({fileType:'image'}));
	}

	public cropScreen():void {

	}

	protected getImageResult(p:Promise<any>):void {
		p.then((success)=> {
			this.file = success.file;
			this.ref = {
				key:this.douid,
				reference:this.context.createReference(),
				index:0
			};
			this.answerChanged = true;
			this.trustURLStyle = this.context.service.fileIO.trustURLStyle(URL.createObjectURL(this.file));

		}, (reason) => {
		});
	} 
	
	// ======================================================
	// data function
	// ======================================================
	public isQuestionComponent():boolean {
		return true;
	}

	public verify(_showScoring:boolean):any {
		// reset verify
		// reset score display
		this.sObj.hideScore();

		if(this.ref) {
			if(!this.resultObject)
				this.resultObject = {correct:-1};
		} else
			this.resultObject = null;

		// 顯示分數 / 已提交
		this.sObj.showScoring(_showScoring, "view", this.resultObject);
		return this.resultObject;
	}

	public get data():string {
		return this.ref ? this.objectToString(this.ref) : null;
	}

	public set data(input:string) {
		// clear verify and answer
		this.reset();
		this.answerChanged = true;

		if(input) {
			this.answerChanged = false; // 載入的資料不算有用戶改答案
			let jsonObj = this.stringToObject(input);
			if(jsonObj.hasOwnProperty("key")) {
				this.ref = jsonObj;
				this.loadFile(this.ref);
			}
		}
	}

	loadFile(obj: any):void
	{
		if(this.context.config.viewMode == "scoring")
		{
			this.context.loadStudentFile(
				<ROPageComponent> this.page,
				this, 
				this.context.config.student.uid,
				obj, 0
			).then((url:string)=>{
				this.trustURLStyle = this.context.service.fileIO.trustURLStyle(url);
			});
		} else if(this.context.config.viewMode == "view") {
			this.context.loadFile(<ROPageComponent> this.page, this, obj).then((url:string)=>{
				this.trustURLStyle = this.context.service.fileIO.trustURLStyle(url);
			});
		}
	}

	public getSelectorSettings(): any {
		let settings = super.getSelectorSettings();
		let ce = this.ce;
		settings.resizer = true;
		settings.widthResizer = !ce.lockScale;
		settings.heightResizer = !ce.lockScale;
		settings.lockedAspectRatio = !!ce.lockScale;

		console.log('ro camera getSelectorSettings', settings, this)
		return settings;
	}

	get ce(): CoordinateExpression {
		return this.parseCE(this.node.attributes.coordinateExpression);
	}

	private reset():void {
		this.answerChanged = false;
		this.ref = null;
		this.file = null;
		this.trustURLStyle = null;
		this.sObj.hideScore();
	}

	public getAnswerAssets():any [] {
		if(this.answerChanged) return [{
			key:this.ref.key,
			reference:this.ref.reference,
			index:0,
			file:this.file
		}];
		return null;
	}

	public getPropertyPanelConfig(selectMarker: SelectMarkerComponent, item: any): any {

		console.log('ro_camera node', this)

		const tabs = [
			{
				tab: "ro_camera_edit",
				icon: SelectMarkerIcon.PALETTE_ICON,
			},
			{
				tab: "ro_camera_move",
				icon: 'property_btn_coordinate',
			},
			{
				tab: "ro_camera_question",
				icon: "property_btn_tick_cross",
			}
		];

		const items = [];
		items.push(
			{
				type: "pulldown",
				name: "bookEditor.ROCamera.frame",
				options: this.frame_options,
				content: this.getPropertiesThroughPanel("frameStyle"),
				buttonColor: "#000000",
				component: this,
				callback: (value: string) => {
					this.setPropertiesThroughPanel("frameStyle", value);
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_edit"
				}
			},
			{
				type: "colorPicker",
				name: "bookEditor.ROCamera.frameColor",
				content: this.getPropertiesThroughPanel("frameColor"),
				isAllowClear: false,
				backgroundColor: "#063C38",
				component: this,
				callback: (event: any) => {
					this.setPropertiesThroughPanel("frameColor", event);
					selectMarker.emitPropertyPanel("frameColor");
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_edit" && this.node.attributes.frameStyle === "blue"
				}
			},
			{
				type: "switch",
				name: "bookEditor.ROCamera.screen_capture",
				content: this.getPropertiesThroughPanel("screenCaptureEnabled"),
				buttonColor: "#000000",
				component: this,
				callback: (value) => {
					this.setPropertiesThroughPanel("screenCaptureEnabled", value);
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_edit"
				}
			}
		);

		items.push(
			{
				type: "doubleNumber",
				label1: "x",
				label2: "y",
				get lock() {
					return this.component.lockXY();
				},
				set lock(value: boolean) {
					this.component.setLockXY(value);
				},
				value1: this.getPropertiesThroughPanel("x"),
				value2: this.getPropertiesThroughPanel("y"),
				component: this,
				callback: (value: number, numberLabel: string) => {
					if (typeof value !== 'number' || typeof numberLabel !== 'string') return;
					var property = '';
					if (numberLabel === "1") {
						property = 'x';
					} else if (numberLabel === "2") {
						property = 'y';
					}
					this.setPropertiesThroughPanel(property, Math.round(value));
					selectMarker.updateItemBoundingOnPropertyPanel(item);
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_move"
				}
			},
			{
				type: "doubleNumber",
				label1: "w",
				label2: "h",
				get lockScale() {
					return !!this.component.ce.lockScale;
				},
				set lockScale(value: boolean) {
					this.component.setLockScale(value);
				},
				value1: this.getPropertiesThroughPanel("w"),
				value2: this.getPropertiesThroughPanel("h"),
				component: this,
				callback: (value: number, numberLabel: string) => {
					if (typeof value !== 'number' || typeof numberLabel !== 'string') return;
					var property = '';
					if (numberLabel === "1") {
						property = 'w';
					} else if (numberLabel === "2") {
						property = 'h';
					}
					this.setPropertiesThroughPanel(property, Math.round(value));
					selectMarker.updateItemBoundingOnPropertyPanel(item);
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_move"
				}
			}
		)

		items.push(
			{
				type: "switch",
				name: "bookEditor.hasScoring",
				content: this.getPropertiesThroughPanel("hasScoring"),
				buttonColor: "#000000",
				component: this,
				callback: (value) => {
					this.setPropertiesThroughPanel("hasScoring", value);
				},
				showWhen: (tag: string) => {
					return tag === "ro_camera_question"
				}
			}
		)

		return {
			tabs,
			items,
		}
	}

	public getPropertiesThroughPanel(key: string): any {

		if (key === 'frameColor') {
			let val = this.node.attributes.frameColor || null;
			if (this.node.attributes.frameStyle === "blue") {
				if (!val) {
					return this.defaultFrameColor;
				}
			}
			if (val === null) {
				return null;
			}
			if (typeof val === 'string') {
				return val;
			}
			return this.rgbToHex(`${val}`);
		}
		if (key === 'screenCaptureEnabled') {
			let val = this.node.attributes[key];
			return val !== false;
		}
		if (key === 'x') {
			return this.x;
		}
		if (key === 'y') {
			return this.y;
		}
		if (key === 'w') {
			return this.w;
		}
		if (key === 'h') {
			return this.h;
		}

		let val = super.getPropertiesThroughPanel(key);
		if (val !== null) {
			return val;
		}

		return this.node.attributes[key];
	}

	public setPropertiesThroughPanel(key: string, val: any): boolean {

		if (key === 'frameColor') {
			this.node.attributes[key] = this.hexToRgb(val);
		} else if (key === 'x') {
			this.x = val;
		} else if (key === 'y') {
			this.y = val;
		} else if (key === 'w') {
			this.w = val;
		} else if (key === 'h') {
			this.h = val;
		} else if (key === 'hasScoring') {
			this.node.attributes[key] = val;
			if (val) {
				if (!this.node.hasAttribute('scoreN')) {
					this.node.attributes.scoreN = 1;
				}
				if (!this.node.hasAttribute('scoringType')) {
					this.node.attributes.scoringType = 1;
				}
				if (!this.node.hasAttribute('unitScore')) {
					this.node.attributes.unitScore = 1;
				}
				if (!this.node.hasAttribute('fullScore')) {
					this.node.attributes.fullScore = 1;
				}
				this.sObj.node.attributes.enable = true;
				if (this.sObj.node.attributes.position === "auto") {
					this.sObj.node.removeAttribute('position');
				}
				this.save_sObj();
				this.showComponentScore();
				this.sObj.updatePosition();
			} else {
				this.hideScore();
				this.sObj.node.attributes.enable = 0;
				this.save_sObj();
				console.log('hsaScoring false')
			}

		} else {
			this.node.attributes[key] = val;
		}

		this.updateWHAfterSetProperty();
		this.savePosition();
		this.build();
		return true;
	}

	public lockXY(): boolean {
		return !!this.ce.lockXY;
	}

	public getPopupSelector(): any[] {
		if (this.editInStage) {
			var ary: any[] = [this.getPSComplete()];

			var i: number = this.context.editLayerManager.editLayers.indexOf(this);
			i = i == -1 ? this.context.editLayerManager.editLayers.length : i;
			if (i >= 1)
				ary.push({
					type: "action",
					action: "back",
					target: "editor",
					color: "#FBB226",
					icon: SelectMarkerIcon.BACK_ICON,
					name: this.context.translate("ro.components.common.prev_layer")
				});
			return ary;
		}

		var ary: any[] = [this.getPSName(),];
		if (this.canEditInside)
			ary.splice(1, 0, this.getPSCanEdit());
		if (this.canDelete())
			ary.push(this.getPSDel());
		return ary;
	}

}