import { ChangeDetectorRef, Component, ElementRef, ViewChild, ViewContainerRef } from "@angular/core";
import {  ROComponent } from "./ROComponent";
import { ROQuestionNumber } from "./ROQuestionNumber";
import { XMLNode } from "./xml/XMLNode";
import { XMLJSNode } from "./xml/XMLParser";
import { SelectMarkerComponent } from "./SelectMarker.component";
///////////////
@Component({
	template:``
})

export class ROQBall extends ROComponent
{
	// questionNumber
	// ballSize <- 現在用 book setting 去控制，這裡的參數不用
	// ballColor

	constructor(cdr:ChangeDetectorRef, elementRef:ElementRef)
	{
		super(cdr, elementRef);
	}

	public getTagNames():string []
	{
		return ["QBall"];
	}

	public getPropertyPanelConfig(selectMarker: SelectMarkerComponent, item: any):any {
		return {};
	}

	// protected rgbToHex(rgb: string): string {
	// 	console.log("rgbToHex", rgb);
	// 	if (rgb === "transparent") return this.context.translate("bookEditor.transparent");
	// 	if (!rgb.match(/\d+/g)) {
	// 		return "invalid";
	// 	}
	// 	const [r, g, b, a = 1] = rgb.match(/\d+/g).map(Number);
	// 	if (a === 0) return this.context.translate("bookEditor.transparent");
	// 	return `#${(+r).toString(16).padStart(2, '0')}${(+g).toString(16).padStart(2, '0')}${(+b).toString(16).padStart(2, '0')}`;
	// }

	public setPropertiesThroughPanel(key:string, val:any):boolean
	{
		switch(key) {
			case "q.section":
				if(val) {
					this.node.setAttribute("questionSection", val);
					this.qObj.setQuestionSection(val);
				} else {
					this.node.removeAttribute("questionSection");
					this.qObj.setQuestionSection(null);
				}
				break;
			case "learningObjective":
				// this.setLearningObjective(val);
				break;
		}
		return true;
	}

	public getPropertiesThroughPanel(key:string):any
	{
		if(key == "q.show")
		{
			if(this.qObj)
			{
				return true;
			}
			return false;
		} else if(key == "q.section")
		{
			return this.node.getAttribute("questionSection");
		} else if(key == "learningObjective")
		{
			return JSON.parse(this.node.getAttribute("learningObjective", '[]'));
		}
		return null;
	}
	

	protected buildQuestionAndScoreObject():void
	{
		let xmlNode:XMLNode = new XMLJSNode().assign(
			{
				attributes: {
					"x": 0,"y": 0,"level": 0,"freezed": 1,"show": true,"ballSize": 36,
					"color":parseInt(this.node.attributes.ballColor.substring(1), 16)
				},
				tag:"QuestionNumber"
			}
		);
		this.qObj = <ROQuestionNumber> this.context.createForEF(this.elementRef, xmlNode, this.page, this);
	}

	protected setDefaultXMLData():void
	{
		this.node = new XMLJSNode().assign({
			attributes: {
				"ver": 1,
				"questionNumber": "1",
				"ballSize": 3,
				"douid": this.createDouid(),
				"coordinateExpression": "UA UK KW KH KR X 0 Y 0 D T 0 L 0 H 60 W 60",
				"hasScoring": true,
				"scoringType": 1,
				"scoreN": 1,
				"unitScore": 1,
				"fullScore": 1,
				"ballColor": "#5bb9d9",
				"locked": false
			},
			tag:"QBall"
		});
		this.node.createElement();
	}
}