import {Component, OnInit} from '@angular/core';
import {WebLessonService} from "../../../coreModule/lessonModule/WebLessonModule/services/WebLesson.service";
import {DataService} from "../../../service/data.service";
import {CommonService} from "../../../service/common.service";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Component({
	selector: 'student-star-counter',
	template: `
		<div class="student-star-counter">
			<div class="star"></div>
			<div class="count">{{ count || 0 }}</div>
		</div>
		
		<div *ngIf="is_play_animation"
			 class="star-count-changed-animation"
			 [class.add]="changed_star_count > 0"
			 [class.remove]="changed_star_count < 0"
		>
			<div class="star">
				<span class="before">{{ before_count }}</span>
				<span class="after">{{ before_count + changed_star_count }}</span>
			</div>
			
			<div class="change-count">
				{{ changed_star_count }}
			</div>
		</div>
	`,
	styleUrls: ['./student-star-counter.component.scss']
})

export class StudentStarCounterComponent implements OnInit {

	public count = null;
	public before_count = null;
	public changed_star_count = null;
	public star_changed_subject = new Subject();
	public is_play_animation = false;

	constructor(
		public web_lesson: WebLessonService,
		public datas: DataService,
		public coms: CommonService,
	) {
		this.star_changed_subject.pipe(debounceTime(1000)).subscribe((value) => {
			this.play_animation();
		})

		this.web_lesson.on_message.subscribe(async (message) => {
			let action = message.content.action;
			if (action === 'update_lesson_state') {
				await this.coms.waitFor(() => this.is_play_animation === false, 999999,100);
				this.init_count();
			}
		});
	}

	async init_count() {
		let uid = `${this.datas.userInfo.uid}`;
		let count = this.web_lesson.lesson_state.student_star[uid] || 0;
		if (this.count !== null && this.count !== count) {
			let changed_star_count = count - this.count;
			if (changed_star_count !== 0) {
				if (this.before_count === null) {
					this.before_count = this.count;
				}
				this.changed_star_count += changed_star_count;
				this.star_changed_subject.next(changed_star_count);
			}
		}

		this.count = count;
	}

	async play_animation() {
		this.is_play_animation = true;
		await this.coms.timeout(1200)
		this.is_play_animation = false;
		this.changed_star_count = null;
		this.before_count = null;
	}

	ngOnInit() {
	}
}