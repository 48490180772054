import { Component, EventEmitter, Input, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { faArrowRotateRight } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "header",
	templateUrl: "./header.html",
	styleUrls: ["./header.scss"]
})
export class speechPracticeHeader {
	@Input() public parent;
	@Input() public questionIndex;
	@Input() public totalQuestionNumber;
	@Input() public isConversationReportOpen;
	@Input() public defaultQuestion;

	@Output() public emitter: EventEmitter<any> = new EventEmitter();

	public refresh = faArrowRotateRight;
	public isButtonHide = false;

	constructor( public router: Router ) {}

	ngOnInit() {
		if (this.parent.exerciseType.includes(this.parent.subType)) this.questionIndex += 1;
		this.isButtonHide = this.parent.viewMode === 'review' || this.parent.viewMode === 'scoring'
	}

	close() {
		if (this.parent.exerciseType.includes(this.parent.subType)) {
			let userTotalScore = 0;
			let pageWithScore = Object.values(this.parent.bookConfig.dataSource.answerMap).filter((item: any) => item.result);
			let submittedNumber = pageWithScore.length;
			let eachPageScore = pageWithScore.map((item: any) => item.result["2280-1"]);
			for (let score of eachPageScore) userTotalScore += score;

			if ((this.questionIndex === this.totalQuestionNumber && submittedNumber === this.totalQuestionNumber) ||
				(this.questionIndex === this.totalQuestionNumber && this.parent.isRecorded)) {
				this.emitter.emit({
					action: "close",
					isFinalReportOpen: true,
					userTotalScore: userTotalScore,
				});
			} else {
				this.emitter.emit({ action: "close", isFinalReportOpen: false });
			}
		} else {
			if (this.isConversationReportOpen) {
				this.emitter.emit({ action: "close", isFinalReportOpen: true });
			} else {
				this.emitter.emit({ action: "close", isFinalReportOpen: false });
			}
		}
	}
}
