import { Injectable, NgZone, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from './alert.service';
import { CommonService } from './common.service';
import { DataService } from './data.service';
import { RoService } from './ro.service';
import { ScriptService } from './script.service';
import { environment } from 'src/environments/environment';
declare var eruda;

@Injectable({ providedIn: 'root' })
export class ErudaService {
	public eruda;
	public commands: any;
	constructor(private script: ScriptService, private datas: DataService, private route: ActivatedRoute, private als: AlertService, private coms: CommonService, private router: Router, private zone: NgZone, private ros: RoService) {
		this.initWindowFunction();
	}

	init() {
		this.script.load('eruda.js').then(() => {
			eruda.init();
			this.eruda = eruda;
			eruda.show();
			this.updateSnippets();
			this.updateInfo();
		});
	}

	toggle() {
		if (this.eruda == null) {
			this.init();
		} else {
			eruda._devTools.toggle();
		}
	}

	updateInfo() {
		let info = eruda.get('info');
		let defaultInfos = info.get();
		info.clear();
		if (this.datas.userInfo) {
			let userInfo = this.datas.userInfo;
			let accountInfo = [
				['uid', userInfo.uid],
				['school_id', userInfo.school_id],
				['jwt', this.datas.jwt],
				['user_role', userInfo.user_role],
			];
			info.add('Account', this.tableize(accountInfo));
		}
		defaultInfos.forEach(item => {
			info.add(item.name, item.val);
		});
	}

	tableize(arr = []) {
		let table = '<table><tbody>';
		arr = arr.filter(e => Array.isArray(e) && e.length == 2);
		let tbody = arr.map(item => '<tr><td class="eruda-device-key">' + item[0] + '</td><td>' + item[1] + '</td></tr>');
		let output = table + tbody.join('') + '</tbody></table>';
		return output;
	}

	updateSnippets() {
		let snippets = eruda.get('snippets');
		snippets.clear();
		snippets.add('hello', function () {
			console.log('Hello World!');
		}, 'Display hello on console');
		
		snippets.add('goHome', ()=>{
			// this.router.navigate(['home-screen', this.datas.lang, this.datas.appId2]);
			this.router.navigate(['home-screen']);
		}, 'go to home screen');
	}

	initWindowFunction() {
		window['s'] = (schoolCode, path = null) => {
			path = path ? path : this.router.url;
			this.commands = { schoolCode: schoolCode, path: path };
			this.zone.run(() => {
				if (isNaN(schoolCode) || ('' + schoolCode).length != 6) {
					this.datas.post('ROHomeScreen.guessSchoolCode', [schoolCode]).subscribe((res: any) => {
						if (res.school_code) {
							this.commands.schoolCode = res.school_code;
							this.router.navigateByUrl('/login/tc/rainbowone/lang-select', { skipLocationChange: true }).then(() => {
								// this.router.navigate(['login']);
								this.router.navigate(['login']);
							});
							console.log('school code: ' + res.school_code + ';' + 'school_id:' + res.school_id);
						} else {
							console.log('not found');
						}
					});
				} else {
					this.router.navigateByUrl(`/login/tc/rainbowone/lang-select/${this.datas.lang}/${this.datas.appId2}`, { skipLocationChange: true }).then(() => {
						this.router.navigate(['login']);
					});
				}
			});
		};

		window['u'] = (uid, path = null) => {
			path = path ? path : this.router.url;
			this.commands = { uid: uid, path: path };
			this.zone.run(() => {
				this.router.navigateByUrl(`/login/tc/rainbowone/lang-select/${this.datas.lang}/${this.datas.appId2}`, { skipLocationChange: true }).then(() => {
					this.router.navigate(['login']);
				});
			});
		};

		window['ngc'] = (selector = null, index = 0) => {
			selector = selector ? selector : 'router-outlet + *';
			let eles = document.querySelectorAll(selector);
			if (eles.length > 0) {
				let component = window['ng'].probe(eles[index]).componentInstance;
				return component;
			} else {
				return 'Angular component not found';
			}
		};

		window['roCall'] = async (name, data = null) => {
			//not work in ie with arrow func
			// i.e. open/book_share/[shareId]/[bookId]/1
			return await this.ros.request(name, data);
		};

		window['jwt'] = (uid = null) => {
			if (uid == null) {
				console.log('uid:' + this.datas.userInfo.uid + ';school_id:' + this.datas.userInfo.school_id);
				return this.datas.jwt;
			} else {
				this.datas.post('Account.school_login', [uid]).subscribe((res: any) => {
					if (res.account.uid) {
						console.log(res.account.jwt);
					} else {
						console.log('user not found');
					}
				});
				return 'Loading';
			}
		};

		window['note'] = (listType = 'sc') => {
			if (listType == 'sc') {
				return '天虹:63512910;leap:573356;FUNTASY:587689;教大:877331;readingstar劉本章:368433;aioral:38165993';
			} else if (listType == 'u') {
				return '';
			} else if (listType == 'si') {
				return 'keilok:26434';
			}
		};

		window['submission'] = (assignmentName) => {
			let uid = this.datas.userInfo.uid;
			this.datas.post('ROTest.findBsidFromUsage', [uid, assignmentName]).subscribe((res: any) => {
				if (res.rows.length > 0) {
					this.commands = { name: res.rows[0].title };
					this.router.navigate(['test', 'student-submission', uid, res.rows[0].bsid]);
				} else {
					console.log('not found');
				}
			});
		}

		window['listTransformDom'] = (selector = null)=>{
			const root = selector?document.querySelector(selector):document.body;
			const flatEle = (child, items = [])=>{
				if (child.children && child.children.length > 0){
					for(let i=0;i < child.children.length;i++){
						let newItems = flatEle(child.children[i], items);
						items = [...items, ...newItems];
					}
				}
				if (child.style.transform){
					items.push(child);
				}
				return items;
			};
			try{
				let items = flatEle(root);
				console.log(items);
			} catch(e){
				console.log('too many Element?');
				console.log(e);
			}
		}

		window['help'] = () => {
			let msg = `
			Help commend(location in eruda.service)
=============================================================

s(schoolCode, path)

quick switch school code with speific path
schoolCode: 6 digit school code or school id or school name
path: if not set, wiil go to current path, '/' - home screen

==============================================================

u(uid, path)

quick switch user with speific path
uid: uid of the user
path: if not set, wiil go to current path, '/' - home screen

==============================================================

ngc(selector)

get Angular component of the selector
selector: selector like 'left-panel', if empty to get the 
    	  element next to router-outlet

==============================================================

roCall(name, data)

run ro api (ie not work)

==============================================================

jwt(uid)

get the jwt of the uid, if uid is null, return current user

==============================================================

note(listType)

listType: s = schoolcode, u = uid
==============================================================

submission(assignmentName)

assignmentName: 電子書的全名或名的部份
要登入左學生才可執行
==============================================================

listTransformDom(selector)

列出有selector內有用過transform的element
==============================================================
			`;
			console.log(msg);
		};

	}


	fixIeWebview() {
		//still not work
		if (document['documentMode']) { //check if browser is ie
			let ele = document.querySelector('body + *');
			let shadowRoot = ele.shadowRoot;
			if (shadowRoot) {
				let btn = shadowRoot.querySelector('.eruda-icon-tool');
				btn.addEventListener('click', function () { eruda._devTools.toggle(); });
			}
		}
	}

}
