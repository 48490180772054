import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CustomQuillEditorComponent } from './customQuillEditor.component';
import { FormsModule } from '@angular/forms';
import { QuillModule } from 'ngx-quill';

@NgModule({
    imports: [
      CommonModule,
      FontAwesomeModule,
	  FormsModule,
	  QuillModule.forRoot()
    ],
    declarations: [
        CustomQuillEditorComponent
    ],
    exports: [
        CustomQuillEditorComponent
    ],
})
export class CustomQuillEditorModule { }
