import { ChineseNumber } from "./ChineseNumber";
import { EnglishNumberParser } from "./EnglishNumberParser";
import { EnglishNumberSplitter } from "./EnglishNumberSplitter";

export class NumberType{
	static ENGLISH_NUMBER:number = 5;
	static CHINESE_NUMBER:number = 1;
	static ROMAN_NUMBER:number = 2;
	
	static CHINESE_VERSION_NUMBER:number = 3;
	static ROMAN_VERSION_NUMBER:number = 4;
}
export class NumberSplitter
{
	private eSplitter:EnglishNumberSplitter;
	public eNumberParser:EnglishNumberParser;
	public cNumberParser:ChineseNumber;
	
	constructor()
	{
		this.eSplitter = new EnglishNumberSplitter();
		this.eNumberParser = new EnglishNumberParser();
		this.cNumberParser = new ChineseNumber();
	}
	
	public isNumber(text:string):any
	{
		var reg1:RegExp = /^[负負]{0,1}[〇一七万三两九二五亿仟億兩八六十千四百萬零]{1,}([點点][一二三四五六七八九十零两兩〇]{1,}){0,1}$/g;
		var reg2:RegExp = /^[\+\-]{0,1}[0-9]{1,}(\.[0-9]{1,}){0,1}$/g;
		
		var reg3:RegExp = /^[负負]{0,1}[〇一七万三两九二五亿仟億兩八六十千四百萬零]{1,}([點点][一二三四五六七八九十零两兩〇]{1,}){0,}$/g;
		var reg4:RegExp = /^[\+\-]{0,1}[0-9]{1,}(\.[0-9]{1,}){0,}$/g;
		
		
		if (reg1.test(text)  )
		{
			return NumberType.CHINESE_NUMBER;
		} else if (reg2.test(text))
		{
			return NumberType.ROMAN_NUMBER;
		} else if (reg3.test(text))
		{
			return NumberType.CHINESE_VERSION_NUMBER;
		} else if (reg4.test(text))
		{
			return NumberType.ROMAN_VERSION_NUMBER;
		} else if (this.eNumberParser.isNumber(text))
		{
			return NumberType.ENGLISH_NUMBER;
		}
		return 0;
		//return (reg1.test(text)  || reg2.test(text)  );
	}
	
	public split(s:string, converter:Function = null):any[]
	{
		var reg1:RegExp = /[负負]{0,1}[〇一七万三两九二五亿仟億兩八六十千四百萬零]{1,}([點点][一二三四五六七八九十零两兩〇]{1,}){0,}/g;
		var reg2:RegExp = /[\+\-]{0,1}[0-9]{1,}(\.[0-9]{1,}){0,}/g;
		var tmpOutput:any [] = [s];
		
		tmpOutput = this.breakIntoArray(tmpOutput, reg1, "cn", converter);
		tmpOutput = this.breakIntoArray(tmpOutput, reg2, "num", converter);
		// var splitter:EnglishNumberParser = new EnglishNumberParser();
		
		
		var output:any [] = [];
		var len:number = tmpOutput.length;
		for (var i:number = 0; i < len; i++)
		{
			var element:any = tmpOutput[i];
			if (typeof element == 'string')
			{
				var array:any [] = this.eSplitter.split(element);
				array.forEach((e:any , index:number)=>
				{
					output.push(e);
				});
				
			} else {
				output.push(element);
			}
		}
		return output;
	}
	
	public test():void
	{
		var output:any [] = this.split(
			"(+1.23) (-456.78) (78.8.8) abacdef 負一百二十三點六五 end) abacdef 111.222.333333 44.55 end)"
		);
		console.log(output);
	}
	
	public isEnglishNumber(s:string):boolean
	{
		return this.eNumberParser.isNumber(s);
		// return new EnglishNumberParser().isNumber(s);
	}
	
	private breakIntoArray(array:any[] , reg:RegExp, reference:any , converter:Function = null):any []
	{
		var len:number = array.length;
		var output:any [] = [];
		for (var i:number = 0; i < len; i++)
		{
			var s:any = array[i];
			if (typeof s == 'string')
			{
				this.breakPattern(s, reg, output, reference, converter);
			} else {
				output.push(s);
			}
		}
		return output;
	}
	private breakPattern(text:string, reg:RegExp, output:any[] , reference:any = null, converter:Function = null):void
	{
		function exec(s:string, a:number , b:number):void
		{
			var subString:string = text.substring(a, b);
			if (subString){
				output.push(subString);
			}
			if (s) {
				if (converter != null)
				{
					output.push(converter.call(null, reference, s));
				} else {
					output.push(s);
				}
			}
		}
		function extract(match:any ):any
		{
			var maxLen:number = 0;
			var text:string = "";
			for (var i:number = 0; i < match.length; i++)
			{
				var s:string = match[i];
				if (s)
				{
					if (s.length > maxLen)
					{
						maxLen = s.length;
					}
					//len += s.length;
					text += s;
				}
				break;
			}
			return {
				start:match.index,
				end:match.index + maxLen,
				text:text
			};
		}
		var index:number = 0;
		var temp:any ;
		while (temp=  reg.exec(text))
		{
			var o:any =  extract(temp);
			exec(o.text, index, o.start);
			index = o.end;
		}
		exec(null, index, text.length);
	}
	
}