import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../../../../service/data.service";
import {CommonService} from "../../../../../service/common.service";
import {UserInfoHelperService} from "../../../../../service/UserInfoHelper.service";
import {DomSanitizer} from "@angular/platform-browser";
import {WebSocketService} from "../../../../../service/webSocket.service";
import {faTimes} from "@fortawesome/pro-solid-svg-icons";
import {WebLessonService} from "../../services/WebLesson.service";
import {WebLessonSelectBookModalComponent} from "../web-lesson-select-book-modal/web-lesson-select-book-modal.component";

@Component({
	selector: 'select-book-page',
	template: `
		<div class="btn-close" (click)="btn_close_clicked.emit()">
			<fa-icon [icon]="faTimes"></fa-icon>
		</div>
		<ng-container *ngIf="ready">
			
			<div class="main-container">
				<ng-template #user_info>
					<div class="user-info">
						<img [src]="image_path" class="user-profile-image"/>
						<div class="user-display_name">
							<span *ngIf="!web_lesson.is_teacher" class="connection-indicator"
								  [ngClass]="{online: web_lesson.is_connected() && web_lesson.lesson}"
							></span>
							{{ web_lesson.get_student_display_name(datas.userInfo) }}
						</div>
					</div>
				</ng-template>
				
				<ng-container *ngIf="web_lesson.is_teacher">
					<ng-container *ngTemplateOutlet="user_info"></ng-container>
				</ng-container>
				<ng-container *ngIf="!web_lesson.is_teacher">
					<div class="wait-teacher-user-info" style="display: flex;">
						<img class="teacher-profile-image" [ngClass]="{'empty': !web_lesson.teacher}"
							 [src]="web_lesson.teacher?.profile_image_url || empty_teacher_image"
						/>
						<dash-line style="width: 52px; margin: 50px -8px 50px 8px;"></dash-line>
						
						<ng-container *ngTemplateOutlet="user_info"></ng-container>
					</div>
				</ng-container>
				<div class="lesson-title">
					<span *ngIf="web_lesson.is_connected() && web_lesson.lesson">{{ 'web-lesson.connected' | translate }}</span>
					<span *ngIf="!web_lesson.is_connected() || !web_lesson.lesson">{{ 'web-lesson.connecting' | translate }}</span>
					<span *ngIf="web_lesson.lesson">- {{ web_lesson.lesson.title }}</span>
				</div>
				<ng-container *ngIf="web_lesson.is_teacher">
					<div class="btn-select-book" (click)="select_book_modal.open();">
						<img src="./assets/img/web-lesson/open_book.svg"/>
						{{ 'web-lesson.open_ebook' | translate }}
					</div>
					<div class="student-list">
						<div *ngFor="let student of student_list" class="student" [ngClass]="{offline: !student.is_online}">
							<img [src]="student.profile_image_url" class="student-profile-image"/>
							<div class="student-name">
								{{ web_lesson.get_student_display_name(student) }}
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="!web_lesson.is_teacher">
					<div *ngIf="!web_lesson.is_teacher_arrived">
						{{ 'web-lesson.wait_teacher_arrive' | translate }}
					</div>
					<div *ngIf="web_lesson.is_teacher_arrived">
						{{ 'web-lesson.wait_teacher_open_book' | translate }}
					</div>
					<div class="wait-teacher-loading"></div>
				</ng-container>
				
				<web-lesson-select-book-modal
					#select_book_modal
					[data]="select_book_modal_data"
					[fullWidth]="true"
				></web-lesson-select-book-modal>
				<web-lesson-share-list-modal
					*ngIf="share_list_modal.is_show"
					[before_shares]="before_shares"
					[book]="book"
					(cancel)="share_list_modal.close()"
				></web-lesson-share-list-modal>
				<network-check *ngIf="network_check_modal" (close)="network_check_modal = false"></network-check>
			</div>
			
			<div class="network-status">
				<div class="btn-reconnect" (click)="web_lesson.reconnect()">
					{{ 'web-lesson.reconnect' | translate }}
				</div>
				<img src="./assets/ro/app_icons/icon_network.svg" (click)="network_check_modal = true;" style="cursor: pointer;"/>
			</div>
		
		</ng-container>
	`,
	styleUrls: ['./select-book-page.component.scss'],
})

export class SelectBookPageComponent implements OnInit {
	ready = false;
	image_path = null;
	empty_teacher_image = null;
	before_shares = null;
	book = null;
	share_list_modal = {
		is_show: false,
		open: () => {
			this.share_list_modal.is_show = true;
		},
		cancel: () => {
			this.share_list_modal.close();
			this.book = null;
			this.before_shares = null;
		},
		close: () => {
			this.share_list_modal.is_show = false;
		}
	}

	select_book_modal_data: any = {
		singleOnly: true,
		open: () => {
			// this.get_explorer_list();
		},
		close: (selected_items) => {
			console.log(selected_items);
			this.select_book_modal.close();
			if (selected_items && selected_items.length) {
				// this.book_selected.emit(selected_items[0]);
				this.open_book(selected_items[0]);
			}
		}
	};

	network_check_modal = false;

	@Input() student_list = [];
	@Output() btn_close_clicked = new EventEmitter();
	@ViewChild('select_book_modal', {static: false}) select_book_modal: WebLessonSelectBookModalComponent;

	constructor(
		public trans: TranslateService,
		public datas: DataService,
		public coms: CommonService,
		public user_info_helper: UserInfoHelperService,
		private dom_sanitizer: DomSanitizer,
		public wss: WebSocketService,
		public web_lesson: WebLessonService,
	) {
		console.log(this.datas.userInfo)
		this.image_path = this.dom_sanitizer.bypassSecurityTrustResourceUrl(this.user_info_helper.getProfileImgPath(this.datas.userInfo));
		this.empty_teacher_image = this.dom_sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon_no_avatar_dark.svg");
		this.trans.use(this.datas.lang);
	}

	ngOnInit() {
		this.coms.timeout(500).then(() => this.ready = true);
	}


	async open_book(book) {
		console.log(book);
		this.book = book;
		this.before_shares = await this.web_lesson.find_all_lesson_book_share(book);
		if (this.before_shares.length) {
			this.share_list_modal.open();
		} else {
			this.web_lesson.create_new_share(book);
		}
	}

	protected readonly faTimes = faTimes;

}