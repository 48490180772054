import {Component, OnInit, Input, Output, EventEmitter, ViewChild, HostListener, ElementRef} from '@angular/core';
import {NoteLayer} from "./NoteLayer";
import {PlaybackController} from "./PlaybackController";
import {faPause, faPlay} from "@fortawesome/pro-solid-svg-icons";
import {faClose} from "@fortawesome/pro-regular-svg-icons";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
	selector: 'playback-player',
	template: `
		<div class="player">
			<div class="btn-play-pause" (click)="controller.is_playing ? pause() : play()">
				<fa-icon [icon]="controller.is_playing ? faPause : faPlay"></fa-icon>
			</div>
			<div class="progress-bar" #progress_bar 
				 [style]="dom_sanitizer.bypassSecurityTrustStyle('--progress: ' + (controller.current_time / controller.duration * 100) + '%')"
			>
				<div class="current-position" (pointerdown)="start_dragging = true"></div>
			</div>
			<div class="time">
				<span class="current-time">{{ controller.current_time | date: 'mm:ss' }}</span> / {{ controller.duration | date: 'mm:ss' }}
			</div>
			<div class="speed" (click)="next_speed()">
				x{{ controller.speed }}
			</div>
			<div class="btn-close">
				<fa-icon [icon]="faClose" (click)="close()"></fa-icon>
			</div>
		</div>
	`,
	styleUrls: ['./playback-player.component.scss'],
})

export class PlaybackPlayerComponent implements OnInit {

	@Input() public controller: PlaybackController;
	@Input() public note_layer: NoteLayer;
	@Output() public on_close = new EventEmitter<void>();

	speed_list = [1, 2, 5, 10]

	@ViewChild('progress_bar', {static: false}) progress_bar: ElementRef;

	public start_dragging = false;

	constructor(
		public dom_sanitizer: DomSanitizer,
	) {
	}

	ngOnInit() {
		this.note_layer.can_accept_new_input = false;
		this.controller.on_end.subscribe(() => {
			this.note_layer.can_accept_new_input = this.controller.is_end;
		})
		this.play();
	}

	protected readonly faPlay = faPlay;
	protected readonly faPause = faPause;

	async play() {
		console.log('playback_controller', this.controller);
		this.note_layer.onoff_svgs(this.note_layer.writingSVG, false);
		await this.controller.play();
	}

	ngOnDestroy() {
		this.close();
	}

	close() {
		this.note_layer.onoff_svgs(this.note_layer.writingSVG, true);
		this.note_layer.currentWritingSVG.innerHTML = "";
		this.note_layer.can_accept_new_input = true;
		this.on_close.emit();
	}

	pause() {
		this.controller.pause();
	}

	protected readonly faClose = faClose;

	next_speed() {
		let index = this.speed_list.indexOf(this.controller.speed);
		index = (index + 1) % this.speed_list.length;
		this.controller.speed = this.speed_list[index];
	}


	@HostListener('window:pointermove', ['$event'])
	change_time(event) {

		if(!this.start_dragging) {
			return;
		}

		if(event.pointerType === 'mouse' && event.buttons === 0) {
			return;
		}

		let rect = this.progress_bar.nativeElement.getBoundingClientRect();
		let percent = (event.screenX - rect.left) / rect.width;
		percent = Math.max(0, Math.min(1, percent));
		console.log('change_time', event, percent, rect);

		let time = percent * this.controller.duration;
		this.controller.move_to(time);
	}

	@HostListener('window:pointerup', ['$event'])
	stop_dragging(event) {
		this.start_dragging = false;
	}
}