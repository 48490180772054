import { Component, Output, EventEmitter, ViewChild, TemplateRef, ViewContainerRef, EmbeddedViewRef, Input } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/service/common.service';
import { LoadingService } from '../loadingModule/loading.service';

@Component({
    selector: 'preview',
    template: `
<ng-template #ngTemplate>
  <div class="backdrop" [class.show]="_isShowing" [style.zIndex]="_backdropZindex" [ngStyle]="backdropStyle">

    <ng-container *ngIf="_previewType=='image'">
      <div class="preview image">
        <img
          #previewImg
          [class.hide]="_clearLoading"
          [src]="_urlPrefix + _file.url"
          (load)="resetImageSizePosition(previewImg)"
          cdkDrag
          cdkDragBoundary=".preview.image"
          [cdkDragFreeDragPosition]="_imageDragPosition"
          [cdkDragDisabled]="_imageDragDisabled"
          (cdkDragEnded)="_imageDragPosition=$event.source.getFreeDragPosition()"
        >
      </div>
      <div class="imageControl">
        <div class="menuBtn" [style.backgroundImage]="'url(assets/img/preview/btnicon_zoomout_white.svg)'" [pTooltip]="{tc:'縮小',sc:'缩小',en:'Zoom out'}[translate.currentLang]" tooltipPosition="top" (click)="resetImageSizePosition(previewImg, 'out')"></div>
        <div class="menuBtn" [style.backgroundImage]="'url(assets/img/preview/btnicon_zoomall_white.svg)'" [pTooltip]="{tc:'復原',sc:'復原',en:'Reset'}[translate.currentLang]" tooltipPosition="top" (click)="resetImageSizePosition(previewImg)"></div>
        <div class="menuBtn" [style.backgroundImage]="'url(assets/img/preview/btnicon_zoomin_white.svg)'" [pTooltip]="{tc:'放大',sc:'放大',en:'Zoom in'}[translate.currentLang]" tooltipPosition="top" (click)="resetImageSizePosition(previewImg, 'in')"></div>
      </div>
    </ng-container>

    <iframe
      *ngIf="_previewType=='office'"
      class="preview office"
      [src]="_safeUrl"
      frameborder="0"
      (load)="clearLoading()"
    ></iframe>

    <iframe
      *ngIf="_previewType=='pdf'"
      class="preview office"
      [src]="_safeUrl"
      frameborder="0"
      (load)="clearLoading()"
    ></iframe>

	<iframe
		*ngIf="_previewType=='youtube'"
		class="preview youtube"
		[src]="_safeUrl"
		frameborder="0"
		(load)="clearLoading()"
	></iframe>

    <div *ngIf="_previewType=='audio'" class="preview audio">
      <audio
        controls
        autoplay
        [src]="_urlPrefix + _file.url"
        (canplay)="clearLoading()"
      >Your browser does not support the audio element.</audio>
    </div>

    <div *ngIf="_previewType=='video'" class="preview video">
      <video
        controls
        autoplay
        controlsList="nodownload nofullscreen noremoteplayback"
        [src]="_urlPrefix + _file.url"
        (canplay)="clearLoading()"
      >Your browser does not support the video element.</video>
    </div>

    <div *ngIf="_previewType=='other'" class="preview other">
      <div>{{"preview.unableToPreview"|translate}}</div>
    </div>

    <div class="toolBar">
      <div class="left" *ngIf="_file && _file.name">
        <div class="fileIcon" [style.backgroundImage]="getPreviewFileInfo(_file.ext || getFileExt(_file.name)).iconPath"></div>
        <div class="filename">{{_file.name}}</div>
      </div>

      <div class="right">
        <div *ngFor="let btn of _btns" class="menuBtn" [style.backgroundImage]="'url(' + btn.icon + ')'" [ngStyle]="btn.iconStyle" [pTooltip]="btn.title" tooltipPosition="bottom" (click)="btn.callback($event)"></div>
        <div class="menuBtn" *ngIf="showCross" [style.backgroundImage]="'url(assets/img/preview/btnicon_close_white.svg)'" [pTooltip]="{tc:'關閉',sc:'关闭',en:'Close'}[translate.currentLang]" tooltipPosition="bottom" (click)="close()"></div>
      </div>
    </div>
    
    <div *ngIf="!fileIs('first')" class="previousBtn" (click)="changeUrl('previous')"></div>
    <div *ngIf="!fileIs('last')" class="nextBtn" (click)="changeUrl('next')"></div>

  </div>
</ng-template>
    `,
    styleUrls: ['./preview.component.scss']
})

export class PreviewComponent{
  @ViewChild("ngTemplate", {static:true}) ngTemplate:TemplateRef<any>;

  public _urlPrefix = "";
  public _btns:any[] = []; //[ {icon:"assets/img/tool_rename_white.svg" callback:()=>{} } ]
  
  public _file:any = null; //{name:"filename", url:"fileUrl"}
  public _fileList:any[] = [];
  public _isShowing:boolean = false;
  public _clearLoading:Function;
  public _backdropZindex:number = 100;
  private _resolveFn:Function = null;
  private _embeddedViewRef:EmbeddedViewRef<any>;

  public _previewType:string = '';
  public _imageDragPosition:any = {x:0, y:0};
  public _imageDragDisabled:boolean = true;
  public _imageZoomRate:number = 1;
  public _safeUrl:SafeResourceUrl;

  public onFileChanged:Function = ()=>{};

  private previewFileInfos:any[] = [
    { type: 'image', ext: 'jpg', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
    { type: 'image', ext: 'jpeg', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
    { type: 'image', ext: 'png', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
    { type: 'image', ext: 'bmp', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
    { type: 'image', ext: 'gif', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
	{ type: 'image', ext: 'svg', iconPath: 'url(assets/img/fileicon/fileicon2_image.svg)' },
    { type: 'audio', ext: 'mp3', iconPath: 'url(assets/img/fileicon/fileicon2_audio.svg)' },
    { type: 'audio', ext: 'wav', iconPath: 'url(assets/img/fileicon/fileicon2_audio.svg)' },
    { type: 'video', ext: 'mp4', iconPath: 'url(assets/img/fileicon/fileicon2_video.svg)' },
    { type: 'video', ext: 'mov', iconPath: 'url(assets/img/fileicon/fileicon2_video.svg)' },
    { type: 'video', ext: 'webm', iconPath: 'url(assets/img/fileicon/fileicon2_video.svg)' },
    { type: 'pdf', ext: 'pdf', iconPath: 'url(assets/img/fileicon/fileicon2_pdf.svg)' },
    { type: 'office', ext: 'doc', iconPath: 'url(assets/img/fileicon/fileicon2_doc.svg)' },
    { type: 'office', ext: 'docx', iconPath: 'url(assets/img/fileicon/fileicon2_doc.svg)' },
    { type: 'office', ext: 'ppt', iconPath: 'url(assets/img/fileicon/fileicon2_ppt.svg)' },
    { type: 'office', ext: 'pptx', iconPath: 'url(assets/img/fileicon/fileicon2_ppt.svg)' },
    { type: 'office', ext: 'xls', iconPath: 'url(assets/img/fileicon/fileicon2_xls.svg)' },
    { type: 'office', ext: 'xlsx', iconPath: 'url(assets/img/fileicon/fileicon2_xls.svg)' },
	{ type: 'office', ext: 'csv', iconPath: 'url(assets/img/fileicon/fileicon2_xls.svg)' },
	{ type: 'youtube', ext: 'youtube', iconPath: 'url(assets/img/fileicon/icon_youtube_24x18.svg)' },
    { type: 'other', ext: null, iconPath: 'url(assets/img/fileicon/fileicon2_others.svg)' }
  ];

  @Output() click:EventEmitter<any> = new EventEmitter();
  @Input() public parent;
  @Input() public backdropStyle:any = {};
  @Input() public showCross = true;


  constructor(
    private coms:CommonService,
    private viewContainerRef: ViewContainerRef,
    private lds:LoadingService,
    public translate:TranslateService, 
    private sanitizer: DomSanitizer
  ) {}

	public clearLoading():void
	{
		if(this._clearLoading != null)
			this._clearLoading();
	}
  public open():Promise<any> {
    if (this._isShowing) return Promise.reject('alreadyOpened');
    if (!this._file && !this._fileList[0]) return Promise.reject('fileNotSet');
	if (this._file) {
		if (this._fileList.indexOf(this._file)<0) this._fileList = [this._file];
	} else {
		this._file = this._fileList[0];
	}

    return new Promise((resolve:Function, reject:Function)=>{
      this._resolveFn = resolve;

      this.processForDiffType();

      //加到body
      if (!this._embeddedViewRef) this._embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.ngTemplate );
    //   for (let node of this._embeddedViewRef.rootNodes) document.body.appendChild(node);
	  for (let node of this._embeddedViewRef.rootNodes) {
		let parent = this.parent?this.parent : document.body
		parent.appendChild(node);
	  }

      this._backdropZindex = this.coms.getZIndex();
      setTimeout(()=>this._isShowing=true, 100);
    });
  }

  private processForDiffType():void {
    this.setLoading();
    let previewType:string = this.getPreviewFileInfo(this._file.ext || this.getFileExt(this._file.name)).type;
	console.log("previewType",previewType)
	console.log("this._file", this._file)


    if (previewType=='office') {
      let path:string = 'https://view.officeapps.live.com/op/embed.aspx?src=' + this._urlPrefix + this._file.url;
      //let path:string = 'https://docs.google.com/viewer?url=' + this._urlPrefix + this._file.url;
      //let path:string = 'http://docs.google.com/gview?url=' + this._urlPrefix + this._file.url + '&embedded=true';
      this._safeUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(path);
    } else if (previewType=='pdf') {
      this._safeUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(this._urlPrefix + this._file.url);
    } else if (previewType=='youtube') {
		let path:string = '//www.youtube.com/embed/' + this._file.url;
		path = '//ro2.azurewebsites.net/RainbowOne/youtube/?videoID=' + this._file.url;
		this._safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(path);
	} else if (previewType=='other') {
      if (this._clearLoading) this._clearLoading();
    } 

    this._previewType = previewType;
  }

  public close():void {
    if (this._embeddedViewRef && this._embeddedViewRef.rootNodes) {
      for (let node of this._embeddedViewRef.rootNodes) {
        try { document.body.removeChild(node); } catch(err){}
      }
    }
    this._isShowing = false;
    //this._file = null;
    this._previewType = null;
    this._resolveFn();
  }

  public changeUrl(type:string):void {
    let curIndex:number = this._fileList.indexOf(this._file);
    let targetIndex:number;
    if (type=='previous') targetIndex = curIndex - 1;
    if (type=='next') targetIndex = curIndex + 1;
    if (targetIndex < 0) targetIndex = this._fileList.length - 1;
    if (targetIndex >= this._fileList.length) targetIndex = 0;
    this._file = this._fileList[targetIndex];
    if (this.onFileChanged) this.onFileChanged(this._file);
    this.processForDiffType();
  }

  private setLoading():void {
    let cls:Function = this.lds.add();
    // 如果有舊的clearLoading，要先clear掉
    if (this._clearLoading) this._clearLoading();
    let timeoutCloseLoading = ()=>{
        cls();
        if (this._clearLoading==timeoutCloseLoading) this._clearLoading = null;
    }
    this._clearLoading = timeoutCloseLoading;
    setTimeout(()=>timeoutCloseLoading(), 10000);
  }

  public fileIs(type:string='first'):boolean {
    let curIndex:number = this._fileList.indexOf(this._file);
    if (type=='first' && curIndex==0) return true;
    if (type=='last' && curIndex==(this._fileList.length-1)) return true;
    return false;
  }

  public isAllowPreview(filename:string=''):boolean {
    let ext:string = filename.split('.').pop().toLowerCase();
    return (this.getPreviewFileInfo(ext).type != 'other');
  }

  public setFile(orgFile:any):void {
    this._file = orgFile;
	console.log("setFile", this._file);

    //this._file = this.isAllowPreview(orgFile.name) ? orgFile : (this._fileList[0] || null);
	if (this._fileList.length<1 || this._fileList.indexOf(this._file)<0) this._fileList = [this._file];
  }

  public setFileList(orgFileList:any[]):void {
    this._fileList = orgFileList;//.filter((file:any)=>this.isAllowPreview(file.name));
    if (!this._file || this._fileList.indexOf(this._file)<0) this._file = (this._fileList[0] || null);
  }

  public setUrlPrefix(prefix:string):void {
    this._urlPrefix = prefix;
  }

  public setBtns(btns:any[]=[]):void {
    this._btns = btns;
  }

  public getFileExt(filename:string):string {
    let filenameArray:string[] = filename.split('.');
    if (filenameArray.length<2) return '';
    return filenameArray[filenameArray.length-1].toLowerCase();
  }

  public getPreviewFileInfo(ext:string):any {
    ext = ext.toLowerCase();
    return this.previewFileInfos.find((item:any)=>item.ext==ext) || this.previewFileInfos.find((item:any)=>item.ext==null && item.type=='other');
  }

  public resetImageSizePosition(imgEle:HTMLImageElement, type:string='default'):void {
    
    let wrapper:HTMLDivElement = imgEle.parentElement as HTMLDivElement;
    if (type=='default') {
      this._imageZoomRate = Math.min((window.innerWidth - 140)/imgEle.naturalWidth, (window.innerHeight - 140)/imgEle.naturalHeight, 1);
      this._imageDragPosition = {x:0, y:0};
    } else if (type=='in') {
      this._imageZoomRate = this._imageZoomRate * 1.5;
    } else {
      this._imageZoomRate = this._imageZoomRate * 0.5;
    }

    let imgEleNewWidth:number = imgEle.naturalWidth * this._imageZoomRate;
    let imgEleNewHeight:number = imgEle.naturalHeight * this._imageZoomRate;
    let wrapperNewLeft:number = (imgEleNewWidth > window.innerWidth) ? (window.innerWidth - imgEleNewWidth) : ((window.innerWidth - imgEleNewWidth) / 2);
    let wrapperNewTop:number = (imgEleNewHeight > window.innerHeight) ? (window.innerHeight - imgEleNewHeight) : ((window.innerHeight - imgEleNewHeight) / 2);
    let wrapperNewWidth:number = (imgEleNewWidth > window.innerWidth) ? ((imgEleNewWidth - window.innerWidth) * 2 + window.innerWidth) : imgEleNewWidth;
    let wrapperNewHeight:number = (imgEleNewHeight > window.innerHeight) ? ((imgEleNewHeight - window.innerHeight) * 2 + window.innerHeight) : imgEleNewHeight;
    
    this._imageDragDisabled = (imgEleNewWidth < window.innerWidth && imgEleNewHeight < window.innerHeight);
    
    imgEle.style.width = imgEleNewWidth + 'px';
    imgEle.style.left = Math.max((wrapperNewWidth - imgEleNewWidth) / 2, 0) + 'px';
    imgEle.style.top = Math.max((wrapperNewHeight - imgEleNewHeight) / 2, 0) + 'px';

    wrapper.style.left = wrapperNewLeft + 'px';
    wrapper.style.top = wrapperNewTop + 'px';
    wrapper.style.width = wrapperNewWidth + 'px';
    wrapper.style.height = wrapperNewHeight + 'px';

    if (this._clearLoading) this._clearLoading();
  }

  public iframeGetUrl():SafeResourceUrl {
    //https%3A%2F%2Fview.officeapps.live.com%2Fop%2Fembed.aspx%3Fsrc%3Dhttps%3A%2F%2Foka.blob.core.windows.net%2Fmedia%2FroWebProfile%2F2022%2F04%2F11%2Fschool(272)%2Ftime(105417)-sample1.docx
    //let path:string = 'https://view.officeapps.live.com/op/embed.aspx?src=' + this._urlPrefix + this._file.url;
    let path:string = 'https://docs.google.com/viewer?url=' + this._urlPrefix + this._file.url;
    path = encodeURIComponent(path).split('.').join('%2E');
    return this.sanitizer.bypassSecurityTrustResourceUrl(path);
  }

}
