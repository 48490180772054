import {Component, EventEmitter, OnInit, TemplateRef, Output, Input} from '@angular/core';

@Component({
	selector: 'tab-view',
	template: `
		<div class="tabs">
			<ng-container *ngFor="let tab of tabs; let i = index">
				<div
					(click)="current_key = tab.key; tab_changed.emit(tab);"
					class="tab"
					[ngClass]="{active: tab.key === current_key}"
				>
					{{ tab.title || tab.key }}
				</div>
				<div class="divider" *ngIf="i < tabs.length -1"></div>
			</ng-container>
		</div>
	`,
	styles: [`
        :host {
            --active-color: #ffffff;
            --active-bg-color: #985e28;
        }

        .tabs {
            display: flex;
            justify-content: center;
            border-width: 1px;
            border-style: solid;
            border-color: var(--active-bg-color);
            border-radius: 10px;
            overflow: hidden;
        }

        .tab {
            padding: 5px 10px;
            cursor: pointer;
            flex: 1;
            width: 120px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--active-bg-color);

            /*&:not(:last-child) {*/
            /*    border-right: 1px solid var(--active-bg-color);*/
            /*}*/
        }
		
		.divider {
			width: 1px;
			background-color: var(--active-bg-color);
		}

        .tab.active {
            background-color: var(--active-bg-color);
            color: var(--active-color);
        }
	`]
})
export class TabViewComponent implements OnInit {

	@Input() tabs: TabViewComponent_Tab[] = [];
	@Input() current_key = null;

	@Output() tab_changed = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	ngOnChanges() {
		if (!this.current_key) {
			if (this.tabs && this.tabs.length) {
				this.current_key = this.tabs[0].key;
			} else {
				this.current_key = null;
			}
		}
	}
}

interface TabViewComponent_Tab {
	key: string;
	title?: string | any;
}