import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { RoService } from 'src/app/service/ro.service';
import { LoadingService } from '../loadingModule/loading.service';
import { AlertService } from 'src/app/service/alert.service';
import { InlineSvgService } from '../inlineSvgModule/inlineSvg.service';
import { faBookBlank } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'course-book-repeat-open-modal',
	template: `
		<div class="book-repeat-open-modal" *ngIf="ready">
			<svg-icon class="close" name="btn_close_gray" (click)="cancelModal()"></svg-icon>
			<div class="photo {{data.book_type?data.book_type:''}} {{data.type?data.type:''}}" [class.no-cover]="!photo" 
				[style.backgroundImage]="photo">
				<fa-icon [icon]="faIcon" *ngIf="faIcon"></fa-icon>	
			</div>
			<div class="name">
				<span>{{data.name}}</span>
			</div>
			<div class="message">{{ customMessage }}</div>
			<div class="choice" *ngFor="let choice of choices" (click)="choiceClick(choice)">
				<div class="choice__topic {{datas.lang}}">{{choice.topic|translate}}</div>
				<div class="choice__text {{datas.lang}}">{{choice.text|translate}}</div>
			</div>
		</div>
  `
	, styleUrls: ['./courseBookRepeatOpen.modal.scss'],
})
export class CourseBookRepeatOpenModal implements OnInit {
	@Input() data: any;
	@Output() openBook = new EventEmitter<any>();
	@Output() close = new EventEmitter<any>();
	public message: { msg: string, obj: any } = { msg: 'open-book.course-repeat-open-message', obj: {} };
	public choices;
	public photo;
	public ready = false;
	public faIcon;
	public faBookBlank = faBookBlank;
	public customMessage = '';
	constructor(private translate: TranslateService, private sanitizer: DomSanitizer, public datas: DataService, private ro: RoService, private lds: LoadingService, private als: AlertService, private inlineSvg: InlineSvgService, public trans: TranslateService) {
	}

	ngOnInit() {
		if (this.datas.appId2 == 'smartchatai'){
			this.message.msg = 'open-book.course-repeat-open-message-exercise';
		}
		this.inlineSvg.regSvgFiles([
			{url: 'btn_close_gray.svg', name: 'btn_close_gray'},
			]).then(()=>{
			if (this.data.cover){
				this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + this.data.cover + "')");
				this.ready = true;
			} else {
				this.datas.post('ROBookShelf.get_single_book_cover2', [this.data.bsid, this.data.book_id]).subscribe((res: any) => {
					if (res.code) {
						if (res.url){
							this.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + res.url + "')");
						} else {
							if (this.data.book_type == 'book' || this.data.type == 'book'){
								this.faIcon = this.faBookBlank;
							}
						}
						this.ready = true;
					}
				});
			}
		});
		this.initChoices(this.data);
	}

	initChoices(data) {
		if (data.action == 'course-book-repeat-open'){
			if (data.open_mode == 'start-over') {
				this.choices = [
					{ id: 1, topic: 'open-book.course-repeat-open-1-1', text: 'open-book.course-repeat-open-1-2' },
					{ id: 2, topic: 'open-book.course-repeat-open-2-1', text: 'open-book.course-repeat-open-2-2' }
				];
			} else {
				const course_mode = this.data.mode ? this.data.mode : '';
				const activeDuration = this.data.your_active_duration ? parseInt(this.data.your_active_duration) : 0;
				if (course_mode == 'order' && activeDuration >= this.data.time_limit && this.data.time_limit != null) {
					this.message = { msg: 'open-book.course-repeat-open-message-2', obj: { minute: this.data.time_limit / 60 } };
					this.choices = [
						{ id: 1, topic: 'open-book.course-repeat-open-1-1', text: 'open-book.course-repeat-open-1-2' },//查看初次提交或改正後之作答，但不可修改答案。
						{ id: 2, topic: 'open-book.course-repeat-open-2-1', text: 'open-book.course-repeat-open-2-2' }//重新作答所有題目，過往提交的答案會被清除。
					];
					if (this.datas.appId == 'smartchatai'){
						this.choices[1].text = 'open-book.course-repeat-open-2-2-exercise';
					}
				} else {
					this.choices = [
						{ id: 1, topic: 'open-book.course-repeat-open-1-1', text: 'open-book.course-repeat-open-1-2' },//查看初次提交或改正後之作答，但不可修改答案。
						{ id: 3, topic: 'open-book.course-repeat-open-3-1', text: 'open-book.course-repeat-open-3-2' }//延續已提交的答案及繼續作答。
					];
				}
			}
		} else { 
			this.choices = [
				{ id: 1, topic: 'open-book.finish-1-1', text: 'open-book.finish-1-2' },//查看初次提交或改正後之作答，但不可修改答案。
				{ id: 4, topic: 'open-book.finish-2-1', text: 'open-book.finish-2-2' },//重新作答所有題目，答案不會傳給老師。
			];
			if (this.datas.appId == 'smartchatai'){
				this.choices[1].text = 'open-book.finish-2-2';
			}
		}
	}

	choiceClick(choice) {
		if (choice.id == 1) { //read only
			this.openBook.emit({ mode: 'read-index-1' });
		} else if (choice.id == 2) { //clear answer then read
			this.writeReadLog();
			this.readStart();
		} else if (choice.id == 4){
			this.writeReadLog();
			this.openBook.emit({ mode: 'clean-redo' });
		} else { //normal open
			this.writeReadLog();
			this.openBook.emit();
		}
	}

	readStart() {
		let shareId = this.data.bsid ? this.data.bsid : 0;
		let bookId = this.data.bid ? this.data.bid : 0;
		bookId = bookId?bookId:this.data.book_id;
		bookId = bookId?bookId:this.data.bookId;
		this.lds.add('clear_my_book_answer');
		this.datas.post('ROBookShare.clear_my_book_answer', [shareId, bookId]).subscribe((res: any) => {
			this.lds.remove('clear_my_book_answer');
			if (res.code !== null) {
				this.openBook.emit();
			}
		});
	}

	writeReadLog() {
		this.datas.post('ROBookshelfKen.logSectionVisit', ['read']).subscribe();
	}

	public cancelModal() {
		this.close.emit();
	}


}