import {Component, OnInit} from '@angular/core';

@Component({
	selector: 'lock-screen-icon',
	template: `
		<div class="lock-screen-icon">
			<img class="background" src="./assets/img/web-lesson/LockScreen/lock_screen_icon_bg.svg" />
			<img class="icon" src="./assets/img/web-lesson/LockScreen/lock_screen_icon.svg" />
		</div>
	`,
	styleUrls: ['./lock-screen-icon.component.scss'],
})

export class LockScreenIconComponent implements OnInit {
	constructor() {
	}

	ngOnInit() {
	}
}