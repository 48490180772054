import { Component, Input,HostListener, OnInit } from '@angular/core';
import { RoService } from 'src/app/service/ro.service';
import { StorageService } from '../service/storage.service';
import { SettingService } from 'src/app/service/setting.service';
import { DataService } from 'src/app/service/data.service';

@Component({
	selector: 'detail',
	templateUrl: './detail.component.html',
	styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit{
	public buttonX = -1;
	public buttonY = -1;
	public showingButton = false;
	@Input() public modData;
	@HostListener('click', ['$event']) compClick($event){
        setTimeout(()=>{
            if (this.showingButton == false){
                this.buttonX = this.buttonY = -1;
            }
        },100);
    }
	constructor(public storage: StorageService, private ro: RoService, private settings: SettingService, public datas: DataService, public setting: SettingService) {

	}

	ngOnInit(): void {
		const chapters = this.storage.chapters;
		chapters.forEach((ch, i)=>{
			if (ch.components){
				ch.components.forEach((comp, j)=>{
					if (i == 0){
						comp.gridClass = '';
					} else {
						let columnUnit = j;
						const hasTop = chapters[i - 1].components[columnUnit] != undefined;
						comp.gridClass = hasTop?'a':'topBorder';
					}
				});
			}
		});
	}

	public checkTopBorder(x, y) {
		if (x == 0 || y > this.storage.maxGrid){
			return false;
		}
		return this.storage.chapters[x - 1].components.length - 1 < y;
	}

	public showButton(x, y) {
		this.buttonX = x;
		this.buttonY = y;
		this.showingButton = true;
		setTimeout(()=>{ this.showingButton = false}, 500);
	}

	public hideButton() {
		// this.buttonX = this.buttonY = -1;
	}

	public go(grid, chapter) {
		const modData = this.modData;
		const data = {
			tag: modData.tagId,
			chapter: chapter.id,
			pid: grid.pid,
			uid: modData.uid,
			bsid: modData.bsid,
			bid: modData.bid,
			index: 1
		};
		const webROBookPreviewEnabled = this.settings.schoolSettings && this.settings.schoolSettings.WEB_ROBOOK_PREVIEW_ENABLED == 1?true:false;
		let pageNoForAllChapter = 1;
		const chapters = this.storage.chapters;
		all:
		for(let i = 0; i < chapters.length; i++){
			if (chapters[i].components){
				for(let j = 0; j < chapters[i].components.length; j++){
					const comp = chapters[i].components[j];
					if (comp.id == grid.id && comp.pageNumber){
						pageNoForAllChapter = comp.pageNumber;
						break all;
					}
				}
			}
		}
		const api = modData.role > 2 && modData.role != 5 && modData.role != 6 ? 'viewStudentResult' : 'viewMyResult';
		if (modData.bookType == 'assessment' || modData.bookType == 'pdf-paper' || (webROBookPreviewEnabled && this.datas.isInWeb) ) {
			let url:any = location.href.replace(/roWeb\/.*/, 'roWeb/bookViewer/');
			if (location.port != ''){
				url = location.protocol + '//' + location.host + '/bookViewer/';
			}
			url = url + modData.bsid + '/' + modData.bid;
			if (modData.role > 2 && modData.role != 5 && modData.role != 6) {
				url = url + '/0/scoring/' + modData.uid + '/' + pageNoForAllChapter;
			} else {
				url = url + '/0/review' + '/' + pageNoForAllChapter;
			}
			if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb){
				window.open(url, '_blank');
			} else {
				location.href = url;
			}
			return;
		} else if (this.storage.fromAppModal) {
			let pageNumber = grid.pageNumber;
			setTimeout(()=>{window.location.hash = `ro/navigate/${pageNumber}`;},10);
		} else if (localStorage.getItem('in-web') == '1' || this.datas.isInWeb) {
			let protocol = this.setting.getOpenAppProtocol(this.storage.appId);
			let href = protocol + 'api.openknowledge.hk/RO/external/?action=view_student_result&bsid=' + data.bsid + '&docID=' + data.chapter + '&uid=' + data.uid + '&pid=' + data.pid + '&bid=' + data.bid + '&index=' + data.index;
			window.location.href = href;
			setTimeout(() => {
				if (document.hasFocus()) {
					this.storage.callOpenBookModal.emit({ href: href });
				}
			}, 1000);
		} else {
			this.ro.request(api, data);
		}
		console.log('api: called');
		this.hideButton();
	}

}

