import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataService} from "../../../../../service/data.service";
import {TranslateService} from "@ngx-translate/core";
import {DomSanitizer} from '@angular/platform-browser';
import {faTimes} from "@fortawesome/pro-light-svg-icons";
import {WebLessonService} from "../../services/WebLesson.service";

@Component({
	selector: 'web-lesson-share-list-modal',
	template: `
		<div class="modal" *ngIf="book && before_shares">
			<div class="btn-close" (click)="cancel.emit()">
				<fa-icon [icon]="faTimes"></fa-icon>
			</div>
			<div [innerHTML]="title"></div>
			<div style="display: flex; height: 380px; align-items: stretch; margin-top: 40px;">
				<div class="new-share-section">
					<img src="./assets/img/web-lesson/new_share_girl_sit_on_laptop.svg"/>
					<div style="flex: 1;"></div>
					<div *ngIf="!is_in_lesson" class="btn btn-new-share" (click)="create_new_share()">
						{{ 'web-lesson.share_list_modal.create_new_record' | translate }}
					</div>
					<div *ngIf="is_in_lesson" class="btn btn-lesson-continue" (click)="lesson_continue()">
						{{ 'web-lesson.share_list_modal.lesson_continue' | translate }}
					</div>
					<div class="hint-text">
						{{ 'web-lesson.share_list_modal.student_submit_in_new_record' | translate }}
					</div>
				</div>
				<div style="width: 60px; display: flex; align-items: center; justify-content: center;">
					{{ 'web-lesson.share_list_modal.or' | translate }}
				</div>
				<div class="old-share-section">
					<img src="./assets/img/web-lesson/old_share_icon.svg" style="width: 40px; align-self: center;"/>
					<div class="old-share-list">
						<div *ngFor="let share of before_shares" class="old-share-list-item"
							 [ngClass]="{selected: share === selected_share}"
							 (click)="selected_share = share"
						>
							<span>{{ share.display_datetime }}</span>
							<span>{{ ("web-lesson.share_list_modal.share_type_" + share.type.toLowerCase()) | translate }}</span>
						</div>
					</div>
					<div class="btn outline btn-load-share"
						 (click)="load_share()"
					>
						{{ 'web-lesson.share_list_modal.load_old_record' | translate }}
					</div>
					<div class="hint-text" style="align-self: center;">
						{{ 'web-lesson.share_list_modal.student_submit_in_old_record' | translate }}
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['../../common.scss', './web-lesson-share-list-modal.component.scss']
})

export class WebLessonShareListModalComponent implements OnInit {

	@Input() book = null;
	@Input() before_shares = [];
	@Output() cancel = new EventEmitter();
	@Input() is_in_lesson = false;
	title = null;
	selected_share = null;

	constructor(
		public datas: DataService,
		public trans: TranslateService,
		public dom_sanitizer: DomSanitizer,
		public web_lesson: WebLessonService,
	) {
		this.trans.use(this.datas.lang).subscribe(() => {
			this.update_title();
		});
	}

	ngOnInit() {
	}

	ngOnChanges() {
		this.update_title();
		if (!this.selected_share && this.before_shares.length) {
			this.selected_share = this.before_shares[0];
		}
	}

	update_title() {
		if (!this.book) {
			return;
		}
		this.title = this.dom_sanitizer.bypassSecurityTrustHtml(this.trans.instant('web-lesson.share_list_modal.title', {book: this.book.title}))
	}

	protected readonly faTimes = faTimes;

	async create_new_share() {
		let book = this.book;

		this.web_lesson.create_new_share(book);
	}

	async lesson_continue() {
		console.log('lesson continue');
		let book = this.book;

		this.web_lesson.create_new_share(book);
		this.cancel.emit();
	}

	async load_share() {
		this.web_lesson.share = this.selected_share;
		this.web_lesson.share_id = this.selected_share.id;
		this.web_lesson.add_session_book(this.book);
	}
}