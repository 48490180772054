import { Injectable } from '@angular/core';
import { ObjectUtils } from 'src/app/common/ObjectUtils';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AssessmentService {
	public componentsPanel:any = {
		"QBFillingBlank":[
			{
				"title":"INPUT",// 輸入
				"valName":"blank.type",
				"type":"options",
				"data":[
					{"value":"T","key":"TEXT"}, //文字
					{"value":"keyword","key":"KEYWORD"} //關鍵字
					//{"value":"hw-box","key":"HAND_WRITING_CHI"}, //手寫(短格)
					//{"value":"hw-full","key":"HAND_WRITING_FULL"}, //手寫(長格)
					//{"value":"R","key":"NUMBER"}, //數字
					//{"value":"R2","key":"NUMBER_TEXT"}, //數字(完全一樣)
					//{"value":"eq","key":"EQUATION"}, //數式
				]
			},
			{
				"title":"CASE",// 區分大小寫
				"valName":"blank.caseSensitive",
				"type":"switch"
			},
			{
				"title":"SYMBOL",// 區分全半形
				"valName":"blank.symbolSensitive",
				"type":"switch"
			},
			{
				"title":"showPaste",// 顯示貼上
				"valName":"blank.showPaste",
				"type":"switch"
			},
		],
		"QBShortAnswer":[
			{
				"title":"MODE",// 模式
				"valName":"context.type",
				"type":"options",
				"data":[
					{"value":"exact","key":"EXACT"}, //完全一致
					{"value":"keyword","key":"KEYWORD"} //關鍵字
					//{"value":"hw-box","key":"HAND_WRITING_CHI"}, //手寫(短格)
					//{"value":"hw-full","key":"HAND_WRITING_FULL"}, //手寫(長格)
					//{"value":"free","key":"FREE"}, //自由
					//{"value":"number","key":"NUMBER"}, //數字
					//{"value":"exact_number","key":"NUMBER_TEXT"}, //數字(完全一樣)
					//{"value":"equation","key":"EQUATION"}, //數式
					//{"value":"multiple","key":"MULTIPLE"}, //多個答案
				]
			},
			{
				"title":"CASE",// 區分大小寫
				"valName":"context.caseSensitive",
				"type":"switch"
			},
			{
				"title":"SYMBOL",// 區分全半形
				"valName":"blank.symbolSensitive",
				"type":"switch"
			},
		],
		"QBToggleOptions":[
			{
				"title":"INPUT",// 輸入
				"valName":"blank.type",
				"type":"options",
				"data":[
					{"value":"v","key":"VERTICAL"}, //直
					{"value":"h","key":"HORIZONTAL"} //橫
				]
			}
		],
		"QBTrueFalse":[
			{
				"title":"SHOW",
				"valName":"ui",
				"type":"options",
				"data":[
					{"value":"chi","key":"TF_CHI"},
					{"value":"en","key":"TF_EN"},
					{"value":"sym","key":"TF_SYM"},
				]
			}
		]
	};

	public componentsCommonDefaultSetting:any = {
		"question":"請輸入題目",
		"level":0,
		"remark":""
	};

	public componentsDefaultSetting:any = {
		"QBInfoBlock":{"question":"請輸入題目資訊"},
		//"QBRecorder":{"question":"請輸入題目"}, 
		//"QBTakePhoto":{"question":"請輸入題目"}, 
		//"QBLongQuestion":{"question":"請輸入題目"}, 

		"QBTextMC":{
			"question":"請輸入題目",
			"totalOptions":"2",
			"opt1":"答案1",
			"opt2":"答案2",
			"multiSelect":"0",
			"answer":"1"
		},
		
		"QBFillingBlank":{
			"question":"我有一個[蘋果]。",
			"blank":{
				"type":"T",
				"caseSensitive":"0",
				"symbolSensitive":"0",
				"showPaste":"1"
			}
		},
		"QBShortAnswer":{
			"question":"我有一個",
			"answer":"蘋果",
			"context":{
				"answerPadding":5,
				"displayType":"color_bg",
				"color":16117210,
				"answerSpacing":6.4,
				"fontOffset":0,
				"height":43,
				"maxChars":100,
				"type":"keyword",
				"answer":{"y":49.4,"height":10},
				"leading":20,
				"letterSpacing":0,
				"maxWidth":84,
				"fontSize":32,
				"answerAlign":"left",
				"lineHeight":38.4,
				"showQuestionTitle":true,
				"answerTextColor":0,
				"multiLine":1,
				"fontFamily":"Noto Sans T Chinese Regular",
				"answerIntenting":0,
				"answerHeight":42,
				"answerFontSize":32,
				"caseSensitive":"0",
				"symbolSensitive":"0",
			}
		},
		"QBToggleOptions":{
			"question":"你好[#嗎/媽]?",
			"blank":{
				"bg":"solid",
				"type":"h"
			}
		},
		"QBTrueFalse":{
			"question":"請輸入題目",
			"answer":"1",
			"ui":"chi",
			"totalOptions":2
		}
	};
	
	constructor(public translate:TranslateService){
	}

	public initPanelValue():void {
		for(var key in this.componentsPanel) {
			var itms:any[] = this.componentsPanel[key];
			itms.forEach(itm=>{
				if(itm.type == "options") {
					itm.data.forEach(d=>{
						d.label = this.translate.instant("ro.components.panel."+d.key);
					});
				}
			});
		}
	}

	// 功能1: 修正舊有題目缺少的新參數
	// 功能2: 新題目設定返預設值
	public fillComponentDefaultValue(component:any, overwrite:boolean = false):void {
		if(this.componentsDefaultSetting.hasOwnProperty(component.type)) {
			ObjectUtils.fillObjectDefaultValue(component, this.componentsDefaultSetting[component.type], overwrite);
		} else {
			ObjectUtils.fillObjectDefaultValue(component, this.componentsCommonDefaultSetting, overwrite);
		}
	}

	public saveComponentPanelValueName(component:any):void {
		if(this.componentsPanel.hasOwnProperty(component.type)) {
			var itms:any[] = this.componentsPanel[component.type];
			if(!component._)
				component._ = {};
			itms.forEach(itm=>{
				var val:any = ObjectUtils.getObjectValueByPath(component, itm.valName);
				if(itm.type == "options") {
					var d:any = itm.data.find(d => d.value == val);
					if(d)
						component._[itm.valName] = d.label;

				} else if(itm.type == "switch") {
					component._[itm.valName] = this.translate.instant(val==1 ? 'ro.components.panel.YES' : 'ro.components.panel.NO');

				}
			});
		}
	}
}