import { Component, HostListener, ViewChild, Input, forwardRef, ElementRef, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { ThemeService } from 'src/app/service/theme.service';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { DateTimePicker3SelectDateComponent } from './dateTimePicker3SelectDate.component';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/service/data.service';
import * as moment from 'moment';

@Component({
	selector: 'dateTimePicker3',
	templateUrl: 'dateTimePicker3.component.html',
	styleUrls: ['./dateTimePicker3.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DateTimePicker3Component),
			multi: true
		}
	  ]
})

export class DateTimePicker3Component implements ControlValueAccessor, OnChanges, OnInit {
	@HostListener('dblclick', []) onDblClick() { console.log(this); }
	@ViewChild("bubbleBox2", { static: true }) bubbleBox2: BubbleBox2Component;
	@ViewChild("dateTimePickerContainer", { static: true }) dateTimePickerContainer: ElementRef<HTMLDivElement>;
	@ViewChild("dateTimePickerSelectDateForStartDate", { static: false }) dateTimePickerSelectDateForStartDate: DateTimePicker3SelectDateComponent;
	@ViewChild("dateTimePickerSelectDateForEndDate", { static: false }) dateTimePickerSelectDateForEndDate: DateTimePicker3SelectDateComponent;

	// 「清除資料」Button
	@Input() public clearBtn: boolean = false;
	// 「沒有日期」Button
	@Input() public noDateBtn: boolean = false;
	// 只顯示單一年份選擇
	@Input() public singleYear: boolean = false;
	// 只顯示單一月份選擇
	@Input() public singleMonth: boolean = false;
	// 只顯示單一日期選擇
	@Input() public singleDate: boolean = false;
	// 只顯示單一時間選擇
	@Input() public singleTime: boolean = false;
	// 正在操作的資料格
	@Input() public targetField: string = 'startDate';
	// 顯示 全天 checkbox
	@Input() public showWholeDayOption: boolean = true;
	// 顯示 endDateTime checkbox
	@Input() public showEndDateTimeOption: boolean = true;
	// 可pick日期
	@Input() public enablePickDate: boolean = true;
	// 可pick時間
	@Input() public enablePickTime: boolean = true;
	// 只可選一個日期的某時段至某時段
	@Input() public inOneDayOnly: boolean = false;
	// 顯示預設選項
	@Input() public showPresetOptions:boolean = false;
	// 預設的預設選項
	@Input() public presetItems:any[] = [];
	// 顯示 customMinutes checkbox
	@Input() public showCustomMinutesOption: boolean = false;
	// 使用模式
	@Input() public pickerMode:string = "dateTime"; //schoolYear, schoolSemester, schoolPeriod
	// 只可選的日期 e.g.: [ {start:DateObj, end:DateObj}, {start:DateObj, end:DateObj} ]
	@Input() public availableDates:any[] = null;
	// 不可選的日期 e.g.: [ {start:DateObj, end:DateObj}, {start:DateObj, end:DateObj} ]
	@Input() public unavailableDates:any[] = null;
	// 不可選的學期id e.g.: [ 1, 34, 48 ]
	@Input() public unavailableSemesterIds:number[] = null;
	// 記data 在localStorage的key
	@Input() public localStorageKey:string;
	// 輸出資料
	@Input() public position = 'auto';
	@Input() public skipLoadApi = false;
	@Input() public allowConfirmWithoutValueChanged = false; //就算無改過都可以save
	@Input() public allowMultiple = false; // 年度時可以多選
	@Input() public isMultiple = false; // 當前是否多選
	@Input() public autoAddCustomPresetItem = true; // 是否自動加入custom preset item
	@Output() confirmEmit = new EventEmitter<any>();
	@Output() closeEmit = new EventEmitter<any>();
	
	public forField:string = this.targetField;
	
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	// 資料暫存
	public tempObj:any = this.genNewObj();
	private sourceObj:any = {};
	private resolveFn:Function = ()=>{};
	private orgOptions:any = {};
	private customPresetItem:any = {};

	public isValueChanged:boolean = false;

	public years:any[] = [];
	public selectedYear:any = null;
	public showCurrentYearOnly = false; //for semester and period only
	//---------------------------------------------------------------------------------------------
	constructor(public thms: ThemeService, public eleRef: ElementRef, public translate:TranslateService, public datas:DataService) {
		thms.getThemeJson("dateTimePicker3Module.json").then((styleObj: any) => {
			thms.applyStyleObj(styleObj, this.dateTimePickerContainer.nativeElement);
		});
	}

	ngOnInit(): void {
		if (!this.skipLoadApi){
			this.getYearAndTimeApi();
		}
		this.genPresetItems();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.pickerMode) {
			if (changes.pickerMode.currentValue) {
				let pickerMode:string = changes.pickerMode.currentValue
				if (['schoolYear', 'schoolSemester', 'schoolPeriod'].indexOf(pickerMode) > -1 && this.years.length<1) {
					this.getYearAndTimeApi();
				}
			}
		}
	}
    //-implements ControlValueAccessor---------------------------------------------------------------
    private onChange = (v:any) => {};
    private onTouched = () => {};
    public isDisabled:boolean = false;
	writeValue(obj: any): void {
		this.sourceObj = obj;
		if (this.localStorageKey){
			this.sourceObj = this.readLocalStorage();
		}
		this.setObjValue(this.sourceObj, this.tempObj);
	}
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
      this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
      this.isDisabled = isDisabled;
    }
	//---------------------------------------------------------------------------------------------
	private genPresetItems():void {
		this.customPresetItem = {
			key:'custom',
			label:{tc:'自訂',sc:'自订',en:'Custom'}[this.translate.currentLang],
			option: ()=>Object.assign({}, this.orgOptions, {
				value: Object.assign({}, this.tempObj, {presetKey:'custom'})
			})
		};

		let now:number = +new Date();
		let getOption:Function = (key:string):any => {

			return {
				clearBtn: false,
				noDateBtn: false,
				singleDate: false,
				singleTime: false,
				targetField: 'startDate',
				showWholeDayOption: false,
				showEndDateTimeOption: false,
				enablePickDate: true,
				enablePickTime: true,
				inOneDayOnly: false,
				showCustomMinutes: false,
				pickerMode: 'dateTime',
				value: {
					startDateTime: new Date(now - (1000 * 60 * 60 * 24 * {
						last7days: 7,
						last14days: 14,
						last30days: 30,
						last2months: 60,
						last3months: 90
					}[key])),
					needEndTime: true,
					customMinutes: false,
					endDateTime: new Date(now),
					presetKey: key
				}
			}
		}
		this.presetItems = this.presetItems[0] ? this.presetItems : [
			{ key:'last7days', label:{tc:'最近7天',sc:'最近7天',en:'最近7天'}[this.translate.currentLang], option:getOption('last7days') },
			{ key:'last14days', label:{tc:'最近14天',sc:'最近14天',en:'最近14天'}[this.translate.currentLang], option:getOption('last14days') },
			{ key:'last30days', label:{tc:'最近30天',sc:'最近30天',en:'最近30天'}[this.translate.currentLang], option:getOption('last30days') },
			{ key:'last2months', label:{tc:'最近2個月',sc:'最近2個月',en:'最近2個月'}[this.translate.currentLang], option:getOption('last2months') },
			{ key:'last3months', label:{tc:'最近3個月',sc:'最近3個月',en:'最近3個月'}[this.translate.currentLang], option:getOption('last3months') },
			{ key:'schoolYear', label:{tc:'學年',sc:'學年',en:'學年'}[this.translate.currentLang], option:{ pickerMode: "schoolYear", value:{presetKey: 'schoolYear'} } },
			{ key:'schoolSemester', label:{tc:'學期',sc:'學期',en:'學期'}[this.translate.currentLang], option:{ pickerMode: "schoolSemester", value:{presetKey: 'schoolSemester'} } },
			{ key:'schoolPeriod', label:{tc:'單元時段',sc:'單元時段',en:'單元時段'}[this.translate.currentLang], option:{ pickerMode: "schoolPeriod", value:{presetKey: 'schoolPeriod'} } },
			this.customPresetItem,
		];
	}

	//---------------------------------------------------------------------------------------------

	private genNewObj():any {
		return {
			startDateTime: this.cloneAndFixMinute(new Date()),
			endDateTime: this.cloneAndFixMinute(new Date()),
			needEndTime: false,
			isWholeDay: false,
			isNoDate: false, //isNoDate代表有輸入，但表明沒有日期(與沒有輸入不同)
			presetKey: this.pickerMode=='dateTime' ? 'custom' : this.pickerMode,
			selectedOption: null,
			selectedOptionsForMultiple: []
		};
	}

	//---------------------------------------------------------------------------------------------

	private setObjValue(fromObj:any, toObj:any, isFromOpenCall:boolean = false):void {
		if (!fromObj) fromObj = this.genNewObj();
		let mode:string = isFromOpenCall ?
			fromObj.presetKey || this.pickerMode : //如果是打開popUp時，open()還未套用option的設定，按照presetKey
			(this.showPresetOptions ? fromObj.presetKey : this.pickerMode); //如果是套用option時，則按showPresetOptions決定是否按照presetKey
		//(this.showPresetOptions ? fromObj.presetKey : this.pickerMode);//fromObj.presetKey || this.pickerMode;//(this.showPresetOptions ? fromObj.presetKey : this.pickerMode);
		
		toObj.customMinutes = fromObj.customMinutes || false;
		if (['schoolYear', 'schoolSemester', 'schoolPeriod'].indexOf(mode) > -1) {
			toObj.startDateTime = fromObj.startDateTime ? this.cloneAndFixMinute(fromObj.startDateTime) : null;
			toObj.needEndTime = true;
		} else {
			toObj.startDateTime = this.cloneAndFixMinute(fromObj.startDateTime || new Date());
			toObj.needEndTime = fromObj.needEndTime || false;
		}
		toObj.endDateTime = toObj.needEndTime ? (this.cloneAndFixMinute(fromObj.endDateTime || new Date())) : null;
		toObj.isWholeDay = fromObj.isWholeDay || false;
		toObj.isNoDate = fromObj.isNoDate || false;
		toObj.presetKey = fromObj.presetKey || 'custom';

		if (fromObj.selectedOption) {
			let option:any = null;
			if (mode == 'schoolYear') {
				option = this.years.find((y:any)=>y.id==fromObj.selectedOption.id);
			} else if (mode == 'schoolSemester') {
				for (let year of this.years) {
					let found:any = year.semesters.find((s:any)=>s.id==fromObj.selectedOption.id);
					if (found){
						option = found;
						option['year_title'] = year.title
					} 
					
				}
			} else if (mode == 'schoolPeriod') {
				for (let year of this.years) {
					let found:any = year.periods.find((p:any)=>p.id==fromObj.selectedOption.id);
					if (found){
						option = found;
						option['year_title'] = year.title
					} 
				}
			}
			//let option:any = this.getOptions().find((option:any)=>option.id==fromObj.selectedOption.id);
			this.setSelectedOption(option, false, fromObj.presetKey);
			toObj.selectedOption = option;
		} else {
			toObj.selectedOption = null;
		}

		if (this.allowMultiple && fromObj.selectedOptionsForMultiple){
			toObj.selectedOptionsForMultiple = [ ...fromObj.selectedOptionsForMultiple ];
		}
		
	}

	//---------------------------------------------------------------------------------------------

	private applyOption(option:any={}, isFromOpenCall:boolean = false):void {
		//套用設定 **options.value是sourceObj
		for (let key in option) {
			if (key!='value' && key!='sourceObj' && this.hasOwnProperty(key)) this[key] = option[key]; //如果不是value或sourceObj，直接套用
			if ((key=='value' || key=='sourceObj') && !isFromOpenCall) { //如果是value或sourceObj，而又不是從open()，才套用。(open()內自己預先執行了setObjValue，在此不用再行)
				this.setObjValue(option[key], this.tempObj);
				/*if (option.pickerMode == 'dateTime') {
					this.setObjValue(option[key], this.tempObj);
				} else if (option[key].presetKey) {
					this.tempObj.presetKey = option[key].presetKey
				}*/
				//((key=='value' || key=='sourceObj') && option.pickerMode == 'dateTime' ) this.setObjValue(option[key], this.tempObj); //this.writeValue((options.value || options.sourceObj));
			}
		}

		//配置設定因素
		//打開時不會是noDate，按下沒有日期才設定
		this.tempObj.isNoDate = false;
		//如果是全日，或不可選時間，要選預設選日期格
		if (this.tempObj.isWholeDay || !this.enablePickTime) {
			//如果不可選時間，必是全天
			if (!this.enablePickTime) {
				this.tempObj.isWholeDay = true;	
				this.showWholeDayOption = false;
			}
			this.forField = { "startTime": "startDate", "endTime": "endDate" }[this.targetField];
			if (!this.forField) this.forField = this.targetField || "startDate";
		} else {
			this.forField = this.targetField;
		}
		//如果不可選日期，要選預設選時間格
		if (!this.enablePickDate) {
			//同時也不可全天
			this.tempObj.isWholeDay = false;
			this.showWholeDayOption = false;
			this.forField = { "startDate": "startTime", "endDate": "endTime" }[this.targetField];
			if (!this.forField) this.forField = this.targetField || "startTime";
		}
		//如果只選時間，必是startTime
		if (this.singleTime) {
			this.forField ="startTime";
		}
		//如果是同一天時段，必需有endTime
		if (this.inOneDayOnly) {
			this.tempObj.needEndTime = true;
			if (!this.tempObj.endDateTime) this.tempObj.endDateTime = this.cloneAndFixMinute(new Date(this.tempObj.startDateTime.getTime() + 300000));
		}

		//重設選日期component的view
		if (this.dateTimePickerSelectDateForStartDate) this.dateTimePickerSelectDateForStartDate.curView = 'selectDate';
		if (this.dateTimePickerSelectDateForEndDate) this.dateTimePickerSelectDateForEndDate.curView = 'selectDate';

		if (this.autoAddCustomPresetItem) {
			//如果是自定義預設時段，要加custom
			if (!this.presetItems.find((item:any)=>item.key=='custom')) this.presetItems.push(this.customPresetItem);
		}

		if (isFromOpenCall) {
			this.orgOptions = {
				clearBtn: this.clearBtn,
				noDateBtn: this.noDateBtn,
				singleDate: this.singleDate,
				singleTime: this.singleTime,
				targetField: this.targetField,
				showWholeDayOption: this.showWholeDayOption,
				showEndDateTimeOption: this.showEndDateTimeOption,
				enablePickDate: this.enablePickDate,
				enablePickTime: this.enablePickTime,
				inOneDayOnly: this.inOneDayOnly,
				pickerMode: this.pickerMode,
			}

			/*if (this.tempObj.presetKey) {
				let presetItem:any = this.presetItems.find((item:any)=>item.key==this.tempObj.presetKey);
				if (presetItem) this.setPresetItem(presetItem);
			}*/
		}

		// if (this.bubbleBox2.opened) setTimeout(()=>this.bubbleBox2.resize(false));
	}

	//---------------------------------------------------------------------------------------------

	public open(targetEle:HTMLElement, option:any={}, appendToEle:HTMLElement=document.body): Promise<any> {
		return new Promise((resolve: Function, reject: Function) => {
			this.isValueChanged = false;
			if (typeof option === 'function') option = option(this);
			const localStorageDateTime = localStorage.getItem('dtp3-last-' + this.localStorageKey);
			if (localStorageDateTime && this.localStorageKey){
				this.readLocalStorage();
			} else if (option.value) {
				this.setObjValue(option.value, this.sourceObj, true); //從option.value到sourceObj
			} else if (option.sourceObj) {
				this.setObjValue(option.sourceObj, this.sourceObj, true); //從option.sourceObj到sourceObj
			}
			this.setObjValue(this.sourceObj, this.tempObj, true); //sourceObj到tempObj，tempObj是用來作dataBinding
			this.applyOption(option, true);

			//在打開popup前，設定當前的pickerMode
			const periodTypeItems = ['schoolYear','schoolSemester','schoolPeriod'];
			this.pickerMode = (this.tempObj.presetKey && periodTypeItems.indexOf(this.tempObj.presetKey) > -1) ? this.tempObj.presetKey : (this.pickerMode || 'dateTime');

			this.resolveFn = resolve;
			setTimeout(()=>{
				this.bubbleBox2.open(targetEle, appendToEle)
				.then(()=>{})
				.catch((err:any)=>{
					this.applyOption(this.orgOptions, false);
				});
			});
		});
	}

	//---------------------------------------------------------------------------------------------

	public setForField(forField: string): void {
		this.forField = forField;
	}

	//---------------------------------------------------------------------------------------------

	private pad(num: number, charQty: number): string {
		let result = num.toString();
		while (result.length < charQty) result = '0' + result;
		return result;
	}

	//---------------------------------------------------------------------------------------------

	public getDateString(date:Date): string {
		if (!date || !this.isDateValid(date)) return '';
		return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
	}

	//---------------------------------------------------------------------------------------------

	public getTimeString(date:Date): string {
		if (!date) return '';
		let hour: number = date.getHours();
		let min: number = date.getMinutes();
		let amPm: string = ' am';

		if (hour > 11) amPm = ' pm';
		if (hour > 12) hour = hour - 12;

		return hour + ":" + this.pad(min, 2) + amPm;
	}

	//---------------------------------------------------------------------------------------------

	public cloneAndFixMinute(date: Date): Date {
		let copiedDate = new Date(date.getTime());
		if (!this.isDateValid(copiedDate)) return copiedDate;
		copiedDate.setSeconds(0, 0);
		if (this.showCustomMinutesOption || (this.tempObj && this.tempObj.customMinutes)) return copiedDate;
        let offset:number = 1;
		while (((copiedDate.getMinutes() % 5) > 0) || (copiedDate.getDate() != date.getDate())) {
            if ((copiedDate.getDate() != date.getDate())) offset = -1;
			copiedDate.setMinutes(copiedDate.getMinutes() + offset);
		}
		return copiedDate;
	}

	//---------------------------------------------------------------------------------------------

	public toggleNeedEndTime(): void {
		let needEndTime:boolean = !this.tempObj.needEndTime;
		if (needEndTime) {
			if (!this.tempObj.endDateTime || this.tempObj.startDateTime >= this.tempObj.endDateTime) {
				this.tempObj.endDateTime = this.cloneAndFixMinute(new Date(this.tempObj.startDateTime.getTime() + 300000));
				//this.tempObj.endDateTime = this.cloneAndFixMinute(new Date(new Date().setHours(this.tempObj.startDateTime.getHours() + 1)));
			}
			
            if (this.forField == 'startDate') this.setForField('endDate');
            if (this.forField == 'startTime') this.setForField('endTime');
		} else {
            if (this.forField == 'endDate') this.setForField('startDate');
            if (this.forField == 'endTime') this.setForField('startTime');
		}
		
		this.tempObj.needEndTime = needEndTime;
		this.isValueChanged = true;
		//if (this.tempObj.needEndTime) this.dateTimeChangeHandler('endTime');
	}

	//---------------------------------------------------------------------------------------------
	public emit_close(){
		this.closeEmit.emit('datetimepicker close')
	}
	public clearBtnClick(): void {
		this.tempObj.startDateTime = null;
		this.tempObj.endDateTime = null;
		this.tempObj.isWholeDay = false;
		
		this.onTouched();
		this.onChange(null);
		this.resolveFn(null);
		this.bubbleBox2.close();
	}

	//---------------------------------------------------------------------------------------------

	public noDateBtnClick(): void {
		this.tempObj.startDateTime = null;
		this.tempObj.endDateTime = null;
		this.tempObj.isWholeDay = false;
		this.tempObj.isNoDate = true;
		this.confirmBtnClick();
	}

	//---------------------------------------------------------------------------------------------

	public confirmBtnClick():void {
		if (!this.sourceObj) this.sourceObj = {};
		this.setObjValue(this.tempObj, this.sourceObj);
		if (this.localStorageKey){
			this.writeLocalStorage(this.sourceObj);
		}
		this.confirmEmit.emit(this.sourceObj)
		this.onTouched();
		this.onChange(this.sourceObj);
		this.resolveFn(Object.assign({},this.sourceObj));
		
		this.bubbleBox2.close();
	}

	//---------------------------------------------------------------------------------------------

	public cancelBtnClick(): void {
		this.applyOption(this.orgOptions, false);
		this.bubbleBox2.close();
	}

	//---------------------------------------------------------------------------------------------

	public dateTimeChangeHandler(changeType: string): void {
		//let customPresetItem:any = this.presetItems.find((item:any)=>item.key=='custom');
		//if (customPresetItem) this.setPresetItem(customPresetItem);
		this.tempObj.presetKey = 'custom';

		// 單選日可能要處理click日期後即close pop up
		if (this.inOneDayOnly){
			if (this.tempObj.startDateTime && this.tempObj.endDateTime){
				this.tempObj.endDateTime.setFullYear(this.tempObj.startDateTime.getFullYear());
				this.tempObj.endDateTime.setMonth(this.tempObj.startDateTime.getMonth());
				this.tempObj.endDateTime.setDate(this.tempObj.startDateTime.getDate());
				/*if (this.tempObj.endDateTime < this.tempObj.startDateTime){
					this.tempObj.startDateTime.setTime(this.tempObj.endDateTime.getTime() - 300000);
				}*/
			}
		}
		if (this.singleDate) {
			//if (changeType == 'startDate') this.bubbleBox2.close();
			// 如是date range，則要處理startTime大過endTime
		} else if (this.tempObj.needEndTime && this.tempObj.startDateTime >= this.tempObj.endDateTime) {
			if (changeType == 'startDate' || changeType == 'startTime') {
				this.tempObj.endDateTime.setTime(this.tempObj.startDateTime.getTime() + 300000);
			} else if (changeType == 'endDate' || changeType == 'endTime') {
				this.tempObj.startDateTime.setTime(this.tempObj.endDateTime.getTime() - 300000);
			}
		}

		//自動跳目標
		//如果不可選時間
		/*let tempForField:string = this.forField;
		if (this.tempObj.isWholeDay || !this.enablePickTime) {
			//如果可選結束日期
			if (this.tempObj.needEndTime) {
				tempForField = { "startDate":"endDate", "endDate": "startDate" }[this.forField];
			} else {
				tempForField = "startDate";
			}
		} else { //如可選時間
			tempForField = { "startDate":"startTime", "endDate": "endTime" }[this.forField];
		}
		if (tempForField) this.forField = tempForField;*/

		this.isValueChanged = true;
	}

	//---------------------------------------------------------------------------------------------

	public setPresetItem(presetItem:any, isByUser:boolean=false):void {
		const periodTypeItems = ['schoolYear','schoolSemester','schoolPeriod'];
		if (periodTypeItems.indexOf(presetItem.key) == -1){
			this.pickerMode = 'dateTime';
			this.bubbleBox2.positionAnimation = true;
			setTimeout(()=>{this.bubbleBox2.positionAnimation = false;}, 400);
		}
		this.applyOption(((typeof presetItem.option === 'function') ? presetItem.option(this) : presetItem.option) || {});
		if (isByUser && this.pickerMode=="dateTime") {
			this.isValueChanged = true;
			if (presetItem.key != 'custom') {
				this.confirmBtnClick();
			}
		} else if (periodTypeItems.indexOf(this.pickerMode) > -1 && this.years.length<1) {
			this.getYearAndTimeApi();
		}
	}

	//---------------------------------------------------------------------------------------------
	public singleMonthOnChangeHandler(value:any):void {
		if (!this.tempObj.startDateTime) this.tempObj.startDateTime = new Date();
		if (value.year) this.tempObj.startDateTime.setFullYear(value.year);
		if (value.month) this.tempObj.startDateTime.setMonth(value.month);
		this.isValueChanged = true;
	}
	//---------------------------------------------------------------------------------------------
	public singleYearOnChangeHandler(year:number):void {
		if (!this.tempObj.startDateTime) this.tempObj.startDateTime = new Date();
		if (year) this.tempObj.startDateTime.setFullYear(year);
		this.isValueChanged = true;
	}
	//---------------------------------------------------------------------------------------------
	private getYearAndTimeApi():void {
		//this.datas.post2({ data: { api:'DateTimePicker.getSchoolYear', json:[] } }).then((res:any)=>{
		this.datas.post2({ data: { api:'DateTimePicker.getSchoolYearAndTime', json:[] } }).then((res:any)=>{
			if (res && res.years) {
				for (let year of res.years) {
					year.start = new Date(year.start_time * 1000);
					year.end = new Date(year.end_time * 1000);
					for (let semester of year.semesters) {
						semester.start = new Date(semester.start_time);
						semester.end = new Date(semester.end_time);
					}
					for (let period of year.periods) {
						period.start = new Date(period.start_time);
						period.end = new Date(period.end_time);
					}
				}

				this.years = res.years;
				let now:Date = new Date();
				this.selectedYear = this.years.find((year:any)=>year.start<=now && year.end>=now);
				if (!this.selectedYear && this.years[0]) this.selectedYear = this.years[0];
			}
        });
	}
	//---------------------------------------------------------------------------------------------
	public setSelectedOption(option:any, setValueChanged:boolean = false, pickerMode:string=this.pickerMode):void {
		if (this.allowMultiple){
			const index = this.tempObj.selectedOptionsForMultiple.findIndex(e=> e.id == option.id);
			if (index === -1){
				this.tempObj.selectedOptionsForMultiple.push(option);
			} else {
				this.tempObj.selectedOptionsForMultiple.splice(index, 1);
			}
			this.isValueChanged = true;
			return;
		}
		if (pickerMode=='schoolYear') this.selectedYear = option;

		if (!option) return;
		if (setValueChanged && (this.tempObj.selectedOption != option)) this.isValueChanged = true;
		this.tempObj.selectedOption = option;

		for (let year of this.years) {
			if (year.semesters.indexOf(option) > -1 || year.periods.indexOf(option) > -1) this.selectedYear = year;
		}

		//如果是學校年度，start_time和end_time會是unix_time，如果是semester或period，是local time string
		//this.tempObj.startDateTime = option.start_time ? new Date(pickerMode=='schoolYear' ? option.start_time * 1000 : option.start_time) : new Date();
		//this.tempObj.endDateTime = option.end_time ? new Date(pickerMode=='schoolYear' ? option.end_time * 1000 : option.end_time) : null;
		this.tempObj.startDateTime = option.start ? new Date(option.start) : new Date();
		this.tempObj.endDateTime = option.end ? new Date(option.end) : null;
	}
	//---------------------------------------------------------------------------------------------
	public getOptions():any[] {
		return {
			schoolYear: this.years,
			schoolSemester: this.selectedYear ? this.selectedYear.semesters : null,
			schoolPeriod: this.selectedYear ? this.selectedYear.periods : null
		}[this.pickerMode] || [];
	}
	//---------------------------------------------------------------------------------------------
	public isDateValid(d:any):boolean {
		if (Object.prototype.toString.call(d) === "[object Date]") {
			// it is a date
			if (isNaN(d)) { // d.getTime() or d.valueOf() will also work
			  return false;
			} else {
			  return true;
			}
		  } else {
			return false;
		  }
	}

	readLocalStorage(){
		const text = localStorage.getItem('dtp3-last-' + this.localStorageKey);
		if (text){
			const arr:any = text.split('|');
			const startDateDateObj = arr[2]?new Date( parseInt(arr[2]) ):null;
			const endDateDateObj = arr[2]?new Date( parseInt(arr[3]) ):null;
			this.sourceObj = {presetKey: arr[0], id: arr[1], startDateTime: startDateDateObj, endDateTime: endDateDateObj, label: arr[4]};
			if (endDateDateObj && endDateDateObj.getFullYear() > 1970){
				this.sourceObj.needEndTime = true;
			}
			if (this.sourceObj.id == ''){
				const found = this.presetItems.find(e=> e.key == this.sourceObj.presetKey);
				if (found && found.option && found.option.value){
					this.sourceObj.startDateTime = found.option.value.startDateTime;
					this.sourceObj.endDateTime = found.option.value.endDateTime;
				}
			} else {
				this.sourceObj.selectedOption = { id: arr[1] };
			}
			return this.sourceObj;
		}
		return null;
	}

	writeLocalStorage(sourceObj){
		if (localStorage){
			let label = '';
			if (sourceObj.presetKey == 'schoolSemester' ||sourceObj.presetKey == 'schoolPeriod'){
				label = sourceObj.selectedOption.year_title + ' (' + sourceObj.selectedOption[this.datas.lang == 'en'?'ename':'cname'] + ')';
			} else if (sourceObj.presetKey == 'schoolYear'){
				label = sourceObj.selectedOption.title;
			} else if (sourceObj.presetKey == 'custom'){
				const startMo = moment(sourceObj.startDateTime);
				if (sourceObj.endDateTime){
					const endMo = moment(sourceObj.endDateTime);
					label = startMo.format('YYYY/M/D') + ' - ' + endMo.format('YYYY/M/D');
				} else {
					label = startMo.format('YYYY/M/D') + ' - ∞';
				}
			} else {
				const found = this.presetItems.find(e=> e.key == sourceObj.presetKey);
				label = found?found.label:'--';
			}
			const localStorageDateTime = sourceObj.presetKey + '|' + (sourceObj.selectedOption && sourceObj.selectedOption.id?sourceObj.selectedOption.id:'') + '|'+ +sourceObj.startDateTime + '|' + +sourceObj.endDateTime + '|' + label;
			localStorage.setItem('dtp3-last-' + this.localStorageKey, localStorageDateTime);
		}
	}

	public isOptionDisabled(option):boolean {
		try {
			if (this.pickerMode=='schoolSemester') {
				if (this.unavailableSemesterIds.includes(parseInt(option.id))) return true;
			}
			return false;
		} catch(e) {
			return false;
		}
	}

	public isOptionSelectedForMultiple(option):boolean{
		return !!this.tempObj.selectedOptionsForMultiple.find(e=> e.id == option.id);
	}

}
