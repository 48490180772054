import {Component, OnInit, ViewChild, Input, Output, EventEmitter, ElementRef} from '@angular/core';
import {Modal2Component} from "../../../../../sharedModule/modal2Module/modal2.component";
import {DataService} from "../../../../../service/data.service";
import {DomSanitizer} from '@angular/platform-browser';
import {ThemeService} from "../../../../../service/theme.service";
import {faBookmark} from "@fortawesome/pro-solid-svg-icons";
import {ROBookConfig} from "../../../../../sharedModule/roBookModule/ROBookConfig";
import {WebLessonService} from "../../services/WebLesson.service";

@Component({
	selector: 'book-toc-modal',
	template: `
		<modal2
			#modal
			(cancelClick)="modal.close()"
			(confirmClick)="select_page()"
			[maxHeight]="'calc(100dvh - 40px)'"
			[height]="'calc(100dvh - 40px)'"
			[width]="'calc(100vw - 40px)'"
			[contentStyle]="{'overflow-y': 'auto', 'padding': '0px', 'display': 'flex', 'flex-direction': 'column'}"
			[title]="modal_title"
		>
			<div class="container" *ngIf="document">
				<div class="chapter-list">
					<div
						*ngFor="let chapter of document.chapters" (click)="current_chapter = chapter"
						class="chapter"
						[ngClass]="{selected: current_chapter === chapter}"
					>
						<div class="chapter-icon">
							<fa-icon [icon]="faBookmark"></fa-icon>
						</div>
						{{ chapter.title }}
					</div>
				</div>
				<div class="chapter-content" *ngIf="current_chapter">
					<div *ngFor="let group of current_chapter.node.children"
						 class="chapter-group"
						 [ngClass]="{'is-first-group': group === document.chapters[0].node.children[0]}"
					>
						<div class="group-title" style="align-self: flex-start;">
							{{ hasGroupingPage && group.attributes.pgId ? group.attributes.pgTitle : group.attributes.title }}
						</div>
						<div class="page-list" *ngIf="has_pages(group)">
							<div *ngIf="can_display_sub_page_group_title(group)" class="group-title" style="width: 100%">
								{{ group.attributes.title }}
							</div>
							
							<ng-container *ngFor="let page of group.children; let i = index;">
								<div
									*ngIf="can_display_page(page)"
									class="page"
									[ngClass]="{selected: current_page === page}"
									(click)="current_page = page"
									[class.teacher-note]="page.attributes.pgType === 'teacher' || page.attributes.broadcast == false"
								>
									<div class="page-thumbnail">
										<img *ngIf="thumbnail_map[page.attributes.douid]" [src]="thumbnail_map[page.attributes.douid]">
									</div>
									<div class="page-title">
										<div class="page-slide-title">
											{{ page.attributes.slideTitle }}
										</div>
										<div class="page-no">
											{{ get_page_no(page) }}
										</div>
										<div class="teacher-note-icon"></div>
									</div>
								</div>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</modal2>
	`,
	styleUrls: ['./book-toc-modal.component.scss'],
})

export class BookTocModalComponent implements OnInit {

	@Input() bookConfig: ROBookConfig = null;
	@Input() current_page_id = null;
	@ViewChild('modal', {static: false}) modal: Modal2Component;

	document = null;
	current_chapter = null;
	current_page = null;
	thumbnail_map = {};
	page_no_map = {};

	@Output() page_selected = new EventEmitter();

	constructor(
		public datas: DataService,
		public dom_sanitizer: DomSanitizer,
		public theme: ThemeService,
		public eleRef: ElementRef,
		public web_lesson: WebLessonService,
	) {
	}

	ngOnInit() {
		this.theme.applyStyleObj({
			"default": {
				"common-textcolor": "#333333",
				"common-textcolor2": "#666666",
				'section-maincolor': '#007464',
				"section-popupbgcolor": "#e5eeea",
				"section-popupbgcolor2": "#fff",
				"section-titlecolor": "#333",
				"pulldown-textcolor": "var(--common-textcolor)",
				"pulldown-trianglecolor": "#666666",
				"pulldown-bgcolor": "#f0f0f0",
				"pulldown-labelcolor": "var(--common-textcolor)",
				"pulldown-bottomcolor": "#d0d0d0",
				"popupform-textcolor": "var(--section-maincolor)",
				"popupform-bgcolor": "#e5eeea",
				"popupform-bgcolor2": "#CFE3DA",
				"popupform-bgcolor3": "#e5eeea",
				"popupform-subtitlesize": "15px",
				"popupform-subtitleweight": "bold",
				"popupform-subtitlecolor": "#333333",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"popupframe-headerbgcolor": "#FFFFFF",
				"popupframe-titlesize": "20px",
				"popupframe-titlecolor": "#000000",
				"header-titlecolor": "#000",
				"color333": "#333",
				"color-333-fff": "#333",
				"color000": "#000",
				"aggrid-headerbgcolor": "#EDEDED",
				"aggrid-linecolor": "#E2E2E2",
				"aggrid-bordercolor": "#CCCCCC",
				"aggrid-headertextcolor": "#666666",
				"aggrid-textcolor": "#666",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"credit-board-bg": "#f1f1f7",
				"credit-color": "var(--section-maincolor)",
				"popupform-panelbgcolor": "#F5F5F5",
				"popupform-paneltextcolor": "#333333",
				"popupform-rowbgcolor": "#FFFFFF",
				"popuptoggle-textsize": "15px",
				"popuptoggle-textweight": "bold",
				"popuptoggle-buttonselectettextcolor": "var(--section-maincolor)",
				"popuptoggle-buttonnormaltextcolor": "#999999",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"alert-cancelbuttoncolor": "#BEBEBE",
				"text-color": "#FFFFFF",
				"alert-bgcolor": "#FFFFFF",
				"popup-head-bg": "#FFFFFF",
				"popup-text": "#000000",
				"book-icon-color": "#CCCCCC",
				"color-f9f9f9-1e1e29": "#f9f9f9",
				"color-1F8492-FFFFFF": "#1F8492",
				"color-d7e6e8-23323b": "#d7e6e8",
				"color-999999-FFFFFF": "#999999",
				"color-CCCCCC-666666": "#CCCCCC",
				"color-eee-1e1e29": "#eee",
				"aggrid-filterinputbgcolor-disabled": "rgba(255,255,255,0.5)",
				"aggrid-filterinputbordercolor-disabled": "rgba(204,204,204,0.4)",
				"alert-okbuttoncolor": "var(--section-maincolor)",
				"pulldown-selectedbgcolor": "var(--section-maincolor)",
				"pulldown-selectedtextcolor": "#FFFFFF",
				"pulldown-overbgcolor": "#E2E2E2",
				"pulldown-shadow": "0px 5px 30px rgba(0,0,0,0.3)",
				'DDCAB4-232334': "#DDCAB4",
				"color-CCCCCC-53536A": "#CCCCCC",
				"color-FFFFFF-1E1E29": "#FFFFFF",
				"color-F0F0F0-2D2C43": "#F0F0F0",
				"color-FFFFFF-2D2C43": "#FFFFFF",
				"color-E6E6E6-2D2C43": "#E6E6E6",
				"color-EDEDED-181821": "#EDEDED",
				"color-secmain-FFFFFF": "var(--section-maincolor)",
				"color-F5F5F5-35344d": "#F5F5F5",
				"color-E4CBD3-53536A": "#E4CBD3",
				"color-E7D0D7-232334": "#E7D0D7",
				"color-FFFFFF-232334": "#FFFFFF",
				"color-F4F4F4-2D2C43": "#F4F4F4",
				"color-CCCCCC-38374F": "#CCCCCC",
				"color-EBEBEB-2D2C43": "#EBEBEB",
				"color-181821-EDEDED": "#181821",
				"color-E0E0E0-5B5B5B": "#E0E0E0",
				"color-0000001A-FFFFFF1A": "#0000001A",
				"color-DF3354-FFFFFF": "#DF3354",
				"list-headerfontsize": "15px",
				"list-headerfontcolor": "#666666",
				"tickbox-selectedcolor": "var(--section-maincolor)",
				"tickbox-unselectecolor": "var(--common-textcolor)",
				"popupform-textmargin": "10px",
				"sidepanel-buttonnormaltextcolor": "#333333"
			},
			"dark": {
				"common-textcolor": "#FFFFFF",
				"common-textcolor2": "#999999",
				'section-maincolor': '#007464',
				"section-popupbgcolor": "#2D2C43",
				"section-popupbgcolor2": "#2D2C43",
				"section-titlecolor": "#fff",
				"pulldown-textcolor": "var(--common-textcolor)",
				"pulldown-trianglecolor": "#FFFFFF",
				"pulldown-bgcolor": "#38374f",
				"pulldown-bottomcolor": "#000",
				"popupform-textcolor": "#fff",
				"popupform-bgcolor": "#2d2c43",
				"popupform-bgcolor2": "#252537",
				"popupform-bgcolor3": "#2d2c43",
				"popupform-subtitlesize": "15px",
				"popupform-subtitleweight": "bold",
				"popupform-subtitlecolor": "#A0A0A0",
				"popupframe-shadow": "0px 15px 50px 0px rgb(0 0 0 / 30%)",
				"popupframe-savebuttontextcolor": "var(--section-maincolor)",
				"popupframe-headerbgcolor": "#1E1E29",
				"popupframe-titlesize": "20px",
				"popupframe-titlecolor": "#FFFFFF",
				"header-titlecolor": "#fff",
				"color333": "#999",
				"color-333-fff": "#fff",
				"color000": "#fff",
				"aggrid-headerbgcolor": "#181821",
				"aggrid-linecolor": "#53536A",
				"aggrid-bordercolor": "#53536A",
				"aggrid-headertextcolor": "#A0A0A0",
				"aggrid-textcolor": "#A0A0A0",
				"aggrid-tagcolor": "var(--section-maincolor)",
				"credit-board-bg": "#26253a",
				"credit-color": "#fff",
				"popupform-panelbgcolor": "#38374F",
				"popupform-paneltextcolor": "#FFFFFF",
				"popupform-rowbgcolor": "#1E1E29",
				"popuptoggle-textsize": "15px",
				"popuptoggle-textweight": "bold",
				"popuptoggle-buttonselectettextcolor": "var(--section-maincolor)",
				"popuptoggle-buttonnormaltextcolor": "#999999",
				"popupframe-linecolor": "rgba(0, 0, 0, 0.1)",
				"alert-cancelbuttoncolor": "#1E1E29",
				"text-color": "#FFFFFF",
				"alert-bgcolor": "#2D2C43",
				"popup-head-bg": "#1E1E29",
				"popup-text": "#FFFFFF",
				"book-icon-color": "#38374F",
				"color-f9f9f9-1e1e29": "#1e1e29",
				"color-1F8492-FFFFFF": "#FFFFFF",
				"color-d7e6e8-23323b": "#23323b",
				"color-999999-FFFFFF": "#FFFFFF",
				"color-CCCCCC-666666": "#666666",
				"color-eee-1e1e29": "#1e1e29",
				"aggrid-filterinputbgcolor-disabled": "rgba(30, 30, 41, 0.5)",
				"aggrid-filterinputbordercolor-disabled": "rgba(83 83 106, 0.5)",
				"alert-okbuttoncolor": "var(--section-maincolor)",
				"pulldown-selectedbgcolor": "var(--section-maincolor)",
				"pulldown-selectedtextcolor": "#FFFFFF",
				"pulldown-overbgcolor": "#1E1E29",
				"pulldown-shadow": "0px 5px 30px rgba(0,0,0,0.3)",
				'pulldown-labelcolor': 'var(--common-textcolor)',
				'DDCAB4-232334': "#232334",
				"color-CCCCCC-53536A": "#53536A",
				"color-FFFFFF-1E1E29": "#1E1E29",
				"color-F0F0F0-2D2C43": "#2D2C43",
				"color-FFFFFF-2D2C43": "#2D2C43",
				"color-E6E6E6-2D2C43": "#2D2C43",
				"color-EDEDED-181821": "#181821",
				"color-secmain-FFFFFF": "#FFFFFF",
				"color-F5F5F5-35344d": "#35344d",
				"color-E4CBD3-53536A": "#53536A",
				"color-E7D0D7-232334": "#232334",
				"color-FFFFFF-232334": "#232334",
				"color-F4F4F4-2D2C43": "#2D2C43",
				"color-CCCCCC-38374F": "#38374F",
				"color-EBEBEB-2D2C43": "#2D2C43",
				"color-181821-EDEDED": "#EDEDED",
				"color-E0E0E0-5B5B5B": "#5B5B5B",
				"color-0000001A-FFFFFF1A": "#FFFFFF1A",
				"color-DF3354-FFFFFF": "#DF3354",
				"list-headerfontsize": "15px",
				"list-headerfontcolor": "#A0A0A0",
				"tickbox-selectedcolor": "var(--section-maincolor)",
				"tickbox-unselectecolor": "var(--common-textcolor)",
				"popupform-textmargin": "10px",
				"sidepanel-buttonnormaltextcolor": "#999999"
			}
		}, this.eleRef.nativeElement);
	}

	open() {
		console.log(this.bookConfig);
		this.fetch_thumbnails();

		this.document = this.bookConfig.document;

		console.log({
			current_page_id: this.current_page_id,
		})

		if (this.current_page_id) {
			let page = this.bookConfig.pages.find(page => {
				return page.attributes.douid === this.current_page_id;
			});
			if (page) {
				let chapter = this.document.getChapters().find(chapter => {
					return chapter.id === page.chapter.id;
				});
				if (chapter) {
					this.current_chapter = chapter;
					this.current_page = this.current_chapter.pageNodes.find(pageNode => {
						return pageNode.attributes.douid === this.current_page_id;
					});
				}
			}
		} else if (this.document.chapters.length) {
			this.current_chapter = this.document.chapters[0];
			if (this.current_chapter.pageNodes.length) {
				this.current_page = this.current_chapter.pageNodes[0];
			}
		}
		this.set_page_no();
		console.log({
			chapter: this.current_chapter,
			page: this.current_page,
		})

		return this.modal.open();
	}

	set_page_no() {
		this.page_no_map = {};
		this.bookConfig.pages.forEach((page, index) => {
			this.page_no_map[page.attributes.douid] = page.pageNo;
		});
	}

	async fetch_thumbnails() {
		if(!this.bookConfig) {
			return;
		}

		let ids = [];
		this.bookConfig.document.getChapters().forEach(c => {
			ids = [
				...ids,
				...c.pageNodes.map(page => `${c.id}/${page.attributes.douid}`),
			];
		});

		let response = await this.datas.call('Resource.get_specific_thumbnails2', ids)
		console.log(response);
		for (let thumbnail of response.thumbnails) {
			this.thumbnail_map[thumbnail.pid] = this.dom_sanitizer.bypassSecurityTrustResourceUrl(`${response.base}${thumbnail.url}`);
		}
	}

	select_page() {
		this.page_selected.emit(this.current_page.attributes.douid);
		this.modal.close();
	}

	get modal_title() {
		if (!this.bookConfig) {
			return '';
		}
		let title = [];
		let book_title = this.bookConfig.book.title;
		if (book_title) {
			title.push(book_title);
		}
		if (this.current_chapter) {
			title.push(this.current_chapter.title);
		}


		return title.join('/');
	}

	get_page_no(page) {
		return this.page_no_map[page.attributes.douid];
	}

	public faBookmark = faBookmark;

	get hasGroupingPage() {
		return this.bookConfig && this.bookConfig.setting && this.bookConfig.setting.hasGroupingPage;
	}

	can_display_page(page) {
		if (this.hasGroupingPage && this.web_lesson.lesson_id && !this.web_lesson.is_teacher) {
			let page_douid_list = this.web_lesson.get_sub_grouping_page_list();
			return page_douid_list.includes(page.attributes.douid);
		}
		let page_douid_list = this.bookConfig.pages.map(page => page.attributes.douid);
		return page_douid_list.includes(page.attributes.douid);
	}

	can_display_sub_page_group_title(group) {
		if (!this.hasGroupingPage) {
			return false;
		}

		if (!group.attributes.pgId) {
			return false;
		}
		if (!group.attributes.title) {
			return false;
		}

		if (this.is_student) {
			if (group.attributes.pgType === 'teacher') {
				return false;
			}
			let page_douid_list = this.bookConfig.pages.map(page => page.attributes.douid);
			let sub_page_douid_list = group.children.map(page => page.attributes.douid);
			return sub_page_douid_list.some(douid => page_douid_list.includes(douid));
		}

		return true;
	}

	has_pages(group) {
		if(!this.hasGroupingPage) {
			return true;
		}
		let page_douid_list = this.bookConfig.pages.map(page => page.attributes.douid);
		let sub_page_douid_list = group.children.map(page => page.attributes.douid);
		return sub_page_douid_list.some(douid => page_douid_list.includes(douid));
	}

	get is_student() {
		return this.datas.userInfo.user_role == 2;
	}

}