import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {DataService} from "../../../../../service/data.service";
import {CommonService} from "../../../../../service/common.service";
import {UserInfoHelperService} from "../../../../../service/UserInfoHelper.service";
import {DomSanitizer} from "@angular/platform-browser";
import {WebSocketService} from "../../../../../service/webSocket.service";
import {faBookBlank, faGear, faShareNodes, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {WebLessonService} from "../../services/WebLesson.service";
import {WebLessonSelectBookModalComponent} from "../web-lesson-select-book-modal/web-lesson-select-book-modal.component";
import {AlertService} from "../../../../../service/alert.service";
import {Modal2Component} from "../../../../../sharedModule/modal2Module/modal2.component";
import {Router} from "@angular/router";
import {OupService} from "../../../../../service/oup.service";
import {ThemeService} from "../../../../../service/theme.service";
import {FileIOService} from "../../../../../service/FileIO.service";

@Component({
	selector: 'select-book-page',
	template: `
		<div class="btn-close" (click)="btn_close_clicked.emit()">
			<fa-icon [icon]="faTimes"></fa-icon>
		</div>
		<ng-container *ngIf="ready">
			
			<div class="main-container"
				 [class.has-book-id]="has_book_id"
				 [class.is-student]="!web_lesson.is_teacher"
				 [class.is-teacher]="web_lesson.is_teacher"
			>
				<ng-container *ngIf="has_book_id">
					<ng-container *ngIf="web_lesson.is_teacher">
						<div style="display: flex;flex-direction: column;align-items: center; max-width: 192px;">
							<ng-container [ngTemplateOutlet]="book_cover_and_title"></ng-container>
							<ng-container [ngTemplateOutlet]="lesson_title"></ng-container>
						</div>
						<ng-container [ngTemplateOutlet]="student_list_section"></ng-container>
						
						<div class="btn-start-lesson" (click)="start_lesson()">
							{{ 'web-lesson.start_lesson' | translate }}
						</div>
					</ng-container>
					<ng-container *ngIf="!web_lesson.is_teacher">
						<div style="display: flex;">
							<div style="display: flex;flex-direction: column;align-items: center; max-width: 192px;">
								<ng-container [ngTemplateOutlet]="book_cover_and_title"></ng-container>
							</div>
							
							<dash-line style="width: 55px;margin: 71px 10px 50px 10px;"></dash-line>
							
							<ng-container *ngTemplateOutlet="user_info"></ng-container>
						</div>
						
						<ng-container [ngTemplateOutlet]="lesson_title"></ng-container>
						
						<div>
							{{ 'web-lesson.wait_teacher_start_lesson' | translate }}
						</div>
						
						<div class="wait-teacher-loading"></div>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="!has_book_id">
					<ng-container *ngIf="web_lesson.is_teacher && !has_book_id">
						<ng-container *ngTemplateOutlet="user_info"></ng-container>
					</ng-container>
					<ng-container *ngIf="!web_lesson.is_teacher">
						<div class="wait-teacher-user-info" style="display: flex;">
							<img class="teacher-profile-image" [ngClass]="{'empty': !web_lesson.teacher}"
								 [src]="web_lesson.teacher?.profile_image_url || empty_teacher_image"
							/>
							<dash-line style="width: 52px; margin: 50px -8px 50px 8px;"></dash-line>
							
							<ng-container *ngTemplateOutlet="user_info"></ng-container>
						</div>
					</ng-container>
					<ng-container [ngTemplateOutlet]="lesson_title"></ng-container>
					<ng-container *ngIf="web_lesson.is_teacher">
						<div class="btn-select-book" (click)="select_book_modal.open();">
							<img src="./assets/img/web-lesson/open_book.svg"/>
							{{ 'web-lesson.open_ebook' | translate }}
						</div>
						<ng-container [ngTemplateOutlet]="student_list_section"></ng-container>
					</ng-container>
					<ng-container *ngIf="!web_lesson.is_teacher">
						<div *ngIf="!web_lesson.is_teacher_arrived">
							{{ 'web-lesson.wait_teacher_arrive' | translate }}
						</div>
						<div *ngIf="web_lesson.is_teacher_arrived">
							{{ 'web-lesson.wait_teacher_open_book' | translate }}
						</div>
						<div class="wait-teacher-loading"></div>
					</ng-container>
				</ng-container>
				
				<web-lesson-select-book-modal
					#select_book_modal
					[data]="select_book_modal_data"
					[fullWidth]="true"
				></web-lesson-select-book-modal>
				<web-lesson-share-list-modal
					*ngIf="share_list_modal.is_show"
					[before_shares]="before_shares"
					[book]="book"
					(cancel)="share_list_modal.close()"
				></web-lesson-share-list-modal>
				<network-check *ngIf="network_check_modal" (close)="network_check_modal = false"></network-check>
			</div>
			
			<div class="network-status" [class.has-book-id]="has_book_id">
				<ng-container *ngIf="has_book_id && web_lesson.is_teacher">
					<fa-icon class="btn-icon" [icon]="faGear" (click)="open_lesson_edit_modal()"></fa-icon>
					<fa-icon class="btn-icon" [icon]="faShareNodes" (click)="open_share_url_modal()"></fa-icon>
				</ng-container>
				<ng-container *ngIf="!web_lesson.is_teacher">
					<div class="btn-check-status" (click)="open_check_status_popup()">
						{{ 'web-lesson.check_status' | translate }}
					</div>
				</ng-container>
				<div style="flex: 1"></div>
				
				<div class="btn-reconnect" (click)="web_lesson.reconnect()">
					{{ 'web-lesson.reconnect' | translate }}
				</div>
				<img src="./assets/ro/app_icons/icon_network.svg" (click)="network_check_modal = true;" style="cursor: pointer;"/>
			</div>
		
		
		</ng-container>
		
		<ng-template #user_info>
			<div class="user-info" [class.student]="!web_lesson.is_teacher">
				<img [src]="image_path" class="user-profile-image"/>
				<div class="user-display_name">
					<span *ngIf="!web_lesson.is_teacher" class="connection-indicator"
						  [ngClass]="{online: web_lesson.is_connected() && web_lesson.lesson}"
					></span>
					{{ web_lesson.get_student_display_name(datas.userInfo) }}
				</div>
			</div>
		</ng-template>
		
		<ng-template #lesson_title>
			<div class="lesson-title">
				<span *ngIf="web_lesson.is_connected() && web_lesson.lesson">{{ 'web-lesson.connected' | translate }}</span>
				<span *ngIf="!web_lesson.is_connected() || !web_lesson.lesson">{{ 'web-lesson.connecting' | translate }}</span>
				<span *ngIf="web_lesson.lesson && !has_book_id">- {{ oup.isOupUser ? oup.get_lesson_name(web_lesson.lesson.owner) : web_lesson.lesson.title }}</span>
			</div>
		</ng-template>
		
		<ng-template #student_list_section>
			<div class="student-list">
				<div *ngFor="let student of student_list" class="student" [ngClass]="{offline: !student.is_online}">
					<img [src]="student.profile_image_url" class="student-profile-image"/>
					<div class="student-name">
						{{ web_lesson.get_student_display_name(student) }}
					</div>
				</div>
			</div>
		</ng-template>
		
		<ng-template #book_cover_and_title>
			<div class="book-cover-image" [class.empty]="!book_cover_image">
				<img *ngIf="book_cover_image" [src]="book_cover_image" class="cover"/>
				<fa-icon *ngIf="!book_cover_image" [icon]="faBookBlank"></fa-icon>
				<ng-container *ngIf="web_lesson.is_teacher">
					<img [src]="image_path" class="user-profile-image"/>
				</ng-container>
			</div>
			<div *ngIf="book" class="book-title">
				{{ book.title }}
			</div>
		</ng-template>
		
		<modal2
			#share_url_modal
			[title]="trans.instant('web-lesson.share_url')"
			(confirmClick)="share_url_modal.close()"
			[contentStyle]="{paddingTop: '0'}"
			[showCancelBtn]="false"
		>
			<div class="activity-name">
				{{ 'web-lesson.activity_name' | translate }}:
				<ng-container *ngIf="book">{{ book.title }}</ng-container>
			</div>
			
			<table class="form">
				<tr>
					<th>
						{{ 'web-lesson.url' | translate }}:
					</th>
					<td>
						<div style="display: flex; align-items: center; " *ngIf="share_url">
							<div class="share-url">{{ share_url }}</div>
							<div style="flex: 1"></div>
							<div class="btn-copy-url" (click)="copy_share_url()">{{ 'web-lesson.copy' | translate }}</div>
						</div>
					</td>
				</tr>
			</table>
		
		</modal2>
		
		<web-lesson-edit-modal #lesson_edit_modal *ngIf="lesson_edit_modal_data" [data]="lesson_edit_modal_data"></web-lesson-edit-modal>
	`,
	styleUrls: ['./select-book-page.component.scss'],
})

export class SelectBookPageComponent implements OnInit {
	ready = false;
	image_path = null;
	empty_teacher_image = null;
	before_shares = null;
	book = null;
	share_list_modal = {
		is_show: false,
		open: () => {
			this.share_list_modal.is_show = true;
		},
		cancel: () => {
			this.share_list_modal.close();
			this.book = null;
			this.before_shares = null;
		},
		close: () => {
			this.share_list_modal.is_show = false;
		}
	}

	select_book_modal_data: any = {
		singleOnly: true,
		open: () => {
			// this.get_explorer_list();
		},
		close: (selected_items) => {
			console.log(selected_items);
			this.select_book_modal.close();
			if (selected_items && selected_items.length) {
				// this.book_selected.emit(selected_items[0]);
				this.open_book(selected_items[0]);
			}
		}
	};

	book_cover_image = null;

	network_check_modal = false;

	share_url = "";

	lesson_edit_modal_data = null;

	@Input() student_list = [];
	@Input() has_book_id = false;
	@Output() btn_close_clicked = new EventEmitter();
	@ViewChild('select_book_modal', {static: false}) select_book_modal: WebLessonSelectBookModalComponent;
	@ViewChild('share_url_modal', {static: false}) share_url_modal: Modal2Component;

	constructor(
		public trans: TranslateService,
		public datas: DataService,
		public coms: CommonService,
		public user_info_helper: UserInfoHelperService,
		private dom_sanitizer: DomSanitizer,
		public wss: WebSocketService,
		public web_lesson: WebLessonService,
		public alert: AlertService,
		public router: Router,
		public oup: OupService,
		public eleRef: ElementRef,
		public theme: ThemeService,
		public file_io: FileIOService,
	) {
		console.log(this.datas.userInfo)
		this.empty_teacher_image = this.dom_sanitizer.bypassSecurityTrustResourceUrl("assets/img/icon_no_avatar_dark.svg");
		this.trans.use(this.datas.lang);
		this.web_lesson.on_message.subscribe(message => {
			if (message.content.action === 'update_lesson_state') {
				if (this.web_lesson.lesson_state.book_id && !this.web_lesson.book && !this.web_lesson.is_teacher) {
					this.init_book_info(this.web_lesson.lesson_state.book_id);
				}
			} else if (message.content.action === 'broadcast') {
				if (!this.web_lesson.is_teacher) {
					if (message.content.type === 'lesson_updated') {
						this.web_lesson.join_lesson(this.web_lesson.lesson_id);
					}
				}
			}
		});
	}

	ngOnInit() {
		this.web_lesson.get_self_photo().then(url => {
			this.image_path = this.dom_sanitizer.bypassSecurityTrustResourceUrl(url);
		});
		this.coms.timeout(500).then(() => this.ready = true);

		if (this.web_lesson.book_id) {
			this.init_book_info(this.web_lesson.book_id);
		}
		// this.theme.applyStyleObj({
		//
		// }, this.eleRef.nativeElement);
	}


	async open_book(book) {
		console.log(book);
		this.book = book;
		this.before_shares = await this.web_lesson.find_all_lesson_book_share(book);
		if (this.before_shares.length) {
			this.share_list_modal.open();
		} else {
			this.web_lesson.create_new_share(book);
		}
	}

	public faTimes = faTimes;

	async init_book_info(book_id) {
		let response = await this.datas.call('ROBook.get_book_info', book_id);
		console.log(response);

		if (response.cover && response.cover.url) {
			this.book_cover_image = this.dom_sanitizer.bypassSecurityTrustResourceUrl(this.file_io.getResourceServer(response.cover.url) + response.cover.url);
		}
		this.book = response.book;

		if (!this.web_lesson.book) {
			await this.web_lesson.get_book_entry(this.web_lesson.book_id);
		}
		this.web_lesson.book.published = 0;
		this.web_lesson.published = 0;

		if (this.web_lesson.is_teacher) {
			if (!this.web_lesson.share_id) {
				await this.web_lesson.create_new_share(this.web_lesson.book, false);
			} else {
				await this.web_lesson.add_session_book(this.web_lesson.book, false);
			}
		}
	}


	public faBookBlank = faBookBlank;

	async start_lesson() {
		await this.web_lesson.clear_end_time_in_book_share();
		this.web_lesson.open_book();
	}

	open_share_url_modal() {
		this.init_share_url();
		this.share_url_modal.open();
	}

	async init_share_url() {
		let response = await this.datas.call(
			'ROBookShare.create_lesson_share_url',
			this.web_lesson.share_id,
			location.href,
		);
		console.log(response);
		if (response.code === 0) {
			this.share_url = response.share.short_url;
		}
	}

	public faGear = faGear;
	public faShareNodes = faShareNodes;

	async copy_share_url() {
		await navigator.clipboard.writeText(this.share_url);
		this.alert.toastSuccess(this.trans.instant('web-lesson.share_url_copied'));
	}

	open_lesson_edit_modal() {
		this.lesson_edit_modal_data = {
			action: 'edit', lesson: this.web_lesson.lesson, close: (lesson) => {
				this.lesson_edit_modal_data = null;
				this.web_lesson.join_lesson(this.web_lesson.lesson_id);
				this.web_lesson.broadcast_msg({
					type: 'lesson_updated'
				})
			}
		};
	}

	open_check_status_popup() {
		let teacher_name = "?";
		let teacher_uid = "?";
		if (this.web_lesson.teacher) {
			teacher_name = this.web_lesson.get_student_display_name(this.web_lesson.teacher);
			teacher_uid = this.web_lesson.teacher.uid;
		}
		let student_name = "?"
		let student_uid = "?"
		if (this.datas.userInfo) {
			student_name = this.web_lesson.get_student_display_name(this.datas.userInfo);
			student_uid = this.datas.userInfo.uid;
		}
		let lesson_title = this.web_lesson.lesson ? this.web_lesson.lesson.title : "?";
		let lesson_id = this.web_lesson.lesson ? this.web_lesson.lesson.id : "?";
		if(this.oup.isOupUser) {
			lesson_title = this.oup.get_lesson_name(this.web_lesson.lesson.owner);
		}
		let book_title = "?";
		let book_id = "?";
		if (this.web_lesson.book) {
			book_title = this.web_lesson.book.title;
			book_id = this.web_lesson.book.id;
		}

		this.alert.alert2(this.trans.instant('web-lesson.check_status_content', {
			teacher_name,
			teacher_uid,
			student_name,
			student_uid,
			lesson_title,
			lesson_id,
			book_title,
			book_id,
		}), null, {
			btns:[
				[{key:'ok',labelKey:"alert.ok", ngStyle:{backgroundColor:"#89be51", width: '278px'} }]
			],
			msgStyle: {
				padding: '20px 12px'
			}
		});
	}
}