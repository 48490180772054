import {NgModule} from '@angular/core';
import {LockScreenComponent} from "./lock-screen.component";
import {LockScreenIconComponent} from "./lock-screen-icon.component";

@NgModule({
	imports: [],
	exports: [
		LockScreenComponent,
		LockScreenIconComponent,
	],
	declarations: [
		LockScreenComponent,
		LockScreenIconComponent,
	],
	providers: [],
})
export class LockScreenModule {
}
