import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms'; // ngModel 需要
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CameraCaptureModule } from '../CameraCaptureModule/CameraCapture.module';
import { ScoringModule } from '../scoringModule/scoring.module';
import { AutoResizeDirective, OKDQBComponent } from './OKDQB.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AudioModule } from '../audioModule/audio.module';
import { ROBookModule } from '../roBookModule/ROBook.module';
import { QuillModule } from 'ngx-quill';
import { CustomQuillEditorModule } from '../customQuillEditorModule/customQuillEditor.module';

@NgModule({
    imports: [
		FontAwesomeModule,CommonModule,TranslateModule
		,CameraCaptureModule, ScoringModule,DragDropModule,FormsModule
		,AudioModule,
		ROBookModule,
		QuillModule.forRoot(),
		CustomQuillEditorModule
    ],
    declarations: [
        OKDQBComponent,
		AutoResizeDirective
    ],
    exports: [
        OKDQBComponent,
		AutoResizeDirective,
    ],
    providers: [],
    bootstrap: []
})
export class OKDQBModule { }
