import { ChineseTextHelper } from "src/app/common/ChineseTextHelper";

export class ChineseNumber
{
	private static numbers:any = {
		'一': 1, '兩': 2, '两': 2, '二': 2, '三': 3, '四': 4, '五': 5,
		'六': 6, '七': 7, '八': 8, '九': 9, '零': 0, '〇': 0,
		'十':10
	};
	
	
	// 浮点部分转换
	public float2numstring (chiNumber:string):string
	{
		return chiNumber.split('').map(
			(digit:string):string=>
			{ 
				return ChineseNumber.numbers[digit] + "";
			}
		).join('');
	}
	
	
	
	// 整数部分转换
	public int2num(chiNumber:string):string
	{
		var stack:any[] = [];
		var separateUnits:any = {
			'萬': 10000,
			'億': 10000
		};
		var units:any = {
			'仟': 1000,
			'千': 1000,
			'百': 100,
			'十': 10
		};
		

		if (chiNumber.length === 1) {
			return ChineseNumber.numbers[chiNumber];
		}

		var total:number = 0;
		var len:number = chiNumber.length;
		for (var i:number = 0; i < len; i++) {
			var current:string = chiNumber.charAt(i);
			if (current in units) 
			{
				total += units[current] * (stack.pop() || 1)
				stack = [];
			} else if (current in separateUnits) {
				var times:number  = (current.match(/億/) && !chiNumber.substr(i).match(/萬/) ? 100000000 : 10000)
				total *= times;
				total += times * (stack.pop() || 0);
				stack = [];
			} else {
				if(!ChineseNumber.numbers[current]){
					if (ChineseNumber.numbers[current] === 0){
						stack.push(0);
					}else{
						stack.push(current);
					}
				}else{
					stack.push(ChineseNumber.numbers[current]);
				}
			}
		}

		if (total) {
			if (stack.length > 0) {
				total += Number(stack.join(''));
			}
			return total.toString();
		} else {
			return chiNumber;
		}
}

	// 正数转换
	public postiveChinese2number(chiNumber:string):string
	{
		var reg:RegExp = this.getChineseDotRegexp();
		var parts:any [] = chiNumber.split(reg);
		if (parts.length > 1)
		{
			var integerPart:string = parts[0];
			var list:any[] = [];
			list.push(this.int2num(integerPart))
			var len:number  = parts.length;
			for (var i:number  = 1; i < len; i++)
			{
				list.push(this.float2numstring(parts[i]))
			}
			//var floatPart:string = parts[1];
			//return (""+int2num(integerPart) + "." + float2numstring(floatPart))
			return list.join(".");
		} else {
			return ""+this.int2num(chiNumber);
		}
	}
	
	private getChineseDotRegexp():RegExp
	{
		return /[點点]/g;
	}
	
	public versionToNumber(versionNumber:string):string
	{
		var parts:any[] = versionNumber.split(this.getChineseDotRegexp());
		return parts.map(
			(element:string):string=>
			{

				
				try
				{
					return this.toNumber(element);


				} catch (err)
				{
					// trace(element);
					// trace(err.getStackTrace());
					var n2:string = this.int2num(element);
					this.postiveChinese2number(element);
				}
				return "";
			}
		).join(".");
	}
	
	public toNumber(chiNumber:string):string
	{
		if (!chiNumber) return '0';
		chiNumber = ChineseTextHelper.toTraditionalized(chiNumber);
		if (chiNumber.match(/^負/)){
			return "-"+ this.postiveChinese2number(chiNumber.replace('負', ''))
		} else {
			return this.postiveChinese2number(chiNumber.replace('負', ''))
		}
	}

}