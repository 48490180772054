import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class SeatingPlanService {

	colors = [
		"#E27E7E", "#FFC279", "#FFE883", "#A5CD62", "#65B4D0",
		"#9187D9", "#D4D4D4", "#E790D3", "#C9A570", "#F3B38C",
		"#84CDAE", "#7A9CD7", "#ABB494", "#A2D28E", "#B38CCB",
		"#F1BFBF", "#FFE1BC", "#FFF4C1", "#D2E6B1", "#B2DAE8",
		"#C8C3EC", "#EAEAEA", "#F3C8E9", "#E4D2B8", "#F9D9C6",
		"#C2E6D7", "#BDCEEB", "#D5DACA", "#D1E9C7", "#D9C6E5"
	]

	constructor() {
	}

	get_color(group) {
		if (group === -1) {
			return "#FFFFFF";
		}
		return this.colors[group % this.colors.length];
	}

}