import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ColorPickerComponent } from './color-picker.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
		FormsModule
    ],
    declarations: [
        ColorPickerComponent,
    ],
    exports: [
        ColorPickerComponent,
    ],
    providers: [
    ],
    entryComponents: []
})
export class ColorPickerModule {
}
