import { Component, HostBinding, Input, HostListener, ViewChild, TemplateRef, ViewContainerRef, AfterViewInit, EmbeddedViewRef, ElementRef, OnInit, OnChanges, SimpleChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PromiseUtils } from 'src/app/common/PromiseUtils';
import { CommonService } from 'src/app/service/common.service';
import { ThemeService } from 'src/app/service/theme.service';

// declare const ResizeObserver: any;
@Component({
  selector: 'bubbleBox2',
  template: `
  <ng-template #ngTemplate>
    <div *ngIf="showBackdrop" class="backdrop" [style.zIndex]="backdropZindex" [ngStyle]="backDropStyle" (click)="closeFn(null, false)"></div>
	
    <div
      #contentContainer
      id="contentContainer"
      class="contentContainer {{elClass}}"
	  [class.position-animation]="positionAnimation"
      [class.resizing] = "resizing"
	  [class.show-arrow]="showArrow"
	  [class.show-popup]="show"
	  [class.hide-popup]="!show"
	  [class.no-animate]="options.noAnimate"
      [ngStyle]="{
        'background-color': backgroundColor,
        'padding': padding + 'px',
        'left': boxPosX + 'px',
        'top': boxPos != 'top'?boxPosY + 'px':'auto',
        'bottom': boxPos == 'top'?boxPosY + 'px':'auto',
        'box-shadow': boxShadow,
        'z-index': contentContainerZindex
      }"
      tabindex="0"
    >
      <div class="contentWrapper">
          <ng-content></ng-content>
      </div>

      <div
        *ngIf="boxPos=='bottom'"
        class="boxTail boxTail-{{boxPos}}"
        [style.left.px]="tailPosX"
        [style.top.px]="tailPosY"
        [style.backgroundColor]="tailBgColor"
      ></div>
      <div
        *ngIf="boxPos=='top'"
        class="boxTail boxTail-{{boxPos}}"
        [style.left.px]="tailPosX"
		[style.top]="'auto'"
        [style.bottom.px]="tailPosY"
        [style.backgroundColor]="tailBgColor"
      ></div>
      <div
        *ngIf="boxPos=='left'"
        class="boxTail boxTail-{{boxPos}}"
        [style.left.px]="tailPosX"
        [style.top.px]="tailPosY"
        [style.backgroundColor]="tailBgColor"
      ></div>
      <div
        *ngIf="boxPos=='right'"
        class="boxTail boxTail-{{boxPos}}"
        [style.left.px]="tailPosX"
        [style.top.px]="tailPosY"
        [style.backgroundColor]="tailBgColor"
      ></div>
    </div>
  </ng-template>
  `,
  styleUrls: ['./bubbleBox2.component.scss']
})

export class BubbleBox2Component implements OnChanges, AfterViewInit {
    @ViewChild("ngTemplate", {static:true}) ngTemplate:TemplateRef<any>;
    @ViewChild("contentContainer", {static:false}) contentContainer: ElementRef<HTMLDivElement>;
    @HostBinding('style.zIndex') public backdropZindex = 100;
	@Input() showArrow:boolean = true;
    @Input() showBackdrop:boolean = false;
    @Input() submitOnClose:boolean = false;
    @Input() rejectOnClose:boolean = false;
    @Input() backgroundColor:string = 'var(--wpopup-bgcolor, #fff)';
    @Input() boxShadow:string = '';//this.themes.getStyleValue('wpopup-shadow') || '0px 5px 30px rgba(0,0,0,0.3)';
    @Input() padding:number = 10;
	@Input() targetMargin:number = 0;
    @Input() marginTop:number = 0;
    @Input() marginBottom:number = 0;
    @Input() marginLeft:number = 0;
    @Input() marginRight:number = 0;
    @Input() options:any = {};
    @Input() backDropStyle:any = {};
    @Input() hCenter:'no'|'try'|'force' = 'no';
    @Input() position:string| 'auto'|'auto-up-down'|'top'|'bottom'|'left'|'right'|'bar' = 'auto';
	@Input() clickOutsideHandler:any;
	@Input() autoClose:boolean = true;
	@Input() autoZIndex:boolean = true;
    @Input() public contentContainerZindex:any = 101;
	@Input() public positionAnimation = false; //just for datetimepicker size translation
    @Input() public elClass = '';
    public boxPos:string = "bottom"; //top/bottom/left/right;
    public targetRect:DOMRect = null;
    public boxPosX:number = -10000;
    public boxPosY:number = -10000;
    public tailPosX:number = 0;
    public tailPosY:number = 0;

    private targetEle:HTMLElement = null;
    private resolveFn:Function = null;
    private rejectFn:Function = null;
    public closeFn:any = null;
    public resizing:boolean = false;
    @Input() public tailBgColor:string = "";
	@Input() public autoTailColor:boolean = true;
  	public show:boolean = true;
    private boxEleCanvas:HTMLCanvasElement = null;
    @Output() public onClose:EventEmitter<void> = new EventEmitter();
	@Output() public onOpen:EventEmitter<void> = new EventEmitter();
	@Output() public emitter:EventEmitter<any> = new EventEmitter();
	public embeddedViewRef:EmbeddedViewRef<any>;

	// private observer: any; // *** ResizeObserver not work on IE 
	// @Input() public autoResize:boolean = false; // autoresize using ResizeObserver
    
    constructor(private zone:NgZone, private coms:CommonService, private viewContainerRef: ViewContainerRef, private themes:ThemeService, private eleRef: ElementRef) {
		// this.observer = new ResizeObserver(entries => this.zone.run(() => this.observe(entries)));
    }

	public getCurrentTargetElement():HTMLElement
	{
		return this.targetEle;
	}
	/*
	private observe(entries: any[]): void {
		this.resize(true);
	}
	*/

    ngAfterViewInit(): void {
        setTimeout(()=>{
            if (!this.embeddedViewRef) this.embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.ngTemplate );
        });

		// if(this.autoResize) this.observer.observe(this.contentContainer.nativeElement); // 
        /*this.coms.waitFor(()=>this.contentContainer.nativeElement, 30).then(()=>{
            let boxEle:HTMLElement = this.contentContainer.nativeElement;
            html2canvas(boxEle).then((canvas:HTMLCanvasElement)=>this.boxEleCanvas = canvas);
        });*/
    }
  ngOnChanges(changes: SimpleChanges): void {
    if (!this.embeddedViewRef) this.embeddedViewRef = this.viewContainerRef.createEmbeddedView( this.ngTemplate );
  }
  
//-------------------------------------------------------------------------------------------------
  public openOrMove(targetEle:HTMLElement, appendToEle:HTMLElement=document.body):Promise<any>
  {
	if(this.lastPromise)
	{
		this.targetEle = targetEle;
		setTimeout(()=>{
			this.updatePosition();
		}, 0);
		return this.lastPromise;
	} else {
		return this.open(targetEle, appendToEle);
	}
  }
  public opened:boolean;
  private lastPromise:Promise<any>;
  public open(targetEle:HTMLElement, appendToEle:HTMLElement=document.body, backgroundColor: string = ""):Promise<any> {
	if (backgroundColor) this.backgroundColor = backgroundColor
	if (this.backgroundColor == null) {
		this.backgroundColor = this.themes.getStyleValue('section-maincolor', this.eleRef.nativeElement) || this.themes.getStyleValue('wpopup-bgcolor', this.eleRef.nativeElement) || '#FFFFFF';
	}

	this.show = true;
    if (this.closeFn) {
		this.show = false;
		this.opened = false;
		// this.closeFn = null;
		// this.onClose.emit();
		this.closeFn();
		return Promise.reject(null);
	}
    return this.lastPromise = new Promise((resolve:Function, reject:Function)=>{
		
		this.opened = true;
      this.resolveFn = resolve;
      this.rejectFn = reject;
      this.targetEle = targetEle;
	  
      setTimeout( 
		()=>{
			if (this.showBackdrop) this.backdropZindex = this.coms.getZIndex();
			if(this.autoZIndex) this.contentContainerZindex = this.coms.getZIndex();
		}, 
		0
		);
		this.resize().catch((e)=>console.error(e));

      //加到body
      //this.embeddedViewRef.detectChanges();
	  if(this.embeddedViewRef == null || this.embeddedViewRef.rootNodes == null || this.embeddedViewRef.rootNodes.length == 0){
		debugger;
	  }
      for (let node of this.embeddedViewRef.rootNodes) appendToEle.appendChild(node);

		var subscription:Subscription = new Subscription(
			()=>{

			}
		);
      this.closeFn = (ev:PointerEvent=null, force:boolean = false)=>{
		var clickOutside:boolean = (this.embeddedViewRef && this.embeddedViewRef.rootNodes) && (!ev || (ev.target!=this.targetEle && !this.isInsideEle(this.embeddedViewRef.rootNodes, ev.target)));
		// var inside:boolean = (!ev || (ev.target!=this.targetEle && !this.isInsideEle(this.embeddedViewRef.rootNodes, ev.target)));
		var p:Promise<any>;
		if(force)
		{
			p = Promise.resolve(null);
		} else if(!clickOutside)
		{
			p = Promise.reject("click inside");
		} else 
		{
			this.emitter.next({type:"clickOutside"});
			if(!this.autoClose)
			{
				p = Promise.reject("do not auto close");
			} else if(this.clickOutsideHandler)
			{
				p = this.clickOutsideHandler();
			} else {
				p =  Promise.resolve(null);
			}
		}
		
		p.then((o:any)=>{
			this.lastPromise = null;
			if (this.submitOnClose) resolve();
            if (this.rejectOnClose) reject();

			return this.hidePopup();
		}).then((o:any)=>{
		    
            for (let node of this.embeddedViewRef.rootNodes) {
				if(node &&  node.parentElement) node.parentElement.removeChild(node);
				//appendToEle.removeChild(node);
			}

			subscription.unsubscribe();
			
            this.closeFn = null;
            this.onClose.emit();
			this.opened = false;
		}).catch((reason)=>{
			// console.log("NOT closing whitepopup because",reason, ev ? ev.type : "");
		})
		
      };
      
      setTimeout(()=>{
		
		this.cancelEvents.forEach((eventType:string)=>{
			subscription.add(
				this.fromEvent(window, eventType)
				.subscribe(
					(event)=>{
						if (this.closeFn){
							this.closeFn(event);
						}
					}
				)
			);
		});
		this.cancelEventsOnlyWhenNotFocus.forEach((eventType:string)=>{
			subscription.add(
				this.fromEvent(window, eventType)
				.subscribe(
					(event)=>{
						if(!this.isInFocus() && this.closeFn) {
							this.closeFn(event);
						}
					}
				)
			);
		});
      });
	  this.onOpen.emit(null);
    });
  }
  private isInFocus():boolean
  {
	return this.isInsideEle(this.embeddedViewRef.rootNodes, document.activeElement);
  }
  private fromEvent(target:any, eventType:string):Observable<any>
  {
	return new Observable((subscriber)=>{
		var fn = (event)=>{
			subscriber.next(event);
		}
		target.addEventListener(eventType, fn, true);
		return ()=>{
			target.removeEventListener(eventType, fn, true);
			target.removeEventListener(eventType, this.closeFn, true);
		}
	})
  }
  // event always trigger cancel
  @Input() cancelEvents:string [] =["pointerdown", "tap", "mousedown", "wheel"];
  
  // event only trigger cancel when not in focus
  @Input() cancelEventsOnlyWhenNotFocus:string [] =["scroll"];
  
  //-------------------------------------------------------------------------------------------------
  public showPopup():Promise<any>
  {
	this.show = true;
	return this.hasAnimation() ? PromiseUtils.delay(250, null) : Promise.resolve(1);
	
  }

  public hidePopup():Promise<any>
  {
	this.show = false;
	return this.hasAnimation() ? PromiseUtils.delay(250, null) : Promise.resolve(1);
  }
  
  private hasAnimation():boolean
  {
	var noAnimation:boolean = false;
	if(this.options && this.options.noAnimate)
	{
		noAnimation = this.options.noAnimate;
	}
	return !noAnimation;
  }

  public close():Promise<any> {
	
	this.opened = false;
	return this.hidePopup().then((o:any)=>{
		if (this.closeFn) this.closeFn(null, true);
	});
  }
  public closeNow():void
  {
	this.opened = false;
	if (this.closeFn) this.closeFn(null, true);
  }
  //-------------------------------------------------------------------------------------------------
  public updatePosition():boolean  
  {
	let boxEle:HTMLElement = this.contentContainer.nativeElement;
	if (this.targetEle && boxEle && boxEle.clientHeight && boxEle.clientWidth){
	  let screenWidth:number = document.body.offsetWidth;
	  let screenHeight:number = document.body.offsetHeight;
	  let boxHeight:number = boxEle.offsetHeight;
	  let boxWidth:number = boxEle.offsetWidth;
	  let targetRect:DOMRect = this.targetEle.getBoundingClientRect() as DOMRect;

	  let getBoxPosX:Function = ()=>{
		  let hCenterPosX:number = (screenWidth - boxWidth) / 2;
		  if (this.hCenter=='force') {
			  return hCenterPosX;
		  } else if (this.hCenter=='try') {
			  //box的border-radius是10，tail的width是20，所以有30 offset
			  if ((hCenterPosX + boxWidth - 30) < targetRect.left) {
				  return targetRect.left - boxWidth + 30;
			  } else if ((hCenterPosX + 30) > targetRect.right) {
				  return targetRect.right - 30;
			  } else {
				  return hCenterPosX;
			  }
		  } else {
			  return targetRect.left + ((targetRect.width - boxWidth) / 2) + this.marginLeft;
		  }
	  }

		if(this.position=="bar") {
			this.boxPos = 'bar';
			this.boxPosX = targetRect.left + ((targetRect.width - boxWidth) / 2) + this.marginLeft;
			this.boxPosY = targetRect.bottom;
			return true;
		}

		if (this.position=="auto") {
			if ((targetRect.bottom + boxHeight) <= screenHeight) {
				this.boxPos = 'bottom';
			} else if ((targetRect.top - boxHeight) >= 0) {
				this.boxPos = 'top';
			} else if ((targetRect.right + boxWidth) <= screenWidth) {
				this.boxPos = 'right';
			} else {
				this.boxPos = 'left';
			}
		} else if(this.position == "auto-up-down")
		{
			if ((targetRect.bottom + boxHeight) <= screenHeight) {
				this.boxPos = 'bottom';
			} else {
				this.boxPos = 'top';
			}
		} else {
			this.boxPos = this.position;
		}

		if (this.boxPos == 'bottom') {
			this.boxPosX = targetRect.left + ((targetRect.width - boxWidth) / 2) + this.marginLeft;
			this.boxPosY = targetRect.bottom + 10 + this.targetMargin;
		} else if (this.boxPos == 'top') {
			this.boxPosX = getBoxPosX();
			// this.boxPosY = targetRect.top - boxHeight - 10;
			this.boxPosY = window.innerHeight - targetRect.top + 10 + this.targetMargin;
		} else if (this.boxPos == 'right') {
			this.boxPosX = targetRect.right + 10 + this.targetMargin;
			this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2) + this.marginTop ;
		} else if (this.boxPos == 'left') {
			this.boxPosX = targetRect.left - boxWidth - 10 - this.targetMargin;
			this.boxPosY = targetRect.top + ((targetRect.height - boxHeight) / 2) + this.marginTop;
		}

	  if (this.boxPosX<0) this.boxPosX = this.marginLeft;
	  if ((this.boxPosX + boxWidth) > screenWidth) this.boxPosX = screenWidth - boxWidth - (this.marginRight || 0);
	  if (this.boxPosY<0) this.boxPosY = this.marginTop;
	  if ((this.boxPosY + boxHeight) > screenHeight) this.boxPosY = screenHeight - boxHeight - this.marginBottom;


	  let getTailPosX:Function = ()=>{
		  let minRight:number = Math.min(this.boxPosX+boxWidth, targetRect.right);
		  let maxLeft:number = Math.max(this.boxPosX, targetRect.left);
		  return (minRight - maxLeft) / 2 + maxLeft - 6 - this.boxPosX;
	  };
	  //Set the tail
	  if (this.boxPos=='bottom') {
		  //this.tailPosX = targetRect.width / 2 + targetRect.left - this.boxPosX - 9.9;
		  this.tailPosX = getTailPosX();
		  this.tailPosY = -7//-8;
	  } else if (this.boxPos=='top') {
		  //this.tailPosX = targetRect.width / 2 + targetRect.left - this.boxPosX - 9.9;
		  this.tailPosX = getTailPosX();
		  this.tailPosY = -7;//9;
	  } else if (this.boxPos=='left') {
		  this.tailPosX = boxWidth - 6;//9;
		  this.tailPosY = targetRect.height / 2 + targetRect.top - this.boxPosY - 9.9;
	  } else if (this.boxPos=='right') {
		  this.tailPosX = -8;//-7;
		  this.tailPosY = targetRect.height / 2 + targetRect.top - this.boxPosY - 9.9;
	  }

	  
	  return true;
	} else 
	{
	  return false;
	}
  }
  private pickTailColor(retryCount:number=10):void
  {
	if(!this.autoTailColor || retryCount<0)
	{
		this.tailBgColor = this.backgroundColor;
		return;
	}
	let boxEle:HTMLElement = this.contentContainer.nativeElement;
	let boxHeight:number = boxEle.offsetHeight;
	let boxWidth:number = boxEle.offsetWidth;
	(()=>{ //自動pick box的tail的顏色，令tail盡量與box的對接位置使用相同顏色(如targetSelector左右不同色的box)，不支持半透明
		this.tailBgColor = "";
		let pickPointX:number = 0;
		let pickPointY:number = 0;
		let boxPickPointY: number
		if (this.boxPos=='bottom') {
			pickPointX = this.tailPosX + 10;
			pickPointY = 0 + 10;
			boxPickPointY = boxEle.getBoundingClientRect().top
		} else if (this.boxPos=='top') {
			pickPointX = this.tailPosX + 10;
			pickPointY = boxHeight - 1 + 8;
			boxPickPointY = boxEle.getBoundingClientRect().bottom - 20
		} else if (this.boxPos=='left') {
			pickPointX = boxWidth - 1;
			pickPointY = this.tailPosY + 10 + 10;
			boxPickPointY = boxEle.getBoundingClientRect().top + (boxHeight / 2)
		} else if (this.boxPos=='right') {
			pickPointX = 0;
			pickPointY = this.tailPosY + 10 + 10;
			boxPickPointY = boxEle.getBoundingClientRect().top + (boxHeight / 2)
		}
		
		setTimeout(()=>{
			let ele:HTMLElement = document.elementFromPoint(this.boxPosX + pickPointX, boxPickPointY) as HTMLElement;
			if (ele && this.isInsideEle(this.embeddedViewRef.rootNodes, ele)) {
				let style:any = window.getComputedStyle(ele);
				while ((ele.classList.contains('boxTail') || !style || !style.backgroundColor || 
					style.backgroundColor=="rgba(0, 0, 0, 0)") || style.backgroundColor.startsWith('rgba(0, 0, 0,') && ele.parentElement) {
					ele = ele.parentElement;
					style = window.getComputedStyle(ele);
				}
				this.tailBgColor = style.backgroundColor || this.backgroundColor;
			} else {
				//this.tailBgColor = this.backgroundColor;
				setTimeout(()=>this.pickTailColor(retryCount-1), 100);
			};
		});

	  })();
  }

  public resize(setResizing:boolean = true):Promise<void> {
    return new Promise((resolve, reject)=>{
      if (setResizing) this.resizing = true;
      let retryFn:Function = (retryCount:number) => {
        if(this.updatePosition())
		{
			this.pickTailColor();
			this.resizing = false;
			resolve();
		  
        } else if (retryCount<100) {
          	setTimeout(()=>retryFn(retryCount+1), 50);
        } else {
			this.resizing = false;
			// reject();
        }
      }
	  setTimeout(()=>retryFn(0));
    });
  }
  //-------------------------------------------------------------------------------------------------
  private isInsideEle(parents, child):boolean {
    for (let parent of parents) {
      let node = child;
      while (node) {
        if (node === parent) return true;
        node = node.parentNode;
      }
    }
    return false;
  }
  //-------------------------------------------------------------------------------------------------
  public resolve():void {
    if (this.resolveFn) this.resolveFn();
    if (this.closeFn) this.closeFn(null, true);
  }
  //-------------------------------------------------------------------------------------------------
  public reject():void {
    if (this.rejectFn) this.rejectFn();
    if (this.closeFn) this.closeFn(null, true);
  }
  //-------------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------------

}
