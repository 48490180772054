import { ROContextService } from './../roBookModule/ROContext';
import { Component, OnInit, Input, OnDestroy, HostListener, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

// =======================================
// icon
// =======================================
import { faUp } from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus, faCircleMinus } from '@fortawesome/pro-light-svg-icons';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { DynamicComponentService } from 'src/app/service/dynamicComponent.service';
import { ROContext } from '../roBookModule/ROContext';
import { ROScoreFreeEditPopup } from '../roBookModule/ROQuestionScore';
import { ROComponent } from '../roBookModule/ROComponent';

interface IROScoreEditPopup
{
	component:ROComponent,
	// node:XMLNode;
	emitter:EventEmitter<any>
	close():void;
}
@Component({
	selector: 'Scoring',
	template: `
	<div class="noScore" *ngIf="state=='noScore'">S</div>
	<div [ngClass]="[getScoreColor()]" *ngIf="state=='score'">{{scoreWithPlus()}}</div>
	<div class="upload" *ngIf="state=='upload'"><fa-icon [icon]="faUp"></fa-icon></div>
	<div class="aiScore" [ngClass]="[getAIScoreStyle()]" *ngIf="isDisplayAIScore() && state !== 'upload'">
		<div class="label">AI</div>
		<span class="score">{{getAIScore()}}</span>
	</div>
	<div class="manual" [ngClass]="[getReviewColor()]" *ngIf="!isDisplayAIScore() && state=='manual'" (click)="openScorePopUp()" #menu>
		<div class="label">T</div>	
		<span class="score">{{scoreWithPlus()}}</span>
	</div>
	`
	, styleUrls: ['./scoring.component.scss']
})

export class ScoringComponent{
	@Input() displayType:"default" | "ai" = "default";
	@Input() mode:string = "scoring";
	@Input() questionSetting:any;
	@Input() scoreResult:any;
	@Input() componentName:string = "";
	@Input() entryAndContent: any;
	@Input() share: any;
	@Input() questionItem:any
	@Output() public onChange:EventEmitter<ScoringComponent> = new EventEmitter();
	@Input() assViewer:any;

	@ViewChild('menu', { static: false }) public menu: ElementRef<HTMLDivElement>;

	public faUp:any = faUp;
	public faCirclePlus:any = faCirclePlus;
	public faCircleMinus:any = faCircleMinus;
	public context:ROContext

	public openScoreEdit:boolean = false;
	protected scoreChanged:boolean  = false;
	public state:string = 'none';
	
	constructor(
		protected cdr: ChangeDetectorRef, 
		public elementRef:ElementRef,
		private roContextService:ROContextService
	) {
		this.context = new ROContext()
		this.context.service = this.roContextService
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.assViewer) {
			const isNormalStudentMode = this.mode == 'view' && this.assViewer.share.type == 'normal'
			const isNormalReviewMode = this.mode == 'review' && this.assViewer.share.type == 'normal'

			if(!this.questionItem.var.submitted)
				this.state = "none";// 無提交
			else {
				// 有提交
				if(this.scoreResult) {
					if(this.scoreResult.correct == -1 || ["QBLongQuestion", "QBTakePhoto", "QBRecorder"].indexOf(this.questionItem.type) >= 0) {
						// 無固定答案
						if(!this.assViewer.showManualScore) {
							this.state = "upload";
						} else {
							if (isNormalReviewMode) {
								// 自由閱讀 AI批改中時
								this.state = 'upload'
							} else if (isNormalStudentMode) {
								// 自由閱讀時顯示批改分數 / 顯示上載
								this.state = this.questionSetting.hasScoring && 
									(this.scoreResult.teacherScore !== null || this.scoreResult.hasOwnProperty('aiScore') && this.scoreResult.aiScore !== null)
									? "manual" : "upload"
							} else {
								// 顯示批改分數 / 顯示上載
								this.state =  this.questionSetting.hasScoring ? "manual" : "upload";
							}
						}
					} else {
						// 有固定答案
						if(!this.assViewer.showAutoScore) {
							this.state = "upload";
						} else
							// 顯示分數 / 顯示無分
							this.state = this.questionSetting.hasScoring ? "score" : "noScore";
					}
				} else {
					this.state = "upload";// 需要改正 / 不需要改正
				}
			}
		}
	}

	public open():void {
		if(!this.openScoreEdit) {
			this.openScoreEdit = true;

			let closeFn: any = (ev: PointerEvent) => {
				if (this.menu && this.menu.nativeElement && ev.target) {
					if (!this.isDescendant(this.menu.nativeElement, ev.target)) {
						this.openScoreEdit = false;
						window.removeEventListener('mousedown', closeFn);
						window.removeEventListener('scroll', closeFn, true);

						if(this.scoreChanged) {
							this.onChange.emit(this);
						}
					}
				}
			};

			setTimeout(() => {
				window.addEventListener('mousedown', closeFn);
				window.addEventListener('scroll', closeFn, true);
			});
		}
			
	}

	private isDescendant(parent, child): boolean {
		let node = child.parentNode;
		while (node) {
			if (node === parent) return true;
			node = node.parentNode;
		}
		return false;
	}

	public score():string {
		let state:string =  this.state;
		if(state == "score")
		{
			if(this.scoreResult.correct == 0)
				return "0";
			return this.scoreResult.score;
		}
		else if(state == "manual")
		{
			if(this.scoreResult.hasOwnProperty("score"))
				return this.scoreResult.score;
		}
		return "?";
	}

	public scoreWithPlus():string {
		let state:string =  this.state;
		if(state == "score")
		{
			if(this.scoreResult.correct == 0)
				return "0";
			return "+"+this.scoreResult.score;
		}
		else if(state == "manual")
		{
			if(this.scoreResult.hasOwnProperty("teacherScore")) {
				if (this.scoreResult.teacherScore !== null) {
					return this.scoreResult.teacherScore > 0 ? "+"+this.scoreResult.teacherScore : this.scoreResult.teacherScore;
				} else {
					return "?";
				}
			}
			if(this.scoreResult.hasOwnProperty("score")) {
				if (this.scoreResult.score) {
					return "+"+this.scoreResult.score;
				} else {
					return this.scoreResult.score;
				}
			}
		}
		return "?";
	}

	public getScoreColor():string {
		if(!this.hasScoring || !this.scoreResult)
			return "";
		if(this.scoreResult.correct == 2)
			return "correct";
		else if(this.scoreResult.correct == 1)
			return "somewrong";
		else if(this.scoreResult.correct == 0)
			return "wrong";
		return "";
	}

	public getReviewColor():string {
		if (this.mode == 'review') {
			if (!this.hasScoring || !this.scoreResult) {
				return "";
			}

			if (this.scoreResult.score > 0) {
				return "correct";
			} else {
				return "wrong";
			}
		}
		return ""
	}

	// 使用 book 格式參數名 (不是 book structure)
	public get hasScoring():boolean {
		if(!this.questionSetting)
			return false;
		return this.questionSetting.hasScoring=='true';
	}

	// 使用 book 格式參數名 (不是 book structure)
	public totalScore():string {
		if(!this.hasScoring)
			return "?";
		if(this.questionSetting.scoringType==1)
			return parseFloat(this.questionSetting.fullScore).toString();
		else if(this.questionSetting.scoringType==2)
			return (parseFloat(this.questionSetting.scoreN)*parseFloat(this.questionSetting.unitScore)).toString();
		return "?";
	}

	public decScore():void {
		if(this.score()=="?")
			return;
		if(this.scoreResult.score==0)
			delete this.scoreResult.score;
		else
			this.scoreResult.score--;
		this.scoreChanged = true;
	}
	
	public incScore():void {
		if(this.score()=="?")
			this.scoreResult.score = 0;
		else if(this.scoreResult.score<parseInt(this.totalScore()))
			this.scoreResult.score++;
		this.scoreChanged = true;
	}

	public openScorePopUp() {
		// check if the ai setting allow teacher scoring
		const hasAISetting = this.entryAndContent.content && this.entryAndContent.content.setting && this.entryAndContent.content.setting.grading
		const isAIGrading = hasAISetting && this.entryAndContent.content.setting.grading == 'AI_GRADING'
		const isAICheckingStatus = this.share && this.share.status == 'aiChecking'
		
		if (this.mode == 'scoring' && !isAIGrading && !isAICheckingStatus) {
			// create the scoring pop up component
			let observation = new Observable((subscriber: Subscriber<any> ) => {
				let dynamicService: DynamicComponentService = this.context.service.dcs
				let component: any = dynamicService.create(ROScoreFreeEditPopup, {
					target:this.elementRef.nativeElement,
					result: this.scoreResult,
					max: this.scoreResult.maxScore,
					isCorrectionShown: false,
					showCorrectionButton: false
				})
				dynamicService.open(component)
				let instance = component.compRef.instance
				let subscription: Subscription = new Subscription(() => {
					dynamicService.destroy(component)
				})
				subscription.add(
					instance.emitter.subscribe({
						next: (param) => {
							subscriber.next(param)
						},
						error: (error) => {
							subscriber.next(error)
						},
						complete: () => {
							subscription.unsubscribe()
						}
					})
				)
				return ()=>{
					instance.close()
				}
			})
	
	
			if(!observation) return
	
			// subscribe to the component for the action
			let sub: Subscription = observation.subscribe({
				next: (data: {type: string, result: { [key: string]: any }}) => {
					if (data.type == 'confirm') {
						this.onChange.emit(this)
						sub.unsubscribe()
					} else if (data.type == 'cancel') {
						sub.unsubscribe()
					}
				},
				error: (error: any) => {
					console.log("scoring error", error);
					setTimeout(()=>{
						sub.unsubscribe()
					}, 0);
				}
			})
		}
	}

	// check the ai setting 
	// display the ai score when ai finish scoring and without teacher's score
	public isDisplayAIScore():boolean {
		const shouldDisplayMode = this.mode == 'scoring' || (this.mode == 'review' && this.assViewer.share.status.indexOf("checking") === -1) || (this.mode == 'view' && this.assViewer.share.type == 'normal')
		if (this.displayType === 'ai' && shouldDisplayMode && this.scoreResult && this.scoreResult.hasOwnProperty("aiScore") && this.scoreResult.aiScore !== null) {
			return true
		}
		return false
	}

	// get the AI score
	public getAIScore():string {
		const currentState: string = this.state;
		if (currentState == "manual" && this.scoreResult.hasOwnProperty("aiScore")) {
			return this.scoreResult.aiScore > 0 ? `+${this.scoreResult.aiScore}` : this.scoreResult.aiScore

		}
		return "?"
	}

	// get the AI score style
	public getAIScoreStyle():string {
		if (this.scoreResult && this.scoreResult.hasOwnProperty("aiScore")) {
			return this.scoreResult.aiScore > 0 ? "correct" : "wrong"
		}

		return ""
	}
}