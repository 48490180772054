import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { CreateShareModal } from './create-share.modal';
import { PresetButton4Component } from './preset-button4.component';
import { ShareTagSelectorComponent } from './share-tag-selector.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { LoadingModule } from 'src/app/sharedModule/loadingModule/loading.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SubjectSelectorComponent } from './subject-selector.component';
import { DateTimePickerComponent } from './dateTimePicker.component';
import { DateTimePickerSelectDateComponent } from './dateTimePickerSelectDate.component';
import { DateTimePickerSelectMonthComponent } from './dateTimePickerSelectMonth.component';
import { DateTimePickerSelectTimeComponent } from './dateTimePickerSelectTime.component';
import { CreateShareService } from './create-share.service';
import { MyDatePipe } from './my-date.pipe';
import { BobbleBoxModule } from 'src/app/sharedModule/bobbleBoxModule/bobbleBox.module';
import { TagGroupModule } from '../tagGroupModule/tagGroup.module';
import { MemberInfoModule } from '../memberInfoModule/memberInfo.module';
import { CreateShare2Component } from './create-share2.component';
import { SubjectModule } from '../subjectModule/subject.module';
import { OkaPulldownModule } from '../okaPulldownModule/okaPulldown.module';
import { Modal2Module } from '../modal2Module/modal2.module';
import { TargetSelectorModule } from './../targetSelectorModule/targetSelector.module';
import { InlineSvgModule } from '../inlineSvgModule/inlineSvg.module';
import { SelectBookModal } from './select-book-modal';
import { PopupToggleModule } from '../popupToggleModule/popupToggle.module';
import { SubjectSelector3Module } from 'src/app/sharedModule/subjectSelector3Module/subjectSelector3.module';
import { CodeScanModule } from 'src/app/sharedModule/codeScanModule/codeScan.module';
import { UploadModule } from './../uploadModule/upload.module';
import { CreateReportModal } from './create-report.modal';
import { StudentReadingReportModalModule } from '../studentReadingReportModalModule/studentReadingReportModal.module';
import { CreateLinkModal } from './create-link.modal';
import { NumberInputModule } from '../numberInputModule/numberInput.module';
import { DateTimePicker3Module } from '../dateTimePicker3Module/dateTimePicker3.module';
import { BubbleBox2Module } from '../bubbleBox2Module/bubbleBox2.module';
import { CreateAIOralPracticeModal } from './create-ai-oral-practice.modal';
import { CreateAICModal } from './create-aic.modal';
import { AdditionRemarkBallModule } from '../additionRemarkBallModule/additionRemarkBall.module';
import { CreateSpeechPracticeComponent } from './create-speech-practice/create-speech-practice.component';
import { DirectiveModule } from 'src/app/directive/DirectiveModule';
import { CreateGameModal } from './create-game/create-game.modal';
import { QuestionAggrid } from './create-game/question.aggrid';
import { AgGridModule } from 'ag-grid-angular';
import { SelectionPanelModule } from '../selectionPanelModule/selectionPanel.module';
import { CreateGameModule } from './create-game/create-game.module';

@NgModule({
    imports: [
		CommonModule, TranslateModule, FormsModule,
		NgSelectModule, UiSwitchModule, LoadingModule,BobbleBoxModule, SubjectModule, OkaPulldownModule,
		PerfectScrollbarModule,FontAwesomeModule, TagGroupModule, MemberInfoModule, Modal2Module,
		TargetSelectorModule, InlineSvgModule, PopupToggleModule, SubjectSelector3Module, CodeScanModule,
		UploadModule, StudentReadingReportModalModule, NumberInputModule, DateTimePicker3Module, BubbleBox2Module,
		AdditionRemarkBallModule, DirectiveModule, AgGridModule, SelectionPanelModule, CreateGameModule
	],
    declarations: [
		CreateShareModal, PresetButton4Component, ShareTagSelectorComponent, SubjectSelectorComponent,
		DateTimePickerComponent, DateTimePickerSelectMonthComponent, DateTimePickerSelectDateComponent, DateTimePickerSelectTimeComponent,
		MyDatePipe, CreateShare2Component, SelectBookModal, CreateReportModal, CreateLinkModal, CreateAIOralPracticeModal, CreateAICModal, 
		CreateSpeechPracticeComponent
    ],
    exports: [
		CreateShareModal, SubjectSelectorComponent, CreateShare2Component, SelectBookModal, CreateReportModal, CreateLinkModal, CreateAIOralPracticeModal,
		MyDatePipe, SelectBookModal, CreateReportModal, CreateLinkModal, CreateAICModal, CreateSpeechPracticeComponent
    ],
    providers: [
		CreateShareService
    ],
    entryComponents: [ CreateShareModal ]
})
export class CreateShareModule {
}
