import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
// import { AlertComponent } from '../sharedModule/alertModule/alert.component';
// import { DynamicComponentService } from './dynamicComponent.service';
// import { TranslateService } from '@ngx-translate/core';
import { UserMenuComponent } from '../sharedModule/userMenuModule/user-menu.component';
import { v4 as uuidv4 } from 'uuid';

@Injectable({ providedIn: 'root' })
export class CommonService {
	public macFirstLoad: boolean = true;
	public logs: any;
	public loading = [];
	public leftPanel;
	public filterBar;
	public filter;
	public defaultPage;
	public dateTimePicker;
	public urlHistories = [];
	public userMenuComponent:UserMenuComponent;
	public sectionComponent:any;
	public keydownListeners = [];
	constructor() {

		this.logs = [];

        /*
        if(window)
        {
            window.addEventListener('error', this.onError.bind(this));
        }
        */
		window.onerror = (error, url, line) => {

			// this.logError('Error:' + error);
			this.error('URL:' + url);
			this.error('Error:' + line + ":"+ error);
			// console.log(error, url, line, a,b,c);
			// this.logError(error)
			// VM10463:1 Uncaught Error: ABC
			// at <anonymous>:1:7
		};
	}

    /*
    private onError(event)
    {
        this.logError("ERROR");
    }
    */
	//---------------------------------------------------------------------------------------------
	public getProperty = function (obj, path) {
		path = path.split('.');
		var current = obj;
		while (path.length) {
			if (typeof current !== 'object') return undefined;
			current = current[path.shift()];
		}
		return current;
	}
	//---------------------------------------------------------------------------------------------
	public debugText: string = "";
	private appendLog(obj:any):void
	{
		this.logs.push(obj);
		if(this.logs.length > 100)
		{
			this.logs.shift();
		}
	}
	public logType(type:string, item:any):Promise<void>
	{
		return new Promise((resolve, reject) => {
			if (environment.production) {
				resolve();
			} else {
				console.log(item);
				var logObject:any;
				try {
					logObject = {
						time: this.getDateString(),
						text: JSON.stringify(item),
						type: type
					};
				} catch (err) {
					logObject = {
						time: this.getDateString(),
						text: item,
						type: type
					};
					
					// this.debugText = this.getDateString() + '   ' + item + "\n\n" + this.debugText;
				} finally {
					this.appendLog(logObject);
					resolve();
				}
				//if (this.debugText.length>10000) this.debugText = this.debugText.substr(0, 10000);
			}
		});
	}
	public error(item: any): Promise<any> 
	{
		return this.logType("error", item);
	}
	public info(item:any): Promise<any> {
		return this.logType("info", item);
	}
	public log(item: any): Promise<any> {
		return this.logType("log", item);
	}
	//---------------------------------------------------------------------------------------------
	public verStr(ver: string): string {
		return "v" + ver + (environment.production ? "" : " (Dev.Mode)");
	}//---------------------------------------------------------------------------------------------
	public getVersion(moduleName:string=null):string {
		return moduleName ? (environment.versions && environment.versions[moduleName]) || '0.0.0' : environment.version;
	}
	//---------------------------------------------------------------------------------------------
	public getVersionString(moduleName: string): string {
		//let versionString: string = (environment.versions && environment.versions[moduleName]) || '0.0.0';
		let versionString:string = this.getVersion(moduleName);
		return "v" + versionString + (environment.production ? "" : " (Dev.Mode)");
	}
	//---------------------------------------------------------------------------------------------
	private zIndex: number = 99;
	public getZIndex(): number {
		return this.zIndex++;
	}
	//---------------------------------------------------------------------------------------------
	public getDateString(d:Date=new Date(), format:string='yyyymmddhhmmss', dateDelimiter:string='-', timeDelimiter:string=':', dateTimeDelimiter:string=' ', use24hrs:boolean=true):string {
		let pad = (v: number): string => {
			return ((v < 10) ? '0' : '') + v;
		}
        if (isNaN(+d)) return '';
        let year = d.getFullYear();
        let month = pad(d.getMonth() + 1);
        let day = pad(d.getDate());
        let hour = pad((!use24hrs && d.getHours() > 12) ? d.getHours() - 12 : d.getHours());
        let min = pad(d.getMinutes());
        let sec = pad(d.getSeconds());
        let apm = use24hrs ? '' : (d.getHours() > 11) ? ' pm' : ' am';
        //YYYYMMDDhhmmss
        //return year+month+day+hour+min+sec
        //YYYY-MM-DD hh:mm:ss
        format = format.toLowerCase();
        if (format=='yyyymmddhhmmss') return year + dateDelimiter + month + dateDelimiter + day + dateTimeDelimiter + hour + timeDelimiter + min + timeDelimiter + sec + apm;
        if (format=='yyyymmddhhmm') return year + dateDelimiter + month + dateDelimiter + day + dateTimeDelimiter + hour + timeDelimiter + min + apm;
        if (format=='yyyymmdd') return year + dateDelimiter + month + dateDelimiter + day;
		if (format=='ddmmyyyy') return day + dateDelimiter + month + dateDelimiter + year;
		if (format=='hhmmss') return hour + timeDelimiter + min + timeDelimiter + sec + apm;
        if (format=='hhmm') return hour + timeDelimiter + min + apm;
	}
	//---------------------------------------------------------------------------------------------
	public waitFor(conditionFn:Function, chance:number=3, delay:number=1000):Promise<any> {
        return new Promise((resolve, reject)=>{
            chance--;
            let result:any;
            let err:any;
            try { result = conditionFn(); } catch(e) { err = e; }
                
            if (result) {
                resolve(result);
            } else if (chance>0) {
                setTimeout(()=>{
                    resolve(this.waitFor(conditionFn, chance, delay));
                }, delay);
            } else {
                reject(err || 'waitFor(' + conditionFn.toString() + ') retryEnd');
            }
        }).catch((e)=>{
			console.log(e);
		});
    }
    //---------------------------------------------------------------------------------------------
	public isEmailFormatValid(email:string):boolean {
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	public timeout(ms: number): Promise<void> {
		return new Promise((resolve, reject) => {
			setTimeout(resolve, ms);
		});
	}

	public audioPlay(src = null, options:any = {}){
		options.removeOther = options.removeOther === undefined?false:options.removeOther;
		options.repeat = options.repeat === undefined?false:options.repeat;
		options.volume = options.volume === undefined?1:options.volume;
		let els:any = document.querySelectorAll('body > audio.ro');
		if (src == null || options.removeOther){
			if (els.length > 0){
				els.forEach(el=>{
					el.pause();
					el.remove();
				});
			}
			if (src == null){
				return;
			}
		}
		let el = document.createElement('audio');
		el.classList.add('ro');
		if (options.className){
			el.classList.add(options.className);
		}
		document.body.appendChild(el);
		el.src = src;
		if (options.repeat){
			el.setAttribute('loop','true');
		}
		el.volume = options.volume;
		el.play();
		return new Promise(resolve=>{
			el.addEventListener('ended', ()=>{
				if (!options.repeat){
					el.remove();
				}
				resolve(null);
			});
		});
	}

	public uuidv4() {
		return uuidv4();
	}
}
