import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {TabViewComponent} from "./tab-view.component";

@NgModule({
	imports: [
		CommonModule,
	],
	exports: [
		TabViewComponent,
	],
	declarations: [
		TabViewComponent,
	],
	providers: [],
})
export class TabViewModule {
}
