import {Component, OnInit, ViewChild, Input, Output, EventEmitter} from '@angular/core';
import {Modal2Component} from "../../../../../sharedModule/modal2Module/modal2.component";
import {DataService} from "../../../../../service/data.service";
import {DomSanitizer} from '@angular/platform-browser';

@Component({
	selector: 'book-toc-modal',
	template: `
		<modal2
			#modal
			(cancelClick)="modal.close()"
			(confirmClick)="select_page()"
			[maxHeight]="'calc(100vh - 40px)'"
			[height]="'calc(100vh - 40px)'"
			[width]="'calc(100vw - 40px)'"
			[contentStyle]="{'overflow-y': 'auto', 'padding': '0px', 'display': 'flex', 'flex-direction': 'column'}"
			[title]="modal_title"
		>
			<div class="container" *ngIf="document">
				<div class="chapter-list">
					<div
						*ngFor="let chapter of document.chapters" (click)="current_chapter = chapter"
						class="chapter"
						[ngClass]="{selected: current_chapter === chapter}"
					>
						<div class="chapter-icon"></div>
						{{ chapter.title }}
					</div>
				</div>
				<div class="chapter-content" *ngIf="current_chapter">
					<div *ngFor="let group of current_chapter.node.children"
						 class="chapter-group"
						 [ngClass]="{'is-first-group': group === document.chapters[0].node.children[0]}"
					>
						<div class="group-title">
							{{ group.attributes.title }}
						</div>
						<div class="page-list">
							<div
								*ngFor="let page of group.children; let i = index;"
								class="page"
								[ngClass]="{selected: current_page === page}"
								(click)="current_page = page"
							>
								<div class="page-thumbnail">
									<img *ngIf="thumbnail_map[page.attributes.douid]" [src]="thumbnail_map[page.attributes.douid]">
								</div>
								<div class="page-title">
									<div class="page-slide-title">
										{{ page.attributes.slideTitle }}
									</div>
									<div class="page-no">
										{{ get_page_no(page) }}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</modal2>
	`,
	styleUrls: ['./book-toc-modal.component.scss'],
})

export class BookTocModalComponent implements OnInit {

	@Input() bookConfig = null;
	@Input() current_page_id = null;
	@ViewChild('modal', {static: false}) modal: Modal2Component;

	document = null;
	current_chapter = null;
	current_page = null;
	thumbnail_map = {};
	page_no_map = {};

	@Output() page_selected = new EventEmitter();

	constructor(
		public datas: DataService,
		public dom_sanitizer: DomSanitizer,
	) {
	}

	ngOnInit() {

	}

	open() {
		console.log(this.bookConfig);
		this.fetch_thumbnails();

		this.document = this.bookConfig.document;

		console.log({
			current_page_id: this.current_page_id,
		})

		if (this.current_page_id) {
			let page = this.bookConfig.pages.find(page => {
				return page.attributes.douid === this.current_page_id;
			});
			if (page) {
				let chapter = this.document.chapters.find(chapter => {
					return chapter.id === page.chapter.id;
				});
				if (chapter) {
					this.current_chapter = chapter;
					this.current_page = this.current_chapter.pageNodes.find(pageNode => {
						return pageNode.attributes.douid === this.current_page_id;
					});
				}
			}
		} else if (this.document.chapters.length) {
			this.current_chapter = this.document.chapters[0];
			if (this.current_chapter.pageNodes.length) {
				this.current_page = this.current_chapter.pageNodes[0];
			}
		}
		this.set_page_no();
		console.log({
			chapter: this.current_chapter,
			page: this.current_page,
		})

		return this.modal.open();
	}

	set_page_no() {
		this.page_no_map = {};
		this.bookConfig.pages.forEach((page, index) => {
			this.page_no_map[page.attributes.douid] = index + 1;
		});
	}

	async fetch_thumbnails() {
		if(!this.bookConfig) {
			return;
		}

		let ids = [];
		this.bookConfig.document.chapters.forEach(c => {
			ids = [
				...ids,
				...c.pageNodes.map(page => `${c.id}/${page.attributes.douid}`),
			];
		});

		let response = await this.datas.call('Resource.get_specific_thumbnails2', ids)
		console.log(response);
		for (let thumbnail of response.thumbnails) {
			this.thumbnail_map[thumbnail.pid] = this.dom_sanitizer.bypassSecurityTrustResourceUrl(`${response.base}${thumbnail.url}`);
		}
	}

	select_page() {
		this.page_selected.emit(this.current_page.attributes.douid);
		this.modal.close();
	}

	get modal_title() {
		if (!this.bookConfig) {
			return '';
		}
		let title = [];
		let book_title = this.bookConfig.book.title;
		if (book_title) {
			title.push(book_title);
		}
		if (this.current_chapter) {
			title.push(this.current_chapter.title);
		}


		return title.join('/');
	}

	get_page_no(page) {
		return this.page_no_map[page.attributes.douid];
	}
}