import { Component, Output, EventEmitter, ViewChild, ElementRef, forwardRef, Input, AfterViewInit, OnInit } from '@angular/core';
import { CommonService } from 'src/app/service/common.service';
import { StorageService } from 'src/app/service/storage.service';
import { faHeart as faHeartR } from '@fortawesome/pro-regular-svg-icons';
import { faSearch, faCheck, faCircle, faTrash, faChevronRight, faChevronDown, faTimes, faUserGroup, faFolderUser, faTriangleExclamation, faHeart as faHeartS } from '@fortawesome/pro-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from 'src/app/service/data.service';
import { AlertService } from 'src/app/service/alert.service';
import { environment } from 'src/environments/environment';
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as faCheckCircleLight } from '@fortawesome/pro-light-svg-icons';
import { BubbleBox2Component } from '../bubbleBox2Module/bubbleBox2.component';
import { ThemeService } from 'src/app/service/theme.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { OupService } from "../../service/oup.service";
import { SettingService } from "../../service/setting.service";

@Component({
	selector: "targetSelector",
	templateUrl: "targetSelector.component.html",
	styleUrls: ['./targetSelector.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => TargetSelectorComponent),
			multi: true
		}
	]
})


export class TargetSelectorComponent implements ControlValueAccessor, OnInit {
	@ViewChild("bubbleBox2", { static: true }) bubbleBox2: BubbleBox2Component;
	@ViewChild("searchInput", { static: true }) searchInput: ElementRef;


	public faSearch = faSearch;
	public faCheck = faCheck;
	public faCircle = faCircle;
	public faTrash = faTrash;
	public faChevronRight = faChevronRight;
	public faChevronDown = faChevronDown;
	public faTimes = faTimes;
	public faCheckCircle = faCheckCircle;
	public faCheckCircleLight = faCheckCircleLight;
	public faUserGroup = faUserGroup;
	public faFolderUser = faFolderUser;
	public faTriangleExclamation = faTriangleExclamation;
	public faArrowLeft = faArrowLeft;
	public faHeartR = faHeartR;
	public faHeartS = faHeartS;

	@Input() public allowForAll: boolean = true;
	@Input() public allowMember: boolean = true;
	@Input() public allowGroup: boolean = true;
	@Input() public allowGroupFolder: boolean = false;
	@Input() public onlyGroupFolder: boolean = false;
	@Input() public showMyGroup: boolean = true;
	@Input() public allowCrossYear: boolean = false;
	@Input() public multiple: boolean = true;
	@Input() public height: string = "350px";
	@Input() public anyOrForAll: boolean = false;
	@Input() public memberRole = 'all';//all | teacher | student
	@Input() public showYearOption: boolean = true;
	@Input() public mandatory: boolean = false;
	@Input() public enableKeywordSearch: boolean = false;
	@Input() public showLoadingWhenFetchData: boolean = true; //取消show loading,有機會user在未load前打開popup. 如有需要才不show loading
	@Input() public showMyFav: boolean = false;
	@Input() public options;

	public previousSelectedKeywords: any[] = [];
	public previousSelectedMemberByKeywords: any[] = [];
	public isTagLoaded: boolean = false;
	public searchText: string = "";
	public keywordSearchText: string = "";
	public tags: any[] = [];
	public groups: any[] = [];
	public members: any[] = [];
	public isForAll: boolean = false;
	public targetPhotos: any[] = [];
	public keywordSearch: boolean = false;
	public memberKeywordMap: any[] = [];
	public selectedKeywords: any[] = [];
	public selectedMemberByKeywords: any[] = [];
	public newKeywords: any[] = [];
	public removeKeywords: any[] = [];

	@Output() public membersLoaded = new EventEmitter<any>();
	//-----------------------------------------------------------
	@Output() public confirm: EventEmitter<any[]> = new EventEmitter();
	@Output() public cancel: EventEmitter<void> = new EventEmitter();
	@Output() public onClose: EventEmitter<void> = new EventEmitter();
	public years: any[] = [];
	public selectedYear: any = null;
	public targets: any[] = [];
	public tempTargets: any[] = [];
	public forAllTarget: any = { tType: 'school' };
	public isDirty: boolean = false;
	public sectionMainColor;
	public isDataReady: boolean = false;
	//-----------------------------------------------------------
	constructor(
		private coms: CommonService,
		public datas: DataService,
		public storage: StorageService,
		private translate: TranslateService,
		private sanitizer: DomSanitizer,
		private als: AlertService,
		public thms: ThemeService,
		public eleRef: ElementRef,
		public oup: OupService,
		public setting: SettingService,
	) {
	}

	ngOnInit(): void {
		//設定Lang
		//this.translate.use(this.datas.lang);
		//取得所有學年並設定現年
		this.coms.waitFor(() => (this.datas.userInfo.school_id && this.translate.translations[this.translate.currentLang].targetSelector), 30)
			.then(() => { this.getYearsApi(this.datas.userInfo.school_id); this.getKeyWordsApi(); });

		if (this.onlyGroupFolder) {
			this.showMyFav = false;
			this.showMyGroup = false;
			this.allowForAll = false;
		}

		if (this.allowCrossYear) {
			this.allowForAll = false;
			this.showMyFav = false;
		}

		this.loadSectionMainColor();
	}

	//-implements ControlValueAccessor---------------------------------------------------------------
	private onChange = (v: any) => { };
	private onTouched = () => { };
	public isDisabled: boolean = false;

	writeValue(targets: any[]): void {
		//if (targets) {
		this.targets = targets || [];
		//this.tempTargets = [...targets];
		this.targetsWaitingToSetSelected = this.targets
		this.setTagAndMemberSelected(this.targets);
		//this.updateTempTargets();
		//}

	}
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouched = fn;
	}
	setDisabledState?(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
	//-------------------------------------------------------------------------------------------------
	public open(targetEle: HTMLElement, appendToEle: HTMLElement = document.body, backgroundColor: string = ""): Promise<any> {
		this.selectedKeywords = [...this.previousSelectedKeywords];
		this.selectedMemberByKeywords = [...this.previousSelectedMemberByKeywords];
		if (this.bubbleBox2.opened)
			return Promise.reject("opened");

		this.isDirty = false;
		return this.bubbleBox2.open(targetEle, appendToEle, backgroundColor).then(() => {
			this.cancelClick();
		});
	}
	onBubbleBoxOpened(): void {
		if (this.searchInput && this.searchInput.nativeElement) {
			this.searchInput.nativeElement.focus();
		}
	}
	//-------------------------------------------------------------------------------------------------
	//-------------------------------------------------------------------------------------------------
	public getYearsApi(schoolId: number, selectedYearId: number = null): void {
		this.datas.post2({
			data: { api: 'ROUserAndGroup.getSchoolYear', json: [schoolId] }, loading: this.showLoadingWhenFetchData
		}).then((res: any) => {
			if (res && res.years) {
				this.years = res.years;

				let curUnixTime: number = Math.floor(Date.now() / 1000);
				this.years.map(year => {
					if (year.start_time && year.end_time && year.start_time < curUnixTime && year.end_time > curUnixTime) year.current = true;
				});

				let selectedYear: any = null;
				if (selectedYearId) selectedYear = this.years.find((year: any) => year.id == selectedYearId);
				if (!selectedYear) selectedYear = this.years.find((year: any) => year.current);
				if (!selectedYear) selectedYear = this.years[this.years.length - 1];
				this.selectedYear = selectedYear;
				this.tagYearChange();
			}
		});
	}
	//-------------------------------------------------------------------------------------------------
	private updateForAllTarget(yearId: number): void {
		this.forAllTarget.value = this.datas.userInfo.school_id;
		this.forAllTarget.title = this.translate.instant('targetSelector.all-users');
		this.forAllTarget.yearId = yearId;
	};
	//-------------------------------------------------------------------------------------------------
	public tagYearChange(): void {
		this.isDataReady = false;
        this.updateForAllTarget(this.selectedYear.id)
        this.getTagsAndMembersApi();
    }
  //-------------------------------------------------------------------------------------------------
	private getTagsAndMembersApi() {
		this.coms.waitFor(() => this.selectedYear, 30).then(async () => {
			const yearId = this.selectedYear.id;
			let ordered = !this.oup.isOupUser;
			this.datas.get2({ data: { api: 'TargetSelector.getTagsAndMembers', json: JSON.stringify([yearId, this.memberRole, ordered]) }, loading: this.showLoadingWhenFetchData }).then(async (res: any) => {
				this.tags = res.tags ? res.tags : [];
				this.tags.forEach(tag => {
					tag._title = tag.title;
					Object.defineProperty(tag, 'title', {
						get: () => {
							if (this.oup.isOupUser) {
								return this.oup.get_class_name(tag._title) || tag._title;
							} else {
								return tag._title;
							}
						},
						set: (value) => {
							tag._title = value;
						}
					});
				})
				if (this.oup.isOupUser) {
					await this.coms.waitFor(() => this.oup._is_fetching_all_data_done, 9999999, 100);
					this.tags = this.sort_tags(this.tags);
				}
				console.log({tags: this.tags});
				this.groups = res.groups ? res.groups : [];
				this.members = res.members ? res.members : [];
				this.members.forEach(m => {
					let url = './assets/img/avatar_student_male.svg';
					if (m.url) {
						url = environment.resourceServer[m.server] + m.url;
					} else {
						url = './assets/img/avatar_' + (m.user_role > 2 ? 'teacher' : 'student') + '_' + (m.gender == 1 ? 'male' : 'female') + '.svg';
					}
					m.photo = this.sanitizer.bypassSecurityTrustStyle("url('" + url + "')");
					m.selected = false;
					return m;
				});
				this.coms.waitFor(() => this.datas.userInfo, 20, 300).then(() => {
					if (this.showMyFav) {
						this.initFavGroup();
					}
					this.handleData();
					this.isTagLoaded = true;
					if (this.targetsWaitingToSetSelected) this.setTagAndMemberSelected(this.targetsWaitingToSetSelected);
					this.membersLoaded.emit(this.members);
				});

				// if (this.oup.isOupUser) {
				// 	this.coms.waitFor(() => this.oup._is_fetching_all_data_done, 9999999, 100).then(() => {
				// 		this.tags.forEach(tag => {
				// 			if (tag._child) {
				// 				tag._child = this.sort_tags(tag._child);
				// 			}
				// 		});
				// 		this.updateTempTargets();
				// 	});
				// }
			});
		});
	}

	//-------------------------------------------------------------------------------------------------
	private getKeyWordsApi() {
		this.datas.post2({ data: { api: 'TargetSelector.getMembersWithKeywords' }, loading: this.showLoadingWhenFetchData }).then((res: any) => {
			this.memberKeywordMap = res;
		});
	}

	updateTempTargets() {
		//const filterData = this.filterData;
		if (this.allowCrossYear) {
			this.tags.forEach(tag => {
				if (tag.type == 3 || tag.type == 9) {
					const index = this.tempTargets.findIndex(e => e.value == tag.id && e.tType == 'tag');
					if (tag.selected && index === -1) {
						let item: any = { tType: 'tag', type: tag.type, value: tag.id, title: tag.title, school_year_id: this.selectedYear.id, ctype: tag.ctype, subject_id: tag.subject_id };
						if (tag.type == '9') {
							item._child = tag._child;
						}
						const found = this.years.find(e => e.id == this.selectedYear.id);
						if (found) {
							item.yearName = found.title;
						}
						this.tempTargets.push(item);
						this.targetPhotos.push(null);
					} else if (!tag.selected && index > -1) {
						this.tempTargets.splice(index, 1);
						this.targetPhotos.splice(index, 1);
					}
				}
			});
			this.isDirty = true;
			return;
		}
		this.tempTargets = [];
		this.targetPhotos = [];
		if (this.allowForAll && this.isForAll) {
			this.tempTargets = [this.forAllTarget];
			this.targetPhotos.push(null);
		}
		this.tags.forEach(t => {
			if ((t.type == 3 || t.type == 9) && t.selected) {
				let item: any = { tType: 'tag', type: t.type, value: t.id, title: t.title, school_year_id: this.selectedYear.id, ctype: t.ctype, subject_id: t.subject_id };
				if (t.type == '9') {
					item._child = t._child;
				}
				this.tempTargets.push(item);
				this.targetPhotos.push(null);
			}
			if (t._members) {
				t._members.forEach(m => {
					if (m.selected) {
						const found = this.tempTargets.find(t => t.value == m.uid && t.tType == 'people');
						if (!found) {
							this.tempTargets.push({ tType: 'people', value: m.uid, title: this.storage.lang == 'en' ? m.ename : m.cname, school_year_id: this.selectedYear.id });
							this.targetPhotos.push(m.photo);
						}
					}
				})
			}
		});

		this.members.forEach(m => {
			if (m.selected) {
				const found = this.tempTargets.find(t => t.value == m.uid && t.tType == 'people');
				if (!found) {
					this.tempTargets.push({ tType: 'people', value: m.uid, title: this.storage.lang == 'en' ? m.ename : m.cname, school_year_id: this.selectedYear.id });
					this.targetPhotos.push(m.photo);
				}
			}
		});
		this.isDirty = true;
	}
	//-------------------------------------------------------------------------------------------------
	public isSelectedKeyword(keyword: string): boolean {
		return this.selectedKeywords.includes(keyword);
	}
	//-------------------------------------------------------------------------------------------------
	private handleData(): void {
		//this.yearFilteredTagList = this.tagList.filter(tag=>tag.school_year==this.selectedYear.id);
		this.tags.unshift({
			id: "myTag",
			pid: "myTag",
			title: this.translate.instant("targetSelector.my-groups"),
			type: "9",
			school_year: 0,
			year_name: "",
			my_tag: 0,
			selected: false,
			open: true,
			color: "#FFDF5F"
		});
		this.tags.push({
			id: this.selectedYear.id + "-0",
			pid: "dummyOthers",
			title: this.translate.instant("targetSelector.others"),
			type: "9",
			school_year: 0,
			year_name: "",
			my_tag: 0,
			selected: false
		});

		this.tags.map(tag => {
			tag.selected = false;
			let childTags: any[] = this.tags.filter(cTag => cTag.pid == tag.id);
			if (tag.id == 'myFav') {
				if (tag._child) {
					tag._child = tag._child.sort((a, b) => {
						if (a._title < b._title) {
							return -1;
						} else if (a._title > b._title) {
							return 1;
						} else {
							return 0;
						}
					});
				}
			} else if (tag.type == "3") {
				let parentTag: any = this.tags.find(pTag => pTag.id == tag.pid);
				if (tag.pid != 'dummyOthers' && !parentTag && childTags.length < 1) tag.pid = this.selectedYear.id + "-0";
			} else if (tag.type == '9') {
				tag._child = childTags;
			}
			tag.my_tag = parseInt(tag.my_tag);

			this.groups.filter(g => parseInt(g.tid) == parseInt(tag.id)).map(g => {
				let foundMember: any = this.members.find(m => parseInt(m.uid) == parseInt(g.uid));
				if (foundMember) {
					if (!tag._members) tag._members = [];
					tag._members.push(foundMember);
				}
			});
		});
		this.tags.filter(tag => tag.type == 9).map(tag => {
			if (tag.id != 'myFav') {
				tag._child = this.tags.filter(child => child.pid && child.pid == tag.id);
			}
			return tag;
		});
		this.tags[0]._child = this.tags.filter(tag => tag.my_tag == 1);

		if (this.allowCrossYear && this.tempTargets && this.tempTargets.length > 0) {
			this.tempTargets.forEach(tag => {
				const found = this.tags.find(e => e.id == tag.value);
				if (found) {
					found.selected = true;
				}
			});
		}
		//this.isForAll = this.targets.find((t:any)=>t.tType=='school') ? true : false;
		/*this.targets.forEach((t,i)=>{
		  if (t.tType == 'school'){
			this.isForAll = !this.isForAll;
			this.targetPhotos.push(null);
		  } else if (t.tType == 'tag'){
			const found = this.tags.find(t1=> t1.id == t.value);
			if (!found){
				this.targets.splice(i, 1);
			}
		  } else {
			const found = this.members.find(m=> m.id == t.value);
			if (!found){
				this.targets.splice(i, 1);
			}
		  }
		});*/

		const selectedTagIds = this.targets.filter(t => t.tType == 'tag').map(t => t.value);
		this.tags.forEach(t => {
			if (t.type == 3) {
				if (selectedTagIds.indexOf(t.id) > -1) {
					t.selected = true;
				}
			}
		});
		const selectedMember = this.targets.filter(t => t.tType == 'people').map(t => t.value);

		this.isDataReady = true;
	}
	//-------------------------------------------------------------------------------------------------
	public toggleIsForAllSelect(): void {
		let curIsForAllValue: boolean = this.isForAll;
		if (curIsForAllValue && this.mandatory && this.tempTargets.length == 1) return; //如果是必選，又沒有其他已選目標，就不進行取消選取
		if (!this.multiple || (!this.isForAll && this.anyOrForAll)) this.clearAll();
		this.isForAll = !curIsForAllValue;
		this.updateTempTargets();
		// this.changed.emit(this.getSelectedResult());
	}
	//-------------------------------------------------------------------------------------------------
	/*public setGroupSelect(group: any, value: boolean): void {
	  if (group.selected == value) return;
	  group.selected = value;
	  group._child.map(tag => this.setTagSelect(tag, value, group));
	}*/
	//-------------------------------------------------------------------------------------------------
	public setTagSelect(tag: any, value: boolean): void {
		if (this.options && this.options.beforeChange && !value) {
			const changeable = this.options.beforeChange(tag, this);
			if (!changeable) {
				return;
			}
		}
		if (!value && this.mandatory && this.tempTargets.length == 1) return; //如果是必選，又沒有其他已選目標，就不進行取消選取
		if (!this.multiple) this.clearAll();
		if (value && this.anyOrForAll) this.isForAll = false;
		this.tags.filter((t: any) => t.id == tag.id).map((t: any) => t.selected = value);
		this.updateTempTargets();
	}
	//-------------------------------------------------------------------------------------------------
	public setMemberSelect(member: any, value: boolean): void {
		if (!value && this.mandatory && this.tempTargets.length == 1) return; //如果是必選，又沒有其他已選目標，就不進行取消選取
		if (!this.multiple) this.clearAll();
		if (value && this.anyOrForAll) this.isForAll = false;
		this.tags.forEach(t => {
			if (t._members) t._members.filter((m: any) => m.uid == member.uid).map((m: any) => m.selected = value);
		});
		this.updateTempTargets();
	}
	//-------------------------------------------------------------------------------------------------
	public isAllChildSelected(childs: any[] = []): boolean {
		let selectedItem: any[] = childs.filter(child => child.selected);
		return (selectedItem.length > 0 && selectedItem.length == childs.length);
	}
	//-------------------------------------------------------------------------------------------------
	public getSelectedItems(): any[] {
		return this.tags.filter(tag => tag.type != 9 && tag.selected);
		/*
		let groups:any[] = this.tags.filter(group=>group.type==9);
		let result:any[] = [];
		groups.map(group=>{
			if (group.selected && result.indexOf(group)<0) {
				result.push(group);
			} else {
				group._child.map(tag=>{
					if (tag.selected && result.indexOf(tag)<0) {
						result.push(tag);
					} else {
						tag._members.map(member=>{
							if (member.selected && result.indexOf(member)<0) result.push(member);
						});
					}
				});
			}
		});
		return result;*/
	}
	//-------------------------------------------------------------------------------------------------
	public unselectItem(item: any): void {
		if (this.options && this.options.beforeChange) {
			const changeable = this.options.beforeChange(item);
			if (!changeable) {
				if (this.options.beforeChangeToast) {
					this.als.toastError(this.options.beforeChangeToast);
				}
				return;
			}
		}
		if (item.tType == 'school') {
			this.isForAll = false;
		} else if (item.tType == 'tag') {
			const found = this.tags.find(t => t.id == item.value);
			if (found) { //crossYear時會found唔到.
				found.selected = false;
			}
			if (this.allowCrossYear) {
				this.tempTargets = this.tempTargets.filter(e => e.value != item.value);
				this.isDirty = true;
				return;
			}
		} else {
			this.tags.forEach(t => {
				if (t._members) {
					t._members.forEach(m => {
						if (m.uid == item.value) {
							m.selected = false;
						}
					});
				}
			});
			this.members.forEach(m => {
				if (m.uid == item.value) {
					m.selected = false;
				}
			});
		}
		this.updateTempTargets();
	}
	//-------------------------------------------------------------------------------------------------
	// public getSelectedResult(): any[] {
	//   let output = [];
	//   if (this.isForAll) {
	//     const lang = this.storage.lang;
	//     output.push({ type: 'school', id: this.storage.userInfo.school_id, title: lang == 'en' ? 'All users' : '全校' });
	//   }
	//   this.getSelectedItems().forEach(tag => {
	//     output.push({ type: 'tag', id: tag.id, title: tag.title });
	//   });
	//   return output;
	// }
	//-------------------------------------------------------------------------------------------------
	public getSearchResult(): any[] {
		let searchedTags: any[] = [];
		let searchedPeople: any[] = [];

		this.tags.map((tag: any) => {
			if (
				this.allowGroup &&
				(tag.type != 9)
				&& (tag.title.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1)
				&& !searchedTags.includes(tag)
				&& (this.allowForAll || tag.tType != 'school')
			) searchedTags.push(tag);
			if (tag._members && this.allowMember) tag._members.map((member: any) => {
				if (
					(
						member.cname.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1 ||
						member.ename.toLowerCase().indexOf(this.searchText.toLowerCase()) > -1
					) &&
					searchedPeople.indexOf(member) == -1
				) searchedPeople.push(member);
			})
		});

		return [...searchedTags, ...searchedPeople];
	}
	//-------------------------------------------------------------------------------------------------
	public setKeywordSelect(keyword: string, addRecord = true) {
		if (this.selectedKeywords.includes(keyword)) {
			this.selectedKeywords.splice(this.selectedKeywords.indexOf(keyword), 1);
			for (let i = this.selectedMemberByKeywords.length - 1; i >= 0; i--) {
				var item = this.tempTargets.find(item => item.value == this.selectedMemberByKeywords[i].uid);
				if (this.selectedMemberByKeywords[i].keywords && this.selectedMemberByKeywords[i].keywords.toLowerCase().includes(keyword.toLowerCase())) {
					if (this.selectedMemberByKeywords[i] && this.showKeywordLabel(item)) {
					} else {
						this.setMemberSelect(this.selectedMemberByKeywords[i], false);
						this.selectedMemberByKeywords.splice(i, 1);
					}

				}
			}
			if (addRecord)
				this.addRecord(keyword, "delete");
		} else {
			this.selectedKeywords.push(keyword);
			this.members.forEach((member) => {
				if (member.keywords && member.keywords.toLowerCase().includes(keyword.toLowerCase())) {
					if (!this.selectedMemberByKeywords.includes(member) && member.selected == false) {
						this.setMemberSelect(member, true);
						this.selectedMemberByKeywords.push({ ...member });
					}
				}
			})
			if (addRecord)
				this.addRecord(keyword, "add");
		}
	}
	//-------------------------------------------------------------------------------------------------

	private addRecord(keyword, action = "add" || "delete") {
		if (action == "delete") {
			if (this.newKeywords.includes(keyword)) {
				this.newKeywords.splice(this.newKeywords.indexOf(keyword), 1);
			} else if (!this.removeKeywords.includes(keyword)) {
				this.removeKeywords.push(keyword);
			}
		}
		else if (action == "add") {

			if (this.removeKeywords.includes(keyword)) {
				this.removeKeywords.splice(this.removeKeywords.indexOf(keyword), 1);
			} else if (!this.newKeywords.includes(keyword)) {
				this.newKeywords.push(keyword);
			}
		}
	}

	//-------------------------------------------------------------------------------------------------
	public getKeywordsResult(): any[] {
		let searchedKeyWords: any[] = [];
		let allKeyWords: any[] = [];

		this.memberKeywordMap.forEach(obj => {
			var keywords = obj.keywords.split(', ');
			keywords.forEach(keyword => {
				if (keyword.toLowerCase().includes(this.keywordSearchText.toLowerCase()) && !searchedKeyWords.includes(keyword.toLowerCase())) {
					searchedKeyWords.push(keyword.toLowerCase());
				}
				if (!allKeyWords.includes(keyword.toLowerCase())) {
					allKeyWords.push(keyword.toLowerCase());
				}
			});
		});
		if (this.keywordSearchText) {
			return searchedKeyWords;
		}
		return allKeyWords;
	}

	//-------------------------------------------------------------------------------------------------
	public showKeywordLabel(item) {
		if (item && item.tType == 'people' && this.selectedKeywords) {
			let result = null;  // Initialize the result variable

			this.members.forEach((member) => {
				if (member.uid == item.value) {
					this.selectedKeywords.forEach((keyword) => {
						if (member.keywords && member.keywords.toLowerCase().includes(keyword.toLowerCase())) {
							if (result == null) {
								result = keyword;  // Assign the keyword to the result variable
							} else {
								result = result + ', ' + keyword;  // Assign the keyword to the result variable
							}
						}
					});
				}
			});

			return result;  // Return the result outside of the loop
		} else if (this.allowCrossYear && item.tType == 'tag') {
			const year = this.years.find(e => e.id == item.school_year_id);
			return year ? year.title : '';
		}

		return null;
	}

	//-------------------------------------------------------------------------------------------------
	public tagClick(tag): void {
		if (this.options && this.options.beforeChange) {
			const changeable = this.options.beforeChange(tag);
			if (!changeable) {
				if (this.options.beforeChangeToast) {
					this.als.toastError(this.options.beforeChangeToast);
				}
				return;
			}
		}
		if (!tag.open) {
			/*if (!tag._members){
			  this.datas.get2({ data: { api:'AnalyticsChart.getTagPeople', json:JSON.stringify([tag.id]) } }).then((res:any)=>{
			  //this.datas.get(['AnalyticsChart.getTagPeople', tag.id]).subscribe((res:any)=>{
				if (res.peoples){
				  tag._members = res.peoples.map(m=>{
					const found = this.members.find(m1=>m1.uid == m.uid);
					m.photo = found.photo;
					return m;
				  });
				  this.setTagAndMemberSelected(this.tempTargets);
				  this.updateTempTargets();
				  tag.open = 1;
				}
			  });
			} else {*/
			tag.open = 1;
			//}
		} else {
			tag.open = 0;
		}
	}

	//-------------------------------------------------------------------------------------------------
	public clearAll(): void {
		this.selectedKeywords.forEach(keyword => {
			setTimeout(() => {
				this.setKeywordSelect(keyword, false);
			}, 5); // Introducing a minimal delay
		});
		this.targetPhotos = [];
		this.isForAll = false;
		this.members.forEach(m => {
			m.selected = false;
		});

		this.tags.forEach(t => {
			t.selected = false;
			if (t._members) {
				t._members.forEach(m => {
					m.selected = false;
				})
			}
		});
		//this.targets = [];
		this.updateTempTargets();
	}

	selectAllChild(event, tag) {
		event.stopPropagation();
		if (tag._child) {
			tag._child.forEach(m => {
				m.selected = true;
			});
		}
		if (tag._members) {
			tag._members.forEach(m => {
				m.selected = true;
			});
		}
		this.updateTempTargets();
	}
	//-------------------------------------------------------------------------------------------------
	public confirmClick(): void {
		this.targets = [...this.tempTargets];
		this.newKeywords = [];
		this.removeKeywords = [];
		this.previousSelectedKeywords = [...this.selectedKeywords];
		this.previousSelectedMemberByKeywords = [...this.selectedMemberByKeywords];
		this.confirm.emit(this.targets);
		this.onTouched();
		this.onChange(this.targets);
		this.bubbleBox2.close();
	}
	//-------------------------------------------------------------------------------------------------
	public cancelClick(): void {
		this.newKeywords.forEach(keyword => {
			this.setKeywordSelect(keyword, false);

		});
		this.removeKeywords.forEach(keyword => {
			this.setKeywordSelect(keyword, false);
		});
		this.newKeywords = [];
		this.removeKeywords = [];
		this.cancel.emit();
		this.bubbleBox2.close();
		this.setTagAndMemberSelected(this.targets);
	}
	//-------------------------------------------------------------------------------------------------
	private targetsWaitingToSetSelected: any[] = null;
	public setTagAndMemberSelected(targets: any[]): void {
		if (!this.allowCrossYear) {
			this.clearAll();
		}
		if (this.isTagLoaded) {
			if (targets && targets.length > 0) {
				this.isForAll = (this.allowForAll && targets && targets.find((t: any) => t.tType == 'school')) ? true : false;

				for (let tag of this.tags) {
					tag.selected = (targets && targets.find((t: any) => t.tType == 'tag' && t.value == tag.id)) ? true : false;

					if (tag._members) for (let member of tag._members) {
						member.selected = (targets && targets.find((t: any) => t.tType == 'people' && t.value == member.uid)) ? true : false;
					}
				}
				this.updateTempTargets();
			} else if (this.allowForAll && this.mandatory) {
				this.isForAll = true;
				this.updateTempTargets();
				this.targets = [...this.tempTargets];
				this.onChange(this.targets);
			}
		} else {
			this.targetsWaitingToSetSelected = targets;
			//setTimeout(()=>this.setTagAndMemberSelected(targets), 100);
		}
	}
	//-------------------------------------------------------------------------------------------------
	public getTarget(tType: string, value: number): any {
		if (tType == 'school') {
			return this.forAllTarget;
		} else if (tType == 'tag') {
			return this.tags.find((tag: any) => tag.id == value);
		} else if (tType == 'people') {
			return this.members.find((member: any) => member.uid == value);
		}
	}
	//-------------------------------------------------------------------------------------------------
	public getBgColor(): string {
		return this.thms.getStyleValue('section-maincolor', this.eleRef.nativeElement) || this.thms.getStyleValue('wpopup-bgcolor', this.eleRef.nativeElement) || '#FFFFFF';
	}
	//-------------------------------------------------------------------------------------------------
	public getLeftBgColor(): string {
		let bgColor: string = this.getBgColor();
		let rgbArray: number[] = [255, 255, 255];
		if (bgColor.startsWith('rgb') || bgColor.startsWith('RGB')) {
			rgbArray = bgColor
				.split("(")[1]
				.split(")")[0]
				.split(",")
				.map((x: string) => parseInt(x));
		} else if (bgColor.startsWith('#')) {
			rgbArray = bgColor
				.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b)
				.substring(1)
				.match(/.{2}/g)
				.map(x => parseInt(x, 16));
		}

		let result: string = "#" + [rgbArray[0], rgbArray[1], rgbArray[2]]
			.map((x: number) => (Math.round(x * 0.8)).toString(16))
			.map((x: string) => (x.length == 1) ? "0" + x : x)
			.join("");
		return result;
	}
	//-------------------------------------------------------------------------------------------------
	public getSelectedTitle(): string {
		return this.targets.filter((t: any) => t.tType == 'school')
			.concat(this.targets.filter((t: any) => t.tType == 'tag'))
			.concat(this.targets.filter((t: any) => t.tType == 'people'))
			.map((t: any) => t.title)
			.join(', ');


	}
	//-------------------------------------------------------------------------------------------------
	public getMembersOfTag(tagId: number, level: number = 5): any[] {
		let result: any[] = [];
		if (level < 0) return [];

		let tag: any = this.tags.find((item: any) => item.id == tagId);
		if (tag) {
			if (tag._child && tag._child[0]) tag._child.map((childTag) => {
				this.getMembersOfTag(childTag.id, level - 1).map((member: any) => result.push(member));
			});
			if (tag._members && tag._members[0]) tag._members.map((member) => result.push(member));
		}
		return result;
	}
	//-------------------------------------------------------------------------------------------------
	public getMember(uid: number): any {
		return this.members.find((m: any) => m.uid == uid);
	}
	//-------------------------------------------------------------------------------------------------
	public getTag(tid: number): any {
		return this.tags.find((t: any) => t.id == tid);
	}
	//-------------------------------------------------------------------------------------------------
	public getTagsOfMember(uid: number): any {
		let result: any[] = [];
		this.tags.map((t: any) => {
			if (t._members && t._members[0]) t._members.map((m) => {
				if (!result.includes(m) && m.uid == uid) result.push(t);
			});
			// if (t._child && t._child[0]) t._child.map((childTag)=>{
			// 	if (childTag._members && childTag._members[0]) childTag._members.map((m)=>{
			// 		if (!result.includes(m) && m.uid==uid) result.push(t);
			// 	});
			// });
		});
		return result;
	}
	//-------------------------------------------------------------------------------------------------
	public getSelectedUids(targets: any[] = this.targets): number[] {
		let result: number[] = [];

		let addUid: Function = (uid: number) => {
			if (!result.includes(uid)) result.push(uid);
		}

		let addTag: Function = (tagId: any) => {
			let members: any[] = this.getMembersOfTag(tagId);
			members.map((m: any) => addUid(parseInt(m.uid)));
		}

		for (let target of targets) {
			if (target.tType == 'school') {
				for (let tag of this.tags) addTag(parseInt(tag.id));
			} else if (target.tType == 'tag') {
				addTag(parseInt(target.value));
			} else if (target.tType == 'people') {
				addUid(parseInt(target.value));
			}
		}

		return result;
	}
	//-------------------------------------------------------------------------------------------------
	public setYear(yearId: number): void {
		let foundYear: any = this.years.find((y: any) => y.id == yearId);
		if (foundYear) {
			this.selectedYear = foundYear;
			this.tagYearChange();
		}
	}
	//-------------------------------------------------------------------------------------------------
	tagHeartClick(tag) {
		if (this.options && this.options.favBeforeChange) {
			this.options.favBeforeChange(tag, this);
		}
		this.datas.post2({ data: { api: 'ROWorkspace.setFavTagId', json: [tag.id] } }).then(async (res: any) => {
			if (
				res.personalSettings &&
				res.personalSettings.fav_tag_ids &&
				this.datas.userInfo
			) {
				if (!this.datas.userInfo.personalSettings) {
					let response = await this.datas.call('Account.getUpdateSetting');
					await this.setting.handleData(response);
				}
				this.datas.userInfo.personalSettings.fav_tag_ids = res.personalSettings.fav_tag_ids;
			}
			this.initFavGroup();
		});
	}
	//-------------------------------------------------------------------------------------------------
	initFavGroup() {
		let favTags = [];
		const psFavTagIds = this.datas.userInfo && this.datas.userInfo.personalSettings && this.datas.userInfo.personalSettings.fav_tag_ids ? this.datas.userInfo.personalSettings.fav_tag_ids : [];
		console.log('this.datas.userInfo.personalSettings', this.datas.userInfo.personalSettings);
		if (psFavTagIds) {
			favTags = psFavTagIds.map(tid => {
				const tag = this.tags.find(e => e.id == tid);
				return tag;
			}).filter(e => e);
		}
		let favGroup = this.tags.find(e => e.id == 'myFav');
		favTags = favTags.sort((a,b)=>{
			if (a._title < b._title) {
				return -1;
			} else if (a._title > b._title) {
				return 1;
			} else {
				return 0;
			}
		});
		if (favGroup) {
			favGroup._child = favTags;
		} else {
			const title = { tc: '我的最愛', sc: '我的最爱', en: 'My favourites' }[this.datas.lang];
			this.tags.unshift({ id: 'myFav', _child: favTags, title: title, type: 9, open: false });
		}
	}
	//-------------------------------------------------------------------------------------------------
	public isMyFavTag(tag: any): boolean {
		let myFavGroup: any = this.tags.find((gp: any) => gp.id == 'myFav');
		if (myFavGroup) {
			if (tag && tag.id && myFavGroup._child) {
				return myFavGroup._child.find((t: any) => t && t.id == tag.id);
			}
		}
		return false;
	}
	//-------------------------------------------------------------------------------------------------
	loadSectionMainColor() {
		let color = null;
		const section = <HTMLElement>document.querySelector('router-outlet + ng-component');
		if (section) {
			color = section.style.getPropertyValue('--section-maincolor') || null;
		}
		if (color == null) {
			color = document.body.style.getPropertyValue('--section-maincolor') || null;
		}
		this.sectionMainColor = color;
	}
	//-------------------------------------------------------------------------------------------------
	private getTagOfMember(uid: number) {
		return this.tags.find(tag =>
			tag._members && tag._members.some(member => member.uid === uid)
		)
	}
	//-------------------------------------------------------------------------------------------------
	public getSelectedResult() {
		const filteredTargets = this.targets.filter((t: any) =>
			t.tType === 'school' || t.tType === 'tag' || t.tType === 'people'
		)

		return filteredTargets.map((t: any) => {
			if (t.tType === 'people') {
				const tag = this.getTagOfMember(t.value)
				t.groupId = tag ? tag.id : null
			}
			return t
		})
	}

	sort_tags(tags) {
		return [...tags].sort((a, b) => {
			return a.title.localeCompare(b.title);
		});
	}
}
