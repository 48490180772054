import * as moment from 'moment';

export const ifLastValue = (key, defaultValue)=>{
	let lastValue = localStorage.getItem('okaSelect-last-' + key);
	if (lastValue == null){
		return defaultValue;
	} else if (lastValue.indexOf('{') == 0 && lastValue.indexOf('}') == lastValue.length - 1){
		return JSON.parse(lastValue);
	} else {
		return lastValue;
	}
};

export const delay = async (ms = 0)=>{
	return new Promise(resolve=>{
		setTimeout(()=>{
			resolve(true);
		}, ms);
	});
}

export const getMomentFromObject = (date: any)=>{
	if (date == null){
		return null;
	} else if (date instanceof Date){
		return moment(date);
	} else if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(date)){ //isoDate
		return moment(date);
	} else if (/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/.test(date)){// YYYY-MM-DD HH:mm:ss
		return moment(date, 'YYYY-MM-DD HH:mm:ss');
	} else if (/^\d{4}-\d{2}-\d{2}$/.test(date)){// YYYY-MM-DD HH:mm:ss
		return moment(date + ' 00:00:00', 'YYYY-MM-DD HH:mm:ss');
	}
	return null;
};

export const preloadImage = (imgs:any)=>{
	return new Promise((resolve,reject)=>{
		let imgArr = imgs === 'string'?[ imgs ]:imgs;
		let loaded = 0;
		let errored = false;
		imgArr.forEach(img=>{
			const imgEle = new Image();
			imgEle.onload = ()=>{
				loaded++;
			};
			imgEle.onerror = err=>{
				errored = true;
				reject(err);
			};
			imgEle.src = img;
		});
		const checkLoaded = ()=>{
			if (loaded == imgArr.length){
				resolve(null);
			} else if (!errored){
				setTimeout(()=>{ checkLoaded();}, 50);
			}
		};
		checkLoaded();
	});
};