import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SettingService } from 'src/app/service/setting.service';
import { faSearch, IconDefinition, faRedo, faBell } from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons';
import { NotificationService } from 'src/app/service/notification.service';
import { DataService } from 'src/app/service/data.service';
import { CommonService } from 'src/app/service/common.service';

@Component({
    selector: 'topRightToolBar',
    template: `
    <div class="btns">
        <ng-container *ngFor="let textBtn of textBtns">
            <ng-container *ngIf="(!textBtn.showIf || textBtn.showIf()) && (!textBtn.hideIf || !textBtn.hideIf())">
                <div class="textBtn" (click)="textBtn.click && textBtn.click()">{{textBtn.text|translate}}</div>
            </ng-container>
        </ng-container>

        <ng-container *ngFor="let iconBtn of iconBtns">
            <ng-container *ngIf="(!iconBtn.showIf || iconBtn.showIf()) && (!iconBtn.hideIf || !iconBtn.hideIf())">
                <search-button #search_button
                    *ngIf="iconBtn.type=='search'"
                    [attr.title]="(iconBtn.title?iconBtn.title:'common.search')|translate" 
					(onSearch)="iconBtn.click && iconBtn.click($event)"
                    [placeholder]="(iconBtn.placeholder?iconBtn.placeholder:'topRightToolBar.searchBoxPlaceholder')|translate" 
                    (onClear)="iconBtn.click && iconBtn.click('')"
					[advanced]="iconBtn.advanced"
					(emitter)="searchBoxEmitter($event)"
                ></search-button>
                <headerIconBtn *ngIf="iconBtn.type=='refresh'" [icon]="(iconBtn.icon?iconBtn.icon:faRedo)" 
					[attr.title]="(iconBtn.title?iconBtn.title:'common.refresh')|translate" 
					(click)="iconBtn.click && iconBtn.click($event)"></headerIconBtn>
                <headerIconBtn *ngIf="!iconBtn.type && iconBtn.icon" 
					[icon]="iconBtn.icon" [iconWrapperStyle]="iconBtn.style" 
					[attr.title]="(iconBtn.title?iconBtn.title:'')|translate" 
					(click)="iconBtn.click && iconBtn.click($event)"></headerIconBtn>
            </ng-container>
        </ng-container>
		
		<!--通知btn-->
		<div *ngIf="ntfs.enableNotification" class="notifyBtnWrapper">
			<headerIconBtn [icon]="faBell" [attr.title]="'common.notify'|translate" [iconWrapperStyle]="{color:notifyIconColor}" (click)="notifyBtnClick($event)"></headerIconBtn>
			<div class="unreadNum" *ngIf="ntfs.unread>0">{{ntfs.unread < 100 ? ntfs.unread : '99+'}}</div>
		</div>
		<notificationPopup #notificationPopup></notificationPopup>
        
		<ng-container *ngIf="getIconBtn('add'); let iconBtn;" >
			<ng-container *ngIf="(!iconBtn.showIf || iconBtn.showIf()) && (!iconBtn.hideIf || !iconBtn.hideIf())">
				<headerIconBtn
				[icon]="faCirclePlus" [noHoverEffect]="true" 
				[attr.title]="'common.add'|translate" 
				style="--fa-primary-color:#FFFFFF; --fa-secondary-opacity:1; --fa-secondary-color:var(--section-maincolor);" 
				[iconWrapperStyle]="{ fontSize:'36px' }" (click)="iconBtn.click && iconBtn.click($event)"
			    ></headerIconBtn>
			</ng-container>
		</ng-container>
    </div>
    <div *ngIf="showUserMenu" class="userMenuDummy"></div>
    <user-menu *ngIf="showUserMenu && sts && sts.otherObjs && sts.otherObjs['user']" [data]="sts.otherObjs['user']" [style]="{top:'15px', right:'15px'}" [showName]="false"></user-menu>
    `,
    styleUrls: ['./topRightToolBar.component.scss']
})

export class TopRighToolBarComponent {
	@ViewChild('search_button', { static: false }) public search_button;
	@ViewChild('notificationPopup', { static: false }) public notificationPopup;
    @Input() public showUserMenu:boolean = true;
    @Input() public iconBtns:any[] = [
        { type:"search", click:this.readme },
        { type:"refresh", click:this.readme },
		{ type:"notify", click:this.readme },
        { type:"add", click:this.readme },
    ];

    @Input() public textBtns:any[] = []; //E.g.: { text:"", click:()=>{} }
	@Input() public isShowSearchBox:boolean = false;
	@Input() public notifyIconColor:string = "";
	@Output() public isShowSearchBoxChanged: EventEmitter<any> = new EventEmitter();
	@Output() public isShowAdvancedSearch: EventEmitter<any> = new EventEmitter();

    public faSearch:IconDefinition = faSearch;
    public faRedo:IconDefinition = faRedo;
    public faCirclePlus:IconDefinition = faCirclePlus;
	public faBell:IconDefinition = faBell;
	public enableNotification = false;
    constructor(public sts:SettingService, public ntfs:NotificationService, public datas:DataService, public coms: CommonService) {
    }

    private readme():void {
        console.log("**TopRightToolBar**\n請配置TopRighToolBar的iconBtns來自定iconBtns");
    }
	public searchBoxEmitter(data){
		console.log(data);
		if (data.type == "start") {
			this.isShowSearchBoxChanged.emit(true)
			this.isShowAdvancedSearch.emit(false)
		}else if(data.type == "end"){
			this.isShowSearchBoxChanged.emit(false)
			this.isShowAdvancedSearch.emit(false)
		}else if(data.type == "Show advanced search"){
			this.isShowAdvancedSearch.emit(true)
		}else if(data.type == "Close advanced search"){
			this.isShowAdvancedSearch.emit(false)
		}
	}
	public close_advanced_search(){
		this.search_button.close_advanced_search()
	}

	public getIconBtn(btnType:string):boolean {
		for (let iconBtn of this.iconBtns) {
			if (iconBtn.type==btnType) return iconBtn;
		}
		return null;
	}

	public notifyBtnClick(event:PointerEvent):void {
		if (this.notificationPopup && event.target) this.notificationPopup.open(event.target);
	}

}
