import { AfterViewInit, Component } from '@angular/core';

@Component({
	selector: "additionRemarkBall-header",
	template: `
	{{params.displayName}}<additionRemarkBall [bubbleText]="params.bubbleText"></additionRemarkBall>
    `,
	styleUrls: ['./additionRemarkBall.header.scss']
})


export class AdditionRemarkBallHeader implements AfterViewInit {
	public text = '';
	public params: any;
	ngAfterViewInit() {
		
	}

	ngOnDestroy(): void {
	}

	agInit(params:any): void {
		this.params = params;
		this.text = this.params.text;
	}	

	refresh(params: any) {
		return true;
	}


}
