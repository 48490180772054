import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { DataService } from "./data.service";

@Injectable()
export class TeacherRoleGuard implements CanActivate {
	constructor(private datas: DataService, private router: Router) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	) {
		let jwt = this.getQuery('jwt');
		if (jwt){
			let jwts:string[] = jwt.split('.');
            let jwtObj = JSON.parse(atob(jwts[1]));
			if (this.checkUserRole(jwtObj.user_role)){
				return true;
			}
		}
		let json = localStorage.getItem('homeScreen_data');
		try {
			let homeScreenData = JSON.parse(json);
			if (this.checkUserRole(homeScreenData.account.user_role)){
				return true;
			}
		} catch (e) {
		}
		this.router.navigate(['login']);
		return false;
	}

	checkUserRole(user_role){
		if (user_role > 2 && user_role != 5 && user_role != 6) {
			return true;
		} else {
			return false;
		}
	}


	getQuery(q) {
		return (window.location.search.match(new RegExp('[?&]' + q + '=([^&]+)')) || [, null])[1];
	}
}