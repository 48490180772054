// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	type:'dev',
	baseHref: "/RainbowOne/webapp/2.8/roWeb/",
	version: require('../../package.json').version,
	versions: require('../../package.json').versions,
	serverHost: 'dev.openknowledge.hk',
	dataUrl: '//dev.openknowledge.hk/RainbowOne/index.php/DataStorage/',
	apiUrl: '//dev.openknowledge.hk/RainbowOne/index.php/PHPGateway/proxy/2.8/',
	db: 'PRODUCTION',
	defaultDataId: '8383',
	defaultToken: '5A2B425B',
	resourceServer: {
		'0': '//api.openknowledge.hk/RainbowOne/',
		'1': '//oka.blob.core.windows.net/media/',
		'22': '//rainbowone.hk/host/',
		'storage': '//dev.openknowledge.hk/RainbowOne/'
	},
	"title": "RoWeb",
	jwt: //'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dfZW5hYmxlZCI6IjEiLCJ0b2tlbiI6IjE3NjEtNDEyZDg5ODQ5NmQ3MWRhZWFmZGFmOGYyYjAyMTVhNTE5NmM0ZTY5N2MwMjI1ZGRkMGU0YzgzODg0Mjk2MTRmMCIsImV4cGlyZXMiOjE2NDk0MDg1NDcsInRpbWUiOiIyMDIxLTA0LTA4IDE3OjAyOjI3IiwidWlkIjoiMTc2MSIsInVzZXJfcm9sZSI6IjIiLCJzY2hvb2xfaWQiOiIyNzIiLCJpcCI6IjE3Mi4xNi4wLjEiLCJ2ZXJzaW9uIjoiMi42LjEwIn0.heQTHgMc_Sp9qjnKhNrJCJWW87UB6amnycpnvkKsVak'
		//'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dfZW5hYmxlZCI6IjEiLCJ0b2tlbiI6IjUzNS0xM2U3Y2MwMzRkMDNjNzViYmJmMjVlYjFlZmJiMTY2MjQyZjc2Yjk1NTI4YjYwZDIyNTVkMDFjYmNjZGRlZDVkIiwiZXhwaXJlcyI6MTY1MDAwOTY0MiwidGltZSI6IjIwMjEtMDQtMTUgMTY6MDA6NDIiLCJ1aWQiOiI1MzUiLCJ1c2VyX3JvbGUiOiIzIiwic2Nob29sX2lkIjoiMjcyIiwiaXAiOiIxNzIuMTYuMTQuMSIsInZlcnNpb24iOiIyLjYuMTAifQ.MW3CJ_jeYJVXvix6AlJXvCis_OEnsrGH2z0mOdCRYf4'
		'eyJ0eXAiOiJqd3QiLCJhbGciOiJIUzI1NiJ9.eyJsb2dfZW5hYmxlZCI6IjEiLCJ0b2tlbiI6IjUzMS1hMThlMTk4YzI3MmYzOGJjMzc0MmQ0MDg4YjBjNGI4MTIyOTAzM2U4M2E5OTIzMTM5NTkyNmU5Mjk1MmRjZDA2IiwiZXhwaXJlcyI6MTY0NjcyOTMyMywidGltZSI6IjIwMjEtMDMtMDggMTY6NDg6NDMiLCJ1aWQiOiI1MzEiLCJ1c2VyX3JvbGUiOiIzIiwic2Nob29sX2lkIjoiMjcyIiwiaXAiOiIxNzIuMTYuMC4xIiwidmVyc2lvbiI6IjIuNS4yMSJ9.0AHKBxB5qqmy_wnGPu5QT5xa8a4PcAdu6CBaLVqmEDQ',
	videoChat: {
		videoServerHost: "//api3.openknowledge.hk",
		videoServerPort: 8084,
		turnServerHost: "turn:api3.openknowledge.hk",
		turnServerPort: 3478,
		turnServerUsername: "okaWebRtc",
		turnServerPassword: "okaWebRtc"
	},
	ocrService: {
		httpHost: "http://api3.openknowledge.hk:8083",
		httpsHost: "https://api3.openknowledge.hk:8088"
	},
	pdfReportService: {
		httpHost: "http://api3.openknowledge.hk:8082",
		httpsHost: "https://api3.openknowledge.hk:8090"
	},
	pdfConverterService: {
		httpHost: "http://api3.openknowledge.hk:8080",
		httpsHost: "https://api3.openknowledge.hk:8091",
	},
	ngReportService: {
		httpHost: "http://api3.openknowledge.hk:8094",
		httpsHost: "https://api3.openknowledge.hk:8095",
	},
	roNodeServer: {
		httpHost: "http://ronode.azurewebsites.net",
		httpsHost: "https://ronode.azurewebsites.net",
	},
	roWebSocketServer: {
		httpHost: "http://ws.openknowledge.hk",
		httpsHost: "https://ws.openknowledge.hk"
	},
	oupMiddleLayer: {
		endpoint: "https://dev.account.oupchina.com.hk/",
		clientID: "dev/activityplatform",
		apiKey: "dev/activityplatform.e0kzc5aKPBCRnKWXqjIhrGmeJivjwnVB"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
